import React, {useContext} from "react";
import {styled} from "@mui/material/styles";
import {FormattedHTMLMessage, FormattedMessage} from "react-intl";
import FormattedNumberWithCurrency
    from "../../../../../components/formattedNumberWithCurrencies/formattedNumberWithCurrency";
import {StoreContext} from "contexts/storeContext";
import {QuoteContext} from "contexts/quoteContext";
import {
    WAITING_REVIEW,
    REVIEW_IN_PROGRESS,
    WAITING_CUSTOMER,
    UPDATING_QUOTE,
    ACCEPTED,
    REFUSED,
    EXPIRED,
} from "../../../enums/state";
import {
    QUOTE_CREATION,
    QUOTE_DISCOUNT_CHANGE,
    QUOTE_PRICE_CHANGE,
    QUOTE_STATE_CHANGE
} from "../../../enums/historyType";
import {
    quoteWaitingReview,
    quoteWaitingCustomer,
    quoteInProgress,
    quoteUpdating,
    quoteAccepted,
    quoteRefused,
    quoteExpired,
} from "assets/jss/views/quote/colors";
import CustomDate from "../notes/components/NoteBlock/CustomDate";
import {infoColor} from 'assets/jss/main';
import { ReactComponent as ArrowRight } from 'assets/img/arrow-right.svg';

const StyledContainer = styled('div')(({ theme }) => ({
    marginBottom: '24px',
    backgroundColor: '#FFF',
    borderRadius: '8px',
    boxShadow: '0px 4px 22px 0px rgba(0, 0, 0, 0.06)',
    color: '#222',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    [theme.breakpoints.down('md')]: {
        marginBottom: '16px',
    },
}));

const StyledFullText = styled('div')(({ theme }) => ({
    display: 'flex',
    padding: '20px 30px',
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: '1 0 0',
    [theme.breakpoints.down('md')]: {
        flexWrap: 'wrap',
        padding: 0,
    },
    '& > div:first-child': {
        [theme.breakpoints.down('md')]: {
            order: 1,
            padding: '16px 16px 0px 12px',
        },
    },
    '& > div:last-child': {
        [theme.breakpoints.down('md')]: {
            order: 2,
            padding: '16px 16px 0px 12px',
        },
    },
    '& > div:nth-child(2)': {
        [theme.breakpoints.down('md')]: {
            order: 3,
            width: '100%',
            textAlign: 'center',
            borderTop: '1px solid #EEEEEE',
            marginTop: '16px',
            padding: ' 24px 16px',
        },
    },
}));

const StyledFullTextForCreation = styled('div')(({ theme }) => ({
    display: 'flex',
    padding: '20px 30px',
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: '1 0 0',
    [theme.breakpoints.down('md')]: {
        padding: '16px 16px 16px 12px',
        alignItems: 'flex-start',
    },
}));

const StyledData = styled('div')(({ theme }) => ({
    display: 'flex',
    padding: '20px 30px',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '32px',
    borderTop: '1px solid #EEEEEE',
    [theme.breakpoints.down('md')]: {
        borderTop: '0px',
        padding: '0px 16px 24px 16px',
        flexDirection: 'column',
        '& > span': {
            display: 'none',
            visibility: 'hidden',
        },
    },
}));

const StyledUserNameContainer = styled('div')({
    marginBottom: '8px',
});

const StyledDisplayStateContainer = styled('div')({
    height: '40px',
    padding: '12px 16px',
    borderRadius: '8px',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
});

const StyledDataStateContainer = styled('div')({
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '16px',
});

const StyledDataQuantityContainer = styled('div')({
    display: 'flex',
    height: '40px',
    padding: '12px 16px',
    alignItems: 'flex-start',
    gap: '8px',
    borderRadius: '8px',
    background: '#F2F2F2',
    fontWeight: 600,
    '& > svg': {
        width: '16px',
        height: '16px',
    },
    '& > span:first-child': {
        color: '#333',
        textDecoration: 'line-through',
    },
    '& > span:last-child': {
        color: infoColor,
    },
});

const StyledBlueSpan = styled('span')({
    color: infoColor,
});

const QuoteHistoryLine = ({ log }) => {
    const store = useContext(StoreContext);
    const { quote } = useContext(QuoteContext);
    const currency = store.getState()?.currentOrganization?.retrieved?.currency;

    function getFormattedData(data) {
        switch (log["@type"]) {
            case QUOTE_PRICE_CHANGE:
                return <FormattedNumberWithCurrency value={data} currency={currency} format="int" />
            case QUOTE_DISCOUNT_CHANGE:
                return data + '%';
        }
        return data;
    }

    function displayData(log) {
        if (log["@type"] === QUOTE_STATE_CHANGE) {
            return (
                <StyledData>
                    <span>
                        <FormattedMessage id={'picking.quote.detail.activity.log.data.QuoteStateChange'} />
                    </span>
                    <StyledDataStateContainer>
                        {displayState(log.oldData)}
                        <ArrowRight />
                        {displayState(log.newData)}
                    </StyledDataStateContainer>
                </StyledData>
            )
        }

        if (!!log.oldData && !!log.newData) {
            return (
                <StyledData>
                    <span>
                        <FormattedMessage id={`picking.quote.detail.activity.log.data.${log['@type']}`} />
                    </span>
                    <StyledDataQuantityContainer>
                        <span>{getFormattedData(log.oldData)}</span>
                        <ArrowRight />
                        <span>{getFormattedData(log.newData)}</span>
                    </StyledDataQuantityContainer>
                </StyledData>
            );
        }
    }

    function displayState(state) {
        let stateData = null;
        switch (state) {
            case WAITING_REVIEW:
                stateData = ['picking.quote.detail.header.state.waiting', quoteWaitingReview, '#222222'];
                break;
            case WAITING_CUSTOMER:
                stateData = ['picking.quote.detail.header.state.waiting_customer', quoteWaitingCustomer, '#FFFFFF'];
                break;
            case REVIEW_IN_PROGRESS:
                stateData = ['picking.quote.detail.header.state.in_progress', quoteInProgress, '#222222'];
                break;
            case UPDATING_QUOTE:
                stateData = ['picking.quote.detail.header.state.updating_quote', quoteUpdating, '#222222'];
                break;
            case ACCEPTED:
                stateData = ['picking.quote.detail.header.state.accepted', quoteAccepted, '#FFFFFF'];
                break;
            case EXPIRED:
                stateData = ['picking.quote.detail.header.state.expired', quoteExpired, '#FFFFFF'];
                break;
            case REFUSED:
                stateData = ['picking.quote.detail.header.state.refused', quoteRefused, '#FFFFFF'];
                break;
            default:
                return null;

        }
        return (
            <StyledDisplayStateContainer style={{backgroundColor: stateData[1], color: stateData[2]}}>
                <FormattedMessage id={stateData[0]} />
            </StyledDisplayStateContainer>
        );
    }

    function displayTextTranslation(log) {
        return <FormattedHTMLMessage
            id={'picking.quote.detail.activity.log.text' + log["@type"]}
            values={{
                sku: getLineSku(log),
                number: quote.number,
                fullName: log.user?.fullName,
            }}
        />
    }

    function getLineSku(log) {
        switch (true) {
            case !!log.variation:
                return log.variation.sku;
            case !!log.quoteLineItem:
                return log.quoteLineItem.variation.sku;
            case !!log.product:
                return log.product.sku;
        }

        return '';
    }

    function displayUserNameAndMail(log) {
        if (!!log.user) {
            return (
                <div>
                    <StyledUserNameContainer>
                        <StyledBlueSpan>
                            <StyledBlueSpan><b>{log.user.fullName}</b></StyledBlueSpan>
                        </StyledBlueSpan>
                    </StyledUserNameContainer>
                    {log.user.email}
                </div>
            );
        }
        return '';
    }

    if (log['@type'] === QUOTE_CREATION) {
        return (
            <StyledContainer>
                <StyledFullTextForCreation>
                    <div>
                        {displayTextTranslation(log)}
                    </div>
                    <CustomDate date={log.createdAt}/>
                </StyledFullTextForCreation>
            </StyledContainer>
        );
    }

    return (
        <StyledContainer>
            <StyledFullText>
            {displayUserNameAndMail(log)}
                <div>
                    {displayTextTranslation(log)}
                </div>
                <CustomDate date={log.createdAt} />
            </StyledFullText>
            {displayData(log)}
        </StyledContainer>
    );
}

export default QuoteHistoryLine;
