import React, {useContext} from "react";
import {Checkbox, TableCell, TableRow,} from "@mui/material";
import {withStyles} from "@mui/styles";
import listStyle from "assets/jss/views/product/listStyle";
import MerchandisingStockStatusCell from "./MerchandisingStockStatusCell";
import CustomDate from "components/date/date";
import MerchandisingImageCell from "./MerchandisingImageCell";
import MerchandisingRankCell from "./MerchandisingRankCell";
import {StoreContext} from "contexts/storeContext";
import FormattedNumberWithCurrency from "components/formattedNumberWithCurrencies/formattedNumberWithCurrency";
import {getSimpleObjectTranslation} from "domain/helpers/translations";
import {Link} from "react-router-dom";

function MerchandisingRow({product, classes, isSelected, handleSelectSingle}) {
    const currency = useContext(StoreContext).getState()?.currentOrganization?.retrieved?.currency;

    const displayProductName = () => {
        return (
            <>
                <div>
                    <span className={classes.productName}>{getSimpleObjectTranslation(product.productName)}</span>
                </div>
                <div className={classes.nameInfos}>
                    <div>
                        <span>SKU: {product.sku}</span>
                    </div>
                </div>
                {product.eshopId &&
                    <div className={classes.nameInfos}>
                        <div>
                            <span>eShop ID: {product.eshopId}</span>
                        </div>
                    </div>
                }
            </>
        );
    }

    const displayPrice = () => {
        return (
            <div>
                <div className={classes.price}>
                    <FormattedNumberWithCurrency value={product.lowestPrice} currency={currency} />
                </div>
                <div className={classes.xsPrice}>
                    <FormattedNumberWithCurrency value={product.highestPrice} currency={currency} />
                </div>
            </div>
        );
    }


    return (
        <TableRow className={isSelected ? classes.lineSelected : ''} sx={{'& .MuiTableCell-sizeMedium': {'paddingX': '5px'}}}>
            <TableCell>
                <Checkbox
                    checked={isSelected}
                    onChange={(event) => handleSelectSingle(event, product.id)}
                />
            </TableCell>
            <MerchandisingRankCell product={product}></MerchandisingRankCell>
            <MerchandisingImageCell product={product}/>
            <TableCell><Link className={classes.lineLink} to={`/retailers/show/${product.retailerId}/products/show/${product.id}`} target="_blank">{displayProductName()}</Link></TableCell>
            <TableCell>{product.brand}</TableCell>
            <TableCell><CustomDate showRelativeDate={true} date={product.updatedAt} /></TableCell>
            <TableCell>{displayPrice()}</TableCell>
            <MerchandisingStockStatusCell product={product}/>
        </TableRow>
    );
}

export default withStyles(listStyle)(MerchandisingRow);
