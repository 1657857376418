export const LineItemStatus = {
    PENDING: 'pending',
    RESERVED: 'reserved',
    TO_SHIP: 'to-ship',
    CONFIRMED: 'confirmed',
    CANCELED : 'canceled',
    UNAVAILABLE : 'unavailable',
    RETURNING_RUNNER : 'returning-runner',
    RETURNING_RETAILER : 'returning-retailer',
    RETURNED: 'returned'
}

export function getAllStatus() {
    let allStatus = [];

    for (const status in LineItemStatus) {
        if (!process.env.REACT_APP_RETURN_ENABLED) {
            if (LineItemStatus[status] === LineItemStatus.RETURNING_RUNNER || LineItemStatus[status] === LineItemStatus.RETURNING_RETAILER) {
                continue;
            }
        }
        allStatus.push(LineItemStatus[status]);
    }

    return allStatus;
}

export function getNotShippedStatus() {
    return [
        LineItemStatus.PENDING,
        LineItemStatus.RESERVED,
        LineItemStatus.TO_SHIP,
        LineItemStatus.UNAVAILABLE,
        LineItemStatus.CANCELED
    ]
}

export function getStatusThatCanBeRefund(isRetailerReturnActivated) {
    let status = [
        LineItemStatus.PENDING,
        LineItemStatus.RESERVED,
        LineItemStatus.TO_SHIP,
        LineItemStatus.CONFIRMED,
    ]

    if (process.env.REACT_APP_RETURN_ENABLED) {
        status.push(LineItemStatus.RETURNING_RUNNER);
    }

    if (isRetailerReturnActivated) {
        status.push(LineItemStatus.RETURNING_RETAILER)
    }

    return status
}
