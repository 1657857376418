import React from "react";
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";
import Dropzone from "react-dropzone";

// @mui/material components
import {CircularProgress} from "@mui/material";
import {makeStyles} from "@mui/styles";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

// @mui/icons-material
import {CloudUpload, Delete} from "@mui/icons-material";

// core components
import Input from "components/input/input";

// action
import {handleFileUpload} from "actions/upload";

// styles
import {dangerColor, infoColor} from "../../../assets/jss/main";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {CKEditorConfig} from "../../../components/input/inputRenderer/wysiwygInputRenderer";

const useStyles = makeStyles(theme => ({
    formControl: {
        padding: "0px",
        width: "100%"
    },
    fieldContainer: {
        padding: "5px"
    },
    fieldLabel: {
        color: infoColor,
    },
    inputTextarea: {
        border: "2px solid #eee",
        borderRadius: "4px",
        width: "100%",
        padding: "8px 10px",
        boxShadow: "none",
        outline: "0",
        resize: "none",
        maxHeight: "200px",
        fontSize: "14px",
        "&::placeholder": {
            fontSize: "14px",
            color: "#acacac",
            fontWeight: "400",
            textAlign: "left",
            fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
            opacity: "1",
            lineHeight: "1.42857",
        },
        "& img": {
            maxWidth: '100%',
        }
    },
    errorMessages:{
        color:'red'
    },
    fieldUpload: {
        display: "flex",
        justifyContent: "space-between",
        flexFlow: 'column',
        position: 'relative',
        cursor: 'pointer',
        maxHeight: 'none',
    },
    fileUploadText: {
        color: "#acacac",
        paddingRight: '24px',
        wordBreak: 'break-all',
    },
    actions: {
        position: 'absolute',
        right: '10px',
        top: '8px',
        "&:last-chid":{
            border: "1px solid blue"
        }
    },
    upload: {
        "&:hover": {
            cursor: "pointer",
            color: infoColor
        },
    },
    delete: {
        marginRight: "5px",
        "&:hover": {
            cursor: "pointer",
            color: dangerColor
        }
    }
}));

function FormFields(props) {
    const { createError, upload, uploadLoading } = props;
    const classes = useStyles();

    function renderPictureName() {
        const contentUrl = props.picture && props.picture.contentUrl

        return (
            <>
                <div className={classes.fileUploadText}>
                    {contentUrl ? contentUrl.split('/').splice(-1) : <FormattedMessage id={"feed.form.file.upload"} />}
                </div>

                {contentUrl && <img src={contentUrl} alt={""} />}
            </>
        )
    }

    return (
        <div className={classes.fieldContainer}>
            <label htmlFor={`title_${props.input.name}`} className={classes.fieldLabel}>
                {props.label}
            </label>
            {props.type === "wysiwyg" && (
                <CKEditor
                    editor={ClassicEditor}
                    data={props.input.value}
                    config={CKEditorConfig}
                    onChange={( event, editor ) => {
                        props.input.onChange(editor.getData());
                    }}
                />
            )}
            {props.type === "textarea" && (
                <textarea
                    className={classes.inputTextarea}
                    placeholder={props.placeholder}
                    {...props.input}
                >
                    {props.input.value}
                </textarea>
            )}
            {props.type === "select" && (
                <Select
                    className={classes.inputTextarea}
                    placeholder={props.placeholder}
                    value={props.input.value}
                    {...props.input}
                >
                    {props.options.map(({ value, label }, index) => <MenuItem key={index} value={value}>{label}</MenuItem>)}
                </Select>
            )}
            {props.type === "text" && (
                <Input
                    {...props.input}
                    type={props.type}
                    required={props.required}
                    id={`title_${props.input.name}`}
                    inputProps={{
                        placeholder: props.placeholder,
                        type: props.type,
                        required: props.required,
                        ...props.input,
                        autoComplete: props.autoComplete
                    }}
                    formControlProps={{
                        className: classes.formControl
                    }}
                />
            )}
            {props.type === "file" && (
                <Dropzone
                    name={props.input.name}
                    accept="image/*"
                    multiple={false}
                    onDrop={(filesToUpload) => upload(filesToUpload, "category_translation_picture", props.input.name)}
                >
                    {({getRootProps, getInputProps}) => {
                        return (
                            <div className={classes.fieldUpload + " " + classes.inputTextarea} {...getRootProps()}>
                                <input id={props.input.name} name={props.input.name} {...getInputProps()} />

                                <div className={classes.actions}>
                                    {uploadLoading ? (
                                        <CircularProgress size={24} />
                                    ) : (
                                            <>
                                                <CloudUpload className={classes.upload} />
                                                <Delete
                                                    className={classes.delete}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        props.onDeletePicture();
                                                    }}
                                                />
                                            </>
                                        )}
                                </div>

                                {renderPictureName()}
                            </div>
                        )
                    }}
                </Dropzone>
            )}
            {createError && createError.errors && createError.errors[props.input.name] &&
                <span className={classes.errorMessages}>
                    <FormattedMessage id={createError.errors[props.input.name]}/>
                </span>
            }
        </div>
    );
}

const mapStateToProps = state => {
    return {
        createError: state.role.create.error,
        uploadLoading: state.upload.loading,
    }
};

const mapDispatchToProps = dispatch => ({
    upload: (file, type, object_id) => dispatch(handleFileUpload(file, type, object_id)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FormFields);
