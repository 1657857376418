import React, {useState} from "react";
import {withStyles} from "@mui/styles";
import {DialogActions, DialogTitle, DialogContent} from "@mui/material";
import {FormattedMessage} from "react-intl";

import Button from "../../../../components/button/button";
import detailStyle from "../../../../assets/jss/views/quote/detailStyle";
import {getTranslation} from "../../../../domain/helpers/translations";
import deleteLine from "../../../../api/quote/line/delete";
import {BeatLoader} from "react-spinners";

const QuoteDeleteLineModal = ({ line, deleted, onCancel, classes }) => {
    const [deleting, setDeleting] = useState(false);

    const callDeleteLine = () => {
        setDeleting(true);
        deleteLine(line.id).then(() => {
            setDeleting(false);
            deleted();
        })
    }

    return (
        <div>
            <DialogTitle>
                <FormattedMessage id={"picking.quote.detail.deleteLineModal.title"}/>
            </DialogTitle>
            <DialogContent>
                {(!!line && !!(line.variation)) && getTranslation(line.variation).name}
            </DialogContent>
            <DialogActions className={classes.dialogDeleteLineActions}>
                <Button
                    round
                    color={"success"}
                    onClick={() => callDeleteLine()}
                >
                    <FormattedMessage id={"picking.quote.detail.deleteLineModal.confirm"}/>
                    {deleting && <BeatLoader sizeUnit={"px"} size={4} color={"#FFF"} className={classes.submitLoading}/>}
                </Button>
                <Button
                    round
                    color={"danger"}
                    onClick={() => onCancel(line)}
                >
                    <FormattedMessage id={"picking.quote.detail.deleteLineModal.cancel"}/>
                </Button>
            </DialogActions>
        </div>
    );
}

export default withStyles(detailStyle)(QuoteDeleteLineModal);
