import React, {Component} from "react";
import ProductsTable from "../../../domain/components/products/ProductsTable";
import {stockEasyOffer} from "../../../domain/enum/offers";

class StockEasyProductList extends Component {
    render() {
        if(!this.props.match.params.params.includes('status')){
            this.props.match.params.params = this.props.match.params.params + '&status=true';
        }
        return <ProductsTable match={this.props.match} history={this.props.history} offer={stockEasyOffer}/>
    }
}

export default StockEasyProductList;
