import React, { useContext, useEffect, useLayoutEffect } from "react";
import connect from "react-redux/es/connect/connect";
import {FormattedMessage} from "react-intl";

// @mui/material components
import {withStyles} from "@mui/styles";

// core components
import DashboardDateRangePicker from "components/dashboardDateRangePicker";
import GridContainer from "components/grid/gridContainer";
import GridItem from "components/grid/gridItem";

import Analytics from "../../dashboard/components/analytics/analytics";
import AverageCart from "../../dashboard/components/averageCart";
import DetailsOnlineOrders from "../../dashboard/components/detailsOnlineOrders";
import GreetMessage from "../../dashboard/components/greetMessage";
import OrderCount from "../../dashboard/components/orderCount";
import ProductsRanking from "./../dashboard/productsRanking";
import SalesByValueGraph from "../../dashboard/components/graphs/salesByValueGraph";
import SalesByVolumeGraph from "../../dashboard/components/graphs/salesByVolumeGraph";

// styles
import dashboardStyle from "assets/jss/views/dashboard/dashboardStyle.js";

// actions
import {retrieveOrdersData, reset} from "actions/dashboard";

// helpers
import abort from "utils/abort";

// context
import {DashboardDateRangePickerContext} from "contexts/dasboardDateRangePickerContext";

function Dashboard(props) {
    const {
        applications,
        classes,
        currentOrganization,
        dashboardOrdersData,
        dashboardOrdersDataLoading,
        dashboardAnalyticsList,
        dashboardAnalyticsData,
        dashboardAnalyticsDataLoading,
        member,
        retailer
    } = props;

    const {
        startDate,
        endDate,
        unit,
        dateChangedTrigger,
        unitParser,
        unitTooltip
    } = useContext(DashboardDateRangePickerContext)

    useEffect(() => {
        props.retrieveOrdersData(
            startDate.format("Y-M-D"),
            endDate.format("Y-M-D"),
            unit,
            retailer
        );
    }, [dateChangedTrigger]);

    // componentWillUnmount
    useLayoutEffect(() => {
        return () => {
            abort.abortRequests();
            props.reset();
        }
    }, [])

    return (
        <div className={classes.containerWithSidebar}>
            <div className={classes.container}>
                <div className={classes.dashboardHeader}>
                    <GreetMessage name={member["givenName"]} />
                    <div>
                        <DashboardDateRangePicker />
                    </div>
                </div>
                <GridContainer>
                    <GridItem xs={12} md={9} lg={10}>
                        {/* Remove condition to enable analytics dashboard */}
                        {(dashboardAnalyticsDataLoading || dashboardAnalyticsData) && (
                            <Analytics
                                startDate={startDate}
                                endDate={endDate}
                                unit={unit}
                            />
                        )}
                        <GridContainer>
                            <GridItem xs={12} sm={6} className={classes.cardContainer}>
                                <SalesByValueGraph
                                    onlineTurnoverGraph={dashboardOrdersData?.["online"]["turnoverGraph"]}
                                    physicalTurnoverGraph={dashboardOrdersData?.["physical"]["turnoverGraph"]}
                                    loading={dashboardOrdersDataLoading}
                                    unit={unit}
                                    unitParser={unitParser}
                                    unitTooltip={unitTooltip}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={6} className={classes.cardContainer}>
                                <SalesByVolumeGraph
                                    onlineCountGraph={dashboardOrdersData?.["online"]["countGraph"]}
                                    physicalCountGraph={dashboardOrdersData?.["physical"]["countGraph"]}
                                    loading={dashboardOrdersDataLoading}
                                    unit={unit}
                                    unitParser={unitParser}
                                    unitTooltip={unitTooltip}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6} className={classes.cardContainer}>
                                <AverageCart
                                    currency={currentOrganization?.currency}
                                    loading={dashboardOrdersDataLoading}
                                    onlineOrdersCount={dashboardOrdersData?.["online"]["countTotal"]}
                                    onlineOrdersTurnover={dashboardOrdersData?.["online"]["turnoverTotal"]}
                                    physicalOrdersCount={dashboardOrdersData?.["physical"]["countTotal"]}
                                    physicalOrdersTurnover={dashboardOrdersData?.["physical"]["turnoverTotal"]}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6} className={classes.cardContainer}>
                                <OrderCount
                                    loading={dashboardOrdersDataLoading}
                                    onlineOrdersCount={dashboardOrdersData?.["online"]["countTotal"]}
                                    physicalOrdersCount={dashboardOrdersData?.["physical"]["countTotal"]}
                                />
                            </GridItem>
                            <GridItem xs={12} className={classes.rankingTableTitle}>
                                <FormattedMessage id={"dashboard.productRank.topCard.title"} values={{limit: 10}}/>
                            </GridItem>
                            <GridItem xs={12}>
                                <ProductsRanking
                                    analyticsData={dashboardAnalyticsList.retrieved}
                                    startDate={startDate}
                                    endDate={endDate}
                                    retailers={[retailer]}
                                    maxResult={10}
                                />
                            </GridItem>
                        </GridContainer>
                    </GridItem>
                    <GridItem xs={12} md={3} lg={2}>
                        <DetailsOnlineOrders
                            pending={dashboardOrdersData?.["statusCount"]["pending"]}
                            reserved={dashboardOrdersData?.["statusCount"]["reserved"]}
                            toShip={dashboardOrdersData?.["statusCount"]["to-ship"]}
                            confirmed={dashboardOrdersData?.["statusCount"]["confirmed"]}
                            canceled={dashboardOrdersData?.["statusCount"]["canceled"]}
                            returning={dashboardOrdersData?.["statusCount"]["returning"]}
                            canClick={applications["picking"]}
                            loading={dashboardOrdersDataLoading}
                        />
                    </GridItem>
                </GridContainer>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        member: state.authentication.member,
        applications: state.authentication.applications,
        dashboardOrdersData: state.dashboard.retrievedOrdersData,
        dashboardOrdersDataLoading: state.dashboard.retrieveOrdersDataLoading,
        dashboardAnalyticsData: state.dashboard.retrieveAnalyticsData,
        dashboardAnalyticsDataLoading: state.dashboard.retrieveAnalyticsDataLoading,
        dashboardAnalyticsList: state.analytics.list,
        physicalEntryCreated: state.physicalEntry.create.created,
        currentOrganization: state.currentOrganization.retrieved ?? null,
    };
};

const mapDispatchToProps = dispatch => ({
    retrieveOrdersData: (startDate, endDate, unit, retailers) => dispatch(retrieveOrdersData(startDate, endDate, unit, retailers)),
    reset: () => dispatch(reset()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(dashboardStyle)(Dashboard));
