import React, {useContext, useState} from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage, injectIntl} from 'react-intl';
import {styled} from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';

import BeatLoaderMini from "components/loaders/BeatLoaderMini";
import SaveButton from '../../../../../../components/button/SaveButton';
import CancelButtonStyled from '../../../../../../components/button/CancelButtonStyled';

import {
    borderGrayColor,
    fifteenthGrey,
    grayColor,
    greyColorHover,
    infoColor,
} from 'assets/jss/main';

import create from "api/quote/quoteInternalNote/create";

import {QuoteContext} from "contexts/quoteContext";

const StyledDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
        display: 'inline-flex',
        width: '664px',
        padding: '24px',
        flexDirection: 'column',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
        gap: '48px',
        borderRadius: '8px',
        background: '#FFF',
        boxShadow: '0px 4px 22px 0px rgba(0, 0, 0, 0.08)',
        [theme.breakpoints.down('md')]: {
            width: '343px',
            padding: '16px',
            gap: '24px',
        },
    },
}));

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '32px',
    alignSelf: 'stretch',
    padding: 0,
    [theme.breakpoints.down('md')]: {
        gap: '16px',
    },
}));

const StyledDialogActions = styled(DialogActions)({
    display: 'flex',
    alignItems: 'flex-start',
    gap: '12px',
    padding: 0,
    '& > *': {
        margin: '0 !important',
    },
});

const StyledTitle = styled('h2')({
    margin: 0,
    color: fifteenthGrey,
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '28px',
    letterSpacing: '0.15px',
});

const StyledTextField = styled(TextField)({
    width: '100%',
    '& .MuiInputBase-root': {
        padding: 0,
        border: 0,
    },
    '& .MuiInputBase-input': {
        minHeight: '164px',
        padding: '16px',
        borderRadius: '8px',
        border: `1px solid ${borderGrayColor}`,
        background: '#FFF',
        color: grayColor,
        fontSize: '14px',
        fontWeight: 400,
        '&:hover': {
            border: `1px solid ${infoColor}`,
        },
        '&:focus': {
            color: fifteenthGrey,
            border: `1px solid ${infoColor}`,
            backgroundColor: greyColorHover,
        },
        '&:focus-within': {
            outline: 'none',
        },
    },
    '& fieldset': {
        display: 'none',
        visibility: 'hidden',
    },
});

function ModalAddInternalNote({ open, onClose, intl }) {
    const { quote, refreshQuote } = useContext(QuoteContext);

    const [note, setNote ] = useState('');
    const [ loading, setLoading ] = useState(false);

    function submit(note) {
        setLoading(true);

        create(quote['@id'], note).then(() => {
            refreshQuote();
        });
    }

    return (
        <StyledDialog
            open={open}
            onClose={() => onClose()}
            slotProps={{ backdrop: { style: { backgroundColor: 'rgba(51, 51, 51, 0.20)' } } }}
        >
            <StyledDialogContent>
                <StyledTitle>
                    <FormattedMessage id={'picking.quote.detail.addInternalNoteModal.title'} />
                </StyledTitle>
                <StyledTextField
                    multiline
                    placeholder={intl.formatMessage({id: 'picking.quote.detail.addInternalNoteModal.placeholder'})}
                    defaultValue={note}
                    onChange={(e) => {
                        setNote(e.target.value);
                    }}
                />
            </StyledDialogContent>
            <StyledDialogActions>
                <CancelButtonStyled
                    onClick={() => onClose()}
                >
                    <FormattedMessage id={'picking.quote.detail.addInternalNoteModal.cancel'} />
                </CancelButtonStyled>
                <SaveButton
                    disabled={note === '' || loading}
                    onClick={() => submit(note)}
                >
                    <FormattedMessage id={'picking.quote.detail.addInternalNoteModal.add'} />
                    <BeatLoaderMini loading={loading} />
                </SaveButton>
            </StyledDialogActions>
        </StyledDialog>
    );
}

ModalAddInternalNote.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default injectIntl(ModalAddInternalNote);
