import * as Cookies from "js-cookie";

const MAX_COOKIES_COUNT = 15;
export function putMemberRetailersInShittyCookie(retailers) {
    if (null === retailers || retailers === undefined) {
        Cookies.set('_r0', null, {domain: process.env.REACT_APP_HOST, expires: 0.4});
        return;
    }

    if (!Array.isArray(retailers)) {
        console.error('retailers given is not array or null');
        return;
    }

    for (let i = 0; i <= MAX_COOKIES_COUNT; i++) {
        Cookies.remove('_r' + i);
    }

    let cookieIndex = 0;
    const setACookie = (retailersToStore) => {
        const content = JSON.stringify(retailersToStore);
        Cookies.set('_r' + cookieIndex, content, {domain: process.env.REACT_APP_HOST, expires: 0.4});
        cookieIndex++;
    };

    const COUNT_RETAILERS_PER_COOKIE = 2;
    let count = 0;
    let retailersToStore = [];
    retailers.forEach(retailer => {
        retailersToStore.push(retailer);
        count++;
        if (count === COUNT_RETAILERS_PER_COOKIE) {
            setACookie(retailersToStore);
            count = 0;
            retailersToStore = [];
        }
    });

    if (retailersToStore.length !== 0 ) {
        setACookie(retailersToStore);
    }
}


export function getMemberRetailers() {
    if (Cookies.get('_r0') === null) {
        // A user with this key at null is probably an admin
        return null;
    }


    let retailers = [];
    for (let i = 0; i <= MAX_COOKIES_COUNT; i++) {
        if (Cookies.get('_r' + i)) {
            let value = JSON.parse(Cookies.get('_r' + i));
            if (typeof value === 'undefined') {
                continue;
            }

            retailers = retailers.concat(value);
        }
    }

    if(1 === retailers.length && null === retailers[0]) {
        return null;
    }

    return retailers;
}
