import React from "react";
import auth0Client from "./auth";

class Logout extends React.Component {
    componentDidMount() {
        auth0Client.signOut();
    }

    render() {
        return null;
    }
}

export default Logout;
