import {
    dangerColor,
    successColor,
    blackMagicColor,
    defaultFont, lightGrayColor, mantisGreenColor, irisBlueColor, infoColor
} from "../../main";

const status = {
    padding: "5px 25px 5px 25px",
    borderRadius: "20px",
    textAlign: "center",
    maxHeight: "32px",
    whiteSpace: "nowrap",
    fontWeight: "400",
    fontSize: "16px"
};

const tableStyle = theme => ({
    hidden: {
        display: "none"
    },
    dialogClose: {
        marginRight: "30px",
        marginBottom: "30px"
    },
    table: {
        marginBottom: "0",
        width: "100%",
        maxWidth: "100%",
        backgroundColor: "transparent",
        borderSpacing: "0",
        borderCollapse: "collapse"
    },
    tableHeadCell: {
        ...defaultFont,
        color: blackMagicColor,
        fontSize: "1em",
        fontWeight: "bold!important",
        padding: '8px !important',
    },
    tableCell: {
        ...defaultFont,
        lineHeight: "1.42857143",
        padding: "12px 8px",
        verticalAlign: 'top',
        '& a': {
            color: 'black',
            textDecoration: 'none',
            "&:hover,&:focus": {
                color: infoColor,
                fontWeight: "300",
            },
        },
    },
    tableImageCell: {
        padding: "10px 0px !important"
    },
    tableCellXL: {
        ...defaultFont,
        lineHeight: "1.42857143",
        padding: "2px",
        verticalAlign: "middle",
        width: "120px"
    },
    tableCellNoResult: {
        ...defaultFont,
        textAlign: "center",
        fontWeight: "bold",
        verticalAlign: "middle"
    },
    tableResponsive: {
        width: "100%",
        overflowX: "auto"
    },
    statusIconEnabled: {
        color: successColor,
    },
    statusIconDisabled: {
        color: dangerColor,
    },
    productImage: {
        verticalAlign: "middle",
        width: "80px",
        height: "80px",
        border: `1px solid ${lightGrayColor}`,
    },
    tableCellBold: {
        fontSize: "16px",
        fontWeight: "bold",
        whiteSpace: "nowrap",
    },
    tableCellColumn: {
        display: "flex",
        flexDirection: "column",
    },
    name: {
        minWidth: "200px",
    },
    tableCellDate: {
        display: "flex"
    },
    date: {
        display: "flex"
    },
    day: {
        paddingRight: "3px"
    },
    actived: {
        ...status,
        backgroundColor: "rgba(120, 188, 97, 0.2)",
        color: mantisGreenColor
    },
    deactivated: {
        ...status,
        backgroundColor: "rgba(244, 67, 54, 0.2)",
        color: dangerColor
    },
    stocks: {
        paddingTop: "10px",
        "& > span:first-child": {
            ...status,
            backgroundColor: "rgba(0, 172, 193, 0.2)",
            color: irisBlueColor,
        }
    }
});

export default tableStyle;
