import {fetch} from "utils/dataAccess";
import {setOrganization} from "utils/dataAccessOrganizationHandler";

export function error(error) {
    return {type: "ROLES_CREATE_ERROR", error};
}

export function loading(loading) {
    return {type: "ROLES_CREATE_LOADING", loading};
}

export function success(created) {
    return {type: "ROLES_CREATE_SUCCESS", created};
}

export function create(values) {
    setOrganization(values);

    return dispatch => {
        dispatch(loading(true));

        return fetch("/roles", {method: "POST", body: JSON.stringify(values)})
            .then(response => {
                dispatch(loading(false));

                return response.json();
            })
            .then(retrieved => dispatch(success(retrieved)))
            .catch(e => {
                dispatch(loading(false));
                dispatch(error(e));
            });
    };
}

export function reset() {
    return dispatch => {
        dispatch({type: "ROLES_CREATE_RESET"});
        dispatch(loading(false));
        dispatch(error(null));
    };
}
