import {getCurrentOrganizationId} from "../../utils/dataAccessOrganizationHandler";
import {fetch} from "../../utils/dataAccess";

export default function (state, search, from, to, itemsPerPage, page) {
    const params = new URLSearchParams();

    if (typeof state === 'string') {
        const parts = state.split(',');
        if (parts.length >= 2) {
            parts.forEach(state => params.append('state[]', state));
        } else {
            params.set('state', state);
        }
    }
    params.set('organization', getCurrentOrganizationId());
    if (!!itemsPerPage) {
        params.set('itemsPerPage', itemsPerPage);
    }
    if (!!page) {
        params.set('page', page);
    }
    if (!!search) {
        params.set('search', search);
    }
    if (!!to) {
        params.set('createdAt[before]', to);
    }
    if (!!from) {
        params.set('createdAt[after]', from);
    }

    params.set('order[createdAt]', 'DESC');
    const url = '/quotes?' + params.toString();


    return new Promise((resolve, reject) => {
        fetch(url)
            .then(response => {
                response.json().then(response => {
                    resolve({
                        list: response["hydra:member"],
                        counts: response['hydra:count_per_state'],
                        retrieved: response,
                    });
                });
            })
            .catch(error => reject(error));
    });
}
