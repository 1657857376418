import React, {Component} from "react";
import PropTypes from "prop-types";
import {FormattedMessage,injectIntl} from "react-intl";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {BeatLoader, PropagateLoader} from 'react-spinners';
import * as moment from "moment";

// @mui/material components
import {withStyles} from "@mui/styles";
import {
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Tooltip,
    CircularProgress,
} from "@mui/material";

// @mui/icons-material
import {Delete, Edit, Ballot, CloudDownload, History} from "@mui/icons-material";

// core components
import GridContainer from "components/grid/gridContainer";
import GridItem from "components/grid/gridItem";

// styles
import tableStyle from "assets/jss/views/feed/tableStyle";
import {primaryColor, dangerColor} from "assets/jss/main";

// helpers
import {capitalize} from "utils/capitalize";

//actions
import {del} from "actions/feed/delete";
import {importFeed} from "actions/feed/importProduct";
import {create} from "actions/feed/mapper/create";
import {Authorizations, hasAuthorization} from "../../../../utils/authorizations";
import {retrieve as showRetailer} from "actions/retailer/show";

class FeedTable extends Component {
    static propTypes = {
        loading: PropTypes.bool.isRequired,
        deleteLoading: PropTypes.bool.isRequired,
        deleted: PropTypes.object
    };

    del = (item) => {
        if (window.confirm(this.props.intl.formatMessage({id: "feed.window.confirm.delete"})))
            this.props.del(item);
    };

    constructor(props) {
        super(props);

        this.state = {
            mappingFeed: null,
            importingFeed: null,
            importingNotification: []
        };

        this.isImporting = this.isImporting.bind(this);
        this.updateImportingStatus = this.updateImportingStatus.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (this.props.notificationNew !== nextProps.notificationNew) {
            let newNotifications = nextProps.notificationRetrieved.filter(item => item.type === "import");

            if(newNotifications.length > 0) {
                let notificationValues = newNotifications[0]["content"]["values"];

                // when import finished, get retailer to update missingMappings value
                if(notificationValues["importing"] === false) {
                    this.props.showRetailer(this.props.retailerId);
                }

                this.updateImportingStatus(notificationValues["id"], notificationValues["importing"]);
            }
        }

        if ((nextProps.tableData && nextProps.tableData.length > 0 && nextProps.tableData !== this.props.tableData)) {
            let importStatus = [];

            nextProps.tableData.forEach(feed => {
                let feedStatus =  {
                    id: feed.id,
                    importing: feed["process"] !== "idle"
                };
                importStatus.push(feedStatus);
            });

            this.setState({importingNotification: importStatus});
        }

        if(this.props.importingProductSuccess !== nextProps.importingProductSuccess) {
            this.updateImportingStatus(nextProps.importingProductSuccess['feedId'], true);
        }
    }

    isImporting = (id) => {
        const {importingNotification} = this.state;

        for (let i = 0; i < importingNotification.length; i++) {
            if (importingNotification[i].id === id && importingNotification[i].importing === true) {
                return true
            }
        }

        return false;
    };

    updateImportingStatus = (id, value) => {
        let importingNotification = this.state.importingNotification;

        importingNotification.forEach(feed => {
            if(feed.id === id) {
                feed.importing = value;
            }
        });

        this.setState({importingNotification});
    };

    render() {
        const {classes, tableHead, tableData, tableHeaderColor} = this.props;

        return (
            <div className={classes.tableResponsive}>
                { this.props.listLoading &&
                    <div className={classes.propagateLoader}>
                        <PropagateLoader
                            sizeUnit={"px"}
                            size={16}
                            color={primaryColor}
                            loading={this.props.listLoading}
                        />
                    </div>
                }
                { this.props.deleteLoading &&
                <div className={classes.beatLoader}>
                    <BeatLoader
                        sizeUnit={"px"}
                        size={10}
                        color={primaryColor}
                        loading={this.props.deleteLoading}
                    />
                </div>
                }
                <Table className={classes.table}>
                    {tableHead !== undefined ? (
                        <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
                            <TableRow>
                                {tableHead.map((prop, key) => {
                                    return (
                                        <TableCell
                                            className={classes.tableCell + " " + classes.tableHeadCell}
                                            key={key}
                                        >
                                            {prop}
                                        </TableCell>
                                    );
                                })}
                                <TableCell/>
                            </TableRow>
                        </TableHead>
                    ) : null}
                    {(tableData && tableData.length) ?
                    <TableBody>
                        {tableData.map((item, key) => {
                            let feedType = item['dataType'] + '-' + item['transportType'];
                            if (item['transportType'] === 'api') {
                                feedType = 'api';
                            }

                            let nextImport = moment(item["lastImportedAt"]);
                            if (item["recurrence"] !== 0) {
                                nextImport.add(item["recurrence"], item["recurrenceUnit"]);
                                if(nextImport.isBefore(moment())) {
                                    nextImport = moment();
                                }
                            }

                            return (
                                <TableRow key={key}>
                                    <TableCell className={classes.tableCell}>
                                        <div><FormattedMessage id={"feed.list.table.type." + item["transportType"] + "." + item["dataType"]}/></div>
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                        <div>{capitalize(item["name"])}</div>
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                        <div>
                                            {item["recurrence"] === 0 ?
                                                <FormattedMessage id={"feed.list.table.recurrence_disabled"}/> :
                                                <FormattedMessage
                                                    id={"feed.list.table.recurrence_" + item["recurrenceUnit"]}
                                                    values={{count: item["recurrence"] !== 1 && item["recurrence"], nextImport: nextImport.fromNow()}}/>
                                            }
                                        </div>
                                    </TableCell>
                                    {this.isImporting(item.id) ?
                                        <TableCell className={classes.tableCellImportInProgress}>
                                            <GridContainer justifyContent={"flex-end"}>
                                                <GridItem xs={6} className={classes.importInProgress}>
                                                    <FormattedMessage id={"feed.list.table.importInProgress"}/>
                                                </GridItem>
                                                <GridItem xs={3} className={classes.importInProgress}>
                                                    <BeatLoader
                                                        sizeUnit={"px"}
                                                        size={8}
                                                        color={primaryColor}
                                                        loading={true}
                                                    />
                                                </GridItem>
                                                <GridItem  xs={3}>
                                                    <Link to={`${encodeURIComponent(item["id"])}/history`}>
                                                        <Tooltip title={<FormattedMessage id={"feed.list.table.tooltip.history"}/>}>
                                                            <History style={{color: primaryColor , cursor: "pointer"}} />
                                                        </Tooltip>
                                                    </Link>
                                                </GridItem>
                                            </GridContainer>
                                        </TableCell> :
                                        <TableCell className={classes.tableCellXL}>
                                            <GridContainer justifyContent={"flex-end"}>
                                                <GridItem xs={2}>
                                                    <span onClick={() => this.del(item)}>
                                                        <Tooltip
                                                            title={<FormattedMessage
                                                                id={"feed.list.table.tooltip.delete"}/>}>
                                                            <Delete style={{color: primaryColor, cursor: "pointer"}}/>
                                                        </Tooltip>
                                                    </span>
                                                </GridItem>
                                                <GridItem xs={2}>
                                                    <Link to={`${feedType}/edit/${encodeURIComponent(item["@id"])}`}>
                                                        <Tooltip
                                                            title={<FormattedMessage
                                                                id={"feed.list.table.tooltip.edit"}/>}>
                                                            <Edit style={{color: primaryColor, cursor: "pointer"}}/>
                                                        </Tooltip>
                                                    </Link>
                                                </GridItem>
                                                {/* This condition should be deleted when all api importer extends abstract importer */}
                                                {/* Because at this moment they all have a mapper */}
                                                {(item['transportType'] !== 'api' || item['dataType'] === 'prestashop' || item['dataType'] === 'shopify' || item['dataType'] === 'woocommerce' || item['dataType'] === 'magento') &&
                                                <GridItem xs={2}>
                                                    {item["mapper"] &&
                                                    <Link
                                                        to={`${encodeURIComponent(item["@id"])}/mapper/edit/${encodeURIComponent(item["mapper"])}`}>
                                                        <Tooltip
                                                            title={<FormattedMessage
                                                                id={"feed.list.table.tooltip.map"}/>}>
                                                            <Ballot style={{color: primaryColor, cursor: "pointer"}}/>
                                                        </Tooltip>
                                                    </Link>
                                                    }
                                                    {!item["mapper"] &&
                                                    <div onClick={() => {
                                                        this.setState({mappingFeed: item});
                                                        this.props.create(item)
                                                    }}>
                                                        <Tooltip
                                                            title={<FormattedMessage
                                                                id={"feed.list.table.tooltip.map"}/>}>
                                                            {(this.props.mapperLoading && this.state.mappingFeed === item) ?
                                                                <CircularProgress size={20}/> :
                                                                <Ballot style={{color: primaryColor, cursor: "pointer"}}/>}
                                                        </Tooltip>
                                                    </div>
                                                    }
                                                </GridItem>
                                                }
                                                {(item["mapper"] || item['transportType'] === 'api') &&
                                                <GridItem xs={2}>
                                                    {
                                                        (item['dataType'] === 'prestashop' &&
                                                            (item['configuration']['locales'] && item['configuration']['locales'].length > 1) &&
                                                            (!item['configuration']['mappedLocales'] || (item['configuration']['mappedLocales'] && item['configuration']['mappedLocales'].length < 1))
                                                        ) ?
                                                            <div>
                                                                <Tooltip title={<FormattedMessage
                                                                    id={"feed.list.table.tooltip.prestashop.noLocale"}/>}>
                                                                    <CloudDownload color="disabled"/>
                                                                </Tooltip>
                                                            </div>
                                                            :
                                                            <div onClick={() => {
                                                                this.setState({importingFeed: item.id});
                                                                item.forceUpdate = undefined;
                                                                this.props.importFeed(item)
                                                            }}>
                                                                <Tooltip
                                                                    title={<FormattedMessage
                                                                        id={"feed.list.table.tooltip.import"}/>}>
                                                                    {(this.props.importProductLoading && this.state.importingFeed === item.id) ?
                                                                        <CircularProgress size={20}/> :
                                                                        <CloudDownload style={{
                                                                            color: primaryColor,
                                                                            cursor: "pointer"
                                                                        }}/>}
                                                                </Tooltip>
                                                            </div>
                                                    }
                                                </GridItem>
                                                }
                                                {(item['transportType'] === 'api' || item["mapper"]) && hasAuthorization(this.props.authorizations, Authorizations.GLOBAL_ORGANIZATION_MANAGEMENT) &&
                                                <GridItem xs={2}>
                                                    {
                                                        (item['dataType'] === 'prestashop' &&
                                                        (item['configuration']['locales'] && item['configuration']['locales'].length > 1) &&
                                                        (!item['configuration']['mappedLocales'] || (item['configuration']['mappedLocales'] && item['configuration']['mappedLocales'].length < 1))
                                                    ) ?
                                                        <div>
                                                            <Tooltip title={<FormattedMessage id={"feed.list.table.tooltip.prestashop.noLocale"}/>}>
                                                                <CloudDownload color="disabled"/>
                                                            </Tooltip>
                                                        </div>
                                                        :
                                                        <div onClick={() => {
                                                            this.setState({importingFeed: item.id});
                                                            item.forceUpdate = true;
                                                            this.props.importFeed(item)
                                                        }}>
                                                            <Tooltip title={<FormattedMessage
                                                                id={"feed.list.table.tooltip.forceImport"}/>}>
                                                                {(this.props.importProductLoading && this.state.importingFeed === item.id) ?
                                                                    <CircularProgress size={20}/> :
                                                                    <CloudDownload
                                                                        style={{
                                                                            color: dangerColor,
                                                                            cursor: "pointer"
                                                                        }}/>}
                                                            </Tooltip>
                                                        </div>
                                                    }
                                                </GridItem>
                                                }

                                                <GridItem className={classes.attribute} xs={2}>
                                                    <Link to={`${encodeURIComponent(item["id"])}/history`}>
                                                        <Tooltip
                                                            title={<FormattedMessage id={"feed.list.table.tooltip.history"}/>}>
                                                            <History style={{color: primaryColor , cursor: "pointer"}} />
                                                        </Tooltip>
                                                    </Link>
                                                </GridItem>
                                            </GridContainer>
                                        </TableCell>
                                    }
                                </TableRow>
                            );
                        })}
                    </TableBody> :
                        <TableBody>
                            {(tableData) &&
                            <TableRow>
                                <TableCell colSpan={5} className={classes.tableCellNoResult}><FormattedMessage
                                    id={"feed.list.table.no_result"}/></TableCell>
                            </TableRow>
                            }
                        </TableBody>
                    }
                </Table>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    listLoading: state.feed.list.loading,
    deleteLoading: state.feed.del.loading,
    mapperLoading: state.mapper.create.loading,
    importProductLoading: state.feed.importProduct.loading,
    importingProductSuccess: state.feed.importProduct.importing,
    notificationRetrieved: state.notification.list.retrieved,
    notificationNew: state.notification.list.notification,
    authorizations: state.authentication.authorizations,
});

const mapDispatchToProps = dispatch => ({
    create: item => dispatch(create(item)),
    importFeed: item => dispatch(importFeed(item)),
    del: item => dispatch(del(item)),
    showRetailer: (retailerId) => dispatch(showRetailer(retailerId))
});

FeedTable.defaultProps = {
    tableHeaderColor: "gray"
};

FeedTable.propTypes = {
    classes: PropTypes.object.isRequired,
    tableHeaderColor: PropTypes.oneOf([
        "warning",
        "primary",
        "danger",
        "success",
        "info",
        "rose",
        "gray"
    ]),
    tableHead: PropTypes.arrayOf(PropTypes.object),
    tableData: PropTypes.array
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(tableStyle)(injectIntl(FeedTable)));
