import React from "react";
import {Route} from "react-router-dom";

import RequireAuthentication from "../hoc/authentication/requireAuthentication";
import List from "../views/accounting/list";

let routes = [
    <Route
        path="/accounting"
        component={RequireAuthentication(List)}
        exact
        strict={true}
        key="accounting"
    />,
    <Route
        path="/picking/accounting"
        component={RequireAuthentication(List)}
        exact
        strict={true}
        key="pickingAccounting"
    />,
];

export default routes;
