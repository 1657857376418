import React, {Component} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";

// actions
import {retrieve} from "../../actions/retailer/show";

export default function (ComposedComponent) {
    class RetailerLoader extends Component {
        PropTypes = {
            router: PropTypes.object,
            currentRetailer: PropTypes.object,
        };

        formatRetailerId(id) {
            if (!id.includes('/retailers/')) {
                return '/retailers/'+id;
            }

            return id;
        }

        UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
            const retailerId = decodeURIComponent(nextProps.match.params.id);

            // detect when member is on his other retailer's view
            // if true => set the new current retailer
            if (this.props.currentRetailer && this.props.currentRetailer['@id'] !== this.formatRetailerId(retailerId)) {
                this.props.retrieve(retailerId)
            }
        }

        componentDidMount() {
            const retailerId = decodeURIComponent(this.props.match.params.id);

            if (!this.props.currentRetailer) {
                this.props.retrieve(retailerId);
            }
        }

        render() {
            return <ComposedComponent {...this.props} />;
        }
    }

    const mapStateToProps = (state) => {
        return {
            currentRetailer: state.retailer.show.retrieved,
        };
    };

    const mapDispatchToProps = dispatch => ({
        retrieve: id => dispatch(retrieve(id)),
    });

    return connect(mapStateToProps, mapDispatchToProps)(RetailerLoader);
}
