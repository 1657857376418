import React from 'react';
import {FormattedMessage} from "react-intl";

import GridContainer from "components/grid/gridContainer";
import GridItem from "components/grid/gridItem";
import Card from "components/card/card";
import CardBody from "components/card/cardBody";
import CardHeader from "components/card/cardHeader";

function CreateCard(props) {
    return (
        <GridContainer>
            <GridItem xs={12}>
                <Card style={{ minHeight: `${window.innerHeight * 0.94}px`}}>
                    <CardHeader color="info" style={{ minHeight: "68px" }}>
                        <GridContainer>
                            <GridItem xs={12}>
                                <h3><FormattedMessage id={"feed.create.title"}/></h3>
                            </GridItem>
                        </GridContainer>
                    </CardHeader>
                    <CardBody>
                        {props.children}
                    </CardBody>
                </Card>
            </GridItem>
        </GridContainer>
    );
}

export default CreateCard;
