import React from "react";
import PropTypes from "prop-types";

// @mui/icons-material
import {ChevronLeft, ChevronRight, FirstPage, LastPage} from "@mui/icons-material";

// core components
import Pagination from "./pagination";

function PaginationContainer({...props}) {
    const {itemsPerPage, paramToDelete, prefix, retrieved, urlParams, encodeURI = true } = props;

    const view = retrieved && retrieved["hydra:view"];

    if (
        !view
        || Object.keys(view).length <= 2
        || retrieved["hydra:totalItems"] <= itemsPerPage
        || (urlParams && decodeURIComponent(urlParams).search("pagination=false") !== -1)
    ) return null;

    let {
        "hydra:first": first,
        "hydra:previous": previous,
        "hydra:next": next,
        "hydra:last": last
    } = view;

    let urlPrefix = prefix ? prefix : '';

    let pageNumber = 1;

    if (urlParams && decodeURIComponent(urlParams).search("page=") !== -1) {
        pageNumber = /page=([0-9]+)/.exec(decodeURIComponent(urlParams))[1];
    }

    const page = pageNumber + " / " + Math.ceil(retrieved["hydra:totalItems"] / itemsPerPage);

    const formatFirstUrl = () => {
        let url = paramToDelete ? first.replace(paramToDelete, '') : first;

        if (!encodeURI) {
            return urlPrefix + url;
        }

        return urlPrefix +  url.split("?")[0] + "/" + encodeURIComponent(url.split("?")[1]);
    }

    const formatPreviousUrl = () => {
        let url = first;

        if (previous && previous !== first) {
           url = previous
        }

        url = paramToDelete ? url.replace(paramToDelete, '') : url;

        if (!encodeURI) {
            return urlPrefix + url;
        }

        return urlPrefix + url.split("?")[0] + "/" + encodeURIComponent(url.split("?")[1]);
    }

    const formatNextUrl = () => {
        if (next) {
            next = paramToDelete ? next.replace(paramToDelete, '') : next;

            if (!encodeURI) {
                return urlPrefix + next;
            }

            return urlPrefix + next.split("?")[0] + "/" + encodeURIComponent(next.split("?")[1]);
        }

        return "#"
    }

    const formatLastUrl = () => {
        if (last) {
            last = paramToDelete ? last.replace(paramToDelete, '') : last;

            if (!encodeURI) {
                return urlPrefix + last;
            }

            return urlPrefix + last.split("?")[0] + "/" + encodeURIComponent(last.split("?")[1]);
        }

        return "#"
    }

    return (
        <Pagination
            pages={[
                {
                    text: <FirstPage/>,
                    to: formatFirstUrl(),
                    disabled: !previous,
                },
                {
                    text: <ChevronLeft/>,
                    to: formatPreviousUrl(),
                    disabled: !previous,
                },
                {
                    active: true,
                    text: page
                },
                {
                    text: <ChevronRight/>,
                    to: formatNextUrl(),
                    disabled: !next,
                },
                {
                    text: <LastPage/>,
                    to: formatLastUrl(),
                    disabled: !next
                }
            ]}
            color="info"
        />
    );
}

Pagination.defaultProps = {
    itemsPerPage: 10,
    encodeURI: true,
};

Pagination.propTypes = {
    itemsPerPage: PropTypes.number.isRequired,
    encodeURI: PropTypes.bool,
};

export default PaginationContainer;
