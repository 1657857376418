import {dangerColor} from "../../../main";

const customModalStyle = theme => ({
    customModal: {
        '& .MuiDialog-paperWidthXs': {
            maxWidth: "280px",
        }
    },
    title: {
        textAlign: "center",
        '& h2': {
            fontSize: "18px",
        }
    },
    icon: {
        width: "120px",
        margin: "auto",
        marginBottom: "15px",
    },
    contentText: {
        textAlign: "center",
        color: "#979797",
        fontSize: "14px",
    },
    actions: {
        flexDirection: "column",
        '& button': {
            minWidth: "80%"
        }
    },
    cancelButton: {
        fontWeight: "initial"
    },
    notice: {
        fontSize: "12px",
        color: dangerColor,
        textAlign: "right",
        fontStyle: "italic",
        marginTop: "-10px",
    }
});

export default customModalStyle;
