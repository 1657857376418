import React from "react";
import PropTypes from "prop-types";

import {Button} from "@mui/material";

import {styled} from '@mui/material/styles';

import {
    infoColorHover,
    infoColor,
    infoColorDisabled,
    green500,
} from "assets/jss/main";

const StyledButton = styled(Button)(({ theme }) => ({
    backgroundColor: infoColor,
    borderRadius: '8px',
    textTransform: 'none',
    padding:'12px 16px',
    borderColor: infoColor,
    lineHeight: 'normal',
    fontWeight: '600',
    [theme.breakpoints.down('md')]: {
        maxWidth: '100%'
    },
    '&:hover': {
        backgroundColor: infoColorHover
    },
    '&.Mui-disabled' : {
        backgroundColor: infoColorDisabled,
        color: '#fff'
    },
}))

SaveButton.propTypes = {
    helperText: PropTypes.string,
    showHelperText: PropTypes.bool
}

export default function SaveButton({showHelperText, helperText, ...props}) {
    return (
        <div style={{display: 'flex', flexDirection: 'column'}}>
            <StyledButton
                variant="contained"
                disableElevation
                {...props}
            >
                {props.children}
            </StyledButton>
            {showHelperText && (
                <span style={{
                    color: green500,
                    fontSize: '12px'
                }}>
                    {helperText}
                </span>
            )}
        </div>
    )
}
