import React from "react";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";

import {makeStyles} from "@mui/styles";

import {purpleColor} from "assets/jss/main";

const useStyles = makeStyles(theme => ({
    cardStatsLegendDotPurple: {
        width: "11px",
        height: "11px",
        borderRadius: "50%",
        backgroundColor: purpleColor
    },
}));

export default function LegendDotPurple(props) {
    const classes = useStyles();

    return (
        <>
            <div className={classes.cardStatsLegendDotPurple} />
            <div>
                <FormattedMessage id={props.title} />
            </div>
        </>
    )
}

LegendDotPurple.defaultProps = {
    title: ""
};

LegendDotPurple.propTypes = {
    title: PropTypes.string.isRequired
};
