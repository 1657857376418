import React, {Component} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import * as moment from "moment";

// @mui/material components
import {withStyles} from "@mui/styles";

// core components
import GridItem from "components/grid/gridItem";
import Card from "components/card/card";
import CardHeader from "components/card/cardHeader";
import CardBody from "components/card/cardBody";
import Table from "./table";

// styles
import listStyle from "assets/jss/views/picking/order/listStyle";

// actions
import {list, count, reset} from "actions/retailer/list";

// helpers
import abort from "utils/abort";

class List extends Component {
    static propTypes = {
        loading: PropTypes.bool.isRequired,
        error: PropTypes.string,
        list: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        const date = new Date();
        const endDate = moment(date);

        date.setDate(date.getDate() - 7);
        const startDate = moment(date);

        this.state = {
            errorNotification: true,
            deletedNotification: true,
            value: 0,
            startDate: startDate,
            initialStartDate: startDate,
            initialEndDate: endDate,
            endDate: endDate,
            name: "",
            countRetrieved: false
        };
    }

    componentDidMount() {
        let params = decodeURIComponent(this.props.match.params.params).split("&");

        if (params.length !== 4) {
            this.handleRouteReset();
        }

        this.props.list(this.props.match.params.params && decodeURIComponent(this.props.match.params.params));

        this.updateStoreParameters(params);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({errorNotification: true});
        this.setState({deletedNotification: true});

        if (this.props.match.params.params !== nextProps.match.params.params && this.props.retrieved) {
            let params = decodeURIComponent(nextProps.match.params.params).split("&");
            nextProps.list(nextProps.match.params.params &&
                decodeURIComponent(nextProps.match.params.params));
            this.setState({countRetrieved: false});
            this.updateStoreParameters(params);
        }

        if (this.props.retrieved !== nextProps.retrieved) {
            let retailersId = nextProps.retrieved["hydra:member"].map(item => item["id"])
            nextProps.count(`retailers={${retailersId}}`)
        }

        if (this.props.countRetrieved !== nextProps.countRetrieved) {
            this.setState({countRetrieved: nextProps.countRetrieved});
        }
    };

    componentWillUnmount() {
        this.props.reset();
        abort.abortRequests();
    };

    updateStoreParameters(params) {
        params.map((param) => {
            return this.setState({[param.split("=")[0]]: param.split("=")[1]})
        });
    };

    handleRouteReset() {
        this.setState({name: ""})
        this.props.history.push(`/picking/retailers/order[status]=asc&order[createdAt]=asc&retailer_has_order=true&pagination=true&itemsPerPage=9999`);
    }

    handlePageReset = (params) => {
        params = params.split("&");

        return params;
    };

    handleDateChange(dates) {
        if (dates.startDate === null || dates.endDate === null) {
            dates = {startDate: this.state.initialStartDate, endDate: this.state.initialEndDate}
        }

        this.setState({startDate: dates.startDate, endDate: dates.endDate}, function() {
            let params = this.handlePageReset(decodeURIComponent(this.props.match.params.params));

            let startDate = dates.startDate.clone();
            let endDate = dates.endDate.clone();

            let newParams = `createdAt[before]=${moment.utc(endDate.startOf('day').add(1, 'd')).format("Y-M-D HH:mm:ss")}&createdAt[after]=${moment.utc(startDate.startOf('day').subtract(1, 's')).format("Y-M-D HH:mm:ss")}`;

            params.map((param) => {
                if (param.search("createdAt") === -1) {
                    newParams += `&${param}`;
                }

                return true;
            });

            this.props.history.push(`/picking/retailers/${encodeURIComponent(newParams)}`);
        });
    };

    handleClearSearch = () => {
        this.setState({
            name: ""
        }, () =>  this.handleSearch(""));
    };

    handleSearch = (value) => {
        this.setState({"name": value});

        let params = this.handlePageReset(decodeURIComponent(this.props.match.params.params));
        let newParams = "";

        if (value.length > 0) {
            newParams = `name=${value.toLowerCase()}`;
        }

        params.map((param) => {
            if (param.search("name") === -1) {
                newParams += `&${param}`;
            }

            return true;
        });

        this.props.history.push(`/picking/retailers/${encodeURIComponent(newParams)}`);
    };

    render() {
        const {classes} = this.props;

        return (
            <div className={classes.containerWithSidebar}>
                <div className={classes.container}>
                    <Card className={classes.card}>
                        <CardHeader color="info" className={classes.cardHeader}>
                            <h3><FormattedMessage id={"picking.retailer.list.title"}/></h3>
                        </CardHeader>
                        <CardBody className={classes.cardBody}>
                            <div className={classes.line}></div>
                            <Table
                                tableHead={[
                                    <FormattedMessage id={"picking.retailer.list.table.header.brand"}/>,
                                    <FormattedMessage id={"picking.retailer.list.table.header.nbProductPending"}/>,
                                    <FormattedMessage id={"picking.retailer.list.table.header.nbProductReserved"}/>,
                                    <FormattedMessage id={"picking.retailer.list.table.header.nbProductReturning"}/>,
                                    <FormattedMessage id={"picking.retailer.list.table.header.location"} />,
                                ]}
                                tableHeaderColor="primary"
                                tableData={this.props.retrieved && this.props.retrieved["hydra:member"]}
                                countRetrieved={this.state.countRetrieved}
                                match={this.props.match}
                                history={this.props.history}
                            />
                        </CardBody>
                        {this.props.retrieved && this.props.retrieved["hydra:member"].length > 0 &&
                            <GridItem xs={12} className={classes.total}>
                                <FormattedMessage id={`picking.retailer.list.label.total.brand`}
                                                  values={{qty: this.props.retrieved["hydra:member"].length}}/>
                            </GridItem>
                        }
                    </Card>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    const {
        retrieved,
        loading,
        error,
        countRetrieved,
    } = state.retailer.list;

    return {retrieved, loading, error, countRetrieved};
};

const mapDispatchToProps = dispatch => ({
    list: params => dispatch(list(params)),
    count: params => dispatch(count(params)),
    reset: () => dispatch(reset())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(listStyle)(List));
