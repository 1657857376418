export function redirectToStockEasyApp(isVariation) {
    const isAndroid = navigator.userAgent.toLowerCase().indexOf("android") > -1;
    let url = '';

    if (!isAndroid) {
        url = `wishibam-mobilescanner://?redirect-url=${window.location.href}`;
        if (isVariation) {
            url = `${url}&variation=true`;
        }
    } else {
        url = `intent://?redirect-url=${window.location.href}`;
        if (isVariation) {
            url = `${url}&variation=true`;
        }
        url = `${url}#Intent;scheme=wishibam-mobilescanner;end`;
    }

    window.location.replace(url);
    setTimeout(function () {
        if (isAndroid) {
            window.location.replace(process.env.REACT_APP_STOCKEASY_APP_ANDROID_STORE_LINK);
        } else {
            window.location.replace(process.env.REACT_APP_STOCKEASY_APP_APPLE_STORE_LINK);
        }
    }, 2000);
}