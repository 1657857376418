import React, {Component} from "react";
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";

// @mui/material components
import {
    Popover,
    Typography
} from "@mui/material";

// styles
import membersStyle from "assets/jss/views/member/memberStyle";
import {withStyles} from "@mui/styles";

class ActionsMenu extends Component {
    static propTypes = {
        position: PropTypes.object,
        handleClose: PropTypes.func.isRequired,
        selectedMember: PropTypes.object.isRequired,
        openEditDialog: PropTypes.func.isRequired,
        openDeleteDialog: PropTypes.func.isRequired
    };

    static defaultProps = {
        position: null
    }

    render() {
        const {classes} = this.props;

        return (
            <Popover
                open={Boolean(this.props.position)}
                anchorEl={this.props.position}
                onClose={() => this.props.handleClose()}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Typography
                    className={classes.typography}
                    onClick={(e) => {
                        e.stopPropagation();
                        this.props.openEditDialog(this.props.selectedMember);
                        this.props.handleClose()
                    }}
                >
                    <FormattedMessage id={"member.allMember.list.actionMenu.edit"}/>
                </Typography>
                <Typography
                    className={classes.typography}
                    onClick={(e) => {
                        e.stopPropagation();
                        this.props.openDeleteDialog();
                    }}
                >
                    <FormattedMessage id={"member.allMember.list.actionMenu.delete"}/>
                </Typography>
            </Popover>
        );
    }
}

export default withStyles(membersStyle)(ActionsMenu);
