import React from "react";
import {BeatLoader} from "react-spinners";

// @mui/material components
import {makeStyles} from "@mui/styles";

// style
import {primaryColor} from "assets/jss/main";

const useStyles = makeStyles(theme => ({
    loaderContainer: {
        color: primaryColor,
        fontSize: "16px",
        fontWeight: "400",
        position: 'absolute',
        right: '30px',
    },
}));

export default function Loader(props) {
    const classes = useStyles();

    return (
        <div className={classes.loaderContainer}>
            <BeatLoader
                sizeUnit={"px"}
                size={12}
                color={primaryColor}
                loading={true}
            />
        </div>
    );
}
