import React, { useEffect } from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";

// @mui/material components
import { Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText } from "@mui/material";

// core components
import Button from "components/button/button";
import Loader from '../../configuration/components/loader';

// actions
import { deleteCategory } from "actions/category/delete";

// helpers
import {getTranslation} from "../../../domain/helpers/translations";

function DeleteDialog(props) {
    const { category, closeDialog, deleted, loading, isOpen } = props;

    const isSubCategory = Boolean(category && category.parent !== null);

    useEffect(() => {
        if (deleted) {
            closeDialog();
        }
    }, [deleted]);

    return (
        <Dialog
            open={isOpen}
            onClose={closeDialog}
            maxWidth={'xs'}
            scroll={'body'}
        >
            <DialogTitle>
                <FormattedMessage
                    id={isSubCategory ? "category.delete.sub.title" : "category.delete.title"}
                    values={{ category: getTranslation(category).name }}
                />
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <FormattedMessage id={"category.delete.text"} />
                </DialogContentText>
            </DialogContent>
                <DialogActions>
                    <Button color={"info"} simple onClick={closeDialog} disabled={loading}>
                        <FormattedMessage id={"category.delete.cancel"} />
                    </Button>
                    <Button
                        round
                        color={"danger"}
                        type="submit"
                        disabled={loading}
                        onClick={() => props.deleteCategory(category)}
                    >
                        <FormattedMessage id={"category.delete.submit"} />
                        {loading && <Loader />}
                    </Button>
                </DialogActions>
        </Dialog>
    )
}

const mapStateToProps = state => {
    return {
        loading: state.category.deleteCategory.loading,
        deleted: state.category.deleteCategory.deleted,
    }
};

const mapDispatchToProps = dispatch => ({
    deleteCategory: (item) => dispatch(deleteCategory(item)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DeleteDialog);
