import React, {Component} from "react";
import {Link} from "react-router-dom";
import PropTypes from "prop-types";
import connect from "react-redux/es/connect/connect";
import {FormattedMessage, injectIntl} from "react-intl";
import {PropagateLoader} from 'react-spinners';

// @mui/material components
import {
    Stepper,
    Step,
    StepButton,
    Tabs,
    Tab,
    CircularProgress, Switch
} from "@mui/material";

// @mui/icons-material
import {
    ArrowBackIos,
} from "@mui/icons-material";

// core components
import Button from "components/button/button";
import CardAttribute from "./components/cardAttribute"

// styles
import attributesStyle from "assets/jss/views/attribute/attributesStyle";
import {primaryColor} from "assets/jss/main";

// actions
import {list, reset} from "actions/feed/attribute/list";
import {reset as resetUpdate} from "actions/feed/attribute/update";

// utils
import abort from "utils/abort";
import {withStyles} from "@mui/styles";

class Attributes extends Component {
    static propTypes = {
        retailerId: PropTypes.string.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            activeStep: 0,
            categoryOptions: [],
            selectedLabels: {},
            activeTab: 0,
            attributesMapped: [],
            unmappedCount: [],
            inStockActive: false,
            inValid: false,
        };

        this.listenUrlChange = null;
        this.inStockActiveSearchParamsKey = 'in-stock-active';
        this.inValidSearchParamsKey = 'in-valid';
    }

    componentDidMount() {
        this.setStateFromSearchParams();
        this.listenUrlChange = this.props.history.listen(() => this.setStateFromSearchParams());
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextState.attributesMapped !== this.state.attributesMapped && nextState.attributesMapped.length > 0) {
            return false;
        }

        return true;
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        // prevent double nextProps.list because of resetUpdate

        if (this.props.attributeRetrieved !== nextProps.attributeRetrieved) {
            nextProps.attributeRetrieved.categoryAttributes = nextProps.attributeRetrieved.categoryAttributes.sort((categA, categB) => categA.trash && !categB.trash ? 1 : -1);
            let steps = this.renderSteps(nextProps.attributeRetrieved);

            this.setState({
                unmappedCount: {
                    'categories': nextProps.attributeRetrieved && nextProps.attributeRetrieved.categoryCountAttributes ? nextProps.attributeRetrieved.categoryCountAttributes : 0,
                    'colors': nextProps.attributeRetrieved && nextProps.attributeRetrieved.colorCountAttributes ? nextProps.attributeRetrieved.colorCountAttributes : 0,
                    'genders': nextProps.attributeRetrieved && nextProps.attributeRetrieved.genderCountAttributes ? nextProps.attributeRetrieved.genderCountAttributes : 0,
                    'sizes': nextProps.attributeRetrieved && nextProps.attributeRetrieved.sizeCountAttributes ? nextProps.attributeRetrieved.sizeCountAttributes : 0
                }
            });

            if (steps.length > 0) {
                let activeStepAttribute = this.getLabels(nextProps.attributeRetrieved, this.state.activeStep);

                if (activeStepAttribute.values && activeStepAttribute.values.length === 0) {
                    this.setState({activeStep: steps[0].index})
                }
            }
        }
    };

    componentWillUnmount() {
        if (!!this.listenUrlChange) {
            this.listenUrlChange();
        }
        this.props.reset()
        abort.abortRequests();
    }

    setStateFromSearchParams() {
        const params = new URLSearchParams(this.props.history.location.search);
        const inStockActive = params.has(this.inStockActiveSearchParamsKey)
            ? JSON.parse(params.get(this.inStockActiveSearchParamsKey))
            : false;
        const inValid = params.has(this.inValidSearchParamsKey)
            ? JSON.parse(params.get(this.inValidSearchParamsKey))
            : false;

        this.setState({ inStockActive: inStockActive });
        this.setState({ inValid: inValid });
        this.props.list(this.props.retailerId, true, inStockActive, inValid);
    };

    getOptions = attributeName => {
        const {colors, categories, genders, sizes} = this.props.attributeRetrieved;

        if (attributeName === "categories" && categories) {
            return categories;
        } else if (attributeName === "colors" && colors) {
            return colors;
        } else if (attributeName === "genders" && genders) {
            genders.forEach((item) => {
                item['name'] = item.value;
            });
            return genders;
        } else if (attributeName === "sizes" && sizes) {
            return sizes;
        }
    };

    handleChange = (item) => {
        const {activeStep} = this.state;

        let tableName = '';

        if (activeStep === 0) {
            tableName = 'categories';
        } else if (activeStep === 1) {
            tableName = 'genders';
        } else if (activeStep === 2) {
            tableName = 'colors';
        } else if (activeStep === 3) {
            tableName = 'sizes';
        }

        item.value = tableName;
        if (item.trash) {
            item.trashCategories = [item.name]
        }
        if (item.untrash) {
            item.untrashCategories = [item.name]
        }
        let data = {[tableName]: item};
        let unmappedCount = this.state.unmappedCount;
        unmappedCount[tableName]--;

        this.setState({attributesMapped: [...this.state.attributesMapped, item.name] });
        this.setState({unmappedCount: unmappedCount });

        this.props.onSubmitEvent(
            data,
            this.props.retailerId
        );
    };

    handleStep = step => () => {
        this.setState({activeStep: step})
    };

    getLabels(attributeRetrieved, activeStep) {
        if (attributeRetrieved) {
            switch (activeStep) {
                case 0 :
                    return {attributeName: "categories", values: attributeRetrieved["categoryAttributes"]};
                case 1 :
                    return {attributeName: "genders", values: attributeRetrieved["genderAttributes"]};
                case 2 :
                    return {attributeName: "colors", values: attributeRetrieved["colorAttributes"]};
                default :
                    return {attributeName: "sizes", values: attributeRetrieved["sizeAttributes"]};
            }

        }
        return false
    }

    renderSteps(attributeRetrieved) {
        const {intl} = this.props;

        let steps = [
            {
                label: attributeRetrieved && attributeRetrieved.categoryAttributes.length ? intl.formatMessage({id: "product.show.attribute.step.label.categories"}) : null,
                index: 0,
                id: "categories"
            },
            {
                label: attributeRetrieved && attributeRetrieved.genderAttributes.length ? intl.formatMessage({id: "product.show.attribute.step.label.genders"}) : null,
                index: 1,
                id: "genders"
            },
            {
                label: attributeRetrieved && attributeRetrieved.colorAttributes.length ? intl.formatMessage({id: "product.show.attribute.step.label.colors"}) : null,
                index: 2,
                id: "colors"
            },
            {
                label: attributeRetrieved && attributeRetrieved.sizeAttributes.length ? intl.formatMessage({id: "product.show.attribute.step.label.sizes"}) : null,
                index: 3,
                id: "sizes"
            },

        ];

        return steps.filter(step => step.label);
    }

    handleToggleInStock = () => {
        this.setState(
            { inStockActive: !this.state.inStockActive },
            function() {
                const params = new URLSearchParams(this.props.history.location.search);
                params.set(this.inStockActiveSearchParamsKey, this.state.inStockActive);
                this.props.history.push(`?${params.toString()}`);

                this.props.list(
                    this.props.retailerId,
                    0 === this.state.activeTab,
                    this.state.inStockActive,
                    this.state.inValid,
                )
            }
        );
    };

    handleToggleInValid = () => {
        this.setState(
            { inValid: !this.state.inValid },
            function() {
                const params = new URLSearchParams(this.props.history.location.search);
                params.set(this.inValidSearchParamsKey, this.state.inValid);
                this.props.history.push(`?${params.toString()}`);

                this.props.list(
                    this.props.retailerId,
                    0 === this.state.activeTab,
                    this.state.inStockActive,
                    this.state.inValid,
                )
            }
        );
    };

    render() {
        const {classes, intl, attributeRetrieved, values} = this.props;
        const {activeStep} = this.state;

        const steps = this.renderSteps(attributeRetrieved);
        const labels = this.getLabels(attributeRetrieved, activeStep);

        let labelName = '';
        let labelNameId = '';
        let labelData = '';

        if (labels['attributeName'] === 'categories' ) {
            labelName = 'category_dictionary_id';
            labelNameId = 'category_id';
            labelData = 'categories';
        } else if (labels['attributeName'] === 'colors') {
            labelName = 'color_dictionary_id';
            labelNameId = 'color_id';
            labelData = 'colors';
        } else if (labels['attributeName'] === 'genders') {
            labelName = 'id';
            labelNameId = 'id';
            labelData = 'genders';
        } else if (labels['attributeName'] === 'sizes') {
            labelName = 'id';
            labelNameId = 'id';
            labelData = 'sizes';
        }

        TabPanel.propTypes = {
            index: PropTypes.any.isRequired,
            activeTab: PropTypes.any.isRequired,
        };

        const renderLabel = () => {
            const {activeTab} = this.state;
            const isMappedView = activeTab === 1;

            return labels && labels.values.map((item, key) => {
                if ((!this.state.attributesMapped.includes(item[labelName]) && !isMappedView) || isMappedView) {
                    return (
                        <CardAttribute
                            key={key}
                            item={item}
                            labelName={labelName}
                            labelNameId={labelNameId}
                            labelData={labelData}
                            labels={labels}
                            values={values}
                            attributeRetrieved={this.props.attributeRetrieved}
                            handleChange={item => this.handleChange(item)}
                            activeTab={this.state.activeTab}
                            activeStep={this.state.activeStep}
                        />
                    );
                }
            });
        };

        const renderForm = () => {
            return (
                <div className={classes.formContainer}>
                    <Stepper alternativeLabel nonLinear activeStep={activeStep}>
                        {steps.map((step, index) => {
                            return (
                                <Step key={step.label} className={step.index !== 0 ? classes.step : null}>
                                    <StepButton
                                        className={activeStep === step.index ? classes.selectedStepper : classes.stepper}
                                        onClick={this.handleStep(step.index)}
                                    >
                                        <div style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            flexWrap: 'wrap',
                                        }}>
                                            {step.label}
                                            <div>&nbsp;(
                                                {this.props.attributeLoading ?
                                                    <CircularProgress
                                                        size={12}
                                                        color={"inherit"}
                                                    />
                                                    :
                                                    this.state.unmappedCount[step.id] && `${this.state.unmappedCount[step.id]}`
                                                }
                                                )
                                            </div>
                                        </div>
                                    </StepButton>
                                </Step>
                            );
                        })}
                    </Stepper>
                    <div className={classes.labelStyle}>
                        {this.props.attributeLoading ?
                            <div className={classes.propagateLoader}>
                                <PropagateLoader
                                    sizeUnit={"px"}
                                    size={16}
                                    color={primaryColor}
                                    loading={this.props.attributeLoading}
                                />
                            </div>
                            :
                            <div>
                                {renderLabel()}
                                <div className={classes.actionsContainer}>
                                    <Button color={"info"} simple component={Link}
                                            to={`/retailers/show/${encodeURIComponent('/retailers/'+this.props.retailerId)}/feeds/`}>
                                        <ArrowBackIos/>
                                        <FormattedMessage id={"feed.attribute.form.back"}/>
                                    </Button>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            );
        }

        const handleChange = (event, newValue) => {
            this.setState({
                attributesMapped: [],
                activeTab: newValue
            }, () => {
                this.props.list(
                    this.props.retailerId,
                    0 === this.state.activeTab,
                    this.state.inStockActive,
                    this.state.inValid,
                );
            });
        };

        function TabPanel(props) {
            const { index, activeTab, content } = props;
            if (activeTab !== index) {
                return false;
            }

            return (
                <div
                    hidden={activeTab !== index}
                    id={`${labelData}-${index}`}
                >
                    {content()}
                </div>
            );
        }

        return (
            <div>
                <div>
                    <FormattedMessage id={'mapper.form.attr.inStock'}/>
                    <Switch
                        checked={this.state.inStockActive}
                        onClick={() => this.handleToggleInStock()}
                        value={true}
                        inputProps={{'aria-label': 'secondary checkbox'}}
                    />
                </div>
                <div>
                    <FormattedMessage id={'mapper.form.attr.valid'}/>
                    <Switch
                        checked={this.state.inValid}
                        onClick={() => this.handleToggleInValid()}
                        value={true}
                        inputProps={{'aria-label': 'secondary checkbox'}}
                    />
                </div>
                {!this.props.attributeRetrieved ?
                    <div className={classes.propagateLoader}>
                        <PropagateLoader
                            sizeUnit={"px"}
                            size={16}
                            color={primaryColor}
                            loading={this.props.attributeLoading}
                        />
                    </div>
                    :
                    <div>
                        <Tabs indicatorColor="primary" value={this.state.activeTab} onChange={handleChange}>
                            <Tab label={intl.formatMessage({id: "mapper.form.tab.unmapped"})} id={`${labelData}-0`}/>
                            <Tab label={intl.formatMessage({id: "mapper.form.tab.mapped"})} id={`${labelData}-1`}/>
                        </Tabs>
                        <TabPanel activeTab={this.state.activeTab} index={0} content={renderForm}/>
                        <TabPanel activeTab={this.state.activeTab} index={1} content={renderForm}/>
                    </div>
                }
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        attributeLoading: state.attribute.list.loading,
        attributeRetrieved: state.attribute.list.retrieved,
        dictionaryRetrieved: state.attribute.list.dictionaryRetrieved,
        isValueSelected: state.form.attribute && state.form.attribute.hasOwnProperty("values"),
        values: state.form.attribute && state.form.attribute.values,
    };
};

const mapDispatchToProps = dispatch => ({
    list: (retailerId, missing, inStock, inValid) => dispatch(list(retailerId, missing, inStock, inValid)),
    reset: () => dispatch(reset()),
    resetUpdate: () => dispatch(resetUpdate()),
});

export default (connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(attributesStyle)(injectIntl(Attributes))));
