import { fetch } from "utils/dataAccess";
import { setOrganization } from "utils/dataAccessOrganizationHandler";
import normalizeTranslations from "../../actions/translations";

import addImages from "./addImages";
import persister from "./persister";
import hydrator from "./hydrator";

export default async function create(values, files) {
    setOrganization(values);
    values = normalizeTranslations(values);
    values = persister(null, values);

    let toPersist = persister(null, values);
    toPersist = await addImages(toPersist, files);
    const response = await fetch("/corners", {method: "POST", body: JSON.stringify(toPersist)})
    const retrieved = await response.json();

    return hydrator(retrieved);
}
