import {useEffect, useState} from "react";
import {fetch} from "utils/dataAccess";

export default function useBatchActions() {

    const [runningActions, setRunningActions] = useState([]);

    useEffect(() => {
        fetch("/batch_actions?pagination=false&status=running").then(
            response => {
                response.json().then(retrieved => {
                    setRunningActions(retrieved['hydra:member']);
                });
            }
        );
    }, []);

    return {
        runningActions
    };
}
