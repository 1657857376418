import {fetch} from "../../utils/dataAccess";

export function error(error) {
    return {type: "PRODUCT_SHOW_ERROR", error};
}

export function loading(loading) {
    return {type: "PRODUCT_SHOW_LOADING", loading};
}

export function success(retrieved) {
    return {type: "PRODUCT_SHOW_SUCCESS", retrieved};
}

export function retrieve(id) {
    const url = "/products/" + id;

    return dispatch => {
        dispatch(loading(true));

        return fetch(url)
            .then(response =>
                response.json()
            )
            .then((retrieved) => {
                dispatch(loading(false));
                dispatch(success(retrieved));
            })
            .catch(e => {
                dispatch(loading(false));
                dispatch(error(e.message));
            });
    };
}

export function reset() {
    return dispatch => {
        dispatch({type: "PRODUCT_SHOW_RESET"});
        dispatch(error(null));
        dispatch(loading(false));
        dispatch(success(null));
    };
}
