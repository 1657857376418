import React from "react";
import {Chip, } from "@mui/material";
import {withStyles} from "@mui/styles";
import productLabelStyle from "../../../../assets/jss/views/product/productLabelStyle";
import {getSimpleObjectTranslation} from "../../../helpers/translations";

function ProductSingleStockLabel({sizeStock, classes}) {

    let stockType;
    if(sizeStock.stock === 0) {
        stockType = classes.noStock;
    } else if (sizeStock.stock <= 5) {
        stockType = classes.middleStock;
    } else {
        stockType = classes.inStock;
    }
    const label = getSimpleObjectTranslation(sizeStock.name) + "(" + sizeStock.stock + ")";

    return (
        <Chip
            label={label}
            className={stockType}
        />
    );
}

export default withStyles(productLabelStyle)(ProductSingleStockLabel);
