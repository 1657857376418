import {infoColor, lightGrayColor, mediumGrayColor, primaryColor, stormGrayColor, terraCottaColor} from "../../main";

const AuthenticationStyle = theme => ({
  gridContainer: {
    minHeight: "calc(100vh - 64px)",
    justifyContent: "center",
  },
  cardBody: {
    textAlign: "center",
    padding: "1.875rem",
  },
  btn: {
    borderRadius: "4px",
    border: "1px solid "+mediumGrayColor,
    background: "transparent",
    color: stormGrayColor,
    height: "52px",
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "16px",
    fontWeight: "400",
    transition: "all .5s",

    "&:hover": {
      border: "1px solid "+infoColor,
      background: infoColor+"08",
      color: primaryColor,
    },
  },
  textSeparator: {
    marginTop: ".5rem",
    marginBottom: ".5rem",
  },
  btnBlock: {
    width: "100%",
  },
  h1: {
    fontWeight: 400,
    fontSize: "1.5rem",
    lineHeight: 1.334,
    paddingBottom: "1rem",
    margin: "-0.875rem -1.875rem 2rem",
    borderBottom: "2px solid "+lightGrayColor
  },
  errorContainer: {
    display: "flex",
    alignItems: "center",
  },
  errorIcon: {
    width: "3rem",
    height: "3rem",
    color: terraCottaColor,
    marginRight: "1.5rem",
  },
  errorMessage: {
    flex: "1 1 auto",
    fontWeight: "400",
    fontSize: "115%",
  },
});

export default AuthenticationStyle;
