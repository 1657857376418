import React, {useContext} from 'react'
import PropTypes from "prop-types";
import {BeatLoader} from "react-spinners";
import {FormattedMessage} from "react-intl";

import Button from "@mui/material/Button";

import makeStyles from "@mui/styles/makeStyles";

import {infoColor} from "assets/jss/main";

import {OrderContext} from "contexts/OrderContext";

import {Authorizations, hasAuthorization} from "utils/authorizations";

const useStyle = makeStyles({
    assign: {
        borderRadius: "20px",
        backgroundColor: infoColor,
        textTransform: "none",
        width: "116px",
        marginRight: "25px",
        "& > span:first-child": {
            display: "initial"
        }
    },
    submitLoading: {
        marginLeft: "5px",
        color: "#FFFFFF"
    },
});

export default function RunnerAssignButton({lineItem, memberId}) {
    const classes = useStyle();

    const {
        authorizations,
        updateLoadingLineItem,
        handleClickAssign
    } = useContext(OrderContext);

    if (hasAuthorization(authorizations, Authorizations.PICKING_READ_ONLY)) {
        return null;
    }

    return (
        <Button
            color="primary"
            variant="contained"
            onClick={() => handleClickAssign(lineItem, memberId)}
            classes={{root: classes.assign}}
        >
            <FormattedMessage id={"picking.order.show.header.assignItToMe"}/>
            <div className={classes.submitLoading}>
                <BeatLoader
                    sizeUnit={"px"}
                    size={4}
                    color={"#FFF"}
                    loading={updateLoadingLineItem}
                />
            </div>
        </Button>
    )
}

RunnerAssignButton.defaultProps = {
    lineItem: {},
    memberId: null
}

RunnerAssignButton.propTypes = {
    lineItem: PropTypes.object,
    memberId: PropTypes.string
}
