import Interface from "../../../utils/interface";
import WysiwygInputRenderer from "./wysiwygInputRenderer";
import FormikInputRenderer from "./formikInputRenderer";
import PriceInputRenderer from "./priceInputRenderer";
import TimepickerRenderer from "./timePickerRenderer";
import DatePickerRenderer from "./datePickerRenderer";
import NumberInputRenderer from "./numberInputRenderer";
import PercentageInputRenderer from "./percentageInputRenderer";
import CreatableSelectRenderer from "./creatableSelectRenderer";

export const formInputImplementations = [
    WysiwygInputRenderer,
    PriceInputRenderer,
    FormikInputRenderer,
    TimepickerRenderer,
    DatePickerRenderer,
    NumberInputRenderer,
    PercentageInputRenderer,
    CreatableSelectRenderer
];

const FormInputRendererInterface = new Interface('FormInput', 'supports', 'render');

export default FormInputRendererInterface;
