import React, {Component} from "react";
import {FormattedMessage} from "react-intl";

// @mui/material components
import {Grid, } from "@mui/material";
import {withStyles} from "@mui/styles";

// core components
import AverageProcessTime from "views/marketplace/dashboard/components/AverageProcessTime";

// styles
import dashboardStyle from "assets/jss/views/picking/dashboard/dashboardStyle";

class TimeBlock extends Component {
    render() {
        const {classes, number} = this.props;

        return (
            <Grid item className={classes.timeBlockContainer}>
                <div className={classes.timeBlockHeader}>
                    <FormattedMessage id={"picking.dashboard.averageProcessingTime"}/>
                </div>

                <div className={classes.timeBlockData}>
                    <div className={classes.timeCircle}>
                        <span className={classes.AverageProcessingTime}>
                            <AverageProcessTime seconds={number}/>
                        </span>
                    </div>
                </div>
            </Grid>
        );
    }
}

export default (withStyles(dashboardStyle)(TimeBlock));
