import React, {Component} from "react";
import {connect} from "react-redux";
import * as moment from "moment";

// core components
import StickyNotification from "../../components/notification/stickyNotification";

// actions
import {list} from "../../actions/physicalEntry/list";

// helpers
import {Authorizations, hasAuthorization} from "../../utils/authorizations";

export default function (ComposedComponent) {
    class PhysicalEntryHandler extends Component {
        state = {
            showNotification: false,
        };

        componentDidMount() {
            if (this.props.match.params.id) {
                let today = moment(new Date());
                this.props.list(`retailer.id=${decodeURIComponent(this.props.match.params.id)}&date=${today.format("YYYY-MM-DD")}`)

                if (this.props.retrieved) {
                    const date = this.props.retrieved["hydra:member"][0] && moment(this.props.retrieved["hydra:member"][0]["date"]);

                    this.setState({showNotification: (this.props.retrieved["hydra:totalItems"] === 0) || (today.format("YYYY-MM-DD") !== date.format("YYYY-MM-DD"))});
                }
            }
        }

        UNSAFE_componentWillReceiveProps(nextProps) {
            if (nextProps.retrieved) {
                const today = moment(new Date());
                const date = nextProps.retrieved["hydra:member"][0] && moment(nextProps.retrieved["hydra:member"][0]["date"]);

                this.setState({showNotification: (nextProps.retrieved["hydra:totalItems"] === 0) || (today.format("YYYY-MM-DD") !== date.format("YYYY-MM-DD"))});
            }
        }

        render() {
            return (
                <div>
                    {(hasAuthorization(this.props.authorizations, Authorizations.MARKETPLACE_PHYSICALENTRY_MANAGEMENT) && this.state.showNotification) && <StickyNotification {...this.props}/>}<ComposedComponent {...this.props} />
                </div>
            );
        }
    }

    const mapStateToProps = (state) => {
        return {
            retrieved: state.physicalEntry.list.retrieved,
            authorizations: state.authentication.authorizations
        };
    };

    const mapDispatchToProps = dispatch => ({
        list: params => dispatch(list(params))
    });

    return connect(mapStateToProps, mapDispatchToProps)(PhysicalEntryHandler);
}
