import {fetch} from "../../utils/dataAccess";

export function error(error) {
    return {type: "NOTIFICATION_DELETE_ALL_ERROR", error};
}

export function loading(loading) {
    return {type: "NOTIFICATION_DELETE_ALL_LOADING", loading};
}

export function success(success) {
    return {type: "NOTIFICATION_DELETE_ALL_SUCCESS", success};
}

export function delAll(member) {
    return dispatch => {
        dispatch(loading(true));
        dispatch(success(false));

        return fetch(`/notifications/delete_all?member=${member}`, {method: "GET"})
            .then(() => {
                dispatch(loading(false));
                dispatch(success(true));
            })
            .catch((e) => {
                dispatch(loading(false));
                dispatch(error(e.message));
            });
    }
};
