import React, {useContext} from 'react'
import {FormattedMessage} from "react-intl";
import classNames from "classnames";

import {makeStyles} from "@mui/styles";

import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import CustomCheckbox from "components/checkbox/CustomCheckbox";

import {defaultFont, lighterBlack} from "assets/jss/main";

import {OrderContext} from "contexts/OrderContext";

import {LineItemStatus} from "utils/LineItemStatus";

const useStyle = makeStyles({
    tableHead: {
        background: lighterBlack,
    },
    tableHeadCell: {
        ...defaultFont,
        fontSize: '14px',
        lineHeight: "1.42857143",
        verticalAlign: "middle",
        color: '#FFFFFF',
        fontWeight: '700 !important',
        borderBottom: 'unset'
    },
    doubleHead: {
        display: 'flex',
        flexDirection: 'column',
        whiteSpace: 'nowrap'
    },
    tableHeadCellCenter: {
        textAlign: 'center'
    },
    checkbox: {
        padding: 0,
        textAlign: 'right',
        borderBottom: 'unset'
    },
})
export default function ShowTableHeader() {
    const classes = useStyle();

    const {
        lineItemSelectedIds,
        orderStatus,
        toShipLineItemLength,
        handleChangeCheckboxGlobal,
    } = useContext(OrderContext);

    return (
        <TableHead className={classes.tableHead}>
            <TableRow>
                <TableCell className={classes.checkbox}>
                    {orderStatus === LineItemStatus.TO_SHIP && (
                        <CustomCheckbox
                            checked={lineItemSelectedIds.length === toShipLineItemLength}
                            onChange={() => handleChangeCheckboxGlobal()}
                        />
                    )}
                </TableCell>
                <TableCell className={classes.tableHeadCell}>
                    <FormattedMessage id={"picking.order.show.table.header.item"} />
                </TableCell>
                <TableCell className={classes.tableHeadCell}>
                    <FormattedMessage id={"picking.order.show.table.header.name"} />
                </TableCell>
                <TableCell className={classes.tableHeadCell}>
                    <div className={classes.doubleHead}>
                        <span>
                            <FormattedMessage id={"picking.order.show.table.header.brand"} />
                        </span>
                        <span>
                            <FormattedMessage id={"picking.order.show.table.header.retailer"} />
                        </span>
                    </div>
                </TableCell>
                <TableCell className={classes.tableHeadCell}>
                    <div className={classes.doubleHead}>
                        <span>
                            <FormattedMessage id={"picking.order.show.table.header.price.selling"} />
                        </span>
                        <span>
                            <FormattedMessage id={"picking.order.show.table.header.price.origin"} />
                        </span>
                    </div>
                </TableCell>
                <TableCell className={classes.tableHeadCell}>
                    <FormattedMessage id={"picking.order.show.table.header.runner"} />
                </TableCell>
                <TableCell className={classNames(classes.tableHeadCell, classes.tableHeadCellCenter)}>
                    <FormattedMessage id={"picking.order.show.table.header.status"} />
                </TableCell>
                <TableCell className={classNames(classes.tableHeadCell, classes.tableHeadCellCenter)}>
                    <FormattedMessage id={"picking.order.show.table.header.actions"} />
                </TableCell>
                <TableCell className={classes.tableHeadCell} padding="checkbox"/>
            </TableRow>
        </TableHead>
    )
}
