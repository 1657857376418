import React, {Component} from "react";
import {withStyles} from "@mui/styles";

import QuoteListDatepicker from "./datepicker";
import QuoteListSearch from "./search";
import listStyle from "assets/jss/views/quote/listStyle";
class QuoteListHeader extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.header}>
                <QuoteListDatepicker
                    history={this.props.history}
                    onSelectDates={this.props.onSelectDates}
                />
                <QuoteListSearch
                    history={this.props.history}
                />
            </div>
        );
    }
}

export default withStyles(listStyle)(QuoteListHeader);
