import React, {useState, useEffect, useContext} from 'react';
import {FilterDispatchContext} from "contexts/filterContext";
import {renderChildrenTab} from "views/marketplace/merchandising/filter/tabFilterPanel";
import TabsContainer from "views/marketplace/merchandising/filter/tabsContainer";

export default function SubTabFilterPanel({items, allItems, selectedParent, lastSelected, setLastSelected}) {
    const [selected, setSelected] = useState(null);
    const [value, setValue] = useState(0);
    const dispatch = useContext(FilterDispatchContext);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleClick = (item) => {
        setSelected(item);
        setLastSelected(item);
        dispatch({
            type: 'filter_remove',
            item: selectedParent
        });

        const children = allItems.filter(child => child.parent && child.parent.id === item.id);
        children.forEach(child => {
            dispatch({
                type: 'filter_remove',
                item: child
            });
        });
    };

    function getParentIds(lastSelected) {
        const ids = [];
        ids.push(lastSelected.id);
        let parent = lastSelected.parent;
        while(parent !== null) {
            ids.push(parent.id)
            parent = parent.parent;
        }

        return ids;
    }

    useEffect(() => {

        setValue(0);
        setSelected(null);

        const parentIds = getParentIds(lastSelected);
        items.every((item, index) => {
            if (parentIds.find(id => id === item.id)) {
                setValue(index);
                setSelected(item);
                return false;
            }
            return true;
        })

    }, [selectedParent]);

    return (
        <TabsContainer
            items={items}
            handleClick={handleClick}
            value={value}
            handleChange={handleChange}
        >
            {selected && renderChildrenTab(selected, allItems, lastSelected, setLastSelected)}
        </TabsContainer>
    );
}
