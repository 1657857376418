import React from 'react';
import {IconButton, TextField} from '@mui/material';

// @mui/material components
import { injectIntl } from "react-intl";
import {makeStyles} from "@mui/styles";

// @mui/icons-material
import { Clear, Search as SearchIcon } from "@mui/icons-material";

const useStyles = makeStyles(theme => ({
    search: {
        paddingRight: "15px",
        "& input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button": {
            WebkitAppearance: "none",
            margin: 0
        },
        "& div": {
            height: "38px",
            backgroundColor: "white",
        },
        "& button": {
            padding: "2px"
        }
    },
}));

function Search (props) {
    const classes = useStyles();
    const { attributeName, search, setSearch } = props;

    return (
        <TextField
            id="search-input"
            placeholder={props.intl.formatMessage({id: `${attributeName}.search.placeholder`})}
            onChange={(event) => setSearch(event.target.value.toLowerCase())}
            value={search}
            variant="outlined"
            InputProps={{
                endAdornment: (
                    search.length > 0 ? (
                        <IconButton color="primary" onClick={() => setSearch('')} size="large">
                            <Clear />
                        </IconButton>
                        ) : <SearchIcon color={"disabled"} />
                ),
            }}
            className={classes.search}
            type={"text"}
        />
    );
}

export default injectIntl(Search);
