import React, { Component } from "react";
import {injectIntl} from "react-intl";
import CreatableSelect from 'react-select/creatable';
import {getIn} from "formik";

const selectStyle = {
    control: (baseStyles, state) => ({
        ...baseStyles,
        fontSize: 14,
        color: '#495057',
        border: '2px solid #eee',
        borderRadius: '4px',
        boxShadow: 'none',
        ':hover': {
            borderColor: '#eee',
        },
    }),
    indicatorSeparator: defaultStyles => ({
        display: 'none'
    }),
    dropdownIndicator: defaultStyles => ({
        color: '#495057',
        height: '15px',
        '& svg': {
            width: '13px',
            marginRight: '2px',
            height: '13px'
        }
    })
}

class CreatableSelectRenderer extends Component {
    static supports(props) {
        return ['creatableSelect'].includes(props.as ?? props.type);
    }

    formatValueOption(value) {
        if (value === '') {
            return null;
        }

        return [{
            'value': value,
            'label': value
        }]
    }

    render() {
        const { formikContext, name, children, intl, placeholder } = this.props;

        const tmpProps = {...this.props};
        delete tmpProps.containerClassName;
        delete tmpProps.fieldClassName;
        delete tmpProps.formikContext;

        const value = getIn(formikContext.values, name);

        tmpProps.value = this.formatValueOption(value);

        return (
            <CreatableSelect
                isClearable
                onChange={(field) => formikContext.setFieldValue(name, (field !== null ? field.value : ''))}
                options={children}
                formatCreateLabel={(name) => `${intl.formatMessage({id: "cancellation_reason.form.field.emailTemplate.addOption"})} ${name}`}
                placeholder={placeholder}
                styles={selectStyle}
                noOptionsMessage={() => intl.formatMessage({id: "cancellation_reason.form.field.emailTemplate.noOptions"})}
                {...tmpProps}
            />
        )
    }
}

export default injectIntl(CreatableSelectRenderer);
