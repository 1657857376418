import React from 'react';
import PropTypes from "prop-types";
import {injectIntl} from "react-intl";

import {createTheme} from "@mui/material/styles";

import Header from "../NoteBlock/Header";
import HistoryNotes from "../HistoryNotes";
import HistoryNoteDetail from "../HistoryNotes/HistoryNoteDetail";
import LastNoteContent from "../NoteBlock/LastNoteContent";
import NoteBlock from "../NoteBlock";
import NoteCardActions from "../NoteBlock/NoteCardActions";
import NoteCardContent from "../NoteBlock/NoteCardContent";
import NoteWillNotAppearMessage from "../Messages/NoteWillNotAppearMessage";

import {greenColor} from "assets/jss/main";

import isObject from "utils/isObject";

import {QUOTE_INTERNAL_NOTE} from "../../../../../enums/quoteNoteType";

InternalNote.propTypes = {
    internalNote: PropTypes.object.isRequired,
    internalNotes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

function InternalNote({internalNote = {}, internalNotes = [], ...props}) {
    const { intl } = props;

    if (!isObject(internalNote) || internalNotes.length === 0) {
        return false;
    }

    const titleWithVersion = (version) => (
        `${intl.formatMessage({id: 'picking.quote.history.internalNote'})} ${version}`
    )

    const internalNoteTheme = createTheme({
        palette: { primary: { main: greenColor } },
    });

    const internalNoteHistory = internalNotes.slice(1);

    return (
        <NoteBlock theme={internalNoteTheme}>
            <NoteCardContent>
                <Header
                    date={internalNote.createdAt}
                    subtitle={internalNote.member?.user.fullName}
                    title={titleWithVersion(internalNotes.length)}
                    noteType={QUOTE_INTERNAL_NOTE}
                    centerContent={<NoteWillNotAppearMessage />}
                />
                <LastNoteContent content={internalNote.note} />
            </NoteCardContent>
            {internalNoteHistory.length > 0 && (
                <NoteCardActions>
                    <HistoryNotes noteType={QUOTE_INTERNAL_NOTE}>
                        {internalNoteHistory.map((internalNote, index) => {
                            const version = internalNoteHistory.length - index;

                            return (
                                <HistoryNoteDetail
                                    key={index}
                                    date={internalNote.createdAt}
                                    subtitle={internalNote.member?.user.fullName}
                                    title={titleWithVersion(version)}
                                    content={internalNote.note}
                                />
                            )
                        })}
                    </HistoryNotes>
                </NoteCardActions>
            )}
        </NoteBlock>
    )
}

export default injectIntl(InternalNote);
