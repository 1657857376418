import React, { useEffect, useState } from "react";
import connect from "react-redux/es/connect/connect";
import {Redirect} from "react-router-dom";
import { FormattedMessage } from "react-intl";

// @mui/material components
import { Tooltip, Fab } from "@mui/material";

// @mui/icons-material
import { AddRounded } from "@mui/icons-material";

// core components
import Card from 'components/card/card';
import CardBody from 'components/card/cardBody';
import CardHeader from 'components/card/cardHeader';
import GridContainer from 'components/grid/gridContainer';
import GridItem from 'components/grid/gridItem';

// View components
import ListLoader from '../../configuration/components/listLoader';
import TableHead from './tableHead';
import TableBody  from "./tableBody";
import Loader from "./Loader";
import CancellationReasonDialog from "./CancellationReasonDialog";

// styles
import listStyle from "assets/jss/views/configuration/defaultListStyle";

// actions
import { list } from "api/cancellationReason/list";

// utils
import {Authorizations, hasAuthorization} from "../../../utils/authorizations";

// context
import {CancellationReasonListContext} from "contexts/CancellationReasonListContext";
import {withStyles} from "@mui/styles";

function List (props) {
    const {
        classes,
        authorizations,
    } = props;

    const [cancellationReasons, setCancellationReasons] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isListNeedToBeRefresh, setNeedToRefreshList ] = useState(false)
    const [isCancellationReasonDialogOpen, toggleCancellationReasonDialog] = useState(false);
    const [selectedCancellationReason, setSelectedCancellationReason] = useState(null);

    function openCancellationReasonDialog() {
        toggleCancellationReasonDialog(true);
    }

    function closeCancellationReasonDialog() {
        toggleCancellationReasonDialog(false);
    }

    function refreshCancellationReasonList() {
        setNeedToRefreshList(true);
    }

    useEffect(() => {
        if (isListNeedToBeRefresh) {
            setLoading(true);
            loadCancellationReasons();
        }

        setNeedToRefreshList(false);
    }, [isListNeedToBeRefresh])

    useEffect(() => {
        loadCancellationReasons();
    }, []);

    const loadCancellationReasons = () => {
        list('order_by_status=asc')
            .then(cancellationReasonsRetrieved => setCancellationReasons(cancellationReasonsRetrieved))
            .finally(() => setLoading(false))
        ;
    }

    if (false === hasAuthorization(authorizations, Authorizations.MARKETPLACE_CANCELLATION_REASON_MANAGEMENT)) {
        return <Redirect to={'/'} />;
    }

    return (
        <CancellationReasonListContext.Provider value={{
            isListNeedToBeRefresh,
            refreshCancellationReasonList,
            loading,
            setLoading,
            isCancellationReasonDialogOpen,
            openCancellationReasonDialog,
            closeCancellationReasonDialog,
            selectedCancellationReason,
            setSelectedCancellationReason,
            cancellationReasons
        }}
        >
            <GridContainer>
                <GridItem xs={12}>
                    <Card className={classes.card}>
                        <CardHeader color="info">
                            <GridContainer>
                                <GridItem xs={9} sm={10}>
                                    <h3>
                                        <FormattedMessage id={"cancellation_reason.title"} />
                                    </h3>
                                </GridItem>
                                <GridItem xs={3} sm={2} className={classes.mainActionsContainer}>
                                    <Fab color={"primary"}>
                                        <Tooltip title={<FormattedMessage id={"cancellation_reason.add"} />}>
                                            <AddRounded
                                                onClick={() => openCancellationReasonDialog()}
                                                fontSize={"large"}
                                            />
                                        </Tooltip>
                                    </Fab>
                                </GridItem>
                            </GridContainer>
                        </CardHeader>
                        <CardBody>
                            {loading && <Loader />}
                            <div className={classes.tableWrapper}>
                                {(!cancellationReasons) ? (
                                    <ListLoader loading={true} />
                                ): (
                                    <div>
                                        <TableHead/>
                                        <TableBody retrieved={cancellationReasons} />
                                    </div>
                                )}
                            </div>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
            <CancellationReasonDialog/>
        </CancellationReasonListContext.Provider>
    );
}

const mapStateToProps = (state) => {
    return {
        authorizations: state.authentication.authorizations,
    };
};

export default connect(mapStateToProps)(withStyles(listStyle)(List));
