import React, {useContext, useState} from 'react'
import {makeStyles} from '@mui/styles'
import {Button, Dialog, DialogActions, DialogContent} from '@mui/material'
import {FormattedMessage} from 'react-intl'
import PropTypes from 'prop-types'

import {borderGrayColor, dangerColor} from '../../../../../assets/jss/main'
import deleteRecommendation from '../../../../../api/recommendation/delete'
import {RecommendationContext} from '../../../context/RecommendationContext'

const useStyles = makeStyles(theme => ({
    paper: {
        borderRadius: '8px',
        padding: '24px',
        boxShadow: '0px 4px 22px 0px rgba(0, 0, 0, 0.04)',
        '& .MuiDialogContent-root': {
            padding: '0 0 32px 0',
        },
        '& .MuiDialogContent-root h2': {
            padding: '0 0 4px 0',
            margin: 0,
            color: '#333',
            fontSize: '20px',
            fontWeight: 600,
        },
        '& .MuiDialogContent-root p': {
            margin: 0,
            color: '#999',
            fontSize: '14px',
        },
        '& .MuiDialogActions-root': {
            padding: 0,
            justifyContent: 'flex-start',
        },
    },
    cancelButton: {
        padding: '12px',
        marginRight: '4px',
        color: '#333',
        fontSize: '14px',
        border: `1px solid ${borderGrayColor}`,
        borderRadius: '8px',
        textTransform: 'initial',
        '&:hover': {
            backgroundColor: '#F1F1F1',
        },
    },
    confirmButton: {
        padding: '12px',
        color: 'white',
        fontSize: '14px',
        backgroundColor: '#FF3737',
        border: `1px solid #FF3737`,
        borderRadius: '8px',
        textTransform: 'initial',
        '&:hover': {
            backgroundColor: '#FF8282',
            borderColor: '#FF8282',
        },
    },
    errorMessage: {
        color: dangerColor,
    }
}))

export default function ModalRemoveOverrodeRecommendation(props) {
    const {recommendation, open, onClose} = props
    const {refreshRecommendationList} = useContext(RecommendationContext)
    const [error, setError] = useState(false)
    const classes = useStyles()

    return (
        <Dialog
            open={open}
            onClose={onClose}
            slotProps={{ backdrop: { style: { backgroundColor: 'rgba(51, 51, 51, 0.20)' } } }}
            classes={{ paper: classes.paper }}
        >
            <DialogContent>
                <h2><FormattedMessage id={'recommendations.overrode_recommendations.modal_remove.title'} /></h2>
                <p>
                    <FormattedMessage id={'recommendations.overrode_recommendations.modal_remove.ask'} />
                    {error && (
                        <span className={classes.errorMessage}>
                            <br /><FormattedMessage id={'recommendations.overrode_recommendations.modal_remove.error'} />
                        </span>
                    )}
                </p>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={onClose}
                    className={classes.cancelButton}
                >
                    <FormattedMessage id={'recommendations.overrode_recommendations.modal_remove.cancel'} />
                </Button>
                <Button
                    onClick={() => {
                        deleteRecommendation(recommendation)
                            .then(() => {
                                refreshRecommendationList()
                                onClose()
                            })
                            .catch(() => setError(true))
                    }}
                    className={classes.confirmButton}
                >
                    <FormattedMessage id={'recommendations.overrode_recommendations.modal_remove.confirm'} />
                </Button>
            </DialogActions>
        </Dialog>
    )
}

ModalRemoveOverrodeRecommendation.propTypes = {
    recommendation: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
}
