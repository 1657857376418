import {fetch} from "../../utils/dataAccess";

export default async function addImages(values, files) {
    const imagesToUpload = {};
    Object.keys(files).forEach(locale => {
       if (!!files[locale] && files[locale] instanceof File) {
           imagesToUpload[locale] = files[locale];
       }
    });

    if (Object.keys(imagesToUpload).length === 0) {
        return values;
    }

    const promises = [];
    const localesOrder = [];
    Object.keys(imagesToUpload).forEach(locale => {
        let formData = new FormData();
        formData.append("file", imagesToUpload[locale]);
        formData.append("type", 'corner_translation_picture');
        promises.push(fetch("/media_objects", {
            method: "POST",
            body: formData
        }));
        localesOrder.push(locale);
    });

    const result = await Promise.all(promises);
    const responses = result.map(response => response.json());
    const mediaObjects = await Promise.all(responses);

    let i = 0;
    mediaObjects.forEach(mediaObject => {
        const locale = localesOrder[i];
        values.translations[locale].image = mediaObject['@id'];
        i++;
    });
    return values;
};
