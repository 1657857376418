import React from 'react';
import {FormattedMessage} from 'react-intl';
import {styled} from '@mui/material/styles';
import Button from '@mui/material/Button';
import {AddCircleOutline} from '@mui/icons-material';
import {
    infoColor,
    infoColorDisabled,
    infoColorHover,
} from 'assets/jss/main';

const StyledButton = styled(Button)({
    display: 'flex',
    gridArea: 'addProduct',
    padding: '12px 16px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
    alignSelf: 'stretch',
    borderRadius: '8px',
    background: infoColor,
    color: '#FFF',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
    textTransform: 'none',
    '&:hover': {
        background: infoColorHover,
    },
    '&.Mui-disabled': {
        color: '#FFF',
        background: infoColorDisabled,
    },
});

export default function AddANewProductButton({...props}) {
    return (
        <StyledButton
            {...props}
        >
            <AddCircleOutline />
            <FormattedMessage id={'picking.quote.detail.gridContent.addANewProduct'} />
        </StyledButton>
    );
}
