import React, {Component} from "react";

import {withStyles} from "@mui/styles";

import ItemsPerPageSelectStyle from "../../assets/jss/components/select/itemsPerPageSelectStyle";
import {MenuItem, OutlinedInput, Select} from "@mui/material";
import {UnfoldMore} from "@mui/icons-material";
import PropTypes from "prop-types";

class SimpleImagePerPageSelect extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: props.value ?? props.choices[0],
        };
    }

    render() {
        const { classes, onChange, choices } = this.props;

        return (
            <Select
                variant="standard"
                value={this.state.value}
                className={classes.customSelect}
                onChange={(event) => this.setState({value: event.target.value}, onChange(event.target.value))}
                IconComponent={UnfoldMore}
                variat="filled"
                input={<OutlinedInput />}>
                {choices.map(item => {
                    return (
                        <MenuItem
                            key={item}
                            value={item}
                            classes={{
                                root: classes.customMenuItem,
                                selected: classes.customMenuItemSelected
                            }}
                        >
                            {item}
                        </MenuItem>
                    )
                })}
            </Select>
        );
    }
}


SimpleImagePerPageSelect.propTypes = {
    onChange: PropTypes.func.isRequired,
};

SimpleImagePerPageSelect.defaultProps = {
    choices: ["5", "10", "25", "50", "100", "500"],
};

export default withStyles(ItemsPerPageSelectStyle)(SimpleImagePerPageSelect);
