import {fetch} from "utils/dataAccess";

export function updateError(updateError) {
    return {type: "PRODUCT_LOCK_UPDATE_ERROR", updateError};
}

export function updateLoading(updateLoading) {
    return {type: "PRODUCT_LOCK_UPDATE_LOADING", updateLoading};
}

export function updateSuccess(updateSuccess) {
    return {type: "PRODUCT_LOCK_UPDATE_SUCCESS", updateSuccess};
}

export function update(item, values) {
    if(item && values) {
        return dispatch => {
            dispatch(updateLoading(true));

            return fetch(item["@id"], {
                method: "PUT",
                headers: new Headers({"Content-Type": "application/ld+json"}),
                body: JSON.stringify(values)
            }).then(response =>
                response.json()
            ).then(({retrieved}) => {
                dispatch(updateSuccess(retrieved));
                dispatch(updateLoading(false));
            })
            .catch(e => {
                dispatch(updateLoading(false));
                dispatch(updateError(e.message));
            });
        };
    }
}

export function reset() {
    return dispatch => {
        dispatch(updateError(false));
        dispatch(updateSuccess(null));
        dispatch(updateLoading(null));
    };
}
