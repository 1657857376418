import React from "react";
import {Route} from "react-router-dom";

import RequireAuthentication from "hoc/authentication/requireAuthentication";
import * as Cookies from "js-cookie";

import Printers from "views/picking/printers";

let routes = [];
const applications = Cookies.get("_a") ? JSON.parse(Cookies.get("_a").toString()) : null;

if (applications && applications["picking"]) {
    routes.push([
        <Route
            path="/picking/printers"
            component={RequireAuthentication(Printers)}
            exact
            key="printers"
        />,
    ])
}

export default routes;
