import React, {useContext} from "react";
import {Tooltip, } from "@mui/material";
import {withStyles} from "@mui/styles";
import {injectIntl} from "react-intl";
import {Edit} from "@mui/icons-material";

import rowStyle from "../../../assets/jss/views/cancellationReason/rowStyle";
import {getTranslation} from "../../../domain/helpers/translations";
import {primaryColor} from "assets/jss/main";

// core components
import ActivationSwitch from "./ActivationSwitch";

// context
import {CancellationReasonListContext} from "contexts/CancellationReasonListContext";
import {getCancellationTransitionsThatNotRefund} from "../../../utils/lineItemTransitions"

function CancellationReasonRow(props) {
    const { cancellationReason, classes, intl } = props;

    const {
        openCancellationReasonDialog,
        setSelectedCancellationReason,
    } = useContext(CancellationReasonListContext)

    let status = `cancellation_reason.table.row.status.${cancellationReason.status}`;
    let transition = `cancellation_reason.table.row.transition.${cancellationReason.transition}`;
    let scope = "";
    if (cancellationReason.itemAllowed && cancellationReason.orderAllowed) {
        scope = intl.formatMessage({id: "cancellation_reason.table.row.scope.global"});
    } else {
        if (cancellationReason.itemAllowed) {
            scope = intl.formatMessage({id: "cancellation_reason.table.row.scope.item"});
        } else if (cancellationReason.orderAllowed) {
            scope = intl.formatMessage({id: "cancellation_reason.table.row.scope.order"});
        }
    }

    return (
        <div className={classes.row}>
            <span className={classes.two + ' ' + classes.bold}>{getTranslation(cancellationReason).name}</span>
            <span className={classes.two}>{intl.formatMessage({id: status})}</span>
            <span className={classes.two}>{intl.formatMessage({id: transition})}</span>
            <span className={classes.one}>{scope}</span>
            <span className={classes.two}>{getShouldTransferBack(cancellationReason)}</span>
            <div className={classes.one}>
                <ActivationSwitch cancellationReason={cancellationReason} />
            </div>
            <div className={classes.one} >
                <Tooltip
                    title={intl.formatMessage({id: "cancellation_reason.table.row.tooltip.edit"})}
                >
                    <Edit
                        onClick={() => {
                            setSelectedCancellationReason(cancellationReason);
                            openCancellationReasonDialog()
                        }}
                        style={{color: primaryColor, cursor: "pointer"}}
                    />
                </Tooltip>
            </div>
        </div>
    );

    function getShouldTransferBack(cancellationReason) {
        if (getCancellationTransitionsThatNotRefund().includes(cancellationReason.transition)) {
            return <span>{intl.formatMessage({id: "cancellation_reason.table.row.refund.noTransferBack"})}</span>
        }

        if (cancellationReason.shouldTransferBack) {
            return <span>{intl.formatMessage({id: "cancellation_reason.table.row.refund.shouldTransferBack"})}</span>
        } else {
            return <span>{intl.formatMessage({id: "cancellation_reason.table.row.refund.shouldNotTransferBack"})}</span>
        }
    }
}


export default withStyles(rowStyle)(injectIntl(CancellationReasonRow));
