import React, {useState, useEffect, useContext} from "react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeView from '@mui/lab/TreeView';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import {FormattedMessage} from "react-intl";
import CategoriesNav from "views/marketplace/merchandising/nav/categoriesNav";
import RetailersNav from "views/marketplace/merchandising/nav/retailersNav";
import CornersNav from "views/marketplace/merchandising/nav/cornersNav";
import {primaryColor} from "assets/jss/main";
import CircularProgress from '@mui/material/CircularProgress';
import {MerchandisingContext} from "contexts/merchandisingContext";

export default function Navigation({loadingHandler, loaded}) {

    const filter = useContext(MerchandisingContext);
    const [ expandedNodes, setExpandedNodes] = useState(['root']);
    const navIdCategory = '1';
    const navIdRetailer = '2';
    const navIdCorner = '3';

    useEffect(() => {
        setExpandedNodes(getNodesToExpand());
    }, [filter]);

    function isAncestorOfCurrentNavigationItem() {
        let currentItem = filter.navigationItem;
        if (currentItem === null) {
            return false;
        }
        while (currentItem !== null && currentItem !== undefined) {
            currentItem = currentItem.parent;
            if (currentItem === filter.expandingItem) {
                return true;
            }
        }
        return false;
    }

    function includeAllParents(selectedIds, item) {
        while (item.parent !== null) {
            item = item.parent;
            selectedIds.push(item.id);
        }
        return selectedIds;
    }

    function getNodesToExpand() {
        let selectedIds = Array.from(expandedNodes);

        let item = filter.expandingItem;
        if (item === null || item === undefined) {
            return selectedIds;
        }
        if (item !== filter.navigationItem) {
            if (!isAncestorOfCurrentNavigationItem()) {
                if (selectedIds.includes(item.id)) {
                    selectedIds.splice(selectedIds.indexOf(item.id));
                } else {
                    selectedIds.push(item.id);
                    selectedIds = includeAllParents(selectedIds, item);
                }
            }
        } else {
            if (selectedIds.includes(item.id)) {
                selectedIds.splice(selectedIds.indexOf(item.id));
            } else {
                if (filter.navigationType === 'categories') {
                    selectedIds.push(navIdCategory);
                } else if (filter.navigationType === 'retailers') {
                    selectedIds.push(navIdRetailer);
                } else if (filter.navigationType === 'corners') {
                    selectedIds.push(navIdCorner);
                }
                selectedIds.push(item.id);
            }
        }

        return selectedIds;
    }

    const accordionSummary = () => {
        return (
            <Typography sx={{ fontWeight: "bold" }}>
                <FormattedMessage id={"merchandising.show.label.nav.title"} />
            </Typography>
        );
    }

    const accordionDetails = () => {
        return (
            <AccordionDetails sx={{ borderTop: "1px solid rgba(0, 0, 0, .125)"}}>
                <TreeView
                    defaultCollapseIcon={<ExpandMoreIcon />}
                    defaultExpanded={['root']}
                    defaultExpandIcon={<ChevronRightIcon />}
                    expanded={expandedNodes}
                >
                    <CategoriesNav loadingHandler={loadingHandler} nodeId={navIdCategory} />
                    <RetailersNav loadingHandler={loadingHandler} nodeId={navIdRetailer} />
                    <CornersNav loadingHandler={loadingHandler} nodeId={navIdCorner} />
                </TreeView>
            </AccordionDetails>
        );
    }

    return (
        <Accordion defaultExpanded={true}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
            >
                {loaded ? accordionSummary()
                    : <CircularProgress sx={{color: primaryColor, marginX: 'auto'}} size={25} /> }
            </AccordionSummary>
            {loaded && accordionDetails()}
        </Accordion>
    );
}
