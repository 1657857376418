export default function normalizeTranslations(item) {
    if (!item.translations) {
        return item;
    }

    Object.entries(item.translations).forEach(([locale, translation]) => {
        if (!translation['@id']) {
            translation.locale = locale;
        }
    });

    return item;
};
