import React from 'react';
import PropTypes from 'prop-types';

import {styled} from "@mui/material/styles";

import {ReactComponent as UserSquare} from "assets/img/user-square.svg";
import {greyishBlue300} from "assets/jss/main";

UserSquareIcon.propTypes = {
    color: PropTypes.string,
    disabled: PropTypes.bool,
};

const StyledSvg = styled(UserSquare)(({ theme, color, disabled}) => ({
    'path': {
        ...color !== null && {
            stroke: color
        },
        ...disabled === true &&  {
            stroke: greyishBlue300
        }
    },
}));

export default function UserSquareIcon(props) {
    return <StyledSvg {...props} />
}
