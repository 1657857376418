import React, {useContext, useEffect, useState} from "react";
import {FormattedMessage} from "react-intl";
import {connect} from "react-redux";
import {BeatLoader} from "react-spinners";

// @mui/material components
import {
    Dialog,
    DialogActions,
    DialogContent
} from "@mui/material";

// core components
import ActionButtons from "./components/ActionButtons";
import CancellationReasonSelect from "./components/CancellationReasonSelect";
import UnavailableDaysForm from "./components/UnavailableDaysForm";

// context
import {CancellationReasonContext} from "contexts/CancellationReasonContext";
import {OrderContext} from "contexts/OrderContext";

// api
import {list as cancellationReasonList} from "api/cancellationReason/list";

// utils
import {Authorizations, hasAuthorization} from "utils/authorizations";
import {LineItemTransitions} from "utils/lineItemTransitions";

// style
import {backgroundAppColor, primaryColor} from "assets/jss/main";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles(theme => ({
    dialogContainer: {
        '& .MuiDialog-paper': {
            minWidth: '420px',
            background: backgroundAppColor,
            borderRadius: '10px',
            '& .MuiDialogContent-root' : {
                paddingLeft: '35px',
                paddingRight:' 35px',
            },
            '& .MuiDialogActions-root': {
                padding: '15px 35px 35px 35px'
            }
        },
    },
    loaderContainer: {
        textAlign: 'center'
    }
}));

function Index(props) {
    const classes = useStyles();

    const [cancellationReasons, setCancellationReasons] = useState([]);
    const [selectedReason, setReason] = useState('');
    const [dayCount, setDayCount] = useState(10);
    const [loading, setLoading] = useState(false);

    const {
        closeCancellationReasonDialog,
        isCancellationReasonDialogOpen,
        transitionActions,
        isItemAllowed,
        isOrderAllowed,
        status,
    } = useContext(OrderContext);

    useEffect(() => {
        setReason(null);
        setCancellationReasons([]);
        setLoading(true);

        if (transitionActions === null) {
            return;
        }

        const isPickingRetailer = hasAuthorization(props.authorizations, Authorizations.PICKING_RETAILER);
        const searchParams = new URLSearchParams();

        if (isItemAllowed) {
            searchParams.append('itemAllowed', true);
        }
        if (isOrderAllowed) {
            searchParams.append('orderAllowed', true);
        }
        if (isPickingRetailer) {
            searchParams.append('retailerAllowed', true);
        }

        searchParams.append('enabled', true);

        searchParams.append('status', status);
        let params = searchParams.toString();

        for (const transitionAction of transitionActions) {
            params += `&transition[]=${transitionAction}`
        }

        cancellationReasonList(params).then((res) => {
            setCancellationReasons(res)
            setLoading(false);
        });
    }, [transitionActions])

    function renderTitle() {
        let titleId = 'picking.order.show.cancellationReason.dialog.title';

        if (transitionActions && transitionActions.includes(LineItemTransitions.CONFIRM_RETURN)) {
            titleId = 'picking.order.show.cancellationReason.dialog.title.return';
        }
        if (transitionActions && transitionActions.includes(LineItemTransitions.CANCEL)) {
            titleId = 'picking.order.show.cancellationReason.dialog.title.cancel'
        }
        if (transitionActions && transitionActions.includes(LineItemTransitions.CANCEL_RETURNING_RUNNER)) {
            titleId = 'picking.order.show.cancellationReason.dialog.title.cancel_returning'
        }
        if (transitionActions && transitionActions.includes(LineItemTransitions.CANCEL_RETURNING_RETAILER)) {
            titleId = 'picking.order.show.cancellationReason.dialog.title.cancel_returning'
        }

        return <FormattedMessage id={titleId} />
    }

    return (
        <CancellationReasonContext.Provider value={{
            selectedReason,
            setReason,
            dayCount,
            setDayCount
        }}>
            <Dialog
                open={isCancellationReasonDialogOpen}
                onClose={() => closeCancellationReasonDialog()}
                aria-labelledby="form-dialog-title"
                className={classes.dialogContainer}
                scroll={"paper"}
                maxWidth={"sm"}
            >
                <DialogContent>
                        <p><b>{renderTitle()}</b></p>
                        {loading ? (
                            <div className={classes.loaderContainer}>
                                <BeatLoader
                                    sizeUnit={"px"}
                                    size={12}
                                    color={primaryColor}
                                />
                            </div>
                        ) : (
                            <CancellationReasonSelect cancellationReasons={cancellationReasons} />
                        )}
                        {selectedReason && selectedReason.overrideStock && (
                            <UnavailableDaysForm />
                        )}
                </DialogContent>
                <DialogActions className={classes.actionsContainer}>
                    <ActionButtons />
                </DialogActions>
            </Dialog>
        </CancellationReasonContext.Provider>
    )
}

const mapStateToProps = state => ({
    authorizations: state.authentication.authorizations
});

export default connect(
    mapStateToProps,
    null
)(Index);
