import React from 'react'

import makeStyles from "@mui/styles/makeStyles";

const useStyle = makeStyles({
    tableCellBold: {
        fontSize: "16px",
        fontWeight: "bold",
    }
})
export default function StyledSpanCellBold({children}) {
    const classes = useStyle();

    return (
        <span className={classes.tableCellBold}>
            {children}
        </span>
    )
}
