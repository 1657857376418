import React from "react";
import {makeStyles} from "@mui/styles";

import CardBody from "components/card/cardBody";

const useStyles = makeStyles(theme => ({
    cardStatsBody: {
        padding: "15px 15px"
    },
}));

export default function CardStatsBody({...props}) {
    const classes = useStyles();

    const { children } = props;

    return (
        <CardBody className={classes.cardStatsBody}>
            {children}
        </CardBody>
    )
}
