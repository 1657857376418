import React, { Component } from 'react';
import PropTypes from "prop-types";
import {FormattedMessage, injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import {Field} from "redux-form";
import Dropzone from "react-dropzone";

// @mui/material components
import {withStyles} from "@mui/styles";
import { CircularProgress} from "@mui/material";
import {AddPhotoAlternateOutlined, Delete} from "@mui/icons-material";

// styles
import imageSliderStyle from "assets/jss/views/product/imageSliderStyle";

// helpers
import {renderS3Url} from "utils/renderS3Url";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";

class ImageSlider extends Component {

    removePicture = (id) => {
        if (window.confirm(this.props.intl.formatMessage({id: "product.form.action.media.remove"}))) {
            this.props.removeFile(id);
        }
    };

    onDragEnd(result) {
        if (result.destination) {
            this.props.sortPictures(result.draggableId, result.source.index, result.destination.index);
        }
    }


    render() {
        const { classes, images } = this.props;

        const renderDropzoneInput = (field) => {
            return (
                <div>
                    <div className={classes.pictureContainer}>
                        {this.props.uploadLoading ?
                            <CircularProgress className={classes.pictureLoading}/> :
                            <div className={classes.picturePlaceholderContent}>
                                <AddPhotoAlternateOutlined fontSize={"large"} className={classes.pictureIcon}/>
                                <span className={classes.pictureText}>
                                    <FormattedMessage id={"product.form.action.upload"}/>
                                </span>
                            </div>
                        }
                        <Dropzone
                            name={field.input.name}
                            accept="image/*"
                            multiple={false}
                            className={classes.dropzoneUserImage}
                            onDrop={(filesToUpload) => this.props.upload(filesToUpload, "product_picture", this.props.currentVariation["id"], images.length+1)}
                        >
                            {({getRootProps, getInputProps}) => {
                                return (
                                    <div
                                        {...getRootProps()}
                                    >
                                        <input {...getInputProps()} />
                                    </div>
                                )
                            }}
                        </Dropzone>
                        {field.meta.touched &&
                        field.meta.error &&
                        <span className="error">{field.meta.error}</span>}
                    </div>
                </div>
            );
        };

        return (
            <div className={classes.dropZonePicture}>
                <Field name={"file"} component={renderDropzoneInput}/>
                <DragDropContext onDragEnd={(res) => this.onDragEnd(res)}>
                    <Droppable droppableId={"list"} direction={"horizontal"}>
                            {provided => (
                                <div
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                    className={classes.draggableContainer}
                                >
                                    {images.map((item, i) => {
                                        return (
                                            <Draggable key={item.contentUrl} index={i} draggableId={item.contentUrl}>
                                                {provided => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        className={classes.imageContainer}
                                                    >
                                                        {item.contentUrl.substring(0, 4) !== "http" ?
                                                            <img className={classes.image} src={renderS3Url(this.props.organizations, this.props.member, "product", item.contentUrl)} alt=""/>
                                                            :
                                                            <img className={classes.image} src={item.contentUrl} alt=""/>
                                                        }
                                                        <Delete className={classes.slickRemove} onClick={() => {this.removePicture(item["@id"])}}/>
                                                    </div>
                                                )}
                                            </Draggable>
                                        );
                                    })}
                                </div>
                            )}
                    </Droppable>
                </DragDropContext>
            </div>
        )
    }
}

ImageSlider.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
    images: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const mapStateToProps = (state) => {
    return {
        organizations: state.authentication.organizations,
        member: state.authentication.member
    }
};

export default connect(
    mapStateToProps,
    null
)(withStyles(imageSliderStyle)(injectIntl(ImageSlider)));
