import {fetch} from "../../utils/dataAccess";

export default function (idQuote) {
    return new Promise((resolve, reject) => {
        fetch(`/quotes/${idQuote}/refuse`, {
            method: 'PATCH'
        })
            .then(response => {
                response.json().then(response => {
                    resolve(response);
                });
            })
            .catch(error => reject(error));
    });
}
