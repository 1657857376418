import React from "react";

import {Button} from "@mui/material";

import {styled} from '@mui/material/styles';
import {borderGrayColor, whiteColorHover} from "assets/jss/main";

const StyledButton = styled(Button)({
    backgroundColor: '#ffffff',
    borderRadius: '8px',
    textTransform: 'none',
    borderColor: borderGrayColor,
    color: '#333',
    '&:hover': {
        backgroundColor: whiteColorHover,
        borderColor: borderGrayColor,
    },
    '&.Mui-disabled' : {
        borderColor: borderGrayColor,
        backgroundColor: borderGrayColor,
        color: '#fff',
    }
})

export default function CancelButton({...props}) {
    return (
        <StyledButton
            variant="outlined"
            disableElevation
            {...props}
        >
            {props.children}
        </StyledButton>
    )
}
