import React from "react";
import QuoteDetailHeaderInfos from "./headerInfos";
import QuoteDetailHeaderCustomerInfos from "./headerCustomerInfos";
import QuoteDetailHeaderActions from "./headerActions";
import GridItem from "../../../../../components/grid/gridItem";
import GridContainer from "../../../../../components/grid/gridContainer";
import QuoteDetailHeaderAddresses from "./headerAddresses";
import detailStyle from "../../../../../assets/jss/views/quote/detailStyle";
import {withStyles} from "@mui/styles";

const QuoteDetailHeader = ({ classes }) => {

    return (
        <GridContainer>
            <GridItem md={6}>
                <QuoteDetailHeaderInfos/>
                <QuoteDetailHeaderAddresses/>
            </GridItem>
            <GridItem md={6} className={classes.headerRight}>
                <QuoteDetailHeaderCustomerInfos/>
                <QuoteDetailHeaderActions/>
            </GridItem>
        </GridContainer>
    );
};

export default withStyles(detailStyle)(QuoteDetailHeader);
