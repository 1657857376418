import React from "react";
import {connect} from "react-redux";
import {FormattedDate, FormattedMessage} from "react-intl";
import {BeatLoader} from 'react-spinners';

// @mui/material components
import {Dialog, DialogTitle, DialogContent, IconButton, } from "@mui/material";
import {withStyles} from "@mui/styles";

// @mui/icons-material
import {Edit, DeleteOutline} from "@mui/icons-material";

// styles
import showStyle from "assets/jss/views/roles/showStyle";
import {primaryColor} from "assets/jss/main";

// actions
import {deleteRole} from "actions/role/delete";
import {list as listRoles} from "actions/role/list";

// helpers
import {capitalize} from "utils/capitalize";

class ShowDialog extends React.Component {
    render() {
        const {classes, closeDialog, showDialog, role, outils, deleteLoading} = this.props;

        return (
            <Dialog
                open={showDialog}
                onClose={closeDialog}
                aria-labelledby="responsive-dialog-title"
                fullWidth={true}
                maxWidth={'sm'}
            >
                <DialogTitle id="max-width-dialog-title">
                    <div className={classes.dialogTitleContainer}>
                        {role && role.name && capitalize(role.name)}
                        {deleteLoading ?
                        <BeatLoader
                            sizeUnit={"px"}
                            size={12}
                            color={primaryColor}
                            loading={deleteLoading}
                        />
                        : role && !role.defaultRole &&
                            <div className={classes.actionsContainer}>
                            <IconButton color="primary" onClick={() => this.props.openEditDialog()} size="large">
                                <Edit/>
                            </IconButton>
                            <IconButton color="primary" onClick={() => this.props.deleteRole(role)} size="large">
                                <DeleteOutline color="primary"/>
                            </IconButton>
                        </div>
                        }
                    </div>
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <div className={classes.labelsDataContainer}>
                        <span className={classes.labelsData}><FormattedMessage id={"role.show.body.id"}/></span>
                        <span>{role && role['@id']}</span>
                    </div>
                    <div className={classes.labelsDataContainer}>
                        <span className={classes.labelsData}><FormattedMessage id={"role.show.body.outil"}/></span>
                        {outils}
                    </div>
                    <div className={classes.labelsDataContainer}>
                        <span className={classes.labelsData}><FormattedMessage id={"role.show.body.creation.date"}/></span>
                        <span>
                            {role &&
                            <FormattedDate
                                value={new Date(role["createdAt"])}
                                year={"numeric"}
                                month={"numeric"}
                                day={"numeric"}
                                hour={"numeric"}
                                minute={"numeric"}
                            />}
                        </span>
                    </div>
                    {role && role['authorizations'] && role['authorizations'].length > 0 &&
                    <div className={classes.authorizationContainer}>
                        <span className={classes.labelsData}><FormattedMessage id={"role.show.footer.title"}/></span>
                        {role['authorizations'].map(item => {
                            return <span key={item["@id"]}> {`- ${item.name}`}</span>
                        })}
                    </div>
                    }
                </DialogContent>
            </Dialog>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    deleteRole: item => dispatch(deleteRole(item)),
    listRoles: params => dispatch(listRoles(params)),
});

export default connect(
    null,
    mapDispatchToProps,
)(withStyles(showStyle)(ShowDialog));
