import {fetch} from "utils/dataAccess";

export function handleFileUpload(file, type, object_id) {
    return new Promise((resolve, reject) => {
        if (file[0].size > 20971520) {
            reject("product.form.validation.file.maxSizeExceed");
            return;
        }

        let formData = new FormData();
        formData.append("file", file[0]);
        formData.append("type", type);

        if (typeof object_id !== "undefined") {
            formData.append("object_id", object_id);
        }

        fetch('/media_objects', {
            method: "POST",
            body: formData
        }).then(response => {
            response.json().then(response => {
                resolve(response);
            });
        }).catch(error => {
            reject(error)
        });
    });
}

export function removeFile(file_id) {
    return new Promise((resolve, reject) => {
        fetch(file_id, {
            method: "DELETE"
        }).then(response => {
            resolve(response);
        }).catch(error => {
            reject(error)
        });
    });
}
