import {
    container,
    defaultFont,
    primaryColor,
} from "../../../../main";

const indexStyle = theme => ({
    containerWithSidebar: {
        [theme.breakpoints.up("md")]: {
            marginLeft: "240px",
        }
    },
    container: {
        ...container,
        ...defaultFont
    },
    cardHeader: {
        borderRadius: 0,
        padding: 0,
    },
    fieldLabel: {
        color: primaryColor,
        fontWeight: "bold",
    },
    textField: {
        marginBottom: "1.6rem",
        '& label': {
            color: primaryColor,
            fontSize: "1.2rem",
            top: "-12px",
            left: "-13px",
        },
        '> legend': {
            display: 'none',
        }
    },
    error:{
        color: "red"
    },
    buttonContainer: {
        display: "flex",
        justifyContent: "end",
    },
    movementSelectWebcamContainer: {
        position: 'relative',
        bottom: '150px'
    },
    notificationWebcamContainer: {
        position: 'fixed',
        top: '100px',
        right: '0px',
        left: 0,
        marginLeft: '20px',
        marginRight: '20px',
    }
});

export default indexStyle;
