import React, {useEffect, useState} from "react";
import {withStyles} from "@mui/styles";
import AuthenticationStyle from "../../../assets/jss/views/authentication/authenticationStyle";
import CompleteProfileForm from "./forms/completeProfileForm";
import {InvitationContext} from "../../../contexts/invitationContext";
import InvitationMethodForm from "./forms/invitationMethodForm";
import get from "../../../api/authentication/invitation/get.js";
import {useParams} from "react-router-dom";
import {ClipLoader} from "react-spinners";
import {FormattedMessage} from "react-intl";
import {Warning} from "@mui/icons-material";


const AuthenticationAcceptInvitationPage = ({classes}) => {
  const [invitedMember, setInvitedMember] = useState();
  const [method, setMethod] = useState();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  let { token } = useParams();

  const changeMethod = (method) => {
    if (method === 'google') {
      // REDIRECT TO GOOGLE AUTH
      alert('Redirect to google');
      return;
    }
    setMethod(method);
  }

  const fetchMember = () => {
    get(
      token
    ).then(invitedMember => {
      setInvitedMember(invitedMember);
    }).catch(() => {
      setError(true);
    }).finally(() => {
      setLoading(false);
    });
  }

  useEffect(() => {
    fetchMember();
  }, []);

  const getContent = () => {
    if (error) {
      return <div className={classes.errorContainer}>
        <Warning className={classes.errorIcon}/>
        <div className={classes.errorMessage}>
          <FormattedMessage id={'authentication.invitation.errorDuringRetrieveInvitation'}/>
        </div>
      </div>;
    }

    if (loading) {
      return <ClipLoader/>;
    }

    switch (method) {
      case 'email':
        return <CompleteProfileForm/>;
      default:
        return <InvitationMethodForm/>;
    }
  }

  return (
    <InvitationContext.Provider value={{
      invitedMember,
      changeMethod,
    }}>
      {getContent()}
    </InvitationContext.Provider>
  );
};

export default withStyles(AuthenticationStyle)(AuthenticationAcceptInvitationPage);
