export const palette = [
    '#3eabca',
    '#5554b2',
    '#c83f83',
    '#ffc000',
    '#72d372',
    '#a871ff',
    '#2500fd',
    '#d481ad',
    '#ff717e',
    '#5cbfa4',
    '#ff9f71',
    '#732c46',
    '#bbb79b',
    '#080a44',
    '#1bbb87',
    '#fc5c65',
    '#a55eea',
    '#f7b731',
    '#2bcbba',
    '#2d98da',
    '#fed330',
    '#84817a',
    '#2c2c54',
    '#aaa69d',
    '#218c74'
];

export const defaultOptions = {
    cutoutPercentage: 70,
    responsive: true,
    maintainAspectRatio: false,
    legend: {
        display: false
    },
    tooltips: {
        callbacks: {
            label: function (tooltipItem, data) {
                let dataset = data.datasets[tooltipItem.datasetIndex];
                let index = tooltipItem.index;
                return dataset.labels[index] + ': ' + dataset.data[index];
            },
        },
    }
}
