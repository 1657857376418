import React, { Component } from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {BeatLoader} from 'react-spinners';
import * as Cookies from "js-cookie";
import {AWS_BASE_URL, AWS_BUCKET} from "../config/_aws";
import {connect} from "react-redux";

// core components
import GridContainer from "components/grid/gridContainer";
import GridItem from "components/grid/gridItem";
import Card from "components/card/card";
import Button from "components/button/button";

// @mui/material components
import {
    CardContent
} from "@mui/material";

// style
import invitationStyle from "assets/jss/views/invitationStyle.js";

// utils
import {fetch} from "utils/dataAccess";
import {infoColor} from "../assets/jss/main";
import {paramsToObject} from "../utils/paramsToObject";
import { putSessionOrganizations, getSessionOrganizations } from "../actions/authentication";
import {withStyles} from "@mui/styles";

export function success(userData) {
    return {type: "LOGIN_SUCCESS", userData};
}

class Invitation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            acceptLoading: false,
            declineLoading: false
        };

        this.sendInvitationResponse = this.sendInvitationResponse.bind(this);
    }

    matchInvitationWithOrgaName(invitations, organizationName) {
        if (invitations) {
            for (let i = 0; i < invitations.length; i++) {
                if (invitations[i].organization.name === organizationName) {
                    return invitations[i];
                }
            }

            return false;
        }

        return false;
    }

    componentDidMount() {
        let params = paramsToObject(this.props.match.params.params);

        if (params.hasOwnProperty('name')
            && params.hasOwnProperty('marketplace')
            && params.hasOwnProperty('picking')
            && params.hasOwnProperty('personalShopper')) {
            this.setState({
                params: params
            });
        }

        if (Cookies.get("_s")) {
            const data = JSON.parse(Cookies.get("_s").toString());

            this.setState({
                email: data.email,
                token: data.token,
                invitation: data.invitations[0]
            })
        } else if (getSessionOrganizations()) {
            const data = getSessionOrganizations();
            const invitation = this.matchInvitationWithOrgaName(data.invitations, params.name)
            if (!data.member || !invitation) {
                this.props.history.push('/signup');
            } else {
                this.setState({
                    email: data.member.email,
                    token: data.token,
                    invitation: invitation
                })
            }
        } else {
            this.handleClickRedirection();
        }
    }

    sendInvitationResponse(join) {
        if (join) {
            this.setState({acceptLoading: true})
        } else {
            this.setState({declineLoading: true})
        }

        let data = {
            "organization": this.state.invitation.organization.id,
            "invitationEmail": this.state.email,
        };

        let url = join ? '/users/invite/accept' : '/users/invite/decline';

        fetch (url, {
            method: 'PUT',
            headers: new Headers({"authorization": "Bearer " + this.state.token}),
            body: JSON.stringify(data)
        }).then(response => {
            return response.json();
        }).then(() => {
            this.setState({
                acceptLoading: false,
                declineLoading: false
            });

            if (join && Cookies.get("_d0")) {
                fetch("/login_sync", {method: "GET", headers: new Headers({"authorization": "Bearer " + this.state.token})})
                    .then(response => {
                        return response.json();
                    }).then(data => {
                        putSessionOrganizations(data["syncedData"]);
                        this.props.success(data["syncedData"]);
                });
            }

            if (Cookies.get('_s')) {
                Cookies.remove('_s', {domain: process.env.REACT_APP_HOST});
            }
            this.handleClickRedirection();
        }).catch(() => {
            this.setState({
                acceptLoading: false,
                declineLoading: false
            });
        });
    };

    handleClickRedirection = () => {
        this.props.history.push('/');
    };

    renderS3Url = (id, name, image) => {
        return AWS_BASE_URL + AWS_BUCKET + id + "_" + name + "/retailer/" + image
    };

    renderButton = () => {
        const {classes} = this.props;

        if (this.state.invitation) {
            return (
                <GridContainer direction="row" justifyContent="center" alignItems="center">
                    <GridItem xs={6}>
                        <Button
                            color={"info"}
                            className={classes.accept}
                            onClick={() => this.sendInvitationResponse(true)}
                        >
                            <FormattedMessage id={"login.invitation.accept"}/>
                            {this.state.acceptLoading && <BeatLoader sizeUnit={"px"} size={8} color={"#FFF"} loading={this.state.acceptLoading}/>}
                        </Button>
                    </GridItem>
                    <GridItem xs={6}>
                        <Button
                            color={"white"}
                            className={classes.cancel}
                            onClick={() => this.sendInvitationResponse(false)}
                        >
                            <FormattedMessage id={"login.invitation.cancel"}/>
                            {this.state.declineLoading && <BeatLoader sizeUnit={"px"} size={8} color={infoColor} loading={this.state.declineLoading}/>}
                        </Button>
                    </GridItem>
                </GridContainer>
            )
        } else if (this.state.params) {
            return (
                <GridContainer direction="row" justifyContent="center" alignItems="center">
                    <GridItem xs={12}>
                        <Button
                            color={"info"}
                            className={classes.connexion}
                            onClick={() => this.handleClickRedirection()}
                        >
                            <FormattedMessage id={"login.invitation.connexion"}/>
                        </Button>
                    </GridItem>
                </GridContainer>
            );
        } else return null
    };

    renderApplications = (applications) => {
        const {classes} = this.props;

        return (
            <div>
                <p>
                    <FormattedMessage id={"login.invitation.application"}/>
                </p>
                <ul className={classes.applications}>
                    {applications && Object.keys(applications).map((app) => {
                        if (applications[app] === true) {
                            return (
                                <li key={app}>
                                    <FormattedMessage id={`login.invitation.application.${app}`}/>
                                </li>
                            )
                        }
                    })}
                </ul>
            </div>
        );
    };

    renderContent = (organizationId, organizationName, logo) => {
        const {classes} = this.props;

        return (
            <div>
                <p>
                    <FormattedMessage id={"login.invitation.organization"}/>
                </p>
                <p>
                {logo ?
                    <img className={classes.logo} alt={"organization"} src={this.renderS3Url(organizationId, organizationName, logo)}/>
                    :
                    <span className={classes.organization}>
                        {organizationName}
                    </span>
                }
                </p>
                <p>
                    <FormattedMessage id={"login.invitation.organization.invite"}/>
                </p>
            </div>
        );
    };

    render() {
        const {classes} = this.props;
        const {invitation, params} = this.state;

        return(
            <div className={classes.container}>
                <GridContainer direction="row" justifyContent="center" alignItems="center">
                    <Card className={classes.card}>
                        <CardContent className={classes.cardContent}>
                            <GridContainer>
                                <GridItem xs={12} className={classes.title}>
                                    <FormattedMessage id={"login.invitation.title"}/>
                                </GridItem>
                                <GridItem xs={12} className={classes.content}>
                                    {invitation ?
                                        this.renderContent(invitation.organization["id"], invitation.organization["name"], invitation.organization.logo)
                                        :
                                        params && this.renderContent(params["id"], params["name"], params["logo"])
                                    }
                                </GridItem>
                                <GridItem xs={12} className={classes.access}>
                                    {invitation ?
                                        this.renderApplications(invitation.applications)
                                        :
                                        params && this.renderApplications({marketplace: params["marketplace"], picking: params["picking"], personalShopper: params["personalShopper"], stockEasy: params["stockEasy"]})
                                    }
                                </GridItem>
                                <GridItem xs={12} className={classes.actions}>
                                    {this.renderButton()}
                                </GridItem>
                            </GridContainer>
                        </CardContent>
                    </Card>
                </GridContainer>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        success: (userData) => dispatch(success(userData)),
    };
};

export default  connect(null, mapDispatchToProps)(withStyles(invitationStyle)(injectIntl(Invitation)));
