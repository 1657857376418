import React, {Component} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {FormattedMessage, injectIntl} from "react-intl";
import {DateRangePicker} from 'react-dates';
import * as moment from "moment";
import {ITEM_PER_PAGE} from "config/_pagination";

import ReactToPrint from "react-to-print";
import PrintIcon from "@mui/icons-material/Print";
import PickingList from "./pickingList";

// @mui/material components
import {
    TextField,
    IconButton,
    Button,
    Tabs,
    Tab,
    Chip,
} from "@mui/material"

// @mui/icons-material
import {
    NotificationsActive,
    Clear,
    Search,
    RemoveRounded,
    AddRounded,
} from "@mui/icons-material";

// core components
import GridContainer from "components/grid/gridContainer";
import GridItem from "components/grid/gridItem";
import Card from "components/card/card";
import CardHeader from "components/card/cardHeader";
import CardBody from "components/card/cardBody";
import Table from "./showTable";
import Snackbar from "components/snackbar/snackbar";

// styles
import showStyle from "assets/jss/views/picking/order/showStyle";

// actions
import {list, reset} from "actions/lineItem/retailerList";
import {retrieve} from "actions/retailer/show";
import {confirm, assign} from "actions/lineItem/update";
import { list as organizationShippingMethodsList } from "api/shippingMethod/list";

// helpers
import {capitalize} from "utils/capitalize";
import abort from "utils/abort";
import {sortOn} from "../../../utils/sortOn";
import {withStyles} from "@mui/styles";
import {paramsToObject} from "../../../utils/paramsToObject";

class Order extends Component {
    static propTypes = {
        loading: PropTypes.bool.isRequired,
        error: PropTypes.string,
        list: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            open: false,
            createErrorNotification: true,
            startDate: null,
            endDate: null,
            initialStartDate: null,
            initialEndDate: null,
            name: "",
            page: 1,
            itemsPerPage: this.props.itemsPerPage !== null ? this.props.itemsPerPage : ITEM_PER_PAGE,
            hideFilter: true,
            filterMenuSelected: "ShippingMethod",
            selectedFilters: [],
            organizationShippingMethods: [],
        };
    };

    componentDidMount() {
        const {params} = this.props.match.params

        this.props.list(`${decodeURIComponent(params)}`);

        organizationShippingMethodsList(`retailersIds=${this.props.match.params.id}`).then((res) => {
            this.setState({
                organizationShippingMethods: res
            })
        })

        this.props.retrieve(`/retailers/${this.props.match.params.id}`)
        this.updateStoreParameters(params);
    }

    componentWillUnmount() {
        this.props.reset();
        abort.abortRequests();
    };

    UNSAFE_componentWillReceiveProps(nextProps) {

        if (nextProps.lineItem.success) {
            nextProps.lineItem.success = false;
            const {params} = this.props.match.params;
            this.props.list(`${decodeURIComponent(params)}`);
            this.updateStoreParameters(decodeURIComponent(params).split("&"));
        }

        this.setState({createErrorNotification: true})

        if (this.props.match.params.params !== nextProps.match.params.params) {
            window.scrollTo(0, 0);
            nextProps.list(`${decodeURIComponent(nextProps.match.params.params)}`);
            this.updateStoreParameters(nextProps.match.params.params);
        }
    }

    updateStoreParameters(params) {
        this.setState(paramsToObject(params));

        params = new URLSearchParams(decodeURIComponent(params));
        let entries = params.entries();
        for (let entry of entries) {
            let [key, value] = entry;
            if ('createdAt[after]' === key) {
                this.setState({'startDate': moment(value, 'YYYY-MM-DD H:i:s')});
            }
            if ('createdAt[before]' === key) {
                this.setState({'endDate': moment(value, 'YYYY-MM-DD H:i:s')});
            }
        }
    };

    checkIfRunnerExist = (lineItems) => {
        for (let i = 0; i < lineItems.length; i++) {
            if (lineItems[i].runner !== null) {
                return true
            }
        }

        return false;
    };

    handlePageReset = (params) => {
        params = params.split("&");

        params.map((param, key) => {
            if (param.search("page") !== -1) {
                params[key] = "page=1";
            }

            return true;
        });

        return params;
    };

    handleDateChange(dates) {
        let params = this.handlePageReset(decodeURIComponent(this.props.match.params.params));
        params = params.join('&');
        params = new URLSearchParams(params);

        let startDate = dates.startDate?.clone();
        let endDate = dates.endDate?.clone();

        if (null !== dates.endDate) {
            params.set('createdAt[before]', endDate.endOf('day').format("YYYY-M-D HH:mm:ss"));
        } else {
            params.delete('createdAt[before]');
        }
        if (null !== dates.startDate) {
            params.set('createdAt[after]', startDate.startOf('day').format("YYYY-M-D HH:mm:ss"));
        } else {
            params.delete('createdAt[after]');
        }
        this.props.history.replace(`/picking/retailers/${this.props.match.params.id}/order/${encodeURIComponent(`${params.toString()}`)}`);
    };

    handleSearch = (value) => {

        this.setState({"name": value});

        let params = this.handlePageReset(decodeURIComponent(this.props.match.params.params));

        params = params.join('&');
        params = new URLSearchParams(params);

        value = value.toLowerCase();

        if (value.length > 0) {
            params.set('variation.name', value);
            params.set('order.customerShippingFirstname', value);
            params.set('order.customerShippingLastname', value);
            params.set('order.externalId', value);
        } else {
            params.delete('variation.name');
            params.delete('order.customerShippingFirstname');
            params.delete('order.customerShippingLastname');
            params.delete('order.externalId');
        }

        this.props.history.replace(`/picking/retailers/${this.props.match.params.id}/order/${encodeURIComponent(`${params.toString()}`)}`)
    };

    handleClearSearch = () => {
        this.setState({
            name: ""
        }, () => this.handleSearch(""));
    };

    renderFilterMenu = () => {
        const {classes, retrieved} = this.props;
        const {filterMenuSelected} = this.state;

        let filters= {};
        let existingShippingMethod = [];
        let finalShippingMethods = [];

        if (retrieved && retrieved["hydra:member"].length > 0) {
            retrieved["hydra:member"].forEach((lineItem) => {
                if (!existingShippingMethod.includes(lineItem.shippingMethod)) {
                    existingShippingMethod.push(lineItem.shippingMethod);
                    finalShippingMethods.push({
                        "@type": "ShippingMethod",
                        "id": lineItem.shippingMethod,
                        "name": <FormattedMessage id={"picking.order.shippingMethod." + lineItem.shippingMethod}/>
                    });
                }
            });

            Object.assign(filters,  {"ShippingMethod": finalShippingMethods});
        }

        return (
            <div>
                <div className={classes.filterMenu}>
                    <Tabs
                        variant="scrollable"
                        value={Object.keys(filters).indexOf(filterMenuSelected)}
                        classes={{root: classes.tabsRoot, indicator: classes.tabsIndicator}}
                    >
                        {Object.keys(filters).map(name => {
                            return (
                                <Tab
                                    key={name}
                                    onClick={() => this.setState({filterMenuSelected: name})}
                                    disableRipple
                                    classes={{root: classes.tabRoot, selected: classes.tabSelected}}
                                    label={<FormattedMessage id={"retailer.product.list.filter." + name.toLowerCase()}/>}
                                />
                            )
                        })}
                    </Tabs>
                </div>
                <div className={classes.filters}>
                    {this.renderFilterElement(filters)}
                </div>
            </div>
        );
    };

    renderFilterElement = (filters) => {
        const {classes} = this.props;
        const {filterMenuSelected} = this.state;

        if (filters[filterMenuSelected]) {
            filters[filterMenuSelected] = filters[filterMenuSelected].sort(sortOn("name"));

            return filters[filterMenuSelected].map(element => {
                return <Chip
                    label={element.name || element.value}
                    key={element.id}
                    onClick={() => this.handleAddSelectedFilter(element)}
                    variant={this.checkIfFilterExist(element) ? "default" : "outlined"}
                    classes={{root: classes.filterItem}}
                />
            })
        }
    };

    checkIfFilterExist = (element) => {
        const {selectedFilters} = this.state;

        for (let i = 0; i < selectedFilters.length; i++) {
            if (selectedFilters[i].id === element.id) {
                return true
            }
        }

        return false;
    };

    handleAddSelectedFilter = element => {
        if (!this.checkIfFilterExist(element)) {
            this.setState({selectedFilters: [...this.state.selectedFilters, element]}, () => this.handleUpdateAddFilter(element, this.state.selectedFilters));
        }
    };

    handleUpdateAddFilter(element, selectedFilters) {
        let params = this.handlePageReset(decodeURIComponent(this.props.match.params.params));
        let newParams = "";

        params.map((param) => {
            if (param.search("shippingMethod") === -1 || element["@type"] !== "ShippingMethod") {
                newParams += `${param}&`;
            }

            return true;
        });

        switch (element["@type"]) {
            case "ShippingMethod":
                let newShippingMethods = [];
                selectedFilters.filter(filter => filter["@type"] === "ShippingMethod").forEach(shippingMethod => {
                    newShippingMethods.push(`${shippingMethod.id}`);
                });
                newParams += `shippingMethod=${newShippingMethods}`;
                break;
            default:
                newParams = "";
        }

        this.props.history.push(`/picking/retailers/${this.props.match.params.id}/order/${encodeURIComponent(newParams)}`);
    }

    handleDeleteSelectedFilter = element => {
        let selectedFilters = this.state.selectedFilters;

        if (this.checkIfFilterExist(element)) {
            let index = selectedFilters.indexOf(element);

            selectedFilters.splice(index, 1);
            this.setState({selectedFilters});

            let params = this.handlePageReset(decodeURIComponent(this.props.match.params.params));
            let newParams = "";

            params.map((param) => {
                if (param.search("shippingMethod") === -1 || element["@type"] !== "ShippingMethod") {

                    newParams += `${param}&`;
                }

                if (param.search("shippingMethod") >= 0 && element["@type"] === "ShippingMethod") {
                    let newShippingMethods = [];
                    selectedFilters.filter(filter => filter["@type"] === "ShippingMethod").forEach(shippingMethod => {
                        newShippingMethods.push(shippingMethod.id);
                    });
                    if (newShippingMethods.length > 0) {
                        newParams += `shippingMethod=${newShippingMethods}&`;
                    }
                }

                return true;
            });

            if (newParams.substr(newParams.length - 1) === "&") {
                newParams = newParams.substring(0, newParams.length - 1);
            }

            this.props.history.push(`/picking/retailers/${this.props.match.params.id}/order/${encodeURIComponent(newParams)}`);
        }
    };

    renderSelectedFilter = () => {
        const {selectedFilters} = this.state;
        const {classes} = this.props;

        return (
            <div className={classes.filterSelectedContainer}>
                {selectedFilters.map(element => {
                    return <Chip
                        key={element.id}
                        label={element.name || element.value}
                        color="primary"
                        onDelete={() => this.handleDeleteSelectedFilter(element)}
                        className={classes.filterSelectedItem}
                    />
                })}
            </div>
        );
    };

    render() {
        const {classes, retrieved, intl, retailer} = this.props;
        const {selectedFilters, hideFilter} = this.state;

        return (
            <div className={classes.containerWithSidebar}>
                {this.props.error && (
                    <Snackbar
                        open={this.state.createErrorNotification}
                        close
                        closeNotification={() => this.setState({createErrorNotification: false})}
                        place={"bl"}
                        color={"danger"}
                        icon={NotificationsActive}
                        message={this.props.error}
                    />
                )}
                <div className={classes.container}>
                    <GridContainer justifyContent={"space-between"} className={classes.header}>
                        <GridItem xs={12} sm={12} md={6} lg={4} className={classes.dateRangePicker}>
                            <DateRangePicker
                                startDateId="startDate"
                                endDateId="endDate"
                                startDatePlaceholderText={"-"}
                                endDatePlaceholderText={"-"}
                                startDate={this.state.startDate}
                                endDate={this.state.endDate}
                                onDatesChange={({startDate, endDate}) => {
                                    this.setState({startDate, endDate})
                                }}
                                focusedInput={this.state.focusedInput}
                                onFocusChange={(focusedInput) => {
                                    this.setState({focusedInput})
                                }}
                                onClose={(dates) => this.handleDateChange(dates)}
                                displayFormat={"D MMMM YYYY"}
                                block={true}
                                readOnly={true}
                                isOutsideRange={() => false}
                                showDefaultInputIcon={true}
                                showClearDates={true}
                                reopenPickerOnClearDates={true}
                                required={true}
                                hideKeyboardShortcutsPanel={true}
                                withFullScreenPortal={window.innerWidth < 768}
                                numberOfMonths={2}
                                orientation={window.innerWidth < 768 ? "vertical" : "horizontal"}
                                anchorDirection={"right"}
                                minimumNights={0}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6} lg={4}>
                            <ReactToPrint
                                trigger={() => <Button color="primary" variant="contained" classes={{root: classes.justificationButton}}><PrintIcon/><FormattedMessage id={"picking.order.show.header.picking.list"}/></Button>}
                                content={() => this.componentRef}
                            />
                            <div className={classes.justificationContainer}>
                                <PickingList ref={el => (this.componentRef = el) } />
                            </div>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6} lg={4}>
                            <TextField
                                id="search-input"
                                placeholder={intl.formatMessage({id: "picking.retailer.list.search.placeholder"})}
                                onChange={(event) => this.handleSearch(event.target.value)}
                                value={this.state.name}
                                variant="outlined"
                                InputProps={{
                                    endAdornment: (
                                        this.state.name.length > 0 ?
                                            <IconButton color="primary" onClick={() => this.handleClearSearch()} size="large">
                                                <Clear/>
                                            </IconButton> :
                                            <Search color={"disabled"}/>
                                    ),
                                }}
                                className={classes.search}
                                type={"text"}
                            />
                        </GridItem>
                    </GridContainer>

                    <Card className={classes.card}>
                        <CardHeader color="info" className={classes.cardHeader}>
                            <div className={classes.filterBarContainer}>
                                <FormattedMessage id={"retailer.product.list.filter.title"}/>
                                <IconButton
                                    className={classes.filterButton}
                                    onClick={() => this.setState({hideFilter: !hideFilter})}
                                    size="large">
                                    {hideFilter ? <AddRounded/> : <RemoveRounded/>}
                                </IconButton>
                                <div hidden={hideFilter}>
                                    <div className={classes.filter}>
                                        {this.renderFilterMenu()}
                                        {selectedFilters.length > 0 && this.renderSelectedFilter()}
                                    </div>
                                </div>
                            </div>
                            {retailer &&
                                <GridContainer className={classes.retailerInfos}>
                                    <GridItem xs={6}>
                                        <span>
                                            <FormattedMessage id={"picking.order.list.table.header.retailer"}/>
                                            <b>{capitalize(retailer.name)}</b>
                                        </span>
                                    </GridItem>
                                    {retailer.address &&
                                        <GridItem xs={6}>
                                            <span>
                                                <FormattedMessage id={"picking.order.list.table.header.retailer.address"}/>
                                                <a
                                                    className={classes.infosLink}
                                                    href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(retailer.address)}`}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    <b>{capitalize(retailer.address)}</b>
                                                </a>
                                            </span>
                                        </GridItem>
                                    }
                                    {retailer.phone &&
                                    <GridItem xs={6}>
                                            <span>
                                                <FormattedMessage id={"picking.order.list.table.header.retailer.phone"}/>
                                                <a
                                                    className={classes.infosLink}
                                                    href={`tel:${retailer.phone}`}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    <b>{capitalize(retailer.phone)}</b>
                                                </a>
                                            </span>
                                    </GridItem>
                                    }
                                    {retailer.email &&
                                    <GridItem xs={6}>
                                            <span>
                                                <FormattedMessage id={"picking.order.list.table.header.retailer.email"}/>
                                                <a
                                                    className={classes.infosLink}
                                                    href={`mailto:${retailer.email}`}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    <b>{retailer.email}</b>
                                                </a>
                                            </span>
                                    </GridItem>
                                    }
                                </GridContainer>
                            }
                        </CardHeader>
                        <CardBody className={classes.cardBody}>
                            <Table
                                tableHead={[
                                    <FormattedMessage id={"picking.order.show.table.header.item"}/>,
                                    <FormattedMessage id={"picking.order.show.table.header.name"}/>,
                                    <FormattedMessage id={"picking.order.show.table.header.order"}/>,
                                    <FormattedMessage id={"picking.order.show.table.header.price"}/>,
                                    <FormattedMessage id={"picking.order.show.table.header.status"}/>,
                                ]}
                                tableHeaderColor="primary"
                                tableData={retrieved && retrieved["hydra:member"]}
                                match={this.props.match}
                                history={this.props.history}
                                runnerheader={retrieved && this.checkIfRunnerExist(retrieved["hydra:member"])}
                                organizationShippingMethods={this.state.organizationShippingMethods}
                            />
                        </CardBody>
                    </Card>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        retrieved: state.lineItem.retailerList.retrieved,
        loading: state.lineItem.retailerList.loading,
        error: state.lineItem.retailerList.error,
        itemsPerPage: state.itemsPerPage,
        lineItem: state.lineItem.update,
        retailer: state.retailer.show.retrieved,
        shippingMethodsRetrieved: state.lineItem.shippingMethods.retrieved,
    }
};

const mapDispatchToProps = dispatch => ({
    list: params => dispatch(list(params)),
    retrieve: params => dispatch(retrieve(params)),
    reset: () => dispatch(reset()),
    confirm: (orderId, params) => dispatch(confirm(orderId, params)),
    assign: (orderId, params) => dispatch(assign(orderId, params)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(showStyle)(injectIntl(Order)));
