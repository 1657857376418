import React, {Component} from 'react';
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";

// @mui/material components
import {withStyles} from "@mui/styles";
import bubbleStyle from "assets/jss/views/product/bubbleStyle";

class Bubble extends Component {

    getBubbleStyle = (type) => {
        const {classes, spacing} = this.props;

        let className;

        switch (type) {
            // Item has a border, no background, text black
            case "white": default:
                    className = classNames({
                        [classes.bubble]: true,
                        [classes.bubbleWhite]: true,
                        [classes.bubbleListSpacing]: !!spacing,
                    });
                break;
            case "success":
                className = classNames({
                    [classes.bubble]: true,
                    [classes.bubbleSuccess]: true,
                    [classes.bubbleListSpacing]: !!spacing,
                });
                break;
            case "danger":
                className = classNames({
                    [classes.bubble]: true,
                    [classes.bubbleDanger]: true,
                    [classes.bubbleListSpacing]: !!spacing,
                });
                break;
            // Item has no border, blue background, text white
            case "blue":
                    className = classNames({
                        [classes.bubble]: true,
                        [classes.bubbleBlue]: true,
                        [classes.bubbleListSpacing]: !!spacing,
                    });
                break;
        }

        return className;
    };

    render() {
        const {classes, type, label, children} = this.props;

        const className = this.getBubbleStyle(type);

        return (
            <div className={classes.container}>
                { label &&
                    <span className={classes.bubbleLabel}>{label}</span>
                }
                <span className={className}>
                    {children}
                </span>
            </div>
        );
    }
};

Bubble.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
    type: PropTypes.oneOf([
        "white",
        "blue",
        "success",
        "danger",
    ]).isRequired,
    label: PropTypes.string,
    //spacing: PropTypes.boolean,
};

export default withStyles(bubbleStyle)(Bubble); 
