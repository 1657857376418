import React from "react";
import {makeStyles} from "@mui/styles";

import {ErrorMessage, useFormikContext} from 'formik';
import {injectIntl} from "react-intl";

import '@ckeditor/ckeditor5-build-classic/build/translations/fr';
import {formInputImplementations} from "./inputRenderer/formInputRendererInterface";

const useStyles = makeStyles(theme => ({
    fieldLabel: {
        display: 'block',
    },
    fieldContainer: {
        margin: '10px 0',
    },
    fieldError: {
        // Here could be added some general style to the error message
    },
}));

const renderField = (props, formikContext) => {
    for (const formInputImplementation of formInputImplementations) {
        if (formInputImplementation['supports'](props)) {
            props.formikContext = formikContext;
            return React.createElement(formInputImplementation, { ...props })
        }
    }

    throw 'No support implementation found for field ' + props.name;
}

function FormikInput(props) {
    const formikContext = useFormikContext();
    const classes = useStyles();
    if (!props.inputProps.name) {
        console.warn('No name given');
        return;
    }

    const renderLabel = () => {
        const { labelText, labelTranslationId, intl } = props;

        let displayLabel = null;
        if (!!labelText) {
            displayLabel = labelText;
        } else if (!!labelTranslationId) {
            const translated = intl.formatMessage({id: labelTranslationId});
            if (translated !== labelTranslationId && !!translated) {
                displayLabel = translated;
            }
        }

        if (!displayLabel) {
            return null;
        }

        return (
            <label
                htmlFor={props.inputProps.name}
                className={classes.fieldLabel}
                data-disabled={props.inputProps.disabled}
            >
                {displayLabel}
            </label>
        );
    };

    return (
        <div className={classes.fieldContainer + ' ' + (props.inputProps.containerClassName ?? '')}>
            { renderLabel()}

            { renderField(props.inputProps, formikContext) }

            <ErrorMessage
                name={props.inputProps.name}
                className={props.fieldError ?? classes.fieldError}
                render={errorMessage => (
                    <span className={props.inputProps.fieldError ?? classes.fieldError}>
                        {errorMessage}
                    </span>
                )}
            />
        </div>
    );
}

export default injectIntl(FormikInput);
