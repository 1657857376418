import {combineReducers} from "redux"
import {getMemberRetailers} from "../actions/authentificationMember";

export function error(state = null, action) {
    switch (action.type) {
        case "LOGIN_ERROR":
            return action.error;

        default:
            return state;
    }
}

export function loading(state = false, action) {
    switch (action.type) {
        case "LOGIN_LOADING":
            return action.loading;

        default:
            return state;
    }
}

export function organizations(state = null, action) {
    switch (action.type) {
        case "LOGIN_SUCCESS_ORGANIZATIONS":
            return action.organizations;

        case "PROFILE_UPDATE_USER_DATA_ORGANIZATIONS_SUCCESS":
            return action.organizations;

        case "LOGOUT_SUCCESS":
            return null;

        default:
            return state;
    }
}

export function applications(state = null, action) {
    switch (action.type) {
        case "LOGIN_SUCCESS_APPLICATIONS":
            return action.applications;

        case "PROFILE_UPDATE_USER_DATA_APPLICATIONS_SUCCESS":
            return action.applications;

        case "LOGOUT_SUCCESS":
            return null;

        default:
            return state;
    }
}

export function authorizations(state = null, action) {
    switch (action.type) {
        case "LOGIN_SUCCESS_AUTHORIZATIONS":
            return action.authorizations;

        case "PROFILE_UPDATE_USER_DATA_AUTHORIZATIONS_SUCCESS":
            return action.authorizations;

        case "LOGOUT_SUCCESS":
            return null;

        default:
            return state;
    }
}

export function invitations(state = null, action) {
    switch (action.type) {
        case "LOGIN_SUCCESS_INVITATIONS":
            return action.invitations;

        case "PROFILE_UPDATE_USER_DATA_INVITATIONS_SUCCESS":
            return action.invitations;

        case "LOGOUT_SUCCESS":
            return null;

        default:
            return state;
    }
}

export function token(state = null, action) {
    switch (action.type) {
        case "LOGIN_SUCCESS_TOKEN":
            return action.token;

        case "PROFILE_UPDATE_USER_DATA_TOKEN_SUCCESS":
            return action.token;

        case "LOGOUT_SUCCESS":
            return null;

        default:
            return state;
    }
}

export function member(state = null, action) {
    let member = null;
    switch (action.type) {
        case "LOGIN_SUCCESS_MEMBER":
            member = action.member;
            member.retailers = getMemberRetailers();
            return member;

        case "PROFILE_UPDATE_USER_DATA_MEMBER_SUCCESS":
            member = action.member;
            member.retailers = getMemberRetailers();
            return member;

        case "LOGOUT_SUCCESS":
            return null;

        default:
            return state;
    }
}

export default combineReducers({error, loading, member, applications, organizations, authorizations, invitations, token});
