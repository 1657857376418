import React from "react";
import {Route} from "react-router-dom";
import {Update} from "../../views/profile/index";
import RequireAuthentication from "../../hoc/authentication/requireAuthentication";
import RetailerUnloader from "../../hoc/retailer/retailerUnloader";
import {getMemberRetailers} from "../../actions/authentificationMember";

let routes = [];
const retailers = getMemberRetailers();

if (!retailers) {
    routes.push([
        <Route
            path="/me"
            component={RequireAuthentication(RetailerUnloader(Update))}
            exact
            key="updateProfile"
        />,
    ])
}

if (retailers) {
    routes.push([
        <Route
            path="/retailers/show/:id/me"
            component={RequireAuthentication(Update)}
            exact
            key="updateRetailerProfile"
        />,
    ]);
}

export default routes;
