import React from 'react';
import IconContainer from "./IconContainer";

import {ReactComponent as CustomerNoteSvg} from "assets/img/customer-note.svg";

export default function CustomerNoteIcon () {
    return (
        <IconContainer>
            <CustomerNoteSvg width={16} height={16}/>
        </IconContainer>
    )
}
