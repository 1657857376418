import React from "react";
import {Link} from "react-router-dom";
import PropTypes from "prop-types";
import classNames from "classnames";

// @mui/material components
import {withStyles} from "@mui/styles";
import Button from "@mui/material/Button";

// styles
import paginationStyle from "../../assets/jss/components/pagination/paginationStyle";

function Pagination({...props}) {
    const {classes, pages, color, pageChanged} = props;

    const renderButton = (pageConfig) => {
        const paginationLink = classNames({
            [classes.paginationLink]: true,
            [classes[color]]: pageConfig.active,
            [classes.disabled]: pageConfig.disabled
        });

        if (pageConfig.to !== undefined && !pageConfig.disabled) {
            return (
                <Button component={Link} to={pageConfig.to} className={paginationLink}>
                    {pageConfig.text}
                </Button>
            );
        }

        if (pageConfig.action !== undefined) {
            return (
                <Button onClick={pageConfig.action()} className={paginationLink}>
                    {pageConfig.text}
                </Button>
            );
        }

        if (pageConfig.page !== undefined && pageChanged && !pageConfig.disabled) {
            return (
                <Button onClick={() => pageChanged(pageConfig.page)} className={paginationLink}>
                    {pageConfig.text}
                </Button>
            );
        }

        return (
            <Button className={paginationLink}>
                {pageConfig.text}
            </Button>
        );
    }

    return (
        <ul className={classes.pagination}>
            {pages.map((prop, key) => {
                return (
                    <li className={classes.paginationItem} key={key}>
                        {renderButton(prop)}
                    </li>
                );
            })}
        </ul>
    );
}

Pagination.defaultProps = {
    color: "primary"
};

Pagination.propTypes = {
    classes: PropTypes.object.isRequired,
    pages: PropTypes.arrayOf(
        PropTypes.shape({
            active: PropTypes.bool,
            disabled: PropTypes.bool,
            page: PropTypes.number,
            text: PropTypes.oneOfType([
                PropTypes.number,
                PropTypes.string,
                PropTypes.object,
            ]).isRequired,
            href: PropTypes.string
        })
    ).isRequired,
    color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"])
};

export default withStyles(paginationStyle)(Pagination);
