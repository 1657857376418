import React from "react";
import connect from "react-redux/es/connect/connect";
import {FormattedDate, FormattedMessage, injectIntl} from "react-intl";
import {BeatLoader} from "react-spinners";
import {ITEM_PER_PAGE} from "config/_pagination";

// @mui/material components
import {
    Badge,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    ListSubheader,
    ClickAwayListener
} from "@mui/material";

// @mui/icons-material
import {
    Notifications,
    Delete,
    Lens,
    Warning
} from "@mui/icons-material";

// styles
import {primaryColor, grayColor, dangerColor} from "../../assets/jss/main";
import notificationStyle from "assets/jss/components/sidebar/notificationStyle";
import classNames from "classnames";

//actions
import {update} from "actions/notification/update";
import {del} from "actions/notification/delete";
import {delAll} from "actions/notification/deleteAll";
import {markAllAsRead} from "actions/notification/markAllAsRead";

// utils
import {capitalize} from "utils/capitalize";
import moment from "moment";
import {withStyles} from "@mui/styles";

class Notification extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            openNotification: false,
            updatingNotification: null,
            itemsPerPage: this.props.itemsPerPage ? this.props.itemsPerPage : ITEM_PER_PAGE
        };

        this.handleMarkNotificationAsRead = this.handleMarkNotificationAsRead.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.notificationNew !== nextProps.notificationNew) {
            if ('object' === typeof nextProps.notificationRetrieved) {
                nextProps.notificationRetrieved.unshift({
                    "@id": "/notifications/" + nextProps.notificationNew.data.id,
                    "@type": "Notification",
                    createdAt: moment.now(),
                    type: nextProps.notificationNew.type,
                    viewed: false,
                    content: nextProps.notificationNew.data,
                    severity: nextProps.notificationNew.severity
                });
            }
        }

        if(this.props.notificationUpdated !== nextProps.notificationUpdated) {
            this.props.notificationRetrieved.forEach(item => {
                if(item["@id"] === nextProps.notificationUpdated["@id"]) {
                    item.viewed = true;
                }
            });
        }

        if(this.props.notificationDeleted !== nextProps.notificationDeleted) {
            for (let i = 0; i < this.props.notificationRetrieved.length; i++) {
                if (this.props.notificationRetrieved[i]["@id"] === nextProps.notificationDeleted["@id"]) {
                    this.props.notificationRetrieved.splice(i, 1);
                }
            }
        }

        if(this.props.notificationMarkedAllAsRead !== nextProps.notificationMarkedAllAsRead) {
            this.props.notificationRetrieved.forEach(item => {
               item.viewed = true;
            });
        }

        if(this.props.notificationDeletedAll !== nextProps.notificationDeletedAll) {
           this.props.notificationRetrieved.length = 0;
        }
    };

    getTextColorClass = (notification, classes) => {
        return classNames({
            [classes.notificationItemText]: true
        });
    };

    renderDate = date => {
        if (date) {
            const millisecondsAgo = Math.abs(Date.now() - new Date(date));
            const minutesAgo = Math.floor(millisecondsAgo / 60000);
            const hoursAgo = Math.round(minutesAgo / 60);
            const daysAgo = Math.round(hoursAgo / 24);
            const relativeDate = capitalize(moment(date).fromNow());

            return daysAgo > 1 ? <FormattedDate value={new Date(date)} year={"numeric"} month={"numeric"} day={"numeric"}/> : relativeDate
        } else return "-"
    };

    handleToggleNotification = () => {
        this.setState({
            openNotification: !this.state.openNotification
        });
    };

    handleClickAway = () => {
        this.setState({
            openNotification: false
        });
    };

    handleMarkNotificationAsRead = (item) => {
        this.setState({updatingNotification: item});

        this.props.update(item, {"viewed": true}).then(() => {
            if (item.type === "import" && item.content.values.importing === false) {
                let retailer = item.content.values.retailer;

                this.props.history.push(`/retailers/show/${encodeURIComponent('/retailers/' + retailer)}/products/${encodeURIComponent(`retailer.id=${retailer}&order[name]=asc&itemsPerPage=${this.state.itemsPerPage}&page=1`)}`)
            } else if (item.type === 'quote') {
                this.props.history.push(`/quote/` + item.content.values.quoteId);
            }
        });
    };

    handleDeleteNotification = (item) => {
        this.setState({updatingNotification: item})
        this.props.del(item);
    };

    render() {
        const {classes, member, notificationRetrieved} = this.props;

        const newNotificationsCount = notificationRetrieved ? notificationRetrieved.filter(item => item.viewed === false).length : 0;
        const errorNotificationsCount = notificationRetrieved ? notificationRetrieved.filter(item => item.severity === 'error' && item.viewed === false).length : 0;

        return (
            <div>
                <Badge
                    className={classes.notificationBadge}
                    badgeContent={newNotificationsCount > 0 ? newNotificationsCount : null}
                    color={errorNotificationsCount > 0 ? "error" : "primary"}
                    onClick={this.handleToggleNotification}
                >
                    <Notifications
                        style={{color: newNotificationsCount > 0 ? dangerColor : grayColor, cursor: "pointer"}}/>
                </Badge>

                {this.state.openNotification &&
                <ClickAwayListener onClickAway={this.handleClickAway}>
                    <List disablePadding className={classes.notificationList}>
                        <ListSubheader component="div" className={classes.notificationHeader}>
                            <FormattedMessage id={"topbar.notification.title"}/>
                            {notificationRetrieved.length > 0 &&
                            <div className={classes.notificationHeaderAction}>
                                {this.props.notificationDeleteAllLoading || this.props.notificationMarkAllAsReadLoading ?
                                    <BeatLoader
                                        sizeUnit={"px"}
                                        size={8}
                                        color={primaryColor}
                                        loading={true}
                                    /> :
                                    newNotificationsCount > 0 ?
                                    <span onClick={() => this.props.markAllAsRead(member.id)}>
                                        <FormattedMessage id={"topbar.notification.global.action.markAllAsRead"}/>
                                    </span> :
                                    <span onClick={() => this.props.delAll(member.id)}>
                                            <FormattedMessage id={"topbar.notification.global.action.deleteAll"}/>
                                    </span>
                                }
                            </div>
                            }
                        </ListSubheader>
                        {notificationRetrieved.length > 0 ? notificationRetrieved.map(item => (
                            <ListItem
                                disabled={item.viewed}
                                key={item["@id"]}
                                divider
                                dense
                                button
                                className={item.viewed === false ? classes.newNotificationItem : classes.notificationItem}
                                onClick={() => this.handleMarkNotificationAsRead(item)}
                            >
                                <span className={classes.notificationIconStack}>
                                    {item.severity === 'error' && <Warning className={classes.errorNotificationIcon}/>}
                                    {item.viewed === false && <Lens className={classes.newNotificationIcon}/>}
                                </span>
                                <ListItemText className={this.getTextColorClass(item, classes)}>
                                    <FormattedMessage id={item.content.message} values={item.content.values}/>
                                    <div className={classes.notificationItemDate}>
                                        {this.renderDate(item.createdAt)}
                                    </div>
                                </ListItemText>
                                <ListItemSecondaryAction>
                                    {((this.props.notificationUpdateLoading || this.props.notificationDeleteLoading) && this.state.updatingNotification === item) ?
                                        <BeatLoader
                                            sizeUnit={"px"}
                                            size={8}
                                            color={primaryColor}
                                            loading={true}
                                        /> :
                                        <Delete
                                            className={classes.notificationDeleteAction}
                                            onClick={() => this.handleDeleteNotification(item)}
                                        />
                                    }
                                </ListItemSecondaryAction>
                            </ListItem>
                            )) :
                            <div className={classes.noNotification}>
                                <Notifications className={classes.noNotificationIcon}/>
                                <div>
                                    <p className={classes.noNotificationText}>
                                        <FormattedMessage id={"topbar.notification.noNotification"}/>
                                    </p>
                                </div>
                            </div>
                        }
                    </List>
                </ClickAwayListener>
                }
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    update: (item, values) => dispatch(update(item, values)),
    del: item => dispatch(del(item)),
    delAll: member => dispatch(delAll(member)),
    markAllAsRead: member => dispatch(markAllAsRead(member))
});
const mapStateToProps = state => {

    return {
        member: state.authentication.member,
        notificationRetrieved: state.notification.list.retrieved,
        notificationNew: state.notification.list.notification,
        notificationUpdated: state.notification.update.success,
        notificationUpdateLoading: state.notification.update.loading,
        notificationDeleted: state.notification.del.success,
        notificationDeleteLoading: state.notification.del.loading,
        notificationDeleteAllLoading: state.notification.delAll.loading,
        notificationDeletedAll: state.notification.delAll.success,
        notificationMarkAllAsReadLoading: state.notification.markAllAsRead.loading,
        notificationMarkedAllAsRead: state.notification.markAllAsRead.success,
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(notificationStyle)(injectIntl(Notification)));
