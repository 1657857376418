import React, {useContext} from 'react'
import PropTypes from "prop-types";

import IconButton from "@mui/material/IconButton";

import Edit from "@mui/icons-material/Edit";

import {OrderContext} from "contexts/OrderContext";

import {Authorizations, hasAuthorization} from "utils/authorizations";
import {LineItemStatus} from "utils/LineItemStatus";

export default function LineItemEditAction({lineItem}) {
    const {
        authorizations,
        setItemBeingEdited,
        setEditMenuPosition
    } = useContext(OrderContext)
    
    if (
        !hasAuthorization(authorizations, Authorizations.PICKING_READ_ONLY)
        && (
            hasAuthorization(authorizations, Authorizations.PICKING_MANAGER)
            || hasAuthorization(authorizations, Authorizations.PICKING_RETAILER_DRIVE)
            || hasAuthorization(authorizations, Authorizations.PICKING_RETAILER_C_C)
        )
        && lineItem.status === LineItemStatus.PENDING
    ) {
        return (
            <IconButton
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={(e) => {
                    setItemBeingEdited(lineItem);
                    setEditMenuPosition(e.currentTarget)
                }}
                size="large"
            >
                <Edit/>
            </IconButton>
        )
    }

    return null;
}

LineItemEditAction.defaultProps = {
    item: {}
}

LineItemEditAction.propTypes = {
    item: PropTypes.object.isRequired
}
