import {primaryColor, boxShadow, dangerColor} from "../../main";

const cardAttributeStyle = theme => ({
    containerWithSidebar: {
        [theme.breakpoints.up("md")]: {
            marginLeft: "240px",
        }
    },
    formContainer: {
        minHeight: window.innerHeight * 0.84,

        "& > div:first-child": {
            background: "transparent",
            height: "150px"
        }
    },
    fieldContainer: {
        marginTop: "10px",
        padding: "5px",
        "& > div:first-child": {
            overflow: "visible"
        }
    },
    fieldAttributeLabel: {
        color: primaryColor,
        fontWeight: "bold",
        alignSelf: "center"
    },
    compareArrows: {
        color: primaryColor,
    },
    actionsContainer: {
        textAlign: "right",
    },
    propagateLoader: {
        left: "0",
        right: "0",
        margin: "0 auto",
        top: "15%",
        display: "flex",
        textAlign: "center",
        justifyContent: "center",
    },
    selectedStepper: {
        "& span": {
            color: `${primaryColor}!important`
        },
        "& span svg:nth-child(1)": {
            fill: `${primaryColor}!important`,
            fontSize: "50px",
            "& > text": {
                display: "none"
            },
        },

    },
    stepper: {
        "& > span:first-child > span:first-child ": {
            borderRadius: "50%",
            ...boxShadow
        },
        "& span svg:nth-child(1)": {
            fill: "white",
            fontSize: "50px",

            "& > text": {
                display: "none"
            },
        },
    },
    step: {
        "& > div:first-child": {
            top: "25px",
            left: "calc(-50% + 27px)"
        },
    },
    line: {
        width: "100%",
        height: "47px",
        borderBottom: "2px solid" + dangerColor,
        position: "absolute",
        marginTop: "-40px",
        "& div": {
            display: "flex",
            justifyContent: "space-between"
        }
    },
    lineContainer: {
        textAlign: "center",
        marginTop: "12px",
        textTransform: "uppercase",
        "& span": {
            fontWeight: "bold"
        }
    },
    round: {
        width: "10px",
        height: "10px",
        borderRadius: "50%",
        background: dangerColor,
        marginTop: "41px"
    },
    spaceEvenlyGrid: {
        display: "flex",
        flexDirection: "row-reverse",
        marginTop: "25px"
    },
    tagCategoryAsTrash: {
        width: "100%",
        "& svg": {
            fontSize: "25px",
            cursor: "pointer"
        }
    },
    listButton: {
        marginTop: "-22px",
        background: "white",
        "& span svg:nth-child(1)": {
            fill: primaryColor,
            fontSize: "35px"
        },
    },
    selectField: {
        marginTop: "-16px",
        "& > div:first-child": {
            "& > div:nth-child(2)": {
                display: "none"
            },
        },
        "& > div:nth-child(2)": {
            "& > div:nth-child(2)": {
                display: "none"
            },
        },

    },
    defaultValueText: {
        textAlign: "right",
        float: "right",
        marginTop: "-5px"
    },
    labelStyle: {
        height: "calc(100vh - 280px)",
        padding: "0px 15px"
    }
});

export default cardAttributeStyle;
