import React from "react";
import {Draggable} from "react-beautiful-dnd";

// @mui/material components
import {Checkbox, IconButton, } from "@mui/material";
import {withStyles} from "@mui/styles";

// @mui/icons-material
import { MoreVert, SubdirectoryArrowRightRounded } from "@mui/icons-material";

// core components
import CustomDate from 'components/date/date';

// styles
import sectorStyle from "assets/jss/views/sectors/sectorStyle";
import {getTranslation} from "../../../domain/helpers/translations";

function Sector (props) {
    const {
        checkedSectors,
        classes,
        data,
        draggableId,
        index,
        openPopover,
        search,
        selectSector,
        setSectorTablePosition,
        setCheckedSectors,
    } = props;

    const isChecked = checkedSectors.some(item => item['@id'] === data['@id']);

    function showPopover(e) {
        e.stopPropagation();
        selectSector(data);
        setSectorTablePosition(e.currentTarget);
        openPopover();
    }

    function checkSector(item) {
        const isItemAlreadyChecked = checkedSectors.some(itemAdded => itemAdded['@id'] === item['@id']);

        if (!isItemAlreadyChecked) {
            setCheckedSectors([...checkedSectors, item]);
        } else {
            let newSelected = checkedSectors.filter((d) => !(d['@id'] === item['@id']));

            setCheckedSectors(newSelected);
        }
    }

    function isSectorMatchWithSearch(sector) {
        return !!getTranslation(sector).name.toLowerCase().includes(search);
    }

    function elementClasses(sector) {
        if(search.length === 0){
            return classes.element
        }

        if (search.length > 0 && isSectorMatchWithSearch(sector) ) {
            return classes.elementMatch
        } else {
            return classes.elementHidden
        }
    }


    function nameClasses() {
        return classes.sectorNameSelected;
    }

    return (
        <>
            <Draggable index={index} draggableId={draggableId}>
                {provided => (
                    <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                        {data.parent && <SubdirectoryArrowRightRounded className={classes.actionsButtonContainer} />}
                        <li className={elementClasses(data)}>
                            <div className={classes.name}>
                                <Checkbox
                                    checked={isChecked}
                                    classes={{ root: classes.checkbox, checked: classes.checked }}
                                    onClick={(e) => e.stopPropagation()}
                                    onChange={() => checkSector(data)}
                                />
                                <span className={nameClasses()}>
                                    {getTranslation(data).name}
                                </span>
                            </div>
                            <div className={classes.actionsButtonContainer}>
                                <CustomDate showRelativeDate={true} date={data.updatedAt} />
                                <IconButton
                                    className={classes.iconButtonMargin}
                                    onClick={(e) => showPopover(e)}
                                    size={"small"}
                                >
                                    <MoreVert />
                                </IconButton>
                            </div>
                        </li>
                    </div>
                )}
            </Draggable>
        </>
    );
}

export default withStyles(sectorStyle)(Sector);
