import React from 'react';
import PropTypes from "prop-types";

import {styled} from '@mui/material/styles';

import MuiIconButton from '@mui/material/IconButton';

import {greyishBlue50} from "assets/jss/main";

const StyledIconButton = styled(MuiIconButton)(({ theme }) => ({
    width: '32px',
    height: '32px',
    padding: '6px',
    borderRadius: '4px',
    backgroundColor: '#fff',
    '&:hover': {
        backgroundColor: greyishBlue50,
    },
    '&.Mui-disabled' : {
        backgroundColor: greyishBlue50,
    }
}))

IconButton.propTypes = {
    disabled: PropTypes.bool,
}

function IconButton(props) {
    return (
      <StyledIconButton {...props}>
          <div>{React.cloneElement(props.children, {disabled: props.disabled})}</div>
      </StyledIconButton>
    );
}

export default IconButton;
