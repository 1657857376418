import {createContext} from "react";

export const ImageDropFieldContext = createContext({
    openFileSelector: () => {},
    importLoading: false,
    disabled: false,
    onDelete: () => {},
    inputProps: {},
    rootProps: {},
    fileRejections: [],
})
