import React from "react";
import Stack from '@mui/material/Stack';
import {TableCell, } from "@mui/material";
import {withStyles} from "@mui/styles";
import ProductValidationLabel from "../products/molecule/ProductValidationLabel";
import ProductStatusLabel from "../products/molecule/ProductStatusLabel";
import ProductStockLabels from "../products/molecule/ProductStockLabels";
import merchandisingStyle from "../../../assets/jss/views/merchandising/merchandisingStyle";


function MerchandisingStockStatusCell({product, classes}) {

    return (
        <TableCell>
            <Stack className={classes.stockStatus}>
                <div className={classes.stocksList}>
                    <ProductValidationLabel product={product}/>
                    <ProductStatusLabel product={product}/>
                    <ProductStockLabels product={product}/>
                </div>
            </Stack>
        </TableCell>
    );
}

export default withStyles(merchandisingStyle)(MerchandisingStockStatusCell);
