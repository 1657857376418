import {container, defaultFont} from "../../main";

const listErrorsStyle = theme => ({
    containerWithSidebar: {
        [theme.breakpoints.up("md")]: {
            marginLeft: "240px",
        }
    },
    container: {
        ...container,
        ...defaultFont,
        paddingTop: '20px',
        paddingLeft: '20px',
        paddingRight: '20px',
        paddingBottom: '20px',
    },
    mainActionsContainer: {
        margin: "8px 0px",
        textAlign: "right"
    },
    yesNo: {
        verticalAlign: 'bottom',
    },
    actionsContainer: {
        float: 'right',
        marginLeft: '10px',
    }
});

export default listErrorsStyle;
