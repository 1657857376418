import React, {useContext} from 'react';
import PropTypes from 'prop-types';

import {ImageDropFieldContext} from "../../../context/ImageDropFieldContext";

Image.propTypes = {
    src: PropTypes.string.isRequired
};

function Image({src}) {
    const {importLoading} = useContext(ImageDropFieldContext);

    return (
        <img
            src={src}
            alt={'image preview'}
            style={{
                borderRadius: 8,
                display: 'block',
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                filter: importLoading ? 'blur(5px)': 'none'
            }}
        />
    );
}

export default Image;
