import {combineReducers} from "redux";

export function createError(state = null, action) {
    switch (action.type) {
        case "VARIATION_LOCK_CREATE_ERROR":
            return action.createError;

        default:
            return state;
    }
}

export function createLoading(state = false, action) {
    switch (action.type) {
        case "VARIATION_LOCK_CREATE_LOADING":
            return action.createLoading;

        default:
            return state;
    }
}

export function createSuccess(state = null, action) {
    switch (action.type) {
        case "VARIATION_LOCK_CREATE_SUCCESS":
            return action.createSuccess;

        default:
            return state;
    }
}

export default combineReducers({
    createError,
    createLoading,
    createSuccess
});