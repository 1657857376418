import {
    blackMagicColor,
    dangerColor,
    grayColor,
    primaryColor,
    sidebarColor,
    terraCottaColor
} from "../../main";

const drawerWidth = 240;

const sidebarStyle = theme => ({
    initial: {
        color: "#000"
    },
    containerWithSidebar: {
        paddingLeft: window.innerWidth * 0.18
    },
    drawerRootContainer: {
        height: "100%",
        background: sidebarColor,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
    },
    drawer: {
        width: drawerWidth,
    },
    drawerPaper: {
        width: drawerWidth,
        overflow: "visible",
        boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12)',
        border: '0px'
    },
    container: {
        backgroundColor: sidebarColor,
        borderBottom: "1px solid rgba(224, 224, 224, 1)",
        paddingTop: "30px"
    },
    envContainer: {
        padding: "15px",
        fontWeight: "bold",
        textAlign: "center",
        backgroundColor: "rgba(237, 106, 90, 0.2)",
        color: terraCottaColor,
        borderBottom: "1px solid"
    },
    infoContainer: {
        width: "100%",
        margin: "0px",
    },
    menuButton: {
        zIndex: "1220",
        [theme.breakpoints.up("md")]: {
            display: "none",
        },
        position: "fixed"
    },
    editButton: {
        textAlign: "right",
        "& a": {
            color: primaryColor
        }
    },
    logoContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        padding: "0px 40px 10px 40px",
        "& .givenName": {
            color: blackMagicColor,
            fontSize: "15px",
            textTransform: 'capitalize',
            display: "block",
            paddingTop: '10px'
        },
        "& .email": {
            color: grayColor,
            fontSize: "15px",
            paddingTop: '5px',
            paddingBottom: '5px'

        },
        "& a ": {
            textDecoration: "none"
        }
    },
    listContainerDrawer: {
        overflow: "scroll",
        height: "100%"
    },
    organizationListContainer: {
        overflow: "auto",
        maxHeight: "350px",
    },
    inputOrganizationFilter: {
        width: "100%",
        fontSize: "12px",
        color: grayColor,
    },
    listContainer: {
        color: "#000000",
    },
    listItem: {
        paddingLeft: "12px",
        paddingRight: "0px",

        "&:hover": {
            background: "transparent",
            "& div span": {
                color: primaryColor,
                fontWeight: "bold"
            },
            "& div svg:nth-child(1)": {
                fill: primaryColor,
                fontWeight: "bold"
            },
            "& svg:nth-child(1)": {
                fill: primaryColor,
                fontWeight: "bold"
            }
        },
    },
    subListIcon: {
        fontSize: "14px"
    },
    subListText: {
        paddingLeft: '18px !important',
        "& span": {
            fontSize: "12px"
        }
    },
    avatarText: {
        width: 80,
        height: 80,
        border: "3px solid white",
        textTransform: 'uppercase',
        fontSize: "26px",
        fontWeight: "bold",
        background: primaryColor
    },
    footer: {
        borderTop: "1px solid rgba(224, 224, 224, 1)",
        left: '0px',
        bottom: '0px',
        width: '240px',
        position: 'relative',
        backgroundColor: sidebarColor
    },
    selected: {
        background: 'linear-gradient(90deg, rgba(0,82,203,1) 3%, rgba(184,208,246,1) 0%, rgba(184,208,246,0) 75%) !important',
        "& div span": {}
    },
    subListSelected: {
        backgroundColor: "transparent !important",
        "& div span": {
            fontWeight: "bold"
        }
    },
    avatar: {
        width: 80,
        height: 80,
        border: "3px solid #0052cb"
    },
    logo: {
        width: 180,
        height: 40,
    },
    footerApps: {
        borderBottom: "1px solid rgba(224, 224, 224, 1)",
        padding: '12px',
        "& > div > div a:hover ": {
            '& svg:nth-child(1)': {
                fill: primaryColor
            }
        }
    },
    logoutButton: {
        textDecoration: 'none',
        '& .MuiButton-root': {
            bottom: 0,
            height: '58px',
            color: dangerColor
        }
    },
    retailerSubMenu: {
        borderTop: "1px solid rgba(224, 224, 224, 1)",
        borderBottom: "1px solid rgba(224, 224, 224, 1)",
        marginTop: '9px',
        '& span': {
            paddingLeft: '30px'

        }
    },
    retailerTitle: {
        paddingLeft: '74px !important',
        '& span': {
            fontWeight: 'bold'
        }
    },
    retailerBadgePush: {
        verticalAlign: 'sub',
        marginLeft: '5px',
    },
    stockEasyRetailerTitle: {
        paddingLeft: '30px !important',
        '& span': {
            fontWeight: 'bold'
        }
    },
    configurationItem: {
        '& span': {
            paddingLeft: '30px'
        }
    },
    badgeEshopExternalLink: {
        position: "absolute",
        marginLeft: "66px",
        zIndex: 3,
        backgroundColor: "white",
        borderRadius: '50%',
        border: "2px solid " + primaryColor,
        width: "1.15em",
        height: "1.15em",
        display: "inline-block",
        fontSize: "1.5rem",
        padding: "2px",
        color: "#0052cb"
    }
})

export default sidebarStyle;
