import {primaryColor, dangerColor, infoColor} from "../../main";

const formStyle = {
    formContainer: {
        paddingBottom: window.innerHeight * 0.15
    },
    fieldContainer: {
        padding: "5px"
    },
    formControl: {
        padding: "0px",
        width: "100%"
    },
    fieldLabel: {
        color: primaryColor,
        fontWeight: "bold"
    },
    fieldFloatRight: {
        float: "right"
    },
    inputError: {
        color: dangerColor
    },
    popoverContainer: {
        display: "inline-block"
    },
    popover: {
        padding: "0",
        boxShadow:
            "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
        lineHeight: "1.5em",
        background: "#fff",
        border: "none",
        borderRadius: "3px",
        display: "block",
        maxWidth: "276px",
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontStyle: "normal",
        fontWeight: "400",
        textAlign: "start",
        textDecoration: "none",
        textShadow: "none",
        textTransform: "none",
        letterSpacing: "normal",
        wordBreak: "normal",
        wordSpacing: "normal",
        whiteSpace: "normal",
        lineBreak: "auto",
        fontSize: "0.875rem",
        wordWrap: "break-word"
    },
    popoverHeader: {
        backgroundColor: "#fff",
        border: "none",
        padding: "15px 15px 5px",
        fontSize: "1.125rem",
        margin: "0",
        color: "#555",
        borderTopLeftRadius: "calc(0.3rem - 1px)",
        borderTopRightRadius: "calc(0.3rem - 1px)"
    },
    popoverBody: {
        padding: "10px 15px 15px",
        lineHeight: "1.4",
        color: "#555"
    },
    pictureContainer: {
        border: "2px solid #eee",
        borderRadius: "4px",
        padding: "10px 12px",
        minHeight: "30px",
    },
    pictureBlock: {
        textAlign: "center",
    },
    pictureActions: {
        float: "right",
    },
    pictureIcon: {
        cursor: "pointer",
        "&:hover": {
            color: "#0052cb",
        }
    },
    pictureName: {
        textAlign: "left",
        padding: "5px 0 10px",
        marginBottom: "15px",
    },
    actionsContainer: {
        position: "absolute",
        right: "5px",
        bottom: "0px",
        textAlign: "right"
    },
    submitLoading: {
        marginLeft: "5px",
        color: "#FFFFFF"
    },
    dropzoneContainer: {
      padding: "5px"
    },
    dropzoneRetailerImage: {
        display: "flex",
        alignItems: "center"
    },
    inputTextarea: {
        border: "2px solid #eee",
        borderRadius: "4px",
        width: "100%",
        padding: "8px 10px",
        boxShadow: "none",
        outline: "0",
        resize: "none",
        maxHeight: "200px",
        fontSize: "14px",
        "&:focus": {
            borderColor: "#0857cc !important",
        },
        "&::placeholder": {
            fontSize: "14px",
            color: "#acacac",
            fontWeight: "400",
            textAlign: "left",
            fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
            opacity: "1",
            lineHeight: "1.42857",
        },

        "& .MuiInput-root": {
            border: "none",
            "&::before": {
                borderBottom: "none",
            },
            "& textarea": {
                fontSize: "14px",
            }
        },

        "& .MuiInput-underline:hover:not(.Mui-disabled)::before": {
            borderBottom: "none",
        },

        "& .MuiInput-underline::after": {
            borderBottom: "none",
        }
    },
    fieldUpload: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    fileUploadText: {
        color: "#acacac",
    },
    actions: {
        "&:last-chid":{
            border: "1px solid blue"
        }
    },
    upload: {
        "&:hover": {
            cursor: "pointer",
            color: infoColor
        },
    },
    delete: {
        marginRight: "5px",
        "&:hover": {
            cursor: "pointer",
            color: dangerColor
        }
    },
    placeholder: {
        color: "#acacac",
        fontSize:"14px",
        fontWeight:"400",
        textAlign:"left",
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        opacity:"1",
        lineHeight:"1.42857",
    },
    dropzoneUserImage: {
        display: "flex",
        alignItems: "center"
    },
    errorMessages: {
        marginLeft: "5px",
        fontSize: "15px",
        color: 'red'
    },
    customMenuItemSelected:{
        background: `${infoColor} !important`,
        color:"white !important",
    },
    customMenuItem: {
        "&:hover": {
            background: infoColor,
            color:"white",
        },
        "&:focus": {
            background: infoColor,
            color:"white",
        }
    },
    billingInfosTitle: {
        color: primaryColor,
        fontWeight: 'bold',
        fontSize: '20px',
        marginBottom: "15px",
        display: "inline",
    },
    imgPreview: {
        width: "100%"
    },
    billingActive: {
        "& b": {
            color: primaryColor,
        }
    }
};

export default formStyle;
