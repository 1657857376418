import React from "react";
import { injectIntl } from "react-intl";
import PropTypes from "prop-types";
import {ErrorMessage, useFormikContext} from "formik";

// @mui/material components
import {Switch} from "@mui/material";
import {makeStyles} from '@mui/styles';

// style
import {dangerColor, infoColor} from "assets/jss/main";

const useStyles = makeStyles(theme  => ({
    fieldLabel: {
        color: infoColor,
    },
    fieldError: {
        color: dangerColor
    },
}));

function DefaultSwitchField(props) {
    const {
        intl,
        checked,
        disabled,
        name,
        labelTranslationId
    } = props;

    const classes = useStyles();

    const formikContext = useFormikContext();

    return (
        <>
            <label htmlFor={name} className={classes.fieldLabel}>
                {intl.formatMessage({id: labelTranslationId})}
            </label>
            <Switch
                checked={checked}
                onChange={formikContext.handleChange}
                name={name}
                color="primary"
                disabled={disabled}
            />
            <ErrorMessage
                name={name}
                className={classes.fieldError}
                render={errorMessage => <span className={classes.fieldError}>{errorMessage}</span>}
            />
        </>
    );
}

DefaultSwitchField.propTypes = {
    checked: PropTypes.bool,
    name: PropTypes.string,
    labelTranslationId: PropTypes.string,
    disabled: PropTypes.bool,
};

DefaultSwitchField.defaultProps = {
    checked: false,
    disabled: false
}

export default injectIntl(DefaultSwitchField);
