import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import {ReactComponent as ArrowUp} from "assets/img/arrow-up.svg";
import {ReactComponent as ArrowDown} from "assets/img/arrow-down.svg";

import {makeStyles} from "@mui/styles";
import {
    black500,
    greyishBlue200,
    greyishBlue50,
    greyishBlue500,
    infoColor,
    grayColor,
} from "assets/jss/main";

const useStyles = makeStyles(theme => ({
    fakeSelectInput: {
        color: black500,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: '#fff',
        cursor: 'pointer',
        border: `1px solid ${greyishBlue200}`,
        borderRadius: '8px',
        padding: '16px',
        fontSize: '0.875rem',
        textAlign: 'left',
        '&:focus' : {
            borderColor: infoColor
        },
        '&:hover': {
            borderColor: greyishBlue500,
        },
        'svg': {
            width: '16px',
            height: '16px'
        },
        width: '100%'
    },
    disabled: {
        borderColor: greyishBlue50,
        backgroundColor: greyishBlue50,
        color: greyishBlue500,
        '&:hover': {
            borderColor: greyishBlue50,
        },
    },
    opened: {
        borderColor: infoColor
    }
}));

export default function FakeSelectInput(props) {
    const classes = useStyles();

    return (
        <button
            onClick={(e) => {
                e.preventDefault();
                props.onClick()
            }}
            className={classNames({
                [classes.fakeSelectInput]: true,
                [classes.disabled]: props.disabled,
                [classes.opened]: props.open
            })}
            disabled={props.disabled}
        >
            <>
                {props.text ? (
                    <span style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                    }}>{props.text}</span>
                ) : props.placeholder ? (
                    <span style={{color: grayColor}}>{props.placeholder}</span>
                ) : (
                    <span></span>
                )}

                {!props.disable && (props.open ? <ArrowUp /> : <ArrowDown />)}
            </>
        </button>
    )
}

FakeSelectInput.defaultProps = {
    onClick: () => {},
    disabled: false,
    text: '',
    opened: false
}

FakeSelectInput.propTypes = {
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    text: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
        PropTypes.bool
    ]),
    open: PropTypes.bool
}
