import React, {useContext} from "react";
import AssessmentIcon from '@mui/icons-material/AssessmentOutlined';
import NavTree from "views/marketplace/merchandising/nav/navTree";
import {ProductDataContext} from "contexts/productDataContext";

export default function CategoriesNav(props) {

    const { categoriesLoaded, categoriesFilters } = useContext(ProductDataContext);
    const topNodeLabel = "merchandising.show.label.nav.categories";
    const topNodeIcon = AssessmentIcon;
    const topCategories = categoriesFilters.tabContent.items.filter(item => item.parent === null);

    return (
        <>
            {categoriesLoaded && <NavTree 
                items={categoriesFilters.tabContent.items}
                topItems={topCategories}
                topNodeLabel={topNodeLabel}
                topNodeIcon={topNodeIcon}
                nodeId={props.nodeId}
                type={'Category'}
                {...props}
            />}
        </>
    );
}
