import React, {Component} from "react";
import connect from "react-redux/es/connect/connect";
import {FormattedMessage, injectIntl} from "react-intl";
import {ITEM_PER_PAGE} from "config/_pagination";
import {BeatLoader, PropagateLoader} from 'react-spinners';

// @mui/material components
import {
    Checkbox,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Tooltip,
    Fab,
    Popover,
    Typography
} from "@mui/material";

// @mui/icons-material
import {
    AddRounded,
    MoreVert,
    NotificationsActive
} from "@mui/icons-material";

// core components
import Card from "components/card/card";
import CardBody from "components/card/cardBody";
import CardHeader from "components/card/cardHeader";
import CreateDialog from "./create";
import EditDialog from "./update";
import GridContainer from "components/grid/gridContainer";
import GridItem from "components/grid/gridItem";
import PaginationContainer from "../../components/pagination/paginationContainer";
import Search from "views/configuration/components/search";
import ShowDialog from "./showDialog";
import TableHead from "./tableHead";
import Snackbar from "components/snackbar/snackbar";
import ItemsPerPageSelect from "components/select/itemsPerPageSelect";

// styles
import listStyle from "assets/jss/views/roles/listStyle";
import {primaryColor} from "assets/jss/main";

// actions
import {list as listRoles} from "actions/role/list";
import {deleteRole} from "actions/role/delete";

// helpers
import abort from "utils/abort";
import {capitalize} from "utils/capitalize";
import {withStyles} from "@mui/styles";
import {encodePercentageCharacter} from '../../utils/encodePercentageCharacter'

class List extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selected: [],
            page: 1,
            search: "",
            itemsPerPage: this.props.itemsPerPage !== null ? this.props.itemsPerPage : ITEM_PER_PAGE,
            anchorEl: null,
            anchorElAll: null,
            showDialog: false,
            index: null,
            createDialog: false,
            editDialog: false,
            createNotification: false,
            roleSelected: null
        };

        this.handleDeleteRole = this.handleDeleteRole.bind(this);
    };

    componentDidMount() {
        if (this.props.match.params.params) {
            const params = decodeURIComponent(encodePercentageCharacter(this.props.match.params.params))

            this.props.listRoles(new URLSearchParams(params).toString())
        }
    };

    UNSAFE_componentWillReceiveProps(nextProps) {

        if (this.props.match.params.params !== nextProps.match.params.params) {
            const params = nextProps.match.params.params && decodeURIComponent(encodePercentageCharacter(nextProps.match.params.params))
            nextProps.listRoles(new URLSearchParams(params).toString())
        }

        if (this.props.created !== nextProps.created) {
            this.setState({createNotification: true});

            const params = decodeURIComponent(encodePercentageCharacter(nextProps.match.params.params))
            this.props.listRoles(new URLSearchParams(params).toString())
        }

        if(this.props.deleted !== nextProps.deleted) {
            this.setState({createNotification: true});

            this.isDialogVisible(false, null);

            const params = decodeURIComponent(encodePercentageCharacter(nextProps.match.params.params))
            this.props.listRoles(new URLSearchParams(params).toString())
        }

        if(this.props.updated !== nextProps.updated) {
            this.setState({createNotification: true});

            const params = decodeURIComponent(encodePercentageCharacter(nextProps.match.params.params))
            this.props.listRoles(new URLSearchParams(params).toString())
        }
    }

    componentWillUnmount() {
        abort.abortRequests();
    }

    isDialogVisible = (visible, index) => {
        const {retrieved} = this.props;

        this.setState({
            showDialog: visible,
            index: index,
            roleSelected: retrieved["hydra:member"][index]
        });
    };

    handleClick = (role, event) => {
        event.stopPropagation();

        this.setState({
            roleSelected: role,
            anchorEl: event.currentTarget
        });
    };

    handleClickAll = (event) => {
        event.stopPropagation();

        this.setState({anchorElAll: event.currentTarget});
    };

    handleClose = (event) => {
        event.persist();
        event.stopPropagation();

        this.setState({anchorEl: null})
    };

    handleCloseAll = (event) => {
        event.persist();
        event.stopPropagation();

        this.setState({anchorElAll: null})
    };

    handleSearch = (value) => {
        abort.abortRequests();

        this.setState({search: value});

        let params = this.handlePageReset(decodeURIComponent(this.props.match.params.params));
        let newParams = "";

        if (value.length > 0) {
            newParams = `name=${value}&`;
        }

        params.map((param) => {
            newParams += `${param}&`;

            return true;
        });

        newParams = newParams.substring(0, newParams.length - 1);

        this.props.history.push(encodeURIComponent(newParams));
    };

    handlePageReset = (params) => {
        params = params.split("&");

        params.map((param, key) => {
            if (param.search("page") !== -1) {
                params[key] = "page=1";
            }

            return true;
        });

        params = params.filter(param => !(param.search("name=") !== -1));

        return params;
    };

    handleCheckRole = (item) => {
        const {selected} = this.state;

        const isItemAlreadyAdded = selected.some(itemAdded => itemAdded['@id'] === item['@id']);

        if (!isItemAlreadyAdded) {
            this.setState({
                selected: [...selected, item]
            });
        } else {
            let newSelected = selected.filter((d) => !(d['@id'] === item['@id']));

            this.setState({
                selected: newSelected
            });
        }
    };

    handleDeleteRole = (role, e) => {
        e.stopPropagation();

        this.handleClose(e);
        this.props.deleteRole(role);
    };

    handleSelectAllClick = (event) => {
        const {retrieved} = this.props;

        this.setState({
            selected: event.target.checked ? JSON.parse(JSON.stringify(retrieved["hydra:member"].filter(role => role.defaultRole === false))) : [],
        });
    };

    isSelected = item => this.state.selected.some(selectedItem => selectedItem['@id'] === item['@id']);

    renderOutils = (role) => {
       let arr = [];

       role['authorizations'].length > 0 && role['authorizations'].forEach(item => {
           let applicationName = item.name.split('.')[0];

           if (applicationName !== "global") {
               if (applicationName === "personal_shopper") {
                   applicationName = "Personal Shopper";
               } else if (applicationName === "stock_easy") {
                   applicationName = "Stock Easy"
               }

               arr.push(capitalize(applicationName));
           }
       });

       const unique = Array.from(new Set(arr));

       return unique.map((item, index) => {
           let delimiter = (index + 1) === unique.length ? '' : unique.length > 1 && ' - ';

           return <span key={item}>{`${item}${delimiter}`}</span>
       })
    };

    render() {
        const {classes, intl, retrieved, loading, created, updated, deleted, deleteLoading} = this.props;
        const {selected, anchorEl, anchorElAll, createDialog, editDialog} = this.state;
        const tableHead = [
            {
                id: "role",
                label: intl.formatMessage({id: "role.table.header.role"})
            },
            {
                id: "outil",
                label: intl.formatMessage({id: "role.table.header.outil"})
            },
            {
                id: "actions",
                label: <div className={classes.actionsButtonContainer}>
                    {(retrieved && retrieved["hydra:member"] && loading) ?
                    <BeatLoader
                        sizeUnit={"px"}
                        size={12}
                        color={primaryColor}
                        loading={true}
                    />
                    : this.state.selected.length > 0 &&
                        <div>
                            <MoreVert className={classes.iconAll} onClick={this.handleClickAll}/>
                            <Popover
                                open={Boolean(anchorElAll)}
                                anchorEl={anchorElAll}
                                onClose={this.handleCloseAll}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                            >
                                <Typography
                                    className={classes.typography}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        this.state.selected.forEach(item => this.props.deleteRole(item))
                                    }}>
                                    <FormattedMessage id={"roles.table.row.delete"}/>
                                </Typography>
                            </Popover>
                        </div>
                    }
                </div>
            },
        ];

        return (
            <div className={classes.containerWithSidebar}>
                <div className={classes.container}>
                    {created && (
                        <Snackbar
                            open={this.state.createNotification}
                            close
                            closeNotification={() => this.setState({createNotification: false})}
                            place={"bl"}
                            color={"success"}
                            icon={() => <NotificationsActive/>}
                            message={<FormattedMessage id={"role.notifications.created"}/>}
                        />
                    )}
                    {deleted && (
                        <Snackbar
                            open={this.state.createNotification}
                            close
                            closeNotification={() => this.setState({createNotification: false})}
                            place={"bl"}
                            color={"success"}
                            icon={() => <NotificationsActive/>}
                            message={<FormattedMessage id={"role.notifications.deleted"}/>}
                        />
                    )}
                    {updated && (
                        <Snackbar
                            open={this.state.createNotification}
                            close
                            closeNotification={() => this.setState({createNotification: false})}
                            place={"bl"}
                            color={"success"}
                            icon={() => <NotificationsActive/>}
                            message={<FormattedMessage id={"role.notifications.updated"}/>}
                        />
                    )}
                    <GridContainer>
                        <GridItem xs={12}>
                            <Card className={classes.card}>
                                <CardHeader color="info">
                                    <GridContainer>
                                        <GridItem xs={9} sm={10}>
                                            <h3><FormattedMessage id={"roles.title"}/></h3>
                                        </GridItem>
                                        <GridItem xs={3} sm={2} className={classes.mainActionsContainer}>
                                            <Fab
                                                onClick={() => this.setState({createDialog: true})}
                                                color={"primary"}
                                            >
                                                <Tooltip title={<FormattedMessage id={"roles.add.role"}/>}>
                                                    <AddRounded fontSize={"large"}/>
                                                </Tooltip>
                                            </Fab>
                                        </GridItem>
                                    </GridContainer>
                                </CardHeader>
                                <CardBody>
                                    <div className={classes.subTableContainer}>
                                        <Search
                                            attributeName={"member.list"}
                                            search={this.state.search}
                                            setSearch={(value) => this.handleSearch(value)}
                                        />
                                        <ItemsPerPageSelect history={this.props.history} match={this.props.match}/>
                                    </div>
                                    <div className={classes.tableWrapper}>
                                        {!(retrieved && retrieved["hydra:member"]) &&
                                            <div className={classes.propagateLoader}>
                                                <PropagateLoader
                                                    sizeUnit={"px"}
                                                    size={16}
                                                    color={primaryColor}
                                                    loading={loading}
                                                />
                                            </div>

                                        }
                                        <Table padding="none" className={classes.table}>
                                            <TableHead
                                                checked={!!(retrieved && retrieved["hydra:member"] && retrieved["hydra:member"].length === this.state.selected.length && retrieved["hydra:member"].length > 0)}
                                                onSelectAllClick={this.handleSelectAllClick}
                                                tableHead={tableHead}
                                                selected={selected}
                                            />
                                            <TableBody className={classes.tableBody}>
                                                {(retrieved && retrieved["hydra:member"].length > 0) && retrieved["hydra:member"].map((role, index) => {
                                                    const isSelected = this.isSelected(role);

                                                    return (
                                                        <TableRow
                                                            className={`${classes.tableRow} ${isSelected ? classes.selectedTableRow : null}`}
                                                            hover
                                                            key={role['@id']}
                                                            tabIndex={-1}
                                                            onClick={() => this.isDialogVisible(true, index)}
                                                        >
                                                            <TableCell className={classes.leftTableCell}>
                                                                {!role.defaultRole &&
                                                                <Checkbox
                                                                    checked={isSelected}
                                                                    classes={{
                                                                        root: classes.checkbox,
                                                                        checked: classes.checked,
                                                                    }}
                                                                    onClick={(e) => e.stopPropagation()}
                                                                    onChange={() => this.handleCheckRole(role)}
                                                                />
                                                                }
                                                            </TableCell>
                                                            <TableCell>
                                                                <div>{capitalize(role.name)}</div>
                                                            </TableCell>
                                                            <TableCell>
                                                                {this.renderOutils(role)}
                                                            </TableCell>
                                                            <TableCell className={classes.actionsButtonContainer}>
                                                                {!role.defaultRole && <MoreVert className={classes.icon} onClick={(e) => this.handleClick(role, e)}/>}
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                                {retrieved && retrieved["hydra:member"].length === 0 &&
                                                <TableRow>
                                                    <TableCell colSpan={9} className={classes.tableCellNoResult}>
                                                        <FormattedMessage id={"member.tab.all.table.no.result"}/>
                                                    </TableCell>
                                                </TableRow>
                                                }
                                            </TableBody>
                                        </Table>
                                    </div>
                                    <Popover
                                        open={Boolean(anchorEl)}
                                        anchorEl={anchorEl}
                                        onClose={this.handleClose}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'center',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'center',
                                        }}
                                    >
                                        <Typography
                                            className={classes.typography}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                this.setState({editDialog: true})
                                            }}
                                        >
                                            <FormattedMessage id={"roles.table.row.edit"}/>
                                        </Typography>
                                        <Typography
                                            className={classes.typography}
                                            onClick={(e) => {
                                                this.handleDeleteRole(this.state.roleSelected, e)
                                            }}
                                        >
                                            <FormattedMessage id={"roles.table.row.delete"}/>
                                        </Typography>
                                    </Popover>
                                    <div className={classes.paginationContainer}>
                                        <PaginationContainer
                                            itemsPerPage={this.state.itemsPerPage}
                                            urlParams={this.props.match.params.params}
                                            retrieved={this.props.retrieved}
                                        />
                                    </div>
                                </CardBody>
                            </Card>
                        </GridItem>
                        <ShowDialog
                            role={retrieved && retrieved["hydra:member"][this.state.index]}
                            showDialog={this.state.showDialog}
                            closeDialog={() => this.isDialogVisible(false, null)}
                            outils={this.state.index !== null ? this.renderOutils(retrieved["hydra:member"][this.state.index]) : null}
                            deleteLoading={deleteLoading}
                            openEditDialog={() => this.setState({editDialog: true})}
                        />
                    </GridContainer>
                </div>
                <CreateDialog
                    openAddMemeberDialog={createDialog}
                    closeDialog={() => this.setState({createDialog: false})}
                />
                <EditDialog
                    editDialog={editDialog}
                    closeDialog={() => this.setState({editDialog: false, showDialog: false})}
                    role={this.state.roleSelected}
                />
            </div>
        );
    };
}

const mapStateToProps = (state) => {
    return {
        itemsPerPage: state.itemsPerPage.itemsPerPage,
        retrieved: state.role.list.retrieved,
        loading: state.role.list.loading,
        created: state.role.create.created,
        deleted: state.role.deleteRole.deleted,
        deleteLoading: state.role.deleteRole.loading,
        updated: state.role.update.updated,
    };
};

const mapDispatchToProps = dispatch => ({
    listRoles: params => dispatch(listRoles(params)),
    deleteRole: item => dispatch(deleteRole(item)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(listStyle)(injectIntl(List)));
