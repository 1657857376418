import {useEffect, useState} from "react";
import StatusFilterPanel from "views/marketplace/merchandising/filter/statusFilterPanel";

const Filter = (tabTitle, tabContent) => { return { tabTitle: tabTitle, tabContent: tabContent } }

export default function useStatus() {

    const [statusFilters, setStatusFilters] = useState(null);
    const [statusLoaded, setStatusLoaded] = useState(false);

    useEffect(() => {

        // Mimic format of fetched data from other filters
        const data = [
            new Filter("status", {items: [
                {id: 'active', name: 'active', '@type': 'Status'}, 
                {id: 'inactive', name: 'inactive', '@type': 'Status'}, 
            ]}),
            new Filter("validity", {items: [
                {id: 'valid', name: 'valid', '@type': 'Validated'}, 
                {id: 'invalid', name: 'invalid', '@type': 'Validated'}, 
            ]}),
            new Filter("oneshop", {items: [
                {id: 'yes', name: 'yes', '@type': 'OnEshop'}, 
                {id: 'no', name: 'no', '@type': 'OnEshop'}, 
            ]}),
        ];

        setStatusFilters(
            new Filter(
                "status",
                {
                    component: StatusFilterPanel,
                    items: data,
                }
            )
        );

        setStatusLoaded(true);
    }, []);

    return {
        statusLoaded,
        statusFilters
    };
}
