const quoteAcceptedColor = "#78BC617F";
const quoteWaitingReviewColor = "#E6C2297F";
const quoteWaitingCustomerColor = "#E6C2297F";
const quoteUpdatingColor = "#E6C2297F";
const quoteInProgressColor = "#ED6A5A7F";
const quoteRefusedColor = "#ED6A5A7F";
const quoteExpiredColor = "#ED6A5A7F";

const quoteAccepted = "#21BA8C";
const quoteWaitingReview = "#F2F2F2";
const quoteWaitingCustomer = "#FE974C";
const quoteUpdating = "#F2F2F2";
const quoteInProgress = "#F2F2F2";
const quoteRefused = "#FF3737";
const quoteExpired = "#FF3737";

export {
    quoteWaitingReviewColor,
    quoteWaitingCustomerColor,
    quoteInProgressColor,
    quoteUpdatingColor,
    quoteAcceptedColor,
    quoteExpiredColor,
    quoteRefusedColor,
    quoteWaitingReview,
    quoteWaitingCustomer,
    quoteInProgress,
    quoteUpdating,
    quoteAccepted,
    quoteExpired,
    quoteRefused,
};
