import {defaultFont} from "../../main";

const UnauthenticatedBarStyle = theme => ({
    appBar: {
        ...defaultFont,
        backgroundColor: "#FFFFFF",
        boxShadow: "0 2px 2px 0 rgba(0, 0, 0, 0), 0 3px 1px -2px rgba(0, 0, 0, 0.01), 0 1px 5px 0 rgba(0, 0, 0, 0.13)",
    },
    appBar_button: {
        fontWeight: "bold",
        "&:hover": {
            color: "#FFF",
        }
    },
    container: {
        width: "100%",
        marginLeft: "0",
    },
    logo_container: {
        display: "flex",
    },
    logo: {
        maxHeight: "32px"
    },
    toolbar: theme.mixins.toolbar,

    "@media (max-width: 599px)": {
        logo_container: {
            justifyContent: "center",
            width: "100%"
        },
        buttons_container: {
            justifyContent: "center",
        },
    }
});

export default UnauthenticatedBarStyle;
