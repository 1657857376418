import {dangerColor, primaryColor} from "../main";

const stripeStyle = theme => ({
    containerCard: {
        marginTop: '15%',
        width: '400px',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    card: {
        textAlign: 'center',
        width: '400px',
        paddingLeft: '0',
        paddingRight: '0',
        marginBottom: '5px',
    },
    cardHeader: {
        paddingBottom: '0px',
    },
    cardBody:{
        paddingTop: '0px',
    },
    logo: {
        width: '50px',
    },
    error: {
        color: dangerColor,
    },
    button: {
        width: '100%',
        backgroundColor: primaryColor,
        color: '#FFFFFF',
        textTransform: 'none',
        padding: '15px',
        "&:hover": {
            backgroundColor: primaryColor,
        }
    },
    link: {
        textDecoration: 'none'
    },
    logoFooter: {
        height: '20px',
    },
    logoutLink:{
        textDecoration: 'none',
        marginBottom: '15px',
        color: primaryColor,
        display: 'block',
    }
});

export default stripeStyle;
