import React from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';

import TextField from '@mui/material/TextField';

import {
    borderGrayColor,
    greyColorHover,
    infoColor,
    whiteColorDisabled
} from 'assets/jss/main';

CommentField.propTypes = {
    isEditing: PropTypes.bool,
    disabled: PropTypes.bool
};

function CommentField({ disabled, isEditing, ...props }) {
    return (
        <TextField
            disabled={disabled}
            minRows={4}
            multiline={true}
            name={'commentNote'}
            placeholder={!disabled && props.intl.formatMessage({id: 'picking.quote.detail.addCommentNote.field.placeholder'})}
            sx={{
                width: '100%',
                '& .MuiOutlinedInput-root': {
                    backgroundColor: isEditing && greyColorHover,
                    borderRadius: '8px',
                    fontSize: '14px',

                    '& fieldset': {
                        borderColor: isEditing ? infoColor : borderGrayColor,
                    },

                    '&.Mui-disabled': {
                        backgroundColor: whiteColorDisabled,

                        'fieldset': {
                            borderColor: borderGrayColor
                        }
                    },

                    '&.Mui-focused fieldset': {
                        border: `1px solid ${infoColor}`,
                    },

                    '&:hover:not(.Mui-disabled) .MuiOutlinedInput-notchedOutline' : {
                        borderColor: infoColor
                    },
                },
                '& .MuiInputBase-input': {
                    padding: '0 0 60px 0',
                },
            }}
            {...props}
        />
    );
}

export default injectIntl(CommentField);
