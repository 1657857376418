import React from "react";
import PropTypes from "prop-types";

import {Divider} from "@mui/material";

import SelectTitle from "components/radioGroup/SelectTitle";

import CategoryOverload from "./components/CategoryOverload";

import {makeStyles} from "@mui/styles";
import {borderGrayColor} from "assets/jss/main";

const useStyles = makeStyles({
    divider: {
        marginTop: '32px',
        marginBottom: '32px'
    }
})

export default function CategoryOverloadField(props) {

    const { formFieldName } = props;

    const classes = useStyles();

    return (
        <>
            <SelectTitle
                titleTranslationKey={'recommendations.form.filter.category_overload.title'}
                subtitleTranslationKey={'recommendations.form.filter.category_overload.subtitle'}
            />
            <CategoryOverload formFieldName={formFieldName} />
            <Divider
                className={classes.divider}
                color={borderGrayColor}
            />
        </>
    )
}

CategoryOverloadField.propTypes = {
    formFieldName: PropTypes.string.isRequired
}
