import {combineReducers} from "redux"

export function loading(state = false, action) {
    switch (action.type) {
        case "CATEGORY_UPDATE_POSITION_LOADING":
            return action.loading;

        default:
            return state;
    }
}

export function error(state = false, action) {
    switch (action.type) {
        case "CATEGORY_UPDATE_POSITION_ERROR":
            return action.error;

        default:
            return state;
    }
}

export function updated(state = null, action) {
    switch (action.type) {
        case "CATEGORY_UPDATE_POSITION_SUCCESS":
            return action.updated;

        default:
            return state;
    }
}

export default combineReducers({loading, error, updated});
