import React from 'react';
import PropTypes from 'prop-types';

import {styled} from "@mui/styles";

import {lightGrayColor} from "assets/jss/main";

LastNoteContent.propTypes = {
    content: PropTypes.string.isRequired,
};

export default function LastNoteContent({content = ''}) {
    const StyledContent = styled('div')(({theme}) => ({
        padding: '20px 32px',
        [theme.breakpoints.down('md')]: {
            padding: '24px 16px'
        },
        color: '#000',
        fontWeight: 400,
        borderTop: `1px solid ${lightGrayColor}`,
        borderBottom: `1px solid ${lightGrayColor}`,
        whiteSpace: 'pre-line',
    }));

    return !!content && (
        <StyledContent>
            {content}
        </StyledContent>
    );
}
