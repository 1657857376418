import React, {Component} from "react";
import PropTypes from "prop-types";
import {injectIntl} from "react-intl";
import {connect} from "react-redux";

// @mui/material components
import {
    Checkbox,
    Hidden,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
    Tooltip,
} from "@mui/material";

// actions
import {setIsSelectAll, setSelected} from "../../../actions/product/selection";

// styles
import {marketPlaceOffer} from "../../enum/offers";
import tableStyle from "assets/jss/views/product/tableHeadStyle";
import {UserAgentUtils} from "../../../utils/useragent";
import {withStyles} from "@mui/styles";

class ProductsTableHead extends Component {
    createSortHandler = property => event => {
        this.props.onRequestSort(event, property);
    };

    getTableHeadRows = () => {
        const rows =  [
            {id: "brand.name", numeric: false, disablePadding: true, label: this.props.intl.formatMessage({id: "retailer.product.list.header.brand"}), sort: false, hideOnMobile: true},
            {id: "translations.name", numeric: false, disablePadding: true, label: this.props.intl.formatMessage({id: "retailer.product.list.header.name"}), sort: true},
            {id: "categories", numeric: false, disablePadding: true, label: this.props.intl.formatMessage({id: "retailer.product.list.header.categories"}), sort: false, hideOnMobile: true},
            {id: "updatedAt", numeric: true, disablePadding: true, label: this.props.intl.formatMessage({id: "retailer.product.list.header.updatedAt"}), sort: true, hideOnMobile: true},
            {id: "lowestPrice", numeric: true, disablePadding: true, label: this.props.intl.formatMessage({id: "retailer.product.list.header.price"}), sort: false, hideOnMobile: true},
            {id: "stock", numeric: true, disablePadding: true, label: this.props.offer === marketPlaceOffer ? this.props.intl.formatMessage({id: "retailer.product.list.header.stock.status"}) : this.props.intl.formatMessage({id: "retailer.product.list.header.stock"}), sort: false, hideOnMobile: true},
        ];

        if (!UserAgentUtils.isMobile()) {
            rows.unshift({id: "id", numeric: false, disablePadding: true, label: this.props.intl.formatMessage({id: "retailer.product.list.header.article"}), sort: true, });
        }

        if (this.props.offer === marketPlaceOffer) {
            rows.push({id: "validated", numeric: true, disablePadding: true, label: this.props.intl.formatMessage({id: "retailer.product.list.header.valid"}), sort: false, hideOnMobile: true});
        }

        return rows;
    }

    renderCell(row) {
        const {state, classes} = this.props;

        return (
            <TableCell
                key={row.id}
                sortDirection={state[`order[${row.id}]`] !== undefined ? state[`order[${row.id}]`] : false}
                className={classes.primaryTableHeader}
            >
                <Tooltip
                    title={row.label}
                    placement={row.numeric ? "bottom-end" : "bottom-start"}
                    disableFocusListener
                    disableHoverListener
                >
                    {row.sort ?
                        <TableSortLabel
                            active={state[`order[${row.id}]`] !== undefined}
                            direction={state[`order[${row.id}]`]}
                            onClick={this.createSortHandler(row.id)}
                        >
                            {row.label}
                        </TableSortLabel>
                        :
                        <TableSortLabel hideSortIcon className={classes.noClick}>
                            {row.label}
                        </TableSortLabel>
                    }
                </Tooltip>
            </TableCell>
        )
    }

    render() {
        const {classes, onSelectAllClick, isSelectAll} = this.props;

        return (
            <TableHead className={classes.tableHeadCell}>
                <TableRow>
                    <TableCell>
                        {!UserAgentUtils.isMobile() && <Checkbox
                            classes={{root: classes.checkbox, checked: classes.checked}}
                            color={"primary"}
                            onChange={onSelectAllClick}
                            disabled={!this.props.isRetrievedFinished}
                            checked={isSelectAll}
                        />}
                    </TableCell>
                    {this.getTableHeadRows().map(row => {
                        if (row.hideOnMobile) {
                            return (
                                <Hidden smDown key={row.id}>
                                    {this.renderCell(row)}
                                </Hidden>
                            );
                        } else return this.renderCell(row)
                    }, this)}
                </TableRow>
            </TableHead>
        );
    }
}

ProductsTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
    return {
        selected: state.product.selection.selected,
        isSelectAll: state.product.selection.isSelectAll
    };
};

const mapDispatchToProps = dispatch => ({
    setSelected: (selected) => dispatch(setSelected(selected)),
    setIsTotalSelected: (selected) => dispatch(setIsSelectAll(selected)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(tableStyle)(injectIntl(ProductsTableHead)));
