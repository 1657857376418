import {combineReducers} from "redux";

export function itemsPerPage(state = null, action) {
    switch (action.type) {
        case "ITEMS_PER_PAGE_SUCCESS":
            return action.value;
        
        default:
            return state;
    }
}

export default combineReducers({
    itemsPerPage
});
