import {combineReducers} from "redux";

export function error(state = null, action) {
    switch (action.type) {
        case "FEED_UPLOAD_ERROR":
            return action.error;

        default:
            return state;
    }
}

export function loading(state = false, action) {
    switch (action.type) {
        case "FEED_UPLOAD_LOADING":
            return action.loading;

        default:
            return state;
    }
}

export function uploaded(state = null, action) {
    switch (action.type) {
        case "FEED_UPLOAD_SUCCESS":
            return action.uploaded;

        default:
            return state;
    }
}

export default combineReducers({error, loading, uploaded});
