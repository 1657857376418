import React, {Component} from "react";
import {withStyles} from "@mui/styles";
import {IconButton, Tooltip} from "@mui/material";
import {FormattedMessage, injectIntl} from "react-intl";

import listStyle from "assets/jss/views/quote/listStyle";
import {GetApp, NotificationsActive} from "@mui/icons-material";
import {downloadExport, downloadExportLineItem} from "actions/quote/export";
import {connect} from "react-redux";
import store from "store";
import Snackbar from "../../../../components/snackbar/snackbar";

class QuoteExporter extends Component {
    constructor(props) {
        super(props);

        this.state = {
            exported: false,
        };
    }

    render() {
        const { intl } = this.props;

        const handleSubmitQuote = () => {
            this.props.exportQuote(
                this.props.startDate,
                this.props.endDate,
                store.getState().currentOrganization?.retrieved.name
            );
            this.setState({exported: true})
        };

        const handleSubmitQuoteLineItem = () => {
            this.props.exportQuoteLineItem(
                this.props.startDate,
                this.props.endDate,
                store.getState().currentOrganization?.retrieved.name
            );
            this.setState({exported: true})
        };

        return (
            <span>
                <Tooltip title={intl.formatMessage({id: "picking.quote.list.export"})}>
                    <IconButton onClick={handleSubmitQuote} color="primary" size="large">
                        <GetApp/>
                    </IconButton>
                </Tooltip>
                <Tooltip title={intl.formatMessage({id: "picking.quote.line.item.list.export"})}>
                    <IconButton onClick={handleSubmitQuoteLineItem} color="primary" size="large">
                        <GetApp/>
                    </IconButton>
                </Tooltip>
                {this.state.exported && (
                    <Snackbar
                        open
                        close
                        closeNotification={() => this.setState({exported: false})}
                        place={"bl"}
                        color={"success"}
                        icon={() => <NotificationsActive/>}
                        message={<FormattedMessage id={"picking.quote.list.export.success"}/>}
                    />
                )}
            </span>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    exportQuote: (startDate, endDate, organization) => dispatch(downloadExport(startDate, endDate, organization)),
    exportQuoteLineItem: (startDate, endDate, organization) => dispatch(downloadExportLineItem(startDate, endDate, organization)),
});

export default connect(
    null,
    mapDispatchToProps
)(withStyles(listStyle)(injectIntl(QuoteExporter)));
