import React from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { Field, reduxForm } from "redux-form";

// core components
import AllTranslationsRequiredMessage from "../../configuration/components/AllTranslationsRequiredMessage";
import FormFields from './formFields';
import GridItem from "components/grid/gridItem";
import FormContainer from "views/configuration/components/FormContainer";
import ActionsContainer from "components/form/ActionsContainer";
import CancelButton from "views/configuration/components/CancelButton";
import SubmitButton from "views/configuration/components/SubmitButton";

// action
import { create } from "actions/sector/create";
import FormTranslator from "../../../components/translator/formTranslator";

function Form(props) {
    const { sector, closeDialog, create, createLoading, intl, isSubSector, submitting, values, requiredFields } = props;

    const isFormValid = values && !!(requiredFields.length && requiredFields.every((input) => {
        let translation = input.split('.');
        return values['translations'][translation[1]] && values['translations'][translation[1]][translation[2]];
    }));

    function submit(e) {
        e.preventDefault();
        if (!isFormValid) {
            return;
        }

        if (sector && isSubSector) {
            values.parent = sector["@id"];
        }

        create(values);
    }
    const renderTranslationForm = (index, code) => {
        if (requiredFields.indexOf(`translations.${code}.name`) === -1) {
            requiredFields.push(`translations.${code}.name`);
        }
        return (
            <div>
                <GridItem xs={12} sm={12}>
                    <Field
                        component={FormFields}
                        name={`translations.${code}.name`}
                        label={intl.formatMessage({id: sector ? "sector.form.label.sub.name" : "sector.form.label.name"})}
                        type={"text"}
                        placeholder={intl.formatMessage({id: "sector.form.placeholder.name"})}
                        required
                    />
                </GridItem>
            </div>
        );
    };

    return (
        <form onSubmit={(e) => submit(e)}>
            <FormContainer>
                <AllTranslationsRequiredMessage />
                {sector && (
                    <GridItem xs={12} sm={12}>
                        {sector}
                    </GridItem>
                )}
                <GridItem xs={12} sm={12}>
                    <FormTranslator formGenerator={renderTranslationForm} />
                </GridItem>
            </FormContainer>
            <ActionsContainer>
                <CancelButton onClick={closeDialog} />
                <SubmitButton
                    disabled={!isFormValid || submitting}
                    loading={createLoading}
                />
            </ActionsContainer>
        </form>
    )
}

const mapStateToProps = state => {
    return {
        createLoading: state.sector.create.loading,
        values: state.form && state.form.addSector && state.form.addSector.values,
    }
};

const mapDispatchToProps = dispatch => ({
    create: (values) => dispatch(create(values)),
    requiredFields: [],
});

export default reduxForm({
    form: "addSector",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
})(connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(Form)));
