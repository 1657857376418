import {SubmissionError} from "redux-form";
import {fetch} from "../../../utils/dataAccess";

export function error(error) {
    return {type: "MAPPER_CREATE_ERROR", error};
}

export function loading(loading) {
    return {type: "MAPPER_CREATE_LOADING", loading};
}

export function success(created) {
    return {type: "MAPPER_CREATE_SUCCESS", created};
}

export function create(feed) {
    let values = {};
    values.feed = feed["@id"];

    return dispatch => {
        dispatch(loading(true));
        dispatch(error(""));

        return fetch("/mappers", {method: "POST", body: JSON.stringify(values)})
            .then(response => {
                dispatch(loading(false));

                return response.json();
            })
            .then(retrieved => {
                retrieved.feed = feed;
                dispatch(success(retrieved))
            })
            .catch(e => {
                dispatch(loading(false));

                if (e instanceof SubmissionError) {
                    dispatch(error(e.errors._error));
                    throw e;
                }

                dispatch(error(e.message));
            });
    };
}