import React from "react";
import PropTypes from 'prop-types';
import {FormattedMessage} from "react-intl";
import {useHistory} from "react-router-dom";

import {Chip} from "@mui/material";
import {makeStyles} from "@mui/styles";

import GridItem from "components/grid/gridItem";
import CardBody from "components/card/cardBody";
import GridContainer from "components/grid/gridContainer";

import Loader from "views/marketplace/dashboard/components/loader";
import CardStats from "views/marketplace/dashboard/components/cards/cardStats";
import {tabs} from "views/quote/components/list/stateSwitcher";

import {
    irisBlueColor,
    lightGrayColor,
    primaryColor,
    purpleColor,
    thunderGrayColor,
    yellowColor
} from "assets/jss/main";

import {ITEM_PER_PAGE} from "config/_pagination";

const useStyles = makeStyles(theme => ({
    rightCardOnlineOrdersTitle: {
        textAlign: "left",
        padding: "15px",
        fontSize: "16px",
        color: thunderGrayColor,
        fontWeight: "400"
    },
    rightCardOnlineOrdersContainer: {
        textAlign: "left",
        "& > div": {
            padding: "5px 0px",
            borderTop: "1px solid " + lightGrayColor,
            textAlign: "right",
            color: primaryColor,
            fontSize: "18px",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            "& > a": {
                width: "100%",
                fontWeight: "bold"
            }
        }
    },
    tabAwaiting: {
        backgroundColor: 'rgba(230, 194, 41, 0.2)',
        color: yellowColor,
        '&:hover': {color: "#C1A635",opacity: 1,},
        '&$tabSelected': {color: "#C1A635",},
        '&:focus': {color: "#C1A635",},
        marginRight: "10px",
    },
    tabInternalAction: {
        color: irisBlueColor,
        backgroundColor: 'rgba(0, 172, 193, 0.2)',
        '&:hover': {color: '#058898',opacity: 1,},
        '&$tabSelected': {color: "#058898", backgroundColor: 'rgba(0, 172, 193, 0.2)'},
        '&:focus': {color: "#058898",},
    },
    tabCustomerAction: {
        color: purpleColor,
        backgroundColor: 'rgba(118, 46, 185, 0.2)',
        '&:hover': {color: '#491C74',opacity: 1,},
        '&$tabSelected': {color: "#491C74", backgroundColor: 'rgba(118, 46, 185, 0.2)'},
        '&:focus': {color: "#491C74",},
    },
    tabValidated: {
        color: 'rgb(120, 188, 97)',
        backgroundColor: 'rgba(120, 188, 97, 0.2)',
        '&:hover': {color: '#43832E',opacity: 1,},
        '&$tabSelected': {color: "#43832E", backgroundColor: 'rgb(120, 188, 97, .2)'},
        '&:focus': {color: "#43832E",},
    },
    tabCancelled: {
        color: 'rgba(237, 106, 90)',
        backgroundColor: 'rgba(237, 106, 90, 0.2)',
        '&:hover': {color: '#BD2A1F',opacity: 1,},
        '&$tabSelected': {color: "#BD2A1F'", backgroundColor: 'rgba(237, 106, 90, 0.2)'},
        '&:focus': {color: "#BD2A1F",},
    },
}));

export default function DetailsQuotes(props) {
    const classes = useStyles();

    const history = useHistory();

    const {
        canClick,
        loading,
        states
    } = props

    function handleRedirect(tab) {
        if (canClick) {
            history.push(`/quotes?tab=${encodeURI(tab.dataKey)}&itemsPerPage=${ITEM_PER_PAGE}&page=1`);
        }
    }

    return (
        <CardStats>
            <div className={classes.rightCardOnlineOrdersTitle}>
                <FormattedMessage id={"dashboard.quotes.card.title.quotesStates"} />
            </div>
            <Loader loading={loading} />
            <CardBody>
                <GridContainer className={classes.rightCardOnlineOrdersContainer}>
                    {tabs.map((tab) => {
                        return (
                            <>
                                <GridItem xs={8}>
                                    <Chip
                                        onClick={() => handleRedirect(tab)}
                                        component={"a"}
                                        className={classes[tab.class]}
                                        label={<FormattedMessage id={`dashboard.quotes.card.quotesStates.${tab.singleDataKey}`} />}
                                    />
                                </GridItem>
                                <GridItem xs={4}>
                                    {states?.[tab.singleDataKey].toLocaleString()}
                                </GridItem>
                            </>
                        )
                    })}
                </GridContainer>
            </CardBody>
        </CardStats>
    )
}

DetailsQuotes.propTypes = {
    canClick: PropTypes.bool,
    loading: PropTypes.bool,
    states: PropTypes.array
};
