import React from 'react'
import PropTypes from "prop-types";

import makeStyles from "@mui/styles/makeStyles";
import {styled} from "@mui/material/styles";

import Checkbox from "@mui/material/Checkbox";

import {infoColor, radioCircleGrey} from "assets/jss/main";

/*
need to create those icon to get non-transparent checkbox
*/
const BpIcon = styled('span')(({ theme }) => ({
    borderRadius: 4,
    width: 16,
    height: 16,
    backgroundColor: '#FFFFFF',
    border: `1px solid ${radioCircleGrey}`
}));

const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: infoColor,
    border: `1px solid ${infoColor}`,
    '&:before': {
        display: 'block',
        width: 14,
        height: 14,
        backgroundImage:
            "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
            " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
            "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
        content: '""',
    },
    'input:hover ~ &': {
        backgroundColor: '#106ba3',
    },
});

const useStyle = makeStyles({
    hidden: {
        visibility: 'hidden'
    }
})
export default function CustomCheckbox(props) {
    const classes = useStyle();

    return (
        <Checkbox
            {...props}
            className={props.hidden ? classes.hidden : ''}
            icon={<BpIcon />}
            checkedIcon={<BpCheckedIcon />}
            sx={{
                '&.Mui-checked': {
                    color: infoColor
                },
            }}
        />
    )
}

CustomCheckbox.defaultProps = {
    checked: false,
    onChange: () => {}
};

CustomCheckbox.propTypes = {
    checked: PropTypes.bool,
    onChange: PropTypes.func
};
