import React from "react";
import { FormattedMessage } from "react-intl";

// @mui/material components
import {makeStyles} from "@mui/styles";

// styles
import { infoColor } from "../../../assets/jss/main";
import {getTranslation} from "../../../domain/helpers/translations";

const useStyles = makeStyles(theme => ({
    hierarchyContainer: {
        padding: "5px"
    },
    hierarchy: {
        color: infoColor
    },
    topAttributeName: {
        textTransform: "uppercase",
        color: infoColor,
        fontWeight: "bold"
    },
}));

export default function Hierarchy (props) {
    const { attributeName, moveAttribute, newParent, attributeSelected } = props;
    const classes = useStyles();

    const hierarchy = (newParent || attributeSelected) && createHierarchy(newParent || attributeSelected);

    function getIndexByValue(hierarchy, attribute) {
        for (let i = 0; i < hierarchy.length; i++ ) {
            if (hierarchy[i].id === attribute.id) {
                return i;
            }
        }

        return -1;
    }

    function createHierarchy(attribute) {
        let hierarchyArray = [];

        while (attribute && attribute.parent !== null) {
            hierarchyArray.push(attribute);

            attribute = attribute.parent;
        }

        if (attribute && attribute.parent === null) {
            hierarchyArray.push(attribute);
        }

        hierarchyArray = hierarchyArray.reverse();

        if (typeof (newParent !== 'undefined') && newParent && newParent.id !== attributeSelected.id) {
           // check if attributeSelected already exist in hierarchy
            let index = getIndexByValue(hierarchyArray, attributeSelected);

            if (index >= 0) {
                hierarchyArray.splice(index, 1);
            }

            // push attributeSelected at the end of hierarchy
            hierarchyArray.push(attributeSelected);
        } else if (newParent === null) {
            hierarchyArray = [attributeSelected]
        }

        return hierarchyArray
    }

    function renderHierarchy(hierarchy) {
        let hierarchyString = '';

        hierarchy.slice(1).forEach((attribute) => {
            hierarchyString += ' > ' + getTranslation(attribute).name;
        });

        hierarchyString.slice(0, -1);

        return hierarchyString;
    }

    return (
        <div className={classes.hierarchyContainer}>
            <span className={classes.hierarchy}>
                <FormattedMessage id={moveAttribute ? `${attributeName}.form.move.label.hierarchy` : `${attributeName}.form.label.hierarchy`} />
                <span className={classes.topAttributeName}>
                    {getTranslation(hierarchy[0]).name}
                </span>
                {renderHierarchy(hierarchy)}
            </span>
        </div>
    );
}
