import {combineReducers} from "redux";

export function error(state = null, action) {
    switch (action.type) {
        case "ORDER_UPDATE_ERROR":
            return action.error;

        default:
            return state;
    }
}

export function loading(state = false, action) {
    switch (action.type) {
        case "ORDER_UPDATE_LOADING":
            return action.loading;

        default:
            return state;
    }
}

export function success(state = null, action) {
    switch (action.type) {
        case "ORDER_UPDATE_SUCCESS":
            return action.success;

        default:
            return state;
    }
}

export default combineReducers({
    error,
    loading,
    success
});