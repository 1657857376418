import React from "react";
import { FormattedMessage } from "react-intl";

// @mui/material components
import {makeStyles} from "@mui/styles";

// styles
import { thunderGrayColor } from "../../../assets/jss/main";

const useStyles = makeStyles(theme => ({
    tableCellNoResult: {
        fontSize: "16px",
        color: thunderGrayColor,
        position: "absolute",
        left: "0",
        right: "0",
        margin: "0 auto",
        top: "48%",
        display: "flex",
        justifyContent: "center",
        fontWeight: "bold",
    },
}));

export default function NoResult (props) {
    const classes = useStyles();

    return (
        <div className={classes.tableCellNoResult}>
            <FormattedMessage id={"member.tab.all.table.no.result"}/>
        </div>
    );
}
