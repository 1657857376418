import React, {Component} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import UnauthenticatedBar from "../../components/topbar/unauthenticatedBar";
import {redirectToSubdomain} from "../../utils/redirectToOrganizationSubdomain";

export default function (ComposedComponent, TemplateComponent = null) {
    class NoRequireAuthentication extends Component {
        componentDidMount() {
            if (this.props.member && this.props.organizations) {
                redirectToSubdomain(this.props.organizations[this.props.member["organization"]]["subdomain"])
            }
        }
        UNSAFE_componentWillReceiveProps(nextProps) {
            if (nextProps.member && nextProps.organizations) {
                redirectToSubdomain(nextProps.organizations[nextProps.member["organization"]]["subdomain"])
            }
        }

        PropTypes = {
            router: PropTypes.object,
        }

        render() {
            let content = <ComposedComponent {...this.props} />;
            if (TemplateComponent) {
                content = (
                    <TemplateComponent>
                        {content}
                    </TemplateComponent>
                )
            }

            return <div><UnauthenticatedBar/>{content}</div>;
        }
    }

    function mapStateToProps(state) {
        return {
            member: state.authentication.member,
            organizations: state.authentication.organizations
        };
    }

    return connect(mapStateToProps)(NoRequireAuthentication);
}
