import React, { createContext, useReducer } from 'react';
import {navReducer} from "reducers/merchandising/navReducer";

export const MerchandisingContext = createContext();
export const MerchandisingDispatchContext = createContext();

export function MerchandisingProvider({ children }) {
    const initialFilter = {
        navigationType: null,
        navigationValue: null,
        navigationItem: null,
        expandingItem: null,
        sortField: 'ranking',
        sortDirection: 'asc',
        shouldReset: false,
        shouldReload: true,
        loading: true
    };

    const [navParam, dispatch] = useReducer(navReducer, initialFilter);

    return (
        <MerchandisingContext.Provider value={navParam}>
            <MerchandisingDispatchContext.Provider value={dispatch}>
                {children}
            </MerchandisingDispatchContext.Provider>
        </MerchandisingContext.Provider>
    );
}
