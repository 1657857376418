import React, {useContext} from "react";
import TreeItem from '@mui/lab/TreeItem';
import Typography from '@mui/material/Typography';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { styled } from '@mui/material/styles';
import { Box } from "@mui/system";
import {MerchandisingContext, MerchandisingDispatchContext} from "../../../../contexts/merchandisingContext";
import {withStyles} from "@mui/styles";
import merchandisingStyle from "../../../../assets/jss/views/merchandising/merchandisingStyle";

const StyledTreeItem = styled(TreeItem)(({ theme }) => ({
    '.MuiTreeItem-content': {
        flexDirection: 'row-reverse',
        '&:hover': {
            backgroundColor: "white"
        },
        '&.Mui-selected': {
            backgroundColor: "white",
            '&:hover': {
                backgroundColor: "white",
            },
            '&.Mui-focused': {
                backgroundColor: "white",
            }
        }
    }
}));

const StyledBox = styled(Box)(({ theme }) => ({
    display: 'flex', 
    alignItems: 'center',
    margin: "10px 0"
}));

function NavItem(props) {
    const { labelText, labelIcon: LabelIcon, item, loadingHandler, classes, ...other } = props;
    const dispatch = useContext(MerchandisingDispatchContext);
    const navParam = useContext(MerchandisingContext);

    const handleClick = () => {
        const regex = new RegExp('^[0-9]{1}$');
        if (regex.test(item.id)) {
            dispatch(
                {
                    type: 'expand',
                    expandingItem: props.item,
                    shouldReset: false,
                    shouldReload: false,
                }
            )
            return;
        }
        loadingHandler(true);
        dispatch(
            {
                type: 'nav',
                navigationItem: props.item,
                shouldReset: false,
                shouldReload: true,
            }
        )
    }

    const handleExpand = () => {
        dispatch(
            {
                type: 'expand',
                expandingItem: props.item,
                shouldReset: false,
                shouldReload: false,
            }
        )
    }

    function isTheTopAncestor(item) {
        const regex = new RegExp('^[0-9]{1}$');
        if (!regex.test(item.id)) {
            return false;
        }
        return item['@type'] === navParam.navigationItem['@type'];
    }

    function isParentOrCurrentItem(item) {
        if (null === navParam.navigationItem || undefined === navParam.navigationItem) {
            return false;
        }
        if (item === navParam.navigationItem) {
            return true;
        }
        let itemToCheck = navParam.navigationItem;
        if (itemToCheck === null || itemToCheck === undefined) {
            return false;
        }
         while (itemToCheck.parent !== null && itemToCheck.parent !== undefined) {
            itemToCheck = itemToCheck.parent;
            if (itemToCheck.id === item.id) {
                return true;
            }
        }
        return isTheTopAncestor(item);
    }

    const isInHierarchy = isParentOrCurrentItem(item);
    return (
        <StyledTreeItem
            label={
                <StyledBox onClick={handleClick} className={isInHierarchy ? classes.navigationIsInHierarchy : ''}>
                    {LabelIcon && <LabelIcon color="inherit" />}
                    <Typography sx={{ paddingLeft: '10px' }}>{labelText}</Typography>
                </StyledBox>
            }
            collapseIcon={<ArrowDropUpIcon onClick={handleExpand} className={isInHierarchy ? classes.navigationIsInHierarchy : ''}/>}
            expandIcon={<ArrowDropDownIcon onClick={handleExpand} className={isInHierarchy ? classes.navigationIsInHierarchy : ''}/>}
            {...other}
        />
    );
}

export default withStyles(merchandisingStyle)(NavItem);
