// if nullLast is defined, null values will be returned at the end
export function sortOn(property, nullLast = false)
{
    return function (a, b) {
        if (nullLast !== false) {
            if (a[property] === null) {
                return 1;
            } else if (b[property] === null) {
                return -1;
            }
        }

        let elementA = typeof a[property] === 'string' ? a[property].toLowerCase() : a[property];
        let elementB = typeof b[property] === 'string' ? b[property].toLowerCase() : b[property];

        if (elementA < elementB) {
            return -1;
        } else if (elementA > elementB) {
            return 1;
        } else {
            return 0;
        }
    }
}
