import React from "react";
import GridContainer from "../../components/grid/gridContainer";
import GridItem from "../../components/grid/gridItem";
import Card from "../../components/card/card";
import CardBody from "../../components/card/cardBody";
import {withStyles} from "@mui/styles";
import AuthenticationStyle from "../../assets/jss/views/authentication/authenticationStyle";

const AuthenticationPageTemplate = ({ classes, children }) => {
  return (
    <GridContainer
      container
      direction="column"
      alignItems="center"
      className={classes.gridContainer}
    >
      <GridItem xs={11} sm={8} md={6} lg={4}>
        <Card>
          <CardBody className={classes.cardBody}>
            {children}
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default withStyles(AuthenticationStyle)(AuthenticationPageTemplate);
