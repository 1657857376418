import React from 'react';
import PropTypes from 'prop-types';

import {styled} from "@mui/material/styles";

import {greyishBlue300, greyishBlue400} from "assets/jss/main";

FileInfosText.propTypes = {
    disabled: PropTypes.bool
};

const StyledSpan = styled('span')(({ theme, disabled}) => ({
    fontSize: '12px',
    color: greyishBlue400,
    ...disabled && {
        color: greyishBlue300,
    },
}));

function FileInfosText({disabled, ...props}) {
    return (
        <StyledSpan disabled={disabled}>
            {props.children}
        </StyledSpan>
    );
}

export default FileInfosText;
