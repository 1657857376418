import {fetch} from "../../../utils/dataAccess";

export default function (invitationToken) {
    return new Promise((resolve, reject) => {
        fetch('/authentication/invitation/' + invitationToken, {method: "GET"}, true)
            .then(response => {
                response.json().then(response => {
                    resolve(response);
                });
            })
            .catch(error => reject(error));
    });
}
