import React, { Component } from 'react';
import Select from 'react-select';
import {ITEM_PER_PAGE} from "config/_pagination";
import {FormattedMessage, injectIntl} from "react-intl";
import {Field, reduxForm, reset, change} from "redux-form";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import connect from "react-redux/es/connect/connect";
import {BeatLoader} from "react-spinners";

// @mui/material components
import {Dialog, DialogContent, Divider, Fab, } from "@mui/material";
import {withStyles} from "@mui/styles";

// @mui/material icons
import {AddAPhoto, ArrowBackIos, HighlightOff, NotificationsActive} from "@mui/icons-material";

// core components
import Button from "components/button/button";
import GridContainer from "components/grid/gridContainer";
import GridItem from "components/grid/gridItem";
import Input from "components/input/input";
import Snackbar from "../../../../../components/snackbar/snackbar";

// assets
import createVariationFormStyle from "assets/jss/views/product/createVariationFormStyle";

// actions
import {create} from "actions/variation/create";

// utils
import { v4 as uuidv4 } from 'uuid';
import FormTranslator from "../../../../../components/translator/formTranslator";
import {PRICE} from "../../../../../domain/enum/regexs";
import BarcodeScanner from "../../../../../components/barcodeScanner";
import {ReactComponent as BarcodeScannerIcon} from "../../../../../assets/img/barcode_scanner.svg";
import {UserAgentUtils} from "../../../../../utils/useragent";
import { isFloat } from "utils/typeValidation";

class CreateVariationForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedOptionColor: false,
            selectedOptionSize: false,
            itemsPerPage: this.props.itemsPerPage !== null ? this.props.itemsPerPage : ITEM_PER_PAGE,
            pictures: [],
            variationsHasBarcode: false,
            errors: [],
            notificationCreateError: false
        }

        this.renderField = this.renderField.bind(this);
    }

    componentDidMount() {
        const {variationsList} = this.props;

        let variationsWithBarcode = variationsList.filter(variation => typeof variation.barcode !== 'undefined');

        // if retailer has never enter a barcode for the variation of the current product
        // we admit that it does not have a barcode for this one
        if (variationsWithBarcode.length > 0) {
            this.setState({variationsHasBarcode: true});
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        const {pictures} = this.state;

        // has success on create variation
        if (nextProps.createSuccess !== null) {
            this.setState({
                selectedOptionColor: false,
                selectedOptionSize: false,
            });

            this.props.resetForm('createVariation');
        }

        if ((nextProps.uploadSuccess !== this.props.uploadSuccess) && !this.props.uploadOrRemoveImageUpdateForm) {
            pictures.push(nextProps.uploadSuccess);
            this.setState({pictures: pictures});
        }

        if (nextProps.removeSuccess && !this.props.uploadOrRemoveImageUpdateForm) {
            pictures.forEach((picture, index) => {
                if (typeof picture === 'object' && picture['@id'] === nextProps.removeSuccess) {
                    delete pictures[index];
                    let newPictures = pictures.filter((pic, indexIt) => indexIt !== index);

                    this.setState({pictures: newPictures});
                }
            });
        }
    }

    handleChangeColor = (selectedOption) => {
        this.setState({
            selectedOptionColor: selectedOption,
        });
    }

    handleChangeSize = (selectedOption) => {
        this.setState({
            selectedOptionSize: selectedOption,
        });
    }

    addPictureInProduct = (event) => {
        this.props.handleFileUpload(event.target.files, "product_picture", null);
        event.target.value = null; // Otherwise we can't add/remove and re-add a same file
    }

    handleBarcodeScanned(barcode) {
        this.props.changeField('createVariation', "sku", barcode);
        this.setState({
            barcodeScanActive: false,
        });
    }

    renderBarcodeScanner(index, code) {
        const {barcodeScanActive} = this.state;

        return (
            <Dialog
                open={barcodeScanActive}
                aria-labelledby="responsive-dialog-title"
                onClose={() => this.setState({barcodeScanActive: false})}
            >
                <DialogContent>
                    <BarcodeScanner
                        hideButtons
                        onBarcodeFound={(barcode) => this.handleBarcodeScanned(barcode)}
                        onExit={() => this.setState({barcodeScanActive: false})}
                        allowLoopScanning={true}
                    />
                </DialogContent>
            </Dialog>
        );
    }

    renderError(fieldName) {
        const {classes, createError} = this.props; // error return by the back
        const {errors} = this.state; // error return by the front

        let errorMessage = null;
        let error = createError !== null ? createError : errors;

        if (error) {
            errorMessage = error[fieldName];
        }

        if (errorMessage) {
            return (
                <span className={classes.inputError}>
                    <FormattedMessage id={`${errorMessage}`} />
                </span>
            )
        }
    }

    removePictureFromProduct(item) {
        const {pictures} = this.state;

        if (window.confirm(this.props.intl.formatMessage({id: "product.form.action.media.remove"}))) {
            if (typeof item === 'object' && item['@id'] !== null) {
                this.props.removeFile(item['@id'])
            } else {
                pictures.splice(pictures.indexOf(item), 1);

                this.setState({pictures});
            }
        }
    }

    renderField = data => {
        const { classes } = this.props;

        return (
            <div className={classes.fieldContainer}>
                <label htmlFor={`product_${data.input.name}`} className={classes.fieldLabel}>
                    {data.label}
                </label>
                <div className={classes.formContainerLock}>
                    {(data.type !== "select" && data.type !== "checkbox" && data.type !== "textarea") && (
                        <Input
                            {...data.input}
                            type={data.type}
                            step={data.step}
                            required={data.required}
                            fullWidth={true}
                            margin={'dense'}
                            id={`product_${data.input.name}`}
                            inputRootCustomClasses={classes.input}
                            inputProps={{
                                placeholder: data.placeholder,
                                type: data.type,
                                multiline: data.multiline,
                                disabled: data.disabled,
                                required: data.required,
                                ...data.input,
                                className: data.className,
                                autoComplete: data.autoComplete
                            }}
                            formControlProps={{
                                className: [classes.formControl]
                            }}
                        />
                    )}
                    {data.type === "select" && (
                        <Select
                            className={classes.formSelect}
                            classes={data.classes}
                            classNamePrefix={"custom"}
                            name={data.name}
                            options={data.options}
                            value={data.selected}
                            isMulti={data.isMulti}
                            inputProps={{
                                ...data.input,
                                required: data.required,
                            }}
                            onChange={data.onChangeValue}
                            placeholder={data.placeholder}
                            noOptionsMessage={data.noOptionsMessage}
                        />
                    )}
                </div>
                {this.renderError(data.input.name)}
            </div>
        );
    };

    reorder(list, startIndex, endIndex) {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    }

    onDragEnd(result) {
        const {pictures} = this.state;

        if (!result.destination) {
            return;
        }

        if (result.destination.index === result.source.index) {
            return;
        }

        let orderedPictures = this.reorder(
            pictures,
            result.source.index,
            result.destination.index
        );

        this.setState({pictures: orderedPictures});
    }

    handleSubmit(event) {
        event.preventDefault();
        let errors = [];

        this.setState({ notificationCreateError: false });

        if (!this.props.productId) {
            return;
        }

        let data = this.props.values ? this.props.values : [];

        data['product'] = this.props.productId;
        data['pictures'] = this.state.pictures;

        if (this.state.variationsHasBarcode === false) {
            data['sku'] = uuidv4();
        } else if ((data['sku'] && data['sku'].length !== 13) || !data['sku']) {
            errors['sku'] = 'stockeasy.barcode.error.length';
        } else {
            data['barcode'] = data['sku'];
        }

        if (!!this.state.selectedOptionColor) {
            data['color'] = this.state.selectedOptionColor.value;
        }

        if (!!this.state.selectedOptionSize) {
            data['size'] = this.state.selectedOptionSize.value;
        } else {
            errors['size'] = 'stockeasy.color.sizes.error.empty';
        }

        let generateDefaultName = () => {
            let colorName = this.state.selectedOptionColor.label;
            let sizeName = this.state.selectedOptionSize.label;

            if (colorName && sizeName) {
                return sizeName + '/' + colorName;
            }

            return sizeName;
        };

        if (data.translations) {
            for (const [locale, translation] of Object.entries(data.translations)) {
                if (!data.translations[locale]['name']) {
                    data.translations[locale]['name'] = generateDefaultName();
                }
            }
        } else {
            data.translations = {};
            data.translations[this.props.currentOrganization.defaultLocale] = {
                name: generateDefaultName()
            };
        }

        if (Object.keys(errors).length > 0) {
            this.setState({
                errors: errors,
                notificationCreateError: true
            });
        } else {
            this.props.createVariation(data);
        }
    }

    renderSnackBar() {
        if (this.state.notificationCreateError) {
            return (
                <Snackbar
                    open
                    autoHideDuration={3000}
                    onClose={() => this.setState({notificationCreateError: false})}
                    place={"bl"}
                    color={"danger"}
                    icon={() => <NotificationsActive />}
                    message={<FormattedMessage id={"variation.form.message.error"}/>}
                />
            );
        }
    }

    renderTranslationForm = (index, code) => {
        const {classes, colorList, sizeList} = this.props;

        const checkPriceIsValid = (e) => {
            const newPrice = e.target.value;
            if (!PRICE.test(newPrice)) {
                e.preventDefault(); // This prevent the data to be update in the redux form
            }
        }

        return <div>
            {this.renderBarcodeScanner(index, code)}
            <GridContainer className={classes.formContainer}>
                <GridItem xs={12} sm={6}>
                    <Field
                        component={this.renderField}
                        name={`translations.${code}.name`}
                        label={<FormattedMessage id={"product.show.attribute.name"}/>}
                        type={"text"}
                    />
                </GridItem>
                <GridItem xs={12} sm={6}>
                    <Field
                        component={this.renderField}
                        name={"stock"}
                        label={<FormattedMessage id={"product.show.attribute.stock"}/>}
                        type={"number"}
                    />
                </GridItem>
                <GridItem xs={12} sm={6}>
                    <Field
                        className={classes.formSelect}
                        classes={classes}
                        label={<FormattedMessage id={"product.show.attribute.color"}/>}
                        type={"select"}
                        name={"color"}
                        options={colorList}
                        component={this.renderField}
                        selected={this.state.selectedOptionColor}
                        onChangeValue={this.handleChangeColor}
                        placeholder={<FormattedMessage id={"product.show.attribute.color"}/>}
                        noOptionsMessage={() => <FormattedMessage id={"product.form.message.noOptions"}/>}
                    />
                </GridItem>
                <GridItem xs={12} sm={6}>
                    <Field
                        className={classes.formSelect}
                        classes={classes}
                        name={"size"}
                        options={sizeList}
                        component={this.renderField}
                        selected={this.state.selectedOptionSize}
                        onChangeValue={this.handleChangeSize}
                        placeholder={<FormattedMessage id={"product.show.attribute.size"}/>}
                        noOptionsMessage={() => <FormattedMessage id={"product.form.message.noOptions"}/>}
                        label={<FormattedMessage id={"product.show.attribute.size"}/>}
                        type={"select"}
                    />
                </GridItem>
                <GridItem xs={12} sm={6}>
                    <Field
                        component={this.renderField}
                        name={"price"}
                        onChange={checkPriceIsValid}
                        label={<FormattedMessage id={"product.show.attribute.priceIncludedTax"}/>}
                        type={"price"}
                    />
                </GridItem>
                <GridItem xs={12} sm={6}>
                    <Field
                        component={this.renderField}
                        name={"weight"}
                        label={<FormattedMessage id={"product.show.attribute.weight"}/>}
                        placeholder={this.props.intl.formatMessage({id: "product.show.attribute.weight.placeholder"})}
                        type={"price"}
                        onChange={event => event.target.value !== '' && !isFloat(event.target.value) && event.preventDefault() }
                    />
                </GridItem>
                <GridItem xs={12} sm={6}>
                    <Field
                        component={this.renderField}
                        name={"outletPrice"}
                        label={<FormattedMessage id={"product.show.attribute.outletPrice"}/>}
                        onChange={checkPriceIsValid}
                        type={"price"}
                    />
                </GridItem>
                <GridItem xs={12} sm={6}>
                    <Field
                        component={this.renderField}
                        name={"discountedPrice"}
                        label={<FormattedMessage id={"product.show.attribute.discountedPrice"}/>}
                        onChange={checkPriceIsValid}
                        type={"price"}
                    />
                </GridItem>
                <GridItem xs={12} sm={6}>
                    <Field
                        component={this.renderField}
                        name={"ecoTax"}
                        label={<FormattedMessage id={"product.show.attribute.ecoTax"}/>}
                        type={"price"}
                    />
                </GridItem>
                {this.state.variationsHasBarcode && (
                    <GridItem xs={12} sm={6}>
                        <div className={classes.positionRelative}>
                            {UserAgentUtils.isMobile() && (
                                <Button
                                    round
                                    className={classes.scanButton}
                                    onClick={() => this.setState({barcodeScanActive: true})}
                                >
                                    <BarcodeScannerIcon className={classes.scanIcon}/>
                                </Button>
                            )}
                            <Field
                                value={"123456789"}
                                component={this.renderField}
                                name={"sku"}
                                label={<FormattedMessage id={"product.show.attribute.variation.sku"}/>}
                                type={"text"}
                            />
                        </div>
                    </GridItem>
                )}
            </GridContainer>
            <GridContainer className={classes.formContainer}>
                <GridItem xs={12} sm={12}>
                    <div className={classes.fieldContainer}>
                        <label className={classes.fieldLabel}>
                            <FormattedMessage id={"product.show.attribute.pictures"}/>
                        </label>
                    </div>
                    <input
                        accept="image/*"
                        onChange={(e) => this.addPictureInProduct(e)}
                        id="icon-button-file"
                        type="file"
                        className={classes.addImageInput}
                    />
                    <label htmlFor="icon-button-file">
                        <Fab
                            size={"small"}
                            color={"primary"}
                            aria-label="upload picture"
                            component="span"
                        >
                            {this.props.uploadLoading ? (
                                <BeatLoader sizeUnit={"px"} size={4} color={'#FFF'} />
                            ) : (
                                <AddAPhoto fontSize={"small"}/>
                            )}
                        </Fab>
                    </label>
                </GridItem>
                <GridItem xs={12} className={classes.imageSlider}>
                    <DragDropContext onDragEnd={(res) => this.onDragEnd(res)}>
                        <Droppable droppableId={"list"} direction={"horizontal"}>
                            {provided => (
                                <div
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                    className={classes.droppable}
                                >
                                    {this.state.pictures.map((item, index) => {

                                        let url = typeof item === 'object' && item.contentUrl !== null
                                            ? item.contentUrl
                                            : item
                                        return (
                                            <Draggable key={url} index={index} draggableId={url}>
                                                {provided => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        className={classes.imageContainer}
                                                    >
                                                        <img src={url} alt=""/>
                                                        <HighlightOff
                                                            fontSize={"large"}
                                                            className={classes.slickRemove}
                                                            onClick={() => this.removePictureFromProduct(item)}
                                                        />
                                                    </div>
                                                )}
                                            </Draggable>
                                        )
                                    })}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </GridItem>
            </GridContainer>
        </div>;
    };

    render() {
        const {classes, createLoading} = this.props;

        return (
            <div className={classes.container}>
                {this.renderSnackBar()}
                <form className={classes.sectionBody} onSubmit={(e) => this.handleSubmit(e)}>
                    <FormTranslator
                        formGenerator={this.renderTranslationForm}
                        changeLocale={(selectedLocale) => this.setState({selectedLocale: selectedLocale})}
                    />

                    <Divider />

                    <div className={classes.actionsContainer}>
                        <GridContainer className={classes.actionsGridContainer}>
                            <Button
                                color={"info"}
                                simple
                                onClick={() => this.props.showProductForm()}
                            >
                                <ArrowBackIos />
                                <FormattedMessage id={"variation.form.cancel"} />
                            </Button>
                            <Button
                                round
                                color={"success"}
                                type="submit"
                                disabled={this.props.createLoading}
                            >
                                <FormattedMessage id={"variation.form.submit"}/>
                                {createLoading && (
                                    <BeatLoader
                                        sizeUnit={"px"}
                                        size={4}
                                        color={"#FFF"}
                                        className={classes.submitLoading}
                                    />
                                )}
                            </Button>
                        </GridContainer>
                    </div>
                </form>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        createSuccess: state.variation.create.success,
        createLoading: state.variation.create.loading,
        createError: state.variation.create.error,
        itemsPerPage: state.itemsPerPage,
        values: state.form && state.form.createVariation && state.form.createVariation.values,
        uploadSuccess: state.upload.success,
        uploadLoading: state.upload.loading,
        removeSuccess: state.upload.removeSuccess,
        currentOrganization: state.currentOrganization.retrieved,
    };
};

const mapDispatchToProps = dispatch => ({
    createVariation: (values) => dispatch(create(values)),
    resetForm: (formName) => dispatch(reset(formName)),
    changeField: (formName, field, value) => dispatch(change(formName, field, value, false, false))
});

export default reduxForm({
    form: "createVariation",
    enableReinitialize: true
})(connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(createVariationFormStyle)(injectIntl(CreateVariationForm))));
