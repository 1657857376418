import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from "react-intl";

import BoldTitle from "../NoteBlock/BoldTitle";

import {QUOTE_COMMENT, QUOTE_INTERNAL_NOTE, QUOTE_NOTE_TYPES} from "../../../../../enums/quoteNoteType";

HistoryNoteAccordionSummaryTitle.propTypes = {
    noteType: PropTypes.oneOf(QUOTE_NOTE_TYPES).isRequired
}

export default function HistoryNoteAccordionSummaryTitle({noteType}) {

    function getTitleByNoteTypes(type) {
        switch (type) {
            case QUOTE_COMMENT:
                return <FormattedMessage id={'picking.quote.history.comment.title'} />
            case QUOTE_INTERNAL_NOTE:
                return <FormattedMessage id={'picking.quote.history.internalNote.title'} />
            default:
                return false;
        }
    }

    const title = getTitleByNoteTypes(noteType);

    return !!title && (
        <BoldTitle>{title}</BoldTitle>
    )

}
