function isValidTextField(content, required) {
    // we return an error when a field is required and has no content
    return !(required && !content);
}

function isValidSelect(content, required) {
    return !(required && content.length < 1);
}

function isUnique(array) {
    return new Set(array).size === array.length;

}

export function createValidation(name, value, valid, type, required = false, unique = false, active = true) {
    return {
        id: name,
        value: value,
        valid: valid,
        type: type,
        required: required,
        unique: unique,
        active: active,
    }
}

export function getVariationsKeys(variations) {
    const colors = [];

    variations.forEach(variation => {
        if (variation.selected) {
            colors.push(variation.color + variation.size);
        }
    })

    return colors;
}

export function getVariationsSku(variations) {
    const barcodes = [];

    variations.forEach(variation => {
        if (variation.sku) {
            barcodes.push(variation.sku);
            variation.barcode = /[0-9]{13}/.test(variation.sku) ? variation.sku : variation.barcode;
        }
    })

    return barcodes;
}

export function createVariationsValidations(variations) {
    const validations = [];

    // we add a global validation for color name uniqueness
    validations.push(createValidation('color', getVariationsKeys(variations),true, 'global', false, false, true));
    validations.push(createValidation('sku', getVariationsSku(variations), true, 'global', false, false, true));

    return validations;
}

export function getValidationById(id, validationsList) {
    if (!validationsList) {
        return;
    }

    let result = null;

    validationsList.forEach(validation => {
        if (validation.id === id) {
            result = validation
        }
    });

    return result;
}

export function validate(content, rules) {
    if (rules.type === 'text') {
        return isValidTextField(content, rules.required);
    } else if (rules.type === 'select') {
        return isValidSelect(content, rules.required);
    } else { // global
        return isUnique(content); // if it's global rule validation is computed in the step
    }
}

export function getUpdatedValidations(value, id, validations) {
    const field = getValidationById(id, validations);

    field.valid = validate(value, field)
    field.value = value;

    return validations;
}
