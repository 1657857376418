import React, {useEffect, useState} from "react";
import classNames from 'classnames'
import connect from "react-redux/es/connect/connect";
import {FormattedMessage} from "react-intl";

import {makeStyles} from "@mui/styles";

import {ArrowDownward} from "@mui/icons-material";
import CardStats from "../cards/cardStats";
import CardStatsBody from "../cards/cardStatsBody";
import Loader from "../loader";
import RankingTable from "./rankingTable";

import {retrieveRetailersRankingData} from "actions/dashboard";

import {grayColor, primaryColor, thunderGrayColor} from "assets/jss/main";

const useStyles = makeStyles(theme => ({
    tableHead: {
        display: 'flex',
        flexDirection: 'column',
        color: thunderGrayColor,
        '& span': {
            display: 'flex',
            alignItems: 'center',
        },
        '& span svg': {
            width: '18px',
            height: '18px',
        },
    },
    tableHeadSelected: {
        color: primaryColor,
    },
    tableHeadSelectable: {
        cursor: 'pointer',
    },
    info: {
        fontSize: '10px',
        color: grayColor,
    },
    ascOrder: {
        transform: 'rotate(180deg)',
    },
}));

function RetailersRanking(props) {
    const classes = useStyles();

    const {
        analyticsData,
        retrieveRetailersRankingData,
        dashboardRetailersRankingData,
        dashboardRetailersRankingDataLoading,
        startDate,
        endDate
    } = props

    const [rankingBy, setRanking] = useState('turnover');
    const [order, setOrder] = useState('desc')

    function toggleOrder(ranking) {
        // Changement de colonne on reset order
        if (ranking !== rankingBy) {
            setOrder('desc')
            return
        }

        if (order === 'desc') {
            setOrder('asc')
        } else {
            setOrder('desc')
        }
    }

    useEffect(() => {
        if (startDate !== null && endDate !== null) {
            retrieveRetailersRankingData(
                startDate.format("Y-M-D"),
                endDate.format("Y-M-D"),
                rankingBy,
                order,
            );
        }

    }, [retrieveRetailersRankingData, startDate, endDate, rankingBy, order]);

    const tableHead = [
        <div className={classes.tableHead}>
            <FormattedMessage id={"dashboard.retailersRanking.table.header.retailer"} />
        </div>,
        <div
            onClick={() => {
                setRanking('turnover')
                toggleOrder('turnover')
            }}
            className={classNames(
                classes.tableHead,
                classes.tableHeadSelectable,
                { [classes.tableHeadSelected]: rankingBy === 'turnover' },
            )}
        >
            <span>
                <FormattedMessage id={'dashboard.retailersRanking.table.header.turnover'} />
                <ArrowDownward
                    className={classNames(
                        { [classes[`${order}Order`]]: rankingBy === 'turnover' },
                    )}
                />
            </span>
        </div>,
        <div
            onClick={() => {
                setRanking('onlineTurnover')
                toggleOrder('onlineTurnover')
            }}
            className={classNames(
                classes.tableHead,
                classes.tableHeadSelectable,
                { [classes.tableHeadSelected]: rankingBy === 'onlineTurnover' },
            )}
        >
            <span>
                <FormattedMessage id={'dashboard.retailersRanking.table.header.onlineTurnover'} />
                <ArrowDownward
                    className={classNames(
                        { [classes[`${order}Order`]]: rankingBy === 'onlineTurnover' },
                    )}
                />
            </span>
            <div className={classes.info}>
                <FormattedMessage id={'dashboard.retailersRanking.table.header.onlineTurnover.info'} />
            </div>
        </div>,
        <div className={classes.tableHead}>
            <span>
                <FormattedMessage id={'dashboard.retailersRanking.table.header.turnoverPerSurface'} />
            </span>
            <div className={classes.info}>
                <FormattedMessage id={'dashboard.retailersRanking.table.header.turnoverPerSurface.info'} />
            </div>
        </div>,
        <div className={classes.tableHead}>
            <span>
                <FormattedMessage id={'dashboard.retailersRanking.table.header.unavailability'} />
            </span>
            <div className={classes.info}>
                <FormattedMessage id={'dashboard.retailersRanking.table.header.unavailability.info'} />
            </div>
        </div>,
        <div className={classes.tableHead}>
            <span>
                <FormattedMessage id={'dashboard.retailersRanking.table.header.return'} />
            </span>
            <div className={classes.info}>
                <FormattedMessage id={'dashboard.retailersRanking.table.header.return.info'} />
            </div>
        </div>,
    ];

    return (
        <CardStats>
            <Loader loading={dashboardRetailersRankingDataLoading} />
            <CardStatsBody>
                <RankingTable
                    tableHead={tableHead}
                    tableData={dashboardRetailersRankingData?.ranking}
                    analytics={analyticsData}
                />
            </CardStatsBody>
        </CardStats>
    )
}

const mapStateToProps = (state) => {
    return {
        dashboardRetailersRankingDataLoading: state.dashboard.retrieveRetailersRankingDataLoading,
        dashboardRetailersRankingData: state.dashboard.retrievedRetailersRankingData,
    };
};

const mapDispatchToProps = dispatch => ({
    retrieveRetailersRankingData: (
        startDate,
        endDate,
        rankingBy,
        order,
    ) => dispatch(retrieveRetailersRankingData(startDate, endDate, rankingBy, order)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RetailersRanking);
