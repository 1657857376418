import React, {Component} from "react";
import {connect} from "react-redux";
import {Link, Redirect} from "react-router-dom";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";

// @mui/material components
import {withStyles} from "@mui/styles";
import {Tooltip} from "@mui/material";

// @mui/icons-material
import {AddRounded, NotificationsActive} from "@mui/icons-material";

// core components
import Snackbar from "components/snackbar/snackbar";
import GridContainer from "components/grid/gridContainer";
import GridItem from "components/grid/gridItem";
import Card from "components/card/card";
import CardHeader from "components/card/cardHeader";
import CardBody from "components/card/cardBody";
import Table from "./table";
import Dropdown from "components/dropdown/dropdown";
import MappingAccessButton from "./components/mappingAccessButton";

// styles
import listStyle from "assets/jss/views/feed/listStyle";

// actions
import {list, reset} from "actions/feed/list";
import {reset as resetUpdate} from "actions/feed/update";
import {retrieve as showRetailer} from "actions/retailer/show";

import abort from "utils/abort";
import {capitalize} from "utils/capitalize";

class List extends Component {
    static propTypes = {
        retrieved: PropTypes.array,
        loading: PropTypes.bool.isRequired,
        error: PropTypes.string,
        deletedItem: PropTypes.object,
        list: PropTypes.func.isRequired,
        reset: PropTypes.func.isRequired,
        // Following statement doesnt work, but you got the idea
        // retailer: PropTypes.oneOfType([null, PropTypes.string]).isRequired
    };

    constructor(props) {
        super(props);

        this.state = {
            importNotification: true,
            errorNotification: true,
            deletedNotification: true,
            updatedNotification:true,
            retailerId: decodeURIComponent(this.props.match.params.id),
        };
    }

    componentDidMount() {
        this.props.list(
            this.props.match.params.id &&
            decodeURIComponent(this.props.match.params.id)
        );

        this.props.showRetailer(decodeURIComponent(this.props.match.params.id));
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.deletedItem !== nextProps.deletedItem) {
            nextProps.list(
                nextProps.match.params.id &&
                decodeURIComponent(nextProps.match.params.id)
            );

            // refresh missingMappings value when delete feed
            this.props.showRetailer(decodeURIComponent(this.props.match.params.id));
        }
    }

    componentWillUnmount() {
        this.props.reset();
        this.props.resetUpdate();
        abort.abortRequests();
    }

    render() {
        const {classes} = this.props;

        if (this.props.createdMapperItem) {
            return <Redirect
                to={`${encodeURIComponent(this.props.createdMapperItem.feed["@id"])}/mapper/edit/${encodeURIComponent(this.props.createdMapperItem["@id"])}`}/>;

        }
        return (
            <div className={classes.containerWithSidebar}>
                <div className={classes.container}>
                    {this.props.updated && (
                        <Snackbar
                            open={this.state.updatedNotification}
                            close
                            closeNotification={() => this.setState({updatedNotification: false})}
                            place={"bl"}
                            color={"success"}
                            icon={function () {
                                return <NotificationsActive/>
                            }}
                            message={<FormattedMessage id={"feed.notifications.feedUpdated"}
                                                       values={{name: capitalize(this.props.updated["name"])}}/>}
                        />
                    )}

                    {this.props.deletedItem && (
                        <Snackbar
                            open={this.state.deletedNotification}
                            close
                            closeNotification={() => this.setState({deletedNotification: false})}
                            place={"bl"}
                            color={"success"}
                            icon={function () {
                                return <NotificationsActive/>
                            }}
                            message={<FormattedMessage id={"feed.notifications.deleted"}
                                                       values={{name: capitalize(this.props.deletedItem["name"])}}/>}
                        />
                    )}
                    {this.props.deletedMapperItem && (
                        <Snackbar
                            open={this.state.deletedNotification}
                            close
                            closeNotification={() => this.setState({deletedNotification: false})}
                            place={"bl"}
                            color={"success"}
                            icon={function () {
                                return <NotificationsActive/>
                            }}
                            message={<FormattedMessage id={"mapper.notifications.deleted"}
                                                       values={{name: this.props.deletedMapperItem["name"]}}/>}
                        />
                    )}
                    {this.props.error && (
                        <Snackbar
                            open={this.state.errorNotification}
                            close
                            closeNotification={() => this.setState({errorNotification: false})}
                            place={"bl"}
                            color={"danger"}
                            icon={function () {
                                return <NotificationsActive/>
                            }}
                            message={this.props.error}
                        />
                    )}

                    {this.props.importingProductSuccess && (
                        <Snackbar
                            open={this.state.importNotification}
                            close
                            closeNotification={() => this.setState({importNotification: false})}
                            place={"bl"}
                            color={"success"}
                            icon={function () {
                                return <NotificationsActive/>
                            }}
                            message={<FormattedMessage id={"feed.notifications.productsImportInProgress"}/>}
                        />
                    )}

                    <GridContainer>
                        <GridItem xs={12}>
                            <Card className={classes.card}>
                                <CardHeader color="info">
                                    <GridContainer spacing={2}>
                                        <GridItem xs={6} sm={6} md={8}>
                                            <h3><FormattedMessage id={"feed.list.title"}/></h3>
                                        </GridItem>
                                        <GridItem xs={6} sm={6} md={4} className={classes.mainActionsContainer}>
                                            <GridContainer className={classes.buttonsWrapper}>
                                                <GridItem xs={6} sm={6} >
                                                    <MappingAccessButton
                                                        retailerId={this.state.retailerId}
                                                        numberOfAttributesToMap={this.props.retailer?.missingMappings}
                                                    />
                                                </GridItem>

                                                <GridItem xs={6} sm={6} md={4} lg={3}>
                                                {((!this.props.loading && !this.props.deleteLoading) || !this.props.retrieved) &&
                                                <Dropdown
                                                    placement={"top-end"}
                                                    fab={true}
                                                    left={true}
                                                    hoverColor="primary"
                                                    buttonIcon={<Tooltip title={<FormattedMessage
                                                        id={"feed.list.tooltip.create"}/>}><AddRounded
                                                        fontSize={"large"}/></Tooltip>}
                                                    buttonIconNoPadding={true}
                                                    customIconColor={"white"}
                                                    dropdownList={[
                                                        <Link className={classes.dropdownLink}
                                                              to="csv-local/create"><FormattedMessage
                                                            id={"feed.list.action.create.CSVLocalFeed"}/></Link>,
                                                        <Link className={classes.dropdownLink}
                                                              to="csv-remote/create"><FormattedMessage
                                                            id={"feed.list.action.create.CSVRemoteFeed"}/></Link>,
                                                        <Link className={classes.dropdownLink}
                                                              to="xml-local/create"><FormattedMessage
                                                            id={"feed.list.action.create.XMLLocalFeed"}/></Link>,
                                                        <Link className={classes.dropdownLink}
                                                              to="xml-remote/create"><FormattedMessage
                                                            id={"feed.list.action.create.XMLRemoteFeed"}/></Link>,
                                                        <Link className={classes.dropdownLink}
                                                              to="json-local/create"><FormattedMessage
                                                            id={"feed.list.action.create.JSONLocalFeed"}/></Link>,
                                                        <Link className={classes.dropdownLink}
                                                              to="json-remote/create"><FormattedMessage
                                                            id={"feed.list.action.create.JSONRemoteFeed"}/></Link>,
                                                        <Link className={classes.dropdownLink}
                                                              to="csv-ftp/create"><FormattedMessage
                                                            id={"feed.list.action.create.ftpFeed"}/></Link>,
                                                        <Link className={classes.dropdownLink}
                                                              to="xml-ftp/create"><FormattedMessage
                                                            id={"feed.list.action.create.xmlFtpFeed"}/></Link>,
                                                        <Link className={classes.dropdownLink}
                                                              to="json-ftp/create"><FormattedMessage
                                                            id={"feed.list.action.create.JSONFtpFeed"}/></Link>,
                                                        <Link className={classes.dropdownLink}
                                                              to="google-sheets-remote/create"><FormattedMessage
                                                            id={"feed.list.action.create.GoogleSpreadsheetFeed"}/></Link>,
                                                        <Link className={classes.dropdownLink}
                                                              to="api/create"><FormattedMessage
                                                            id={"feed.list.action.create.apiFeed"}/></Link>,
                                                    ]}
                                                />
                                                }
                                                </GridItem>
                                            </GridContainer>
                                        </GridItem>
                                    </GridContainer>
                                </CardHeader>
                                <CardBody>
                                    <Table
                                        tableHead={[
                                            <FormattedMessage id={"feed.list.table.header.type"}/>,
                                            <FormattedMessage id={"feed.list.table.header.name"}/>,
                                            <FormattedMessage id={"feed.list.table.header.recursive"}/>,
                                        ]}
                                        tableHeaderColor="primary"
                                        tableData={this.props.retrieved}
                                        match={this.props.match}
                                        retailerId={this.props.match.params.id}
                                    />
                                </CardBody>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    const {
        retrieved,
        loading,
        error,
    } = state.feed.list;

    const {deleted} = state.feed.del;
    const deleteLoading = state.feed.del.loading;
    const deletedMapperItem = state.mapper.del.deleted;
    const createdMapperItem = state.mapper.create.created;
    const importingProductSuccess = state.feed.importProduct.importing;

    return {
        retrieved,
        loading,
        error,
        deletedItem: deleted,
        deleteLoading,
        deletedMapperItem,
        createdMapperItem,
        importingProductSuccess,
        updated: state.feed.update.updated,
        retailer: state.retailer.show.retrieved,
    };
};

const mapDispatchToProps = dispatch => ({
    list: retailer => dispatch(list(retailer)),
    reset: () => dispatch(reset()),
    resetUpdate: () => dispatch(resetUpdate()),
    showRetailer: (retailerId) => dispatch(showRetailer(retailerId))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(listStyle)(List));
