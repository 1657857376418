import {dangerColor, infoColor, lightGrayColor, primaryColor} from "../../main";

const formStyle = {
    formContainer: {
        paddingBottom: window.innerHeight * 0.15
    },
    fieldContainer: {
        padding: "5px"
    },
    formControl: {
        padding: "0px",
        width: "100%"
    },
    fieldLabel: {
        color: primaryColor,
        fontWeight: "bold",
    },
    fieldMappingLabel: {
        color: primaryColor,
        fontWeight: "bold",
        alignSelf: "center"
    },
    fieldFloatRight: {
        float: "right"
    },
    compareArrows: {
        color: primaryColor,
    },
    selectField: {
        height: "100%",
        width: "80%",
        float: "right"
    },
    fileUploadContainer: {
        position: "relative",
        float: "right",
        padding: "0 5px",
        cursor: "pointer",
        maxWidth: "80%",
        borderWidth: "1px",
        borderColor: infoColor,
        borderStyle: "solid",
        borderRadius: "5px",
        color: primaryColor
    },
    fieldUpload: {
        display: "flex",
        padding: "5px"
    },
    fileUploadText: {
        paddingLeft: "10px"
    },
    input: {
        "& input": {
            textAlign: "left"
        }
    },
    inputError: {
        color: dangerColor
    },
    gridItemNoPadding: {
        padding: "0px"
    },
    inviteFieldContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: "100%"
    },
    inviteFieldLabel: {
        color: primaryColor,
        marginBottom: "5px"
    },
    inviteSelectField: {
        padding: "0px",
        width: "100%",
        height: "43px",
        borderWidth: "1px",
        borderColor: lightGrayColor,
        borderStyle: "solid",
        borderRadius: '4px',
        marginBottom: "15px"
    },
    inviteSwitchFieldContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    requiredSelect: {
        color: 'red',
        fontSize: '14px',
        width: "100%"
    },
    emailFieldLabel: {
        color: primaryColor,
    },
    errorMessages:{
        marginLeft:"5px",
        fontSize:"15px",
        color:'red'
    },
    recurrence: {
        marginTop: "-5px",
        marginBottom: "-12px",
        paddingLeft: "5px"
    },
    locales: {
        marginTop: "20px",
        paddingLeft: "5px"
    },
};

export default formStyle;
