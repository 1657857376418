import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";

// @mui/material components
import {withStyles} from "@mui/styles";

// styles
import cardStyle from "../../assets/jss/components/card/cardStyle";

function Card({...props}) {
    const {classes, className, children, plain, carousel, last, draggableProps, ...rest} = props;
    const cardClasses = classNames({
        [classes.card]: true,
        [classes.cardPlain]: plain,
        [classes.cardCarousel]: carousel,
        [classes.cardLast]: last,
        [className]: className !== undefined
    });

    return (
        <div className={cardClasses} {...draggableProps} {...rest}>
            {children}
        </div>
    );
}

Card.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
    plain: PropTypes.bool,
    carousel: PropTypes.bool,
    last: PropTypes.bool,
};

export default withStyles(cardStyle)(Card);
