import React, {Component} from "react";
import {BeatLoader} from 'react-spinners';

// @mui/material components
import { withStyles } from "@mui/styles";

import loaderStyle from "../../../../assets/jss/views/stockeasy/product/loaderStyle";

class Loader extends Component {
    render() {
        return (
            <div className={this.props.classes.loader}>
                <BeatLoader
                    sizeUnit={"px"}
                    size={4}
                    color={"#FFF"}
                    loading={true}
                />
            </div>
        );
    }
}

export default withStyles(loaderStyle)(Loader);
