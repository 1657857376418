import React, {Component} from "react";
import Card from "../../../components/card/card";
import {Step, StepLabel, Stepper, } from "@mui/material";
import {withStyles} from "@mui/styles";
import {injectIntl} from "react-intl";

// styles
import StepperStyle from "assets/jss/components/stepper/StepperStyle";

// utils
import {UserAgentUtils} from "../../../utils/useragent";

class StockEasyStepper extends Component {
    render() {
        const {step, classes, intl} = this.props;
        //todo: use translations (and move to enum?)
        const steps = [
            intl.formatMessage({id: "stockeasy.step.reference"}),
            intl.formatMessage({id: "stockeasy.step.product"}),
            intl.formatMessage({id: "stockeasy.step.size-color-weight"}),
            intl.formatMessage({id: "stockeasy.step.price"}),
            intl.formatMessage({id: "stockeasy.step.stock"}),
            intl.formatMessage({id: "stockeasy.step.confirm"}),
        ];

        const isMobile = UserAgentUtils.isMobile()

        return(
            <Card className={classes.root}>
                <Stepper alternativeLabel className={classes.stepperMobile} nonLinear={true} activeStep={step}>
                    {steps.map(stepName => {
                        return (
                            <Step key={stepName}>
                                <StepLabel>
                                    {!isMobile && (<span className={classes.stepName}>{stepName}</span>)}
                                </StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
                {isMobile && (<p className={classes.stepNameMobile}>{steps[step]}</p>)}
            </Card>
        )
    }
}

export default(withStyles(StepperStyle)(injectIntl(StockEasyStepper)));
