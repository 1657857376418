import {
    successColor,
    dangerColor,
    crystalGreenColor,
    crystalRedColor,
    warningColor
} from "../../main";

const baseLabel = {
    margin: "2px",
    fontSize: "14px",
}

const baseStock = {
    margin: "2px 2px 2px 2px",
    fontWeight: "400",
    fontSize: "14px",
    height: "24px",
    padding: "2px",
}

const productLabelStyle = theme => ({
    popover: {
        pointerEvents: "none",
    },
    popoverContent: {
        pointerEvents: "auto",
        padding: theme.spacing(1),
        backgroundColor: "#000",
        color: "#fff",
        maxWidth: "300px",
        maxHeight: "200px",
        overflowWrap: "break-word",
        overflow: "scroll"
    },
    baseLabel: {
        ...baseLabel,
    },
    ok: {
        ...baseLabel,
        backgroundColor: crystalGreenColor,
        borderColor: successColor,
        color: successColor,
    },
    ko: {
        ...baseLabel,
        backgroundColor: crystalRedColor,
        borderColor: dangerColor,
        color: dangerColor,
    },
    inStock: {
        ...baseStock,
        color: "#fff",
        backgroundColor: successColor,
        borderColor: successColor,
    },
    middleStock: {
        ...baseStock,
        color: "#fff",
        backgroundColor: warningColor,
        borderColor: warningColor,
    },
    noStock: {
        ...baseStock,
        color: "#fff",
        backgroundColor: dangerColor,
        borderColor: dangerColor,
    },
});

export default productLabelStyle;
