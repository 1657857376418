import React from "react";
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import FilterChip from "views/marketplace/merchandising/filter/filterChip";
import Typography from '@mui/material/Typography';
import {FormattedMessage} from "react-intl";
import {primaryColor} from "assets/jss/main";

export default function StatusFilterPanel({items}) {

    return (
        <Grid container spacing={3} sx={{ paddingLeft: '23px' }}>
            {items.map((filter, index) => {
                return (
                    <Grid spacing={2} key={index}>
                        <Stack spacing={2}>
                            <Typography sx={{ color: primaryColor }}>
                                <FormattedMessage id={"merchandising.show.label.filters.title." + filter.tabTitle} />
                            </Typography>
                            {filter.tabContent.items.map((item, index) => {
                                return (
                                    <FilterChip
                                        key={index}
                                        label={<FormattedMessage id={"merchandising.show.label.filters." + item.name} />}
                                        item={item}
                                        variant="outlined"
                                    />
                                );
                            })}
                        </Stack>
                    </Grid>
                );
            })}
        </Grid>
    );
}
