import {
    warningColor,
    primaryColor,
    dangerColor,
    successColor,
    infoColor,
    roseColor,
    grayColor,
    defaultFont
} from "../../main";

const expansionStyle = theme => ({
    warningTableHeader: {
        color: warningColor
    },
    row: {
        height: '40px',
        cursor: 'pointer',
    },
    noError: {
        color: '',
    },
    primaryTableHeader: {
        color: primaryColor
    },
    dangerTableHeader: {
        color: dangerColor
    },
    successTableHeader: {
        color: successColor
    },
    infoTableHeader: {
        color: infoColor
    },
    roseTableHeader: {
        color: roseColor
    },
    grayTableHeader: {
        color: grayColor
    },
    hidden: {
        display: "none"
    },
    fullWidthDialog: {
        [theme.breakpoints.down('md')]: {
            margin: "0px",
            width: "100%",
            height: "100%"
        }
    },
    dialogClose: {
        marginRight: "30px",
        marginBottom: "30px"
    },
    table: {
        marginBottom: "0",
        width: "100%",
        maxWidth: "100%",
        backgroundColor: "transparent",
        borderSpacing: "0",
        borderCollapse: "collapse"
    },
    tableHeadCell: {
        ...defaultFont,
        color: "inherit",
        fontSize: "1em",
        fontWeight: "400!important"
    },
    tableCell: {
        ...defaultFont,
        lineHeight: "1.42857143",
        padding: "12px 8px",
        verticalAlign: "middle"
    },
    tableCellImportInProgress: {
        ...defaultFont,
        lineHeight: "1.42857143",
        verticalAlign: "middle",
        minWidth: "180px",
        textAlign: "right"
    },
    importInProgress: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        whiteSpace: "nowrap",
        fontSize: "16px",
        padding: "0px",
        color: grayColor,
        "& div": {
            paddingTop: "2px"
        }
    },
    tableCellXL: {
        ...defaultFont,
        lineHeight: "1.42857143",
        padding: "2px",
        verticalAlign: "middle",
        width: "260px",
        textAlign: "right",
        paddingRight: "24px !important"
    },
    tableCellNoResult: {
        ...defaultFont,
        textAlign: "center",
        fontWeight: "bold",
        verticalAlign: "middle"
    },
    tableResponsive: {
        width: "100%",
        marginTop: theme.spacing(2),
        overflowX: "auto"
    },
    statusIconEnabled: {
        color: successColor,
    },
    statusIconDisabled: {
        color: dangerColor,
    },
    listToggleSwitch: {
        paddingRight: "0px!important",
        "& label": {
            marginRight: "0px"
        }
    },
    beatLoader: {
        position: "absolute",
        right: "30px",
        margin: "0 auto",
        top: "26px",
        display: "flex",
        justifyContent: "center"
    },
    propagateLoader: {
        position: "absolute",
        left: "0",
        right: "0",
        margin: "0 auto",
        top: "48%",
        display: "flex",
        justifyContent: "center"
    },
    errorLine: {
        display: "flex",
        width: "100%",
        justifyContent: "space-between"
    },
    errorLineLeftColumn: {
        fontSize: theme.typography.pxToRem(13),
        color: theme.palette.text.secondary,
    },
    errorLineRightColumn: {
        fontSize: theme.typography.pxToRem(13),
    }
});

export default expansionStyle;
