import React, {useEffect} from "react";
import connect from "react-redux/es/connect/connect";

import {makeStyles} from "@mui/styles";

import GridContainer from "components/grid/gridContainer";
import GridItem from "components/grid/gridItem";

import AnalyticsGraph from "./analyticsGraph";
import ConversionRate from "./converstionRate";
import VisitorValue from "./visitorValue";

import {retrieveAnalyticsData} from "actions/dashboard";
import {list as listAnalytics, reset as resetAnalyticsList} from "actions/analytics/list";

const useStyles = makeStyles(theme => ({
    cardContainer: {
        display: "flex"
    },
}));

function Analytics(props) {
    const classes = useStyles();

    const {
        currentOrganization,
        dashboardAnalyticsData,
        dashboardAnalyticsDataLoading,
        dashboardAnalyticsList,
        dashboardOrdersData,
        startDate,
        endDate,
        unit,
        retrieveAnalyticsData
    } = props

    let valueVisitor = 0;
    let onlineTotalVisitor = 0;
    let physicalValueVisitor = 0;
    let onlineConversionRate = 0;
    let physicalConversionRate = 0;
    let onlineOrdersCount = 0;
    let onlineOrdersTurnover = 0;

    let analyticsOnlinePeople = [];

    useEffect(() => {
        listAnalytics();

        if (
            dashboardAnalyticsList.retrieved
            && 0 !== dashboardAnalyticsList.retrieved["hydra:totalItems"]
            && startDate !== null
            && endDate !== null
        ) {
            retrieveAnalyticsData(
                startDate.format("Y-M-D"),
                endDate.format("Y-M-D"),
                unit
            );
        }
    }, [retrieveAnalyticsData, dashboardAnalyticsList, startDate, endDate, listAnalytics]);

    if (dashboardOrdersData) {
        onlineOrdersCount = dashboardOrdersData["online"]["countTotal"];
        onlineOrdersTurnover = dashboardOrdersData["online"]["turnoverTotal"];
    }

    if (dashboardAnalyticsData?.sessions) {
        Object.keys(dashboardAnalyticsData["sessions"]["sessionsGraph"]).map((key) => {
            return analyticsOnlinePeople.push({
                x: key,
                y: dashboardAnalyticsData["sessions"]["sessionsGraph"][key]
            });
        });

        onlineTotalVisitor = dashboardAnalyticsData["sessions"]["countTotal"];

        valueVisitor = (onlineTotalVisitor / onlineOrdersTurnover) || 0;
        valueVisitor = valueVisitor === Infinity ? 0 : valueVisitor;

        onlineConversionRate = (onlineOrdersCount / onlineTotalVisitor) || 0;
        onlineConversionRate = onlineConversionRate === Infinity ? 0 : onlineConversionRate;
    }

    return (
        <GridContainer>
            <GridItem xs={12} sm={12} className={classes.cardContainer}>
                <AnalyticsGraph
                    loading={dashboardAnalyticsDataLoading}
                    analyticsOnlinePeople={analyticsOnlinePeople}
                />
            </GridItem>
            <GridItem xs={12} sm={12} md={6} className={classes.cardContainer}>
                <VisitorValue
                    currency={currentOrganization?.currency}
                    loading={dashboardAnalyticsDataLoading}
                    valueVisitor={valueVisitor}
                    physicalValueVisitor={physicalValueVisitor}
                />
            </GridItem>
            <GridItem xs={12} sm={12} md={6} className={classes.cardContainer}>
                <ConversionRate
                    currency={currentOrganization?.currency}
                    loading={dashboardAnalyticsDataLoading}
                    onlineConversionRate={onlineConversionRate}
                    physicalConversionRate={physicalConversionRate}
                />
            </GridItem>
        </GridContainer>
    )
}

const mapStateToProps = (state) => {
    return {
        dashboardOrdersData: state.dashboard.dashboardOrdersData,
        dashboardAnalyticsDataLoading: state.dashboard.retrieveAnalyticsDataLoading,
        dashboardAnalyticsData: state.dashboard.retrieveAnalyticsData,
        dashboardAnalyticsList: state.analytics.list,
        currentOrganization: state.currentOrganization.retrieved ?? null,
    };
};

const mapDispatchToProps = dispatch => ({
    retrieveAnalyticsData: (startDate, endDate , unit) => dispatch(retrieveAnalyticsData(startDate, endDate, unit)),
    listAnalytics: () => dispatch(listAnalytics()),
    resetAnalyticsList: () => dispatch(resetAnalyticsList())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Analytics);
