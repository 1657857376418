import React, {useEffect, useState} from "react";
import {Draggable} from "react-beautiful-dnd";

// @mui/material components
import {Checkbox, IconButton} from "@mui/material";

// @mui/icons-material
import { MoreVert, KeyboardArrowDown, KeyboardArrowRight, AddRounded,  SubdirectoryArrowRightRounded } from "@mui/icons-material";

// core components
import Children from './children';
import CustomDate from 'components/date/date';

// styles
import colorStyle from "assets/jss/views/configuration/defaultAttributeRowStyle";

// helpers
import {sortOn} from "utils/sortOn";
import {getTranslation} from "../../../domain/helpers/translations";
import {withStyles} from "@mui/styles";

import CreateBelow from '../../configuration/components/CreateBelow';
import CreateDialog from './createDialog';

function Color (props) {
    const {
        allColors,
        checkedColors,
        classes,
        data,
        draggableId,
        index,
        openCreatedDialog,
        openPopover,
        search,
        selectColor,
        setColorTablePosition,
        setCheckedColors,
    } = props;

    const [openChildren, setOpenChildren] = useState(false);
    const [createDialog, showCreateDialog] = useState(false);
    const [createBelow, showCreateBelow] = useState(false);

    const isTopColor = data.parent === null;

    const children = allColors.filter(item => item.parent && item.parent.id === data.id);
    const hasChildren = children.length > 0;

    const isChecked = checkedColors.some(item => item['@id'] === data['@id']);

    useEffect(() => {
        if (search.length > 0) {
            setOpenChildren(true);
        }
    }, [search]);

    function openAddSubColorDialog(e) {
        e.stopPropagation();
        selectColor(data);
        openCreatedDialog();
    }

    function showPopover(e) {
        e.stopPropagation();
        selectColor(data);
        setColorTablePosition(e.currentTarget);
        openPopover();
    }

    function checkColor(item) {
        const isItemAlreadyChecked = checkedColors.some(itemAdded => itemAdded['@id'] === item['@id']);

        if (!isItemAlreadyChecked) {
            setCheckedColors([...checkedColors, item]);
        } else {
            let newSelected = checkedColors.filter((d) => !(d['@id'] === item['@id']));

            setCheckedColors(newSelected);
        }
    }

    function isColorMatchWithSearch(color) {
        return !!getTranslation(color).name.toLowerCase().includes(search);
    }

    function showChildren() {
        if (hasChildren) {
            setOpenChildren(!openChildren)
        }
    }

    function elementClasses(color) {
        if (openChildren) {
            if (search.length > 0 && isColorMatchWithSearch(color)) {
                return classes.elementMatch
            } else {
                return classes.elementOpen
            }
        } else {
            return classes.element
        }
    }

    function subdirectoryIconClasses() {
        if (openChildren) {
            return classes.subdirectoryIconOpen
        } else {
            return classes.subdirectoryIconClose
        }
    }
    function nameClasses() {
        if (isTopColor) {
            if (openChildren) {
                return classes.topAttributeNameSelected;
            } else {
                return classes.topAttributeName;
            }
        } else if (openChildren) {
            return classes.attributeNameSelected;
        } else {
            return null
        }
    }

    return <>
        <Draggable index={index} draggableId={draggableId}>
            {provided => (
                <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                    {data.parent && <SubdirectoryArrowRightRounded className={subdirectoryIconClasses()} />}
                    <li
                        className={elementClasses(data)}
                        onClick={() => showChildren()}
                        onMouseEnter={() => showCreateBelow(true)}
                        onMouseLeave={() => showCreateBelow(false)}
                    >
                        <div className={classes.name}>
                            <Checkbox
                                checked={isChecked}
                                classes={{root: classes.checkbox, checked: classes.checked}}
                                onClick={(e) => e.stopPropagation()}
                                onChange={() => checkColor(data)}
                            />
                            <span className={nameClasses()}>
                                {getTranslation(data).name}
                            </span>
                            <IconButton
                                component="span"
                                onClick={(e) => openAddSubColorDialog(e)}
                                aria-label="add sub colors"
                                className={classes.addSubAttributeButton}
                                size="large">
                                <AddRounded className={classes.addSubAttributeIcon} />
                            </IconButton>
                        </div>
                        <div className={classes.actionsButtonContainer}>
                            <CustomDate showRelativeDate={true} date={data.updatedAt} />
                            <IconButton
                                className={hasChildren ? classes.iconButton : classes.iconButtonMargin}
                                onClick={(e) => showPopover(e)}
                                size={"small"}
                            >
                                <MoreVert />
                            </IconButton>
                            {hasChildren && (
                                <IconButton className={classes.iconButton} size={"small"}>
                                    {openChildren ? <KeyboardArrowDown color={"primary"} /> : <KeyboardArrowRight />}
                                </IconButton>
                            )}
                        </div>
                        <CreateDialog
                            closeDialog={() => {
                                showCreateBelow(false);
                                showCreateDialog(false);
                            }}
                            isOpen={createDialog}
                            allColors={allColors}
                            position={index + 1}
                        />
                        {createBelow && (
                            <CreateBelow
                                onClick={() => showCreateDialog(true)}
                            />
                        )}
                    </li>
                </div>
            )}
        </Draggable>
        {(hasChildren && openChildren) && (
            <li>
                <Children
                    children={children.sort(sortOn("position"))}
                    allColors={allColors}
                    openCreatedDialog={openCreatedDialog}
                    selectColor={selectColor}
                    setColorTablePosition={setColorTablePosition}
                    openPopover={openPopover}
                    search={search}
                    checkedColors={checkedColors}
                    setCheckedColors={setCheckedColors}
                />
            </li>
        )}
    </>;
}

export default withStyles(colorStyle)(Color);
