import {fetch} from "../../../utils/dataAccess";
import {updateError, updateSuccess} from "./update";

export function error(error) {
    return {type: "MAPPER_DELETE_ERROR", error};
}

export function loading(loading) {
    return {type: "MAPPER_DELETE_LOADING", loading};
}

export function success(deleted) {
    return {type: "MAPPER_DELETE_SUCCESS", deleted};
}

export function del(item) {
    return dispatch => {
        dispatch(loading(true));
        dispatch(error(""));
        dispatch(updateError(""));
        dispatch(updateSuccess(null));

        return fetch(item["@id"], {method: "DELETE"})
            .then(() => {
                dispatch(loading(false));
                dispatch(success(item));
            })
            .catch(e => {
                dispatch(loading(false));
                dispatch(error(e.message));
            });
    };
}
