import {
    warningColor,
    primaryColor,
    dangerColor,
    successColor,
    infoColor,
    roseColor,
    grayColor,
    defaultFont
} from "../../../main";

const tableStyle = theme => ({
    warningTableHeader: {
        color: warningColor
    },
    primaryTableHeader: {
        color: primaryColor
    },
    dangerTableHeader: {
        color: dangerColor
    },
    successTableHeader: {
        color: successColor
    },
    infoTableHeader: {
        color: infoColor
    },
    roseTableHeader: {
        color: roseColor
    },
    grayTableHeader: {
        color: grayColor
    },
    hidden: {
        display: "none"
    },
    dialogClose: {
        marginRight: "30px",
        marginBottom: "30px"
    },
    table: {
        marginBottom: "0",
        borderSpacing: "0",
        borderCollapse: "collapse",
    },
    tableHeadCell: {
        ...defaultFont,
        color: "inherit",
        fontSize: "1em",
        fontWeight: "400!important"
    },
    tableRow: {
        cursor: "pointer",
    },
    tableCell: {
        ...defaultFont,
        lineHeight: "1.42857143",
        verticalAlign: "middle"
    },
    tableCellXL: {
        ...defaultFont,
        lineHeight: "1.42857143",
        padding: "2px",
        verticalAlign: "middle",
        width: "120px"
    },
    tableCellNoResult: {
        ...defaultFont,
        textAlign: "center",
        fontWeight: "bold",
        verticalAlign: "middle"
    },
    tableResponsive: {
        width: "100%",
        marginTop: theme.spacing(3),
        overflowX: 'auto',
    },
    statusIconEnabled: {
        color: successColor,
    },
    statusIconDisabled: {
        color: dangerColor,
    },
    beatLoader: {
        position: "absolute",
        right: "30px",
        margin: "0 auto",
        top: "26px",
        display: "flex",
        justifyContent: "center"
    },
    propagateLoader: {
        position: "absolute",
        left: "0",
        right: "0",
        margin: "0 auto",
        top: "50%",
        display: "flex",
        justifyContent: "center"
    },
    shippingMethods: {
        display:"flex",
        justifyContent: "flex-start",
        alignItems: "center"
    },
    pickup: {
        color: "#dd9eeb",
        border: "1px solid #dd9eeb",
        borderRadius: "50%",
        padding: "5px",
        fontSize: "15px",
        fontWeight:"700",
        width: '40px',
        height: '40px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    image: {
        width: "50px",
        height: "50px",
        borderRadius: "50%",
        boxSizing: "border-box",
        padding: "0px",
        boxShadow: " 5px 5px 10px -7px rgba(0,0,0,0.96)",
        marginRight: "-20px",
    },
    imgPlaceholder: {
        background: "linear-gradient(60deg, #377dff, #0052cb)",
        color: "#FFFFFF",
        padding: "12px",
        borderRadius: "50%",
        width: "50px",
        height: "50px",
        fontWeight: "bold",
        boxShadow: " 5px 5px 10px -7px rgba(0,0,0,0.96)",
    },
    primaryColor: {
        color: primaryColor,
    },
    emptyRunner: {
        width: "50px",
        textAlign: "center",
    },
    lineIcon: {
      display: "flex",
      alignItems: "center"
    },
    lineIconIcon: {
      color: "#0052cb",
      fontSize: "125%",
      marginLeft: "3px",
    },
});

export default tableStyle;
