import React, {useEffect, useState} from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { Field, reduxForm } from "redux-form";

// @mui/material components
import {FormControlLabel, Switch} from "@mui/material";

// core components
import AllTranslationsRequiredMessage from "../../configuration/components/AllTranslationsRequiredMessage";
import FormFields from './formFields';
import FormContainer from "../../configuration/components/FormContainer";
import GridItem from "components/grid/gridItem";
import Hierarchy from '../../configuration/components/hierarchy';
import ActionsContainer from "components/form/ActionsContainer";
import CancelButton from "views/configuration/components/CancelButton";
import SubmitButton from "views/configuration/components/SubmitButton";

// action
import { create } from "actions/category/create";
import { reset as resetUpload} from "actions/upload";
import FormTranslator from "../../../components/translator/formTranslator";

function Form(props) {
    const { category, closeDialog, create, createLoading, intl, isSubCategory, resetUpload, submitting, uploaded, uploadLoading, values, requiredFields } = props;

    const isFormValid = values && !!(requiredFields.length && requiredFields.every((input) => {
        let translation = input.split('.');

        return typeof values['translations'] != 'undefined' && values['translations'][translation[1]] && values['translations'][translation[1]][translation[2]];
    }));

    const [uploadedMediaObject, setUploadedMediaObject] = useState(null);
    const [pictures, setPictures] = useState(null);
    const [descriptions, setDescriptions] = useState(null);
    const [longDescriptions, setLongDescriptions] = useState(null);

    if (pictures === null && typeof values !== 'undefined') {
        let translatedPictures = {};
        for (let code in values.translations) {
            translatedPictures[code] = values.translations[code].picture;
        }
        setPictures(translatedPictures);
    }

    if (descriptions === null && typeof values !== 'undefined') {
        let descriptions = {};
        for (let code in values.translations) {
            descriptions[code] = values.translations[code].description;
        }
        setDescriptions(descriptions);
    }

    if (longDescriptions === null && typeof values !== 'undefined') {
        let longDescriptions = {};
        for (let code in values.translations) {
            longDescriptions[code] = values.translations[code].longDescription;
        }
        setLongDescriptions(longDescriptions);
    }

    if (uploaded !== null && uploadedMediaObject !== uploaded) {
        setUploadedMediaObject(uploaded);
        let code = uploaded.uploaded_object_id.split('.')[1];
        let translatedPictures = {...pictures};
        translatedPictures[code] = uploaded;
        setPictures(translatedPictures);
    }

    const onDeletePicture = (code) => {
        let translatedPictures = {...pictures};
        translatedPictures[code] = null;
        setPictures(translatedPictures);
    }

    useEffect(() => {
        // componentWillUnmount
        return () => {
            resetUpload();
        };
    });

    function submit(e) {
        e.preventDefault();
        if (!isFormValid) {
            return;
        }

        if (category && isSubCategory) {
            values.parent = category["@id"];
        }

        for (let code in values.translations) {
            values.translations[code].picture = pictures[code];
            values.translations[code].description = descriptions[code];
            values.translations[code].longDescription = longDescriptions[code];
        }

        create(values);
    }

    const renderSwitches = ({ input, label }) => (
        <FormControlLabel
            control={<Switch
                checked={input.value ? true : false} // Looks stupid but documented as it
                onChange={input.onChange}
                color="primary"
                inputProps={{ 'aria-label': 'primary checkbox' }}
                value={category ? category[input.name] : false}
            />}
            label={label}
        />
    );
    const renderTranslationForm = (index, code) => {
        if (requiredFields.indexOf(`translations.${code}.name`) === -1) {
            requiredFields.push(`translations.${code}.name`);
        }

        return (
            <div>
                <GridItem xs={12} sm={12}>
                    <Field
                        component={FormFields}
                        name={`translations.${code}.name`}
                        label={intl.formatMessage({id: category ? "category.form.label.sub.name" : "category.form.label.name"})}
                        type={"text"}
                        placeholder={intl.formatMessage({id: "category.form.placeholder.name"})}
                        required
                    />
                </GridItem>
                <GridItem xs={12} sm={12}>
                    <Field
                        component={FormFields}
                        name={`translations.${code}.description`}
                        label={intl.formatMessage({id: "category.form.label.description"})}
                        type={"wysiwyg"}
                        onChange={(value => {
                            descriptions[code] = value;
                            setDescriptions(descriptions);
                        })}
                        placeholder={intl.formatMessage({id: "category.form.placeholder.description"})}
                    />
                </GridItem>
                <GridItem xs={12} sm={12}>
                    <Field
                        component={FormFields}
                        name={`translations.${code}.longDescription`}
                        label={intl.formatMessage({id: "category.form.label.longDescription"})}
                        type={"wysiwyg"}
                        onChange={(value => {
                            longDescriptions[code] = value;
                            setLongDescriptions(longDescriptions);
                        })}
                        placeholder={intl.formatMessage({id: "category.form.placeholder.longDescription"})}
                    />
                </GridItem>
                <GridItem xs={12} sm={12}>
                    <Field
                        component={FormFields}
                        name={`translations.${code}.metaTitle`}
                        label={intl.formatMessage({id: "category.form.label.metaTitle"})}
                        type={"text"}
                        placeholder={intl.formatMessage({id: "category.form.placeholder.metaTitle"})}
                    />
                </GridItem>
                <GridItem xs={12} sm={12}>
                    <Field
                        component={FormFields}
                        name={`translations.${code}.metaDescription`}
                        label={intl.formatMessage({id: "category.form.label.metaDescription"})}
                        type={"textarea"}
                        placeholder={intl.formatMessage({id: "category.form.placeholder.metaDescription"})}
                    />
                </GridItem>
                <GridItem xs={12} sm={12}>
                    <Field
                        component={FormFields}
                        name={`translations.${code}.picture`}
                        label={intl.formatMessage({id: "category.form.label.image"})}
                        type={"file"}
                        placeholder={"image"}
                        onDeletePicture={() => {onDeletePicture(code)}}
                        picture={pictures !== null ? pictures[code] : null}
                    />
                </GridItem>
            </div>
        );
    };

    return (
        <form onSubmit={(e) => submit(e)}>
            <FormContainer>
                <AllTranslationsRequiredMessage />
                {category && (
                    <GridItem xs={12} sm={12}>
                        <Hierarchy attributeName={'category'} attributeSelected={category} />
                    </GridItem>
                )}
                <GridItem xs={12} sm={12}>
                    <FormTranslator formGenerator={renderTranslationForm} />
                </GridItem>
                <GridItem xs={12} sm={12}>
                    <Field
                        component={renderSwitches}
                        name={"restricted"}
                        label={intl.formatMessage({id: "category.form.label.restricted"})}
                    />
                </GridItem>
                <GridItem xs={12} sm={12}>
                    <Field
                        component={renderSwitches}
                        name={"catalogOnly"}
                        label={intl.formatMessage({id: "category.form.label.catalogOnly"})}
                    />
                </GridItem>
                <GridItem xs={12} sm={12}>
                    <Field
                        component={renderSwitches}
                        name={"privateProducts"}
                        label={intl.formatMessage({id: "category.form.label.privateProducts"})}
                    />
                </GridItem>
                <GridItem xs={12} sm={12}>
                    <Field
                        component={renderSwitches}
                        name={"privatePrices"}
                        label={intl.formatMessage({id: "category.form.label.privatePrices"})}
                    />
                </GridItem>
                <GridItem xs={12} sm={12}>
                    <Field
                        component={renderSwitches}
                        name={"hiddenPrices"}
                        label={intl.formatMessage({id: "category.form.label.hiddenPrices"})}
                    />
                </GridItem>
            </FormContainer>
            <ActionsContainer>
                <CancelButton onClick={closeDialog} />
                <SubmitButton
                    disabled={!isFormValid || submitting || uploadLoading}
                    loading={createLoading}
                />
            </ActionsContainer>
        </form>
    )
}

const mapStateToProps = state => {
    return {
        createLoading: state.category.create.loading,
        values: state.form && state.form.addCategory && state.form.addCategory.values,
        uploaded: state.upload.success,
        uploadLoading: state.upload.loading
    }
};

const mapDispatchToProps = dispatch => ({
    create: (values) => dispatch(create(values)),
    resetUpload: () => dispatch(resetUpload()),
    requiredFields: [],
});

export default reduxForm({
    form: "addCategory",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
})(connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(Form)));
