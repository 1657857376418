import React, {Component} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import {BeatLoader} from 'react-spinners';
import {Redirect} from "react-router-dom";

// @mui/material components
import {withStyles} from "@mui/styles";

// @mui/icons-material
import {NotificationsActive} from "@mui/icons-material";

// core components
import Form from "./form";
import Snackbar from "components/snackbar/snackbar";
import GridContainer from "components/grid/gridContainer";
import GridItem from "components/grid/gridItem";
import Card from "components/card/card";
import CardHeader from "components/card/cardHeader";
import CardBody from "components/card/cardBody";

// actions
import {create, reset} from "actions/retailer/create";

// styles
import updateStyle from "assets/jss/views/user/updateStyle";
import {primaryColor} from "assets/jss/main";

// helpers
import abort from "utils/abort";

class Create extends Component {
    static propTypes = {
        error: PropTypes.object,
        loading: PropTypes.bool.isRequired,
        created: PropTypes.object,
        create: PropTypes.func.isRequired,
        reset: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);

        this.state = {
            createErrorNotification: true
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({createErrorNotification: true})
    }

    componentWillUnmount() {
        this.props.reset();
        abort.abortRequests();
    }

    render() {
        const {classes, error } = this.props;

        if (this.props.created) {
            return <Redirect to={'/retailers/show/%2Fretailers%2F'+this.props.created.id}/>;
        }

        return (
            <div className={classes.containerWithSidebar}>
                <div className={classes.container}>
                    {error && !error.errors &&
                        <Snackbar
                            open={this.state.createErrorNotification}
                            close
                            closeNotification={() => this.setState({createErrorNotification: false})}
                            place={"bl"}
                            color={"danger"}
                            icon={function () {
                                return <NotificationsActive/>
                            }}
                            message={`${error}`}
                        />
                    }

                    <GridContainer>
                        <GridItem xs={12}>
                            <Card className={classes.card}>
                                <div className={classes.beatLoader}>
                                    <BeatLoader
                                        sizeUnit={"px"}
                                        size={12}
                                        color={primaryColor}
                                        loading={this.props.loading}
                                    />
                                </div>
                                <CardHeader color="info" className={classes.cardHeader}>
                                    <GridContainer>
                                        <GridItem xs={10}>
                                            <h3><FormattedMessage id={"retailer.create.title"}/></h3>
                                        </GridItem>
                                    </GridContainer>
                                </CardHeader>
                                <CardBody>
                                    <Form onSubmit={this.props.create} values={this.props.item}/>
                                </CardBody>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    const {created, error, loading} = state.retailer.create;

    return {created, error, loading};
};

const mapDispatchToProps = dispatch => ({
    create: values => dispatch(create(values)),
    reset: () => dispatch(reset())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(updateStyle)(Create));
