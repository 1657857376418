import React from 'react';
import {FormattedMessage} from "react-intl";
import PropTypes from 'prop-types';

import {blueColor} from "assets/jss/main";

FormTitle.propTypes = {
    titleId: PropTypes.string.isRequired
};

function FormTitle({titleId}) {
    return (
        <h4 style={{
            fontSize: '20px',
            fontWeight: '600',
            color: blueColor,
            margin: 'unset'
        }}>
            <FormattedMessage id={titleId} />
        </h4>
    );
}

export default FormTitle;
