import {grayColor} from "../../main";
import {keyframes} from "@emotion/react";

const rowStyle = theme => ({
    row: {
        margin: '10px 0',
        display: 'flex',
        padding: '10px',
        backgroundColor: 'white',
    },
    one: {
        width: '10%',
        textAlign: 'left',
    },
    two: {
        width: '20%',
        textAlign: 'left',
    },
    three: {
        width: '30%',
        textAlign: 'left',
    },
    four: {
        width: '40%',
        textAlign: 'left',
    },
    five: {
        width: '50%',
        textAlign: 'left',
    },
    bold: {
        fontWeight: 'bold',
    },
    cornerId: {
        fontSize: '.9em',
        color: grayColor,
        fontWeight: '400'
    },
    '@keyframes shake': {
        '0%': {
            '-webkit-transform': 'scale3d(1, 1, 1)',
            'transform': 'scale3d(1, 1, 1)',
        },
        '30%': {
            '-webkit-transform': 'scale3d(1.25, 0.75, 1)',
            'transform': 'scale3d(1.25, 0.75, 1)',
        },
        '40%': {
            '-webkit-transform': 'scale3d(0.75, 1.25, 1)',
            'transform': 'scale3d(0.75, 1.25, 1)',
        },
        '50%': {
            '-webkit-transform': 'scale3d(1.15, 0.85, 1)',
            'transform': 'scale3d(1.15, 0.85, 1)',
        },
        '65%': {
            '-webkit-transform': 'scale3d(0.95, 1.05, 1)',
            'transform': 'scale3d(0.95, 1.05, 1)',
        },
        '75%': {
            '-webkit-transform': 'scale3d(1.05, 0.95, 1)',
            'transform': 'scale3d(1.05, 0.95, 1)',
        },
        '100%': {
            '-webkit-transform': 'scale3d(1, 1, 1)',
            'transform': 'scale3d(1, 1, 1)',
        },
    },
    fadeAnime: {
        animation: '$shake 0.9s linear 0s',
    }
});
export default rowStyle;
