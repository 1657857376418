import {
    fetch,
    normalize,
} from "../../utils/dataAccess";

export function retrieveVariationPriceStockDataError(retrieveError) {
    return {type: "VARIATION_PRICE_STOCK_DATA_RETRIEVE_ERROR", retrieveError};
}

export function retrieveVariationPriceStockDataLoading(retrieveLoading) {
    return {type: "VARIATION_PRICE_STOCK_DATA_RETRIEVE_LOADING", retrieveLoading};
}

export function retrieveVariationPriceStockDataSuccess(retrieved) {
    return {type: "VARIATION_PRICE_STOCK_DATA_RETRIEVE_SUCCESS", retrieved};
}

export function retrieveVariationPriceStockData(variationId, startDate, endDate, unit) {
    return dispatch => {
        dispatch(retrieveVariationPriceStockDataLoading(true));
        dispatch(retrieveVariationPriceStockDataError(""));

        let url = `/variations/${variationId}/price_stock_data?startDate=${startDate}&endDate=${endDate}&unit=${unit}`;

        return fetch(url, {method: "GET"})
            .then(response =>
                response.json()
            )
            .then(retrieved => {
                retrieved = normalize(retrieved);

                dispatch(retrieveVariationPriceStockDataLoading(false));
                dispatch(retrieveVariationPriceStockDataSuccess(retrieved));

            })
            .catch(e => {
                dispatch(retrieveVariationPriceStockDataLoading(false));
                dispatch(retrieveVariationPriceStockDataError(e.message));
            });
    };
}

export function reset() {
    return dispatch => {
        dispatch({type: "VARIATION_PRICE_STOCK_DATA_RESET"});
    };
}
