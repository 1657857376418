import {
    container,
    defaultFont,
    infoColor,
    darkGrayColor
} from "../main";

const button = {
    padding: "10px",
    width: "100%",
    fontSize: "1em",
    textTransform: "none"
};
const invitationStyle = theme => ({
    container: {
        ...container,
        ...defaultFont,
        marginTop: "30px",
        textAlign:" center",
        maxWidth: "550px !important",
    },
    card:{
        fontSize: "1.25em",

    },
    cardContent: {
        paddingBottom: "0 !important",
        paddingTop: "0 !important"
    },
    title: {
        fontSize: "1.25em",
        fontWeight: "bold",
        padding:"20px !important",
        color: infoColor,
    },
    content:{
        ...defaultFont,
        borderTop: `1px solid ${darkGrayColor}`,
        borderBottom: `1px solid ${darkGrayColor}`
    },
    organization: {
        fontWeight: "bold",
        fontSize: "1.25em",
    },
    logo:{
        width: 200
    },
    applications: {
        listStyle: "none",
        textAlign: "center",
        paddingInlineStart: 0,
    },
    access: {
        borderBottom: `1px solid ${darkGrayColor}`
    },
    actions: {
      padding: "20px 40px"
    },
    accept:{
        ...button
    },
    cancel:{
        ...button,
        color: infoColor,
        border: `1px solid ${infoColor}`,
        "&:hover": {
            color: infoColor,
        },
    },
    connexion:{
        ...button,
        width: "50%",
    }
});

export default invitationStyle;
