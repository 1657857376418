import React from 'react'
import PropTypes from "prop-types";

import {makeStyles} from "@mui/styles";

import ShippingIcon from "../../../../components/shippingIcon";
import ProductImage from "./ProductImage";

const useStyle = makeStyles({
    shippingIconContainer: {
        position: 'relative',
        left: '60px',
        top: '15px',
        zIndex: 1,
    }
});

export default function PickingProductImage({pictures, shippingMethodName, organizationShippingMethods}) {
    const classes = useStyle();

    return (
        <>
            <div className={classes.shippingIconContainer}>
                <ShippingIcon
                    shippingMethodName={shippingMethodName}
                    organizationShippingMethods={organizationShippingMethods}
                    size={'small'}
                />
            </div>
            <ProductImage pictures={pictures} />
        </>
    )
}

PickingProductImage.defaultProps = {
    pictures: [],
    shippingMethodName: '',
    organizationShippingMethods: []
}

PickingProductImage.propTypes = {
    pictures: PropTypes.array,
    shippingMethodName: PropTypes.string,
    organizationShippingMethods: PropTypes.array
}
