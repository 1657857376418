import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";

// @mui/material components
import {withStyles} from "@mui/styles";

// core components
import cardFooterStyle from "../../assets/jss/components/card/cardFooterStyle";

function CardFooter({...props}) {
    const {classes, center, className, children, ...rest} = props;
    const cardFooterClasses = classNames({
        [classes.cardFooter]: true,
        [classes.center]: center !== undefined,
        [className]: className !== undefined
    });
    return (
        <div className={cardFooterClasses} {...rest}>
            {children}
        </div>
    );
}

CardFooter.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
    center: PropTypes.bool
};

export default withStyles(cardFooterStyle)(CardFooter);
