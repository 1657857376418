import React, {useContext} from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {Formik} from "formik";

import GridContainer from "components/grid/gridContainer";
import GridItem from "components/grid/gridItem";
import TopRightLoader from "components/loaders/TopRightLoader";

import RecommendationForm from "./RecommendationForm";
import SaveButton from "./SaveButton";

import {makeStyles} from "@mui/styles";
import {borderGrayColor} from "assets/jss/main";

import {CategoryFilter, ColorFilter, RetailerFilter, Sort, Type} from "utils/recommendation";

import {RecommendationContext} from "../context/RecommendationContext";

import update from 'api/recommendation/update';
import create from 'api/recommendation/create';

const useStyles = makeStyles({
    defaultRecommendationsForm: {
        border: `1px solid ${borderGrayColor}`,
        borderRadius: '8px',
        marginLeft: 'unset',
        marginRight: 'unset',
        paddingBottom: '24px',
    },
    form: {
        paddingLeft: '24px',
        paddingRight: '24px',
        marginTop: '24px',
        marginBottom: '32px',
        '&:nth-child(2)': {
            borderRight: `1px solid ${borderGrayColor}`,
        }
    },
    actionContainer: {
        width: '100%',
    },
    actionItem: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row-reverse',
        gap: '12px'
    },
})

function DefaultRecommendationForms(props) {
    const classes = useStyles();

    const {apiLoading, recommendations, refreshRecommendationList} = useContext(RecommendationContext);

    const defaultProductTypeRecommendation = recommendations.filter((recommendation) => {
        if (
            recommendation.overrodeCategory === null
            && recommendation.type === Type.PRODUCT
        ) return recommendation
    })[0];

    const defaultCartTypeRecommendation = recommendations.filter((recommendation) => {
        if (
            recommendation.overrodeCategory === null
            && recommendation.type === Type.CART
        ) return recommendation
    })[0];

    const productTypeInitialValues = {
        retailerFilter: defaultProductTypeRecommendation?.retailerFilter ?? RetailerFilter.SAME,
        categoryFilter: defaultProductTypeRecommendation?.categoryFilter ?? CategoryFilter.SAME,
        colorFilter: defaultProductTypeRecommendation?.colorFilter ?? ColorFilter.SAME,
        sort: defaultProductTypeRecommendation?.sort ?? Sort.BEST_SELLER,
        recommendedProduct: defaultProductTypeRecommendation?.category || defaultProductTypeRecommendation?.corner || null,
        isUpdate: !!defaultProductTypeRecommendation?.hasOwnProperty('@id'),
    };

    const cartTypeInitialValues = {
        retailerFilter: defaultCartTypeRecommendation?.retailerFilter ?? RetailerFilter.SAME,
        categoryFilter: defaultCartTypeRecommendation?.categoryFilter ?? CategoryFilter.SAME,
        colorFilter: defaultCartTypeRecommendation?.colorFilter ?? ColorFilter.SAME,
        sort: defaultCartTypeRecommendation?.sort ?? Sort.BEST_SELLER,
        recommendedProduct: defaultCartTypeRecommendation?.category || defaultCartTypeRecommendation?.corner || null,
        isUpdate: !!defaultCartTypeRecommendation?.hasOwnProperty('@id'),
    }

    const initialValues = {
        product: productTypeInitialValues,
        cart: cartTypeInitialValues
    }

    async function submitForm(values) {
        const productTypeValues = values.defaultRecommendationsForm.product;
        const cartTypeValues = values.defaultRecommendationsForm.cart;

        if (values.defaultRecommendationsForm.product.recommendedProduct?.['@type'] === 'Category') {
            productTypeValues.categoryFilter = CategoryFilter.ALL;
        }
        if (values.defaultRecommendationsForm.cart.recommendedProduct?.['@type'] === 'Category') {
            cartTypeValues.categoryFilter = CategoryFilter.ALL;
        }

        if (productTypeValues.isUpdate) {
            await update(defaultProductTypeRecommendation, productTypeValues);
        } else {
            productTypeValues.type = Type.PRODUCT;
            await create(productTypeValues)
        }

        if (cartTypeValues.isUpdate) {
            await update(defaultCartTypeRecommendation, cartTypeValues);
        } else {
            cartTypeValues.type = Type.CART;
            await create(cartTypeValues)
        }
    }

    return (
        <Formik
            initialValues={{defaultRecommendationsForm : initialValues}}
            enableReinitialize={true}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={async (values, { setSubmitting }) => {
                setSubmitting(true);

                await submitForm(values);

                setSubmitting(false)

                refreshRecommendationList();
            }}
        >
            {({
                  handleSubmit,
                  isSubmitting,
                  submitForm
            }) => (
                <form onSubmit={handleSubmit}>
                    <GridContainer className={classes.defaultRecommendationsForm}>
                        <TopRightLoader loading={apiLoading || isSubmitting} />
                        <GridItem xs={12} md={6} className={classes.form}>
                            <RecommendationForm formName={'defaultRecommendationsForm'} />
                        </GridItem>
                        <GridItem xs={12} md={6} className={classes.form}>
                            <RecommendationForm
                                formName={'defaultRecommendationsForm'}
                                type={Type.CART}
                            />
                        </GridItem>
                        <GridContainer className={classes.actionContainer}>
                            <GridItem className={classes.actionItem}>
                                <SaveButton
                                    disabled={apiLoading || isSubmitting}
                                    onClick={submitForm}
                                >
                                    <FormattedMessage id={"recommendations.form.default.action.save"} />
                                    {isSubmitting && <></>}
                                </SaveButton>
                            </GridItem>
                        </GridContainer>
                    </GridContainer>
                </form>
            )}
        </Formik>
    )
}

export default injectIntl(DefaultRecommendationForms);
