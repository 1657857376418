import React from "react";

import {withStyles} from "@mui/styles";
import {SingleDatePicker} from "react-dates";
import classNames from "classnames";
import '@ckeditor/ckeditor5-build-classic/build/translations/fr';

import {getIn} from 'formik';

import * as moment from "moment";
import fieldStyle from "../../../assets/jss/views/formik/fieldStyle";

class DatePickerRenderer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            focused: false,
            date: null,
        };
    }

    static supports(props) {
        return 'date' === props.type;
    }

    render() {
        const now = moment();
        const { name, fieldClassName, formikContext, classes } = this.props;
        const errors = formikContext.errors;
        const fieldIsInError = getIn(errors, name);

        const fieldClasses = classNames({
            [" " + classes.field]: true,
            [" " + classes.fieldInvalid]: !!fieldIsInError && formikContext.touched,
            [" " + (fieldClassName ?? '')]: true,
        });

        return (
            <div className={this.props.containerClassName + ' ' + fieldClasses}>
            <SingleDatePicker
                id={name}
                date={this.state.date}
                onDateChange={date => {
                    this.setState({ date });
                    formikContext.setFieldValue(name, date);
                }}
                focused={this.state.focused}
                onFocusChange={({ focused }) => this.setState({focused})}
                isOutsideRange={date => date.year() !== now.year()}
                displayFormat={"D MMMM"}
                required={true}
                openDirection={'up'}
                hideKeyboardShortcutsPanel={true}
                withFullScreenPortal={window.innerWidth < 768}
                orientation={window.innerWidth < 768 ? "vertical" : "horizontal"}
                anchorDirection={"right"}
            />
            </div>
        );
    }
}

export default withStyles(fieldStyle)(DatePickerRenderer);
