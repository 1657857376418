const StepperStyle = theme => ({
    stepperMobile:{
        [theme.breakpoints.down('sm')]: {
            paddingTop: '24px',
            paddingBottom: '0px'
        }
    },
    root: {
        marginTop: '0.5rem',
        marginBottom: '-20px',
        padding: '0px',
    },
    stepName: {
        [theme.breakpoints.down('lg')]: {
            fontSize: '0.8rem',
        },
        [theme.breakpoints.down('md')]: {
            fontSize: '0.7rem',
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
    },
    stepNameMobile: {
        alignSelf: 'center',
        fontSize: '1rem',
        fontWeight: '400',
        marginTop: '0',
    }
});

export default StepperStyle;
