import React from "react";
import { injectIntl } from "react-intl";
import {useFormikContext} from "formik";

// core components
import FormikInput from "components/input/formikInput";

// store redux
import store from "store";

// utils
import {getStatusThatCanBeRefund} from "utils/LineItemStatus";

function StatusField(props) {
    const { intl } = props;

    const formikContext = useFormikContext();

    const isRetailerReturnActivated = store.getState().currentOrganization?.retrieved?.retailersReturnEnabled;

    const statusOptions = () => (
        getStatusThatCanBeRefund(isRetailerReturnActivated).map((lineItemStatus, key) => (
            <option value={lineItemStatus} key={key}>
                {intl.formatMessage({id: "picking.order.lineItemStatus." + lineItemStatus})}
            </option>
        ))
    );

    return (
        <FormikInput
            labelTranslationId={'cancellation_reason.form.label.status'}
            inputProps={
                {
                    name: 'status',
                    as: 'select',
                    children: statusOptions(),
                    onChange: (e) => {
                        formikContext.handleChange(e)
                        formikContext.setFieldValue('status', e.target.value)
                    }
                }
            }
        />
    );
}

export default injectIntl(StatusField);
