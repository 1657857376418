function CurrencyCodeToSymbol(currency) {
    currency = currency ?? "EUR";

    switch (currency) {
        case 'EUR':
            currency = '€';
            break;
        case 'DKK':
            currency = 'kr.';
            break;
        case 'SEK':
        case 'NOK':
            currency = 'kr';
            break;
        case 'USD':
            currency = '$';
            break;
        case 'GBP':
            currency = '£';
            break;
    }

    return currency;
}

export default CurrencyCodeToSymbol;
