import React from "react";
import { FormattedMessage } from "react-intl";

// @mui/material components
import { Popover, Typography } from "@mui/material";

// styles
import { primaryColor } from "../../../assets/jss/main";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles(theme => ({
    typography: {
        padding: theme.spacing(2),
        cursor: "pointer",
        "&:hover": {
            background: primaryColor,
            color: "white",
        },
    },
}));

export default function EnhancedPopover(props) {
    const classes = useStyles();
    const { attributeName, closePopover, isPopoverOpen, openDeleteDialog, openEditDialog, openMoveDialog, position } = props;

    function deleteDialog() {
        openDeleteDialog();
        closePopover();
    }

    function editDialog() {
        openEditDialog();
        closePopover();
    }

    function moveDialog() {
        openMoveDialog();
        closePopover();
    }

    return (
        <Popover
            open={isPopoverOpen}
            anchorEl={position}
            onClose={closePopover}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            <Typography className={classes.typography} onClick={editDialog}>
                <FormattedMessage id={`${attributeName}.table.row.edit`} />
            </Typography>
            <Typography className={classes.typography} onClick={deleteDialog}>
                <FormattedMessage id={`${attributeName}.table.row.delete`} />
            </Typography>
            {'sector' !== attributeName &&
                <Typography className={classes.typography} onClick={moveDialog}>
                    <FormattedMessage id={`${attributeName}.table.row.move`}/>
                </Typography>
            }
        </Popover>
    );
}
