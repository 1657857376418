import React from "react";
import PropTypes from "prop-types";

import {FormattedMessage} from "react-intl";

import FormattedNumberWithCurrency
    from "components/formattedNumberWithCurrencies/formattedNumberWithCurrency";

import Loader from "./loader";
import CardStatsTitle from "./cards/cardStatsTitle";
import CardStatsValue from "./cards/cardStatsValue";
import CardStatsBody from "./cards/cardStatsBody";
import CardStatsFooter from "./cards/cardStatsFooter";
import CardStats from "./cards/cardStats";

export default function Turnover(props) {
    const { currency, isRetailer, loading, onlineOrdersTurnover, physicalOrdersTurnover } = props

    return (
        <CardStats>
            <Loader loading={loading} />
            <CardStatsTitle>
                <FormattedMessage id={"dashboard.card.title.turnover"} />
            </CardStatsTitle>
            <CardStatsBody>
                <CardStatsValue>
                    <FormattedNumberWithCurrency
                        value={(physicalOrdersTurnover + onlineOrdersTurnover)}
                        currency={currency}
                    />
                </CardStatsValue>
            </CardStatsBody>
            {!isRetailer && (
                <CardStatsFooter>
                    <div>
                        <span>
                            <FormattedMessage id={"dashboard.card.info.online"} />
                        </span>
                        <FormattedNumberWithCurrency
                            value={onlineOrdersTurnover}
                            currency={currency}
                        />
                    </div>
                    <div>
                    <span>
                        <FormattedMessage id={"dashboard.card.info.physical"} />
                    </span>
                        <FormattedNumberWithCurrency
                            value={physicalOrdersTurnover}
                            currency={currency}
                        />
                    </div>
                </CardStatsFooter>
            )}
        </CardStats>
    )
}
Turnover.defaultProps = {
    onlineOrdersTurnover: 0,
    physicalOrdersTurnover: 0
}

Turnover.propTypes = {
    loading: PropTypes.bool,
    onlineOrdersTurnover: PropTypes.number.isRequired,
    physicalOrdersTurnover:  PropTypes.number.isRequired
};
