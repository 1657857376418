import React from 'react';
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";
import {Link} from "react-router-dom";

import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import StyledListItemButton from "./StyledListItemButton";
import UserSquareIcon from "components/icons/UserSquareIcon";

import {Authorizations, hasAuthorization} from "utils/authorizations";
import {primaryColor} from "../../../assets/jss/main";

function DrawerRetailerProfiles({...props}) {
    if (!hasAuthorization(props.authorizations, Authorizations.OTHER_RETAILER_PROFILE)) {
        return null;
    }

    const isSelected = window.location.pathname.indexOf('/retailer_profiles') === 0;

    return (
        <div>
            <StyledListItemButton
                component={Link}
                to="/retailer_profiles"
                key="retailerProfiles"
                selected={isSelected}
            >
                <ListItemIcon>
                    <UserSquareIcon color={isSelected ? primaryColor : undefined}/>
                </ListItemIcon>
                <ListItemText
                    primaryTypographyProps={{color: isSelected ? "primary" : "secondary"}}
                    primary={<FormattedMessage id={"sidebar.item.retailerProfiles"} />}
                />
            </StyledListItemButton>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        authorizations: state.authentication.authorizations,
    };
};

export default connect(mapStateToProps)(DrawerRetailerProfiles);

