import React from "react";
import GridItem from "components/grid/gridItem";
import FormikInput from "components/input/formikInput";
import { injectIntl } from "react-intl";
import { TagPosition } from "../tagPosition";

// Formik specific tagForm
function FormikTagForm(props) {

    const { intl, tagEnabled, tagColors } = props;

    function formatChoices(options, labelPrefix) {
        const formattedOptions = [];

        Object.entries(options).forEach(entry => {
            const [name, value] = entry;
            formattedOptions.push({
                'value': value,
                'label': intl.formatMessage({id: labelPrefix + name.toLowerCase()})
            });
        });

        return formattedOptions
    }

    if (tagEnabled) {
        return (
            <>
                <GridItem xs={12} sm={12}>
                    <FormikInput
                        labelText={intl.formatMessage({id: "corner.form.label.tagPriority"})}
                        inputProps={
                            {
                                name: 'tagPriority',
                                type:"number",
                                placeholder: intl.formatMessage({id: "corner.form.placeholder.tagPriority"}),
                                required: true
                            }
                        }
                    />
                </GridItem>
                <GridItem xs={12} sm={12}>
                    <FormikInput
                        labelText={intl.formatMessage({id: "corner.form.label.tagColor"})}
                        inputProps={
                            {
                                name: 'tagColor',
                                type: 'creatableSelect',
                                children: formatChoices(tagColors, 'corner.form.label.color.'),
                                placeholder: intl.formatMessage({id: "corner.form.placeholder.tagColor"})
                            }
                        }
                    />
                </GridItem>
                <GridItem xs={12} sm={12}>
                    <FormikInput
                        labelText={intl.formatMessage({id: "corner.form.label.tagPosition"})}
                        inputProps={
                            {
                                name: 'tagPosition',
                                type: 'creatableSelect',
                                children: formatChoices(TagPosition, 'corner.form.label.position.'),
                                placeholder: intl.formatMessage({id: "corner.form.placeholder.tagPosition"})
                            }
                        }
                    />
                </GridItem>
            </>
        );
    }
}

export default injectIntl(FormikTagForm);
