import React, {useMemo, useEffect} from "react";
import {InputAdornment, TextField} from "@mui/material";
import {FormattedMessage, injectIntl} from "react-intl";
import Grid from "@mui/material/Grid";
import {styled} from "@mui/material/styles";
import {SearchOutlined} from "@mui/icons-material";
import debounce from 'lodash.debounce';

const StyledTextField = styled(TextField)({
    width: '100%'
});


function Search({intl, handleSearch}) {

    // The debouncer stuff is a bit tricky because we need to keep it across renders of the component
    const changeHandler = (value) => {
        handleSearch(value);
    };
    const debouncedChangeHandler = useMemo(
        () => debounce(changeHandler, 300)
    , []); // Be sure to add dependencies correctly here in case there are some (such as the request itself)

    // Stop the invocation of the debounced function
    // after unmounting
    useEffect(() => {
        return () => {
            debouncedChangeHandler.cancel();
        }
    }, [debouncedChangeHandler]);

    const onChange = (e) => {
        const value = e.target.value;
        debouncedChangeHandler(value);
    }

    return (<Grid container>
        <Grid item xs={8}>
            <h3>
                <FormattedMessage id="merchandising.header.title" />
            </h3>
        </Grid>
        <Grid item xs={4}>
            <StyledTextField
                id="merch-search"
                placeholder={intl.formatMessage({id: "merchandising.header.search.placeholder"})}
                variant="outlined"
                onChange={onChange}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchOutlined />
                        </InputAdornment>
                    ),
                }}
            />
        </Grid>
    </Grid>)
}

export default injectIntl(Search)
