import React, { Component } from 'react';
import {FormattedMessage} from "react-intl";

// @mui/material
import {withStyles} from "@mui/styles";

// @mui/icons-material
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";

// core components
import Card from "../../../../../components/card/card";
import CardBody from "../../../../../components/card/cardBody";
import GridContainer from "../../../../../components/grid/gridContainer";
import GridItem from "../../../../../components/grid/gridItem";

// style
import notificationStyle from "../../../../../assets/jss/views/stockeasy/product/stock/notification/notificationStyle";
import {successColor, dangerColor} from "../../../../../assets/jss/main";

class Notification extends Component {

    constructor(props) {
        super(props);
        this.state = {visible:true}
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.children !== this.props.children) {
            this.setState({visible: true});
        }
    }

    render() {
        const {classes, movementAction, variationName} = this.props;

        let action = movementAction === 'increment' ? 'add' : 'remove';

        if (this.state.visible) {
            return (
                <Card>
                    <CardBody className={classes.notificationBody}>
                        <GridContainer direction="row" justifyContent="flex-start" alignItems="center">
                            <GridItem xs={2} className={classes.checkIconContainer}>
                                <CheckCircleOutlinedIcon
                                    style={{ color: (movementAction === 'increment' ? successColor: dangerColor) }}
                                    className={classes.checkCircleIcon}
                                />
                            </GridItem>
                            <GridItem xs={10}>
                                <GridContainer direction="column">
                                    <GridItem className={classes.bold}>
                                        <FormattedMessage id={`stockeasy.manage.stock.notification.${action}`} />
                                    </GridItem>
                                    <GridItem className={classes.secondaryText}>
                                        <FormattedMessage
                                            id={`stockeasy.manage.stock.notification.${action}.detail`}
                                            values={{ variationName: variationName }}
                                        />
                                    </GridItem>
                                </GridContainer>
                            </GridItem>
                        </GridContainer>
                    </CardBody>
                </Card>
            )
        } else return null;
    }
}

export default withStyles(notificationStyle)(Notification);
