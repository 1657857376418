import React, {Component} from "react";
import Button from "./button";
import Loader from "../../views/stockeasy/product/components/loader";
import {injectIntl} from "react-intl";

class SubmitButton extends Component {
    getValue() {
        const {intl} = this.props;

        if (this.props.content) {
            return this.props.content;
        } else {
            return intl.formatMessage({id: "button.ok"})
        }
    }

    render() {
        return (
            <Button
                fullWidth
                round
                color={"success"}
                onClick={this.props.onClick}
                disabled={this.props.disabled}
            >
                {this.props.loading ? <Loader /> : this.getValue()}
            </Button>
        )
    }
}

export default (injectIntl(SubmitButton));
