import React from 'react'
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import {BeatLoader} from "react-spinners";

import makeStyles from "@mui/styles/makeStyles";

import Badge from "@mui/material/Badge";

import {
    irisBlueColor,
    mantisGreenColor,
    primaryColor,
    terraCottaColor,
    thunderGrayColor,
    yellowColor
} from "assets/jss/main";

import {getTranslation} from "domain/helpers/translations";

import {LineItemStatus} from "utils/LineItemStatus";

const status = {
    padding: "5px 25px 5px 25px",
    borderRadius: "20px",
    textAlign: "center",
    maxHeight: "32px",
    whiteSpace: "nowrap",
    fontWeight: "400",
    fontSize: "16px"
};

const useStyles = makeStyles(theme => ({
    pending: {
        ...status,
        backgroundColor: "rgba(230, 194, 41, 0.2)",
        color: yellowColor,
    },
    toShip: {
        ...status,
        backgroundColor: "rgba(0, 172, 193, 0.2)",
        color: irisBlueColor,
    },
    reserved: {
        ...status,
        backgroundColor: "rgba(105, 41, 230, 0.2)",
        color: '#976ef5',
    },
    canceled: {
        ...status,
        backgroundColor: "rgba(237, 106, 90, 0.2)",
        color: terraCottaColor,
    },
    confirmed: {
        ...status,
        backgroundColor: "rgba(120, 188, 97, 0.2)",
        color: mantisGreenColor
    },
    unavailable: {
        ...status,
        backgroundColor: "rgba(74, 74, 73, 0.2)",
        color: thunderGrayColor,
    },
    refunded: {
        ...status,
        backgroundColor: "rgba(230, 81, 0, 0.2)",
        color: "#ff833a",
    },
    returned: {
        ...status,
        backgroundColor: "#FDDDD2",
        color: "#FF823C",
    },
    returning: {
        ...status,
        backgroundColor: '#FFE6C9',
        color: '#FF8E5E',
    },
    returningBadge: {
        '& .MuiBadge-badge': {
            border: "3px solid white",
            borderRadius: "50%",
            width: '25px',
            height: '25px',
            padding: '0',
        }
    },
    cancellationReason: {
        display: 'block',
        color: '#4B4B4D',
        lineHeight: '2em',
    },
}));

export default function StatusChip({status, cancellationReason, loading}) {
    const classes = useStyles();

    if (loading) {
        return <BeatLoader
            sizeUnit={"px"}
            size={4}
            color={primaryColor}
            loading={true}
        />
    }

    let isNotReturning = true;
    let badgeContent = '';
    let itemStatus = status;

    if ([LineItemStatus.RETURNING_RUNNER, LineItemStatus.RETURNING_RETAILER].includes(status)) {
        badgeContent = status === LineItemStatus.RETURNING_RUNNER ? 'R' : 'M';
        itemStatus = 'returning';
        isNotReturning = false;
    }

    return (
        <>
            <Badge
                className={classes.returningBadge}
                badgeContent={badgeContent}
                color="primary"
                invisible={isNotReturning}
            >
                <span className={itemStatus === LineItemStatus.TO_SHIP ? classes.toShip : classes[itemStatus]}>
                    <FormattedMessage id={"picking.order.activity.status." + itemStatus} />
                </span>
            </Badge>
            {cancellationReason &&
                <span className={classes.cancellationReason}>
                    {getTranslation(cancellationReason).name}
                </span>
            }
        </>
    )
}

StatusChip.defaultProps = {
    status: '',
    cancellationReason: {},
    loading: false
};

StatusChip.propTypes = {
    status: PropTypes.string,
    cancellationReason: PropTypes.object,
    loading: PropTypes.bool
};
