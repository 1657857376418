import React, {useContext} from 'react';
import {FormattedMessage} from "react-intl";

import {blueColor, greyishBlue300} from "assets/jss/main";

import {ImageDropFieldContext} from "../../../context/ImageDropFieldContext";

function ImportButton() {
    const {disabled, openFileSelector} = useContext(ImageDropFieldContext);

    return (
        <button
            style={{
                color: disabled ? greyishBlue300 : blueColor,
                background: 'none',
                border: 'none',
                cursor: 'pointer'
            }}
            type="button"
            onClick={!disabled ? openFileSelector : undefined}
        >
            <FormattedMessage id="components.imageDropField.import" />
        </button>
    );
}

export default ImportButton;
