import React, {Component} from "react";
import {FormattedMessage} from "react-intl";

// @mui/material components
import {Grid, } from "@mui/material";
import {withStyles} from "@mui/styles";

// styles
import dashboardStyle from "assets/jss/views/picking/dashboard/dashboardStyle";

const percentage = (partialValue, totalValue) => {
    return (100 * partialValue) / totalValue;
};

const totalPercent = (array) => {
    let initialValue = 0;
    return array.reduce(function (acc, currentValue) {
        return acc + currentValue.productsCount;
    }, initialValue);
};

const LegendItem = (props) => {
    return (
        <div className={props.classes.legendItemContainer}>
            <div className={props.classes.firstLegendItemBlock}>
                <div className={props.classes.legendCircle} style={{backgroundColor: props.color}}/>
            </div>
            <div className={props.classes.secondLegendItemBlock}>
                <span>{`${props.percent}%  ${props.runnerName}`}</span>
            </div>

        </div>
    )
};

class TopRunnerBlock extends Component {
    render() {
        const {classes, topThreeRunners} = this.props;
        const total = topThreeRunners && totalPercent(topThreeRunners);
        const firstRunner = topThreeRunners && topThreeRunners[0] && topThreeRunners[0].productsCount;
        const secondRunner = topThreeRunners && topThreeRunners[1] && topThreeRunners[1].productsCount;
        const thirdRunner = topThreeRunners && topThreeRunners[2] && topThreeRunners[2].productsCount;
        let firstPercent = (Math.round(percentage(firstRunner, total)));
        let secondPercent = (Math.round(percentage(secondRunner, total)));
        let thirdPercent = (Math.round(percentage(thirdRunner, total)));
        const round = 100 - firstPercent;

        return (
            <Grid item className={classes.bestRunnerBlockContainer}>
                <div className={classes.statisticBlockHeader}>
                    <FormattedMessage id={"picking.dashboard.header.top.three.runners"}/>
                </div>
                <div className={classes.bestRunnerBlockData}>
                    <div className={classes.bestRunnerContainer}>
                        <div className={classes.commonRunnerBar}
                             style={{height: `100%`, backgroundColor: '#0066FF'}}/>
                        <div className={classes.commonRunnerBar}
                             style={{height: `${secondPercent + round}%`, backgroundColor: '#8FCAF2'}}/>
                        <div className={classes.commonRunnerBar}
                             style={{height: `${thirdPercent + round}%`, backgroundColor: '#DEF3FC'}}/>
                    </div>
                    <div className={classes.legendRunnerContainer}>
                        <LegendItem classes={classes} color="#0066FF" runnerName={topThreeRunners && topThreeRunners[0] && topThreeRunners[0].name} percent={firstPercent}/>
                        {topThreeRunners && topThreeRunners[1] &&
                            <LegendItem classes={classes} color="#8FCAF2" runnerName={topThreeRunners[1].name} percent={secondPercent}/>
                        }
                        {topThreeRunners && topThreeRunners[2] &&
                            <LegendItem classes={classes} color="#DEF3FC" runnerName={topThreeRunners[2].name} percent={thirdPercent}/>
                        }
                    </div>
                </div>
            </Grid>
        );
    }
}

export default (withStyles(dashboardStyle)(TopRunnerBlock));
