import React from 'react';
import {styled} from "@mui/material/styles";

import {container, defaultFont} from "assets/jss/main";

const StyledDiv = styled('div')(({ theme }) => ({
    ...container,
    ...defaultFont
}));

export default function Container(props) {
    return (
        <StyledDiv>
            {props.children}
        </StyledDiv>
    );
}
