import React, { Component } from 'react';
import {injectIntl} from "react-intl";
import Slider from "react-slick";

// @mui/material components
import {withStyles} from "@mui/styles";
import { Fab, Container, Grid} from "@mui/material";
import {AddRounded, HighlightOff, ImageOutlined} from "@mui/icons-material";

// styles
import imageSliderStyle from "assets/jss/views/stockeasy/product/create/slider/imageSliderStyle";

class ImageSlider extends Component {

    addImage(event) {
        if (event.target.files && event.target.files[0]) {
            this.props.addPictureInProduct(event.target.files, "product_picture", null);
        }
    }

    removeImage(id) {
        if (window.confirm(this.props.intl.formatMessage({id: "product.form.action.media.remove"}))) {
            this.props.removePictureFromProduct(id);
        }
    };

    renderPictures(images) {
        const {classes} = this.props;

        if ('undefined' == typeof(images) || images.length === 0) {
            return (
                <div className={classes.slickContainer}>
                    <ImageOutlined className={classes.imagePlaceholder} />
                </div>
            )
        }

        return images.map((item, index) => {
            if (item === null) {
                return;
            }

            return (
                <div key={index} className={classes.slickContainer}>
                    {this.props.removePictureFromProduct && (
                        <HighlightOff
                            fontSize={"large"}
                            className={classes.slickRemove}
                            onClick={() => this.removeImage(item)}
                        />
                    )}
                    {typeof item === 'object' && item.contentUrl !== null ? (
                        <img className={classes.image}
                             src={item.contentUrl}
                             alt=""
                        />
                    ) : (
                        <img className={classes.image} src={item} alt=""/>
                    )}
                </div>
            )
        });
    }

    renderSlider() {
        const {classes, addPictureInProduct} = this.props;
        const settings = {
            dots: false,
            infinite: false,
            centerMode: false,
            slidesToShow: 4,
            slidesToScroll: 1,
            variableWidth: false,
            initialSlide: 0,
            className: "slider variable-width",
            responsive: [
                {
                    breakpoint: 1190,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: false,
                        dots: true
                    }
                },
                {
                    breakpoint: 995,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };

        if(addPictureInProduct) {
            return (
                <Container className={classes.sliderWrapper} >
                    <Grid
                        container
                        spacing={3}
                    >
                        <Grid item xs={10} md={11}>
                            <Slider {...settings}>
                                {this.renderPictures(this.props.images)}
                            </Slider>
                        </Grid>

                        {addPictureInProduct && (
                            <Grid item xs={2} md={1}>
                                <input
                                    accept="image/*"
                                    onChange={(e) => addPictureInProduct(e)}
                                    className={classes.input}
                                    id="icon-button-file"
                                    type="file"
                                />
                                <label htmlFor="icon-button-file">
                                    <Fab
                                        size={"small"}
                                        color={"primary"}
                                        aria-label="upload picture"
                                        component="span"
                                    >
                                        <AddRounded fontSize={"small"} />
                                    </Fab>
                                </label>
                            </Grid>
                        )}
                    </Grid>
                </Container>
            )
        } else {
            return (
                <Container className={classes.sliderWrapper} >
                    <Grid
                        container
                        spacing={3}
                    >
                        <Grid item xs={12} md={12}>
                            <Slider {...settings}>
                                {this.renderPictures(this.props.images)}
                            </Slider>
                        </Grid>

                    </Grid>
                </Container>
            )
        }

    }

    render() {
        return this.renderSlider();
    }
}

export default withStyles(imageSliderStyle)(injectIntl(ImageSlider));
