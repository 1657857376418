import {fetch} from "utils/dataAccess";

export function error(error) {
    return {type: "SECTOR_DELETE_SELECTION_ERROR", error};
}

export function loading(loading) {
    return {type: "SECTOR_DELETE_SELECTION_LOADING", loading};
}

export function success(deleted) {
    return {type: "SECTOR_DELETE_SELECTION_SUCCESS", deleted};
}

export function deleteSelection(sectors) {
    return dispatch => {
        dispatch(loading(true));

        return fetch('/sector/delete_selection', {method: "POST", body: JSON.stringify({sectors: sectors})})
            .then(() => {
                dispatch(loading(false));
                dispatch(success(sectors));
            })
            .catch((e) => {
                dispatch(loading(false));
                dispatch(error(e.message));
            });
    };
}
