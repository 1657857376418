/**
 * @param filename the file will be downloaded from the browser with this name
 * @param content the file content
 * @param type equivalent of content-disposition header, ex: 'text/csv'
 */
export default function downloadFileFromString(filename, content, type) {
    const blob = new Blob([content], {type: type});
    if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(blob, filename);
    } else {
        const elem = window.document.createElement('a');
        elem.href = window.URL.createObjectURL(blob);
        elem.download = filename;
        document.body.appendChild(elem);
        elem.click();
        document.body.removeChild(elem);
    }
}

export function downloadFileFromBlob(filename, blob) {
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
}
