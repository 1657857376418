import React from "react";
import { ThemeProvider } from '@mui/material/styles';
import connect from "react-redux/es/connect/connect";
import {Link} from "react-router-dom";
import {FormattedMessage, injectIntl} from "react-intl";
import * as Cookies from "js-cookie";

// @mui/material components
import {
    Drawer,
    Hidden,
    Avatar,
    Grid,
    Button,
    createTheme,
    Tooltip,
    IconButton,
    Input,
    ListItem,
    ListItemText,
    Collapse,
    List,
    StyledEngineProvider
} from "@mui/material";

// @mui/icons-material
import {
    Store,
    Menu,
    DirectionsRun,
    PowerSettingsNewOutlined,
    ExpandLess,
    ExpandMore,
    AccountBalance,
    AssignmentReturned,
    Storefront,
    Equalizer
} from "@mui/icons-material";

// core components
import GridItem from "../grid/gridItem";
import GridContainer from "../grid/gridContainer";
import DrawerList from './drawerList';
import Notification from "./notification";

// styles
import {primaryColor, grayColor, dangerColor} from "../../assets/jss/main";
import sidebarStyle from "assets/jss/components/sidebar/sidebarStyle";
import logo from "../../assets/img/logo.png";

// actions
import {update} from "actions/profile/update";

// domain helper
import {getStockEasyLink} from "domain/helpers/StockEasyHelper";

// utils
import {capitalize} from "utils/capitalize";
import {fetch} from "../../utils/dataAccess";
import {list} from "../../actions/retailer/list";
import {Authorizations, hasAuthorization} from "../../utils/authorizations";
import {withStyles} from "@mui/styles";

const palette = createTheme({
    palette: {
        error: {main: dangerColor},
        primary: {main: primaryColor},
        secondary: {main: grayColor},
    }
});

class Sidebar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            isDesktop: window.innerWidth >= 960,
            organizationsTabOpen: false,
            stripeLink: false,
            searchTerm: '',
            filteredOrganizations: null,
        };

        this.organizationFilterInput = React.createRef();
        this.updateDimensions = this.updateDimensions.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (this.props.profileUpdated !== nextProps.profileUpdated) {
            // fix to relog the user on the right organization
            Cookies.remove('_a', {domain: process.env.REACT_APP_HOST});
            Cookies.remove('_b', {domain: process.env.REACT_APP_HOST});
            Cookies.remove('_c', {domain: process.env.REACT_APP_HOST});
            Cookies.remove('_d', {domain: process.env.REACT_APP_HOST});
            Cookies.remove('_e', {domain: process.env.REACT_APP_HOST});
            Cookies.remove('_f', {domain: process.env.REACT_APP_HOST});
            window.location = "/";
        }
    }

    handleDrawerToggle = (open) => {
        this.setState({open: open});
    };

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions);
    }

    updateDimensions() {
        let isDesktop = window.innerWidth >= 960;

        if (this.state.isDesktop !== isDesktop) {
            this.setState({isDesktop: isDesktop});
        }
    }

    UNSAFE_componentWillMount() {
        if (this.props.member && this.props.member['retailers'] && null !== this.props.member.retailers[0] && undefined !== this.props.member.retailers[0]) {
            let firstRetailerId = this.props.member["retailers"][0]["@id"].substring(11);

            const stripeAccounts = this.props.member?.stripeAccounts;
            if (
                !!stripeAccounts && stripeAccounts.hasOwnProperty(firstRetailerId)
                && !!stripeAccounts[firstRetailerId]
                && stripeAccounts[firstRetailerId].hasAccount === true
            ) {
                fetch(`/retailer/stripe_link?retailer=` + firstRetailerId, {signal: null}).then(response => {
                    response.json().then(retrieved => {
                        if (retrieved.success) {
                            this.setState({stripeLink: retrieved.url});
                        }
                    })
                });
            }
        }
    }

    handleOrganizationsTabChange = () => {
        this.setState(state => ({organizationsTabOpen: !state.organizationsTabOpen}));
    };

    handleCurrentOrganizationChange = (event) => {
        const {currentOrganization} = this.props;
        if (currentOrganization.id !== event.currentTarget.getAttribute('id')) {
            let item = {'@id': `/users/${this.props.member.userId}`};
            let values = {'currentOrganization': `/organizations/${event.currentTarget.getAttribute('id')}`};
            this.props.update(item, values);
        }
    };

    focusOnSearchFilter = () => {
        if (this.organizationFilterInput.current) {
            this.organizationFilterInput.current.focus();
        }
    }

    handleOrganizationSearch = (e) => {
        const newData = {};
        const textData = e.target.value.toUpperCase();

        Object.keys(this.props.organizations).forEach((value) => {
            const nameData = this.props.organizations[value].name.toUpperCase();
            const subdomainData = this.props.organizations[value].subdomain.toUpperCase();
            if (nameData.indexOf(textData) > -1 || subdomainData.indexOf(textData) > -1) {
                newData[value] = this.props.organizations[value];
            }
        });

        this.setState({
            searchTerm: e.target.value,
            filteredOrganizations: newData
        });
    }

    render() {
        const {classes, itemsPerPage, member, organizations, applications, match, currentOrganization} = this.props;
        const {organizationsTabOpen, filteredOrganizations} = this.state;
        const currentOrganizationName = currentOrganization ? capitalize(currentOrganization['name']) : '';
        const nbOrganizations = Object.keys(organizations).length;

        const isPickingPath = match.path.startsWith('/picking') || match.path.startsWith('/quote');
        const isStockEasyPath = match.path.startsWith('/stockeasy');
        const isStatiticsPath = match.path.startsWith('/statistics');

        let userOrganisations = {};
        let availablesOrganizations = filteredOrganizations ?? organizations;

        Object.keys(availablesOrganizations).forEach((value) => {
            userOrganisations[value] = capitalize(availablesOrganizations[value]['name']);
        });

        let applicationsCount = 0;

        if (applications) {
            Object.keys(applications).map(function(key) {
                if (applications[key]) {
                    applicationsCount = applicationsCount + 1;
                }

                return applicationsCount;
            });
        }

        const drawer = <div className={classes.drawerRootContainer}>
            {process.env.REACT_APP_ENVIRONMENT === 'preprod' &&
                <div className={classes.envContainer}>
                    {this.props.intl.formatMessage({id: "sidebar.app.process.preprod"})}
                </div>
            }
            <div className={classes.container}>
                {member &&
                <GridContainer className={classes.infoContainer}>
                    <GridItem>
                        {!!currentOrganization?.eshopUrl &&
                            <Tooltip title={this.props.intl.formatMessage({id: "sidebar.app.externalLinkToEshop"})}>
                                <Storefront
                                    onClick={() => window.open(currentOrganization.eshopUrl, '_blank')}
                                    className={classes.badgeEshopExternalLink}
                                    fontSize='small'
                                />
                            </Tooltip>
                        }
                        <Notification history={this.props.history} itemsPerPage={itemsPerPage}/>
                        <div className={classes.logoContainer}>
                            <Link
                                to={(member["retailers"]) ? `/retailers/show/${encodeURIComponent(member["retailers"][0]["@id"])}/me` : `/me`}
                            >
                                {member["picture"] ?
                                    <Avatar src={member["picture"]} className={classes.avatar} />
                                    :
                                    <Avatar className={classes.avatarText}>
                                        {member['givenName'] && member['givenName'].charAt(0)}
                                        {member['familyName'] && member['familyName'].charAt(0)}
                                    </Avatar>
                                }
                            </Link>
                            <Link
                                to={(member["retailers"]) ? `/retailers/show/${encodeURIComponent(member["retailers"][0]["@id"])}/me` : `/me`}
                            >
                                <span className={"givenName"}>{member["givenName"]}</span>
                            </Link>
                            <span className={"email"}>{member["email"]}</span>
                            {nbOrganizations > 1 ?
                                <div>
                                    <ListItem
                                        className={classes.listItem}
                                        classes={{selected: classes.subListSelected}}
                                        onClick={this.handleOrganizationsTabChange}
                                        key={"organizations"}
                                        button
                                    >
                                        <ListItemText
                                            primary={<img className={classes.logo} src={logo} alt={""}/>}
                                        />
                                        {organizationsTabOpen ? <ExpandLess/> : <ExpandMore/>}
                                    </ListItem>
                                    <Collapse in={organizationsTabOpen} onEntered={this.focusOnSearchFilter}>
                                        <List
                                            className={nbOrganizations > 2 ? classes.organizationListContainer : classes.listContainer}
                                            disablePadding
                                        >
                                            <Input
                                                className={classes.inputOrganizationFilter}
                                                onChange={(e) => this.handleOrganizationSearch(e)}
                                                value={this.state.searchTerm}
                                                inputRef={this.organizationFilterInput}
                                                placeholder={this.props.intl.formatMessage({id: "sidebar.search.organization.label"})}
                                            />
                                            {Object.keys(userOrganisations).length ?
                                                <div>
                                                    {Object.keys(userOrganisations).map(item => {
                                                        return (
                                                            <ListItem
                                                                className={classes.listItem}
                                                                classes={{selected: classes.selected}}
                                                                onClick={(event) => this.handleCurrentOrganizationChange(event)}
                                                                value={userOrganisations[item]}
                                                                id={item}
                                                                key={userOrganisations[item]}
                                                                button
                                                            >
                                                                <ListItemText
                                                                    className={classes.subListText}
                                                                    inset
                                                                    primaryTypographyProps={{color: currentOrganizationName === userOrganisations[item] ? "primary" : "secondary"}}
                                                                    primary={userOrganisations[item]}
                                                                />
                                                            </ListItem>
                                                        );
                                                    })}
                                                </div>
                                                :
                                                <ListItem value={"0"}>
                                                    <ListItemText
                                                        className={classes.subListText}
                                                        inset
                                                        primary={this.props.intl.formatMessage({id: "sidebar.search.organization.nothing"})}
                                                    />
                                                </ListItem>
                                            }
                                        </List>
                                    </Collapse>
                                </div> : <img className={classes.logo} src={logo} alt={""}/>
                            }
                        </div>
                    </GridItem>
                </GridContainer>
                }
            </div>

            <DrawerList {...this.props} />

            <div className={classes.footer}>
                {(applicationsCount > 1 || this.state.stripeLink) &&
                <Grid className={classes.footerApps}>
                    <Grid container justifyContent="center" spacing={4}>
                        {/*MARKETPLACE*/}
                        {(applicationsCount > 1 && applications["marketplace"]) &&
                            <Grid item>
                                <Link to={"/"}>
                                    <Tooltip title={this.props.intl.formatMessage({id: "sidebar.app.marketplace"})}>
                                        <Store
                                            fontSize='large'
                                            color={isPickingPath || isStockEasyPath || isStatiticsPath ? 'secondary' : 'primary'}
                                        />
                                    </Tooltip>
                                </Link>
                            </Grid>
                        }
                        {/*PICKING*/}
                        {(applicationsCount > 1 && applications["picking"]) &&
                            <Grid item>
                                <Link to={"/picking"}>
                                    <Tooltip title={this.props.intl.formatMessage({id: "sidebar.app.picking"})}>
                                        <DirectionsRun
                                            fontSize='large'
                                            color={isPickingPath ? 'primary' : 'secondary'}
                                        />
                                    </Tooltip>
                                </Link>
                            </Grid>
                        }
                        {/* STOCK EASY : because admins doesn't need an access to Stock Easy we remove it for the sidebar when connected user is an admin */}
                        {(applicationsCount > 1 && applications["stockEasy"]) && (!hasAuthorization(this.props.authorizations, Authorizations.GLOBAL_MEMBER_MANAGEMENT)) &&
                            <Grid item>
                                {/* we return the first retailers product list by default, a retailer is supposed to have at least one member.retailers */}
                                <Link to={getStockEasyLink(this.props.member.retailers[0]['@id'].replace('/retailers/', ''))}>
                                    <Tooltip title={this.props.intl.formatMessage({id: "sidebar.app.stockEasy"})}>
                                        <AssignmentReturned
                                            fontSize='large'
                                            color={!isStockEasyPath ? 'secondary' : 'primary'}
                                        />
                                    </Tooltip>
                                </Link>
                            </Grid>
                        }
                        {(hasAuthorization(this.props.authorizations, Authorizations.STATISTICS_ACCESS)) &&
                        <Grid item>
                            {/* we return the first retailers product list by default, a retailer is supposed to have at least one member.retailers */}
                            <Link to={'/statistics'}>
                                <Tooltip title={this.props.intl.formatMessage({id: "sidebar.app.statistics"})}>
                                    <Equalizer
                                        fontSize='large'
                                        color={!isStatiticsPath ? 'secondary' : 'primary'}
                                    />
                                </Tooltip>
                            </Link>
                        </Grid>
                        }

                        {this.state.stripeLink &&
                            <Grid item>
                                <a href={this.state.stripeLink} target={"_blank"} rel="noopener noreferrer">
                                    <Tooltip title={"Stripe"}>
                                        <AccountBalance fontSize='large' color={'secondary'}/>
                                    </Tooltip>
                                </a>
                            </Grid>
                        }
                    </Grid>
                </Grid>}

                <Link to={"/logout"} className={classes.logoutButton}>
                    <Button fullWidth variant="standard">
                        <PowerSettingsNewOutlined/>
                        <FormattedMessage id={"topbar.action.logout"} />
                    </Button>
                </Link>
            </div>
        </div>;

        return (
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={palette}>
                    <Hidden mdUp implementation="css">
                        <IconButton
                            color="primary"
                            aria-label="Open drawer"
                            onClick={() => this.handleDrawerToggle(true)}
                            className={classes.menuButton}
                            size="large">
                            <Menu/>
                        </IconButton>
                    </Hidden>
                    <div>
                        <Drawer
                            className={this.state.isDesktop ? classes.drawer : null}
                            variant={this.state.isDesktop ? "persistent" : "temporary"}
                            anchor={this.state.isDesktop ? "left" : "left"}
                            open={this.state.isDesktop ? true : this.state.open}
                            onClose={() => {
                                if (!this.state.isDesktop) {
                                    this.handleDrawerToggle(false)
                                }
                            }}
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                            ModalProps={!this.state.isDesktop ? {
                                keepMounted: true,
                            } : {}}
                        >
                            {drawer}
                        </Drawer>
                    </div>
                </ThemeProvider>
            </StyledEngineProvider>
        );
    }
}

const mapStateToProps = state => {
    const {
        retrieved,
        loading,
        error,
    } = state.retailer.list;

    return {
        member: state.authentication.member,
        applications: state.authentication.applications,
        organizations: state.authentication.organizations,
        profileUpdated: state.profile.update.updated,
        currentOrganization: state.currentOrganization.retrieved,
        authorizations: state.authentication.authorizations,
        retrieved,
        loading,
        error
    }
};

const mapDispatchToProps = dispatch => ({
    update: (item, values) => dispatch(update(item, values)),
    list: params => dispatch(list(params)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(sidebarStyle)(injectIntl(Sidebar)));
