import {combineReducers} from "redux";

export function massUpdateError(state = null, action) {
    switch (action.type) {
        case "PRODUCT_MASS_UPDATE_UPDATE_ERROR":
            return action.massUpdateError;

        default:
            return state;
    }
}

export function massUpdateLoading(state = false, action) {
    switch (action.type) {
        case "PRODUCT_MASS_UPDATE_UPDATE_LOADING":
            return action.massUpdateLoading;

        default:
            return state;
    }
}

export function massUpdateSuccess(state = null, action) {
    switch (action.type) {
        case "PRODUCT_MASS_UPDATE_UPDATE_SUCCESS":
            return action.massUpdateSuccess;

        default:
            return state;
    }
}

export default combineReducers({
    massUpdateError,
    massUpdateLoading,
    massUpdateSuccess
});