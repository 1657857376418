import React, {Component} from "react";

import {withStyles} from "@mui/styles";

import ListContainer from "./components/list/container";

import listStyle from "../../assets/jss/views/quote/listStyle";

class QuotesList extends Component {
    render() {
        const { classes } = this.props;

        return (
            <div className={classes.containerWithSidebar}>
                <div className={classes.container}>
                    <ListContainer
                        history={this.props.history}
                        match={this.props.match}
                    />
                </div>
            </div>
        );
    }
}

export default withStyles(listStyle)(QuotesList);
