import React from "react";

import {Button} from "@mui/material";

import {styled} from '@mui/material/styles';

import {
    dangerColor,
    dangerColorDisabled,
    dangerColorHover
} from "assets/jss/main";

const StyledButton = styled(Button)(({ theme }) => ({
    backgroundColor: dangerColor,
    borderRadius: '8px',
    textTransform: 'none',
    padding:'12px 16px',
    lineHeight: 'normal',
    [theme.breakpoints.down('md')]: {
        width: '100%'
    },
    '&:hover': {
        backgroundColor: dangerColorHover
    },
    '&.Mui-disabled' : {
        backgroundColor: dangerColorDisabled,
        color: '#fff'
    }
}));

export default function DeleteButton({...props}) {
    return (
        <StyledButton
            variant="contained"
            disableElevation
            {...props}
        >
            {props.children}
        </StyledButton>
    )
}
