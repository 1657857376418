import React from "react";
import PropTypes from "prop-types";
import {BeatLoader} from "react-spinners";

import {infoColor} from "assets/jss/main";

BeatLoaderRegular.propTypes = {
    loading: PropTypes.bool
};

export default function BeatLoaderRegular(props) {
    return (
        <BeatLoader
            color={infoColor}
            {...props}
        />
    )
}
