import {fetch} from "../../utils/dataAccess";
import normalizeTranslations from "../translations";

export function updateError(updateError) {
    return {type: "PRODUCT_UPDATE_UPDATE_ERROR", updateError};
}

export function updateLoading(updateLoading) {
    return {type: "PRODUCT_UPDATE_UPDATE_LOADING", updateLoading};
}

export function updateSuccess(updateSuccess) {
    return {type: "PRODUCT_UPDATE_UPDATE_SUCCESS", updateSuccess};
}

export function update(item, values) {
    const data = {};
    const available_data = {
        "name"              : "string",
        "externalId"        : "string",
        "outletPrice"       : "float",
        "price"             : "float",
        "stock"             : "int",
        "url"               : "string",
        "sku"               : "string",
        "description"       : "string",
        "categories"        : "categories",
        "corners"           : "corners",
        "brand"             : "brand",
        "taxRate"           : "taxRate",
        "gender"            : "gender",
        "status"            : "bool",
        "catalogOnly"       : "bool",
        "privatePrice"      : "bool",
        "privateProduct"    : "bool",
        "hiddenPrice"       : "bool",
    };

    Object.keys(available_data).forEach(function(key,index) {
        if( null !== values[key] || (values[key] === false && key !== 'status')){
            switch (available_data[key]) {
                default: break;
                case "string":
                    data[key] = (values[key])? values[key] : null;
                    break;
                case "bool":
                    data[key] = values[key];
                    break;
                case "float":
                    data[key] = (values[key])? parseFloat(values[key]) : 0;
                    break;
                case "int":
                    data[key] = (values[key])? parseInt(values[key]) : 0;
                    break;
                case "array":
                    data[key] = values[key];
                    break;
                case "brand":
                    if(Array.isArray(values[key]) || typeof values[key] === "object"){
                        if(values[key]["@id"]) {
                            data[key] = values[key]["@id"];
                        }
                    }else{
                        data[key] = values[key];
                    }
                    break;
                case "taxRate":
                    if(Array.isArray(values[key]) || typeof values[key] === "object"){
                        if(values[key]["@id"]) {
                            data[key] = values[key]["@id"];
                        }
                    }else{
                        data[key] = values[key];
                    }
                    break;
                case "gender":
                    if((Array.isArray(values[key]) || typeof values[key] === "object")){
                        if(values[key]["@id"]) {
                            data[key] = values[key]["@id"];
                        }
                    }else{
                        data[key] = values[key];
                    }
                    break;
                case "categories":
                    if (Array.isArray(values[key]) || typeof values[key] === "object") {
                        let tmp = [];
                        values[key].forEach(function (value) {
                            if (Array.isArray(values) || typeof value === "object") {
                                if (value["@id"]) {
                                    tmp.push(value["@id"]);
                                }
                            } else {
                                tmp.push(value);
                            }
                        });
                        data[key] = tmp;
                    }
                    break;
                case "corners":
                    if (Array.isArray(values[key]) || typeof values[key] === "object") {
                        let tmp = [];
                        values[key].forEach(function (value) {
                            if (Array.isArray(values) || typeof value === "object") {
                                if (value["@id"]) {
                                    tmp.push(value["@id"]);
                                }
                            } else {
                                tmp.push(value);
                            }
                        });
                        data[key] = tmp;
                    }
                    break;
            }
        }
    });

    normalizeTranslations(values);
    data.translations = values.translations;

    return dispatch => {
        dispatch(updateLoading(true));
        return fetch(item["@id"], {
            method: "PUT",
            headers: new Headers({"Content-Type": "application/ld+json"}),
            body: JSON.stringify(data)
        }).then(response =>
            response.json()
        ).then((retrieved) => {
            dispatch(updateSuccess(retrieved));
            dispatch(updateLoading(false));
        })
            .catch(e => {
                dispatch(updateLoading(false));
                dispatch(updateError(e.message));
            });

    };
}

export function reset() {
    return dispatch => {
        dispatch(updateError(false));
        dispatch(updateSuccess(null));
        dispatch(updateLoading(null));
    };
}
