import {fetch} from "../../../utils/dataAccess";

export default async function createLine(quoteId, unitPrice, variationId) {
    const body = {
        quote: quoteId,
        unitPrice: unitPrice,
        variation: variationId
    };
    return new Promise((resolve, reject) => {
        fetch(`/quote_line_items`, {
            method: 'POST',
            body: JSON.stringify(body),
        })
            .then(response => response.json().then(data => resolve(data)))
            .catch(error => reject(error));
    });
}
