import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

// @mui/material components
import {withStyles} from "@mui/styles";
import Snack from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";

// @mui/icons-material
import Close from "@mui/icons-material/Close";

// styles
import snackbarStyle from "../../assets/jss/components/snackbar/snackbarStyle";

function Snackbar({ ...props }) {
    const { classes, message, color, close, icon, place, open, autoHideDuration, onClose } = props;
    let action = [];
    const messageClasses = classNames({
        [classes.iconMessage]: icon !== undefined
    });
    if (close !== undefined) {
        action = [
            <IconButton
                className={classes.iconButton}
                key="close"
                aria-label="Close"
                color="inherit"
                onClick={() => props.closeNotification()}
                size="large">
                <Close className={classes.close} />
            </IconButton>
        ];
    }
    return (
        <Snack
            anchorOrigin={{
                vertical: place.indexOf("t") === -1 ? "bottom" : "top",
                horizontal:
                    place.indexOf("l") !== -1
                        ? "left"
                        : place.indexOf("c") !== -1 ? "center" : "right"
            }}
            open={open}
            message={
                <div className={classes.snackContentContainer}>
                    {icon !== undefined ? <props.icon className={classes.icon} /> : null}
                    <span className={messageClasses}>{message}</span>
                </div>
            }
            action={action}
            autoHideDuration={autoHideDuration}
            onClose={onClose}
            ContentProps={{
                classes: {
                    root: classes.root + " " + classes[color],
                    message: classes.message
                }
            }}
        />
    );
}

Snackbar.propTypes = {
    classes: PropTypes.object.isRequired,
    message: PropTypes.node.isRequired,
    color: PropTypes.oneOf(["info", "success", "warning", "danger", "primary"]),
    close: PropTypes.bool,
    icon: PropTypes.func,
    place: PropTypes.oneOf(["tl", "tr", "tc", "br", "bl", "bc"]),
    open: PropTypes.bool
};

export default withStyles(snackbarStyle)(Snackbar);
