import React from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";

// @mui/material components
import {makeStyles} from "@mui/styles";

// core components
import Input from "components/input/input";

// styles
import { infoColor } from "../../../assets/jss/main";

const useStyles = makeStyles(theme => ({
    formControl: {
        padding: "0px",
        width: "100%"
    },
    fieldContainer: {
        padding: "5px"
    },
    fieldLabel: {
        color: infoColor,
    },
    errorMessages:{
        color:'red'
    },
}));

function FormFields(props) {
    const { createError } = props;
    const classes = useStyles();

    return (
        <div className={classes.fieldContainer}>
            <label htmlFor={`title_${props.input.name}`} className={classes.fieldLabel}>
                {props.label}
            </label>
            {props.type === "text" && (
                <Input
                    {...props.input}
                    type={props.type}
                    required={props.required}
                    id={`title_${props.input.name}`}
                    inputProps={{
                        placeholder: props.placeholder,
                        type: props.type,
                        required: props.required,
                        ...props.input,
                        autoComplete: props.autoComplete
                    }}
                    formControlProps={{
                        className: classes.formControl
                    }}
                />
            )}
            {createError && createError.errors && createError.errors[props.input.name] &&
            <span className={classes.errorMessages}>
                <FormattedMessage id={createError.errors[props.input.name]}/>
            </span>
            }
        </div>
    );
}

const mapStateToProps = state => {
    return {
        createError: state.role.create.error,
    }
};

export default connect(
    mapStateToProps,
    null
)(FormFields);
