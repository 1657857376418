import React, {useContext} from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import {Formik} from "formik";

// @mui/material
import {makeStyles} from "@mui/styles";

// @mui/icons-material
import {ArrowBackIos} from "@mui/icons-material";

// core components
import AllTranslationsRequiredMessage from "views/configuration/components/AllTranslationsRequiredMessage";
import Button from "components/button/button";
import GridItem from "components/grid/gridItem";
import FormContainer from "views/configuration/components/FormContainer";

// view components
import Loader from 'views/configuration/components/loader';
import DefaultSwitchField from "./components/Fields/DefaultSwitchField";
import EmailTemplateField from "./components/Fields/EmailTemplateField";
import StatusField from "./components/Fields/StatusField";
import TransitionField from "./components/Fields/TransitionField";
import TranslationForm from "./components/TranslationForm";

// context
import {CancellationReasonListContext} from "contexts/CancellationReasonListContext";

// api
import create from 'api/cancellationReason/create';
import update from "api/cancellationReason/update";

// utils
import {LineItemStatus} from "utils/LineItemStatus";
import {
    getTransitionsThatCanApplyCancellationReasonByStatus,
    getCancellationTransitionsThatNotRefund
} from "utils/lineItemTransitions";

// redux store
import store from 'store';

// style
const useStyles = makeStyles(theme  => ({
    actionsContainer: {
        position: "absolute",
        right: "5px",
        bottom: "0px",
        textAlign: "right"
    },
}));

function Index(props) {
    const {
        refreshCancellationReasonList,
        closeCancellationReasonDialog,
        selectedCancellationReason
    } = useContext(CancellationReasonListContext)

    const { intl } = props;

    const classes = useStyles();

    const isEditing = !!selectedCancellationReason;

    const locales = store.getState().currentOrganization?.retrieved?.locales;

    function getTranslationInitialValues() {
        const translations = {};

        if (isEditing) {
            locales.forEach(locale => translations[locale] = {
                name: selectedCancellationReason.translations[locale]?.name,
            });
        } else {
            locales.forEach(locale => translations[locale] = {
                name: '',
            });
        }

        return translations;
    }

    const initialValues = {
        enabled: isEditing ? selectedCancellationReason.enabled : true,
        status: isEditing ? selectedCancellationReason.status : LineItemStatus.PENDING,
        translations: getTranslationInitialValues(),
        transition: isEditing ? selectedCancellationReason.transition : getTransitionsThatCanApplyCancellationReasonByStatus(LineItemStatus.PENDING)[0],
        emailTemplate: isEditing ? selectedCancellationReason.emailTemplate : '',
        orderAllowed: isEditing ? selectedCancellationReason.orderAllowed : false,
        itemAllowed: isEditing ? selectedCancellationReason.itemAllowed : false,
        overrideStock: isEditing ? selectedCancellationReason.overrideStock : false,
        retailerAllowed: isEditing ? selectedCancellationReason.retailerAllowed : true,
        shouldTransferBack: isEditing ? selectedCancellationReason.shouldTransferBack : true
    };

    const validate = values => {
        const errors = {};

        if (!values.orderAllowed && !values.itemAllowed) {
            errors.orderAllowed = intl.formatMessage({id: "cancellation_reason.form.error.orderAllowed.missing"});
            errors.itemAllowed = intl.formatMessage({id: "cancellation_reason.form.error.itemAllowed.missing"});
        }

        locales.forEach(locale => {
            if (!!values.translations[locale]) {
                const translation = values.translations[locale];

                if (!translation.name || translation.name.trim() === '') {
                    if (!errors.translations) errors.translations = {};
                    if (!errors.translations[locale]) errors.translations[locale] = {};
                    errors.translations[locale].name = intl.formatMessage({id: "cancellation_reason.form.error.name.missing"});
                }
            }
        });

        return errors;
    };

    function updateCancellationReason(values) {
        for (const locale of locales) {
            values.translations[locale].id = selectedCancellationReason.translations[locale].id;
            values.translations[locale]['@id'] =  selectedCancellationReason.translations[locale]['@id'];
        }

        update(selectedCancellationReason, values).finally(() => {
            closeCancellationReasonDialog();
            refreshCancellationReasonList();
        });
    }

    function createCancellationReason(values) {
        create(values).finally(() => {
            closeCancellationReasonDialog();
            refreshCancellationReasonList();
        });
    }

    return (
        <Formik
            initialValues={initialValues}
            validate={validate}
            validateOnChange={true}
            validateOnBlur={false}
            validateOnMount={true}
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true);

                if (isEditing) {
                    updateCancellationReason(values);
                } else {
                    createCancellationReason(values);
                }
            }}
        >
            {({
                  values,
                  handleSubmit,
                  isSubmitting,
                  submitForm,
                  isValid,
                }) => (
                <form onSubmit={handleSubmit}>
                    <FormContainer>
                        <AllTranslationsRequiredMessage />
                        <GridItem xs={12} sm={12}>
                            <TranslationForm />
                        </GridItem>
                        <GridItem xs={12} sm={12}>
                            <DefaultSwitchField
                                name={'enabled'}
                                checked={!!values ? values.enabled : initialValues.enabled}
                                labelTranslationId={'cancellation_reason.form.label.enabled'}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12}>
                            <StatusField />
                        </GridItem>
                        <GridItem xs={12} sm={12}>
                            <TransitionField status={values.status} />
                        </GridItem>
                        <GridItem xs={12} sm={12}>
                            <EmailTemplateField transition={values.transition} />
                        </GridItem>
                        <GridItem xs={12} sm={12}>
                            <DefaultSwitchField
                                name={'orderAllowed'}
                                checked={!!values ? values.orderAllowed : initialValues.orderAllowed}
                                labelTranslationId={'cancellation_reason.form.label.orderAllowed'}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12}>
                            <DefaultSwitchField
                                name={'itemAllowed'}
                                checked={!!values ? values.itemAllowed : initialValues.itemAllowed}
                                labelTranslationId={'cancellation_reason.form.label.itemAllowed'}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12}>
                            <DefaultSwitchField
                                name={'overrideStock'}
                                checked={(!!values ? values.overrideStock : initialValues.overrideStock)}
                                labelTranslationId={'cancellation_reason.form.label.overrideStock'}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12}>
                            <DefaultSwitchField
                                name={'retailerAllowed'}
                                checked={!!values ? values.retailerAllowed : initialValues.retailerAllowed}
                                labelTranslationId={'cancellation_reason.form.label.retailerAllowed'}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12}>
                            <DefaultSwitchField
                                name={'shouldTransferBack'}
                                checked={
                                    (!!values ? values.shouldTransferBack : initialValues.shouldTransferBack)
                                    && !getCancellationTransitionsThatNotRefund().includes(values.transition)
                                }
                                labelTranslationId={'cancellation_reason.form.label.shouldTransferBack'}
                                disabled={getCancellationTransitionsThatNotRefund().includes(values.transition)}
                            />
                        </GridItem>
                    </FormContainer>
                    <div className={classes.actionsContainer}>
                        <Button
                            color={"info"}
                            simple
                            onClick={closeCancellationReasonDialog}
                        >
                            <ArrowBackIos />
                            <FormattedMessage id={"cancellation_reason.form.cancel"} />
                        </Button>
                        <Button
                            round
                            color={"success"}
                            onClick={submitForm}
                            disabled={isSubmitting || !isValid}
                        >
                            <FormattedMessage id={"cancellation_reason.form.submit"} />
                            {isSubmitting && <Loader />}
                        </Button>
                    </div>
                </form>
            )}
        </Formik>
    );
}

export default injectIntl(Index);
