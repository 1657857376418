import React from 'react';
import GridContainer from "components/grid/gridContainer";
import GridItem from "components/grid/gridItem";

export default function NoteContainer(props) {
    return (
        <GridContainer sx={{ marginBottom: '24px' }}>
            <GridItem xs={12}>
                {props.children}
            </GridItem>
        </GridContainer>
    );
}
