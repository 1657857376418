import {fetch} from "../../../utils/dataAccess";

export function error(error) {
    return {type: "LINE_ITEM_SHIPPING_METHOD_LIST_ERROR", error};
}

export function loading(loading) {
    return {type: "LINE_ITEM_SHIPPING_METHOD_LIST_LOADING", loading};
}

export function success(retrieved) {
    return {type: "LINE_ITEM_SHIPPING_METHOD_LIST_SUCCESS", retrieved};
}

export function shippingMethodsList(params = '') {
    return dispatch => {
        fetch(`/lineitem/shippingmethods?${params}`).then(response => {
            response.json().then(retrieved => {
                dispatch(success(retrieved["hydra:member"]));
            })
        }).catch(error => {
            // canceled
        });
    }
}

export function reset() {
    return dispatch => {
        dispatch({type: "LINE_ITEM_SHIPPING_METHOD_LIST_RESET"});
    };
}
