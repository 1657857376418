import React, {useState} from 'react';
import ModalAddInternalNote from '../notes/components/ModalAddInternalNote';
import AddInternalNoteButton from '../notes/components/AddInternalNoteButton';

export default function GridAddInternalNote() {
    const [isInternalNoteDialogOpen, setInternalNoteDialogOpen] = useState(false);

    return (
        <>
            <ModalAddInternalNote
                open={isInternalNoteDialogOpen}
                onClose={() => setInternalNoteDialogOpen(false)}
            />
            <AddInternalNoteButton
                onClick={() => setInternalNoteDialogOpen(true)}
            />
        </>
    );
}
