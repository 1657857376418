import React from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {Line} from "react-chartjs-2";

import CardBody from "components/card/cardBody";

import CardStats from "../cards/cardStats";
import CardStatsGraphTitle from "../cards/cardStatsGraphTitle";
import Loader from "../loader";

import {defaultOptions, blueLine} from "config/graph/defaultLineGraphConfig";

function AnalyticsGraph(props) {
    const { analyticsOnlinePeople, loading, intl } = props

    blueLine.label = intl.formatMessage({id: "dashboard.graph.label.online"});
    blueLine.pointRadius = 2
    blueLine.pointHoverRadius = 4
    blueLine.pointBorderWidth = 2
    blueLine.data = analyticsOnlinePeople;

    return (
        <CardStats>
            <Loader loading={loading} />
            <CardStatsGraphTitle>
                <FormattedMessage id={"dashboard.graph.onlineVisitor.title"}/>
            </CardStatsGraphTitle>
            <CardBody>
                <Line
                    data={{datasets: [blueLine]}}
                    options={defaultOptions}
                    width={400}
                    height={150}
                />
            </CardBody>
        </CardStats>
    )
}

export default injectIntl(AnalyticsGraph);
