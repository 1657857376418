import React, {Component} from "react";
import * as Cookies from "js-cookie";

// core components
import CompleteProfileForm from "./completeProfileForm";
import GridContainer from "components/grid/gridContainer";
import GridItem from "components/grid/gridItem";
import CardBody from "components/card/cardBody";

// utils
import {fetch} from "utils/dataAccess";
import {UserAgentUtils} from "../utils/useragent";

class CompleteProfile extends Component {
    constructor(props) {
        super(props);

        this.state = {
            authenticatedUserData: JSON.parse(Cookies.get("_s").toString()),
            loading: false,
            success: false,
            error: ''
        };
    }

    completeProfile(values) {
            this.setState({
                loading: true
            });

            let user = `/users/${this.state.authenticatedUserData.user}`;
            let vals = {
                "givenName": values.givenName,
                "familyName": values.familyName
            };

            fetch(user, {method: "PUT", body: JSON.stringify(vals), headers: new Headers({"authorization": "Bearer " + this.state.authenticatedUserData.token})})
                .then(response => {
                    return response.json();
                })
                .then(() =>
                    this.setState({
                        success: true,
                        loading: false
                    })
                )
                .catch(e => {
                    this.setState({
                        error: e.message,
                        loading: false
                    })
                });
    };

    render() {
        if (this.state.success) {
            this.props.history.push('/');
        }

        return (
            <GridContainer direction="row" justifyContent="center" alignItems="center">
                <GridItem xs={UserAgentUtils.isMobile() ? 12 : 6}>
                    <CardBody>
                        <CompleteProfileForm
                            onSubmit={values => this.completeProfile(values)}
                            initialValues={{
                                loading: this.state.loading
                            }}
                        />
                    </CardBody>
                </GridItem>
            </GridContainer>
        );
    }
}

export default CompleteProfile;
