import {fetch} from '../../utils/dataAccess';

export function error(error) {
    return {type: "LINE_ITEM_UPDATE_ERROR", error};
}

export function loading(loading) {
    return {type: "LINE_ITEM_UPDATE_LOADING", loading};
}

export function success(success) {
    return {type: "LINE_ITEM_UPDATE_SUCCESS", success};
}

export const confirm = (item) => dispatch => {
    dispatch(loading(true));

    return fetch(`${item["@id"]}/collect`, {
        method: 'PUT',
        headers: new Headers({'Content-Type': 'application/ld+json'}),
        body: JSON.stringify({})
    })
    .then(() => {
        dispatch(loading(false))
        dispatch(success(true))
    })
    .catch((e) => {
        dispatch(loading(false));
        dispatch(error(e.message))
    });
};

export const reserve = (item) => dispatch => {
    dispatch(loading(true));

    return fetch(`${item["@id"]}/reserve`, {
        method: 'PUT',
        headers: new Headers({'Content-Type': 'application/ld+json'}),
        body: JSON.stringify({})
    })
        .then(() => {
            dispatch(loading(false))
            dispatch(success(true))
        })
        .catch((e) => {
            dispatch(loading(false));
            dispatch(error(e.message))
        });
};

export const updatePrice = (item, price) => dispatch => {
    const priceAsFloat = parseFloat(price);
    if (isNaN(priceAsFloat)) {
        throw new Error(`The value ${price} can't be parsed at float.`)
    }

    dispatch(loading(true));
    return fetch(`${item["@id"]}/updatePrice`, {
        method: "PUT",
        headers: new Headers({"Content-Type": "application/ld+json"}),
        body: JSON.stringify({
            price: priceAsFloat,
        })
    })
    .then(() => {
        dispatch(loading(false));
        dispatch(success(true))
    })
    .catch((e) => {
        dispatch(loading(false));
        dispatch(error(e.message))
    });
}

export const assign = (item, data) => dispatch => {
    dispatch(loading(true));

    return fetch(`${item["@id"]}/assign`, {
        method: 'PUT',
        headers: new Headers({'Content-Type': 'application/ld+json'}),
        body: JSON.stringify(data)
    })
    .then(() => {
        dispatch(loading(false))
        dispatch(success(true))
    })
    .catch((e) => {
        dispatch(loading(false));
        dispatch(error(e.message))
    });
};

// TODO delete this action, it's with the UnavailableUntilDayDialog component
// it's useless now
export const unavailable = (item, unavailableBefore) => dispatch => {
    dispatch(loading(true));

    return fetch(`${item["@id"]}/unavailable`, {
        method: 'PUT',
        headers: new Headers({'Content-Type': 'application/ld+json'}),
        body: JSON.stringify({unavailableBefore: unavailableBefore})
    })
    .then(() => {
        dispatch(loading(false))
        dispatch(success(true))
    })
    .catch((e) => {
        dispatch(loading(false));
        dispatch(error(e.message))
    });
};

export const confirmReturningRunnerToRetailer = (item) => dispatch => {
    dispatch(loading(true))

    return fetch(`${item["@id"]}/returning-retailer`, {
        method: "PUT",
        headers: new Headers({"Content-Type": "application/ld+json"}),
        body: JSON.stringify({}),
    })
        .then(() => {
            dispatch(loading(false))
            dispatch(success(true))
        })
        .catch((e) => {
            dispatch(loading(false))
            dispatch(error(e.message))
        })
}

export const confirmReturn = (item) => dispatch => {
    dispatch(loading(true))

    return fetch(`${item["@id"]}/confirm-return`, {
        method: "PUT",
        headers: new Headers({"Content-Type": "application/ld+json"}),
        body: JSON.stringify({}),
    })
        .then(() => {
            dispatch(loading(false))
            dispatch(success(true))
        })
        .catch((e) => {
            dispatch(loading(false))
            dispatch(error(e.message))
        })
}

export const cancellationReason = (lineItem, reason, unavailableDays = 0) => dispatch => {
    dispatch(loading(true))

    const values = {
        reason: reason,
        unavailableDays : unavailableDays
    }

    return fetch(`${lineItem['@id']}/cancellation-reason`, {
        method: "PUT",
        body: JSON.stringify(values),
        headers: new Headers({'Content-Type': 'application/ld+json'}),
    }).then(() => {
        dispatch(loading(false))
        dispatch(success(true))
    })
    .catch((e) => {
        dispatch(loading(false))
        dispatch(error(e.message))
    });
}

export function resetUpdate() {
    return dispatch => {
        dispatch(loading(false));
        dispatch(error(false));
        dispatch(success(null));
    };
}
