import React from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { Field, reduxForm } from "redux-form";

// core components
import AllTranslationsRequiredMessage from "../../configuration/components/AllTranslationsRequiredMessage";
import FormFields from './formFields';
import GridItem from "components/grid/gridItem";
import ActionsContainer from "components/form/ActionsContainer";
import FormContainer from "views/configuration/components/FormContainer";
import CancelButton from "views/configuration/components/CancelButton";
import SubmitButton from "views/configuration/components/SubmitButton";

// action
import { update } from "actions/gender/update";

// styles
import FormTranslator from "../../../components/translator/formTranslator";
import {getTranslation} from "../../../domain/helpers/translations";

function EditForm(props) {
    const { gender, closeDialog, intl, update, updateLoading, submitting, values } = props;

    const isFormValid = !! (values && getTranslation(values).value);

    function submit(e) {
        e.preventDefault();

        if (gender) {
            if (values.description === null) {
                values.description = ''
            }

            if (gender.parent === null) {
                delete values.parent
            } else {
                values.parent = gender.parent && gender.parent["@id"];
            }

            update(gender, values);
        }
    }
    const renderTranslationForm = (index, code) => {
        return (
            <div>
                <GridItem xs={12} sm={12}>
                    <Field
                        component={FormFields}
                        name={`translations.${code}.value`}
                        label={intl.formatMessage({id:"gender.form.label.name"})}
                        type={"text"}
                        placeholder={intl.formatMessage({id: "gender.form.placeholder.name"})}
                        required
                    />
                </GridItem>
            </div>
        );
    };

    return (
        <form onSubmit={(e) => submit(e)}>
            <FormContainer>
                <AllTranslationsRequiredMessage />
                <GridItem xs={12} sm={12}>
                    <FormTranslator formGenerator={renderTranslationForm} />
                </GridItem>
            </FormContainer>
            <ActionsContainer>
                <CancelButton onClick={closeDialog} />
                <SubmitButton
                    isEditing={true}
                    disabled={!isFormValid || submitting}
                    loading={updateLoading}
                />
            </ActionsContainer>
        </form>
    )
}

const mapStateToProps = state => {
    return {
        updateLoading: state.gender.update.loading,
        values: state.form && state.form.editGender && state.form.editGender.values,
    }
};

const mapDispatchToProps = dispatch => ({
    update: (item, values) => dispatch(update(item, values)),
});

export default reduxForm({
    form: "editGender",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
})(connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(EditForm)));
