import React, {useState} from "react";

// @mui/icons-material
import {ChevronLeft, ChevronRight, FirstPage, LastPage} from "@mui/icons-material";

// core components
import Pagination from "./pagination";
import PropTypes from "prop-types";

function PaginationChangeHandler({...props}) {
    const {retrieved, pageChanged, initialPage} = props;
    const view = retrieved && retrieved["hydra:view"];
    const [currentPage, setCurrentPage] = useState(initialPage ?? 1);

    if (
        !view
        || Object.keys(view).length <= 2
    ) {
        return null;
    }

    let {
        "hydra:previous": previous,
        "hydra:next": next,
        "hydra:last": last,
    } = view;

    const extractPageFromLink = (link) => {
        const matches = link.match(/page=([0-9]+)/);
        if (matches.length < 2) {
            return 1;
        }

        return +matches[1];
    }

    let lastPage = extractPageFromLink(last);

    return (
        <Pagination
            pageChanged={(newPage) => {
                setCurrentPage(newPage);
                pageChanged(newPage);
            }}
            pages={[
                {
                    text: <FirstPage/>,
                    page: 1,
                    disabled: !previous,
                },
                {
                    text: <ChevronLeft/>,
                    page: currentPage - 1,
                    disabled: !previous,
                },
                {
                    active: true,
                    text: currentPage
                },
                {
                    text: <ChevronRight/>,
                    page: currentPage + 1,
                    disabled: !next,
                },
                {
                    text: <LastPage/>,
                    page: lastPage,
                    disabled: !next
                }
            ]}
            color="info"
        />
    );
}

PaginationChangeHandler.defaultProps = {
    currentPage: 1,
};

PaginationChangeHandler.propTypes = {
    currentPage: PropTypes.number,
    pageChanged: PropTypes.func.isRequired,
};

export default PaginationChangeHandler;
