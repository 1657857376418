import React, {useContext} from "react";
import {TableBody} from "@mui/material";

import {QuoteContext} from "../../../../../contexts/quoteContext";
import QuoteDetailGridContentLine from "./gridLine";

const QuoteDetailGridContent = ({ setLineAskingForDeletion, classes }) => {
    const { quote } = useContext(QuoteContext);

    return (
        <TableBody>
            {quote.lines.map((line, key) => {
                return (
                    <QuoteDetailGridContentLine
                        line={line}
                        key={key}
                        setLineAskingForDeletion={setLineAskingForDeletion}
                    />
                );
            })}
        </TableBody>
    );
};

export default QuoteDetailGridContent;
