import {fetch} from '../../utils/dataAccess';

export default async function updateCustomerShipping(orderId, values) {
    const body = {
        customerShippingAddress: values.address,
        customerShippingPostalCode: values.postalCode,
        customerShippingCity: values.city,
        customerShippingPhone: values.phone,
    };

    const response = await fetch(
        `/orders/${orderId}`,
        {
            method: 'PATCH',
            body: JSON.stringify(body),
            headers: new Headers({'Content-Type': 'application/merge-patch+json'}),
        },
    );
    return await response.json();
}
