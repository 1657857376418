import React from 'react'
import PropTypes from "prop-types";
import ImageZoom from "react-medium-image-zoom";

import {makeStyles} from "@mui/styles";

import ImageSharp from "@mui/icons-material/ImageSharp";

import {getFirstProductImage} from "utils/getFirstProductImage";

const useStyles = makeStyles(theme => ({
    productImage: {
        verticalAlign: "middle",
        width: "80px",
        height: "80px",
    },
}));

export default function ProductImage({pictures}) {
    const classes = useStyles();

    if (pictures.length > 0 ) {
        return (
            <ImageZoom>
                <img
                    alt="variation_image"
                    src={getFirstProductImage(pictures)}
                    className={classes.productImage}
                />
            </ImageZoom>
        )
    }

    return (
        <ImageSharp color={"disabled"} className={classes.productImage}/>
    )
}

ProductImage.defaultProps = {
    pictures: []
};

ProductImage.propTypes = {
    pictures: PropTypes.array
};
