import {combineReducers} from "redux";

export function error(state = null, action) {
    switch (action.type) {
        case "PICKING_DASHBOARD_DATA_ERROR":
            return action.error;

        case "PICKING_DASHBOARD_DATA_RESET":
            return null;

        default:
            return state;
    }
}

export function loading(state = false, action) {
    switch (action.type) {
        case "PICKING_DASHBOARD_DATA_LOADING":
            return action.loading;

        case "PICKING_DASHBOARD_DATA_RESET":
            return false;

        default:
            return state;
    }
}

export function retrieved(state = null, action) {
    switch (action.type) {
        case "PICKING_DASHBOARD_DATA_SUCCESS":
            return action.retrieved;

        case "PICKING_DASHBOARD_DATA_RESET":
            return null;

        default:
            return state;
    }
}

export default combineReducers({error, loading, retrieved});
