import React from 'react'
import PropTypes from 'prop-types'

import {Radio} from '@mui/material'

import {infoColor, radioCircleGrey} from 'assets/jss/main'

const RadioButtonIcon = ({ checked }) => (
    <svg
        width='20px'
        height='20px'
        viewBox='0 0 24 24'
        fontSize='20px'
    >
        <circle
            cx='50%'
            cy='50%'
            r='11px'
            stroke={radioCircleGrey}
            fill='none'
        />
        {checked && (
            <circle
                cx='50%'
                cy='50%'
                r='6px'
                fill={infoColor}
            />
        )}
    </svg>
)

export default function RadioButton({checked}) {
    return (
        <Radio
            icon={<RadioButtonIcon />}
            checkedIcon={<RadioButtonIcon checked />}
            checked={checked}
            sx={{ padding: 'unset'}}
        />
    )
}

RadioButton.propTypes = {
    checked: PropTypes.bool,
}
