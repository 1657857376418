import {
    infoColor,
    dangerColor,
    warningColor,
    lightGrayColor,
    grayColor,
    lightBlueColor,
    successColor,
    primaryColor,
    defaultFont,
    container,
} from "../../../main";

const defaultProductListFont = {
    fontWeight: "400",
    fontSize: "16px"
};

const defaultProductListXsFont = {
    fontWeight: "300",
    fontSize: "12px"
};

const defaultBorder = {
    borderWidth: "1px 0px 0px 0px",
    borderStyle: "solid",
    borderColor: lightGrayColor,
};

const defaultStock = {
    ...defaultProductListFont,
    color: "#fff",
    margin: "5px 0px 5px 0px",
    width: "120px",
    padding: "10px",
};

const listStyle = theme => ({
    containerWithSidebar: {
        [theme.breakpoints.up("md")]: {
            marginLeft: "240px",
        }
    },
    container: {
        ...container,
        ...defaultFont
    },
    mainActionsContainer: {
        textAlign: "right",
        "& div": {
            width: "100%"
        }
    },
    card: {
        minHeight: window.innerHeight * 0.94
    },
    titleContainer: {
        display:"flex",
        alignItems: "center"

    },
    tableWrapper: {
        overflowX: "auto",
    },
    searchInput: {
        ...defaultProductListFont,
        width: "35vh",
        marginTop: "20px",
        marginBottom: "10px",
        "&:focus": {
            borderColor: infoColor,
        },
    },
    filterMenu:{
        ...defaultBorder,
        borderWidth: "0px 0px 1px 0px",
    },
    tabsIndicator: {
        backgroundColor: infoColor,
    },
    tableRow:{
        "&:hover": {
            cursor: "pointer",
            backgroundColor: "whitesmoke !important",
        }
    },
    tabRoot: {
        textTransform: "initial",
        minWidth: 72,
        fontWeight: theme.typography.fontWeightRegular,
        marginRight: theme.spacing(4),
        "&:hover": {
            color: infoColor,
            opacity: 1,
        },
        "&$tabSelected": {
            color: infoColor,
            fontWeight: theme.typography.fontWeightMedium,
        },
        "&:focus": {
            color: infoColor,
        },
    },
    tabSelected: {
        color: infoColor
    },
    tableTitle: {
        marginLeft: "-10px",
        fontSize:"18px"
    },
    filterBarContainer:{
        border: "1px solid" + lightGrayColor,
        borderRadius: "5px",
        padding: "5px",
        "& > span": {
            ...defaultProductListFont,
            color: primaryColor
        }
    },
    filterButton:{
        color: infoColor,
        float:"right",
        padding: "0px",
    },
    gridItemNoPadding: {
        padding: "0px"
    },
    priceFilter:{
        ...defaultProductListFont,
        display: "flex",
        flexDirection: "column",
        margin: "10px 0 10px 10px",
    },
    priceFilterTo:{
        margin: "0px 10px 0px 10px"
    },
    priceFilterSearch: {
        marginLeft: "10px"
    },
    statusStockFilter:{
        display: "flex",
        margin: "10px 0 10px 10px",
        borderStyle: "inherit !important"
    },
    filterInColumn : {
        display: "flex",
        flexDirection: "column",
        color: "#000",
    },
    filterLoading : {
        width: "100%",
        textAlign: "center",
        margin: "10px 0px 0px 0px",
    },
    filterBlueTitle: {
        ...defaultProductListFont,
        color: infoColor,
        fontWeight: 500,
    },
    filters: {
        display: "flex",
        overflowY:"auto",
        "&::-webkit-scrollbar": {
            WebkitAppearance: "none",
        },
        "&::-webkit-scrollbar:vertical" :{
            width: "11px",
        },
        "&::-webkit-scrollbar:horizontal": {
            height: "11px",
        },
        "&::-webkit-scrollbar-thumb": {
            borderRadius: "8px",
            border: "2px solid white", /* should match background, can"t be transparent */
            backgroundColor: "rgba(0, 0, 0, .5)",
        }
    },
    filterItem: {
        ...defaultProductListFont,
        margin: "10px 10px 10px 10px ",
        flex:" 0 0 7%",
    },
    filterSelectedContainer: {
        ...defaultBorder,
        display: "flex",
        flexWrap: "wrap",
    },
    filterSelectedItem: {
        ...defaultProductListFont,
        margin: "10px 0px 10px 10px ",
        backgroundColor: infoColor,
        color: "#FFF",
    },
    resetBar:{
        ...defaultBorder,
    },
    resetSelected: {
        width:"130px",
        verticalAlign:"center",
        align: "center",
        margin:"10px",
        padding:"7px",
    },
    actionsContainer: {
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        flexWrap: "wrap",
    },
    exportButton: {
        marginLeft: '5px',
    },
    sync: {
        marginLeft: "10px",
        display: "flex",

        "& svg":{
            marginRight: "10px"
        }
    },
    syncing: {
        animation: "slide infinite 2s linear"
    },
    "@keyframes slide": {
        "from": {transform: "rotate(360deg)"},
        "to": {transform: "rotate(0deg)"},
    },
    action: {
        padding: "15px",
    },
    actionSelect:{
        ...defaultProductListFont,
        minWidth: "102px"
    },
    myOffers: {
        border: 'none !important',
        marginRight: '10px',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
    },
    disableElementsContainer: {
        display: 'inline-flex',
    },
    checkbox: {
        color: lightGrayColor,
        "&$checked": {
            color: infoColor,
        },
    },
    checked: {},
    image: {
        verticalAlign: "middle",
        maxWidth: "60px",
        maxHeight: "60px"
    },
    imagePlaceholder: {
        verticalAlign: "middle",
        fontSize: "60px"
    },
    brandCell: {
        ...defaultProductListFont,
    },
    imageCell: {
        textAlign: "center",
    },
    name: {
        ...defaultProductListFont,
        display: "-webkit-box",
        "-webkit-line-clamp": 3,
        "-webkit-box-orient": "vertical",
        overflow: "hidden"
    },
    nameInfos:{
        ...defaultProductListXsFont,
    },
    category: {
        ...defaultProductListFont,
        backgroundColor: primaryColor,
        color: "#FFFFFF",
        margin: "5px 5px 5px 0px"
    },
    categoryDraft: {
        ...defaultProductListFont,
        backgroundColor: lightBlueColor,
        color: "#FFFFFF",
        margin: "5px 5px 5px 0px"
    },
    price : {
        ...defaultProductListFont
    },
    crossedPrice: {
        ...defaultProductListXsFont,
        textDecoration: "line-through",
    },
    stockStatus :{
        display: "flex",
        justifyContent: "flex-start",
        flexDirection: "column",
    },
    status: {
        ...defaultProductListFont,
        border: "1px solid black",
        margin: "5px 0px 5px 0px",
        maxWidth: "120px"
    },
    statusOff:{
        ...defaultProductListFont,
        margin: "5px 0px 5px 0px",
        padding: "10px",
        maxWidth: "120px"
    },
    inStock:{
       ...defaultStock,
        backgroundColor: successColor,
        borderColor: successColor,
    },
    middleStock:{
        ...defaultStock,
        backgroundColor: warningColor,
        borderColor: warningColor,
    },
    noStock: {
        ...defaultStock,
        backgroundColor: dangerColor,
        borderColor: dangerColor,
    },
    soldOnline:{
        marginLeft:"5px"
    },
    customSelectActions: {
        ...defaultProductListFont,
        borderRadius: "10px",
        height: "45px",
        width: "102px",
        textAlign: "center",
        color: primaryColor
    },
    showParentIcon: {
        cursor: "pointer",
        fontSize: "28px",
        paddingLeft: "5px",
        "&:hover": {
            color: primaryColor,
        },
        verticalAlign: "middle"
    },
    editIcon:{
        marginRight: "12px",
        color: infoColor,
        cursor: "pointer"
    },
    footer: {
        display: "flex",
        alignItems: "center",
        backgroundColor: "transparent",
        marginBottom: "20px"
    },
    pagination : {
        marginTop:"20px",
        marginRight: "auto !important",
        marginLeft: "auto !important"
    },
    tableCellNoResult: {
        ...defaultProductListFont,
        textAlign: "center",
        fontWeight: "bold",
        verticalAlign: "middle",
        marginTop: "20px"
    },
    submitLoading: {
        marginLeft: "5px",
        color: "#FFFFFF"
    },
    loadingBar: {
        position: "fixed"
    },
    totalContainer: {
        ...defaultProductListFont,
        color: grayColor,
        textAlign: "right",
        padding: "15px",
        display: "flex",
        justifyContent: "flex-end",
    },
    totalSpan: {
        display: "flex",
        alignItems: "center",
        marginRight: "10px"
    },
    beatLoader: {
        position: "absolute",
        right: "30px",
        margin: "0 auto",
        top: "77px",
        display: "flex",
        justifyContent: "center"
    },
    propagateLoader: {
        position: "absolute",
        left: "0",
        right: "0",
        margin: "0 auto",
        top: "58%",
        display: "flex",
        justifyContent: "center"
    },
    submitLoadingSwitch:{
        color: "#DDD"
    },
    dialogSearch:{
        width: "100%",
        padding: "10px",
    },
    categorieListItem: {
        padding: 0,
        cursor: "pointer"
    },
    categoryNameListItem:{
        padding: 0,
        "&:hover": {
            "& span": {
                color: infoColor
            }
        }
    },
    categoryNameListItemSelected: {
        "& span": {
            color: infoColor
        }
    },
    childrenCategories: {
        marginLeft: 30,
    },
    dialogTitle: {
        textAlign: "center"
    },
    dialogContent: {
        padding: "0 !important",
    },
    dialogContentLoader: {
        width: "100%",
        textAlign: "center",
    },
    notUsedProduct: {
        marginLeft: "10px",
        display: "flex",
        borderColor: warningColor
    },
    inviteSelectField: {
        width: "100%",
        height: "43px",
        border: `1px solid ${lightGrayColor}`,
        borderRadius: "4px",
        marginBottom: "15px",
        padding: "10px"
    },
    dialogContentChild: {
        padding: "0 24px 24px"
    },
    categoriesFilterContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        overflowX: "scroll"
    },
    categoriesFilterColumn: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        borderWidth: "0px 1px 0px 0px",
        borderStyle:"solid",
        borderColor: lightGrayColor,
        overflowY: "scroll"
    },
    selectTotalContainer: {
        background: "rgba(241,243,244,0.871)",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        padding: "6px 16px"
    },
    selectTotalButton:{
        textTransform: "none",
        color: infoColor,
    },

});

export default listStyle;
