import React, {useState, useRef, useContext, useEffect} from "react";
import TableCell from "@mui/material/TableCell";
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import EditIcon from '@mui/icons-material/Edit';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';
import Paper from '@mui/material/Paper';
import {FormattedMessage} from "react-intl";
import {fetch} from "utils/dataAccess";
import {MerchandisingContext} from "contexts/merchandisingContext";
import {useSnackbar} from "notistack";

export default function MerchandisingRankCell({product}) {
    const navParam = useContext(MerchandisingContext);
    const boxRef = useRef();
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);
    const [currentRank, setCurrentRank] = useState(0);
    const [value, setValue] = useState(0);
    const { enqueueSnackbar } = useSnackbar();

    const getProductRank = () => {
        const rankings = product.rankings;
        const navId = navParam.navigationItem ? navParam.navigationItem.id : 'global';

        for (let i = 0; i < rankings.length; i++) {
            const ranking = rankings[i];

            if (ranking.navigationId === 'global' && navId === 'global' || ranking.navigationId.split('_')[1] === navId) {
                return ranking.rank;
            }
        }

        return 0;
    }

    useEffect(() => {
        setCurrentRank(getProductRank());
        setValue(getProductRank());
    }, [product, navParam]);

    const handleClose = () => {
        setAnchorEl(null);
        setOpen(false);
    };

    const handleOpen = () => {
        setAnchorEl(boxRef.current);
        setOpen(true);
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter' && value !== '' && value !== currentRank) {
            setCurrentRank(value)
            syncRank(value);
        }
    }

    const handleOnBlur = () => {
        if (value === '' || value === currentRank) {
            return;
        }

        setCurrentRank(value)
        syncRank(value);
    }

    function resetRank() {
        if (currentRank === 0) {
            return;
        }

        setValue(0);
        setCurrentRank(0);
        syncRank(0);
    }

    function syncRank(newRank) {

        let headers = new Headers();
        headers.set('Accept', 'application/json');

        let body = {
            productId: product.id,
            rank: newRank,
            navigationType: 'global',
        };

        if (navParam.navigationType) {
            body.navigationType = navParam.navigationType;
            body.navigationValue = navParam.navigationValue;
        }

        fetch("/merchandising/rank", {method: "POST", body: JSON.stringify(body), headers}).then(
            response => {
                if (response.ok) {
                    enqueueSnackbar(
                        <FormattedMessage
                            id={"merchandising.ranking.card.success"}
                        />,
                        {
                            variant: 'success'
                        }
                    );
                } else {
                    enqueueSnackbar(
                        <FormattedMessage
                            id={"merchandising.ranking.card.error"}
                        />,
                        {
                            variant: 'error'
                        }
                    );
                }
            }
        );
    }

    return (
        <TableCell>
            <Box
                ref={boxRef}
                onMouseEnter={handleOpen}
                onMouseLeave={handleClose}
            >
                <TextField
                    type="number"
                    error={/^-?\d+$/.test(value) === false}
                    onKeyPress={handleKeyPress}
                    onBlur={handleOnBlur}
                    value={value}
                    onChange={(event) => {
                        setValue(event.target.value);
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end" sx={{marginLeft: 0}}>
                                <EditIcon sx={{fontSize:'15px'}}/>
                            </InputAdornment>
                        ),
                    }}
                    variant="outlined"
                    size="small"
                    color="primary"
                    sx={{
                        width: '80px',
                        '&:focus-within':{
                            width: '90px',
                        },
                        '& .MuiInputBase-input': {
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                        },
                        transition: 'width 0.5s',
                        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                            display: "none",
                        },
                        "& input[type=number]": {
                            MozAppearance: "textfield",
                        },
                    }}
                />
                <Popper
                    anchorEl={anchorEl}
                    open={open}
                    placement="right-end"
                    disablePortal={true}
                    sx={{
                        paddingLeft: '5px',
                    }}
                >
                    <Paper>
                        <Button 
                            variant="outlined"
                            sx={{height:'40px'}}
                            onClick={resetRank}
                        >
                            <FormattedMessage id={"merchandising.show.label.actions.reset_rank"}/>
                        </Button>
                    </Paper>
                </Popper>
            </Box>
        </TableCell>
    );
}
