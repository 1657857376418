import React from "react";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";

import {makeStyles} from "@mui/styles";

import {primaryColor} from "assets/jss/main";

const useStyles = makeStyles(theme => ({
    cardStatsLegendDotBlue: {
        width: "11px",
        height: "11px",
        borderRadius: "50%",
        backgroundColor: primaryColor
    },
}));

export default function LegendDotBue(props) {
    const classes = useStyles();

    return (
        <>
            <div className={classes.cardStatsLegendDotBlue} />
            <div>
                <FormattedMessage id={props.title} />
            </div>
        </>
    )
}

LegendDotBue.defaultProps = {
    title: ""
};

LegendDotBue.propTypes = {
    title: PropTypes.string.isRequired
};
