import React, {Component} from "react";
import {stockEasyOffer} from "../../domain/enum/offers";

export default function (ComposedComponent) {
    class MarketPlaceOfferInjector extends Component {
        render(props) {
            return <ComposedComponent {...this.props} offer={stockEasyOffer}/>;
        }
    }

    return MarketPlaceOfferInjector;
}
