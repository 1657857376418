import { fetch } from "../../utils/dataAccess";

export function error(error) {
    return {type: "SECTOR_LIST_ERROR", error};
}

export function loading(loading) {
    return {type: "SECTOR_LIST_LOADING", loading};
}

export function success(retrieved) {
    return {type: "SECTOR_LIST_SUCCESS", retrieved};
}

export function sectorList(params = "pagination=false") {
    return dispatch => {
        dispatch(loading(true));

        fetch(`/sectors?${params}`).then(response => {
            response.json().then(retrieved => {
                dispatch(loading(false));
                dispatch(success(retrieved["hydra:member"]));
            })
        }).catch(error => {
            // canceled
        });
    };
}


export function reset() {
    return dispatch => {
        dispatch({type: "SECTOR_LIST_RESET"});
    };
}
