import React, {useContext} from "react";
import {FormattedMessage} from "react-intl";
import {Formik} from "formik";

import RecommendationForm from "./RecommendationForm";

import GridContainer from "components/grid/gridContainer";
import GridItem from "components/grid/gridItem";

import SaveButton from "./SaveButton";
import CancelButton from "./CancelButton";

import {makeStyles} from "@mui/styles";

import {
    CategoryFilter,
    ColorFilter,
    RetailerFilter,
    Sort,
    Type
} from "utils/recommendation";

import {RecommendationContext} from "../context/RecommendationContext";

import create from "api/recommendation/create";
import update from "../../../api/recommendation/update";

const useStyle = makeStyles({
    overloadRecommendationsForm: {
        borderRadius: '8px',
        marginLeft: 'unset',
        marginRight: 'unset',
        paddingBottom: '24px',
    },
    form: {
        marginBottom: '24px',
    },
    actionContainer: {
        width: '100%',
        marginLeft: 'unset'
    },
    actionItem: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row-reverse',
        gap: '12px'
    },
})
export default function RecommendationOverloadForm() {

    const {
        apiLoading,
        closeRecommendationOverloadModal,
        refreshRecommendationList,
        selectedRecommendation
    } = useContext(RecommendationContext);

    const classes = useStyle();

    const recommendationOverloadInitialValues = {
        retailerFilter: selectedRecommendation?.retailerFilter ?? RetailerFilter.SAME,
        categoryFilter: selectedRecommendation?.categoryFilter ?? CategoryFilter.SAME,
        colorFilter: selectedRecommendation?.colorFilter ?? ColorFilter.SAME,
        sort: selectedRecommendation?.sort ?? Sort.BEST_SELLER,
        recommendedProduct: selectedRecommendation?.category || selectedRecommendation?.corner || null,
        overrodeCategory: selectedRecommendation?.overrodeCategory ?? null
    }

    const initialValues = {
        product: recommendationOverloadInitialValues
    }

    const validate = (values) => {
        const errors = {};

        if (!values.recommendation.product.overrodeCategory) {
            errors.overrodeCategory = true;
        }

        return errors;
    };

    function renameErrorsFormKey(errors) {
        const newErrors = {
            recommendation: {
                product: {}
            }
        }

        Object.keys(errors).forEach((oldKey) => {
            newErrors[`recommendation.product.${oldKey}`] = errors[oldKey];
        })

        return newErrors;
    }

    function closeAndRefresh() {
        closeRecommendationOverloadModal();
        refreshRecommendationList();
    }

    return (
        <Formik
            initialValues={{recommendation : initialValues}}
            enableReinitialize={true}
            validateOnChange={true}
            validateOnBlur={false}
            validateOnMount={true}
            validate={validate}
            onSubmit={async (values, {setSubmitting, setErrors}) => {
                setSubmitting(true);

                values = values.recommendation.product;
                values.type = Type.PRODUCT

                if (values.recommendedProduct?.['@type'] === 'Category') {
                    values.categoryFilter = CategoryFilter.ALL;
                }

                const cloneValues = { ...values };

                if (selectedRecommendation) {
                    await update(selectedRecommendation, cloneValues).then(() => {
                        closeAndRefresh()
                    }).catch((e) => {
                        setErrors(renameErrorsFormKey(e.errors));
                    });
                } else {
                    await create(cloneValues).then(() => {
                        closeAndRefresh()
                    }).catch((e) => {
                        setErrors(renameErrorsFormKey(e.errors));
                    })
                }

                setSubmitting(false)
            }}
        >
            {({
                  isSubmitting,
                  submitForm,
                  isValid
            }) => (
                <>
                    <GridContainer className={classes.overloadRecommendationsForm}>
                        <GridItem xs={12} className={classes.form}>
                            <RecommendationForm
                                enableCategorySelector
                                titleTranslationKey={'recommendations.form.overload.title'}
                            />
                        </GridItem>
                        <GridContainer className={classes.actionContainer}>
                            <GridItem className={classes.actionItem}>
                                <SaveButton
                                    disabled={apiLoading || isSubmitting || !isValid}
                                    onClick={submitForm}
                                >
                                    <FormattedMessage id={"recommendations.form.overload.action.save"} />
                                    {isSubmitting && <></>}
                                </SaveButton>
                                <CancelButton
                                    disabled={isSubmitting}
                                    onClick={() => closeRecommendationOverloadModal()}
                                >
                                    <FormattedMessage id={"recommendations.form.overload.action.cancel"} />
                                </CancelButton>
                            </GridItem>
                        </GridContainer>
                    </GridContainer>
                </>
            )}
        </Formik>
    )
}
