import React from "react";
import {Route} from "react-router-dom";
import Dashboard from "../../views/marketplace/dashboard";
import DashboardQuotes from "views/marketplace/dashboardQuote";
import DashboardOpe from "../../views/statistics/dashboardOpe";
import RequireAuthentication from "../../hoc/authentication/requireAuthentication";
import RetailerUnloader from "../../hoc/retailer/retailerUnloader";
import {Authorizations, hasAuthorization} from "../../utils/authorizations";
import * as Cookies from "js-cookie";

let routes = [];
const authorizations = Cookies.get("_b") ? JSON.parse(Cookies.get("_b").toString()) : [];

if (hasAuthorization(authorizations, Authorizations.STATISTICS_ACCESS)) {
    routes.push([
        <Route
            path="/statistics"
            component={RequireAuthentication(RetailerUnloader(Dashboard))}
            exact
            key="dashboard"
        />,
    ])
}

routes.push([
    <Route
        path="/statistics/ope"
        component={RequireAuthentication(RetailerUnloader(DashboardOpe))}
        exact
        key="dashboard"
    />,
])

if (hasAuthorization(authorizations, Authorizations.PICKING_STATISTICS_QUOTES)) {
    routes.push([
        <Route
            path="/picking/statistics/quotes"
            component={RequireAuthentication(RetailerUnloader(DashboardQuotes))}
            exact
            key="dashboard"
        />,
    ])
}

export default routes;
