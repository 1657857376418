import React from 'react';
import {styled} from "@mui/styles";

export default function IconContainer (props) {
    const StyledIconContainer = styled('div')(({ theme }) => ({
        padding: '8px',
        borderTopLeftRadius: '6px',
        lineHeight: '0',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: theme.palette.primary.main
    }));

    return (
        <StyledIconContainer>
            {props.children}
        </StyledIconContainer>
    )
}
