import React from "react";
import {PropagateLoader} from "react-spinners";

// @mui/material components
import {makeStyles} from "@mui/styles";

// styles
import { primaryColor } from "../../../assets/jss/main";

const useStyles = makeStyles(theme => ({
    propagateLoader: {
        position: "absolute",
        left: "0",
        right: "0",
        margin: "0 auto",
        top: "30%",
        display: "flex",
        justifyContent: "center"
    },
}));

export default function ListLoader (props) {
    const classes = useStyles();

    return (
        <div className={classes.propagateLoader}>
            <PropagateLoader
                sizeUnit={"px"}
                size={16}
                color={primaryColor}
                loading={props.loading}
            />
        </div>
    );
}
