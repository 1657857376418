import {fetch} from "utils/dataAccess";
import { setOrganization } from "utils/dataAccessOrganizationHandler";
import payloadFactory from "./helper/payloadFactory";

export default async function create(values)  {
    setOrganization(values);

    return new Promise((resolve, reject) => {
        fetch('recommendations', {
            method: "POST",
            body: JSON.stringify(payloadFactory(values)),
        }).then(response => {
            response.json().then(response => {
                resolve(response);
            });
        }).catch(error => {
            reject(error)
        });
    })
}
