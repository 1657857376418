export const QUOTE_ATTRIBUTION = 'QuoteAttribution';
export const QUOTE_CREATION = 'QuoteCreation';
export const QUOTE_DISCOUNT_CHANGE = 'QuoteDiscountChange';
export const QUOTE_PRICE_CHANGE = 'QuotePriceChange';
export const QUOTE_PRODUCT_ADD = 'QuoteProductAdd';
export const QUOTE_PRODUCT_DELETION = 'QuoteProductDeletion';
export const QUOTE_QUANTITY_CHANGE = 'QuoteQuantityChange';
export const QUOTE_STATE_CHANGE = 'QuoteStateChange';

export const ALL_QUOTES_WITHOUT_QUOTE_CREATION = [
    QUOTE_ATTRIBUTION,
    QUOTE_DISCOUNT_CHANGE,
    QUOTE_PRICE_CHANGE,
    QUOTE_PRODUCT_ADD,
    QUOTE_PRODUCT_DELETION,
    QUOTE_QUANTITY_CHANGE,
    QUOTE_STATE_CHANGE,
];
