import {fetch} from "../../../utils/dataAccess";

export default function deleteLine(lineId) {
    return new Promise((resolve, reject) => {
        fetch(`/quote_line_items/${lineId}`, {
            method: 'DELETE',
        })
            .then(response => {
                resolve(true);
            })
            .catch(error => reject(error));
    });
}
