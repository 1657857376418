import {combineReducers} from "redux"

export function loading(state = false, action) {
    switch (action.type) {
        case "AUTHORIZATIONS_LIST_LOADING":
            return action.loading;

        default:
            return state;
    }
}

export function error(state = null, action) {
    switch (action.type) {
        case "AUTHORIZATIONS_LIST_ERROR":
            return action.error;

        default:
            return state;
    }
}

export function retrieved(state = null, action) {
    switch (action.type) {
        case "AUTHORIZATIONS_LIST_SUCCESS":
            return action.retrieved;

        case "AUTHORIZATIONS_LIST_RESET" :
            return null;

        default:
            return state;
    }
}

export default combineReducers({retrieved, loading, error});
