import { fetch } from "../../utils/dataAccess";

export function list(params = "") {
    return new Promise((resolve, reject) => {
        fetch(`/shipping_methods?${params}&pagination=false`)
            .then(response => {
                response.json().then(retrieved => {
                    resolve(retrieved['hydra:member'])
                })
            })
            .catch(error => reject(error));
    });
}
