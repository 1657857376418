import { fetch } from "utils/dataAccess";
import {getCurrentOrganizationId} from "utils/dataAccessOrganizationHandler";

export default function (params = "") {
    return new Promise((resolve, reject) => {
        params += '&organization.id=' + getCurrentOrganizationId()

        fetch(`/payment_accounts?${params}`)
            .then(response => {
                response.json().then(response => {
                    resolve(response["hydra:member"])
                })
            })
            .catch(error => reject(error));
    });
}
