import React from 'react'
import classNames from "classnames";

import makeStyles from "@mui/styles/makeStyles";

const useStyle = makeStyles({
    tableCellColumn: {
        display: "flex",
        flexDirection: "column",
    }
})
export default function TableCellColumn({className, children}) {
    const classes = useStyle();

    return (
        <div className={classNames(className, classes.tableCellColumn)}>
            {children}
        </div>
    )
}
