import React, { useState } from "react";

// @mui/material components
import {makeStyles} from "@mui/styles";

// core components
import Children from "./children";
import CreateDialog from './createDialog';
import DeleteDialog from './deleteDialog';
import EditDialog from './editDialog';
import MoveDialog from "./moveDialog";
import NoResult from '../../configuration/components/noResult';
import { default as Popover } from '../../configuration/components/popover';

// action
import { colorExist } from "actions/color/list";

// helpers
import {sortOn} from "utils/sortOn";
import {getTranslation} from "../../../domain/helpers/translations";

// styles
const useStyles = makeStyles(theme => ({
    removeUlPadding: {
        padding: 0,
        margin: 0,
        listStyleType: "none"
    },
}));

export default function EnhancedTableBody (props) {
    const { checkedColors, retrieved, search, setCheckedColors } = props;

    const classes = useStyles();

    const [createSubDialog, showCreateSubDialog] = useState(false);
    const [deleteDialog, showDeleteDialog] = useState(false);
    const [editDialog, showEditDialog] = useState(false);
    const [moveDialog, showMoveDialog] = useState(false);

    const [colorSelected, setColorSelected] = useState(null);
    const [colorTablePosition, setColorTablePosition] = useState(null);

    const [openPopover, setOpenPopover] = useState(false);

    function getTopParentsBySearch(retrieved) {
        let parents = [];
        let colorRetrieved = retrieved;

        if (search !== '') {
            colorRetrieved = retrieved.filter(color => getTranslation(color).name.toLowerCase().includes(search));
        }

        // if search is not null: extract all top parent where one of child match with search
        colorRetrieved.forEach(color => {
            while (color && color.parent !== null) {
                color = color.parent;

                if (color && color.parent === null && !colorExist(color, parents)) {
                    parents.push(color);
                }
            }

            if (color && color.parent === null && !colorExist(color, parents)) {
                parents.push(color);
            }
        });

        return parents.sort(sortOn("position"));
    }

    return (
        <div className={classes.removeUlPadding}>
            {(retrieved && retrieved.length > 0) && (
                <Children
                    children={getTopParentsBySearch(retrieved)}
                    allColors={retrieved}
                    openCreatedDialog={() => showCreateSubDialog(true)}
                    selectColor={setColorSelected}
                    setColorTablePosition={setColorTablePosition}
                    openPopover={() => setOpenPopover(true)}
                    search={search}
                    checkedColors={checkedColors}
                    setCheckedColors={setCheckedColors}
                    isTopParent={true}
                />
            )}
            {retrieved && retrieved.length === 0 && (
                <NoResult />
            )}
            {colorSelected && (
                <div>
                    <CreateDialog
                        isOpen={createSubDialog}
                        closeDialog={() => showCreateSubDialog(false)}
                        color={colorSelected}
                        isSubColor={true}
                    />
                    <DeleteDialog
                        isOpen={deleteDialog}
                        closeDialog={() => showDeleteDialog(false)}
                        color={colorSelected}
                    />
                    <EditDialog
                        isOpen={editDialog}
                        closeDialog={() => showEditDialog(false)}
                        color={colorSelected}
                        isSubColor={colorSelected.parent !== null}
                    />
                    <MoveDialog
                        isOpen={moveDialog}
                        closeDialog={() => showMoveDialog(false)}
                        colorSelected={colorSelected}
                        allColors={retrieved}
                    />
                </div>
            )}
            <Popover
                isPopoverOpen={openPopover}
                closePopover={() => setOpenPopover(false)}
                position={colorTablePosition}
                openDeleteDialog={() => showDeleteDialog(true)}
                openEditDialog={() => showEditDialog(true)}
                openMoveDialog={() => showMoveDialog(true)}
                attributeName={'color'}
            />
        </div>
    );
}
