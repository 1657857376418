import React, {useState} from "react";

import {withStyles} from "@mui/styles";
import { Chip, ListItem, Fab} from "@mui/material";
import {Add, Close, Done} from "@mui/icons-material";

import {Formik} from "formik";
import moment from "moment";
import {injectIntl} from "react-intl";

import yup from "../../../assets/translations/validation";
import openingDaysStyle from "../../../assets/jss/views/retailer/openingDaysStyle";
import FormikInput from "../../../components/input/formikInput";

const schema = yup.object({
    start: yup.string().required("start time cannot be empty"),
    end: yup
        .string()
        .required("end time cannot be empty")
        .test("is-greater", "end time should be greater", function() {
            const { start, end } = this.parent;
            return moment(end, "HH:mm").isAfter(moment(start, "HH:mm"));
        })
});

function OpeningDay (props) {
    const { classes, translationId, openingHours, addHour, deleteHour, day, intl } = props;

    const [openHourPickerDisplay, displayHourPicker] = useState(false);
    const isClosed = !openingHours || openingHours.length === 0;
    const deleteOpeningPeriod = (index) => {
        const [start, end] = openingHours[index];
        deleteHour(day, start, end);
    }

    const addOpeningHour = (form) => {
        addHour(day, form.start, form.end);
        displayHourPicker(false);
    }

    return (
        <div className={classes.dayRow}>
            <span>{intl.formatMessage({id: translationId})} : {isClosed && intl.formatMessage({id: 'retailer.form.openingDays.close.label'})}</span>
            <Fab color="primary" size={'small'} aria-label="add" onClick={() => displayHourPicker(!openHourPickerDisplay)} className={classes.addPeriod}>
                {!openHourPickerDisplay && <Add fontSize={'small'}/>}
                {openHourPickerDisplay && <Close fontSize={'small'}/>}
            </Fab>


            <Formik
                initialValues={{
                    start: '',
                    end: '',
                }}
                validationSchema={schema}
                validateOnChange
                onSubmit={addOpeningHour}
            >{({
                   submitForm,
               }) => (
                <React.Fragment>
                    {openHourPickerDisplay && (
                        <div className={classes.hourContainer} key={'opening_hour-container'}>
                            <FormikInput
                                inputProps={
                                    {
                                        name: 'start',
                                        type: 'time',
                                        emptyLabel: intl.formatMessage({id: 'retailer.form.openingDays.hour_select.empty_label_start_date'}),
                                        choicesInterval: {
                                            minutes: 15,
                                        },
                                        containerClassName: classes.timeField,
                                    }
                                }
                            />

                            <FormikInput
                                inputProps={
                                    {
                                        name: 'end',
                                        type: 'time',
                                        emptyLabel:  intl.formatMessage({id: 'retailer.form.openingDays.hour_select.empty_label_end_date'}),
                                        choicesInterval: {
                                            minutes: 15,
                                        },
                                        containerClassName: classes.timeField,
                                    }
                                }
                            />

                            <Fab color="primary" aria-label="add" onClick={submitForm} className={classes.savePeriod} size={'small'}>
                                <Done fontSize={'small'}/>
                            </Fab>
                        </div>
                    )}
                </React.Fragment>
            )}
            </Formik>

            {!isClosed && (
                <ListItem key={day} className={classes.openingHours}>
                    {openingHours.map((openingPeriod, index) => {
                        const [openingHour, closingHour] = openingPeriod;
                        const label = `${openingHour} - ${closingHour}`

                        return (
                            <Chip
                                key={index}
                                label={label}
                                color={"primary"}
                                onDelete={() => deleteOpeningPeriod(index)}
                                className={classes.hourBadge}
                            />
                        );
                    })}
                </ListItem>
            )}
        </div>
    );
}

export default withStyles(openingDaysStyle)(injectIntl(OpeningDay));
