import React from 'react';
import {default as MuiCard} from '@mui/material/Card'

import {lightGrayColor} from "assets/jss/main";

function NoteCard(props) {
    return (
        <MuiCard sx={{
            boxShadow: '0px 4px 22px 0px rgba(0, 0, 0, 0.06)',
            border: `1px solid ${lightGrayColor}`,
            borderRadius: '12px',
            fontSize: '14px',
            color: '#000',
            lineHeight: 'normal',
            '.MuiCardContent-root:last-child': {
                paddingBottom: 0
            }
        }}>
            {props.children}
        </MuiCard>
    );
}

export default NoteCard;
