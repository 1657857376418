import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import FilterChip from "views/marketplace/merchandising/filter/filterChip";
import {accessibiltyProps} from "views/marketplace/merchandising/filter/tabFilterPanel";
import {getFilterLabel} from "domain/helpers/filterTranslations";

export default function TabsContainer({items, handleClick, children, value, handleChange}) {

    return (
        <Box sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex' }}>
            <Tabs
                orientation="vertical"
                variant="fullWidth"
                value={value}
                onChange={handleChange}
                aria-label="Vertical nav"
                sx={{ 
                    '& .MuiTabs-indicator': { display: 'none' },
                    borderRight: 1,
                    borderColor: 'divider',
                    margin: 0,
                    padding: 0
                }}
            >
                {
                    items.map((item, index) => {
                        return (
                            <Tab
                                disableRipple={true}
                                onClick={() => handleClick(item)}
                                label={
                                    <FilterChip
                                        key={index}
                                        label={getFilterLabel(item)}
                                        item={item}
                                        sx={{ padding: "10px" }}
                                    />
                                }
                                key={index} 
                                {...accessibiltyProps(index)} 
                            />
                        );
                    })
                }
            </Tabs>
            <>
                {children}
            </>
        </Box>
    );

}
