import {darkGrayColor, primaryColor} from "../../../../../main";

const imageSliderStyle = theme => ({
    image: {
        maxWidth: "150px",
        maxHeight: "150px",
        display: "inline-block"
    },
    slickContainer: {
        lineHeight: "150px",
        display: "flex!important",
        alignItems: "center",
        height: "100%",
    },
    slickRemove : {
        position: "absolute",
        right: "5px",
        top: "5px",
        color: primaryColor,
        cursor: "pointer",
    },
    addImage: {
        float: "right"
    },
    input: {
        display: "none"
    },
    imagePlaceholder: {
        fontSize: "180px",
        color: darkGrayColor
    },
    sliderWrapper: {
        marginBottom: "2rem",
        padding: "10px",
        "& .slick-next:before, & .slick-prev:before":{
            color: primaryColor
        },
        "& .slick-next":{
            right: "0"
        },
        "& .slick-prev":{
            left: "0",
            zIndex: "1"
        },
        "& .slick-slide": {
            padding: "0",
            position: "relative",
            width: "150px",
            height: "150px",
            margin: "3px 10px 3px 10px",
            display: "flex",
            alignItems: "center",
            overflow: "hidden",
            border: "2px solid #e0e0e0",
            justifyContent: "center",
            "& > div:first-child": {
                height: "150px"
            }
        },
        "& .slick-slide:first-child": {
            margin: "3px 3px 3px 10px",
        },
        "& .slick-list": {
            margin: "0 5px"
        },
        "& .slick-track": {
            display: "flex",
            alignItems: "center"
        }
    },
});

export default imageSliderStyle;
