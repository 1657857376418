import React from "react";

import {withStyles} from "@mui/styles";
import listStyle from "../../assets/jss/views/quote/listStyle";

import QuoteDetailContainer from "./components/show/container";

const QuoteDetail = ({ classes, history, match }) => {
    return (
        <div className={classes.containerWithSidebar}>
            <div className={classes.container}>
                <QuoteDetailContainer
                    history={history}
                    match={match}
                />
            </div>
        </div>
    );
}

export default withStyles(listStyle)(QuoteDetail);
