import React from 'react';
import PropTypes from 'prop-types';

import Checkbox from "@mui/material/Checkbox";

import {ReactComponent as SwitchOff} from "assets/img/switch_off.svg";
import {ReactComponent as SwitchOn} from "assets/img/switch_on.svg";

CustomSwitch.propTypes = {
    checked: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired
};

CustomSwitch.defaultProps = {
    checked: false,
    onClick: () => {}
}

function CustomSwitch({checked, onClick, ...props}) {
    return (
        <Checkbox
            icon={<SwitchOff />}
            checkedIcon={<SwitchOn />}
            checked={checked}
            onClick={onClick}
            inputProps={{ 'aria-label': 'primary checkbox' }}
            sx={{
                '&.MuiCheckbox-root': {
                    cursor: 'pointer',
                    padding: 0,
                    marginRight: '8px'
                }
            }}
            {...props}
        />
    );
}

export default CustomSwitch;
