import React from "react";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";

import FormattedNumberWithCurrency
    from "components/formattedNumberWithCurrencies/formattedNumberWithCurrency";

import CardStatsFooter from "./cards/cardStatsFooter";
import CardStatsTitle from "./cards/cardStatsTitle";
import CardStatsValue from "./cards/cardStatsValue";
import CardStatsBody from "./cards/cardStatsBody";
import CardStats from "./cards/cardStats";
import Loader from "./loader";

export default function AverageCart(props) {
    const {
        currency,
        isRetailer,
        loading,
        onlineOrdersCount,
        onlineOrdersTurnover,
        physicalOrdersCount,
        physicalOrdersTurnover
    } = props

    let onlineAverageCart;
    let physicalAverageCart;

    let averageCart;
    let dividedBy = 1;

    onlineAverageCart = (onlineOrdersTurnover / onlineOrdersCount) || 0;
    physicalAverageCart = (physicalOrdersTurnover / physicalOrdersCount) || 0;

    if (onlineAverageCart > 0 && physicalAverageCart > 0) {
        dividedBy = 2;
    }

    averageCart = ((onlineAverageCart + physicalAverageCart) / dividedBy);

    return (
        <CardStats>
            <Loader loading={loading} />
            <CardStatsTitle>
                <FormattedMessage id={"dashboard.card.title.averageCart"}/>
            </CardStatsTitle>
            <CardStatsBody>
                <CardStatsValue>
                    <FormattedNumberWithCurrency
                        value={averageCart}
                        currency={currency}
                    />
                </CardStatsValue>
            </CardStatsBody>
            {!isRetailer && (
                <CardStatsFooter>
                    <div>
                        <span>
                            <FormattedMessage id={"dashboard.card.info.online"}/>
                        </span>
                        <FormattedNumberWithCurrency
                            value={onlineAverageCart}
                            currency={currency}
                        />
                    </div>
                    <div>
                    <span>
                        <FormattedMessage id={"dashboard.card.info.physical"}/>
                    </span>
                        <FormattedNumberWithCurrency
                            value={physicalAverageCart}
                            currency={currency}
                        />
                    </div>
                </CardStatsFooter>
            )}
        </CardStats>
    )
}
AverageCart.defaultProps = {
    onlineOrdersCount: 0,
    onlineOrdersTurnover: 0,
    physicalOrdersCount: 0,
    physicalOrdersTurnover: 0
};

AverageCart.propTypes = {
    currency: PropTypes.string,
    isRetailer: PropTypes.bool,
    loading: PropTypes.bool,
    onlineOrdersCount: PropTypes.number.isRequired,
    onlineOrdersTurnover: PropTypes.number.isRequired,
    physicalOrdersCount: PropTypes.number.isRequired,
    physicalOrdersTurnover: PropTypes.number.isRequired
};
