import React from 'react';

import DefaultSaveButton from 'components/button/SaveButton';

import {styled} from '@mui/styles';

export default function SaveButton(props) {
    const StyledSaveButton = styled(DefaultSaveButton)(({theme}) => ({
        [theme.breakpoints.down('md')]: {
            flex: 4
        },
    }))

    return (
        <StyledSaveButton {...props}>
            {props.children}
        </StyledSaveButton>
    );
}
