import React from "react";
import {connect} from "react-redux";
import {DragDropContext, Droppable} from "react-beautiful-dnd";

// @mui/material components
import {makeStyles} from "@mui/styles";

// core components
import Size from './size';

// action
import { updatePosition } from "actions/size/updatePosition";

// styles
const useStyles = makeStyles(theme => ({
    boxesList: {
        position: "relative",
        background: "green !important"
    },
    removeStyleType: {
        listStyleType: "none",
    },
    removePadding :{
        listStyleType: "none",
        paddingInlineStart: "0px",
        '&  li': {
            position: 'relative',
        },
        '&  li:hover': {
            overflow: 'visible',
        },
    },
}));

function Boxes (props) {
    const { allSizes, search, updatePosition } = props;

    const classes = useStyles();

    function reorder(list, startIndex, endIndex) {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    }

    function onDragEnd(result) {
        if (!result.destination) {
            return;
        }

        if (result.destination.index === result.source.index) {
            return;
        }

        const sizes = reorder(
            allSizes,
            result.source.index,
            result.destination.index
        );

        // assignPositionToSize
        let formattedSizes = [];

        sizes.forEach((size, index) => {
            let item = {
                "id": size.id,
                "position": index + 1
            };

            formattedSizes.push(item);
        });

        updatePosition({"sizes": formattedSizes});
    }

    return (
        <DragDropContext onDragEnd={onDragEnd} className={classes.boxesList}>
            <Droppable droppableId={"list"}>
                {provided => (
                    <ul className={classes.removePadding}>
                        <div ref={provided.innerRef} {...provided.droppableProps}>
                            {allSizes.map((size, index) => {
                                if (search.length > 0 ? size : true) {
                                    return (
                                        <Size
                                            key={size.id}
                                            data={size}
                                            provided={provided}
                                            draggableId={size.id}
                                            index={index}
                                            {...props}
                                        />
                                    )
                                } else return null;
                            })}
                        </div>
                        {provided.placeholder}
                    </ul>
                )}
            </Droppable>
        </DragDropContext>
    )
}

const mapStateToProps = (state) => {
    return {
        created: state.size.create.created,
        deleted: state.size.deleteSize.deleted,
        updated: state.size.update.updated,
    };
};

const mapDispatchToProps = dispatch => ({
    updatePosition: (params) => dispatch(updatePosition(params)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Boxes);
