import React, {Component} from 'react';
// nodejs library to set properties for components
import PropTypes from "prop-types";
import {FormattedMessage, injectIntl} from "react-intl";
import classNames from "classnames";

// @mui/material components
import { withStyles } from "@mui/styles";
import miniCardStyle from "assets/jss/views/product/miniCardStyle";

// core components
import Bubble from "./bubble";
import MiniCardDetails from "./miniCardDetails";
import {BeatLoader} from "react-spinners";
import {primaryColor} from "assets/jss/main";

// helpers
import connect from "react-redux/es/connect/connect";
import FormattedNumberWithCurrency from "../../../../../components/formattedNumberWithCurrencies/formattedNumberWithCurrency";
import {getFirstProductImage} from "utils/getFirstProductImage";
import {getTranslation} from "../../../../../domain/helpers/translations";

class MiniCardImage extends Component {

    getMiniCardStyle = () => {
        const {classes, currentType} = this.props;

        let className;

        if (currentType && currentType === "variation") {
            className = classNames({
                [classes.miniCardContainer]: true
            });
        } else {
            className = classNames({
                [classes.miniCardContainer]: true,
                [classes.miniCardContainerSelected]: true
            });
        }

        return className;
    };

    render() {
        const {classes, currentProduct, onProductTypeChange, variations, stock, price} = this.props;
        const className = this.getMiniCardStyle();

        return (
            <div className={className} onClick={() => onProductTypeChange(true)}>
                <div className={classes.miniCardHeader}>
                    <span className={classes.miniCardHeaderTitle}>{this.props.intl.formatMessage({id: "product.show.offer"})}</span>
                    {variations ?
                        <Bubble type={currentProduct.status ? "success" : "danger"}>
                            {currentProduct.status ? <FormattedMessage id={"product.show.attribute.isActive"}/> :
                                <FormattedMessage id={"product.show.attribute.isNotActive"}/>}
                        </Bubble>
                        :
                        <div className={classes.BeatLoader}>
                            <BeatLoader
                                sizeUnit={"px"}
                                size={12}
                                color={primaryColor}
                                loading={true}
                            />
                        </div>
                    }
                </div>
                <div>
                    {variations &&
                        <div className={classes.miniCardImage}>
                            <img src={getFirstProductImage(variations[0].pictures)} alt="" />
                        </div>
                    }
                </div>
                <MiniCardDetails
                    title={{label : currentProduct ? getTranslation(currentProduct).name : this.props.intl.formatMessage({id: "product.show.attribute.na"})}}
                    leftTop={{
                        label : this.props.intl.formatMessage({id: "retailer.product.list.label.eshopid"}),
                        value: currentProduct ? (currentProduct.eshopId) ? currentProduct.eshopId : this.props.intl.formatMessage({id: "product.show.attribute.na"}) : this.props.intl.formatMessage({id: "product.show.attribute.na"}),
                    }}
                    leftBottom={{
                        label: this.props.intl.formatMessage({id: "product.show.attribute.sku"}),
                        value: currentProduct ? currentProduct.sku : this.props.intl.formatMessage({id: "product.show.attribute.na"}),
                    }}
                    rightTop={{
                        label: this.props.intl.formatMessage({id: "product.show.attribute.price"}),
                        value: price ? <FormattedNumberWithCurrency value={price} currency={this.props.currentOrganization?.currency} /> : this.props.intl.formatMessage({id: "product.show.attribute.na"}),
                    }}
                    rightBottom={{
                        label: this.props.intl.formatMessage({id: "product.show.attribute.stock"}),
                        value: stock ? stock : this.props.intl.formatMessage({id: "product.show.attribute.na"}),
                    }}
                />
            </div>
        );
    }
}

MiniCardImage.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string
};

const mapStateToProps = (state) => {
    return {
        organizations: state.authentication.organizations,
        member: state.authentication.member,
        currentOrganization: state.currentOrganization.retrieved ?? null,
    }
};

export default connect(
    mapStateToProps,
    null
)(withStyles(miniCardStyle)(injectIntl(MiniCardImage)));
