import React from 'react';

import Switch from '@mui/material/Switch';

import {styled} from "@mui/styles";

import {dangerColor, successColor} from "assets/jss/main";

const ColoredSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase': {
        color: dangerColor,
    },
    '& .MuiSwitch-switchBase + .MuiSwitch-track': {
        backgroundColor: dangerColor,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: successColor,
    },
    '& .MuiSwitch-switchBase.Mui-disabled': {
        opacity: 0.4
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: successColor
    },
}));

export default function StyledSwitch(props) {
    return (
        <ColoredSwitch {...props} />
    );
}
