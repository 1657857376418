import React, {useContext} from 'react'
import PropTypes from "prop-types";

import makeStyles from "@mui/styles/makeStyles";

import FormattedNumberWithCurrency
    from "components/formattedNumberWithCurrencies/formattedNumberWithCurrency";
import StyledSpanCellBold from "components/TableCells/StyledSpanCellBold";

import {OrderContext} from "contexts/OrderContext";

const useStyles = makeStyles(theme => ({
    crossedPrice: {
        textDecoration: "line-through",
        whiteSpace: "nowrap",
    },
}));

export default function Price({price, initialRecommendedPrice}) {
    const classes = useStyles();

    const {currentOrganization} = useContext(OrderContext);

    if (initialRecommendedPrice && initialRecommendedPrice / 100 !== price) {
        return (
           <>
                <StyledSpanCellBold>
                   <FormattedNumberWithCurrency value={price} currency={currentOrganization?.currency} />
                </StyledSpanCellBold>
                <span className={classes.crossedPrice}>
                     <FormattedNumberWithCurrency
                         value={initialRecommendedPrice / 100}
                         currency={currentOrganization?.currency}
                     />
                </span>
           </>
        )
    }

    return (
        <StyledSpanCellBold>
             <FormattedNumberWithCurrency value={price} currency={currentOrganization?.currency} />
        </StyledSpanCellBold>
    )
}

Price.defaultProps = {
    price: 0,
    initialRecommendedPrice: 0
}

Price.propTypes = {
    price: PropTypes.number,
    initialRecommendedPrice: PropTypes.number
}
