import { Component } from 'react';

// core components
import auth0Client from './auth';

class Login extends Component {
    componentDidMount() {
        auth0Client.signIn();
    }

    render() {
        return null;
    }
}

export default Login;