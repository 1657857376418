import React, {Component} from "react";
import { ThemeProvider } from '@mui/material/styles';
import connect from "react-redux/es/connect/connect";
import {FormattedMessage, injectIntl} from "react-intl";
import {ITEM_PER_PAGE} from "config/_pagination";
import {BeatLoader, PropagateLoader} from 'react-spinners';

// @mui/material components
import {
    Avatar,
    Fab,
    Checkbox,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Tooltip,
    StyledEngineProvider
} from "@mui/material";

// @mui/icons-material
import { CheckRounded, CloseRounded } from "@mui/icons-material";

// core components
import TableHead from "./components/tableHead";
import PaginationContainer from "components/pagination/paginationContainer";
import InviteDialog from "./components/inviteDialog";
import ItemsPerPageSelect from "components/select/itemsPerPageSelect";
import Search from "./components/search";

// styles
import membersStyle from "assets/jss/views/member/memberStyle";
import {dangerColor, successColor, primaryColor} from 'assets/jss/main'

// actions
import {list, reset} from "actions/member/list";
import {update} from "actions/member/update";
import {del} from "actions/member/delete";

// helpers
import {createTheme} from "@mui/material/styles/index";
import {capitalize} from "utils/capitalize";
import {withStyles} from "@mui/styles";

const theme = createTheme({
    palette: {
        primary: {main: successColor},
        secondary: {main: dangerColor},
    }
});

class RequestList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selected: [],
            page: 1,
            search: "",
            itemsPerPage: this.props.itemsPerPage !== null ? this.props.itemsPerPage : ITEM_PER_PAGE,
            inviteDialog: false,
            memberSelected: null
        };
    };

    UNSAFE_componentWillMount() {
        this.props.reset();
        this.props.history.push(`/members/${encodeURIComponent(`invitationToken[exists]=false&invitationEmail[exists]=true&itemsPerPage=${this.state.itemsPerPage}&pagination=true&page=1`)}`);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.match.params.params !== nextProps.match.params.params) {
            this.props.list(decodeURIComponent(nextProps.match.params.params))
        }

        if(this.props.deleted !== nextProps.deleted) {
            nextProps.list(decodeURIComponent(nextProps.match.params.params))
        }

        if(this.props.updated !== nextProps.updated) {
            nextProps.list(decodeURIComponent(nextProps.match.params.params))
        }
    };

    handleCheckMember = (e, item) => {
        e.stopPropagation();

        const {selected} = this.state;

        const isItemAlreadyAdded = selected.some(itemAdded => itemAdded['@id'] === item['@id']);

        if (!isItemAlreadyAdded) {
            this.setState({
                selected: [...selected, item]
            });
        } else {
            let newSelected = selected.filter((d) => !(d['@id'] === item['@id']));

            this.setState({selected: newSelected});
        }
    };

    handleSelectAllClick = (event) => {
        let newDataArray = JSON.parse(JSON.stringify(this.props.retrieved["hydra:member"]));

        this.setState({
            selected: event.target.checked ? newDataArray : []
        });
    };

    isSelected = item => this.state.selected.some(selectedItem => selectedItem['@id'] === item['@id']);

    isDialogVisible = (visible) => {
        this.setState({inviteDialog: visible});
    };

    acceptInvitation = (values) => {
        if (values.roles) {
            let rolesId = [];

            values.roles.forEach(role => {
                rolesId.push(`${role.value}`);
            });

            values.roles = rolesId;
        }

        if (values.user) {
            values.user = values.user['@id'];
        }

        if (values.retailers && values.retailers.length > 0) {
            let retailerIds = [];

            values.retailers.forEach(retailer => {
                retailerIds.push(`${retailer.value}`);
            })

            values.retailers = retailerIds;
        }

        values.state = 'enabled';
        values.invitationEmail = null;

        this.props.update(this.state.memberSelected, values)
            .then(() => {
                this.isDialogVisible(false)
            })
    };

    render() {
        const {classes, retrieved, listLoading, updateLoading} = this.props;
        const {inviteDialog} = this.state;

        const tableHead = [
            {id: "image", label: ""},
            {id: "member", label: <FormattedMessage id={"member.allMember.list.table.header.email"}/>},
            {id: "name", label: <FormattedMessage id={"member.allMember.list.table.header.name"}/>},
            {
                id: "actions",
                label: retrieved && listLoading ?
                    <BeatLoader
                        sizeUnit={"px"}
                        size={12}
                        color={primaryColor}
                        loading={listLoading}
                    /> : this.state.selected.length > 0 && <StyledEngineProvider injectFirst>
                        <ThemeProvider theme={theme}>
                        <Fab
                            color="primary"
                            size={'small'}
                            onClick={() => {}}
                            className={classes.invActionsMargin}
                        >
                            <Tooltip title={<FormattedMessage id={"members.join.request.table.action.confirm"}/>}>
                                <CheckRounded size={'small'} classes={{root: classes.validateAction}}/>
                            </Tooltip>
                        </Fab>
                        <Fab
                            color="secondary"
                            size={'small'}
                            onClick={() => this.state.selected.map(member => this.props.delete(member))}
                        >
                            <Tooltip title={<FormattedMessage id={"members.join.request.table.action.cancel"}/>}>
                                <CloseRounded size={'small'} classes={{root: classes.cancelAction}}/>
                            </Tooltip>
                        </Fab>
                    </ThemeProvider>
                </StyledEngineProvider>
            },
        ];

        return (
            <div>
                <div className={classes.subTableContainer}>
                    <Search params={this.props.match.params.params} history={this.props.history}/>
                    <ItemsPerPageSelect history={this.props.history} match={this.props.match} />
                </div>
                <div className={classes.tableWrapper}>
                    {!retrieved &&
                        <div className={classes.propagateLoader}>
                            <PropagateLoader
                                sizeUnit={"px"}
                                size={16}
                                color={primaryColor}
                                loading={listLoading}
                            />
                        </div>
                    }
                    <Table padding="none" className={classes.table}>
                        <TableHead
                            checked={retrieved && retrieved["hydra:totalItems"] > 0 && (retrieved["hydra:member"].length === this.state.selected.length)}
                            onSelectAllClick={this.handleSelectAllClick}
                            tableHead={tableHead}
                        />
                        <TableBody className={classes.tableBody}>
                            {(retrieved && retrieved["hydra:member"].length > 0) &&
                            retrieved["hydra:member"].map(member => {
                                const isSelected = this.isSelected(member);

                                if (!member["user"]) return null;

                                return (
                                    <TableRow
                                        hover
                                        className={`${member.state === 'enabled' ? classes.enabledTableRow : classes.disabledTableRow} ${isSelected ? classes.selectedTableRow : null}`}
                                        key={member['@id']}
                                        tabIndex={-1}
                                    >
                                        <TableCell className={classes.leftTableCell}>
                                            <Checkbox
                                                checked={isSelected}
                                                classes={{
                                                    root: classes.checkbox,
                                                    checked: classes.checked,
                                                }}
                                                onChange={(e) => this.handleCheckMember(e, member)}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <div className={classes.logoContainer}>
                                                {
                                                    member["user"]["picture"] ?
                                                        <Avatar
                                                            src={member["user"]["picture"]}
                                                            className={classes.avatar}/>
                                                        :
                                                        <Avatar className={classes.avatarText}>
                                                            {member["user"]['givenName'] && member["user"]['givenName'].charAt(0)}{member["user"]['familyName'] && member["user"]['familyName'].charAt(0)}
                                                        </Avatar>
                                                }
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            <div>{member.user.email}</div>
                                        </TableCell>
                                        <TableCell>
                                            <div>{capitalize(member.user.fullName)}</div>
                                        </TableCell>
                                        <TableCell>
                                            <StyledEngineProvider injectFirst>
                                                <ThemeProvider theme={theme}>
                                                    <Fab
                                                        color="primary"
                                                        size={'small'}
                                                        onClick={() => {
                                                            this.setState({memberSelected: member});
                                                            this.isDialogVisible(true)
                                                        }}
                                                        className={classes.invActionsMargin}
                                                    >
                                                        <Tooltip title={<FormattedMessage id={"members.join.request.table.action.confirm"}/>}>
                                                            <CheckRounded
                                                                size={'small'}
                                                                classes={{root: classes.validateAction}}
                                                            />
                                                        </Tooltip>
                                                    </Fab>
                                                    <Fab
                                                        color="secondary"
                                                        size={'small'}
                                                        onClick={() => this.props.delete(member)}
                                                    >
                                                        <Tooltip title={<FormattedMessage id={"members.join.request.table.action.cancel"}/>}>
                                                            <CloseRounded
                                                                size={'small'}
                                                                classes={{root: classes.cancelAction}}
                                                            />
                                                        </Tooltip>
                                                    </Fab>
                                                </ThemeProvider>
                                            </StyledEngineProvider>
                                        </TableCell>
                                    </TableRow>
                                );
                            })
                            }
                            {retrieved && retrieved["hydra:member"].length === 0 &&
                            <TableRow>
                                <TableCell colSpan={9} className={classes.tableCellNoResult}>
                                    <FormattedMessage id={"member.tab.all.table.no.result"}/>
                                </TableCell>
                            </TableRow>
                            }
                        </TableBody>
                    </Table>
                </div>
                <div className={classes.paginationContainer}>
                    <PaginationContainer
                        itemsPerPage={this.state.itemsPerPage}
                        urlParams={this.props.match.params.params}
                        retrieved={this.props.retrieved}
                    />
                </div>
                <InviteDialog
                    openDialog={inviteDialog}
                    closeDialog={() => this.isDialogVisible(false)}
                    submitLoading={updateLoading}
                    submitAction={this.acceptInvitation}
                    member={this.state.memberSelected}
                />
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        itemsPerPage: state.itemsPerPage.itemsPerPage,
        retrieved: state.member.list.retrieved,
        listLoading: state.member.list.loading,
        updateLoading: state.member.update.loading,
        updated: state.member.update.updated,
        deleted: state.member.del.deleted,
    };
};

const mapDispatchToProps = dispatch => ({
    update: (item, value) => dispatch(update(item, value)),
    list: (params) => dispatch(list(params)),
    delete: (item) => dispatch(del(item)),
    reset: () => dispatch(reset()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(membersStyle)(injectIntl(RequestList)));
