import React, {Component} from "react";
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";
import PropTypes from "prop-types";
import {FormattedMessage, injectIntl} from "react-intl";
import {BeatLoader} from 'react-spinners';

// @mui/material components
import {Tooltip, } from "@mui/material";
import {withStyles} from "@mui/styles";

// @mui/icons-material
import {Delete, NotificationsActive} from "@mui/icons-material";

// core components
import Form from "./form";
import CardBody from "components/card/cardBody";
import GridContainer from "components/grid/gridContainer";
import GridItem from "components/grid/gridItem";
import Card from "components/card/card";
import CardHeader from "components/card/cardHeader";
import Snackbar from "components/snackbar/snackbar";
import Sync from './sync';

// styles
import {del} from "actions/retailer/delete";
import updateStyle from "assets/jss/views/user/updateStyle";
import {primaryColor} from "assets/jss/main";

// actions
import {retrieve, update, reset} from "actions/retailer/update";

// helpers
import abort from "utils/abort";

class Update extends Component {
    static propTypes = {
        retrieved: PropTypes.object,
        retrieveLoading: PropTypes.bool.isRequired,
        retrieveError: PropTypes.string,
        updateLoading: PropTypes.bool.isRequired,
        updateError: PropTypes.string,
        deleteLoading: PropTypes.bool.isRequired,
        deleteError: PropTypes.string,
        updated: PropTypes.object,
        deleted: PropTypes.object,
        retrieve: PropTypes.func.isRequired,
        update: PropTypes.func.isRequired,
        del: PropTypes.func.isRequired,
        reset: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);

        this.state = {
            retrieveErrorNotification: true,
            updatedNotification: true,
            createdNotification: true,
            updateErrorNotification: true,
            deleteErrorNotification: true,
            listParams:''
        };
    }

    componentDidMount() {
        const decodedParams = decodeURIComponent(this.props.match.params.id)
        const firstOccurence = decodedParams.indexOf("&")
        const id = decodedParams.slice(0,firstOccurence)
        this.setState({
            listParams:decodedParams.slice(firstOccurence +1 ,decodedParams.length)
        })
        this.props.retrieve(id);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({retrieveErrorNotification: true});
        this.setState({updateErrorNotification: true});
        this.setState({updatedNotification: true});
        this.setState({deleteErrorNotification: true});
    }

    componentWillUnmount() {
        this.props.reset();
        abort.abortRequests();
    }

    del = () => {
        if (window.confirm(this.props.intl.formatMessage({id: "retailer.window.confirm.delete"})))
            this.props.del(this.props.retrieved);
    };

    render() {
        if ((this.props.deleted && false) || this.props.updated) return <Redirect
            to={`/retailers/${encodeURIComponent(`order[pushActive]=desc&order[name]=asc&pagination=true&itemsPerPage=500&page=1`)}`}/>;

        const item = this.props.updated ? this.props.updated : this.props.retrieved;
        const {classes, updateError, retrieveError} = this.props;
        return (
            <div>
                <div className={classes.containerWithSidebar}>

                    <div className={classes.container}>
                        {updateError && !updateError.errors && (
                            <Snackbar
                                open={this.state.updateErrorNotification}
                                close
                                closeNotification={() => this.setState({updateErrorNotification: false})}
                                place={"bl"}
                                color={"danger"}
                                icon={function () {
                                    return <NotificationsActive/>
                                }}
                                message={`${updateError}`}
                            />
                        )}

                        {retrieveError && (
                            <Snackbar
                                open={this.state.retrieveErrorNotification}
                                close
                                closeNotification={() => this.setState({retrieveErrorNotification: false})}
                                place={"bl"}
                                color={"danger"}
                                message={retrieveError}
                                icon={function () {
                                    return <NotificationsActive/>
                                }}
                            />
                        )}

                        {this.props.deleteError && (
                            <Snackbar
                                open={this.state.deleteErrorNotification}
                                close
                                closeNotification={() => this.setState({deleteErrorNotification: false})}
                                place={"bl"}
                                color={"danger"}
                                icon={function () {
                                    return <NotificationsActive/>
                                }}
                                message={this.props.deleteError}
                            />
                        )}

                        <GridContainer>
                            <GridItem xs={12}>
                                <Card className={classes.card}>
                                    <CardHeader color="info" className={classes.cardHeader}>
                                        <div className={classes.beatLoader}>
                                            <BeatLoader
                                                sizeUnit={"px"}
                                                size={12}
                                                color={primaryColor}
                                                loading={this.props.retrieveLoading || this.props.updateLoading || this.props.deleteLoading}
                                            />
                                        </div>
                                        <GridContainer justifyContent={"space-between"}>
                                            <GridItem xs={10}>
                                                <h3><FormattedMessage id={"retailer.update.title"}/></h3>
                                            </GridItem>
                                            {(!this.props.retrieveLoading && !this.props.updateLoading && !this.props.deleteLoading && item) &&
                                                <GridItem xs={2} className={classes.mainActionsContainer}>
                                                    <span style={{cursor: "pointer"}} onClick={this.del}><Tooltip
                                                        title={<FormattedMessage
                                                            id={"retailer.update.tooltip.delete"}/>}><Delete
                                                        color={"error"}/></Tooltip></span>
                                                </GridItem>
                                                }
                                            </GridContainer>
                                    </CardHeader>
                                    <CardBody>
                                        <Form
                                            update
                                            onSubmit={values => this.props.update(item, values)}
                                            initialValues={item && item}
                                            updateLogo={true}
                                        />
                                    </CardBody>
                                </Card>
                                {item &&
                                    <Sync retailer={item} />
                                }
                            </GridItem>
                        </GridContainer>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    retrieved: state.retailer.update.retrieved,
    retrieveError: state.retailer.update.retrieveError,
    retrieveLoading: state.retailer.update.retrieveLoading,
    updateError: state.retailer.update.updateError,
    updateLoading: state.retailer.update.updateLoading,
    deleteError: state.retailer.del.error,
    deleteLoading: state.retailer.del.loading,
    created: state.retailer.create.created,
    deleted: state.retailer.del.deleted,
    updated: state.retailer.update.updated
});

const mapDispatchToProps = dispatch => ({
    retrieve: id => dispatch(retrieve(id)),
    update: (item, values) => dispatch(update(item, values)),
    del: item => dispatch(del(item)),
    reset: () => dispatch(reset()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(updateStyle)(injectIntl(Update)));
