import React, {Component} from "react";
import {Authorizations, hasAuthorization} from "../../../utils/authorizations";
import {Collapse, List, ListItem, ListItemIcon, ListItemText} from "@mui/material";
import {ExpandLess, ExpandMore, Settings} from "@mui/icons-material";
import {FormattedMessage} from "react-intl";
import {Link} from "react-router-dom";

class DrawerConfiguration extends Component {
    constructor(props) {
        super(props);
        this.state = {
            configurationTabOpen: this.isConfigurationTabShouldStayOpen(this.props.match.path),
        };
    }

    isConfigurationTabShouldStayOpen = (path) => {
        return !!(path.startsWith("/members")
            || path.startsWith("/roles")
            || path.startsWith("/categories")
            || path.startsWith("/sizes")
            || path.startsWith("/colors")
            || path.startsWith("/genders")
            || path.startsWith("/corners")
            || path.startsWith("/sectors")
            || path.startsWith("/cancellation_reasons")
            || path.startsWith("/recommendations"))
    }

    handleConfigurationTabChange = () => {
        this.setState(state => ({configurationTabOpen: !state.configurationTabOpen}));
    };
    renderListItemConfiguration(itemName) {
        const {authorizations, classes, itemsPerPage, match} = this.props;

        let itemPath = null;

        if (itemName === "roles") {
            if (hasAuthorization(authorizations, Authorizations.GLOBAL_ROLE_MANAGEMENT)) {
                itemPath = `/roles/${encodeURIComponent('order[name]=asc&pagination=true&page=1')}`
            } else return;
        } else if (itemName === "members") {
            if (hasAuthorization(authorizations, Authorizations.GLOBAL_MEMBER_MANAGEMENT)) {
                itemPath = `/members/${encodeURIComponent(`user[exists]=true&invitationToken[exists]=false&invitationEmail[exists]=false&itemsPerPage=${itemsPerPage}&pagination=true&page=1`)}`;
            } else return;
        } else if (itemName === "categories") {
            if (hasAuthorization(authorizations, Authorizations.MARKETPLACE_CATEGORY_MANAGEMENT)) {
                itemPath = `/categories`;
            } else return;
        } else if (itemName === "corners") {
            if (hasAuthorization(authorizations, Authorizations.MARKETPLACE_CATEGORY_MANAGEMENT)) {
                itemPath = `/corners`;
            } else return;
        } else if (itemName === "cancellation_reasons") {
            if (hasAuthorization(authorizations, Authorizations.MARKETPLACE_CANCELLATION_REASON_MANAGEMENT)) {
                itemPath = `/cancellation_reasons`;
            } else return;
        } else if (itemName === "recommendations") {
            if (hasAuthorization(authorizations, Authorizations.GLOBAL_ORGANIZATION_MANAGEMENT)) {
                itemPath = `/recommendations`
            } else return
        } else {
            if (hasAuthorization(authorizations, Authorizations.GLOBAL_ORGANIZATION_ESHOP_MANAGEMENT)) {
                itemPath = (`/${itemName}`);
            } else return;
        }

        return (
            <ListItem
                button
                className={classes.listItem}
                classes={{selected: classes.selected}}
                component={Link}
                to={itemPath}
                key={itemName}
                selected={match.path.startsWith(itemPath)}
            >
                <ListItemText
                    className={classes.subListText}
                    inset
                    primaryTypographyProps={{color: match.path.startsWith(itemPath) ? "primary" : "secondary"}}
                    primary={<FormattedMessage id={`sidebar.item.${itemName}`}/>}
                />
            </ListItem>
        )
    }

    render() {
        const {authorizations, classes, match} = this.props;
        const {configurationTabOpen} = this.state;
        const isInConfigurationPath = match.path.startsWith("/members/")
            || match.path.startsWith("/roles")
            || match.path.startsWith("/categories")
            || match.path.startsWith("/sizes")
            || match.path.startsWith("/genders")
            || match.path.startsWith("/colors")
            || match.path.startsWith("/corners")
            || match.path.startsWith("/sectors")
            || match.path.startsWith("/cancellation_reasons")
            || match.path.startsWith("/recommendations")
        ;

        const entryList = ["members", "roles", "categories", "colors", "corners", "sizes", "genders", "sectors", "cancellation_reasons"];

        if (process.env.REACT_APP_RECOMMENDATION_ENABLED) {
            entryList.splice(3, 0, 'recommendations');
        }

        return (
            <div>
                {(hasAuthorization(authorizations, Authorizations.GLOBAL_MEMBER_MANAGEMENT)
                    || hasAuthorization(authorizations, Authorizations.GLOBAL_ROLE_MANAGEMENT)
                    || hasAuthorization(authorizations, Authorizations.MARKETPLACE_CATEGORY_MANAGEMENT)
                    || hasAuthorization(authorizations, Authorizations.MARKETPLACE_CANCELLATION_REASON_MANAGEMENT)
                    || hasAuthorization(authorizations, Authorizations.GLOBAL_ORGANIZATION_ESHOP_MANAGEMENT)) && (
                    <div>
                        <ListItem
                            button
                            className={classes.listItem}
                            classes={{selected: classes.subListSelected}}
                            onClick={this.handleConfigurationTabChange}
                            key={"configuration"}
                            selected={isInConfigurationPath}
                        >
                            <ListItemIcon>
                                <Settings color={isInConfigurationPath ? "primary" : "secondary"} />
                            </ListItemIcon>
                            <ListItemText
                                primaryTypographyProps={{color: isInConfigurationPath ? "primary" : "secondary"}}
                                primary={<FormattedMessage id={"sidebar.app.configuration"}/>}
                            />
                            {configurationTabOpen ? (
                                <ExpandLess color={"primary"} />
                            ) : (
                                <ExpandMore color={"secondary"} />
                            )}
                        </ListItem>
                        <Collapse in={configurationTabOpen}>
                            <List className={classes.listContainer} disablePadding>
                                {entryList.map(item => {
                                    return this.renderListItemConfiguration(item);
                                })}
                            </List>
                        </Collapse>
                    </div>
                )}
            </div>
        )
    }
}

export default DrawerConfiguration;
