import {fetch} from "utils/dataAccess";
import payloadFactory from "./helper/payloadFactory";

export default async function update(recommendation, values)  {

    return new Promise((resolve, reject) => {
        fetch(recommendation['@id'], {
            method: "put",
            body: JSON.stringify(payloadFactory(values)),
        }).then(response => {
            response.json().then(response => {
                resolve(response);
            });
        }).catch(error => {
            reject(error)
        });
    })
}
