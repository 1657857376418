import {SUPPORTED_LOCALES, SUPPORTED_LOCALES_CODES} from "../enum/locales";
import store from "../../store";

export const localeData = (locales) => {
    return locales.map((locale) => {
        if (-1 === SUPPORTED_LOCALES_CODES.indexOf(locale)) {
            throw new Error(`The locale ${locale} is configured on organization, but not allowed on the front`);
        }

        return SUPPORTED_LOCALES[locale];
    });
};

const getResourceTranslation = (item) => {
    if (!item.translations) {
        throw new Error('You can use getTranslation function only on translatable resources.');
    }

    // Support when the item has no translation (can happen when there is no name on a variation)
    if (item.translations.length === 0) {
        return {};
    }

    const organization = store.getState().currentOrganization?.retrieved;
    const userLocale = store.getState().authentication?.member?.locale;

    if (!organization) {
        return {};
    }

    const isUserLocaleValidForOrganization = organization.locales.includes(userLocale);

    // Return the translation corresponding to the current user's locale
    // (high priority because it is an organization locale)
    if (isUserLocaleValidForOrganization && item.translations[userLocale]) {
        return item.translations[userLocale];
    }

    // Return the translation corresponding to the organization default locale
    const defaultLocale = organization.defaultLocale;
    if (defaultLocale && item.translations[defaultLocale]) {
        return item.translations[defaultLocale];
    }

    // Return one the translation in organization available locales
    for (const organizationLocale in organization.locales) {
        if (item.translations && item.translations[organizationLocale]) {
            return item.translations[organizationLocale];
        }
    }

    // Return the translation corresponding to the current user's locale
    // (low priority because it is not an organization locale)
    if (!isUserLocaleValidForOrganization && userLocale && item.translations[userLocale]) {
        return item.translations[userLocale];
    }

    // Super weird case: the translation exists but do not correspond to any of user or organization locale
    return item.translations[Object.keys(item.translations)[0]];
};

export const getSimpleObjectTranslation = (translations) => {
    const organization = store.getState().currentOrganization.retrieved;
    const userLocale = store.getState().authentication?.member?.locale;
    const isUserLocaleValidForOrganization = organization.locales.includes(userLocale);

    // Return the translation corresponding to the current user's locale
    // (high priority because it is an organization locale)

    let foundObject = translations.find(o => o.locale === userLocale);
    if (isUserLocaleValidForOrganization && !!foundObject) {
        return foundObject.translated;
    }

    // Return the translation corresponding to the organization default locale
    const defaultLocale = organization.defaultLocale;
    foundObject = translations.find(o => o.locale === defaultLocale);
    if (defaultLocale && !!foundObject) {
        return foundObject.translated;
    }

    // Return one the translation in organization available locales
    for (const organizationLocale in organization.locales) {
        foundObject = translations.find(o => o.locale === organizationLocale);
        if (translations && !!foundObject) {
            return foundObject.translated;
        }
    }

    // Return the translation corresponding to the current user's locale
    // (low priority because it is not an organization locale)
    foundObject = translations.find(o => o.locale === userLocale);
    if (!isUserLocaleValidForOrganization && userLocale && !!foundObject) {
        return foundObject.translated;
    }

    if (translations instanceof Array && translations.length > 0 && typeof translations[0] === 'object' && !!translations[0].translated) {
        return translations[0].translated
    }
    /* All hopes lost */
    return "";
}

export const getTranslation = getResourceTranslation;
