import React from 'react';
import PropTypes from 'prop-types';

import {FormattedMessage} from "react-intl";

import StyledSwitch from "components/switch/StyledSwitch";

LabeledSwitch.propTypes = {
    labelMessageId: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    checked: PropTypes.bool,
}

export default function LabeledSwitch({labelMessageId, disabled = false, checked = false, ...props}) {
    return (
        <>
            <span style={{ fontSize: "14px" }}>
                <FormattedMessage id={labelMessageId} />
            </span>
            <StyledSwitch
                disabled={disabled}
                checked={checked}
                {...props}
            />
        </>
    );
}
