import React from "react";

// @mui/material components
import {makeStyles} from "@mui/styles";

// core components
import NoResult from '../../configuration/components/noResult';
import CancellationReasonRow from './cancellationReasonRow';

// styles
const useStyles = makeStyles(theme => ({
    removeUlPadding: {
        padding: 0,
        margin: 0,
        listStyleType: "none"
    },
}));

export default function EnhancedTableBody (props) {
    const { retrieved } = props;

    const classes = useStyles();

    return (

        <div className={classes.removeUlPadding}>
            {(retrieved && retrieved.length > 0) && (
                retrieved.map(cancellationReason => (
                    <CancellationReasonRow
                        key={cancellationReason.id}
                        cancellationReason={cancellationReason}
                    />
                ))
            )}
            {retrieved && retrieved.length === 0 && (
                <NoResult />
            )}
        </div>
    );
}
