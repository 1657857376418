import React, {Component} from "react";
import {injectIntl} from "react-intl";

// core component
import Button from "../../../../../../components/button/button";
import CancelButton from "components/button/cancelButton";
import GridItem from "components/grid/gridItem";
import GridContainer from "components/grid/gridContainer";
import SubmitButton from "components/button/submitButton";

// styles
import {withStyles} from "@mui/styles";
import StockEasyButtonStyle from "assets/jss/views/stockeasy/product/create/button/stockEasyButtonStyle";

import localStorageSE from "../localStorageManager/localStorageSE";

class ButtonsWrapper extends Component {
    displaySecondButton = () => {
        const {submit, loading, firstButtonLink, intl, firstButtonDisabled} = this.props;

        if (firstButtonLink) {
            return (
                <Button
                    fullWidth
                    round
                    color={"success"}
                    href={firstButtonLink}
                    disabled={firstButtonDisabled}
                >
                    {this.props.firstButtonContent ? this.props.firstButtonContent : intl.formatMessage({id: "button.ok"})}
                </Button>
            )
        }

        return (
            <SubmitButton
                onClick={submit}
                loading={loading}
                content={this.props.firstButtonContent}
                disabled={firstButtonDisabled}
            />
        )
    }

    displayThirdButton = () => {
        const {intl, removeDraft} = this.props;
        return (
            <CancelButton
                onClick={removeDraft}
                content={intl.formatMessage({id: "stockeasy.step.leave.modal.remove.draft"})}
                color={"danger"}
            />
        )
    }

    displayFirstButton = () => {
        const {goToPreviousStep, secondButtonLink, intl, secondButtonContent, step} = this.props;

        let content;
        if (secondButtonContent) {
            content = secondButtonContent;
        } else if (step >= 2) {
            content = intl.formatMessage({id: "stockeasy.button.previous"});
        } else {
            content = intl.formatMessage({id: "stockeasy.button.cancel"});
        }

        // if there is a link, it's a simple button with href
        if (secondButtonLink) {
            return (
                <Button
                    fullWidth
                    color={"primary"}
                    simple
                    href={secondButtonLink}
                >
                    {content}
                </Button>
            )
        }

        return (
            <CancelButton
                onClick={goToPreviousStep}
                content={this.props.secondButtonContent}
                step={step}
            />
        )
    }

    render() {
        const {classes} = this.props;
        return (
            <>
                <GridContainer className={classes.buttonsWrapper}>
                    <GridItem xs={12} md={6} className={classes.buttonWrapper}>
                        {this.displayFirstButton()}
                    </GridItem>
                    <GridItem xs={12} md={6} className={classes.buttonWrapper}>
                        {this.displaySecondButton()}
                    </GridItem>
                </GridContainer>
                { localStorageSE.getProductData() &&
                    <GridContainer className={classes.buttonsWrapper}>
                        <GridItem xs={12} md={12} className={classes.buttonWrapper}>
                            {this.displayThirdButton()}
                        </GridItem>
                    </GridContainer>
                }
            </>
        )
    }
}

export default (withStyles(StockEasyButtonStyle)(injectIntl(ButtonsWrapper)));
