import React from 'react';
import {FormattedMessage} from "react-intl";

import {sixteenGrey} from "assets/jss/main";

export default function NoteWillNotAppearMessage(props) {
    return (
        <span style={{ color: sixteenGrey}}>
            <FormattedMessage id={'picking.quote.history.internalNote.not.on.quote'} />
        </span>
    );
}
