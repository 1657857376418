export function setSelected(selected) {
    return {type: "SET_PRODUCT_SELECTION", selected};
}

export function setIsSelectAll(isSelectAll) {
    return {type: "SET_IS_SELECT_ALL_PRODUCTS", isSelectAll};
}

export function handleSelectProduct(item, selected) {
    let isItemAlreadyAdded = selected.some(itemAdded => itemAdded.id === item.id);

    return dispatch => {
        if (!isItemAlreadyAdded) {
            dispatch(setSelected([...selected, item]));
        } else {
            // if item is already selected, we remove it from the list
            let newSelected = selected;

            for (let i = 0; i < newSelected.length; i++) {
                if (newSelected[i].id === item.id) {
                    newSelected.splice(i, 1);
                }
            }

            dispatch(setSelected([...newSelected]));
        }

        dispatch(setIsSelectAll(false));
    }
}

export function reset() {
    return dispatch => {
        dispatch({type: "RESET_PRODUCT_SELECTION"});
    };
}