import React, {Component} from "react";
import {FormattedMessage} from "react-intl";
import Quagga from "@ericblade/quagga2";

// @mui/material
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select
} from "@mui/material";

// core components
import Button from "./button/button";
import GridContainer from "./grid/gridContainer";
import GridItem from "./grid/gridItem";

// icons
import {Edit, CameraEnhance} from "@mui/icons-material";
import {redirectToStockEasyApp} from "../utils/redirectToStockEasyApp";
import {withStyles} from "@mui/styles";

const barcodeScannerStyle = {
    contentBarcodeScannerContainer: {
        maxWidth: '90%',
        marginRight: 'auto',
        marginLeft: 'auto',
        display: 'flex',
    },
    barcodeScannerContainer: {
        background: 'white',
    },
    topBarcodeScannerContainer: {
        borderTop: '1px solid black',
        background: 'white',
    },
    formControl: {
        'width': '100%',
        'margin-bottom': '10px'
    },
    barcodeScanner: {
        "& video, canvas": {
            width: '100%',
            height: 'auto'
        },
        '& canvas.drawingBuffer, video.drawingBuffer': {
            display: 'none'
        },
    },
    buttonContainer: {
        marginTop: '15px',
        display: 'flex',
        flexDirection: 'column',
    },
};

class BarcodeScanner extends Component {
    constructor(props) {
        super(props);

        this.state = {
            videoInputDevices: [],
            selectedDevice: null,
            closed: false
        };
    }

    componentDidMount() {
        if (this.props.browserScanner) {
            this.initCamera();
        }
    }

    componentWillUnmount() {
        Quagga.stop();
    }

    initCamera = (cameraId) => {
        let inputStreamConfig = {
            name : "Live",
            type : "LiveStream",
            target: document.querySelector('#barcode-camera-video'),

            constraints: {
                aspectRatio: { ideal: 1 },
                // Disabled in case the deviceId is specified
                // https://github.com/serratus/quaggaJS/blob/862df889706be6a60f98f36bbec31d491f9bdf7b/src/input/camera_access.js#L82
                ...(cameraId === 'default' ? { facingMode: 'environment' } : { deviceId: cameraId })
            }
        };

        Quagga.init({
            inputStream : inputStreamConfig,
            decoder : {
                readers : ["ean_reader"]
            }
        }, (err) => {
            if (err) {
                return;
            }

            Quagga.CameraAccess.enumerateVideoDevices().then((devices) => {
                this.setState({
                    videoInputDevices: devices,
                });
            });

            Quagga.start();
        });

        Quagga.onDetected((result) => {
            if (result) {
                let code = result.codeResult.code;
                // properly decoded barcodes
                if (this.props.onBarcodeFound) {
                    let found = true;
                    result.codeResult.decodedCodes.forEach(function(code) {
                        if (code.error !== undefined) {
                            if(parseFloat(code.error) > 0.25) {
                                found = false;
                            }
                        }
                    });

                    if (true === found) {
                        this.props.onBarcodeFound(code);

                        if (!this.props.allowLoopScanning) {
                            console.log('not allowLoopScanning');
                            Quagga.stop();
                        }
                    }
                } else {
                    console.error('Callback onBarcodeFound not provided');
                }
            }
        });
    };

    handleDeviceChange = (event) => {
        console.log('device change');
        Quagga.stop();
        this.setState({
            selectedDevice: event.target.value,
        });
        this.initCamera(event.target.value);
    };

    exit = (type) => {
        Quagga.stop();
        console.log('exit');
        this.setState({closed: true});
        this.props.onExit(type);
    };

    render() {
        const {classes, hideButtons, browserScanner} = this.props;
        if (this.state.closed) {
            return null;
        }

        let buildInputOptions = () => {
            let options = [];
            this.state.videoInputDevices.forEach((device, index) => {
                const label = device.label || device.deviceId || device.id;
                options.push(<MenuItem value={device.deviceId} key={device.deviceId}>{label}</MenuItem>);
            });

            return options;
        };

        return (
            <GridContainer
                justifyContent="center"
                alignItems="center"
                className={classes.contentBarcodeScannerContainer}
            >
                { browserScanner &&
                    <>
                        <GridItem className={classes.topBarcodeScannerContainer}>
                            <FormControl variant="standard" className={classes.formControl}>
                                <InputLabel><FormattedMessage id={"stockeasy.barcode.scanner.select.camera"}/></InputLabel>
                                <Select
                                    variant="standard"
                                    onChange={this.handleDeviceChange}
                                    value={this.state.selectedDevice ? this.state.selectedDevice : ''}>
                                    {buildInputOptions()}
                                </Select>
                            </FormControl>
                        </GridItem>
                        <GridItem className={classes.barcodeScannerContainer}>
                            <div id="barcode-camera-video" className={classes.barcodeScanner}>
                            </div>
                        </GridItem>
                    </>
                }
                {!hideButtons &&
                    <GridItem className={classes.buttonContainer}>
                        <Button onClick={() => redirectToStockEasyApp(false)} color="primary" className={classes.button}>
                            <CameraEnhance/> <FormattedMessage id={"stockeasy.barcode.scanner.button.scan"}/>
                        </Button>
                        <Button onClick={() => this.exit('barcode')} color="primary" className={classes.button}>
                            <Edit/> <FormattedMessage id={"stockeasy.barcode.scanner.button.barcode"}/>
                        </Button>
                        <Button onClick={() => this.exit('no-barcode')} color="primary" className={classes.button}>
                            <FormattedMessage id={"stockeasy.barcode.scanner.button.no.barcode"}/>
                        </Button>
                    </GridItem>
                }
            </GridContainer>
        );
    }
}

BarcodeScanner.defaultProps = {
    allowLoopScanning: false
};

export default withStyles(barcodeScannerStyle)(BarcodeScanner);
