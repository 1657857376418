import {createContext} from "react";

export const OrderContext = createContext({
    confirmCancellationAction: () => {},
    isCancellationReasonDialogOpen: false,
    closeCancellationReasonDialog: () => {},
    transitionActions: null,
    status: null,
    currentOrganization: null,
    authorizations: null,
    member: null,
    updateLoadingLineItem: false,
    updateLoadingOrder: false,
    openCancellationDialogByTransitionAndItem: () => {},
    handleReserve: () => {},
    handleConfirm: () => {},
    handleConfirmReturnRunnerToRetailer: () => {},
    handleConfirmReturn: () => {},
    setItemBeingEdited: () => {},
    setEditMenuPosition: () => {},
    handleShowAssignButton: () => {},
    setShowAssignButtonForLineItem: () => {},
    showAssignButtonForLineItem: null,
    handleClickAssign: () => {},
    runnerHeader: false,
    organizationShippingMethods: [],
    lineItemSelectedIds: [],
    handleChangeCheckboxItem: () => {},
    orderStatus: null,
    toShipLineItemLength: 0,
    handleChangeCheckboxGlobal:() => {},
    refreshOrder: () => {},
    toggleModalWaybillSelector:() => {},
    setLineItemSelectedIds: () => {},
    setSelectedShipment: () => {},
    waitingTimeBeforeRefreshingList: false
})
