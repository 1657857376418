import React, {Component} from "react";
import {connect} from "react-redux";
import {FormattedMessage, injectIntl} from "react-intl";
import {ITEM_PER_PAGE} from "config/_pagination";
import {BeatLoader, PropagateLoader} from 'react-spinners';

// @mui/material components
import {
    Avatar,
    Checkbox,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Popover,
    Typography
} from "@mui/material"

// @mui/icons-material
import { MoreVert } from "@mui/icons-material";

// core components
import TableHead from "./components/tableHead";
import CustomDate from "components/date/date";
import ItemsPerPageSelect from "components/select/itemsPerPageSelect";
import PaginationContainer from "../../components/pagination/paginationContainer";
import Search from "./components/search";

// actions
import {list, reset} from "actions/member/list";
import {del, reset as resetDeleted} from "actions/member/delete";
import {reSend} from "actions/member/invite";

// styles
import membersStyle from "assets/jss/views/member/memberStyle";
import {primaryColor} from "assets/jss/main";

// utils
import {capitalize} from "utils/capitalize";
import abort from "utils/abort";
import {withStyles} from "@mui/styles";

class InvitationList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: 0,
            page: 1,
            selected: [],
            itemsPerPage: this.props.itemsPerPage !== null ? this.props.itemsPerPage : ITEM_PER_PAGE,
            countRetrieved: false,
            anchorEl: null,
            memberForPopover: null
        };
    };


    UNSAFE_componentWillMount() {
        this.props.reset();
        this.props.history.push(`${encodeURIComponent(`invitationToken[exists]=true&itemsPerPage=${this.state.itemsPerPage}&pagination=true&page=1`)}`);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.deleted !== nextProps.deleted) {
            this.props.list(decodeURIComponent(nextProps.match.params.params))
        }

        if (this.props.invited !== nextProps.invited) {
            this.props.list(decodeURIComponent(nextProps.match.params.params))
        }

        if (this.props.match.params.params !== nextProps.match.params.params) {
            this.props.list(decodeURIComponent(nextProps.match.params.params))
        }
    };

    componentWillUnmount() {
        this.props.resetDeleted();

        abort.abortRequests();
    }

    handleSelectAllClick = (event) => {
        const {retrieved} = this.props;

        let newDataArray = JSON.parse(JSON.stringify(retrieved["hydra:member"]));

        newDataArray = newDataArray.filter((member) => !this.isConnected(member['@id']));

        this.setState({
            selected: event.target.checked ? newDataArray : []
        })
    };

    handleClick = (member, event) => {
        event.stopPropagation();

        this.setState({
            anchorEl: event.currentTarget,
            memberForPopover: member
        });
    };

    handleCheckMember = (e, item) => {
        e.stopPropagation();

        const {selected} = this.state;

        if (this.isConnected(item['@id'])) return false;

        const isItemAlreadyAdded = selected.some(itemAdded => itemAdded['@id'] === item['@id']);

        if (!isItemAlreadyAdded) {
            this.setState({
                selected: [...selected, item]
            });
        } else {
            let newSelected = selected.filter((d) => !(d['@id'] === item['@id']));

            this.setState({selected: newSelected});
        }
    };

    handleClose = (e) => {
        e.stopPropagation();

        this.resetPopover();
    };

    isSelected = item => this.state.selected.some(selectedItem => selectedItem['@id'] === item['@id']);

    isConnected = (userId) => {
        const id = userId.replace('/members/', '');

        return id === this.props.member.id;
    };

    resetPopover(){
        this.setState({
            anchorEl: null,
            memberForPopover: null,
        });
    }

    deleteMember(member, e) {
        e.stopPropagation();

        if (window.confirm(this.props.intl.formatMessage({id: "member.invitation.window.confirm.delete"}))) {
            this.resetPopover();

            this.props.delete(member);
        }
    }

    resendInviteMember(member, e) {
        e.stopPropagation();

        const member_id = member["@id"].replace('/members/', '');

        this.resetPopover();

        this.props.resend({email: member.invitationEmail, memberId: member_id});
    }

    render() {
        const {classes, retrieved, intl, listLoading} = this.props;
        const { anchorEl, selected} = this.state;

        const tableHead = [
            {
                id: "image",
                label: ""
            },
            {
                id: "member",
                label: intl.formatMessage({id: "member.invitation.list.table.header.email"})
            },
            {
                id: "name",
                label: intl.formatMessage({id: "member.invitation.list.table.header.name"})
            },
            {

                id: "magasin",
                label: intl.formatMessage({id: "member.invitation.list.table.header.magasin"})
            },
            {
                id: "sent",
                label: intl.formatMessage({id: "member.invitation.list.table.header.sent"})
            },
            {
                id: "actions",
                label: (retrieved && listLoading) &&
                    <BeatLoader
                        sizeUnit={"px"}
                        size={12}
                        color={primaryColor}
                        loading={listLoading}
                    />
            },
        ];

        return (
            <div>
                <div className={classes.subTableContainer}>
                    <Search params={this.props.match.params.params} history={this.props.history}/>
                    <ItemsPerPageSelect history={this.props.history} match={this.props.match} />
                </div>
                <div className={classes.tableWrapper}>
                    {!retrieved &&
                        <div className={classes.propagateLoader}>
                            <PropagateLoader
                                sizeUnit={"px"}
                                size={16}
                                color={primaryColor}
                                loading={listLoading}
                            />
                        </div>
                    }
                    <Table className={classes.table} padding="none">
                        <TableHead
                            checked={retrieved && retrieved["hydra:totalItems"] > 0 && (retrieved["hydra:member"].length === this.state.selected.length)}
                            onSelectAllClick={this.handleSelectAllClick}
                            tableHead={tableHead}
                            selected={selected.length > 0}
                        />
                        <TableBody className={classes.tableBody}>
                            {(retrieved && retrieved["hydra:member"].length > 0) && retrieved["hydra:member"].map(member => {
                                const isSelected = this.isSelected(member);

                                return (
                                    <TableRow
                                        hover
                                        className={`${member.state === 'enabled' ? classes.enabledTableRow : classes.disabledTableRow} ${isSelected ? classes.selectedTableRow : null}`}
                                        key={member['@id']}
                                        tabIndex={-1}
                                        onClick={(e) => this.handleCheckMember(e, member)}
                                    >
                                        <TableCell className={classes.leftTableCell}>
                                            <Checkbox
                                                checked={isSelected}
                                                classes={{
                                                    root: classes.checkbox,
                                                    checked: classes.checked,
                                                }}
                                                onChange={(e) => this.handleCheckMember(e, member)}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <div className={classes.logoContainer}>
                                            {member["user"] && member["user"]["picture"] ?
                                                <Avatar
                                                    src={member["user"]["picture"]}
                                                    className={classes.avatar}
                                                />
                                                :
                                                <Avatar className={classes.avatarText}>
                                                {member["user"] &&
                                                    <span>
                                                        {member["user"]['givenName'] && member["user"]['givenName'].charAt(0)}
                                                        {member["user"]['familyName'] && member["user"]['familyName'].charAt(0)}
                                                    </span>
                                                }
                                                </Avatar>
                                            }
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            <div>{member.invitationEmail}</div>
                                        </TableCell>
                                        <TableCell>
                                            <div>{member.user ? capitalize(member.user.fullName) :
                                                <b><FormattedMessage id={"member.list.table.na"}/></b>}</div>
                                        </TableCell>
                                        <TableCell>
                                            <div>{member.retailer && capitalize(member.retailer.name)}</div>
                                        </TableCell>
                                        <TableCell>
                                            <div><CustomDate showRelativeDate={true} date={member.updatedAt} /></div>
                                        </TableCell>
                                        <TableCell>
                                            <MoreVert className={classes.icon} onClick={(e) => this.handleClick(member, e)}/>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                            {retrieved && retrieved["hydra:member"].length === 0 &&
                            <TableRow>
                                <TableCell colSpan={9} className={classes.tableCellNoResult}>
                                    <FormattedMessage id={"member.invitation.table.no_result"}/>
                                </TableCell>
                            </TableRow>
                            }
                        </TableBody>
                    </Table>
                </div>
                <Popover
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={this.handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <Typography
                        className={classes.typography}
                        onClick={(e) => this.resendInviteMember(this.state.memberForPopover, e)}
                    >
                        <FormattedMessage id={"member.invitation.list.action.resend"}/>
                    </Typography>
                    <Typography
                        className={classes.typography}
                        onClick={(e) => this.deleteMember(this.state.memberForPopover, e)}
                    >
                        <FormattedMessage id={"member.invitation.list.action.cancel"}/>
                    </Typography>
                </Popover>
                <div className={classes.paginationContainer}>
                    <PaginationContainer
                        itemsPerPage={this.state.itemsPerPage}
                        urlParams={this.props.match.params.params}
                        retrieved={this.props.retrieved}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        member: state.authentication.member,
        itemsPerPage: state.itemsPerPage.itemsPerPage,
        retrieved: state.member.list.retrieved,
        listLoading: state.member.list.loading,
        deleted: state.member.del.deleted,
        deletedLoading: state.member.del.loading,
        invited: state.member.invite.invited,
    };
};

const mapDispatchToProps = dispatch => ({
    list: (params) => dispatch(list(params)),
    reset: () => dispatch(reset()),
    delete: (id) => dispatch(del(id)),
    resend: (member) => dispatch(reSend(member)),
    resetDeleted: () => dispatch(resetDeleted()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(membersStyle)(injectIntl(InvitationList)));
