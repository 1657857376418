import React from 'react';

import AccordionDetails from "@mui/material/AccordionDetails";

import {sevenTeenGrey} from "assets/jss/main";

export default function HistoryNoteAccordionDetails(props) {
    return (
        <AccordionDetails sx={{
            backgroundColor: sevenTeenGrey,
            padding: 'unset'
        }}>
            {props.children}
        </AccordionDetails>
    );
}
