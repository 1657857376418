import {SubmissionError} from "redux-form";
import {fetch} from "../utils/dataAccess";
import {retrieve as retrieveUpdate} from "./retailer/update";
import {retrieve as retrieveUser} from "./profile/update"

export function uploadError(error) {
    return {type: "UPLOAD_ERROR", error};
}

export function uploadLoading(loading) {
    return {type: "UPLOAD_LOADING", loading};
}

export function uploadSuccess(success) {
    return {type: "UPLOAD_SUCCESS", success};
}

export function removeUploadSuccess(removeSuccess) {
    return {type: "REMOVE_UPLOAD_SUCCESS", removeSuccess};
}

export function handleFileUpload(file, type, object_id, position) {
    let formData = new FormData();
    formData.append("file", file[0]);
    formData.append("type", type);

    if (typeof object_id !== "undefined") {
        formData.append("object_id", object_id);
    }

    return dispatch => {
        dispatch(uploadLoading(true));

        if(file[0].size > 20971520) {
            dispatch(uploadError("product.form.validation.file.maxSizeExceed"));
            dispatch(uploadLoading(false));
            return;
        }

        return fetch("/media_objects", {
            method: "POST",
            body: formData
        }).then(response =>
            response.json()
        )
        .then((retrieved) => {
            if (type === "image_user_picture") {
                dispatch(retrieveUser(object_id));
            } else if (type === "image_retailer_logo") {
                dispatch(retrieveUpdate("/retailers/" + object_id));
            } else if (type === "product_picture") {
                dispatch(uploadSuccess(retrieved));
            } else if (
                type === "image_retailer_translation_cover"
                || type === "image_retailer_translation_logo"
                || type === "category_translation_picture"
            ) {
                retrieved.uploaded_object_id = object_id;
                dispatch(uploadSuccess(retrieved));
            }

            dispatch(uploadLoading(false));
        })
        .catch(e => {
            dispatch(uploadLoading(false));

            if (e instanceof SubmissionError) {
                const errorIndex = Object.keys(e.errors).find(val => val !== '_error');
                if (!!errorIndex) {
                    dispatch(uploadError(e.errors[errorIndex]));
                }
            } else {
                dispatch(uploadError(e.message));
            }
        });
    }
}

export function removeFile(file_id) {

    return dispatch => {
        dispatch(uploadLoading(true));

        return fetch(file_id, {
            method: "DELETE"
        }).then(() => {
            dispatch(removeUploadSuccess(file_id));
            dispatch(uploadLoading(false));
        })
        .catch(e => {
            if (e instanceof SubmissionError) {
                dispatch(uploadError(e.errors._error));
                throw e;
            }
            dispatch(uploadError(e.message));
        });
    }
}

export function reset() {
    return dispatch => {
        dispatch(uploadLoading(false));
        dispatch(uploadError(false));
        dispatch(uploadSuccess(null));
        dispatch(removeUploadSuccess(null));
    };
}
