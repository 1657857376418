import React from "react";
import {FormattedMessage} from "react-intl";

import FormattedNumberWithCurrency
    from "components/formattedNumberWithCurrencies/formattedNumberWithCurrency";

import CardStats from "../cards/cardStats";
import CardStatsBody from "../cards/cardStatsBody";
import CardStatsFooter from "../cards/cardStatsFooter";
import CardStatsTitle from "../cards/cardStatsTitle";
import CardStatsValue from "../cards/cardStatsValue";
import Loader from "../loader";

export default function ConversionRate(props) {
    const { onlineConversionRate, physicalConversionRate, loading, currency } = props

    return (
        <CardStats>
            <Loader loading={loading} />
            <CardStatsTitle>
                <FormattedMessage id={"dashboard.graph.conversionRate.title"} />
            </CardStatsTitle>
            <CardStatsBody>
                <CardStatsValue>
                    <FormattedNumberWithCurrency
                        value={onlineConversionRate}
                        lang={"%"}
                        currency={currency}
                    />
                </CardStatsValue>
            </CardStatsBody>
            <CardStatsFooter>
                <div>
                    <span>
                        <FormattedMessage id={"dashboard.card.info.online"} />
                    </span>
                    <FormattedNumberWithCurrency
                        value={onlineConversionRate}
                        lang={"%"}
                        currency={currency}
                    />
                </div>
                <div>
                    <span>
                        <FormattedMessage id={"dashboard.card.info.physical"} />
                    </span>
                    <FormattedNumberWithCurrency
                        value={physicalConversionRate}
                        lang={"%"}
                        currency={currency}
                    />
                </div>
            </CardStatsFooter>
        </CardStats>
    )
}
