import React from "react";
import PropTypes from 'prop-types';
import {FormattedMessage} from "react-intl";

import CardStatsTitle from "views/marketplace/dashboard/components/cards/cardStatsTitle";
import CardStatsValue from "views/marketplace/dashboard/components/cards/cardStatsValue";
import CardStatsBody from "views/marketplace/dashboard/components/cards/cardStatsBody";
import CardStats from "views/marketplace/dashboard/components/cards/cardStats";
import Loader from "views/marketplace/dashboard/components/loader";

export default function QuoteCount(props) {
    const { quotesCount, loading } = props

    return (
        <CardStats>
            <Loader loading={loading} />
            <CardStatsTitle>
                <FormattedMessage id={"dashboard.quotes.card.title.quotes"} />
            </CardStatsTitle>
            <CardStatsBody>
                <CardStatsValue>
                    {quotesCount ? quotesCount.toLocaleString() : 0}
                </CardStatsValue>
            </CardStatsBody>
        </CardStats>
    )
}

QuoteCount.propTypes = {
    loading: PropTypes.bool,
    quotesCount: PropTypes.number.isRequired,
};
