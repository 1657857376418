import React, {useState, useRef} from "react";
import {Chip, Popover, Typography, } from "@mui/material";
import {withStyles} from "@mui/styles";
import productLabelStyle from "../../../../assets/jss/views/product/productLabelStyle";
import {fetch} from "../../../../utils/dataAccess";
import {FormattedMessage} from "react-intl";

function ProductValidationLabel({product, classes}) {
    const popoverEl = useRef(null);
    const [open, setOpen] = useState(false);
    const [error, setError] = useState(null);

    const fetchValidationError = () => {
        fetch("/products/" + product.id).then(
            response => {
                response.json().then(retrieved => {
                    setError(retrieved.validationErrors);
                });
            }
        );
    }

    const handlePopoverOpen = () => {
        setOpen(true);
        if (null === error) {
            fetchValidationError();
        }
    };

    const handlePopoverClose = () => {
        setOpen(false);
    };

    if (product.validated) {
        return (
            <Chip
                label={"ok"}
                variant="outlined"
                size="small"
                className={classes.ok}
            />
        );
    }
    return (
        <>
            <Chip
                label={"ko"}
                variant="outlined"
                size="small"
                className={classes.ko}
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
                ref={popoverEl}
            />
            <Popover
                className={classes.popover}
                open={open}
                anchorEl={popoverEl.current}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                disableRestoreFocus
                PaperProps={{ onMouseEnter: handlePopoverOpen, onMouseLeave: handlePopoverClose}}
            >
                <Typography className={classes.popoverContent}>
                    {error !== null ?
                        error : <FormattedMessage id={"merchandising.show.label.validation.loading"}/>}
                </Typography>
            </Popover>
        </>
    );
}

export default withStyles(productLabelStyle)(ProductValidationLabel);
