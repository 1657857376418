import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";

// @mui/material components
import {withStyles} from "@mui/styles";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Paper from "@mui/material/Paper";
import Grow from "@mui/material/Grow";
import Divider from "@mui/material/Divider";
import {IconButton, Fab} from "@mui/material";
import Popper from "@mui/material/Popper";

// core components
import Button from "../button/button";

// styles
import dropdownStyle from "../../assets/jss/components/dropdown/dropdown";

class Dropdown extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false
        };
        this.buttonRef = React.createRef();
        this.handleClick = this.handleClick.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }
    handleClick() {
        this.setState({ open: true });
    }
    handleClose() {
        this.setState({ open: false });
    }
    render() {
        const { open } = this.state;
        const {
            containerProps,
            classes,
            buttonIconWithText,
            buttonText,
            buttonIcon,
            buttonIconNoPadding,
            fab,
            iconColor,
            customIconColor,
            placement,
            dropdownList,
            buttonProps,
            dropup,
            dropdownHeader,
            caret,
            hoverColor,
            rtlActive,
            noLiPadding,
            mouseOver,
            disabled
        } = this.props;
        const caretClasses = classNames({
            [classes.caret]: true,
            [classes.caretActive]: open,
            [classes.caretRTL]: rtlActive
        });
        const dropdownItem = classNames({
            [classes.dropdownItem]: true,
            [classes[hoverColor + "Hover"]]: true,
            [classes.noLiPadding]: noLiPadding,
            [classes.dropdownItemRTL]: rtlActive
        });

        // Note: Adding float here doesn't really make sense since this behavior should be handled by
        //       the Fab element, but the div breaks it, this little style addition is a fix in many situations
        return (
            <div {...containerProps} onMouseEnter={mouseOver && this.handleClick} style={fab ? {float: 'right'} : {}} onMouseLeave={mouseOver && this.handleClose}>
                {(!fab && buttonIcon) &&
                    <IconButton
                        className={classNames({
                            [classes.buttonIcon]: true,
                            [classes.buttonIconNoPadding]: buttonIconNoPadding,
                            [classes[customIconColor + "ButtonIcon"]]: true,
                        })}
                        ref={this.buttonRef}
                        color={iconColor}
                        aria-owns={open ? "menu-list" : null}
                        aria-haspopup="true"
                        onClick={this.handleClick}
                        disableRipple={true}
                        disabled={disabled}
                        size="large">
                        {buttonIcon}
                    </IconButton>
                }
                {fab &&
                <Fab
                    className={classNames({
                        [classes.buttonIconNoPadding]: buttonIconNoPadding,
                        [classes[customIconColor + "ButtonIcon"]]: true,
                    })}
                    ref={this.buttonRef}
                    color={"primary"}
                    aria-owns={open ? "menu-list" : null}
                    aria-haspopup="true"
                    onClick={this.handleClick}
                >
                    {buttonIcon}
                </Fab>
                }
                {(!buttonIcon && !fab) &&
                    <Button
                        aria-owns={open ? "menu-list" : null}
                        aria-haspopup="true"
                        ref={this.buttonRef}
                        {...buttonProps}
                        onClick={this.handleClick}
                    >
                        {buttonIconWithText}
                        {buttonText !== undefined ? buttonText : null}
                        {caret ? <b className={caretClasses} /> : null}
                    </Button>
                }
                <Popper
                    open={open}
                    anchorEl={this.buttonRef.current}
                    disablePortal
                    placement={placement ? placement : "top"}
                    modifiers={[
                        {
                            flip: {},
                            preventOverflow: {
                                options: {altBoundary: true},
                            },
                        }
                    ]}
                    className={classNames({
                        [classes.popperClose]: !open,
                        [classes.pooperResponsive]: true
                    })}
                >
                    {({ TransitionProps, placement }) => (
                        <Grow
                            in={open}
                            id="menu-list"
                            style={
                                dropup
                                    ? { transformOrigin: 'left bottom' }
                                    : { transformOrigin: 'left top' }
                            }
                        >
                            <Paper className={classes.dropdown}>
                                <ClickAwayListener onClickAway={this.handleClose}>
                                    <MenuList role="menu" className={classes.menuList}>
                                        {dropdownHeader !== undefined ? (
                                            <MenuItem
                                                onClick={this.handleClose}
                                                className={classes.dropdownHeader}
                                            >
                                                {dropdownHeader}
                                            </MenuItem>
                                        ) : null}
                                        {dropdownList.map((prop, key) => {
                                            if (prop.divider) {
                                                return (
                                                    <Divider
                                                        key={key}
                                                        onClick={this.handleClose}
                                                        className={classes.dropdownDividerItem}
                                                    />
                                                );
                                            }
                                            return (
                                                <MenuItem
                                                    key={key}
                                                    onClick={this.handleClose}
                                                    className={dropdownItem}
                                                >
                                                    {prop}
                                                </MenuItem>
                                            );
                                        })}
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </div>
        );
    }
}

Dropdown.defaultProps = {
    caret: true,
    hoverColor: "primary"
};

Dropdown.propTypes = {
    classes: PropTypes.object.isRequired,
    containerProps: PropTypes.object,
    hoverColor: PropTypes.oneOf([
        "black",
        "primary",
        "info",
        "success",
        "warning",
        "danger",
        "rose"
    ]),
    buttonText: PropTypes.node,
    buttonIconWithText: PropTypes.object,
    buttonIcon: PropTypes.object,
    buttonIconNoPadding: PropTypes.bool,
    iconColor: PropTypes.string,
    customIconColor: PropTypes.string,
    placement: PropTypes.string,
    dropdownList: PropTypes.array,
    buttonProps: PropTypes.object,
    dropup: PropTypes.bool,
    dropdownHeader: PropTypes.node,
    rtlActive: PropTypes.bool,
    caret: PropTypes.bool,
    fab: PropTypes.bool,
    noLiPadding: PropTypes.bool,
    mouseOver: PropTypes.bool,
    disabled: PropTypes.bool
};

export default withStyles(dropdownStyle)(Dropdown);
