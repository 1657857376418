import {fetch} from "../../utils/dataAccess";

export function error(error) {
    return {type: "ANALYTICS_LIST_ERROR", error};
}

export function loading(loading) {
    return {type: "ANALYTICS_LIST_LOADING", loading};
}

export function success(retrieved) {
    return {type: "ANALYTICS_LIST_SUCCESS", retrieved};
}

export function list() {
    return dispatch => {
        dispatch(error(""));

        let url = `/analytics_configs`;

        return fetch(url, {method: "GET"})
            .then(response =>
                response.json()
            )
            .then(retrieved => {
                dispatch(success(retrieved));
            })
            .catch(e => {
                dispatch(error(e.message));
            });
    };
}

export function reset() {
    return dispatch => {
        dispatch({type: "ANALYTICS_LIST_RESET"});
    };
}
