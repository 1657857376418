import React from "react";
import PropTypes from "prop-types";

import {
    FormControlLabel as DefaultFormControlLabel,
    Radio
} from "@mui/material";

import {styled} from "@mui/material/styles";

import {borderGrayColor, greyColorHover, infoColor, radioCircleGrey} from "assets/jss/main";

const StyledFormControlLabel = styled(DefaultFormControlLabel)({
    width: '100%',
    marginLeft: "unset",
    padding: '24px 0 24px 16px',
    justifyContent: "space-between",
    alignSelf: 'stretch',
    border: `1px solid ${borderGrayColor}`,
    borderBottom: '1px solid white',
    background: 'white',
    '&:first-of-type': {
        borderTopLeftRadius: '8px',
        borderTopRightRadius: '8px',
    },
    '&:last-child': {
        borderBottomColor: borderGrayColor,
        borderBottomLeftRadius: '8px',
        borderBottomRightRadius: '8px',
    },
    '&:hover': {
        backgroundColor: greyColorHover,
        border: `1px solid ${infoColor}`,
    },
    '.MuiFormControlLabel-label': {
        fontSize: '0.875rem',
    },
})

export default function FormControlLabel(props) {
    const RadioButtonIcon = ({ checked }) => (
        <svg
            width="20px"
            height="20px"
            viewBox="0 0 24 24"
            fontSize="20px"
        >
            <circle
                cx="50%"
                cy="50%"
                r="11px"
                stroke={radioCircleGrey}
                fill="none"
            />
            {checked && (
                <circle
                    cx="50%"
                    cy="50%"
                    r="6px"
                    fill={infoColor}
                />
            )}
        </svg>
    );

    return (
        <StyledFormControlLabel
            labelPlacement="start"
            control={
                <Radio
                    icon={<RadioButtonIcon />}
                    checkedIcon={<RadioButtonIcon checked />}
                    sx={{
                        padding: 'unset',
                        marginRight: '16px',
                    }}
                />
            }
            {...props}
        />
    )
}

FormControlLabel.propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};
