import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@mui/styles';
import {FormattedMessage} from 'react-intl';
import {ReactComponent as AddButtonBlue} from 'assets/img/add-button-blue.svg';
import {blueColor, blue50} from 'assets/jss/main';

const useStyles = makeStyles(theme => ({
    button: {
        display: 'inline-flex',
        padding: '0px 12px 0px 4px',
        alignItems: 'center',
        position: 'absolute',
        bottom: '-30px',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#ffffff',
        borderRadius: '48px',
        textAlign: 'center',
        border: 0,
        boxShadow: '0px 4px 22px 0px rgba(0, 0, 0, 0.08)',
        color: blueColor,
        fontFamily: 'Roboto',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: 'normal',
        zIndex: 1,
        '&:hover': {
            backgroundColor: blue50,
        },
    },
}));

CreateBelow.propTypes = {
    onClick: PropTypes.func,
};

export default function CreateBelow({ onClick, }) {
    const classes = useStyles();

    return (
        <button
            type={'button'}
            className={classes.button}
            onClick={onClick}
        >
            <AddButtonBlue />
            <FormattedMessage id={'configuration.form.create-below'} />
        </button>
    );
}
