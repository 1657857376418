import React from "react";

// @mui/material components
import {withStyles} from "@mui/styles";
import Grid from "@mui/material/Grid";

const style = {
    grid: {
        position: "relative",
        width: "100%",
        minHeight: "1px",
        paddingRight: "15px",
        paddingLeft: "15px",
        flexBasis: "auto"
    }
};

function GridItem({ ...props }) {
    const { classes, children, className, ...rest } = props;
    let extraClasses = className;
    if (className === undefined) extraClasses = "";

    return (
        <Grid item {...rest} className={classes.grid + " " + extraClasses}>
            {children}
        </Grid>
    );
}

export default withStyles(style)(GridItem);
