import React from 'react'
import {FormattedMessage} from "react-intl";

import GridContainer from "components/grid/gridContainer";
import GridItem from "components/grid/gridItem";

import {infoColor} from "assets/jss/main";

export default function AllTranslationsRequiredMessage() {
    return (
        <GridContainer sx={{
            paddingBottom: '16px',
            color: infoColor
        }}>
            <GridItem xs={12} sm={12}>
                <FormattedMessage id={'configuration.form.translations.required'} />
            </GridItem>
        </GridContainer>
    )
}
