import React, {useContext} from 'react';
import {FormattedMessage} from "react-intl";
import PropTypes from 'prop-types';

import {styled} from "@mui/material/styles";

import GalleryAddIcon from "components/icons/GalleryAddIcon";

import ImportButton from "./components/ImportButton";
import RecommendedFormatText from "./components/RecommendedFormatText";
import FileSupportTypesText from "./components/FileSupportTypesText";

import Loading from "../Loading";

import {
    black500,
    blue25,
    blueColor,
    greyishBlue300,
    greyishBlue50,
    red50,
    red500
} from "assets/jss/main";

import {ImageDropFieldContext} from "../../context/ImageDropFieldContext";

Index.propTypes = {
    fileSupportTypes: PropTypes.array,
    recommendedFormat: PropTypes.string,
};

const StyledImportZone = styled('div')(({ theme, disabled, isValid}) => ({
    width: '100%',
    height: '252px',
    maxHeight: '252px',
    minHeight: '252px',
    minWidth: '252px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 8,
    border: '1px dashed',
    borderColor: blueColor,
    background: blue25,
    position: 'relative',
    'p': {
        color: black500,
        marginTop: 0
    },
    ...disabled && {
        borderColor: greyishBlue300,
        background: greyishBlue50,
        'p' : {
            marginTop: 0,
            color: greyishBlue300,
        }
    },
    ...isValid === false && {
        borderColor: red500,
        background: red50
    }
}));


function Index({fileSupportTypes, recommendedFormat}) {
    const {
        inputProps,
        rootProps,
        openFileSelector,
        disabled,
        importLoading,
        fileRejections
    } = useContext(ImageDropFieldContext);

    return (
        <StyledImportZone
            isValid={fileRejections.length === 0}
            disabled={disabled}
            {...rootProps}
        >
            <div style={{
                textAlign: 'center',
                fontWeight: 400,
                padding: '16px 24px',
            }}>
                <input {...inputProps} />
                <GalleryAddIcon color={blueColor} disabled={disabled} />
                <p>
                    <FormattedMessage id="components.imageDropField.drop" />
                    <ImportButton onClick={!disabled ? openFileSelector : undefined} />
                    <FormattedMessage id="components.imageDropField.yourPicture" />
                    <br/>
                    <FileSupportTypesText types={fileSupportTypes} />
                    <br />
                </p>
                {recommendedFormat && (
                    <RecommendedFormatText format={recommendedFormat} />
                )}

                <Loading loading={importLoading} />
            </div>
        </StyledImportZone>
    );
}

export default Index;
