import React from 'react';
import ListItemButton from "@mui/material/ListItemButton";
import {primaryColor} from "../../../assets/jss/main";

function StyledListItemButton(props) {
    return (
        <ListItemButton
            sx={{
                paddingLeft: "12px",
                paddingRight: "0px",

                "&:hover": {
                    background: "transparent",
                    "& div span": {
                        color: primaryColor,
                        fontWeight: "bold"
                    },
                    "& div svg path": {
                        stroke: primaryColor,
                        fontWeight: "bold"
                    },
                },
                '.Mui-selected': {
                    background: 'linear-gradient(90deg, rgba(0,82,203,1) 3%, rgba(184,208,246,1) 0%, rgba(184,208,246,0) 75%) !important',
                    "& div span": {}
                }
            }}
            {...props}
        >
            {props.children}
        </ListItemButton>
    );
}

export default StyledListItemButton;
