import {UAParser} from 'ua-parser-js';

export class UserAgentUtils {

    // Documentation here: https://github.com/faisalman/ua-parser-js#uaparserjs
    static userAgentParser = new UAParser();

    static isMobile() {
        let type = this.userAgentParser.getDevice().type;

        return type === 'mobile';
    }

    static isMobileOrTablet() {
        let type = this.userAgentParser.getDevice().type;

        return type === 'mobile' || type === 'tablet';
    }

    static isConsole() {
        let type = this.userAgentParser.getDevice().type;

        return type === 'console';
    }
}
