import {combineReducers} from "redux";

export function error(state = null, action) {
    switch (action.type) {
        case "PRODUCT_LIST_ERROR":
            return action.error;

        case "PRODUCT_LIST_RESET":
            return null;

        default:
            return state;
    }
}

export function loading(state = false, action) {
    switch (action.type) {
        case "PRODUCT_LIST_LOADING":
            return action.loading;

        case "PRODUCT_LIST_RESET":
            return null;

        default:
            return state;
    }
}

export function retrieved(state = false, action) {
    switch (action.type) {
        case "PRODUCT_LIST_SUCCESS":
            return action.retrieved;

        case "PRODUCT_LIST_RESET":
            return null;

        default:
            return state;
    }
}

export function exportLoading(state = false, action) {
    switch (action.type) {
        case "PRODUCT_LIST_EXPORT_LOADING":
            return action.loading;

        default:
            return state;
    }
}

export function exportSuccess(state = false, action) {
    switch (action.type) {
        case "PRODUCT_LIST_EXPORT_SUCCESS":
            return action.success;

        default:
            return state;
    }
}

export default combineReducers({error, loading, retrieved, exportLoading, exportSuccess});
