import React from "react";
import {makeStyles} from "@mui/styles";

import CardFooter from "components/card/cardFooter";

import {
    aliceBlueColor,
    portageBlueColor,
} from "assets/jss/main";

const useStyles = makeStyles(theme => ({
    cardStatsFooter: {
        flexWrap: "wrap",
        backgroundColor: aliceBlueColor,
        padding: "10px 0px",
        justifyContent: "space-evenly",
        color: portageBlueColor,
        fontWeight: "300",

        "& span": {
            fontWeight: "300",
            color: portageBlueColor,
            margin: "0px 5px"
        }
    },
}));

export default function CardStatsFooter({...props}) {
    const classes = useStyles();

    const { children } = props;

    return (
        <CardFooter className={classes.cardStatsFooter}>
            {children}
        </CardFooter>
    )
}
