import {combineReducers} from "redux";

export function error(state = null, action) {
    switch (action.type) {
        case "VARIATION_MEDIA_OBJECT_CREATE_ERROR":
            return action.error;

        default:
            return state;
    }
}

export function loading(state = false, action) {
    switch (action.type) {
        case "VARIATION_MEDIA_OBJECT_CREATE_LOADING":
            return action.loading;

        default:
            return state;
    }
}

export function success(state = null, action) {
    switch (action.type) {
        case "VARIATION_MEDIA_OBJECT_CREATE_SUCCESS":
            return action.success;

        case "VARIATION_MEDIA_OBJECT_CREATE_RESET":
            return null;

        default:
            return state;
    }
}

export default combineReducers({
    error,
    loading,
    success
});
