import {fetch} from "utils/dataAccess";

export default function list(params) {
    return new Promise((resolve, reject) => {
        fetch(`/sectors?${params}`).then(response => {
            response.json().then(response => {
                resolve(response["hydra:member"])
            })
        }).catch(error => reject(error));
    });
}
