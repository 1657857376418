import {combineReducers} from "redux";
import list from "./list";
import create from "./create";
import update from "./update";
import del from "./delete";
import show from "./show";
import invite from './stripe/invite';
import confirm from './stripe/confirm';
import invitationLink from './stripe/invitationLink';
import sync from "./sync";

export default combineReducers({
    list,
    create,
    update,
    del,
    show,
    invite,
    confirm,
    invitationLink,
    sync
});
