import React from 'react';
import PropTypes from 'prop-types';

import {styled} from "@mui/styles";

Content.propTypes = {
    content: PropTypes.string.isRequired,
};

export default function Content({content = ''}) {
    const StyledContent = styled('div')(({theme}) => ({
        padding: '20px 32px',
        [theme.breakpoints.down('md')]: {
            padding: '24px 16px'
        },
        color: '#000',
        fontWeight: 400
    }));

    return !!content && (
        <StyledContent>
            {content}
        </StyledContent>
    );
}
