import React, {useEffect, useState} from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {useFormikContext} from 'formik';

import Grid from '@mui/material/Grid';

import Label from 'components/input/new/Label';
import InputTextField from 'components/input/new/InputTextField';
import SelectTitle from 'components/radioGroup/SelectTitle';
import RadioGroup from 'components/radioGroup/RadioGroup';
import FormControlLabel from 'components/radioGroup/FormControlLabel';

import FormBloc from './components/FormBloc';

import {getTranslation} from 'domain/helpers/translations';

import listSector from 'api/sector/list';

function InfosForm({intl}) {
    const {values, handleChange, handleBlur, touched, errors, setFieldValue} = useFormikContext();

    const [sectorList, setSectorList] = useState([]);

    // component did mount
    useEffect(() => {
        listSector().then((response) => setSectorList(response));
    }, []);

    function getSectorNameById(sectorId) {
        if (!sectorId) {
            return;
        }

        const sector = sectorList.find((sector) => sector['@id'] === sectorId);

        return sector ? getTranslation(sector).name : '';
    }

    return (
        <FormBloc titleId="retailer.profile.edit.form.title.infos">
            <Grid container rowSpacing={3}>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Label htmlFor="email" disabled={true}>
                                <FormattedMessage id="retailer.profile.edit.form.field.email" />
                            </Label>
                            <InputTextField
                                name="email"
                                value={values.email}
                                onChange={handleChange}
                                disabled
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Label htmlFor="phone">
                                <FormattedMessage id="retailer.profile.edit.form.field.phone" />
                            </Label>
                            <InputTextField
                                name="phone"
                                placeholder={intl.formatMessage({id: "retailer.profile.edit.form.field.phone.placeholder"})}
                                value={values.phone}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.phone && Boolean(errors.phone)}
                                helperText={touched.phone && errors.phone}
                                fullWidth
                                required
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Label htmlFor="address">
                                <FormattedMessage id="retailer.profile.edit.form.field.address" />
                            </Label>
                            <InputTextField
                                name="address"
                                placeholder={intl.formatMessage({id: "retailer.profile.edit.form.field.address.placeholder"})}
                                value={values.address}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.address && Boolean(errors.address)}
                                helperText={touched.address && errors.address}
                                fullWidth
                                required
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Label htmlFor="smsPhone">
                                <FormattedMessage id="retailer.profile.edit.form.field.smsPhone" />
                            </Label>
                            <InputTextField
                                name="smsPhone"
                                placeholder={intl.formatMessage({id: "retailer.profile.edit.form.field.smsPhone.placeholder"})}
                                value={values.smsPhone}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.smsPhone && Boolean(errors.smsPhone)}
                                helperText={touched.smsPhone && errors.smsPhone}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <SelectTitle titleTranslationKey="retailer.profile.edit.form.field.sector" />
                            <RadioGroup
                                alignListWithButton={true}
                                disabled={sectorList.length === 0}
                                placeholder={intl.formatMessage({id: "retailer.profile.edit.form.field.sector.placeholder"})}
                                onChange={(event) => setFieldValue('sector', event.target.value)}
                                onBlur={handleBlur}
                                selectedValueLabel={getSectorNameById(values.sector)}
                                value={values.sector}
                            >
                                {sectorList.map((sector, index) => (
                                    <FormControlLabel
                                        key={index}
                                        value={sector['@id']}
                                        label={sector && getTranslation(sector).name}
                                    />
                                ))}
                            </RadioGroup>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </FormBloc>
    );
}

export default injectIntl(InfosForm);
