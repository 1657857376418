import React, {useContext, useEffect} from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {useFormikContext} from 'formik';

import Grid from '@mui/material/Grid';

import CustomSwitch from 'components/switch/CustomSwitch';
import FormTranslator from 'components/translator/formTranslator';
import Label from 'components/input/new/Label';
import InputTextField from 'components/input/new/InputTextField';

import {RetailerProfilesEditContext} from '../../context/RetailerProfilesEditContext';

import FormBloc from './components/FormBloc';
import {connect} from "react-redux";
import {localeData} from "../../../../../domain/helpers/translations";


function ClickAndCollectForm({intl, organization}) {
    const {values, handleChange, touched, handleBlur, errors, setFieldValue, setFieldTouched} = useFormikContext();

    const { isClickAndCollect, setClickAndCollect } = useContext(RetailerProfilesEditContext);
    const inputRef = React.useRef();

    // when check c&c, add yup clickAndCollectInstructions validation
    useEffect(() => {
        if (organization?.locales) {
            localeData(organization?.locales).forEach((locale) => {
                // trigger yup validation
                setFieldValue(`translations[${locale.code}].clickAndCollectInstructions`, '');

                // reset validation when uncheck c&c
                if (!isClickAndCollect) {
                    setFieldTouched(`translations[${locale.code}].clickAndCollectInstructions`, true);
                }
            })
        }
    }, [isClickAndCollect])

    return (
        <FormBloc titleId="retailer.profile.edit.form.title.clickAndCollect">
            <Grid container rowSpacing={3}>
                <Grid item xs={12}>
                    <CustomSwitch
                        onClick={(event) => setClickAndCollect(event.target.checked)}
                        checked={isClickAndCollect}
                    />
                    <FormattedMessage id="retailer.profile.edit.form.field.enableClickAndCollect" />
                </Grid>
                {isClickAndCollect && (
                    <>
                        <Grid item xs={12}>
                            <CustomSwitch
                                onClick={handleChange}
                                name="clickAndCollectOnly"
                                checked={values.clickAndCollectOnly}
                            />
                            <FormattedMessage id="retailer.profile.edit.form.field.onlyClickAndCollect" />
                        </Grid>
                        <Grid item xs={12}>
                            <FormTranslator formGenerator={(index, code) => (
                                <>
                                    <Label htmlFor={`translations.${code}.clickAndCollectInstructions`}>
                                        <FormattedMessage id="retailer.profile.edit.form.field.clickAndCollectInstructions" />
                                    </Label>
                                    <InputTextField
                                        name={`translations.${code}.clickAndCollectInstructions`}
                                        onChange={handleChange}
                                        value={values.translations?.[code]?.clickAndCollectInstructions ?? ''}
                                        placeholder={intl.formatMessage({id: "retailer.profile.edit.form.field.clickAndCollectInstructions.placeholder"})}
                                        multiline
                                        fullWidth
                                        rows={7}
                                        onBlur={handleBlur}
                                        error={touched.translations?.[code]?.clickAndCollectInstructions && Boolean(errors.translations?.[code]?.clickAndCollectInstructions)}
                                        helperText={touched.translations?.[code]?.clickAndCollectInstructions && errors.translations?.[code]?.clickAndCollectInstructions}
                                    />
                                </>
                            )} />
                        </Grid>
                    </>
                )}
            </Grid>
        </FormBloc>
    );
}

export default connect(
    state => ({
        organization: state.currentOrganization.retrieved,
    })
)(injectIntl(ClickAndCollectForm));
