import React, { useEffect } from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";

// @mui/material components
import { Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText } from "@mui/material";

// core components
import Button from "components/button/button";
import Loader from '../../configuration/components/loader';

// actions
import { deleteColor } from "actions/color/delete";

function DeleteDialog(props) {
    const { color, closeDialog, deleted, loading, isOpen } = props;

    const isSubColor = Boolean(color && color.parent !== null);

    useEffect(() => {
        if (deleted) {
            closeDialog();
        }
    }, [deleted]);

    return (
        <Dialog
            open={isOpen}
            onClose={closeDialog}
            maxWidth={'xs'}
            scroll={'body'}
        >
            <DialogTitle>
                <FormattedMessage
                    id={isSubColor ? "color.delete.sub.title" : "color.delete.title"}
                    values={{ color: color.name }}
                />
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <FormattedMessage id={"color.delete.text"} />
                </DialogContentText>
            </DialogContent>
                <DialogActions>
                    <Button color={"info"} simple onClick={closeDialog} disabled={loading}>
                        <FormattedMessage id={"color.delete.cancel"} />
                    </Button>
                    <Button
                        round
                        color={"danger"}
                        type="submit"
                        disabled={loading}
                        onClick={() => props.deleteColor(color)}
                    >
                        <FormattedMessage id={"color.delete.submit"} />
                        {loading && <Loader />}
                    </Button>
                </DialogActions>
        </Dialog>
    )
}

const mapStateToProps = state => {
    return {
        loading: state.color.deleteColor.loading,
        deleted: state.color.deleteColor.deleted,
    }
};

const mapDispatchToProps = dispatch => ({
    deleteColor: (item) => dispatch(deleteColor(item)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DeleteDialog);
