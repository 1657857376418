import React, { Component } from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {FormattedMessage, injectIntl} from "react-intl";
import classNames from "classnames";
import {BeatLoader} from "react-spinners";

// @mui/material components
import {
    Accordion, AccordionDetails, AccordionSummary,
    Fab,
    Tooltip
} from "@mui/material";
import {AddRounded, ExpandMore} from "@mui/icons-material";

// components
import MiniCardDetails from "./miniCardDetails";

// styles
import {primaryColor} from "assets/jss/main";
import miniCardExpansionStyle from "assets/jss/views/product/miniCardExpansionStyle";

// helpers
import {getFirstProductImage} from "utils/getFirstProductImage";
import {getTranslation} from "../../../../../domain/helpers/translations";
import {withStyles} from "@mui/styles";

class MiniCardExpansion extends Component {

    groupByColor = (variations) => {
        let groups = {};
        let otherGroup = [];

        Object.keys(variations).map((key) => {
            const item = variations[key];
            if (!item.color) {
                return otherGroup.unshift(item);
            } else {
                if (getTranslation(item.color).name in groups) {
                    return groups[getTranslation(item.color).name].unshift(item);
                } else {
                    return groups[getTranslation(item.color).name] = [item];
                }
            }
        });

        if (otherGroup.length) {
            groups[this.props.intl.formatMessage({id: "product.show.variations.colorGroup"})] = otherGroup;
        }

        return groups;
    };

    getMiniCardExpansionStyle = (item) => {
        const {classes, currentType, currentVariation, disabled} = this.props;

        let className;

        if (currentType && currentType === "variation" && currentVariation.id === item.id) {
            className = classNames({
                [classes.accordionDetailsItem]: true,
                [classes.accordionDetailsItemSelected]: true
            });
        } else {
            className = classNames({
                [classes.accordionDetailsItem]: true
            });
        }

        if (!!disabled) {
            className += ' ' + classNames({
                [classes.variationItemDisabled]: true,
            });
        }

        return className;
    }

    render() {
        const { classes, onVariationChange, data, variationData, disabled } = this.props;

        const groupData = ( data ) ? this.groupByColor(data) : undefined;

        return (
            <div className={classes.container}>
                <div className={classes.cardHeader}>
                    <span className={classes.cardHeaderTitle}>
                        {this.props.intl.formatMessage({id: "product.show.variations"})}
                    </span>
                    {!groupData &&
                    <div className={classes.BeatLoader}>
                        <BeatLoader
                            sizeUnit={"px"}
                            size={12}
                            color={primaryColor}
                            loading={true}
                        />
                    </div>
                    }
                    <Fab
                        color={"primary"}
                        onClick={() => this.props.scrollToCreateVariationForm()}
                        className={classes.addVariationButton}
                    >
                        <Tooltip title={<FormattedMessage id={"product.show.tooltip.add.variation"}/>}>
                            <AddRounded fontSize={"large"}/>
                        </Tooltip>
                    </Fab>
                </div>
                <div>
                    {!!groupData && (Object.keys(groupData).map((key) => {
                        let isSelected;

                        for (let i = 0; i < Object.keys(groupData[key]).length; i++) {
                            if (groupData[key][i].sku === variationData.sku) {
                                isSelected = true;
                                break;
                            }
                        }

                        return (
                            <Accordion
                                key={key}
                                className={classes.accordion}
                                classes={{expanded: classes.accordionExpanded}}
                                defaultExpanded={isSelected}
                            >
                                <AccordionSummary className={classes.accordionSummary} expandIcon={<ExpandMore />}>
                                    <div className={classes.variationGroupHeader}>
                                        <div>
                                            <img
                                                className={classes.image}
                                                src={getFirstProductImage(groupData[key][0].pictures)}
                                                alt=""
                                            />
                                            <span>{key}</span>
                                        </div>
                                    </div>

                                </AccordionSummary>
                                <AccordionDetails className={classes.accordionDetails}>
                                    {Array.isArray(groupData[key]) && groupData[key].map(item => {
                                        const isSelected = item.sku === variationData.sku;
                                        return (
                                            <div className={this.getMiniCardExpansionStyle(item, disabled)} key={key + item["@id"]}>
                                                <MiniCardDetails
                                                    title={{label: item.size ? item.size.name : ""}}
                                                    onClick={() => {
                                                        if(!disabled) {
                                                            onVariationChange(item.sku);
                                                        }
                                                    }}
                                                    isSelected={isSelected}
                                                    selectVariation={() => onVariationChange(item.sku)}
                                                    leftTop={{label: this.props.intl.formatMessage({id: "retailer.product.list.label.eshopid"}), value: item.eshopId || this.props.intl.formatMessage({id: "product.show.attribute.na"})}}
                                                    leftMiddle={{label: this.props.intl.formatMessage({id: "product.show.attribute.variation.sku"}), value: item.sku || this.props.intl.formatMessage({id: "product.show.attribute.na"})}}
                                                    rightTop={{label: this.props.intl.formatMessage({id: "product.show.attribute.stock"}), value: item.stock || 0}}
                                                    leftBottom={{label: this.props.intl.formatMessage({id: "product.show.attribute.size"}), value: item.size ? getTranslation(item.size).name : null}}
                                                />
                                            </div>
                                        )
                                    })}
                                </AccordionDetails>
                            </Accordion>
                        )
                    }))}
                </div>
            </div>
        );
    }
}

MiniCardExpansion.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
    onVariationChange: PropTypes.func.isRequired
};

const mapStateToProps = state => {
    const {retrieved} = state.product.show;
    const {organizations, member} = state.authentication;

    return {retrieved, organizations, member};
};

export default connect(
    mapStateToProps,
)(withStyles(miniCardExpansionStyle)(injectIntl(MiniCardExpansion)));
