import React from "react"
import PropTypes from "prop-types";

import LineItemRow from "../../LineItemRow";
import ShipmentHeader from "./ShipmentHeader";

import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

export default function ShipmentTable({shipment, incrementalNumber}) {
    return (
        <>
            <TableRow>
                <TableCell
                    colSpan={9}
                    sx={{ padding: 0 }}
                >
                    <ShipmentHeader
                        incrementalNumber={incrementalNumber}
                        shipment={shipment.shipmentData}
                        lineItems={shipment.lineItems}
                    />
                </TableCell>
            </TableRow>
            {shipment.lineItems.map((lineItem, index) => {
                return <LineItemRow lineItem={lineItem} key={index} />
            })}
        </>
    )
}

ShipmentTable.propTypes = {
    shipment: PropTypes.object.isRequired
};
