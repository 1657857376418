import {
    container,
    defaultFont,
    primaryColor,
    infoColor,
    grayColor,
    blackMagicColor,
} from "../../main";


const detailStyle = theme => ({
    containerWithSidebar: {
        [theme.breakpoints.up("md")]: {
            marginLeft: "240px",
        }
    },
    container: {
        ...container,
        ...defaultFont
    },
    header: {
        width: "100%",
        height: "15%",
        marginTop: "20px",
        maxHeight: "200px",
        display: "flex",
    },
    attributeQuoteButton: {
        cursor: "pointer",
    },
    attributeQuote: {
        width: "30px",
        height: "30px",
        position: "relative",
        top: "8px",
        marginRight: "8px",
    },
    downloadPdf: {
        width: "23px",
        height: "25px",
        marginLeft: "5px",
        color: primaryColor,
        borderBottom: "solid 2px " + primaryColor,
        cursor: "pointer",
    },
    state: {
        display: "inline",
        minWidth: "150px",
        height: "15px",
        borderRadius: "4px",
        padding: "5px",
        marginLeft: "10px",
    },
    datepicker: {
        flex: "1",
        maxWidth: "430px",
    },

    orderActions: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
    },
    validateAction: {
        color: "#FFFFFF",
        fontSize: "40px",
    },
    cancelAction: {
        color: "#FFFFFF",
        fontSize: "40px",
    },
    editAction: {
        color: "#000000",
        fontSize: "40px",
        width: "28px",
    },
    validationButton: {
        margin: "0 5px",
    },
    cancelButton: {
        marginRight: "5px",
    },
    editButton: {
        marginLeft: "5px",
    },
    processingLoader: {
        width: "25px !important",
        height: "25px !important",
        marginLeft: "10px",
    },
    warningForceCustomerValidation: {
        marginRight: "10px",
    },
    alignFlexCenter: {
        display: "flex",
        alignItems: "center"
    },

    // Grid style
    cardBody: {
        paddingTop: 0,
    },
    cardHeader: {
        paddingBottom: 0,
        borderBottom: "1px solid #eee",
        textTransform: "unset",
        fontWeight: "400 !important",
    },
    cardHeaderAddress: {
        fontWeight: "300 !important",
    },
    headerRight: {
        textAlign: "right",
    },
    table: {
        marginBottom: "0",
        borderSpacing: "0",
        borderCollapse: "collapse",
    },
    tableHeadCell: {
        ...defaultFont,
        color: primaryColor,
        fontSize: "1em",
        fontWeight: "400 !important"
    },
    tableRow: {
        cursor: "pointer",
    },
    tableFooter: {
        fontSize: "1rem",
        color: blackMagicColor,
    },
    highlight: {
        fontWeight: "bold",
        color: primaryColor,
    },
    primary: {
        color: primaryColor,
    },
    secondary: {
        color: grayColor,
    },
    smallButton: {
        minWidth: "24px",
    },
    brand: {
        textTransform: "uppercase",
    },
    tableSubContent: {
        display: "block",
        fontSize: "80%",
        color: blackMagicColor,
    },
    tableTableProductImage: {
        width: "70px",
    },
    originalPrice: {
        paddingLeft: "11px",
    },
    formField: {
        width: "100px",
        borderColor: primaryColor,
        borderWidth: "1px",
        height: "42px",
    },
    formFieldContainer: {
        margin: "0 0 10px !important",
    },
    formFieldDisplay: {
        border: "1px solid #eee",
        padding: "10px",
        width: "100px",
        height: "42px",
        margin: "0 0 10px !important",
        borderRadius: "4px",
        overflowX: "hidden"
    },
    tableCellFooterTotal: {
        paddingRight: "2px",
    },
    tableFooterRowTotal: {
        borderBottom: "none",
    },
    lineItemLineActions: {
        minWidth: "180px",
    },
    openNewModalButton: {
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
    },
    openNewModalIcon: {
        marginRight: "8px",
        color: primaryColor,
    },
    /* MODALS */
    dialogDeleteLineActions: {
        justifyContent: "center",
    },
    submitLoading: {
        marginLeft: "10px",
        color: "#FFFFFF"
    },
    addNoteContainer: {
        display: "flex",
        marginRight: "20px",
        marginBottom: "20px"
    },
    addNote: {
        marginLeft: "auto",
        fontSize: "20px",
        color: primaryColor,
        cursor: "pointer"
    },
    historyCard: {
        marginBottom: "25px"
    },
    historyData: {
        height: "100%",
        fontWeight: "bold",
        border: "1px solid " + primaryColor,
        borderRadius: "5px",
        paddingLeft: "15px",
        paddingRight: "15px",
        fontSize: "1rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    historyStates: {
        height: "100%",
        width: "auto",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginRight: "20px"
    },
    historyState: {
        display: "inline",
        minWidth: "100px",
        borderRadius: "4px",
        padding: "5px",
        marginLeft: "10px",
        textAlign: "center"
    },
    historyBoldBlue: {
        fontWeight: "bold",
        color: primaryColor
    },
    oldData: {
        textDecoration: "line-through",
    },
    newData: {
        color: primaryColor,
    },
    historyDataArrow: {
        marginLeft: "25px",
        marginRight: "25px",
    },
    cardContent: {
        paddingLeft: "3em",
    },
    inputUnitPriceHT: {
        marginTop: "10px",
    },
    inputUnitPriceTTC: {
        marginTop: "-5px",
        marginBottom: "-5px",
    },
    inputUnitPriceTTCInEditView: {
        paddingLeft: "11px",
    },
    quantityPopover: {
        padding: "15px",
    },
    quantityPopoverButtons: {
        display: "flex",
        justifyContent: "end",
        padding: "0 15px 15px",
    },
    noWrap: {
        whiteSpace: "nowrap",
    },
    assign: {
        borderRadius: "20px",
        backgroundColor: infoColor,
        textTransform: 'none',
        marginBottom: '14px',
        marginLeft: '12px',
    },
});

export default detailStyle;
