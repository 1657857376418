import React, {Component} from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import * as Cookies from "js-cookie";
import {Link} from "react-router-dom";

// @mui/material components
import {TextField} from "@mui/material";

import {ArrowBackIos} from "@mui/icons-material";

// core components
import BarcodeScanner from "../../../../components/barcodeScanner";
import Card from "../../../../components/card/card";
import CardBody from "../../../../components/card/cardBody";
import CardHeader from "../../../../components/card/cardHeader";
import GridContainer from "../../../../components/grid/gridContainer";
import GridItem from "../../../../components/grid/gridItem";
import MovementSelect from "./components/movementSelect";
import Notification from "./components/notification";

// styles
import indexStyle from "../../../../assets/jss/views/stockeasy/product/stock/index";
import Button from "../../../../components/button/button";
import Loader from "../components/loader";
import {withStyles} from "@mui/styles";

// action
import {list, reset as resetList} from "../../../../actions/variation/list";
import {update, reset as resetUpdate} from "../../../../actions/variation/update";

// utils
import {UserAgentUtils} from "../../../../utils/useragent";

class Stock extends Component {
    retailerId = null;

    constructor(props) {
        super(props);

        const path = window.location.pathname;
        const regex = /\/stockeasy\/retailers\/(.*)\/product\/stock/g;
        const matches = regex.exec(path);

        if (matches.length < 1) {
            window.location.href = '/'; // retailer is mandatory in the url
        }

        this.retailerId = matches[1];

        this.state = {
            barcode: '',
            variationName: null,
            isDesktop: true,
            webcam: false,
            movementAction: Cookies.get("stockEasyMovement") ?? 'decrement',
            variationNotFound: false,
        }
    }

    componentDidMount() {
        if (UserAgentUtils.isMobileOrTablet()) {
            this.showWebCam();
        } else {
            this.setState({ isDesktop: true})
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (this.props.retrieved !== nextProps.retrieved) {
            if (nextProps.retrieved["hydra:member"] && nextProps.retrieved["hydra:member"][0]) {
                this.handleUpdateStock(nextProps.retrieved["hydra:member"][0]);
                this.setState({variationName: nextProps.retrieved["hydra:member"][0].name})
            } else {
                this.setState({ variationNotFound: true})
            }
        }

        if (this.props.updated !== nextProps.updated && nextProps.updated !== null) {
            setTimeout(() => {
               this.props.resetUpdate()
            }, 4000);
        }

    }

    handleUpdateStock(variation) {
        let stock = variation.stock;

        if (this.state.movementAction === 'increment') {
            stock += 1
        } else if (this.state.movementAction === 'decrement') {
            stock = stock > 0 ? stock - 1 : 0;
        }

        this.props.update(variation, {stock: stock})
    }

    handleSearchVariation() {
        this.props.resetUpdate();

        this.setState({ variationNotFound: false});

        this.props.list(`barcode=${this.state.barcode}&retailer.id=${this.retailerId}`)
    }

    showWebCam() {
        this.setState({
            isDesktop: false,
            webcam: true,
        })
    }

    setBarcode(barcode) {
        this.setState({ barcode: barcode })

        // if we are on webcam view we directly search the variation
        if (
            this.state.webcam
            // multiple check to prevent update in chain
            && !this.props.retrieveLoading
            && !this.props.updateLoading
            && this.props.updated === null
        ) {
            this.handleSearchVariation();
        }
    }

    handleChangeMovementAction(value) {
        this.props.resetUpdate();

        this.setState({
            movementAction: value
        })
    }

    showBarcodeScanner() {
        if (!UserAgentUtils.isMobileOrTablet()) {
              return;
        }

        if (this.state.webcam === false) {
            return;
        }

        return (
            <BarcodeScanner
                onBarcodeFound={(result) => {
                    this.setBarcode(result);
                }}
                onExit={() => {
                    // On click on exit button we show the popup
                    this.setState({webcam: false})
                }}
                allowLoopScanning={true}
                browserScanner={true}
            />
        );
    }

    renderNotification() {
        if (this.props.updated) {
            return (
                <GridContainer justifyContent="flex-end">
                    <GridItem xs={12} sm={6} lg={6}>
                        <Notification
                            movementAction={this.state.movementAction}
                            variationName={this.state.variationName}
                        />
                    </GridItem>
                </GridContainer>
            )
        }
    }

    render() {
        const {classes, intl} = this.props;

        if (!this.state.webcam) {
            return (
                <div className={classes.containerWithSidebar}>
                    <div className={classes.container}>
                        <GridContainer>
                            <GridItem xs={12}>
                                <Card className={classes.card}>
                                    <CardHeader color="info">
                                        <GridContainer>
                                            <GridItem xs={12}>
                                                <h3><FormattedMessage id={"stockeasy.manage.stock.title"}/></h3>
                                            </GridItem>
                                        </GridContainer>
                                    </CardHeader>
                                    <CardBody>
                                        <GridContainer spacing={3} direction="column" justifyContent="center">
                                            <GridItem xs={12} sm={6}>
                                                <MovementSelect
                                                    customOnChange={(data) => this.handleChangeMovementAction(data.value)}
                                                />
                                            </GridItem>
                                            <GridItem xs={12} sm={6}>
                                                <GridContainer>
                                                    <GridItem xs={12}>
                                                        <label className={classes.fieldLabel}>
                                                            <FormattedMessage id={"stockeasy.manage.stock.barcode.input.label"}/>
                                                        </label>
                                                        <TextField
                                                            variant="standard"
                                                            autoFocus
                                                            margin="dense"
                                                            id="barcode"
                                                            placeholder={this.props.intl.formatMessage({id: "stockeasy.manage.stock.barcode.input.placeholder"})}
                                                            type="text"
                                                            fullWidth
                                                            value={this.state.barcode}
                                                            onChange={(e) => this.setBarcode(e.target.value)}
                                                            disabled={this.props.retrieveLoading} />
                                                    </GridItem>
                                                </GridContainer>
                                            </GridItem>
                                            <GridItem xs={12} className={classes.buttonContainer}>
                                                <Button
                                                    color={"info"}
                                                    simple
                                                    component={Link}
                                                    to={`/stockeasy/show/retailers/${this.retailerId}/products/${encodeURIComponent(`retailer.id=${this.retailerId}&order[translations.name]=asc&itemsPerPage=10&page=1`)}`}
                                                >
                                                    <ArrowBackIos />
                                                    <FormattedMessage id={"product.form.back"} />
                                                </Button>
                                                <Button
                                                    round
                                                    color={"success"}
                                                    onClick={() => this.handleSearchVariation()}
                                                    disabled={this.props.retrieveLoading || this.props.updateLoading}
                                                >
                                                    {(this.props.retrieveLoading || this.props.updateLoading)
                                                        ? <Loader/>
                                                        : intl.formatMessage({id: "stockeasy.barcode.dialog.button"})
                                                    }
                                                </Button>
                                            </GridItem>
                                            <GridItem xs={12}>
                                                {this.state.variationNotFound && (
                                                    <span className={classes.error}>
                                                        <FormattedMessage id={"stockeasy.barcode.dialog.error"}/>
                                                    </span>
                                                )}
                                            </GridItem>
                                        </GridContainer>
                                    </CardBody>
                                </Card>
                            </GridItem>
                        </GridContainer>
                        {this.renderNotification()}
                    </div>
                </div>
            );
        } else {
            // webcam view
            return (
                <div>
                    {this.showBarcodeScanner()}
                    <div className={classes.movementSelectWebcamContainer}>
                        <MovementSelect customOnChange={(data) => this.handleChangeMovementAction(data.value)} />
                    </div>
                    <div className={classes.notificationWebcamContainer}>
                        {this.renderNotification()}
                    </div>
                </div>
            )
        }
    }
}


const mapStateToProps = state => {
    return {
        retrieved: state.variation.list.retrieved,
        retrieveLoading: state.variation.list.loading,
        updated: state.variation.update.updateSuccess,
        updateLoading: state.variation.update.updateLoading
    };
};

const mapDispatchToProps = dispatch => ({
    list: (params) => dispatch(list(params)),
    update: (item, values) => dispatch(update(item, values)),
    resetUpdate: () => dispatch(resetUpdate()),
    resetList: () => dispatch(resetList())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(indexStyle)(injectIntl(Stock)));
