import {useEffect, useState} from "react";
import {fetch} from "utils/dataAccess";
import SimpleFilterPanel from "views/marketplace/merchandising/filter/simpleFilterPanel";

const Filter = (tabTitle, tabContent) => { return { tabTitle: tabTitle, tabContent: tabContent } }

export default function useGenders() {

    const [gendersFilters, setGendersFilters] = useState(null);
    const [gendersLoaded, setGendersLoaded] = useState(false);

    useEffect(() => {
        fetch("/genders?pagination=false&draft=false").then(
            response => {
                response.json().then(retrieved => {
                    setGendersFilters(
                        new Filter(
                            "genders",
                            {
                                component: SimpleFilterPanel,
                                items: retrieved['hydra:member'],
                            }
                        )
                    );

                    setGendersLoaded(true);
                });
            }
        );
    }, []);

    return {
        gendersLoaded,
        gendersFilters
    };
}
