const priceChartStyle = theme => ({
    container: {
        padding: "10px",
    },
    datePicker: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "20px",
        flexWrap: "wrap",
    },
    beatLoader: {
        width: "100%",
        textAlign: "center",
        marginTop: "40px",
        marginBottom: "40px",
    }
});

export default priceChartStyle;
