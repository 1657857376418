import DrawerDashboard from "../../../components/sidebar/lineItems/DrawerDashboard";
import {ListItem, ListItemIcon, ListItemText} from "@mui/material";
import {Link} from "react-router-dom";
import {DirectionsRun} from "@mui/icons-material";
import {FormattedMessage} from "react-intl";
import {Authorizations, hasAuthorization} from "../../../utils/authorizations";
import DrawerConfiguration from "../../../components/sidebar/lineItems/DrawerConfiguration";
import DrawerHelp from "../../../components/sidebar/lineItems/DrawerHelp";
import React, {Component} from "react";
import DrawerPwa from "../../../components/sidebar/lineItems/DrawerPwa";
import DrawerRetailerProfiles from "../../../components/sidebar/lineItems/DrawerRetailerProfiles";

class StatisticsMenu extends Component {
    render() {
        const {authorizations, classes, match, dashboardLink} = this.props;

        const isInDashboardPath = match.path === "/statistics";

        return (
            <div>
                <DrawerDashboard
                    classes={classes}
                    dashboardLink={dashboardLink}
                    isInDashboardPath={isInDashboardPath}
                />

                {hasAuthorization(authorizations, Authorizations.STATISTICS_OPE) &&
                    <ListItem
                        button
                        className={classes.listItem}
                        classes={{selected: classes.selected}}
                        component={Link}
                        to={`/statistics/ope`}
                        key={"statsOpe"}
                        selected={match.path.startsWith('/statistics/ope')}
                    >
                        <ListItemIcon>
                            <DirectionsRun color={match.path.startsWith("/statistics/ope") ? "primary" : "secondary"} />
                        </ListItemIcon>
                        <ListItemText
                            primaryTypographyProps={{color: match.path.startsWith('/statistics/ope') ? "primary" : "secondary"}}
                            primary={<FormattedMessage id={"sidebar.item.ope"}/>}
                        />
                    </ListItem>
                }

                <DrawerConfiguration
                    classes={classes}
                    match={match}
                    authorizations={authorizations}
                />

                {(hasAuthorization(authorizations, Authorizations.OTHER_HELPDESK_MERCHAND) || hasAuthorization(authorizations, Authorizations.OTHER_HELPDESK_CLIENT)) &&
                    <DrawerHelp
                        classes={classes}
                        authorizations={authorizations}
                    />
                }
                <DrawerRetailerProfiles classes={classes} />
                <DrawerPwa classes={classes} />
            </div>
        )
    }
}

export default StatisticsMenu;
