import {dangerColor, primaryColor} from "../../main";

const fieldStyle = theme => ({
    fieldError: {
        color: dangerColor,
    },
    fieldInvalid: {
        border: '2px solid '+dangerColor + ' !important',
    },
    field: {
        width: '100%',
        display: 'block',
        borderRadius: 4,
        position: "relative",
        backgroundColor: 'white',
        border: "2px solid #eee",
        textAlign: "left",
        fontSize: 14,
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        fontWeight: "400",
        lineHeight: "1.42857",
        padding: "10px 12px",
        opacity: "1",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        "&:focus": {
            borderRadius: 4,
            borderColor: primaryColor,
            boxShadow: primaryColor
        },
        color: "#495057",
        "&,&::placeholder": {
            textAlign: "left",
            fontSize: "14px",
            fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
            fontWeight: "400",
            lineHeight: "1.42857",
            opacity: "1"
        },
        "&::placeholder": {
            color: "#AAAAAA"
        },
        "&:disabled": {
            backgroundColor: "#eee",
            color: "#000"
        }
    },
});

export default fieldStyle;
