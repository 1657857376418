import React, {Component} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import Sidebar from "../../components/sidebar/sidebar";

export default function (ComposedComponent) {
    class RequireAuthentication extends Component {

        UNSAFE_componentWillMount() {
            if (!this.props.token) {
                this.props.history.push("/logout");
            }
        }

        UNSAFE_componentWillUpdate(nextProps) {
            if (!nextProps.token) {
                nextProps.history.push("/logout");
            }
        }

        PropTypes = {
            router: PropTypes.object,
        };

        render() {
            return <div><Sidebar {...this.props}/><ComposedComponent {...this.props} /></div>
        }
    }

    const mapStateToProps = (state) => {
        return {
            token: state.authentication.token,
        };
    };

    return connect(mapStateToProps)(RequireAuthentication);
}
