import React, {Component} from "react";
import {ITEM_PER_PAGE} from "../../../config/_pagination";
import {marketPlaceOffer, stockEasyOffer} from "../../../domain/enum/offers";

// Components
import StockEasyRetailersDrawer from "../../../domain/components/sidebar/drawers/StockEasyRetailersDrawer";
import MarketPlaceRetailersDrawer from "../../../domain/components/sidebar/drawers/MarketPlaceRetailersDrawer";

class DrawerRetailers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            productTabOpen: this.props.match.path.startsWith("/retailers/show/:id/feeds/") || this.props.match.path.startsWith("/retailers/show/:id/products/") || this.props.match.path.startsWith("/retailers/show/:id/variations/"),
            itemsPerPage: this.props.itemsPerPage ? this.props.itemsPerPage : ITEM_PER_PAGE,
        };
    }

    getRetailersLinkByOffer = () => {
        return "/retailers/" + encodeURIComponent("order[pushActive]=desc&order[name]=asc&pagination=true&itemsPerPage=" + 500 + "&page=1");
    }

    renderDrawer = (offer) => {
        const {authorizations, classes, match, retailerRetrieved, itemsPerPage, productTabOpen, member} = this.props;

        if (offer === stockEasyOffer) {
            return (
                <StockEasyRetailersDrawer
                    authorizations = {authorizations}
                    match = {match}
                    classes = {classes}
                    offer={stockEasyOffer}
                    member={member}
                />
            )
        } else {
            return (
                <MarketPlaceRetailersDrawer
                    itemsPerPage = {itemsPerPage}
                    productTabOpen = {productTabOpen}
                    authorizations = {authorizations}
                    match = {match}
                    retailerRetrieved = {retailerRetrieved}
                    classes = {classes}
                    handleProductTabChange={this.props.handleProductTabChange}
                    offer = {marketPlaceOffer}
                />
            )
        }
    }

    render() {return (this.renderDrawer(this.props.offer))}
}

export default DrawerRetailers;
