import {Popover} from "@mui/material";
import {FormattedMessage} from "react-intl";
import FormattedNumberWithCurrency
    from "../../../../components/formattedNumberWithCurrencies/formattedNumberWithCurrency";
import Button from "../../../../components/button/button";
import React, {useContext} from "react";
import {withStyles} from "@mui/styles";
import detailStyle from "../../../../assets/jss/views/quote/detailStyle";
import {getQuantityPromotionByQuantity} from "./grid/gridLine";
import {StoreContext} from "../../../../contexts/storeContext";

const QuantityPromotionPopover = ({line, values, classes, quantityPromotionPopoverDisplayed, quantityInputRef, closeQuantityPromotionPopover }) => {
    const store = useContext(StoreContext);
    const currency = store.getState()?.currentOrganization?.retrieved?.currency;
    const quantityPromotion = getQuantityPromotionByQuantity(line, values.quantity);
    return (
        <Popover
            open={quantityPromotionPopoverDisplayed}
            anchorEl={quantityInputRef.current}
            onClose={() => closeQuantityPromotionPopover(values)}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
        >
            {quantityPromotion && (
                <div className={classes.quantityPopover}>
                    <FormattedMessage id={"picking.quote.detail.gridContent.quantityPromotion.message"} />
                    <div>
                        <strong><FormattedNumberWithCurrency value={quantityPromotion.discountedPrice} currency={currency} decimalScale={2} /></strong>
                        <FormattedMessage id={"picking.quote.detail.gridContent.quantityPromotion.fromNUnitsMessage"} values={{minQuantity: quantityPromotion?.minQuantity ?? 0}} />
                    </div>
                </div>
            )}
            {!quantityPromotion && (
                <div className={classes.quantityPopover}>
                    <FormattedMessage id={"picking.quote.detail.gridContent.quantityPromotion.resetMessage"} />
                </div>
            )}
            <div className={classes.quantityPopoverButtons}>
                <Button
                    color={"transparent"}
                    onClick={() => closeQuantityPromotionPopover(values)}
                >
                    <FormattedMessage id={"picking.quote.detail.gridContent.quantityPromotion.ignore"} />
                </Button>

                <Button
                    color={"success"}
                    onClick={() => closeQuantityPromotionPopover(values, true)}
                >
                    <FormattedMessage id={"picking.quote.detail.gridContent.quantityPromotion.apply"} />
                </Button>
            </div>
        </Popover>
    );
}

export default withStyles(detailStyle)(QuantityPromotionPopover);
