import React from 'react';
import PropTypes from 'prop-types';

import GalleryIcon from "components/icons/GalleryIcon";

import {black20} from "assets/jss/main";

Banner.propTypes = {
    url: PropTypes.string
};

const style = {
    width: '100%',
    height: '172px',
    borderTopLeftRadius: '16px',
    borderTopRightRadius: '16px',
};

function Banner({url}) {
    return (
        <div style={{ maxHeight: '172px' }}>
            {url ? (
                <img
                    style={{
                        ...style,
                        objectFit: 'cover',
                    }}
                    src={url}
                    alt={"retailer banner"}
                />
            ) : (
                <div style={{
                    ...style,
                    height: '172px',
                    background: black20,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                    <GalleryIcon size={82} />
                </div>
            )}
        </div>
    );
}

export default Banner;
