import React, {Component} from "react";

import {withStyles} from "@mui/styles";

import Container from "./components/container";
import listStyle from "../../assets/jss/views/accounting/listStyle";

class List extends Component {
    render() {
        const { classes } = this.props;

        return (
            <div className={classes.containerWithSidebar}>
                <div className={classes.container}>
                    <Container history={this.props.history}/>
                </div>
            </div>
        );
    }
}

export default withStyles(listStyle)(List);
