import React from 'react';
import PropTypes from "prop-types";

import HistoryNoteAccordion from "./HistoryNoteAccordion";
import HistoryNoteAccordionDetails from "./HistoryNoteAccordionDetails";
import HistoryNoteAccordionSummary from "./HistoryNoteAccordionSummary";

import {QUOTE_NOTE_TYPES} from "../../../../../enums/quoteNoteType";

Index.propTypes = {
    noteType: PropTypes.oneOf(QUOTE_NOTE_TYPES).isRequired,
    children: PropTypes.any.isRequired,
};

export default function Index({noteType, children}) {
    return !!noteType && !!children && (
        <HistoryNoteAccordion>
            <HistoryNoteAccordionSummary noteType={noteType} />
            <HistoryNoteAccordionDetails>
                {children}
            </HistoryNoteAccordionDetails>
       </HistoryNoteAccordion>
    );
}
