import React from 'react'
import {makeStyles} from '@mui/styles'

import {borderGrayColor} from '../../../../../../../assets/jss/main'

const useStyles = makeStyles(theme => ({
    selectorTitle: {
        display: 'flex',
        padding: '1.5rem 1rem',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexShrink: '0',
        border: `1px solid ${borderGrayColor}`,
        borderBottom: 0,
        borderTopLeftRadius: '8px',
        borderTopRightRadius: '8px',
        background: 'white',
        fontSize: '1rem',
        '& svg': {
            cursor: 'pointer',
        },
        '& span': {
            width: '100%',
            textAlign: 'center',
            color: '#474747',
            fontWeight: '600',
        },
    },
}))

export default function SelectorTitle(props) {
    const classes = useStyles()

    return (
        <div className={classes.selectorTitle}>
            {props.children}
        </div>
    )
}
