import React from 'react';

import CardActions from "@mui/material/CardActions";

export default function NoteCardActions(props) {
    return (
        <CardActions sx={{ padding: 0 }}>
            {props.children}
        </CardActions>
    );
}
