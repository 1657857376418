import {
    container,
    darkGrayColor,
    defaultFont,
    irisBlueColor,
    lightGrayColor,
    mantisGreenColor,
    primaryColor,
    terraCottaColor,
    thunderGrayColor,
    yellowColor
} from "../../../main";

const dashboardStyle = (theme) => ({
    containerWithSidebar: {
        [theme.breakpoints.up("md")]: {
            marginLeft: "240px",
        }
    },
    container: {
        ...container,
        ...defaultFont
    },

    headerContainer: {
        display: "flex",
        height: "100px",
        flexDirection: 'row',
        marginTop: '30px',
        borderRadius: '5px',
        backgroundColor: '#fff',
        boxShadow:
            "0 2px 2px 0 rgba(0, 0, 0, 0), 0 3px 1px -2px rgba(0, 0, 0, 0.01), 0 1px 5px 0 rgba(0, 0, 0, 0.13)",
    },
    rightContainer: {
        [theme.breakpoints.up('sm')]: {
            marginLeft: "5px"
        },
    },
    leftContainer: {
        [theme.breakpoints.up('sm')]: {
            marginRight: "5px"
        },
    },
    imageContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#CDDBF9',
        width: "100px",
        borderTopLeftRadius: "5px",
        borderBottomLeftRadius: "5px",
    },
    icon: {
        fontSize: "80px",
        color: "#FFFFFF"
    },
    image: {
        borderRadius: "50%",
        width: "50px",
        height: "50px",
    },
    imgPlaceholder: {
        background: "linear-gradient(60deg, #377dff, #0052cb)",
        color: "#FFFFFF",
        padding: "12px",
        borderRadius: "50%",
        width: "50px",
        height: "50px",
        fontWeight: "bold",
        boxShadow: " 5px 5px 10px -7px rgba(0,0,0,0.96)",
    },
    dataContainer: {
        display: "flex",
        flex: 1,
        justifyContent: "center",
        alignItems: "flex-start",
        flexDirection: "column",
        paddingLeft: "10px",
    },
    dataValue: {
        fontSize: "28px",
        paddingTop: "10px"
    },
    globalDataContainer: {
        display: "flex",
        flex: 1,
        flexDirection: 'row'
    },
    timeBlockContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        backgroundColor: '#CDDBF9',
        height: "300px",
        marginTop: "30px",
        borderRadius: "5px",
        marginLeft:"12px"
    },
    timeBlockHeader: {
        display: 'flex',
        minHeight: "70px",
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingLeft: 10,
        paddingRight: 10,
        color: '#fff',
        borderBottom: '1px solid #fff'
    },
    timeBlockData: {
        display: 'flex',
        flex: 3,
        justifyContent: 'center',
        alignItems: 'center',
        color: '#fff',
    },
    timeCircle: {
        display: 'flex',
        height: "160px",
        width: '160px',
        borderRadius: "80px",
        border: "1px dashed #fff",
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
    },
    labelMonthTurnover: {
        fontSize: "16px",
        fontWeight: "light",
        color: "#8FCAF2"
    },
    AverageProcessingTime: {
        fontSize: "24px",
        fontWeight: "bold",
        color: "#fff"
    },
    statisticBlockContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        backgroundColor: '#FFF',
        marginTop: "20px",
        borderRadius: "5px",
        marginLeft:"12px",
        boxShadow:
            "0 2px 2px 0 rgba(0, 0, 0, 0), 0 3px 1px -2px rgba(0, 0, 0, 0.01), 0 1px 5px 0 rgba(0, 0, 0, 0.13)",
    },
    statisticBlockHeader: {
        display: 'flex',
        minHeight: "70px",
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingLeft: 10,
        paddingRight: 10,
        color: '#000',
        borderBottom: '1px solid #ddd'
    },
    statisticBlockData: {
        display: 'flex',
        flex: 3,
        padding: "10px",
        justifyContent: 'center',
        alignItems: 'center',
        color: '#000',
        "& canvas": {
            zIndex: 1,
        }
    },
    statisticFooter: {
        display: 'flex',
        flex: 1.5,
        paddingTop: 5,
        paddingBottom: 5,
        borderTop: '1px solid #ddd',
        flexDirection: 'row',
    },
    footerSubBlock: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        '&:last-child': {
            paddingRight: 0,
        },
    },
    ordersCountData: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        flexDirection: 'column'
    },
    ordersCountNumber: {
        fontSize: "38px",
        fontWeight: "bold",
        color: "#000",
    },
    ordersCountLabel: {
        fontSize: "12px",
        fontWeight: "bold",
        color: "#000",
    },
    legendContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        width: '100%',
        padding: '5px 0px',
        alignItems:'center',
        borderBottom: '1px solid ' + lightGrayColor,
        color: '#0052cb',
        fontSize: '18px',
        '&:last-child': {
            borderBottom: 0,
        },
    },
    legendCircle: {
        height: "15px",
        width: "15px",
        borderRadius: "7.5px",
    },
    bestRunnerBlockContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        backgroundColor: '#FFF',
        height: "200px",
        marginTop: "20px",
        borderRadius: "5px",
        marginLeft:"12px",
        boxShadow:
            "0 2px 2px 0 rgba(0, 0, 0, 0), 0 3px 1px -2px rgba(0, 0, 0, 0.01), 0 1px 5px 0 rgba(0, 0, 0, 0.13)",
    },
    bestRunnerBlockData: {
        display: 'flex',
        flex: 4,
        height: "100%",
        backgroundColor: '#FFF',
        flexDirection: 'row',
        position: "relative"
    },
    bestRunnerContainer: {
        height: "100%",
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        padding:"10px 0px"
    },
    commonRunnerBar: {
        width: "12px",
        borderRadius: "10px",
        marginLeft: "15px",
        alignSelf: 'flex-end'
    },
    legendRunnerContainer: {
        height: "100%",
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'flex-end'
    },
    legendItemContainer: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom:"10px"
    },
    firstLegendItemBlock:{
       flex:0.5,

    },
    secondLegendItemBlock:{
        display:'flex',
        flex:3,
        flexDirection:'column'
    },
    legendCommandLabel: {
        fontSize: '12px',
        color: '#8FCAF2'
    },
    labelPending:{
        paddingRight:'15px',
        paddingLeft:'15px',

    },
    labelShowMoreCommands:{
        paddingRight:'15px',
        paddingTop:"15px",
        color:primaryColor
    },
    labelLink:{
        color:"inherit",
        textDecoration: "none"
    },
    chip: {
        width: '100%',
        '& .MuiChip-label': {
            overflow: 'visible',
        },
    },
    productCountValue: {
        paddingLeft: 0,
        paddingRight: 0
    },
    pendingChip: {
        backgroundColor: 'rgba(230, 194, 41, 0.2)',
        color: yellowColor,
        fontWeight: 'bold',
    },
    confirmedChip: {
        backgroundColor: 'rgba(120, 188, 97, 0.2)',
        color: mantisGreenColor,
        fontWeight: 'bold',
    },
    toShipChip: {
        backgroundColor: 'rgba(0, 172, 193, 0.2)',
        color: irisBlueColor,
        fontWeight: 'bold',
    },
    canceledChip: {
        backgroundColor: 'rgba(237, 106, 90, 0.2)',
        color: terraCottaColor,
        fontWeight: 'bold',
    },
    reservedChip: {
        backgroundColor: 'rgba(105, 41, 230, 0.2)',
        color: '#976ef5',
        fontWeight: 'bold',
    },
    returningChip: {
        backgroundColor: '#FFE6C9',
        color: '#FF8E5E',
        fontWeight: 'bold',
    },
    returnChip: {
        backgroundColor: lightGrayColor,
        color: darkGrayColor,
        fontWeight: "bold"
    },
    unavailableChip: {
        backgroundColor: 'rgba(74, 74, 73, 0.2)',
        color: thunderGrayColor,
        fontWeight: 'bold',
    },
    returnedChip: {
        backgroundColor: '#FDDDD2',
        color: '#FF823C',
        fontWeight: 'bold',
    },
    beatLoader: {

    },
    "@media (max-width: 480px)": {
        headerContainer: {
            flexDirection: 'column',
            height: "auto",
        },
        imageContainer: {
            width: "100%",
        },
        globalDataContainer: {
            padding: "10px 0 10px 0",
        },
        dataValue: {
            fontSize: "23px",
        }
    },
});

export default dashboardStyle;
