import React, {useContext, useState} from 'react'
import {makeStyles} from '@mui/styles'
import {IconButton} from '@mui/material'
import classNames from 'classnames'
import {FormattedMessage} from 'react-intl'
import PropTypes from 'prop-types'

import {borderGrayColor} from '../../../../../assets/jss/main'
import { ReactComponent as Brush } from 'assets/img/brush.svg'
import { ReactComponent as Vector } from 'assets/img/vector.svg'
import Breadcrumb from '../../fields/components/RecommendedProduct/components/Breadcrumb'
import {getTranslation} from '../../../../../domain/helpers/translations'
import ModalRemoveOverrodeRecommendation from './ModalRemoveOverrodeRecommendation'
import {RecommendationContext} from '../../../context/RecommendationContext'

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        gap: '0.75rem',
        width: '100%',
        paddingBottom: '2rem',
        borderBottom: `1px solid ${borderGrayColor}`,
        '&:last-child': {
            paddingBottom: 0,
            borderBottom: 0,
        },
        '& > div': {
            width: '100%',
        },
    },
    title: {
        marginBottom: '0.75rem',
        color: '#333',
        fontSize: '0.875rem',
        fontWeight: 600,
    },
    fakeSelectInput: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: '#fff',
        border: `1px solid ${borderGrayColor}`,
        borderRadius: '8px',
        padding: '16px',
        fontSize: '0.875rem',
        textAlign: 'left',
        marginTop: '6px',
        width: '100%',
    },
    button: {
        width: '58px',
        height: '58px',
        marginTop: '29px',
        padding: '2px 3px',
        border: `1px solid ${borderGrayColor}`,
        borderRadius: '8px',
        backgroundColor: '#fff',
        '&:hover': {
            backgroundColor: '#FAFAFA',
        },
    },
    removeActive: {
        backgroundColor: '#FF3737',
        borderColor: '#FF3737',
        '&:hover': {
            backgroundColor: '#FF3737',
        },
        '& svg path': {
            stroke: 'white',
        },
    },
}))

export default function OverrodeRecommendation(props) {
    const {recommendation} = props
    const [opened, setOpen] = useState(false)
    const {openRecommendationOverloadModal, setSelectedRecommendation} = useContext(RecommendationContext)
    const recommendedProduct = recommendation.category || recommendation.corner || null
    const classes = useStyles()

    return (
        <div className={classes.container}>
            <div>
                <span className={classes.title}>
                    <FormattedMessage id={'recommendations.overrode_recommendations.overrodeCategory'} />
                </span>
                <div className={classes.fakeSelectInput}>
                    {getTranslation(recommendation.overrodeCategory).name}
                </div>
                <Breadcrumb node={recommendation.overrodeCategory} />
            </div>
            <div>
                <span className={classes.title}>
                    <FormattedMessage id={'recommendations.overrode_recommendations.category'} />
                </span>
                <div className={classes.fakeSelectInput}>
                    {recommendedProduct ? getTranslation(recommendedProduct).name : <FormattedMessage id={"recommendations.form.recommended_product.all_products"} />}
                </div>
                <Breadcrumb node={recommendedProduct} />
            </div>
            <IconButton
                className={classes.button}
                onClick={() => {
                    setSelectedRecommendation(recommendation);
                    openRecommendationOverloadModal(true);
                }}
            >
                <Brush />
            </IconButton>
            <IconButton
                className={classNames(
                    classes.button,
                    { [classes.removeActive]: opened },
                )}
                onClick={() => setOpen(true)}
            >
                <Vector />
            </IconButton>
            <ModalRemoveOverrodeRecommendation
                recommendation={recommendation}
                open={opened}
                onClose={() => setOpen(false)}
            />
        </div>
    )
}

OverrodeRecommendation.propTypes = {
    recommendation: PropTypes.object.isRequired,
}
