import {combineReducers} from "redux";
import show from "./show";
import update from "./update";
import list from "./list";
import massUpdate from "./massUpdate";
import updateLock from "./lock/update";
import createLock from "./lock/create";
import selection from "./selection";

export default combineReducers({update, massUpdate, show, list, updateLock, createLock, selection});
