import React from 'react';
import PropTypes from 'prop-types';

import {BeatLoader} from "react-spinners";

import {primaryColor} from "assets/jss/main";

CardLoader.defaultProps = {
    loading: false
};

CardLoader.propTypes = {
    loading: PropTypes.bool
};

function CardLoader({loading}) {
    return (
        <div style={{
            position: "absolute",
            right: "12px",
            margin: "0 auto",
            top: "12px",
            display: "flex",
            justifyContent: "center"
        }}>
            <BeatLoader
                sizeUnit={"px"}
                size={12}
                color={primaryColor}
                loading={loading}
            />
        </div>
    );
}

export default CardLoader;
