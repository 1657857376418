import React, {Component} from "react";
import PropTypes from "prop-types";
import {FormattedDate, FormattedMessage, FormattedTime, injectIntl} from "react-intl";
import {connect} from "react-redux";
import {BeatLoader, PropagateLoader} from 'react-spinners';
import ImageZoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';

// @mui/material components
import {
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Tooltip,
    Button,
    Fab,
} from "@mui/material";

// @mui/icons-material
import {
    ImageSharp,
    CloseRounded,
    CheckRounded,
    ReportProblem,
} from "@mui/icons-material";

// core components
import FormattedNumberWithCurrency from "../../../components/formattedNumberWithCurrencies/formattedNumberWithCurrency";
import CustomDate from "../../../components/date/date";

// styles
import tableStyle from "assets/jss/views/picking/order/showTableStyle";
import {primaryColor} from "assets/jss/main";
import { ThemeProvider, createTheme, StyledEngineProvider } from '@mui/material/styles';
import {dangerColor, successColor} from 'assets/jss/main';

// Actions
import {
    confirm,
    assign,
    reserve,
    cancellationReason,
} from "actions/lineItem/update";
import {Authorizations, hasAuthorization} from "../../../utils/authorizations";

// Utils
import {getFirstProductImage} from "../../../utils/getFirstProductImage";
import {capitalize} from "../../../utils/capitalize";
import ShippingIcon from "../components/shippingIcon";
import {OrderContext} from "contexts/OrderContext";
import CancellationReasonDialog from "../components/cancellationReasonDialog";
import {LineItemTransitions} from "utils/lineItemTransitions";
import {withStyles} from "@mui/styles";

const theme = createTheme({
    palette: {
        primary: {main: successColor},
        secondary: {main: dangerColor},
    }
});

class OrderTable extends Component {

    constructor(props) {
        super(props);

        this.state = {
            updatedItem: "",
            cancellationReasonDialog: false,
            transitionActions: null,
            selectedItem: null
        };
    };

    static propTypes = {
        loading: PropTypes.bool.isRequired,
        deleteLoading: PropTypes.bool.isRequired,
        deleted: PropTypes.object
    };

    renderStatus = (item) => {
        const {classes} = this.props;

        if (this.props.updateLoadingOrder) {
            return  <BeatLoader
                sizeUnit={"px"}
                size={4}
                color={primaryColor}
                loading={true}
            />
        }

        let spanClass;
        let isReturning = false;
        let dateReturning;
        if ('returning-runner' === item.status || 'returning-retailer' === item.status) {
            spanClass = classes.listReturning;
            isReturning = true;
            item['lineItemActivities'].forEach(function(activity) {
                if (activity.newData === 'returning-runner' || activity.newData === 'returning-retailer') {
                    dateReturning = !dateReturning || (activity.createdAt > dateReturning) ? activity.createdAt : dateReturning;
                }
            });
        } else if ('to-ship' === item.status) {
            spanClass = classes.listToShip;
        } else {
            spanClass = classes[`list${capitalize(item.status)}`];
        }

        return (
            <span className={classes.statusGroup}>
                <span className={spanClass}>
                    <FormattedMessage id={"picking.order.show.table.status." + item.status}/><br/>
                </span>
                { (isReturning && dateReturning) &&
                    <span className={classes.returningDate}>
                        {this.renderDate(dateReturning)}
                    </span>
                }
            </span>
        )
    };

    renderDate = date => {
        const {classes} = this.props;
        if (date) {
            return (
                <div className={classes.date}>
                    <div className={classes.day}>
                        <FormattedDate value={new Date(date)} year={"numeric"} month={"numeric"} day={"numeric"}/> <FormattedTime value={new Date(date)} hour={"numeric"} minute={"numeric"}/>
                    </div>
                </div>
            );
        } else return "-"
    };

    renderCustomer = (order) => {
        const {classes} = this.props;
        return (
            <div className={classes.tableCellColumn + " " + classes.name}>
                <span
                    className={classes.tableCellBold}>{order.customerShippingFirstname || order.customerBillingFirstname} {order.customerShippingLastname || order.customerBillingLastname}</span>
                <span>{order.customerShippingEmail}</span>
                <span>{order.customerShippingPhone}</span>
                <span>{order.customerShippingAddress}</span>
                <span>{order.customerShippingCity} {order.customerShippingCountry}</span>
            </div>
        );
    }

    renderName = (item, variation) => {
        const {classes} = this.props;

        if (variation) {
            return (
                <div className={classes.tableCellColumn + " " + classes.name}>
                    <span className={classes.tableCellBold}>{item.name}</span>
                    <span><FormattedMessage id={"picking.order.show.table.name.ugs"}/> {variation.product.sku}</span>
                    <span><FormattedMessage id={"picking.order.show.table.name.sku"}/> {variation.sku}</span>
                    {variation.barcode &&
                        <span>
                            <FormattedMessage id={"picking.order.show.table.name.ean"}/> {variation.barcode}
                        </span>
                    }
                    <span>
                        <FormattedMessage id={"picking.order.show.table.name.size"}/>
                        {item.size ?
                            <b>{item.size.toUpperCase()}</b> :
                            <FormattedMessage id={"picking.order.show.table.na"}/>
                        }
                    </span>
                    <span>
                        <FormattedMessage id={"picking.order.show.table.name.color"}/>
                        {item.color ?
                            <b>{item.color}</b> :
                            <FormattedMessage id={"picking.order.show.table.na"}/>
                        }
                    </span>
                    <span>
                        <FormattedMessage id={"picking.order.show.table.name.brand"}/>
                        {item.brand ?
                            <b>{capitalize(item.brand.toLowerCase())}</b> :
                            <FormattedMessage id={"picking.order.show.table.na"}/>
                        }
                    </span>
                </div>
            );
        } else {
            return (
                <div className={classes.tableCellColumn + " " + classes.name}>
                    <div>
                        <span className={classes.tableCellBold}>{item.name}</span>
                        <Tooltip
                            className={classes.toolTipNa}
                            title={<FormattedMessage id={"picking.order.show.table.tooltip.na"}/>}
                        >
                            <ReportProblem/>
                        </Tooltip>
                    </div>
                    <span>
                        <FormattedMessage id={"picking.order.show.table.name.ugs"}/>
                        <FormattedMessage id={"picking.order.show.table.na"}/>
                    </span>
                    <span>
                        <FormattedMessage id={"picking.order.show.table.name.sku"}/>
                        <FormattedMessage id={"picking.order.show.table.na"}/>
                    </span>
                    <span>
                        <FormattedMessage id={"picking.order.show.table.name.size"}/>
                        {item.size ?
                            <b>{item.size.toUpperCase()}</b> :
                            <FormattedMessage id={"picking.order.show.table.na"}/>
                        }
                    </span>
                    <span>
                        <FormattedMessage id={"picking.order.show.table.name.color"}/>
                        {item.color ?
                            <b>{item.color}</b> :
                            <FormattedMessage id={"picking.order.show.table.na"}/>
                        }
                    </span>
                    <span>
                        <FormattedMessage id={"picking.order.show.table.name.brand"}/>
                        {item.brand ?
                            <b>{capitalize(item.brand.toLowerCase())}</b> :
                            <FormattedMessage id={"picking.order.show.table.na"}/>
                        }
                    </span>
                </div>
            );
        }
    };

    renderPrice = (price, initialRecommendedPrice) => {
        const {classes} = this.props;

        if (initialRecommendedPrice && initialRecommendedPrice !== price) {
            return (
                <div className={classes.tableCellColumn}>
                    <span className={classes.tableCellBold}>
                       <FormattedNumberWithCurrency value={price} currency={this.props.currentOrganization?.currency} />
                    </span>
                    <span className={classes.crossedPrice}>
                         <FormattedNumberWithCurrency value={initialRecommendedPrice} currency={this.props.currentOrganization?.currency}/>
                    </span>
                </div>
            )
        } else {
            return (
                <div className={classes.tableCellColumn}>
                    <span className={classes.tableCellBold}>
                         <FormattedNumberWithCurrency value={price} currency={this.props.currentOrganization?.currency} />
                    </span>
                </div>
            )
        }
    }

    renderActions = (item) => {
        const {classes} = this.props;
        const haveRunner = !!item['runner'];
        const isRetailer = hasAuthorization(this.props.authorizations, Authorizations.PICKING_RETAILER);
        const runnerIsTheUser = haveRunner && (`/members/${this.props.member["id"]}` === item['runner']['@id']);
        const statusIsPending = item && item["status"] === "pending";
        const statusIsReserved = item && item["status"] === "reserved";

        if (hasAuthorization(this.props.authorizations, Authorizations.PICKING_READ_ONLY)) {
            return null;
        }

        if (this.props.updateLoadingOrder) {
            return null
        }

        if (this.props.updateLoadingLineItem) {
            return (
                <BeatLoader
                    sizeUnit={"px"}
                    size={4}
                    color={primaryColor}
                    loading={true}
                />
            )
        }

        if (runnerIsTheUser && !isRetailer  && (statusIsPending || statusIsReserved)) {
            return (
                <div className={classes.actionsCell}>
                    <StyledEngineProvider injectFirst>
                        <ThemeProvider theme={theme}>
                            {statusIsPending ?
                                <Fab color="primary" size={'small'} onClick={() => this._handleReserve(item)}>
                                    <Tooltip title={<FormattedMessage id={"picking.order.show.table.action.product.reserve"}/>}>
                                        <CheckRounded classes={{root: classes.validateAction}}/>
                                    </Tooltip>
                                </Fab>
                                :
                                <Fab color="primary" size={'small'} onClick={() => this._handleConfirm(item)}>
                                    <Tooltip title={<FormattedMessage id={"picking.order.show.table.action.product.confirm"}/>}>
                                     <CheckRounded classes={{root: classes.validateAction}}/>
                                    </Tooltip>
                                </Fab>
                            }
                            <Fab
                                color="secondary"
                                size={'small'}
                                onClick={() => {
                                    this.openCancellationDialogByTransitionAndItem(item, [
                                        LineItemTransitions.CANCEL, LineItemTransitions.UNAVAILABLE
                                    ]);
                                }}
                            >
                                <Tooltip title={<FormattedMessage id={"picking.order.show.table.action.product.cancel"}/>}>
                                    <CloseRounded classes={{root: classes.cancelAction}}/>
                                </Tooltip>
                            </Fab>
                        </ThemeProvider>
                    </StyledEngineProvider>
                </div>
            )
        }

        if (isRetailer) {
            return (
                <div className={classes.actionsCell}>
                    <StyledEngineProvider injectFirst>
                        <ThemeProvider theme={theme}>
                            {statusIsPending &&
                                <Fab color="primary" size={'small'} onClick={() => this._handleReserve(item)}>
                                    <Tooltip title={<FormattedMessage id={"picking.order.show.table.action.product.reserve"}/>}>
                                        <CheckRounded classes={{root: classes.validateAction}}/>
                                    </Tooltip>
                                </Fab>
                            }
                            <Fab
                                color="secondary"
                                size={'small'}
                                onClick={() => {
                                    this.openCancellationDialogByTransitionAndItem(item, [
                                        LineItemTransitions.UNAVAILABLE
                                    ]);
                                }}
                            >
                                <Tooltip title={<FormattedMessage id={"picking.order.show.table.action.product.cancel"}/>}>
                                    <CloseRounded classes={{root: classes.cancelAction}}/>
                                </Tooltip>
                            </Fab>
                        </ThemeProvider>
                    </StyledEngineProvider>
                </div>
            )
        }
        if ((item['runner'] == null && statusIsPending) || !isRetailer) {
            return (
                <Button color="primary" variant="contained" onClick={() => this.handleClickAssign(item, this.props.member["id"])}
                        classes={{root: classes.assign}}>
                    <FormattedMessage id={"picking.order.show.header.assignItToMe"}/>
                    <div className={classes.submitLoading}>
                        <BeatLoader
                            sizeUnit={"px"}
                            size={4}
                            color={"#FFF"}
                            loading={this.props.updateLoadingLineItem}
                        />
                    </div>
                </Button>
            )
        }

        return null
    };

    _handleConfirm(item) {
        if (window.confirm(this.props.intl.formatMessage({id: "picking.order.show.table.window.confirm"}))) {
            this.props.confirm(item)
        }
    }

    _handleReserve(item) {
        if (window.confirm(this.props.intl.formatMessage({id: "picking.order.show.table.window.reserve"}))) {
            this.props.reserve(item)
        }
    }

    handleClickAssign(item) {
        this.setState({updatedItem: item["@id"]}, () => {

            this.props.assign(item, {"runner": "/members/" + this.props.member.id})
                .then(() => {
                    this.setState({updatedItem: ""})
                })
        })
    }
    
    confirmCancellationAction(reason, unavailableDays = null) {
        const {selectedItem} = this.state;

        this.props.cancellationReason(selectedItem, reason, unavailableDays);

        this.closeCancellationReasonDialog();
    }

    openCancellationDialogByTransitionAndItem(item, transitionActions) {
        this.setState({
            selectedItem: item,
            transitionActions: transitionActions,
            cancellationReasonDialog: true
        })
    }

    closeCancellationReasonDialog() {
        this.setState({
            cancellationReasonDialog: false,
            selectedItem: null,
            transitionActions: null
        })
    }

    render() {
        const {classes, tableHead, tableData, tableHeaderColor} = this.props;

        return (
            <div className={classes.tableResponsive}>
                {!tableData ?
                    <div className={classes.propagateLoader}>
                        <PropagateLoader
                            sizeUnit={"px"}
                            size={16}
                            color={primaryColor}
                            loading={this.props.loading}
                        />
                    </div> :
                    <div className={classes.beatLoader}>
                        <BeatLoader
                            sizeUnit={"px"}
                            size={12}
                            color={primaryColor}
                            loading={this.props.loading}
                        />
                    </div>
                }

                <Table className={classes.table}>
                    {tableHead !== undefined ? (
                        <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
                            <TableRow>
                                <TableCell padding="checkbox"/>
                                {tableHead.map((prop, key) => {
                                    return (
                                        prop !== null &&
                                        <TableCell
                                            className={classes.tableHeadCell + " " + classes.tableHeadCellXl}
                                            key={key}
                                        >
                                            {prop}
                                        </TableCell>
                                    );
                                })}
                                <TableCell padding="checkbox"/>
                            </TableRow>
                        </TableHead>
                    ) : null}
                    {(tableData && tableData.length) ?
                        <TableBody>
                            {tableData.map((item, key) => {
                                return (
                                    <TableRow key={key} className={classes.tableRow}>
                                        <TableCell padding="checkbox" className={classes.shippingMethod}>
                                            <ShippingIcon
                                                shippingMethodName={item.shippingMethod}
                                                organizationShippingMethods={this.props.organizationShippingMethods}
                                            />
                                        </TableCell>
                                        <TableCell className={classes.tableCell + " " + classes.tableImageCell}>
                                            {item.variation && item.variation.pictures.length > 0 ?
                                                <ImageZoom>
                                                    <img
                                                        alt="variation_image"
                                                        src={getFirstProductImage(item.variation.pictures)}
                                                        className={classes.productImage}
                                                    />
                                                </ImageZoom> :
                                                <ImageSharp color={"disabled"} className={classes.productImage}/>
                                            }
                                        </TableCell>
                                        <TableCell className={classes.tableCell}>
                                            {this.renderName(item, item.variation)}
                                        </TableCell>
                                        <TableCell className={classes.tableCell}>
                                            <div className={classes.externalIdColor}
                                                 onClick={() => this.props.history.push(`/picking/orders/show/${item.order.id}`)}>
                                                #{item.order.externalId} ({item.order.productCount})
                                            </div>
                                            {item["createdAt"] && <CustomDate date={item["createdAt"]} />}
                                        </TableCell>
                                        <TableCell className={classes.tableCell}>
                                            {(item.adjustments && item.adjustments.length) ?
                                                this.renderPrice(item.amount,  item.retailerPrice)
                                                :
                                                this.renderPrice(item.retailerPrice,  item.initialRecommendedPrice / 100)
                                            }
                                        </TableCell>
                                        <TableCell className={classes.tableCell}>
                                            {this.renderStatus(item)}
                                        </TableCell>
                                        <TableCell padding="checkbox" className={classes.tableCell}>
                                            { 'returning-retailer' !== item['status'] && 'returning-runner' !== item['status'] &&
                                                this.renderActions(item)
                                            }
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody> :
                        <TableBody>
                            {(tableData) &&
                            <TableRow>
                                <TableCell colSpan={8} className={classes.tableCellNoResult}>
                                    <FormattedMessage id={"retailer.list.table.no_result"}/>
                                </TableCell>
                            </TableRow>
                            }
                        </TableBody>
                    }
                </Table>
                <OrderContext.Provider value={{
                    closeCancellationReasonDialog: () => this.closeCancellationReasonDialog(),
                    confirmCancellationAction: (selectedReason, daysCount) => {
                        this.confirmCancellationAction(selectedReason, daysCount)
                    },
                    isCancellationReasonDialogOpen: this.state.cancellationReasonDialog,
                    transitionActions: this.state.transitionActions,
                    isOrderAllowed: false,
                    isItemAllowed: true,
                    status: this.state.selectedItem?.status
                }}>
                    <CancellationReasonDialog />
                </OrderContext.Provider>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    loading: state.lineItem.retailerList.loading,
    member: state.authentication.member,
    organizations: state.authentication.organizations,
    authorizations: state.authentication.authorizations,
    updateLoadingLineItem: state.lineItem.update.loading,
    currentOrganization: state.currentOrganization.retrieved ?? null,
});

const mapDispatchToProps = dispatch => ({
    confirm: (item, params) => dispatch(confirm(item, params)),
    reserve: (item, params) => dispatch(reserve(item, params)),
    assign: (item, params) => dispatch(assign(item, params)),
    cancellationReason: (item, reason, unavailableDays) => dispatch(cancellationReason(item, reason, unavailableDays))
});

OrderTable.defaultProps = {
    tableHeaderColor: "gray",
    organizationShippingMethods: [],
};

OrderTable.propTypes = {
    classes: PropTypes.object.isRequired,
    tableHeaderColor: PropTypes.oneOf([
        "warning",
        "primary",
        "danger",
        "success",
        "info",
        "rose",
        "gray"
    ]),
    tableHead: PropTypes.arrayOf(PropTypes.object),
    organizationShippingMethods: PropTypes.arrayOf(PropTypes.object)
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(tableStyle)(injectIntl(OrderTable)));
