import React, {useState} from "react";
import {withStyles} from "@mui/styles";

import {injectIntl} from "react-intl";

import openingDaysStyle from "../../../assets/jss/views/retailer/openingDaysStyle";
import {MONDAY, TUESDAY, WEDNESDAY, THURSDAY, FRIDAY, SATURDAY, SUNDAY} from "../../../domain/enum/retailer/openingDays";
import OpeningDay from "./openingDay";
import ExceptionalDays from "./exceptionalDays";

export const daysMap = {};
daysMap[MONDAY] = 'retailer.form.openingDays.days.monday';
daysMap[TUESDAY] = 'retailer.form.openingDays.days.tuesday';
daysMap[WEDNESDAY] = 'retailer.form.openingDays.days.wednesday';
daysMap[THURSDAY] = 'retailer.form.openingDays.days.thursday';
daysMap[FRIDAY] = 'retailer.form.openingDays.days.friday';
daysMap[SATURDAY] = 'retailer.form.openingDays.days.saturday';
daysMap[SUNDAY] = 'retailer.form.openingDays.days.sunday';

function OpeningDays (props) {
    const { classes, openingDays, openingDaysChanged, intl } = props;
    const [days, setDays] = useState(openingDays);

    const addHour = (day, start, end) => {
        if (!Array.isArray(days[day])) {
            days[day] = [];
        }

        const newHour = [start, end];
        days[day].push(newHour);
        const tmpDays = {... days};
        setDays(tmpDays);
        openingDaysChanged(tmpDays);
    };

    const deleteHour = (day, start, end) => {
        if (!days || !days[day]) {
            console.warn('Impossible to delete given day is empty');
            return;
        }

        let dayHours = days[day];
        dayHours.forEach((openingPeriod, index) => {
            const [startIt, endIt] = openingPeriod;
            if (startIt === start && endIt === end) {
                delete dayHours[index];
                const tmpDays = {...days};
                setDays(tmpDays);
                openingDaysChanged(tmpDays);
            }
        });
    };

    const addExceptionalDay = (day, isOpen, start, end) => {
        if (!days?.exceptionalDays) {
            days.exceptionalDays = [];
        }

        let existingDayIndex = days?.exceptionalDays.findIndex(exceptionalDay => exceptionalDay.date === day);

        if (-1 === existingDayIndex) {
            days.exceptionalDays.push({
                date: day,
                hours: [],
            });
            existingDayIndex = days.exceptionalDays.length - 1;
        }

        if (!Array.isArray(days.exceptionalDays[existingDayIndex]?.hours)) {
            days.exceptionalDays[existingDayIndex].hours = [];
        }

        // The day is now closed
        if (null === start && null === end) {
            days.exceptionalDays[existingDayIndex].hours = null;
        } else {
            for (const [startIt, endIt] of days.exceptionalDays[existingDayIndex].hours) {
                // prevent adding same hour couple multiple time
                if (start === startIt && endIt === end) {
                    return;
                }
            }
            days.exceptionalDays[existingDayIndex].hours.push([start, end]);
        }

        const tmpDays = {...days};
        setDays(tmpDays);
        openingDaysChanged(tmpDays);
    }

    const removeExceptionalHour = (day, start, end) => {
        const exceptionalDayIndex = days?.exceptionalDays.findIndex(exceptionalDay => exceptionalDay.date === day);
        if (-1 === exceptionalDayIndex) {
            return;
        }

        const dayObj = days?.exceptionalDays[exceptionalDayIndex];
        // Delete an exceptional closed day 😭
        if (null === start && null === end) {
            days.exceptionalDays.splice(exceptionalDayIndex, 1);
            const tmpDays = {...days};
            setDays(tmpDays);
            openingDaysChanged(tmpDays);
            return;
        }
        const hourIndex = dayObj.hours.findIndex(hours => hours[0] === start && hours[1] === end);
        if (-1 === hourIndex) {
            return;
        }

        days.exceptionalDays[exceptionalDayIndex].hours.splice(hourIndex, 1);
        if (days?.exceptionalDays[exceptionalDayIndex].hours.length === 0) {
            days.exceptionalDays.splice(exceptionalDayIndex, 1);
        }
        const tmpDays = {...days};
        setDays(tmpDays);
        openingDaysChanged(tmpDays);
    };

    return (
        <div className={classes.containerWithSidebar}>
            <div className={classes.container}>
                <div className={classes.title}>{intl.formatMessage({id: 'retailer.form.openingDays.field.label'})}</div>
                <div className={classes.day}>
                    {Object.keys(daysMap).map(day => (
                        <OpeningDay
                            key={day}
                            day={day}
                            addHour={addHour}
                            deleteHour={deleteHour}
                            translationId={daysMap[day]}
                            openingHours={!!days ? days[day] : null}
                        />)
                    )}
                </div>

                <ExceptionalDays
                    exceptionalDays={!!days?.exceptionalDays ? days.exceptionalDays : []}
                    addExceptionalDay={(day, isOpen, start, end) => addExceptionalDay(day, isOpen, start, end)}
                    removeExceptionalHour={(day, start, end) => removeExceptionalHour(day, start, end)}
                />
            </div>
        </div>
    );
}

export default withStyles(openingDaysStyle)(injectIntl(OpeningDays));
