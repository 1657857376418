import React from 'react';
import {connect} from "react-redux";
import PropTypes from 'prop-types';

import Infos from './components/Infos';
import Banner from './components/Banner';
import Logo from './components/Logo';

Index.propTypes = {
    retailer: PropTypes.object.isRequired
};

function Index({retailer, member}) {
    return (
        <div style={{
            position: 'relative',
            borderRadius: '16px',
            boxShadow: '0px 4px 22px 0px rgba(0, 0, 0, 0.06)',
            height: '100%'
        }}>
            <Banner url={retailer.translations?.[member.locale]?.picture?.contentUrl} />
            <Logo url={retailer.translations?.[member.locale]?.logo?.contentUrl} />
            <Infos
                name={retailer.name}
                description={retailer.translations?.[member.locale]?.description}
                city={retailer.addressVO?.city}
                email={retailer.email}
                id={retailer.id}
            />
        </div>
    );
}

const mapStateToProps = state => {
    return {
        member: state.authentication.member,
    };
};

export default connect(mapStateToProps)(Index)
