import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";

// @mui/material components
import {withStyles} from "@mui/styles";

// styles
import cardBodyStyle from "../../assets/jss/components/card/cardBodyStyle";

function CardBody({...props}) {
    const {classes, className, children, ...rest} = props;
    const cardBodyClasses = classNames({
        [classes.cardBody]: true,
        [className]: className !== undefined
    });
    return (
        <div className={cardBodyClasses} {...rest}>
            {children}
        </div>
    );
}

CardBody.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string
};

export default withStyles(cardBodyStyle)(CardBody);
