import { fetch } from "utils/dataAccess";
import { setOrganization } from "utils/dataAccessOrganizationHandler";
import normalizeTranslations from "../../actions/translations";

export default async function create(values) {
    setOrganization(values);
    values = normalizeTranslations(values);

    values.email = values.emailTemplate !== '';

    const response = await fetch("/cancellation_reasons", {method: "POST", body: JSON.stringify(values)})

    return await response.json();
}
