import React, { useEffect, useState } from "react";
import connect from "react-redux/es/connect/connect";
import { FormattedMessage } from "react-intl";

// @mui/icons-material
import { NotificationsActive } from "@mui/icons-material";

// core components
import Snackbar from "components/snackbar/snackbar";

function Notification(props) {
    const { closeNotification, created, deleted, isOpen } = props;
    const [message, setMessage] = useState(null);

    useEffect(() => {
        if (created) {
            setMessage("category.notification.created");
        }

        if (deleted) {
            setMessage("category.notification.deleted")
        }
    }, [created, deleted]);

    return (
        message && (
            <Snackbar
                open={isOpen}
                closeNotification={closeNotification}
                place={"bl"}
                color={"success"}
                icon={() => <NotificationsActive/>}
                close
                message={<FormattedMessage id={message}/>}
            />
        )
    );
}

const mapStateToProps = (state) => {
    return {
        created: state.category.create.created,
        deleted: state.category.deleteCategory.deleted
    };
};

export default connect(
    mapStateToProps,
    null
)(Notification);
