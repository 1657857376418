import {combineReducers} from "redux";

export function error(state = null, action) {
    switch (action.type) {
        case "SECTOR_UPDATE_ERROR":
            return action.error;

        default:
            return state;
    }
}

export function loading(state = false, action) {
    switch (action.type) {
        case "SECTOR_UPDATE_LOADING":
            return action.loading;

        default:
            return state;
    }
}

export function updated(state = null, action) {
    switch (action.type) {
        case "SECTOR_UPDATE_SUCCESS":
            return action.updated;

        default:
            return state;
    }
}

export default combineReducers({updated, loading, error});
