import React, { useContext } from "react";
import Grid from '@mui/material/Unstable_Grid2';
import RemoveFilterChip from "views/marketplace/merchandising/filter/removeFilterChip";
import {FilterContext} from "contexts/filterContext";
import {getFilterLabel} from "domain/helpers/filterTranslations";

export default function RemoveFilterPanel() {

    const {filters} = useContext(FilterContext);

    return (
        <>
            <Grid spacing={2}>
                {filters.map((filter, index) => {
                    return (
                        <RemoveFilterChip key={index} label={getFilterLabel(filter)} item={filter} />
                    );
                })}
            </Grid>
        </>
    );
}
