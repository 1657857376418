import React, {Component} from "react";
import {withStyles} from "@mui/styles";
import {Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel, Tooltip} from "@mui/material";
import {FormattedMessage} from "react-intl";

import {BeatLoader, PropagateLoader} from "react-spinners";

import CustomDate from "../../../../components/date/date";
import FormattedNumberWithCurrency from "../../../../components/formattedNumberWithCurrencies/formattedNumberWithCurrency";

import listStyle from "../../../../assets/jss/views/quote/listStyle";
import {primaryColor} from "../../../../assets/jss/main";
import store from "../../../../store";

const tableHead = [
    <FormattedMessage id={"picking.quote.listing.gridHeader.quote"}/>,
    <FormattedMessage id={"picking.quote.listing.gridHeader.customer"}/>,
    <FormattedMessage id={"picking.quote.listing.gridHeader.total"}/>,
    <FormattedMessage id={"picking.quote.listing.gridHeader.quantity"}/>,
    <FormattedMessage id={"picking.quote.listing.gridHeader.addressedTo"}/>,
    <FormattedMessage id={"picking.quote.listing.gridHeader.updatedAt"}/>,
];

class QuoteGridContent extends Component {
    constructor(props) {
        super(props);
    }

    renderCustomer(item) {
        if (!item.organizationCustomer) {
            return;
        }

        return (
            <div>
                <div><b>{item.organizationCustomer.firstName}</b></div>
                <div>({item.organizationCustomer.lastName})</div>
            </div>
        );
    }

    renderHandler(item) {
        if (!item.assignedAt) {
            return null;
        }

        return (
            <div>
                <div><b>{item.assignedAt.user.givenName + " " + item.assignedAt.user.familyName}</b></div>
                <div>({item.organization.name})</div>
            </div>
        );
    }

    renderTableBody() {
        const { classes, quotes } = this.props;

        if (quotes === null || quotes.length === 0) {
            return (
                <TableBody>
                    {(quotes) &&
                    <TableRow>
                        <TableCell colSpan={7} className={classes.tableCellNoResult}>
                            <FormattedMessage id={"retailer.list.table.no_result"}/>
                        </TableCell>
                    </TableRow>
                    }
                </TableBody>
            );
        }

        return (
            <TableBody>
                {quotes.map((item, key) => {
                    return (
                        <TableRow
                            hover
                            key={key}
                            className={classes.tableRow}
                            onClick={() => this.props.history.push(`/quote/${item.id}`)}
                        >
                            <TableCell className={classes.tableCell}>
                                <div className={classes.primaryColor + ' ' + classes.highlight}>
                                    #{item["number"]}
                                </div>
                                <CustomDate date={item["createdAt"]} />
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                                <div>
                                    {this.renderCustomer(item)}
                                </div>
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                                <div>
                                    <b>
                                        <FormattedNumberWithCurrency value={item.total / 100} currency={store.getState().currentOrganization?.retrieved?.currency} />
                                    </b>
                                </div>
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                                <div>
                                    {item.quantity}
                                </div>
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                                <div>
                                    {this.renderHandler(item)}
                                </div>
                            </TableCell>
                            <TableCell className={classes.tableCell + ' ' + classes.highlight}>
                                {item["updatedAt"] !== item["createdAt"] && <CustomDate date={item["updatedAt"]} />}
                            </TableCell>
                        </TableRow>
                    );
                })}
            </TableBody>
        );
    }

    renderTable() {
        const { classes } = this.props;

        return (<Table className={classes.table}>
            <TableHead className={classes.primaryTableHeader}>
                <TableRow>
                    {tableHead.map((prop, key) => {
                        return (
                            <TableCell
                                className={classes.tableCell + " " + classes.tableHeadCell}
                                key={key}
                            >
                                {prop && prop.props.id === "picking.order.list.table.header.order" ?
                                    <Tooltip title="Sort" enterDelay={100}>
                                        <TableSortLabel
                                            active={true}
                                            onClick={prop.props.onClick}
                                            direction={prop.props.sortDirection}
                                        >
                                            {prop}
                                        </TableSortLabel>
                                    </Tooltip>
                                    : prop
                                }
                            </TableCell>
                        );
                    })}
                </TableRow>
            </TableHead>
            {this.renderTableBody()}
        </Table>);
    }

    render() {
        const { classes, loading, quotes } = this.props;

        return (
            <div className={classes.tableResponsive}>
                {!quotes ?
                    <div className={classes.propagateLoader}>
                        <PropagateLoader
                            sizeUnit={"px"}
                            size={16}
                            color={primaryColor}
                            loading={loading}
                        />
                    </div> :
                    <div className={classes.beatLoader}>
                        <BeatLoader
                            sizeUnit={"px"}
                            size={12}
                            color={primaryColor}
                            loading={loading}
                        />
                    </div>
                }

                {this.renderTable()}
            </div>
        );
    }
}

export default withStyles(listStyle)(QuoteGridContent);
