import React, {useContext} from 'react'
import PropTypes from "prop-types";

import Zoom from "@mui/material/Zoom";
import Avatar from "@mui/material/Avatar";

import {makeStyles} from "@mui/styles";

import RunnerAssignButton from "./RunnerAssignButton";

import {orangeColor} from "assets/jss/main";

import {OrderContext} from "contexts/OrderContext";

import {Authorizations, hasAuthorization} from "utils/authorizations";
import {capitalize} from "utils/capitalize";

const defaultStyle = {
    color: "#FFFFFF",
    width: "40px",
    height: "40px",
    borderRadius: "50%",
}

const useStyle = makeStyles({
    img: {
        ...defaultStyle,
        boxSizing: "border-box",
        padding: "0px",
    },
    imgPlaceholder: {
        ...defaultStyle,
        background: orangeColor,
        padding: "12px",
        leadingTrim: 'both',
        textEdge: 'cap',
        fontSize: '20px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: 'normal',
        letterSpacing: '0.24px',
},
})

export default function RunnerAvatar({lineItem}) {
    const {
        authorizations,
        showAssignButtonForLineItem,
        member
    } = useContext(OrderContext)

    const classes = useStyle();

    if (
        hasAuthorization(authorizations, Authorizations.PICKING_RETAILER)
        || hasAuthorization(authorizations, Authorizations.PICKING_READ_ONLY)
    ) {
        return null;
    }

    if (!lineItem.runner) {
        return null
    }

    if (showAssignButtonForLineItem === lineItem['@id']) {
        return (
            <Zoom in={true} style={{ transitionDelay:'100ms'}}>
               {/* do not remove the div
                <Zoom> children need ref,
                so we must add a div
                https://mui.com/material-ui/transitions/#child-requirement
                */}
                <div>
                    <RunnerAssignButton lineItem={lineItem} memberId={member['id']} />
                </div>
            </Zoom>
        )
    }

    if (lineItem.runner.user.picture) {
        return (
            <img
                src={lineItem.runner.user.picture}
                className={classes.img}
                alt={""}
            />
        )
    }

    return (
        <Avatar className={classes.imgPlaceholder}>
            {capitalize(lineItem.runner.user.givenName.charAt(0))}
        </Avatar>
    )
}

RunnerAvatar.defaultProps = {
    lineItem: {}
}

RunnerAvatar.propTypes = {
    lineItem: PropTypes.object
}
