import React from 'react'
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";

import Button from "components/button/button";
import Loader from "./loader";

MoveButton.defaultProps = {
    disabled: false,
    loading: false
}

MoveButton.propTypes = {
    disabled: PropTypes.bool,
    loading: PropTypes.bool
}

export default function MoveButton(props) {
    return (
        <Button
            round
            color={"success"}
            type="submit"
            disabled={props.disabled}
            {...props}
        >
            <FormattedMessage id={"configuration.form.move.submit"} />
            {props.loading && <Loader />}
        </Button>
    )
}

