import React from 'react';
import {styled} from "@mui/material/styles";

const StyledDiv = styled('div')(({ theme }) => ({
    [theme.breakpoints.up("md")]: {
        marginLeft: "240px",
    }
}));

export default function ContainerWithSideBar(props) {
    return (
        <StyledDiv>
            {props.children}
        </StyledDiv>
    );
}
