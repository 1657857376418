import React from "react";
import {FormattedMessage} from "react-intl";
import {getTranslation} from "domain/helpers/translations";
import NavItem from "./navItem"
import LastChildIcon from "./icon/lastChildIcon"

export default function NavTree(props) {

    const {
        items,
        topItems,
        topNodeLabel,
        topNodeIcon,
        loadingHandler,
        type,
        ...other
    } = props;

    function renderTree(item) {
        const children = items.filter(child => child.parent && child.parent.id === item.id);
        const isLastChild = children.length === 0;

        return (
            <NavItem
                nodeId={item.id}
                key={item.id}
                labelText={getTranslation(item).name ? getTranslation(item).name : item.name}
                labelIcon={isLastChild && LastChildIcon}
                loadingHandler={loadingHandler}
                item={item}
            >
                {children.length !== 0 && children.map((child) => renderTree(child))}
            </NavItem>
        );
    }

    return (
        <NavItem
            labelText={<FormattedMessage id={topNodeLabel} />}
            labelIcon={topNodeIcon}
            loadingHandler={loadingHandler}
            item={{id: props.nodeId, '@type': type, parent: null}}
            {...other}
        >
            {topItems.length !== 0 && topItems.map((item) => renderTree(item))}
        </NavItem>
    );

}
