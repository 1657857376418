import {fetch} from "../../utils/dataAccess";

export default function () {
    return new Promise((resolve, reject) => {
        fetch('/tax_rates?status=true')
            .then(response => {
                response.json().then(response => {
                    resolve(response["hydra:member"]);
                });
            })
            .catch(error => reject(error));
    });
}
