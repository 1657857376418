import {fetch} from "utils/dataAccess";

export function error(error) {
    return {type: "SIZE_DELETE_SELECTION_ERROR", error};
}

export function loading(loading) {
    return {type: "SIZE_DELETE_SELECTION_LOADING", loading};
}

export function success(deleted) {
    return {type: "SIZE_DELETE_SELECTION_SUCCESS", deleted};
}

export function deleteSelection(sizes) {
    return dispatch => {
        dispatch(loading(true));

        return fetch('/sizes/delete_selection', {method: "POST", body: JSON.stringify({sizes: sizes})})
            .then(() => {
                dispatch(loading(false));
                dispatch(success(sizes));
            })
            .catch((e) => {
                dispatch(loading(false));
                dispatch(error(e.message));
            });
    };
}