import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {FormattedMessage, injectIntl} from 'react-intl';
import {Link} from 'react-router-dom';
import * as yup from 'yup';
import "yup-phone-lite";
import {Form, Formik} from 'formik';

// material
import Grid from '@mui/material/Grid';

// global components
import CancelButtonStyled from 'components/button/CancelButtonStyled';
import Page from 'components/page/Page';
import SaveButton from 'components/button/SaveButton';

// local components
import ActionsContainer from './components/ActionsContainer';
import InfosForm from './components/Forms/InfosForm';
import VisualForm from './components/Forms/VisualForm';
import ClickAndCollectForm from './components/Forms/ClickAndCollectForm';
import BillForm from './components/Forms/BillForm';

// api
import {showProfile} from 'api/retailer/show';
import {updateProfile} from 'api/retailer/update';
import {removeFile} from 'api/upload';

// context
import {RetailerProfilesEditContext} from './context/RetailerProfilesEditContext';
import mapValues from "lodash/mapValues";
import {lazy} from "yup";

function Index(props) {
    const {intl, match, member} = props;

    const [loading, setLoading] = useState(false);

    const [retailerInfos, setRetailerInfos] = useState({});
    const [isClickAndCollect, setClickAndCollect] = useState(false);

    const [picturesToDelete, setPicturesToDelete] = useState([]);

    // component did mount
    useEffect(() => {
        setLoading(true);

        showProfile(match.params.id).then((response) => {
            setRetailerInfos(response);
            setClickAndCollect(response.clickAndCollectInstructions);
        }).finally(() => setLoading(false));
    }, []);

    const validationSchema = yup.object({
        phone: yup
            .string()
            .phone(member.locale, intl.formatMessage({id: "retailer.profile.edit.form.field.phone.message"}))
            .required(intl.formatMessage({id: "retailer.profile.edit.form.field.phone.required"})),
        smsPhone: yup
            .string()
            .phone(member.locale, intl.formatMessage({id: "retailer.profile.edit.form.field.phone.message"})),
        address: yup.string().required(intl.formatMessage({id: "retailer.profile.edit.form.field.address.required"})),
        paymentAccount: yup.object({
            siret: yup
                .string()
                .matches(/^[0-9]+$/, intl.formatMessage({id: 'retailer.profile.edit.form.field.paymentAccount.siret.message.type'}))
                .min(14, intl.formatMessage({id: 'retailer.profile.edit.form.field.paymentAccount.siret.message.length'}))
                .max(14, intl.formatMessage({id: 'retailer.profile.edit.form.field.paymentAccount.siret.message.length'})),
            email: yup.string().email(intl.formatMessage({id: 'retailer.profile.edit.form.field.paymentAccount.email.message'}))
        }),
        translations: lazy(obj => yup.object(
            mapValues(obj, (value, key) => {
                return yup.object({
                    clickAndCollectInstructions: isClickAndCollect ? yup.string().nullable().required("Passphrase is required") : yup.string().nullable(),
                })
            })
        ))
    });

    const initialValues = {
        'email': retailerInfos?.email || '',
        'phone': retailerInfos?.phone || '',
        'address': retailerInfos?.address || '',
        'smsPhone': retailerInfos?.smsPhone || '',
        'sector': retailerInfos?.sector || '',
        'paymentAccount': retailerInfos?.paymentAccount || {
            'tradeName': '',
            'vatNumber': '',
            'siret': '',
            'email': '',
            'address': ''
        },
        'clickAndCollectOnly': retailerInfos?.clickAndCollectOnly || false,
        'translations': retailerInfos?.translations || {}
    };

    function updateRetailer(values, actions) {
        setLoading(true);

        if (!isClickAndCollect) {
            for (let code in values.translations) {

                values.clickAndCollectOnly = false;
                values.translations[code].clickAndCollectInstructions = null;

                if (isClickAndCollect && !values.translations[code].clickAndCollectInstructions) {
                    console.log('Should not happen');
                }
            }
        }

        updateProfile(retailerInfos, values).then(async () => {
            for (const mediaObject of picturesToDelete) {
                await removeFile(mediaObject['@id']);
            }
        }).then(() => {
            actions.setSubmitting(false);
            props.history.push('/retailer_profiles');
        }).catch((error) => {
            if (error.errors) {
                for (const [field, errorMessage] of Object.entries(error?.errors)) {
                    actions.setFieldError(field, errorMessage);
                }
            }
            actions.setSubmitting(false);
        });
    }

    const isAllowedToChangeLogo = retailerInfos.allowedToChangeLogo;

    return (
        <Page
            titleId="retailer.profile.edit.title"
            subtitleId="retailer.profile.edit.subtitle"
            loading={loading}
        >
            <RetailerProfilesEditContext.Provider value={{
                picturesToDelete,
                setPicturesToDelete,
                isClickAndCollect,
                setClickAndCollect,
                isAllowedToChangeLogo,
            }}>
                <Formik
                    initialValues={initialValues}
                    enableReinitialize={true}
                    validationSchema={validationSchema}
                    validateOnChange={false}
                    validateOnBlur={true}
                    onSubmit={(values, {setFieldError, setSubmitting}) => {
                        updateRetailer(values, {setFieldError, setSubmitting})
                    }}
                >
                    {({isSubmitting, isValid}) => (
                        <Form>
                            <Grid container rowSpacing={6}>
                                <InfosForm />
                                {retailerInfos?.paymentAccount && (
                                    <BillForm />
                                )}
                                <VisualForm />
                                <ClickAndCollectForm />
                                <Grid item xs={12}>
                                    <ActionsContainer>
                                        <Link style={{ textDecoration: 'none' }} to={`/retailer_profiles`}>
                                            <CancelButtonStyled disabled={isSubmitting}>
                                                <FormattedMessage id="retailer.profile.edit.form.button.cancel" />
                                            </CancelButtonStyled>
                                        </Link>
                                        <SaveButton
                                            type={"submit"}
                                            disabled={loading || !isValid || isSubmitting}
                                        >
                                            <FormattedMessage id="retailer.profile.edit.form.button.save" />
                                        </SaveButton>
                                    </ActionsContainer>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </RetailerProfilesEditContext.Provider>
        </Page>
    );
}

const mapStateToProps = state => {
    return {
        member: state.authentication.member,
    };
};

export default connect(mapStateToProps)(injectIntl(Index));
