import React from "react";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import {Link} from "react-router-dom";

import {Badge, Fab, Tooltip} from "@mui/material";

import {CompareArrows} from "@mui/icons-material";

export default function MappingAccessButton(props) {
    const { numberOfAttributesToMap, retailerId } = props

    return (
        <Link to={`${retailerId}/attributes-mapping`}>
            <Fab color="secondary">
                <Tooltip title={<FormattedMessage id={"feed.list.tooltip.mapping"} />}>
                    <Badge
                        max={100000}
                        badgeContent={numberOfAttributesToMap}
                        color="primary"
                    >
                        <CompareArrows fontSize={"large"} />
                    </Badge>
                </Tooltip>
            </Fab>
        </Link>
    )
}

MappingAccessButton.defaultProps = {
    numberOfAttributesToMap: 0,
};

MappingAccessButton.propTypes = {
    numberOfAttributesToMap: PropTypes.number,
    retailerId: PropTypes.string,
};
