import {fetch} from "../../../utils/dataAccess";

export function error(error) {
    return {type: "ORDER_NOTE_LIST_ERROR", error};
}

export function loading(loading) {
    return {type: "ORDER_NOTE_LIST_LOADING", loading};
}

export function success(retrieved) {
    return {type: "ORDER_NOTE_LIST_SUCCESS", retrieved};
}

let listRequest = null;
export function list(params = `order[createdAt]=asc`) {
    return dispatch => {
        if (!!listRequest) {
            return;
        }

        dispatch(loading(true));
        listRequest = fetch("/notes?" + params);
        listRequest.then(response => {
            response.json().then(retrieved => {
                dispatch(loading(false));
                dispatch(success(retrieved));
            }).catch(() => {
                dispatch(loading(false));
            });
        }).catch(error => {
            // canceled
        }).finally(() => listRequest = null);
    }
}

export function reset() {
    return dispatch => {
        dispatch({type: "ORDER_NOTE_LIST_RESET"});
    };
}
