import {fetch} from "../../utils/dataAccess";
import {setOrganization} from "../../utils/dataAccessOrganizationHandler";
import normalizeTranslations from "../translations";

export function error(error) {
    return {type: "RETAILER_CREATE_ERROR", error};
}

export function loading(loading) {
    return {type: "RETAILER_CREATE_LOADING", loading};
}

export function success(created) {
    return {type: "RETAILER_CREATE_SUCCESS", created};
}

export function create(values) {
    setOrganization(values);

    values = normalizeTranslations(values);

    return dispatch => {
        dispatch(loading(true));

        const data = {
            name: values.name,
            email: values.email,
            rent: parseFloat(values.rent, 10),
            surface: parseFloat(values.surface, 10),
            sellers: parseFloat(values.sellers, 10),
            sector: values.sector,
            organization: values.organization,
            pushActive: values.pushActive,
            address: values.address,
            phone: values.phone,
            stockThreshold: values.stockThreshold && parseFloat(values.stockThreshold, 10),
            whatsappPhone: values.whatsappPhone,
            smsPhone: values.smsPhone,
            commissionRate: values.commissionRate,
            translations: values.translations,
            taxRateZero: values.taxRateZero,
            catalogOnly: values.catalogOnly,
            privateProducts: values.privateProducts,
            privatePrices: values.privatePrices,
            hiddenPrices: values.hiddenPrices,
            metaTitle: values.metaTitle,
            metaDescription: values.metaDescription,
            coniqLocationId: values.coniqLocationId,
            siret: values.siret,
            contactName: values.contactName !== '' ? values.contactName : null,
        };

        return fetch("/retailers", {method: "POST", body: JSON.stringify(data)})
            .then(response => {
                dispatch(loading(false));

                return response.json();
            })
            .then(retrieved => dispatch(success(retrieved)))
            .catch(e => {
                dispatch(loading(false));
                dispatch(error(e));
            });
    };
}

export function reset() {
    return dispatch => {
        dispatch({type: "RETAILER_CREATE_RESET"});
        dispatch(loading(false));
        dispatch(error(null));
    };
}
