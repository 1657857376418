import {darkGrayColor} from "../../main";

const imageSliderStyle = theme => ({
    image: {
        maxWidth: "150px",
        maxHeight: "150px",
        display: "inline-block",
        position: "relative",
    },
    dropZonePicture: {
        display: "flex",
    },
    slickContainer: {
        lineHeight: "150px",
        display: "flex!important",
        alignItems: "center",
        height: "150px",
        position: "relative",
    },
    draggableContainer: {
        display: "flex",
        overflow: "auto",
    },
    pictureContainer: {
        position: "relative",
        float: "right",
        display: "flex",
        justifyContent: "flex-end",
        cursor: "pointer",
        borderRadius: "15px",
        border: "2px dashed #E0E0E0",
        "& div:last-child": {
            borderWidth: "0px!important",
            width: "145px!important",
            height: "145px!important",
            overflow: "hidden",
            "& img": {
                width: "140px",
                maxHeight: "140px",
                maxWidth: "140px",
                borderRadius: "18%"
            },
            "& input": {
                display: "none"
            }
        }
    },
    picturePlaceholderContent: {
        display: "flex",
        alignItems: "center",
    },
    pictureIcon: {
        position: "absolute",
        zIndex: "1",
        color: darkGrayColor,
        pointerEvents: "none",
        width: "100%",
        textAlign: "center",
        marginTop: "-12px"
    },
    pictureText: {
        color: darkGrayColor,
        zIndex: "1",
        position: "absolute",
        fontSize: "12px",
        pointerEvents: "none",
        fontWeight: "400",
        width: "100%",
        textAlign: "center",
        marginTop: "12px"
    },
    picturePlaceholder: {
        borderRadius: "25%",
        width: "140px",
        height: "140px",
        background: "linear-gradient(60deg, #377dff, #0052cb)",
    },
    pictureLoading: {
        position: "absolute",
        zIndex: "1",
        color: darkGrayColor+"!important",
        right: "50px",
        top: "50px",
        pointerEvents: "none",
    },
    actionsContainer: {
        position: "absolute",
        right: "5px",
        bottom: "0px",
        textAlign: "right"
    },
    submitLoading: {
        marginLeft: "5px",
        color: "#FFFFFF"
    },
    slickRemove : {
        width: "30px",
        height: "20px",
        position: "absolute",
        right: "5px",
        top: "5px",
        color: darkGrayColor,
        cursor: "pointer",
        backgroundColor: "#FFF",
        borderRadius: "35px"
    },
    imageContainer: {
        width: "150px",
        minWidth: "150px",
        border: "2px solid #e0e0e0",
        height: "150px",
        margin: "3px 10px 3px 10px",
        display: "flex",
        padding: "0",
        overflow: "hidden",
        position: "relative",
        alignItems: "center",
        justifyContent: "center",
        background: "#FFF",
    }
});

export default imageSliderStyle;
