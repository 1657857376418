import React from 'react';

/**
 * @deprecated use src/components/containers/ActionsContainer
 */
export default function ActionsContainer(props) {
    return (
        <div style={{
            position: "absolute",
            right: "5px",
            bottom: "0px",
            textAlign: "right"
        }}>
            {props.children}
        </div>
    );
}

