import React from "react";
import {TableCell, } from "@mui/material";
import {withStyles} from "@mui/styles";
import listStyle from "../../../assets/jss/views/product/listStyle";
import {ImageSharp} from "@mui/icons-material";

function MerchandisingImageCell({product, classes}) {
    let url = null;
    if (null !== product.pictureUrl) {
        url = product.pictureUrl;
    }

    if (null === url) {
        return (
            <TableCell>
                <ImageSharp color={"disabled"} className={classes.imagePlaceholder} />
            </TableCell>
        );
    }
    return (

        <TableCell>
            <img
                alt={"product_image"}
                src={url}
                className={classes.image}
            />
        </TableCell>
    );

}

export default withStyles(listStyle)(MerchandisingImageCell);
