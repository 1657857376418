import {fetch} from "../../../utils/dataAccess";

export function error(error) {
    return {type: "PRODUCT_CREATE_ERROR", error};
}

export function loading(loading) {
    return {type: "PRODUCT_CREATE_LOADING", loading};
}

export function success(retrieved) {
    return {type: "PRODUCT_CREATE_SUCCESS", retrieved};
}

function format(product) {
    const formatPrice = (inputPrice) => {
        if (!!inputPrice) {
            const price = inputPrice.toString().replace(',', '.').replaceAll(',', '');
            return parseFloat(price);
        }

        return null;
    };
    product.price = formatPrice(product.price);
    product.outletPrice = formatPrice(product.outletPrice);
    product.variations = product.variations.map((variation) => {
        variation.price = formatPrice(variation.price);
        variation.outletPrice = formatPrice(variation.outletPrice);
        return variation;
    });

    return product;
}

export function create(product) {
    return dispatch => {
        dispatch(error(false));
        dispatch(loading(true));

        fetch("/products", {method: "POST", body: JSON.stringify(format(product))}).then(response => {
            response.json().then(retrieved => {
                dispatch(loading(false));
                dispatch(success(retrieved));
            }).catch(() => {
                dispatch(loading(false));
            });
        }).catch(message => {
            dispatch(loading(false));
            dispatch(error(true));
        });
    }
}

export function reset() {
    return dispatch => {
        dispatch({type: "PRODUCT_CREATE_RESET"});
    };
}
