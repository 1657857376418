import React from 'react';
import PropTypes from 'prop-types';

import GalleryIcon from "components/icons/GalleryIcon";

import {black20} from "assets/jss/main";

Logo.propTypes = {
    url: PropTypes.string
};

const style  = {
    width: '96px',
    height: '96px',
    borderRadius: '50%',
    border: '4px solid #FFFFFF',
};

function Logo({url}) {
    return (
        <div style={{
            position: 'absolute',
            top: '124px',
            left: '20px',
            ...style,
            background: `url(${url})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
        }}>
            {!url &&
                <div style={{
                    ...style,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    background: black20,
                    marginLeft: '-4px',
                    marginTop: '-4px',
                }}>
                    <GalleryIcon/>
                </div>
            }
        </div>
    );
}

export default Logo;
