import React, {useContext} from "react";
import {QuoteContext} from "../../../../../contexts/quoteContext";
import {FormattedMessage} from "react-intl";

const QuoteDetailHeaderCustomerInfos = () => {
    const { quote } = useContext(QuoteContext);

    const renderPhoneNumber = () => {
        let phoneNumber = quote.customerShippingPhone ?? quote.customerBillingPhone ?? null;
        if (!phoneNumber) {
            return null;
        }

        return (
            <span>
                <a href={'tel:'+phoneNumber}>{phoneNumber}</a><br/>
            </span>
        );
    }

    const renderEmail = () => {
        let email = quote.customerShippingEmail ?? quote.customerBillingEmail ?? null;
        if (!email) {
            return null;
        }

        return (
            <span>
                <a href={'mailto:'+email}>{email}</a><br/>
            </span>
        );
    }

    return (
        <>
            <FormattedMessage id={"picking.quote.listing.gridHeader.customer"}/> n°{quote.organizationCustomer?.externalNumber}<br/>
            {quote.organizationCustomer?.firstName} {quote.organizationCustomer?.lastName}<br/>
            {renderPhoneNumber()}
            {renderEmail()}
        </>
    );
};

export default QuoteDetailHeaderCustomerInfos;
