import React from 'react';

function PreviewContainer(props) {
    return (
        <div style={{
            display: 'inline-flex',
            width: '100%',
            height: '252px',
            boxSizing: 'border-box'
        }}>
            <div style={{
                display: 'flex',
                minWidth: 0,
                overflow: 'hidden',
                width: '100%',
                position: 'relative'
            }}>
                {props.children}
            </div>
        </div>
    );
}

export default PreviewContainer;
