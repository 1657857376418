import {fetch} from "utils/dataAccess";

function error(error) {
    return {type: "MEMBER_UPDATE_ERROR", error};
}

function loading(loading) {
    return {type: "MEMBER_UPDATE_LOADING", loading};
}

function success(success) {
    return {type: "MEMBER_UPDATE_SUCCESS", success};
}

export const update = (item, values) => dispatch => {
    dispatch(loading(true));

    return fetch(item["@id"], {
        method: 'PUT',
        headers: new Headers({'Content-Type': 'application/ld+json'}),
        body: JSON.stringify(values)
    })
    .then(response => {
        response.json().then(retrieved => {
            dispatch(loading(false));
            dispatch(success(retrieved));
        })
    })
    .catch((e) => {
        dispatch(loading(false));
        dispatch(error(e));
    });
};

export function reset() {
    return dispatch => {
        dispatch({type: "UPDATE_MEMBER_RESET"});
    };
}