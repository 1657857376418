import React, {useEffect, useRef, useState} from "react";
import {Fab, } from "@mui/material";
import {withStyles} from "@mui/styles";
import {Close} from "@mui/icons-material";

import {injectIntl} from "react-intl";

import metadataStlye from "../../assets/jss/views/components/metadatasStyle";

function Metadata ({metadataKey, metadataValue, metadataChanged, onDelete, classes}) {
    const [key, setKey] = useState(metadataKey);
    const [value, setValue] = useState(metadataValue);

    const ref = useRef();
    useEffect(() => {
        if (!ref.current) {
            ref.current = true;
            return;
        }

        metadataChanged(key, value);
    }, [key, value]);


    return (
        <div key={metadataKey} className={classes.metadata}>
            <span>Clé</span>
            <input
                type={'text'}
                className={classes.input}
                value={key}
                onChange={e => setKey(e.target.value)}
            />
            <span>Valeur</span>
            <input
                type={'text'}
                className={classes.input}
                value={value}
                onChange={e => setValue(e.target.value)}
            />

            <Fab color="secondary" size={'small'} aria-label="delete" onClick={() => onDelete(metadataKey)} className={classes.removeItem}>
                <Close fontSize={'small'}/>
            </Fab>
        </div>
    )
}

export default withStyles(metadataStlye)(injectIntl(Metadata));
