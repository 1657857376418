import React, {Component} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";

// actions
import {reset} from "../../actions/retailer/show";

export default function (ComposedComponent) {
    class RetailerUnloader extends Component {
        PropTypes = {
            router: PropTypes.object,
            retrieved: PropTypes.object,
            reset: PropTypes.func.isRequired
        };

        componentDidMount() {
            this.props.reset();
        }

        render() {
            return <ComposedComponent {...this.props} />;
        }
    }

    const mapDispatchToProps = dispatch => ({
        reset: () => dispatch(reset())
    });

    return connect(null, mapDispatchToProps)(RetailerUnloader);
}
