export const redirectToSubdomain = (subdomain) => {
    let protocol = window.location.protocol;
    let host = window.location.host;
    let parts = host.split(":");

    let url = subdomain + "." + process.env.REACT_APP_HOST;
    if (parts.length === 2) {
        url += ":" + parts[1]
    }
    window.location = protocol + "//" + url;
}