import React from 'react'
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import {Link} from "react-router-dom";

import {ArrowBackIos} from "@mui/icons-material";

import Button from "components/button/button";

export default function CancelButton({retailerId}) {
    return (
        <Button
            color={"info"}
            simple
            component={Link}
            to={`/retailers/show/${encodeURIComponent(retailerId)}/feeds/`}
        >
            <ArrowBackIos />
            <FormattedMessage id={"feed.form.back"} />
        </Button>
    )
}

CancelButton.propTypes = {
    retailerId: PropTypes.string.isRequired
};
