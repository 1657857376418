import React from "react";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";

import CardBody from "components/card/cardBody";
import CardStats from "views/marketplace/dashboard/components/cards/cardStats";
import CardStatsTitle from "views/marketplace/dashboard/components/cards/cardStatsTitle";
import Loader from "views/marketplace/dashboard/components/loader";

export default function CardStatsOpe({...props}) {
    const { loading, title } = props;

    return (
        <CardStats>
            <Loader loading={loading} />
            <CardStatsTitle>
                <FormattedMessage id={title}/>
            </CardStatsTitle>
            <CardBody>
                <div>
                    {props.children}
                </div>
            </CardBody>
        </CardStats>
    )
}

CardStatsOpe.defaultProps = {
    loading: false,
    title: ''
}

CardStatsOpe.propTypes = {
    loading: PropTypes.bool,
    title: PropTypes.string,
};
