import React, {useEffect} from "react";
import MerchandisingTableContent from "domain/components/merchandising/MerchandisingTableContent";
import MerchandisingPagination from "domain/components/merchandising/MerchandisingTablePagination";
import SelectBatchAction from "domain/components/merchandising/batchActions/SelectBatchAction";
import {
    Button,
    Table,
    TableCell,
    IconButton,
    TableContainer,
    TableFooter,
    TableHead,
    TableRow,
    Tooltip,
    Box
} from "@mui/material";
import {FormattedMessage} from "react-intl";
import {withStyles} from "@mui/styles";
import listStyle from "assets/jss/views/product/listStyle";
import {GetApp} from "@mui/icons-material";

function MerchandisingTableContainer({products, page, itemsPerPage, handlePagination, handleChangeItemsPerPage, totalItems, loading, handleExport, buildBody}) {
    const [selectedProducts, setSelectedProducts] = React.useState([]);
    const [globalCheck, setGlobalCheck] = React.useState(false);
    const [selectAll, setSelectAll] = React.useState(false);

    useEffect(() => {
        if (globalCheck) {
            const selection = products.map((p) => {
                return p.id;
            });

            setSelectedProducts(selection);
        }
        if (selectAll) {
            let selection = products.map((p) => {
                return p.id;
            });
            setSelectedProducts(selection);
        }
    }, [globalCheck, selectAll]);

    useEffect(() => {
        setSelectAll(false);
        setSelectedProducts([]);
        setGlobalCheck(false);
    }, [products])

    const handleGlobalCheckbox = (event) => {
        if (event.target.checked) {
            const selection = products.map((p) => {
                return p.id;
            });

            setSelectedProducts(selection);
            checkGlobalCheckboxIfNeed(selection.length);
            return;
        }
        setSelectedProducts([]);
        setSelectAll(false);
        checkGlobalCheckboxIfNeed(0);
    }

    const handleSelectAll = () => {
        let selection = [];
        if (selectAll) {
            setSelectAll(false);
            setSelectedProducts(selection);
        } else {
            setSelectAll(true);
            selection = products.map((p) => {
                return p.id;
            });

            setSelectedProducts(selection);
        }
        checkGlobalCheckboxIfNeed(selection.length);
    }

    const handleOnPageChange = (event, newPage) => {
        handlePagination(event, newPage);

        if (!selectAll) {
            checkGlobalCheckboxIfNeed(0);
        }
    }

    const checkGlobalCheckboxIfNeed = (selectionLength) => {
        if (selectionLength === products.length) {
            setGlobalCheck(true);
            return;
        }
        setGlobalCheck(false);
    }

    const handleExportClick = () => {
        handleExport(selectAll, selectedProducts);
    }

    const handleSelectSingle = (event, name) => {
        const selectedIndex = selectedProducts.indexOf(name);
        let newSelection = [];

        if (selectedIndex === -1) {
            newSelection = newSelection.concat(selectedProducts, name);
        } else if (selectedIndex === 0) {
            newSelection = newSelection.concat(selectedProducts.slice(1));
        } else if (selectedIndex === selectedProducts.length - 1) {
            newSelection = newSelection.concat(selectedProducts.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelection = newSelection.concat(
                selectedProducts.slice(0, selectedIndex),
                selectedProducts.slice(selectedIndex + 1),
            );
        }

        setSelectedProducts(newSelection);
        checkGlobalCheckboxIfNeed(newSelection.length);
    };

    const textSelectAllButton = selectAll ? "merchandising.show.button.deselect_all_products" : "merchandising.show.button.select_all_products";
    const isActionsDisabled = selectedProducts.length < 1 && !selectAll;
    return (
        <TableContainer>
            <Table aria-label="collapsible table">
                <TableHead sx={{ height: '79px'}}>
                    <TableRow>
                        <TableCell colSpan={5}>
                            <div>
                                <SelectBatchAction
                                    isActionsDisabled={isActionsDisabled}
                                    selectedProducts={selectedProducts}
                                    selectAll={selectAll}
                                    buildBody={buildBody}
                                />
                                <Tooltip title={"Export"}>
                                    <Box component="span">
                                        <IconButton
                                            disabled={isActionsDisabled}
                                            onClick={handleExportClick}
                                            sx={{
                                                'marginX': '5px'
                                            }}
                                        >
                                            <GetApp />
                                        </IconButton>
                                    </Box>
                                </Tooltip>
                                {(globalCheck && products.length > 0 && totalItems > itemsPerPage) &&
                                    <Button
                                        onClick={handleSelectAll}
                                        size='small'
                                        variant={selectAll ? 'contained' : 'outlined'}
                                        sx={{ fontSize: '12px'}}
                                        fullWidth={false}
                                    >
                                        <FormattedMessage id={textSelectAllButton} values={{nbProducts: totalItems}} />
                                    </Button>
                                }
                            </div>
                        </TableCell>
                        <MerchandisingPagination
                            page={page}
                            rowsPerPage={itemsPerPage}
                            rowsPerPageOptions={[10,25,50]}
                            count={totalItems}
                            onPageChange={handleOnPageChange}
                            onRowsPerPageChange={handleChangeItemsPerPage}
                            nextIconButtonProps={{ style: { display: "none" } }}
                            backIconButtonProps={{ style: { display: "none" } }}
                            labelRowsPerPage={''}
                            hideActions={true}
                            sx={{
                                '& .MuiTablePagination-toolbar': {paddingX: 0},
                                '& .MuiTablePagination-displayedRows': {order: -1},
                                '& .MuiTablePagination-spacer': {order: -3},
                                '& .MuiTablePagination-selectLabel': {order: 1},
                                '& .MuiTablePagination-select': {order: 0},
                            }}
                        />
                    </TableRow>
                </TableHead>
                <MerchandisingTableContent products={products} selectedProducts={selectedProducts} handleSelectSingle={handleSelectSingle} handleGlobalCheckbox={handleGlobalCheckbox} globalCheck={globalCheck} loading={loading}/>
                <TableFooter>
                    <TableRow>
                        <MerchandisingPagination
                            page={page}
                            rowsPerPage={itemsPerPage}
                            rowsPerPageOptions={[]}
                            count={totalItems}
                            onPageChange={handleOnPageChange}
                            sx={{
                                '& .MuiTablePagination-spacer': {display: "none"},
                                '& .MuiTablePagination-displayedRows': {display: 'none'}
                            }}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
    );
}

export default withStyles(listStyle)(MerchandisingTableContainer);
