import React from 'react';
import PropTypes from 'prop-types';

import BoldTitle from "./BoldTitle";

import {sixteenGrey} from "assets/jss/main";

TitleBlock.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string
};

export default function TitleBlock({title, subtitle}) {
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            padding: '0 12px 0 0',
            fontWeight: 400,
        }}>
            <BoldTitle>{title}</BoldTitle>
            {subtitle && (
                <span
                    style={{
                        color: sixteenGrey,
                        marginTop: '8px'
                    }}
                >
                    {subtitle}
                </span>
            )}
        </div>
    );
}
