import React from "react";
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";
import From from './components/form'

// @mui/material components
import {Dialog, DialogTitle, DialogContent} from "@mui/material";

// actions
import {list, reset} from "actions/authorization/list";
import {create} from "actions/role/create";

class CreateDialog extends React.Component {
    componentDidMount() {
        this.props.list()
    }

    componentWillUnmount() {
        this.props.reset()
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if(this.props.created !== nextProps.created) {
            this.props.closeDialog()
        }
    }

    render() {
        const {closeDialog, openAddMemeberDialog, authorizationsList, authorizationsLoading} = this.props;

        return (
            <Dialog
                open={openAddMemeberDialog}
                onClose={closeDialog}
                aria-labelledby="responsive-dialog-title"
                fullWidth={true}
                maxWidth={'md'}
                scroll={'body'}
            >
                <DialogTitle id="max-width-dialog-title">
                    <div>
                        <FormattedMessage id={"role.create.form.title"}/>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <From
                        onSubmit={this.props.create}
                        authorizationsList={authorizationsList}
                        closeDialog={closeDialog}
                        authorizationsLoading={authorizationsLoading}
                    />
                </DialogContent>
            </Dialog>
        )
    }
}


const mapStateToProps = state => {
    return {
        authorizationsList: state.authorization.list.retrieved,
        authorizationsLoading: state.authorization.list.loading,
        created: state.role.create.created,
    }
};

const mapDispatchToProps = dispatch => ({
    list: () => dispatch(list()),
    reset: () => dispatch(reset()),
    create: (values) => dispatch(create(values))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(CreateDialog);
