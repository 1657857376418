import {combineReducers} from "redux";

export function updateError(state = null, action) {
    switch (action.type) {
        case "PRODUCT_UPDATE_UPDATE_ERROR":
            return action.updateError;

        default:
            return state;
    }
}

export function updateLoading(state = false, action) {
    switch (action.type) {
        case "PRODUCT_UPDATE_UPDATE_LOADING":
            return action.updateLoading;

        default:
            return state;
    }
}

export function updateSuccess(state = null, action) {
    switch (action.type) {
        case "PRODUCT_UPDATE_UPDATE_SUCCESS":
            return action.updateSuccess;

        default:
            return state;
    }
}

export default combineReducers({
    updateError,
    updateLoading,
    updateSuccess
});
