import React from 'react'
import makeStyles from "@mui/styles/makeStyles";

import Fab from "@mui/material/Fab";

import createTheme from "@mui/material/styles/createTheme";
import ThemeProvider from "@mui/material/styles/ThemeProvider";

import {dangerColor, orangeReturn, successColor} from "assets/jss/main";

const useStyle = makeStyles({
    fab: {
        boxShadow: 'unset'
    }
});

const theme = createTheme({
    palette: {
        primary: {main: successColor},
        secondary: {main: dangerColor},
        warning: {main: orangeReturn}
    }
})

export default function FabButton(props) {
    const classes = useStyle();

    return (
        <ThemeProvider theme={theme}>
            <Fab className={classes.fab} {...props}>
                {props.children}
            </Fab>
        </ThemeProvider>
    )
}
