import {fetch} from "../../utils/dataAccess";
import {downloadFileFromBlob} from "../../utils/createDownloadableFile";

export async function downloadQuotePdf(quoteId, quoteNumber) {
    if (!quoteNumber) {
        quoteNumber = quoteId;
    }

    const filename = `devis-${quoteNumber}.pdf`;

    return new Promise((resolve, reject) => {
        fetch(`/quotes/${quoteId}/pdf`)
            .then(response => response.blob())
            .then(blob => {
                downloadFileFromBlob(filename, blob);
                resolve(true);
            })
            .catch(e => reject(e))
    });
}
