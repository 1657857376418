import {dangerColor, grayColor, primaryColor, boxShadow, successColor} from "../../../main";

const formStyle = {
    formContainer: {
        minHeight: window.innerHeight * 0.84,

        "& > div:first-child": {
            background: "transparent",
            height: "150px"
        }
    },
    fieldContainer: {
        marginTop: "10px",
        padding: "5px",
        "& > div:first-child": {
            overflow: "visible"
        }
    },
    fieldMappingLabel: {
        color: primaryColor,
        fontWeight: "bold",
        alignSelf: "center"
    },
    selectField: {
        marginTop: "-16px",
        "& > div:first-child": {
            "& > div:nth-child(2)": {
                display: "none"
            },
        },
        "& > div:nth-child(2)": {
            "& > div:nth-child(2)": {
                display: "none"
            },
        },

    },
    actionsContainer: {
        textAlign: "right",
    },
    submitLoading: {
        marginLeft: "5px",
        color: "#FFFFFF"
    },
    inviteFieldContainer: {
        display: "flex",
        flexDirection: "column",
        width: "100%"
    },
    line: {
        width: "100%",
        height: "47px",
        borderBottom: "2px solid" + dangerColor,
        position: "absolute",
        marginTop: "-40px",
        "& div": {
            display: "flex",
            justifyContent: "space-between"
        }
    },
    lineContainer: {
        textAlign: "center",
        marginTop: "12px",
        textTransform: "uppercase",
        "& span": {
            fontWeight: "bold"
        }
    },
    round: {
        width: "10px",
        height: "10px",
        borderRadius: "50%",
        background: dangerColor,
        marginTop: "41px"
    },
    spaceEvenlyGrid: {
        display: "flex",
        flexDirection: "row-reverse",
        marginTop: "25px"
    },
    listButton: {
        marginTop: "-22px",
        background: "white",
        "& span svg:nth-child(1)": {
            fill: primaryColor,
            fontSize: "35px"
        },
    },
    defaultValueText: {
        textAlign: "right",
        float: "right",
        marginTop: "-5px"
    },
    selectedStepper: {
        "& span": {
            color: `${primaryColor}!important`
        },
        "& span svg:nth-child(1)": {
            fill: `${primaryColor}!important`,
            fontSize: "50px",

        },

    },
    stepper: {
        "& > span:first-child > span:first-child ": {
            borderRadius: "50%",
            ...boxShadow
        },
        "& span svg:nth-child(1)": {
            fill: "white",
            fontSize: "50px",
            "& > text": {
                fill: `${grayColor}!important`,
                fontSize: "10px"
            },
        },
    },
    step: {
        "& > div:first-child": {
            top: "25px",
            left: "calc(-50% + 27px)"
        },
    },
    fieldMappingSubTitle: {
        display: "block",
        fontSize: "12px",
        color: grayColor,
    },
    separateLine: {
        marginTop: "30px",
        width: "73%",
        marginBottom: "30px"
    },
    labelStyle: {
        height: "calc(100vh - 250px)",
        overflowY: "scroll",
        padding: "0px 15px"
    },
    modalTitle: {
        textAlign: "center"
    },
    draggableStyle: {
        height: "100vh",
        "& > div:first-child": {
            height: "100%",
        },
    },
    draggableLabels: {
        color: primaryColor,
        fontWeight: "bold",
    },
    draggableCardBody: {
        paddingTop: "8px",
        paddingBottom: "8px",
        paddingLeft: "15px",
    },
    draggableCard: {
        marginTop: "8px",
        marginBottom: "8px",
    },
    draggableCardMasterRight: {
        boxShadow: "0 0 0",
        borderLeft: "1px solid #DDD",
        borderRadius: 0,
        maxHeight: "600px",
        marginTop: "0",
        marginBottom: "0",
    },
    draggableCardMasterLeft: {
        boxShadow: "0 0 0",
        borderRight: "1px solid #DDD",
        borderRadius: 0,
        maxHeight: "600px",
        marginTop: "0",
        marginBottom: "0",
    },
    draggableNoScroll: {
        overflow: "scroll",
    },
    expertModePopover: {
        padding: "10px",
        "& .rta__autocomplete": {
            overflow: "auto",
            height: "150px"
        }
    },
    myTextarea: {
        fontSize: "20px",
        padding: "5px",
        border: "2px solid black",
        fontFamily: "Roboto !important"
    },
    propertyTitle: {
        paddingLeft: "10px",
        textTransform: "capitalize"
    },
    expertModeActionArea:{
        display: "flex",
        justifyContent: "flex-end",
        paddingRight: "10px"
    },
    expertModeButton: {
        height: "10px"
    },
    accordionExpertMode: {
        border: 0,
        boxShadow: "none",
    },
    accordionSummaryExpertMode: {
        padding: "0 10px 0 10px",
        color: primaryColor,
    },
    accordionSummaryExpandedExpertMode: {
        margin: 0,
    },
    accordionExpertModeIcon: {
        paddingTop: 0,
        paddingBottom: 0,
    },
    expertModeEntriesBox: {
        display: "flex",
    },
    expertModeEntriesBoxIcon: {
        cursor: "pointer",
        color: primaryColor,
    },
    expertModeEntriesBoxInput: {
        marginRight: "5px",
        border: "none",
        borderBottom: "1px solid grey",
    },
    accordionDetailsExpertMode: {
        display: "block",
        padding: "5px 10px 5px",
        maxWidth: "400px",
    },
    expertModeResultError: {
        color: dangerColor,
        overflowWrap: "break-word",
    },
    expertModeResultSuccess: {
        color: successColor,
        overflowWrap: "break-word",
    }

};

export default formStyle;
