import React from "react";
import {FormattedMessage} from "react-intl";

import FormattedNumberWithCurrency
    from "components/formattedNumberWithCurrencies/formattedNumberWithCurrency";

import CardStats from "../cards/cardStats";
import CardStatsBody from "../cards/cardStatsBody";
import CardStatsFooter from "../cards/cardStatsFooter";
import CardStatsTitle from "../cards/cardStatsTitle";
import CardStatsValue from "../cards/cardStatsValue";
import Loader from "../loader";

function VisitorValue(props) {

    const { valueVisitor, physicalValueVisitor, loading, currency } = props

    return (
        <CardStats>
            <Loader loading={loading} />
            <CardStatsTitle>
                <FormattedMessage id={"dashboard.graph.valueVisitor.title"}/>
            </CardStatsTitle>
            <CardStatsBody>
                <CardStatsValue>
                    <FormattedNumberWithCurrency
                        value={valueVisitor}
                        currency={currency}
                    />
                </CardStatsValue>
            </CardStatsBody>
            <CardStatsFooter>
                <div>
                    <span>
                        <FormattedMessage id={"dashboard.card.info.online"} />
                    </span>
                    <FormattedNumberWithCurrency
                        value={valueVisitor}
                        currency={currency}
                    />
                </div>
                <div>
                    <span>
                        <FormattedMessage id={"dashboard.card.info.physical"} />
                    </span>
                    <FormattedNumberWithCurrency
                        value={physicalValueVisitor}
                        currency={currency}
                    />
                </div>
            </CardStatsFooter>
        </CardStats>
    )
}

export default VisitorValue;
