import React, { createContext } from 'react';
import useColors from "views/marketplace/merchandising/hook/useColors";
import useBrands from "views/marketplace/merchandising/hook/useBrands";
import useCategories from "views/marketplace/merchandising/hook/useCategories";
import useCorners from "views/marketplace/merchandising/hook/useCorners";
import useGenders from "views/marketplace/merchandising/hook/useGenders";
import useRetailers from "views/marketplace/merchandising/hook/useRetailers";
import useSizes from "views/marketplace/merchandising/hook/useSizes";
import useStatus from "views/marketplace/merchandising/hook/useStatus";

export const ProductDataContext = createContext([]);

export function ProductDataProvider({ children }) {

    const { colorsLoaded, colorsFilters } = useColors();
    const { brandsLoaded, brandsFilters } = useBrands();
    const { categoriesLoaded, categoriesFilters } = useCategories();
    const { cornersLoaded, cornersFilters } = useCorners();
    const { gendersLoaded, gendersFilters } = useGenders();
    const { retailersLoaded, retailersFilters } = useRetailers();
    const { sizesLoaded, sizesFilters } = useSizes();
    const { statusLoaded, statusFilters } = useStatus();

    return (
        <ProductDataContext.Provider
            value={{
                colorsLoaded,
                colorsFilters,
                brandsLoaded,
                brandsFilters,
                categoriesLoaded,
                categoriesFilters,
                cornersLoaded,
                cornersFilters,
                gendersLoaded,
                gendersFilters,
                retailersLoaded,
                retailersFilters,
                sizesLoaded,
                sizesFilters,
                statusLoaded,
                statusFilters,
            }}>
            {children}
        </ProductDataContext.Provider>
    );
}
