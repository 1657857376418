import {fetch, normalize} from "utils/dataAccess";
import normalizeTranslations from "../translations";

export function error(error) {
    return { type: "GENDER_UPDATE_ERROR", error };
}

export function loading(loading) {
    return { type: "GENDER_UPDATE_LOADING", loading };
}

export function success(updated) {
    return { type: "GENDER_UPDATE_SUCCESS", updated };
}

export const update = (item, values) => dispatch => {
    dispatch(loading(true));
    values = normalizeTranslations(values);

    return fetch(item["@id"], {
        method: 'PUT',
        headers: new Headers({'Content-Type': 'application/ld+json'}),
        body: JSON.stringify(normalize(values))
    })
        .then(response => {
            response.json().then(retrieved => {
                dispatch(loading(false));
                dispatch(success(retrieved));
            })
        })
        .catch((e) => {
            dispatch(loading(false));
            dispatch(error(e));
        });
};
