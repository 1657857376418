import React, { useState } from "react";

// @mui/material components
import {makeStyles} from "@mui/styles";

// core components
import Boxes from "./boxes";
import CreateDialog from './createDialog';
import DeleteDialog from './deleteDialog';
import EditDialog from './editDialog';
import NoResult from '../../configuration/components/noResult';
import { default as Popover } from '../../configuration/components/popover';

// styles
const useStyles = makeStyles(theme => ({
    removeUlPadding: {
        padding: 0,
        margin: 0,
        listStyleType: "none"
    },
}));

export default function EnhancedTableBody (props) {
    const { checkedGenders, retrieved, search, setCheckedGenders } = props;

    const classes = useStyles();

    const [createSubDialog, showCreateSubDialog] = useState(false);
    const [deleteDialog, showDeleteDialog] = useState(false);
    const [editDialog, showEditDialog] = useState(false);

    const [genderSelected, setGenderSelected] = useState(null);
    const [genderTablePosition, setGenderTablePosition] = useState(null);

    const [openPopover, setOpenPopover] = useState(false);

    return (

        <div className={classes.removeUlPadding}>
            {(retrieved && retrieved.length > 0) && (
                <Boxes
                    allGenders={retrieved}
                    openCreatedDialog={() => showCreateSubDialog(true)}
                    selectGender={setGenderSelected}
                    setGenderTablePosition={setGenderTablePosition}
                    openPopover={() => setOpenPopover(true)}
                    search={search}
                    checkedGenders={checkedGenders}
                    setCheckedGenders={setCheckedGenders}
                />
            )}
            {retrieved && retrieved.length === 0 && (
                <NoResult />
            )}
            {genderSelected && (
                <div>
                    <CreateDialog
                        isOpen={createSubDialog}
                        closeDialog={() => showCreateSubDialog(false)}
                        gender={genderSelected}
                        isSubGender={true}
                    />
                    <DeleteDialog
                        isOpen={deleteDialog}
                        closeDialog={() => showDeleteDialog(false)}
                        gender={genderSelected}
                    />
                    <EditDialog
                        isOpen={editDialog}
                        closeDialog={() => showEditDialog(false)}
                        gender={genderSelected}
                        isSubGender={genderSelected.parent !== null}
                    />
                </div>
            )}
            <Popover
                isPopoverOpen={openPopover}
                closePopover={() => setOpenPopover(false)}
                position={genderTablePosition}
                openDeleteDialog={() => showDeleteDialog(true)}
                openEditDialog={() => showEditDialog(true)}
                attributeName={'gender'}
            />
        </div>
    );
}
