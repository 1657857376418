import React, {useEffect, useState, useRef} from "react";
import {connect} from "react-redux";
import {DragDropContext, Droppable} from "react-beautiful-dnd";

// @mui/material components
import {makeStyles} from "@mui/styles";

// core components
import Category from './category';

// action
import { updatePosition } from "actions/category/updatePosition";

// helper
import {getTranslation} from "../../../domain/helpers/translations";

// styles
const useStyles = makeStyles(theme => ({
    childrenList: {
        position: "relative",
        background: "green !important"
    },
    removeStyleType: {
        listStyleType: "none",
    },
    removePadding :{
        listStyleType: "none",
        paddingInlineStart: "0px"
    }
}));

function Children (props) {
    const { allCategories, isTopParent, search, updatePosition } = props;

    const classes = useStyles();

    const [children, setChildren] = useState(props.children ? props.children : []);

    const mounted = useRef();

    // used to re set props.children to children const when created or deleted or updated
    useEffect(() => {
        if (!mounted.current) {
            mounted.current = true;
        } else {
            // componentDidUpdate logic
            if (props.created || props.deleted || props.updated ) {
                setChildren(props.children);
            }
        }
    });

    function isChildOrGrandChildrenContainSearch(category) {
        if (getTranslation(category).name.toLowerCase().includes(search)) {
            return true;
        } else if (hasChildren(category)) {
            let children = getChildren(category);

            for (let i = 0; i < children.length; i++) {
                if (isChildOrGrandChildrenContainSearch(children[i])) {
                    return true;
                }
            }
        } else {
            return false;
        }
    }

    function hasChildren(category) {
        return !!allCategories.filter(item => item.parent !== null && item.parent.id === category.id).length > 0;
    }

    function getChildren(category) {
        return allCategories.filter(item => item.parent && item.parent.id === category.id);
    }

    function reorder(list, startIndex, endIndex) {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    }

    function onDragEnd(result) {
        if (!result.destination) {
            return;
        }

        if (result.destination.index === result.source.index) {
            return;
        }

        const categories = reorder(
            children,
            result.source.index,
            result.destination.index
        );

        // update position in front
        setChildren(categories);

        // assignPositionToCategory
        let formattedCategories = [];

        categories.forEach((category, index) => {
            let item = {
                "id": category.id,
                "position": index + 1
            };

            formattedCategories.push(item);
        });

        updatePosition({"categories": formattedCategories});
    }

    return (
        <DragDropContext onDragEnd={onDragEnd} className={classes.childrenList}>
            <Droppable droppableId={"list"}>
                {provided => (
                    <ul className={isTopParent ? classes.removePadding : classes.removeStyleType}>
                        <div ref={provided.innerRef} {...provided.droppableProps}>
                            {children.map((child, index) => {
                                if (search.length > 0 ? isChildOrGrandChildrenContainSearch(child) : true) {
                                    return (
                                        <Category
                                            key={child.id}
                                            data={child}
                                            provided={provided}
                                            draggableId={child.id}
                                            index={index}
                                            {...props}
                                        />
                                    )
                                } else return null;
                            })}
                        </div>
                        {provided.placeholder}
                    </ul>
                )}
            </Droppable>
        </DragDropContext>
    )
}

const mapStateToProps = (state) => {
    return {
        created: state.category.create.created,
        deleted: state.category.deleteCategory.deleted,
        updated: state.category.update.updated,
    };
};

const mapDispatchToProps = dispatch => ({
    updatePosition: (params) => dispatch(updatePosition(params)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Children);
