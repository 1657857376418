import {fetch} from "utils/dataAccess";
import {ITEM_PER_PAGE} from "config/_pagination";

function error(error) {
    return {type: "MEMBER_LIST_ERROR", error};
}

function loading(loading) {
    return {type: "MEMBER_LIST_LOADING", loading};
}

function success(retrieved) {
    return {type: "MEMBER_LIST_SUCCESS", retrieved};
}

function requestCountError(error) {
    return {type: "MEMBER_LIST_REQUEST_COUNT_ERROR", error};
}

function requestCountLoading(loading) {
    return {type: "MEMBER_LIST_REQUEST_COUNT_LOADING", loading};
}

function requestCountSuccess(retrieved) {
    return {type: "MEMBER_LIST_REQUEST_COUNT_SUCCESS", retrieved};
}

export function list(params = `itemsPerPage=${ITEM_PER_PAGE}&pagination=true&page=1`) {
    return dispatch => {
        dispatch(loading(true));

        return fetch("/members?" + params)
            .then(response => {
                response.json().then(retrieved => {
                    dispatch(loading(false));
                    dispatch(success(retrieved));
                }).catch((e) => {
                    dispatch(loading(false));
                    dispatch(error(e));
                });
            }).catch(error => {
                // canceled
            })

    }
}

export function requestCount() {
    return dispatch => {
        dispatch(requestCountLoading(true));

        return fetch("/members?invitationToken[exists]=false&invitationEmail[exists]=true&pagination=false")
            .then(response => {
                response.json().then(retrieved => {
                    dispatch(requestCountLoading(false));
                    dispatch(requestCountSuccess(retrieved["hydra:totalItems"]));
                }).catch((e) => {
                    dispatch(requestCountLoading(false));
                    dispatch(requestCountError(e));
                });
            }).catch(error => {
                // canceled
            })
    }
}


export function reset() {
    return dispatch => {
        dispatch({type: "MEMBER_LIST_RESET"});
    };
}

