import {dangerColor, primaryColor} from "../../main";

const SelectStyle = theme => ({
    label: {
        color: primaryColor,
        fontWeight: "400"
    },
    select: {
        marginBottom: "1.6rem",
        "& .app__menu": {
            'z-index': 10,
        },
    },
    errorSelect: {
        color: dangerColor,
        border: "1px solid " + dangerColor
    },
    errorLabel: {
        color: "red"
    }
})

export default SelectStyle;
