import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from "react-intl";

import {greenColor} from "assets/jss/main";

SuccessMessage.propTypes = {
    messageId: PropTypes.string.isRequired
};

export default function SuccessMessage({messageId}) {
    return (
        <span style={{
            fontSize: '12px',
            color: greenColor,
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: 'normal'
        }}>
            <FormattedMessage id={messageId} />
        </span>
    );
}
