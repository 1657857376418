import React, {Component} from "react";
import * as Cookies from "js-cookie";

// core components
import GridContainer from "components/grid/gridContainer";
import GridItem from "components/grid/gridItem";
import CardBody from "components/card/cardBody";

// utils
import Card from "../components/card/card";
import CardHeader from "../components/card/cardHeader";
import squareLogo from "../assets/img/squareLogo.png";
import {FormattedMessage, injectIntl} from "react-intl";
import {Button} from "@mui/material";
import {Link} from "react-router-dom";
import logoWishibamGrey from "../assets/img/logoWishibamGrey.png";
import notVerifiedAccountStyle from "../assets/jss/views/notVerifiedAccountStyle";
import {connect} from "react-redux";
import {withStyles} from "@mui/styles";

class ValidateAccount extends Component {
    constructor(props) {
        super(props);
    }
    componentWillUnmount() {
        this.props.history.push('/logout');
    }

    componentDidMount() {
        if (localStorage.getItem('validateScreen')) {
            localStorage.clear();
            this.props.history.push('/logout');
        } else {
            localStorage.setItem('validateScreen', true);
        }
    }

    render() {
        const {classes} = this.props;

        return (
            <GridContainer direction="row" justifyContent="center" alignItems="center" className={classes.containerCard}>
                <GridItem xs={12} className={classes.card}>
                    <Card className={classes.card}>
                        <CardHeader className={classes.cardHeader}>
                            <GridContainer>
                                <GridItem xs={12}>
                                    <img className={classes.logo} src={squareLogo} alt={""}/>
                                    <h3><FormattedMessage id={"user.account.not_verified"}/></h3>
                                </GridItem>
                            </GridContainer>
                        </CardHeader>
                        <CardBody className={classes.cardBody}>
                            <GridContainer>
                                <GridItem xs={12}>
                                    <div>
                                        <div className={classes.error}><FormattedMessage id={"user.account.not_verified.error"}/></div><br/>
                                        <span className={classes.bold}><FormattedMessage id={"user.account.not_verified.details"}/></span><br/>
                                        <span className={classes.help}><FormattedMessage
                                            id={"user.account.not_verified.spam"}/></span><br/><br/>
                                        <FormattedMessage id={"user.account.not_verified.assistance"}/><br/><br/>
                                        <Link to={'/'} className={classes.link}>
                                            <Button className={classes.button}>
                                                <FormattedMessage id={"retailer.stripe.confirm.action.back"}/>
                                            </Button>
                                        </Link>
                                    </div>
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                    </Card>
                    <Link to={'/logout'} className={classes.logoutLink}>
                        <FormattedMessage id={'retailer.stripe.action.logout'}/>
                    </Link><br/>
                    <img className={classes.logoFooter} src={logoWishibamGrey} alt={""}/>
                </GridItem>
            </GridContainer>
        );
    }
}

export default connect()(withStyles(notVerifiedAccountStyle)(injectIntl(ValidateAccount)));
