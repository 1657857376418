import {fetch, normalize} from "utils/dataAccess";

import normalizeTranslations from "actions/translations";

import persist from "./persister";

export function update(item, values) {
    values = normalizeTranslations(values);

    return new Promise((resolve, reject) => {
        const data = persist(values);

        fetch(item["@id"], {
            method: "PUT",
            body: JSON.stringify(data)
        }).then(response => response.json()).then((retrieved) => {
            resolve(normalize(retrieved));
        }).catch(error => reject(error));
    });
}

export function updateProfile(item, values) {
    values = normalizeTranslations(values);

    return new Promise((resolve, reject) => {
        const data = persist(values);

        fetch(`retailer_profiles/${item.id}`, {
            method: "PUT",
            body: JSON.stringify(data)
        }).then(response => response.json()).then((retrieved) => {
            resolve(normalize(retrieved));
        }).catch(error => reject(error));
    });
}
