import {useEffect, useState} from "react";
import {fetch} from "utils/dataAccess";
import SimpleFilterPanel from "views/marketplace/merchandising/filter/simpleFilterPanel";

const Filter = (tabTitle, tabContent) => { return { tabTitle: tabTitle, tabContent: tabContent } }

export default function useColors() {

    const [colorsFilters, setColorsFilters] = useState(null);
    const [colorsLoaded, setColorsLoaded] = useState(false);

    useEffect(() => {
        fetch("/colors?pagination=false&draft=false&translatedOrder[name]=asc").then(
            response => {
                response.json().then(retrieved => {
                    // Adding missing API propperty
                    retrieved['hydra:member'].forEach((item) => {
                        item['@type'] = 'Color';
                    });

                    setColorsFilters(
                        new Filter(
                            "colors",
                            {
                                component: SimpleFilterPanel,
                                items: retrieved['hydra:member'],
                            }
                        )
                    );

                    setColorsLoaded(true);
                });
            }
        );
    }, []);

    return {
        colorsLoaded,
        colorsFilters
    };
}
