import React from 'react';
import PropTypes from "prop-types";

import {ThemeProvider} from "@mui/material/styles";

import NoteContainer from "./NoteContainer";
import NoteCard from "./NoteCard";

Index.propTypes = {
    theme: PropTypes.object
}

export default function Index(props) {
    return (
        <ThemeProvider theme={props.theme}>
            <NoteContainer>
                <NoteCard>
                    {props.children}
                </NoteCard>
            </NoteContainer>
        </ThemeProvider>
    );
}
