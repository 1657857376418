export const roundPrice = (price, decimalsCount = 2) => {
    const p = Math.pow(10, decimalsCount);
    return Math.round(humanize(price * p)) / p;
};

function humanize(x){
    // before 9.995 * 100 = 999.4999999999999
    // after: 9.995 * 100 = 999.5
    return parseFloat(x.toFixed(6).replace(/\.?0*$/,''));
}

export function getLineCalculator(unitPriceTaxIncluded, taxRate, quantity, discount = 0) {
    const applyDiscount = (total) => {
        const discountPercentage = discount;
        if (discountPercentage === 0) {
            return total;
        }

        return total - (total * discountPercentage / 100);
    }
    const getTotalTaxExcluded = (shouldApplyDiscount = true) => {
        const totalHt = getTotalWithTax(shouldApplyDiscount) / (1 + taxRate / 100);
        return roundPrice(totalHt, 2);
    }

    const getTotalWithTax = (shouldApplyDiscount = true) => {
        if (!shouldApplyDiscount) {
            const total = unitPriceTaxIncluded * quantity;
            return roundPrice(total);
        }
        return roundPrice(roundPrice(applyDiscount(unitPriceTaxIncluded))*quantity);
    }

    return {
        // Simple accessors
        taxRate,
        quantity,
        discount,
        unitPriceWithTax: unitPriceTaxIncluded,

        // Do some calculation
        applyDiscount,
        getTotalWithTax,
        getTotalTaxExcluded,
    }
}

export const convertHT_to_TTC = (priceHT, taxRate) => roundPrice(priceHT * (1 + taxRate / 100));
