import React, {Component} from 'react';
import {Line} from "react-chartjs-2";
import {DateRangePicker} from "react-dates";
import connect from "react-redux/es/connect/connect";
import {BeatLoader} from "react-spinners";
import {FormattedMessage, injectIntl} from "react-intl";
import * as moment from "moment";

// @mui/material components
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select
}  from '@mui/material'

// styles
import priceChartStyle from "assets/jss/views/product/priceChartStyle";
import {primaryColor} from "../../../../../assets/jss/main";

// actions
import {retrieveVariationPriceStockData} from "../../../../../actions/variation/priceStockData";
import {withStyles} from "@mui/styles";

class PriceChart extends Component {

    constructor(props) {
        super(props);

        const date = new Date();
        const endDate = moment(date);

        date.setDate(date.getDate() - 20);
        const startDate = moment(date);

        this.state = {
            startDate: startDate,
            initialStartDate: startDate,
            endDate: endDate,
            initialEndDate: endDate,
            unit: 'day',
            chartData: {},
        }
    }
    componentDidMount() {
        this.loadChartData();
    }

    componentDidUpdate(prevProps) {
        if (this.props.currentVariation !== prevProps.currentVariation) {
            this.loadChartData();
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (this.props.variationPriceStockData !== nextProps.variationPriceStockData) {

            let xIndexes = {};
            let priceData = [];
            let stockData = [];

            if (this.state.unit === 'detail') {
                xIndexes = [...new Set([...Object.keys(nextProps.variationPriceStockData.price),...Object.keys(nextProps.variationPriceStockData.stock)])];
                priceData = Object.values(Object.assign(xIndexes.reduce((b,i)=> (b[i]=null,b),{}), nextProps.variationPriceStockData.price));
                stockData = Object.values(Object.assign(xIndexes.reduce((b,i)=> (b[i]=null,b),{}), nextProps.variationPriceStockData.stock));
            } else {
                xIndexes = Object.keys(nextProps.variationPriceStockData.price);
                priceData = Object.values(nextProps.variationPriceStockData.price);
                stockData = Object.values(nextProps.variationPriceStockData.stock);
            }

            let chartData = {
                labels: xIndexes,
                datasets: [
                    {
                        label: this.props.intl.formatMessage({id: "variation.graph.label.price"}),
                        data: priceData,
                        fill: false,
                        backgroundColor: 'rgb(0, 214, 86)',
                        borderColor: 'rgba(0, 214, 86, .8)',
                        yAxisID: 'y-axis-1',
                        tension: 0.1,
                        pointBorderWidth: 4,
                        spanGaps: true,
                    },
                    {
                        label: this.props.intl.formatMessage({id: "variation.graph.label.stock"}),
                        data: stockData,
                        fill: false,
                        backgroundColor: 'rgb(0, 82, 203)',
                        borderColor: 'rgba(0, 82, 203, .8)',
                        yAxisID: 'y-axis-2',
                        tension: 0.1,
                        pointBorderWidth: 4,
                        spanGaps: true,
                    },
                ],
            };
            this.setState({chartData : chartData});
        }
    }

    handleDateChange(dates) {
        if (dates.startDate === null || dates.endDate === null) {
            dates = {startDate: this.state.initialStartDate, endDate: this.state.initialEndDate}
        }

        this.setState({startDate: dates.startDate, endDate: dates.endDate}, function () {
            this.loadChartData();
        })
    };

    handleChartPrecisionChange = (event) => {
        this.setState({unit: event.target.value}, function () {
            this.loadChartData();
        })
    };

    loadChartData = () => {
        this.props.retrieveVariationPriceStockData(this.props.currentVariation.id, this.state.startDate.format("Y-M-D"), this.state.endDate.format("Y-M-D"), this.state.unit);
    }

    render() {
        const {classes} = this.props;

        const options = {
            scales: {
                yAxes: [
                    {
                        type: 'linear',
                        display: true,
                        position: 'left',
                        id: 'y-axis-1',
                    },
                    {
                        type: 'linear',
                        display: true,
                        position: 'right',
                        id: 'y-axis-2',
                    },
                ],
            },
        };

        return (
            <div className={classes.container}>
                <div className={classes.datePicker}>
                    <DateRangePicker
                        startDateId="startDate"
                        endDateId="endDate"
                        startDatePlaceholderText={"-"}
                        endDatePlaceholderText={"-"}
                        startDate={this.state.startDate}
                        endDate={this.state.endDate}
                        onDatesChange={({startDate, endDate}) => {
                            this.setState({startDate, endDate})
                        }}
                        focusedInput={this.state.focusedInput}
                        onFocusChange={(focusedInput) => {
                            this.setState({focusedInput})
                        }}
                        onClose={(dates) => this.handleDateChange(dates)}
                        displayFormat={"D MMMM YYYY"}
                        block={true}
                        readOnly={true}
                        isOutsideRange={() => false}
                        showDefaultInputIcon={true}
                        showClearDates={true}
                        reopenPickerOnClearDates={true}
                        required={true}
                        hideKeyboardShortcutsPanel={true}
                        withFullScreenPortal={window.innerWidth < 768}
                        numberOfMonths={2}
                        orientation={window.innerWidth < 768 ? "vertical" : "horizontal"}
                        anchorDirection={"right"}
                        minimumNights={0}
                    />

                    <FormControl variant="standard" className={classes.formControl}>
                        <InputLabel id="simple-select-label"><FormattedMessage id={"variation.graph.unit.label"}/></InputLabel>
                        <Select
                            variant="standard"
                            labelId="simple-select-label"
                            id="simple-select"
                            value={this.state.unit}
                            onChange={this.handleChartPrecisionChange}>
                            <MenuItem value={'day'}><FormattedMessage id={"variation.graph.unit.day"}/></MenuItem>
                            <MenuItem value={'month'}><FormattedMessage id={"variation.graph.unit.month"}/></MenuItem>
                            <MenuItem value={'year'}><FormattedMessage id={"variation.graph.unit.year"}/></MenuItem>
                            <MenuItem value={'detail'}><FormattedMessage id={"variation.graph.unit.detail"}/></MenuItem>
                        </Select>
                    </FormControl>
                </div>

                <b><FormattedMessage id={"variation.graph.title"}/></b><br/>

                {this.props.variationPriceStockDataLoading ?
                    <div className={classes.beatLoader}>
                        <BeatLoader
                            sizeUnit={"px"}
                            size={10}
                            color={primaryColor}
                            loading={true}
                        />
                    </div>
                :
                    <Line data={this.state.chartData} options={options} />
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => {

    return {
        variationPriceStockData: state.variation.priceStockData.retrieveVariationPriceStockData,
        variationPriceStockDataLoading: state.variation.priceStockData.retrieveVariationPriceStockDataLoading,
    }
}

const mapDispatchToProps = dispatch => ({
    retrieveVariationPriceStockData: (variationId, startDate, endDate, unit) => dispatch(retrieveVariationPriceStockData(variationId, startDate, endDate, unit)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(priceChartStyle)(injectIntl(PriceChart)));
