import {Component} from "react";
import {connect} from "react-redux";

class HandleApplicationRoot extends Component {
    UNSAFE_componentWillMount() {
        if (!this.props.member) {
            this.props.history.push("/login");
        } else if (this.props.applications["marketplace"] && null !== this.props.member.retailers && 1 === this.props.member.retailers.length) {
            this.props.history.push(`/retailers/show/${encodeURIComponent(this.props.member.retailers[0]["@id"])}`);
        } else if (this.props.applications["statistics"]) {
            this.props.history.push("/statistics");
        }else if (!this.props.applications["marketplace"]) {
            this.props.history.push("/picking");
        }
    }

    render() {
        return null;
    }
}

const mapStateToProps = (state) => {
    return {
        applications: state.authentication.applications,
        member: state.authentication.member
    };
};

export default connect(mapStateToProps)(HandleApplicationRoot);
