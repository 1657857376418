import React, { useContext } from "react";
import Chip from '@mui/material/Chip';
import {FilterDispatchContext} from "contexts/filterContext";

export default function RemoveFilterChip({label, item}) {
    const dispatch = useContext(FilterDispatchContext);

    const handleDelete = (item) => {
        dispatch({
            type: 'filter_remove',
            item: item
        });
    };

    return (
        <Chip
            label={label}
            variant="contained"
            onDelete={() => handleDelete(item)}
            color={"primary"}
            sx={{ marginRight: '5px', fontWeight: '500', marginY: '5px' }}
        />
    );
}
