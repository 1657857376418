import React from 'react';
import PropTypes from "prop-types";

import {styled} from "@mui/styles";

import AccordionSummary from "@mui/material/AccordionSummary";

import HistoryNoteAccordionSummaryTitle from "./HistoryNoteAccordionSummaryTitle";

import {ReactComponent as ArrowDown} from "assets/img/arrow-down.svg";

import {QUOTE_NOTE_TYPES} from "../../../../../enums/quoteNoteType";

HistoryNoteAccordionSummary.propTypes = {
    noteType: PropTypes.oneOf(QUOTE_NOTE_TYPES).isRequired
}

function HistoryNoteAccordionSummary({noteType}) {
    const StyledAccordionSummary = styled(AccordionSummary)(({ theme })=> ({
        padding: '20px 32px',
        '& .MuiAccordionSummary-content': {
            margin: 0,
        },
        [theme.breakpoints.down('md')]: {
            padding: '16px'
        },
    }));

    return (
        <StyledAccordionSummary expandIcon={<ArrowDown width={24} height={24} />}>
            <HistoryNoteAccordionSummaryTitle noteType={noteType} />
        </StyledAccordionSummary>
    );
}

export default HistoryNoteAccordionSummary;
