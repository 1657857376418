import React from "react";
import GridItem from "components/grid/gridItem";
import { injectIntl } from "react-intl";
import { Field } from "redux-form";
import FormFields from 'views/categories/components/formFields';
import { TagPosition } from "./tagPosition";

function TagForm(props) {

    const { intl, tagEnabled, tagColors } = props;

    function formatChoices(options, labelPrefix) {
        const formattedOptions = [];

        Object.entries(options).forEach(entry => {
            const [name, value] = entry;
            formattedOptions.push({
                'value': value,
                'label': intl.formatMessage({id: labelPrefix + name.toLowerCase()})
            });
        });

        return formattedOptions
    }

    if (tagEnabled) {
        return (
            <>
                <GridItem xs={12} sm={12}>
                    <Field
                        component={FormFields}
                        name={'tagPriority'}
                        label={intl.formatMessage({id: "corner.form.label.tagPriority"})}
                        type={"text"}
                        placeholder={intl.formatMessage({id: "corner.form.placeholder.tagPriority"})}
                        required={true}
                    />
                </GridItem>
                <GridItem xs={12} sm={12}>
                    <Field
                        component={FormFields}
                        name={'tagColor'}
                        label={intl.formatMessage({id: "corner.form.label.tagColor"})}
                        type={"select"}
                        options={formatChoices(tagColors, 'corner.form.label.color.')}
                        placeholder={intl.formatMessage({id: "corner.form.placeholder.tagColor"})}
                    />
                </GridItem>
                <GridItem xs={12} sm={12}>
                    <Field
                        component={FormFields}
                        name={'tagPosition'}
                        label={intl.formatMessage({id: "corner.form.label.tagPosition"})}
                        type={"select"}
                        options={formatChoices(TagPosition, 'corner.form.label.position.')}
                        placeholder={intl.formatMessage({id: "corner.form.placeholder.tagPosition"})}
                    />
                </GridItem>
            </>
        );
    }
}

export default injectIntl(TagForm);
