import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";

// @mui/material components
import {withStyles} from "@mui/styles";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Input from "@mui/material/Input";

// styles
import customInputStyle from "../../assets/jss/components/input/inputStyle";

function CustomInput({...props}) {
    const {
        classes,
        formControlProps,
        labelText,
        id,
        labelProps,
        inputProps,
        error,
        white,
        fullWidth,
        inputRootCustomClasses,
        success
    } = props;

    const labelClasses = classNames({
        [" " + classes.labelRootError]: error,
        [" " + classes.labelRootSuccess]: success && !error
    });
    const marginTop = classNames({
        [inputRootCustomClasses]: inputRootCustomClasses !== undefined
    });
    const inputClasses = classNames({
        [classes.input]: true,
        [classes.whiteInput]: white
    });
    let formControlClasses;
    if (formControlProps !== undefined) {
        formControlClasses = classNames({
            [formControlProps.className]: true,
            [classes.formControl]: true,
            [classes.fullWidth]: fullWidth
        });
    } else {
        formControlClasses = classes.formControl;
    }
    return (
        <FormControl variant="standard" {...formControlProps} className={formControlClasses}>
            {labelText !== undefined ? (
                <InputLabel
                    className={classes.labelRoot + " " + labelClasses}
                    htmlFor={id}
                    {...labelProps}
                >
                    {labelText}
                </InputLabel>
            ) : null}
            <Input
                disableUnderline={true}
                classes={{
                    input: inputClasses,
                    root: marginTop,
                    disabled: classes.disabled,
                }}
                id={id}
                fullWidth={true}
                {...inputProps}
            />
        </FormControl>
    );
}

CustomInput.propTypes = {
    classes: PropTypes.object.isRequired,
    labelText: PropTypes.node,
    labelProps: PropTypes.object,
    id: PropTypes.string,
    inputProps: PropTypes.object,
    formControlProps: PropTypes.object,
    inputRootCustomClasses: PropTypes.string,
    error: PropTypes.bool,
    success: PropTypes.bool,
    white: PropTypes.bool,
    fullWidth: PropTypes.bool
};

export default withStyles(customInputStyle)(CustomInput);
