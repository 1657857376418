import React, {useState} from "react";
import {Tooltip} from "@mui/material";
import {injectIntl} from "react-intl";
import {Check, Clear, Edit, Storefront, CopyAll} from "@mui/icons-material";

import rowStyle from "../../../assets/jss/views/corner/rowStyle";
import {getTranslation} from "../../../domain/helpers/translations";
import {grayColor, primaryColor, successColor, terraCottaColor} from "../../../assets/jss/main";
import {withStyles} from "@mui/styles";

function CornerRow(props) {
    const { corner, classes, openEditDialog, cornerSelected, openDisableDialog, intl } = props;
    const [isClicked, setIsClicked] = useState(false);

    const handleCopy = (cornerId) => {
        navigator.clipboard.writeText(cornerId);
        setIsClicked(true);
        setTimeout(() => setIsClicked(false), 900);
    };

    return (
        <div className={classes.row}>
            <span className={classes.three + ' ' + classes.bold}>
                {getTranslation(corner).name}<br/>
                <span className={classes.cornerId}>
                    {corner.id}
                    <CopyAll
                        className={isClicked ? classes.fadeAnime: ''}
                        onClick={() => handleCopy(corner.id)}
                        style={{color: grayColor, cursor: "pointer", width: "20px", verticalAlign: "top", marginLeft: "10px"}}
                    />
                </span>
            </span>
            <span className={classes.five}>{getTranslation(corner).description}</span>
            <span className={classes.one}>{corner.productsCount}</span>
            <div className={classes.one}>
                {corner.public ? (<Check style={{color: successColor}}/>) : (
                    <Clear style={{color: terraCottaColor}}/>)}
            </div>
            <div className={classes.one}>
                {corner.enabled ? (<Check style={{color: successColor}}/>) : (
                    <Clear style={{color: terraCottaColor}}/>)}
            </div>
            <div className={classes.one}>
                <Tooltip
                    title={intl.formatMessage({id: "corner.table.row.tooltip.edit"})}>
                    <Edit
                        onClick={() => {
                            openEditDialog(true);
                            cornerSelected(corner);
                        }}
                        style={{color: primaryColor, cursor: "pointer"}}
                    />
                </Tooltip>
                {corner.enabled &&
                    <Tooltip
                        title={intl.formatMessage({id: "corner.table.row.tooltip.disable"})}>
                        <Clear
                            onClick={() => {
                                openDisableDialog(true);
                                cornerSelected(corner);
                            }}
                            style={{color: primaryColor, cursor: "pointer"}}
                        />
                    </Tooltip>
                }
                {
                    !!corner.eshopUrl &&
                    <Tooltip title={intl.formatMessage({id: "corner.table.row.tooltip.externalLinkToEshop"})}>
                        <Storefront
                            onClick={() => window.open(corner.eshopUrl, '_blank')}
                            style={{color: primaryColor, cursor: "pointer"}}
                        />
                    </Tooltip>
                }
            </div>
        </div>
    );
}


export default withStyles(rowStyle)(injectIntl(CornerRow));
