import React from "react";
import {makeStyles} from "@mui/styles";

import { thunderGrayColor } from "assets/jss/main";

const useStyles = makeStyles(theme => ({
    cardStatsGraphTitle: {
        marginTop: "15px",
        marginLeft: "15px",
        fontSize: "18px",
        fontWeight: "300",
        color: thunderGrayColor
    },
}));

export default function CardStatsGraphTitle({...props}) {
    const classes = useStyles();

    const { children } = props;

    return (
        <div className={classes.cardStatsGraphTitle}>
            {children}
        </div>
    )
}
