import {
    // Add your imported colors below
    primaryColor,
} from "../../main";

const miniCardStyle = theme => ({
    miniCardContainer: {
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        borderRadius: "5px",
        border: "0px",
        padding: "20px",
        backgroundColor: "#fff",
        "&:hover":{
            cursor: "pointer",
            backgroundColor: "#F7F7F7"
        }
    },
    miniCardContainerSelected:{
        border: "2px solid",
        borderColor: primaryColor,
        "&:hover":{
            backgroundColor: "#FFF"
        }
    },
    miniCardHeader: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    miniCardHeaderTitle: {
        fontSize: "16px",
        fontWeight: "400",
    },
    miniCardImage: {
        '& img': {
            width: "100%",
            border: "1px solid #EEE",
            borderRadius: "3px"
        },
        margin: "8px 0"
    },
});

export default miniCardStyle;