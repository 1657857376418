import {
    darkGrayColor,
    infoColor,
    primaryColor,
} from "../../main";

const formStyle = theme => ({
    fieldContainer: {
        padding: "5px"
    },
    formControl: {
        padding: "0px",
        width: "100%"
    },
    fieldLabel: {
        color: infoColor,
    },
    fieldFloatRight: {
        float: "right"
    },
    placeholder: {
        color: "#acacac",
        fontSize: "14px",
        fontWeight: "400",
        textAlign: "left",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        opacity: "1",
        lineHeight: "1.42857",
    },
    addNewRoleButtonContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    addNewRoleText: {
        color: primaryColor,
        fontSize: "14px"
    },
    addNewRoleButton: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "30px",
        width: "30px",
        border: "2px solid",
        borderColor: primaryColor,
        borderRadius: "20px",
        marginRight: "10px"
    },
    duplicateError: {
        fontSize: "14px",
        color: "red"
    },
    deleteRole: {
        paddingTop: "25px"
    },
    containerRole: {
        paddingBottom: "50px"
    },
    inputTextarea: {
        border: "2px solid #eee",
        borderRadius: "4px",
        width: "100%",
        padding: "8px 10px",
        boxShadow: "none",
        color: darkGrayColor,
        outline: "0",
        resize: "none",
        maxHeight: "100px",
    },
    card: {
        marginBottom: "10px",
        marginTop: "10px"
    },
    authorizationName: {
        marginTop: "10px"
    },
    switch: {
        float: "right"
    },
    propagateLoader: {
        position: "absolute",
        left: "0",
        right: "0",
        margin: "0 auto",
        top: "30%",
        display: "flex",
        justifyContent: "center"
    },
    snackbarMessage: {
        display: "flex",
        alignItems: "center",

        "& span": {
            paddingLeft: "10px"
        }
    },
    errorMessages:{
        color:'red'
    }
});

export default formStyle;
