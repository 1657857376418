import React, {Component} from "react";
import {FormattedMessage, injectIntl} from "react-intl";

import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
    TablePagination,
} from "@mui/material";

class SortedTable extends Component
{
    constructor(props) {
        super(props);
        this.state = {
            orderBy: this.props.orderBy,
            order: 'asc',
            page: 0,
            rowsPerPage: this.props.perPage ?? 10,
        }
    }

    handleChangePage = (event, newPage) => {
        this.setState({
            page: newPage,
        });
    }

    handleChangeRowsPerPage = (event) => {
        this.setState({
            page: 0,
            rowsPerPage: parseInt(event.target.value, 10),
        });
    }

    createSortHandler(headerId) {
        const {orderBy, order} = this.state;
        return (event, property) => {
            const isAsc = orderBy === headerId && order === 'asc';
            this.setState({
                order: isAsc ? 'desc' : 'asc',
                orderBy: headerId,
            });
        }
    }

    getRows(data) {
        const {orderBy, order, page, rowsPerPage} = this.state;
        const orderFactor = order === 'asc' ? 1 : -1;

        if (!data) {
            return []
        }

        data.sort((a, b) => {
            if (b[orderBy] === a[orderBy]) {
                return 0;
            }
            if (b[orderBy] < a[orderBy]) {
                return -1 * orderFactor;
            } else {
                return 1 * orderFactor;
            }
        });

        return data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    }

    render() {
        const {orderBy, order, page, rowsPerPage} = this.state;

        return (
            <div>
                <Table>
                    <TableHead>
                        <TableRow>
                            {this.props.headers.map(header => (
                                <TableCell
                                    key={header.key}
                                    sortDirection={orderBy === header.key ? order : false}
                                >
                                    <TableSortLabel
                                        active={orderBy === header.key}
                                        direction={orderBy === header.key ? order : 'asc'}
                                        onClick={this.createSortHandler(header.key)}
                                    >
                                        <FormattedMessage id={header.label} {...header} />
                                    </TableSortLabel>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.getRows(this.props.data).map((data, index) => (
                            <TableRow key={index}>
                                {this.props.headers.map(header => (
                                    <TableCell>
                                        {data[header.key]}
                                        {header.suffix}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>

                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={this.props.data?.length}
                    rowsPerPage={rowsPerPage}
                    labelRowsPerPage={<FormattedMessage id={'list.rows_per_page'}/>}
                    page={page}
                    onPageChange={this.handleChangePage}
                    onRowsPerPageChange={this.handleChangeRowsPerPage}
                />
            </div>
        );
    }
}

export default (injectIntl(SortedTable));
