const StockEasyButtonStyle = () => ({
    buttonWrapper: {
        maxWidth: "220px",
    },
    buttonsWrapper: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        marginLeft: "0",
        marginRight: "0",
        '& button': {
            marginRight:"1rem",
        },
        marginTop: "1rem",
    },
});

export default StockEasyButtonStyle;
