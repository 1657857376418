import {Collapse, List, ListItem, ListItemIcon, ListItemText, Tooltip} from "@mui/material";
import {Link} from "react-router-dom";
import {ExpandLess, ExpandMore, StoreOutlined, VerifiedUser} from "@mui/icons-material";
import {FormattedMessage} from "react-intl";
import React, {Component} from "react";
import {Authorizations, hasAuthorization} from "utils/authorizations";
import {capitalize} from "utils/capitalize";
import {ITEM_PER_PAGE} from "config/_pagination";
import {mantisGreenColor, orangeReturn, terraCottaColor} from "../../../../assets/jss/main";

class MarketPlaceRetailersDrawer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            productTabOpen: this.props.match.path.startsWith("/retailers/show/:id/feeds/") || this.props.match.path.startsWith("/retailers/show/:id/products/") || this.props.match.path.startsWith("/retailers/show/:id/variations/"),
            itemsPerPage: this.props.itemsPerPage ? this.props.itemsPerPage : ITEM_PER_PAGE,
        };
    }

    getRetailersLinkByOffer = () => {
        return "/retailers/" + encodeURIComponent("order[pushActive]=desc&order[name]=asc&pagination=true&itemsPerPage=" + 500 + "&page=1");
    }

    render() {
        const {authorizations, classes, match, retailerRetrieved, itemsPerPage, productTabOpen} = this.props;
        const isInRetailerProductPath = (match.path.startsWith("/retailers/show/:id/products/") || match.path.startsWith("/retailers/show/:id/variations/"));
        const isInRetailerFeedPath = match.path.startsWith("/retailers/show/:id/feeds/");

        const isInRetailerAdminPath = (
            match.path === "/retailers/:params"
            || match.path === "/retailers/create"
            || match.path === "/retailers/edit/:id"
            || Boolean(retailerRetrieved)
        );

        let errorRetailerPushMessage = null;
        let pushWithoutStripe = null;

        if (retailerRetrieved) {
            if (retailerRetrieved['pushActive'] && retailerRetrieved['pushWithoutStripe'] && (!retailerRetrieved['paymentAccount'] || (retailerRetrieved['paymentAccount'] && !retailerRetrieved['paymentAccount']['enabled']))) {
                pushWithoutStripe = <FormattedMessage id={"sidebar.item.retailerList.retailer.push.noStripe"}/>;
            } else if (!retailerRetrieved['pushActive'] && (!retailerRetrieved['paymentAccount'])) {
                errorRetailerPushMessage = <FormattedMessage id={"sidebar.item.retailerList.retailer.validated.nothing"}/>;
            } else if (!retailerRetrieved['pushActive'] && retailerRetrieved['paymentAccount']) {
                errorRetailerPushMessage = <FormattedMessage id={"sidebar.item.retailerList.retailer.validated.push"}/>;
            } else if (retailerRetrieved['pushActive'] && (!retailerRetrieved['paymentAccount'] || (retailerRetrieved['paymentAccount'] && !retailerRetrieved['paymentAccount']['enabled']))) {
                errorRetailerPushMessage = <FormattedMessage id={"sidebar.item.retailerList.retailer.validated.stripe"}/>;
            }
        }

        if ((hasAuthorization(authorizations, Authorizations.MARKETPLACE_RETAILER_MANAGEMENT) || hasAuthorization(authorizations, Authorizations.MARKETPLACE_RETAILER_VIEW))) {
            return (
                <div>
                    <ListItem
                        button
                        className={classes.listItem}
                        classes={{selected: (isInRetailerAdminPath) ? classes.selected : classes.subListSelected}}
                        component={Link}
                        to={this.getRetailersLinkByOffer()}
                        key={"retailerList"}
                        selected={isInRetailerAdminPath}
                    >
                        <ListItemIcon>
                            <StoreOutlined color={isInRetailerAdminPath ? "primary" : "secondary"}/>
                        </ListItemIcon>
                        <ListItemText
                            primaryTypographyProps={{color: isInRetailerAdminPath ? "primary" : "secondary"}}
                            primary={<FormattedMessage id={"sidebar.item.retailerList"}/>}
                        />
                        {retailerRetrieved && <ExpandLess color={"primary"}/>}
                    </ListItem>
                    {/* show selected retailer name*/}
                    <Collapse in={Boolean(retailerRetrieved)}>
                        {retailerRetrieved &&
                        <ListItemText
                            primaryTypographyProps={{color: "initial"}}
                            className={classes.retailerTitle}
                        >
                            {capitalize(retailerRetrieved["name"])}
                            {pushWithoutStripe ?
                                <Tooltip className={classes.retailerBadgePush} title={pushWithoutStripe}>
                                    <VerifiedUser fontSize="small" style={{color: orangeReturn}} />
                                </Tooltip>
                                : errorRetailerPushMessage ?
                                    <Tooltip className={classes.retailerBadgePush} title={errorRetailerPushMessage}>
                                        <VerifiedUser fontSize="small" style={{color: terraCottaColor}} />
                                    </Tooltip>
                                :
                                    <Tooltip className={classes.retailerBadgePush} title={<FormattedMessage id={"sidebar.item.retailerList.retailer.validated"}/>}>
                                        <VerifiedUser fontSize="small" style={{color: mantisGreenColor}} />
                                    </Tooltip>
                            }
                        </ListItemText>
                        }
                        {retailerRetrieved &&
                        <div className={classes.retailerSubMenu}>
                            <ListItem
                                button
                                className={classes.listItem}
                                classes={{selected: classes.selected}}
                                component={Link}
                                to={`/retailers/show/${encodeURIComponent(retailerRetrieved["@id"])}`}
                                key={"retailerDashboard"}
                                selected={match.path === "/retailers/show/:id"}
                            >
                                <ListItemText
                                    primaryTypographyProps={{color: match.path === "/retailers/show/:id" ? "primary" : "secondary"}}
                                    primary={<FormattedMessage id={"sidebar.item.dashboard"}/>}
                                />
                            </ListItem>

                            {(hasAuthorization(authorizations, Authorizations.MARKETPLACE_FEED_MANAGEMENT) || hasAuthorization(authorizations, Authorizations.MARKETPLACE_PRODUCT_VIEW) || hasAuthorization(authorizations, Authorizations.MARKETPLACE_PRODUCT_MANAGEMENT)) &&
                            <div>
                                <ListItem
                                    button
                                    className={classes.listItem}
                                    classes={{selected: classes.subListSelected}}
                                    onClick={this.props.handleProductTabChange}
                                    key={"products"}
                                    selected={isInRetailerFeedPath || isInRetailerProductPath}
                                >
                                    <ListItemText
                                        primaryTypographyProps={{color: isInRetailerFeedPath || isInRetailerProductPath ? "primary" : "secondary"}}
                                        primary={<FormattedMessage id={"sidebar.item.products"}/>}
                                    />
                                    {productTabOpen ? (
                                        <ExpandLess color={"primary"}/>
                                    ) : (
                                        <ExpandMore color={"secondary"}/>
                                    )
                                    }
                                </ListItem>
                                <Collapse in={productTabOpen}>
                                    <List className={classes.listContainer} disablePadding>
                                        {(hasAuthorization(authorizations, Authorizations.MARKETPLACE_PRODUCT_VIEW) || hasAuthorization(authorizations, Authorizations.MARKETPLACE_PRODUCT_MANAGEMENT)) &&
                                        <ListItem
                                            button
                                            className={classes.listItem}
                                            classes={{selected: classes.selected}}
                                            component={Link}
                                            to={`/retailers/show/${encodeURIComponent(retailerRetrieved["@id"])}/products/${encodeURIComponent(`retailer.id=${retailerRetrieved["id"]}&order[translations.name]=asc&itemsPerPage=50&page=1`)}`}
                                            key={"productList"}
                                            selected={isInRetailerProductPath}
                                        >
                                            <ListItemText
                                                className={classes.subListText}
                                                inset
                                                primaryTypographyProps={{color: match.path.startsWith("/retailers/show/:id/products") ? "primary" : "secondary"}}
                                                primary={<FormattedMessage id={"sidebar.item.productList"}/>}
                                            />
                                        </ListItem>
                                        }
                                        {hasAuthorization(authorizations, Authorizations.MARKETPLACE_FEED_MANAGEMENT) &&
                                        <ListItem
                                            button
                                            className={classes.listItem}
                                            classes={{selected: classes.selected}}
                                            component={Link}
                                            to={`/retailers/show/${encodeURIComponent(retailerRetrieved["@id"])}/feeds/`}
                                            key={"feeds"}
                                            selected={isInRetailerFeedPath}
                                        >
                                            <ListItemText
                                                className={classes.subListText}
                                                inset
                                                primaryTypographyProps={{color: match.path.startsWith("/retailers/show/:id/feeds") ? "primary" : "secondary"}}
                                                primary={<FormattedMessage id={"sidebar.item.feeds"}/>}
                                            />
                                        </ListItem>
                                        }
                                    </List>
                                </Collapse>
                            </div>}
                        </div>}
                    </Collapse>
                </div>
            )
        } else {
            return null;
        }
    }
}

export default MarketPlaceRetailersDrawer;
