import {fetch} from "../../utils/dataAccess";

export function error(error) {
    return {type: "VARIATION_MEDIA_OBJECT_CREATE_ERROR", error};
}

export function loading(loading) {
    return {type: "VARIATION_MEDIA_OBJECT_CREATE_LOADING", loading};
}

export function success(success) {
    return {type: "VARIATION_MEDIA_OBJECT_CREATE_SUCCESS", success};
}

export function create(variationId, mediaObjectId, position) {
    const values = {
        variation: variationId,
        mediaObject: mediaObjectId,
        position: !!position ? position : 0,
    }

    return dispatch => {
        dispatch(loading(true));

        return fetch('/variation_media_objects', {
            method: "POST",
            headers: new Headers({"Content-Type": "application/ld+json"}),
            body: JSON.stringify(values),
        }).then(response =>
            response.json()
        ).then((retrieved) => {
            dispatch(loading(false));
            dispatch(success(retrieved));
        }).catch(e => {
            dispatch(loading(false));
            dispatch(error(e.errors));
        });
    };
}

export function reset() {
    return dispatch => {
        dispatch(error(false));
        dispatch(success(null));
        dispatch(loading(null));
    };
}
