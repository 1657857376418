import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from "react-intl";

import {fifteenthGrey} from "assets/jss/main";

CardTitle.propTypes = {
    titleId: PropTypes.string.isRequired,
    subtitleId: PropTypes.string
};

function CardTitle({titleId, subtitleId}) {
    return (
        <>
            <h2 style={{
                fontSize: '24px',
                color: fifteenthGrey,
                marginBottom: "unset",
                textTransform: "none"
            }}>
                <FormattedMessage id={titleId} />
            </h2>
            {subtitleId && (
                <span style={{fontSize: "14px", textTransform: "none"}}>
                    <FormattedMessage id={subtitleId} />
                </span>
            )}
        </>
    );
}

export default CardTitle;
