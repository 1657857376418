import React from "react";

// core components
import NumberFormat from 'react-number-format';
import CurrencyCodeToSymbol from "./currencyCodeToSymbol";
import currencyStyle from "../../assets/jss/views/currencyStyle";
import {withStyles} from "@mui/styles";

function FormattedNumberWithCurrency(props) {
    const acceptedLanguages = ["fr"];
    const defaultCurrency = "EUR";

    let language = navigator.language.split(/[-_]/)[0];
    language = acceptedLanguages.indexOf(language) !== -1 ? language : "fr";

    const newProps = {...props};
    var format = "float";
    if(!!newProps.format) {
        format = newProps.format;
        delete newProps.format;
    }

    if (format === "int" && !!newProps.value) {
        newProps.value = newProps.value / 100;
    }

    const values = {
        value: 0,
        displayType: "text",
        lang: language,
        decimalScale: newProps.decimalScale ?? 2,
        fixedDecimalScale: true,
        ...newProps,
        currency: newProps.currency ?? defaultCurrency,
    };

    values["currency"] = CurrencyCodeToSymbol(values["currency"])

    const defaultValuesOnLang = {
        "fr" : {
            "decimalSeparator": ",",
            "suffix": " " +  values["currency"],
            "thousandSeparator": " ",
        },
        "en" : {
            "decimalSeparator": ".",
            "prefix":  values["currency"],
            "thousandSeparator": ",",
        },
        "%" : {
            "decimalSeparator": ",",
            "suffix": " %",
            "thousandSeparator": false,
        }
    };

    const finalValues = {
        ...defaultValuesOnLang[values.lang],
        ...values,
    };

    return (
        <NumberFormat className={props.classes.noWrap} {...finalValues}/>
    );
}

export default withStyles(currencyStyle)(FormattedNumberWithCurrency);
