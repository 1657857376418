import {
    container,
    defaultFont,
} from "../../../../main";

const indexStyle = theme => ({
    containerWithSidebar: {
        [theme.breakpoints.up("md")]: {
            marginLeft: "240px",
        }
    },
    container: {
        ...container,
        ...defaultFont
    },
    "@media (max-width: 768px)": {
        gridItemStepContainer: {
            paddingLeft: "0",
            paddingRight: "0",
        },
    },
});

export default indexStyle;
