import React, { useState } from "react";

// @mui/material components
import {makeStyles} from "@mui/styles";

// core components
import CornerDialog from './cornerDialog';
import DisableDialog from './disableDialog';
import NoResult from '../../configuration/components/noResult';
import CornerRow from './cornerRow';

// styles
const useStyles = makeStyles(theme => ({
    removeUlPadding: {
        padding: 0,
        margin: 0,
        listStyleType: "none"
    },
}));

export default function EnhancedTableBody (props) {
    const { retrieved, needReload } = props;

    const classes = useStyles();

    const [disableDialog, showDisableDialog] = useState(false);
    const [editDialog, showEditDialog] = useState(false);
    const [cornerSelected, setCornerSelected] = useState(null);

    return (

        <div className={classes.removeUlPadding}>
            {(retrieved && retrieved.length > 0) && (
                retrieved.map(corner => (
                    <CornerRow
                        key={corner.id}
                        corner={corner}
                        openEditDialog={showEditDialog}
                        openDisableDialog={showDisableDialog}
                        cornerSelected={setCornerSelected}
                    />
                ))
            )}
            {retrieved && retrieved.length === 0 && (
                <NoResult />
            )}
            {!!cornerSelected && editDialog &&
                <CornerDialog
                    isOpen={editDialog}
                    closeDialog={() => showEditDialog(false)}
                    corner={cornerSelected}
                    needReload={() => needReload()}
                />
            }
            {!!cornerSelected && disableDialog &&
                <DisableDialog
                    isOpen={disableDialog}
                    closeDialog={() => showDisableDialog(false)}
                    corner={cornerSelected}
                    disabled={() => needReload()}
                />
            }
        </div>
    );
}
