import React, {Component} from "react";
import PropTypes from "prop-types";
import {FormattedDate, FormattedMessage, injectIntl} from "react-intl";
import {connect} from "react-redux";
import {PropagateLoader} from 'react-spinners';

// styles
import expansionStyle from "assets/jss/views/report/expansionStyle";
import {primaryColor} from "assets/jss/main";

import {
    TableCell,
    TableRow,
    TableHead,
    TableBody,
    Table,
    Typography,
} from "@mui/material";
import {withStyles} from "@mui/styles";

class HistoryTable extends Component {
    static propTypes = {
        loading: PropTypes.bool.isRequired,
    };

    displayImportDetail(row) {
        const currentUrl = this.props.match.url;
        const reportId = row['@id'].replace('/import_reports/', '');

        this.props.history.push({
            pathname:`${currentUrl}/${reportId}`,
            type:"parent",
        });
    }

    render() {
        const {classes, tableData} = this.props;
        if (this.props.loading) {
            return (
                <div className={classes.propagateLoader}>
                    <PropagateLoader
                        sizeUnit={"px"}
                        size={16}
                        color={primaryColor}
                        loading={this.props.loading}
                    />
                </div>
            );
        }

        if (!tableData || tableData["hydra:member"].length === 0) {
            return (
                <div>
                    <FormattedMessage id={"feed.history.noResult"}/>
                </div>
            );
        }

        const rows = tableData["hydra:member"];
        const hasRows = rows && rows.length > 0;

        return (
            <div className={classes.tableResponsive}>
                <div className={classes.table}>
                    <Table padding={hasRows ? "none" : "default"}>
                        <TableHead
                            state={this.state}
                            offer={this.props.offer}
                        >
                            <TableRow>
                                <TableCell><FormattedMessage id={'feed.history.detail.label.start'}/></TableCell>
                                <TableCell><FormattedMessage id={'feed.history.detail.label.end'}/></TableCell>
                                <TableCell><FormattedMessage id={'feed.history.list.label.count_errors'}/></TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {rows.map(row => (
                                <TableRow
                                    className={classes.row}
                                    onClick={() => this.displayImportDetail(row)}
                                    hover
                                    tabIndex={-1}
                                    key={row['@id']}
                                >
                                    <TableCell>
                                        <Typography className={classes.heading}>
                                            {<FormattedDate
                                                value={new Date(row["createdAt"])}
                                                year={"numeric"}
                                                month={"numeric"}
                                                day={"numeric"}
                                                hour={"numeric"}
                                                minute={"numeric"}
                                                second={"numeric"}
                                            />}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography className={classes.heading}>
                                            {!!row["endedAt"] ?
                                                (<FormattedDate
                                                    value={new Date(row["endedAt"])}
                                                    year={"numeric"}
                                                    month={"numeric"}
                                                    day={"numeric"}
                                                    hour={"numeric"}
                                                    minute={"numeric"}
                                                    second={"numeric"}
                                                />) :
                                                <FormattedMessage id={'feed.history.not_finish'} />
                                            }
                                        </Typography>
                                    </TableCell>

                                    <TableCell>
                                        <Typography className={classes.heading}>
                                            {row.nbErrors > 0 ?
                                                (<span className={classes.dangerTableHeader}>{row.nbErrors}</span>) :
                                                <span className={classes.primaryTableHeader}>
                                                    <FormattedMessage id={'feed.history.detail.label.no_error'}/>
                                                </span>
                                            }
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    loading: state.report.list.loading,
});

export default connect(
    mapStateToProps
)(withStyles(expansionStyle)(injectIntl(HistoryTable)));
