import {combineReducers} from "redux";

export function error(state = null, action) {
    switch (action.type) {
        case "RETAILER_STRIPE_CONFIRM_ERROR":
            return action.error;

        case "RETAILER_STRIPE_CONFIRM_RESET":
            return null;

        default:
            return state;
    }
}

export function loading(state = false, action) {
    switch (action.type) {
        case "RETAILER_STRIPE_CONFIRM_LOADING":
            return action.loading;

        case "RETAILER_STRIPE_CONFIRM_RESET":
            return false;

        default:
            return state;
    }
}

export function confirmed(state = null, action) {
    switch (action.type) {
        case "RETAILER_STRIPE_CONFIRM_SUCCESS":
            return action.created;

        case "RETAILER_STRIPE_CONFIRM_RESET":
            return null;

        default:
            return state;
    }
}

export default combineReducers({error, loading, confirmed});
