import React, { useEffect } from "react";
import { connect }  from "react-redux";
import { FormattedMessage } from "react-intl";

// @mui/material components
import { Dialog, DialogTitle, DialogContent } from "@mui/material";

// core components
import EditForm from './editForm';

function EditDialog(props) {
    const { category, closeDialog, isOpen, isSubCategory, updated } = props;

    useEffect(() => {
        if (updated) {
            closeDialog();
        }
    }, [updated]);

    return (
        <Dialog
            open={isOpen}
            onClose={closeDialog}
            maxWidth={'sm'}
            scroll={'body'}
        >
            <DialogTitle>
                <FormattedMessage id={isSubCategory ? "category.form.sub.update.title" : "category.form.update.title"} />
            </DialogTitle>
            <DialogContent>
                <EditForm
                    closeDialog={closeDialog}
                    category={category}
                    initialValues={category}
                />
            </DialogContent>
        </Dialog>
    )
}

const mapStateToProps = state => {
    return {
        updated: state.category.update.updated,
    }
};

export default connect(
    mapStateToProps,
    null,
)(EditDialog);
