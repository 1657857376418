import React, {Component} from "react";
import {ListItem, ListItemIcon, ListItemText} from "@mui/material";
import {Link} from "react-router-dom";
import {FormattedMessage} from "react-intl";
import {HomeOutlined} from "@mui/icons-material";

class DrawerDashboard extends Component {
    render() {
        const dashboardLink = this.props.dashboardLink;
        const classes = this.props.classes;
        const isInDashboardPath = this.props.isInDashboardPath;

        return (
            <ListItem
                button
                className={classes.listItem}
                classes={{selected: classes.selected}}
                component={Link}
                to={dashboardLink}
                key={"dashboard"}
                selected={isInDashboardPath}
            >
                <ListItemIcon>
                    <HomeOutlined color={isInDashboardPath ? "primary" : "secondary"} />
                </ListItemIcon>
                <ListItemText
                    primaryTypographyProps={{color: isInDashboardPath ? "primary" : "secondary"}}
                    primary={<FormattedMessage id={"sidebar.item.dashboard"}/>}
                />
            </ListItem>
        )
    }
}

export default DrawerDashboard;
