import {container, defaultFont, grayColor, primaryColor, secondaryColor} from "../../main";

const listStyle = theme => ({
    containerWithSidebar: {
        [theme.breakpoints.up("md")]: {
            marginLeft: "240px",
        }
    },
    container: {
        ...container,
        ...defaultFont
    },
    mainActionsContainer: {
        margin: "8px 0px",
        textAlign: "right"
    },
    card: {
        minHeight: window.innerHeight * 0.94
    },
    createFeed: {
        color: "#FFFFFF"
    },
    scrollNoticeWrapper: {
        position: "absolute",
        right: "0px",
        width: "10px"
    },
    scrollNotice: {
        display: "flex",
        justifyContent: "flex-end",
        textTransform: "uppercase",
        fontStyle: "italic",
        fontSize: "10px",
        whiteSpace: "nowrap",
        fontWeight: "bold",
        animation: "slide infinite 2s linear"
    },
    dropdownLink: {
        textDecoration: "none"
    },
    "@keyframes slide": {
        "0%": {marginRight: "0%", color: secondaryColor},
        "50%": {marginRight: "100%", color: secondaryColor},
        "100%": {marginRight: "0%", color: primaryColor}
    },
    actionsContainer: {
        position: "absolute",
        right: "5px",
        bottom: "0px",
        textAlign: "right"
    },
    buttonReturn: {
        color: "#0052cb",
        fontWeight: "300",
        fontSize: "12px",
    },
    totalContainer: {
        color: grayColor,
        textAlign: "right",
        padding: "15px",
        display: "flex",
        justifyContent: "end",
    },
    totalSpan: {
        display: "flex",
        alignItems: "center",
        marginRight: "10px"
    },
});

export default listStyle;
