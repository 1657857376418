import React, {useState} from 'react';
import {makeStyles} from '@mui/styles';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
} from '@mui/material';
import {FormattedMessage} from 'react-intl';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {Formik} from 'formik';
import FormikInput from '../../../../components/input/formikInput';
import {
    borderGrayColor, dangerColor,
    grayColor,
    infoColor,
    infoColorDisabled,
    infoColorHover,
    whiteColorDisabled,
} from '../../../../assets/jss/main';
import updateCustomerShipping from '../../../../api/order/updateCustomerShipping';

const useStyles = makeStyles(theme => ({
    paper: {
        display: 'inline-flex',
        padding: 24,
        flexDirection: 'column',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
        boxShadow: '0px 4px 22px 0px rgba(0, 0, 0, 0.08)',
        borderRadius: 8,
        '& .MuiDialogContent-root': {
            padding: 0,
            marginBottom: 48,
        },
        '& .MuiDialogContent-root h2': {
            padding: 0,
            margin: 0,
            marginBottom: '32px',
            color: '#333',
            fontSize: '20px',
            fontWeight: 700,
            lineHeight: '28px',
            letterSpacing: '0.15px',
        },
        '& .MuiDialogActions-root > :not(:first-of-type) ': {
            marginLeft: '12px',
        },
        '& .MuiDialogActions-root': {
            padding: 0,
            justifyContent: 'flex-end',
        },
    },
    form: {
        display: 'flex',
        alignItems: 'flex-start',
        alignContent: 'flex-start',
        gap: 16,
        alignSelf: 'stretch',
        flexWrap: 'wrap',
        '& div': {
            width: 'calc(50% - 8px)',
            margin: 0,
            padding: 0,
            '& label': {
                marginBottom: 4,
                color: '#333',
                fontSize: '12px',
                fontStyle: 'normal',
                fontWeight: 600,
                lineHeight: 'normal',
                '&[data-disabled="true"]': {
                    color: grayColor,
                },
            },
            '& input': {
                padding: 16,
                borderRadius: 8,
                border: `1px solid ${borderGrayColor}`,
                background: '#FFF',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: 'normal',
                '&:disabled': {
                    background: whiteColorDisabled,
                    color: grayColor,
                },
                '&:focus': {
                    outline: 0,
                    borderRadius: 8,
                    borderColor: infoColor,
                },
            },
            '& > span': {
                fontSize: '14px',
                color: dangerColor,
            },
        },
    },
    errorMessage: {
        fontSize: '14px',
        color: dangerColor,
        marginTop: 8,
    },
    actionButton: {
        padding: '12px 16px',
        color: '#333',
        fontSize: '14px',
        lineHeight: '14px',
        fontWeight: 400,
        textTransform: 'none',
        borderRadius: 8,
        border: `1px solid ${borderGrayColor}`,
        '&:hover': {
            backgroundColor: '#F1F1F1',
        },
    },
    validatedButton: {
        color: '#FFF',
        backgroundColor: infoColor,
        '&:hover': {
            backgroundColor: infoColorHover,
        },
        '&.Mui-disabled': {
            color: '#FFF',
            backgroundColor: infoColorDisabled,
        },
    },
}))

export default function ModalCustomerShippingEdit({open, onClose, order, refreshOrder}) {
    const [errorMessageId, setErrorMessageId] = useState('');
    const classes = useStyles();

    const initialValues = {
        firstname: order?.customerShippingFirstname || '',
        lastname: order?.customerShippingLastname || '',
        address: order?.customerShippingAddress || '',
        postalCode: order?.customerShippingPostalCode || '',
        city: order?.customerShippingCity || '',
        country: order?.customerShippingCountry || '',
        phone: order?.customerShippingPhone || '',
        email: order?.customerShippingEmail || '',
    };

    const validate = values => {
        const errors = {};

        if (!values.address || values.address.trim() === '') {
            errors.address = <FormattedMessage id={'picking.order.show.customerShippingEdit.form.error.address.missing'} />;
        }
        if (!values.postalCode || values.postalCode.trim() === '') {
            errors.postalCode = <FormattedMessage id={'picking.order.show.customerShippingEdit.form.error.postalCode.missing'} />;
        }
        if (!values.city || values.city.trim() === '') {
            errors.city = <FormattedMessage id={'picking.order.show.customerShippingEdit.form.error.city.missing'} />;
        }
        if (!values.phone || values.phone.trim() === '') {
            errors.phone = <FormattedMessage id={'picking.order.show.customerShippingEdit.form.error.phone.missing'} />;
        }

        return errors;
    };

    return (
        <Formik
            initialValues={initialValues}
            validate={validate}
            validateOnChange={false}
            validateOnBlur={true}
            enableReinitialize={true}
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true);

                updateCustomerShipping(order.id, values).then(() => {
                    setSubmitting(false);
                    onClose();
                    refreshOrder();
                }).catch(() => {
                    setErrorMessageId('picking.order.show.customerShippingEdit.error');
                })
            }}
        >
            {({
                  handleSubmit,
                  isSubmitting,
                  isValid,
                  dirty,
                  submitForm,
                  resetForm,
              }) => (
                <Dialog
                    open={open}
                    onClose={onClose}
                    slotProps={{ backdrop: { style: { backgroundColor: 'rgba(51, 51, 51, 0.20)' } } }}
                    classes={{ paper: classes.paper }}
                >
                    <DialogContent>
                        <h2>
                            <FormattedMessage id={'picking.order.show.customerShippingEdit.title'} />
                        </h2>

                        <form
                            className={classes.form}
                            onSubmit={handleSubmit}
                        >
                            <FormikInput
                                labelTranslationId={'picking.order.show.customerShippingEdit.form.label.firstname'}
                                inputProps={
                                    {
                                        name: 'firstname',
                                        type: 'text',
                                        disabled: true,
                                    }
                                }
                            />
                            <FormikInput
                                labelTranslationId={'picking.order.show.customerShippingEdit.form.label.lastname'}
                                inputProps={
                                    {
                                        name: 'lastname',
                                        type: 'text',
                                        disabled: true,
                                    }
                                }
                            />
                            <FormikInput
                                labelTranslationId={'picking.order.show.customerShippingEdit.form.label.address'}
                                inputProps={
                                    {
                                        name: 'address',
                                        type: 'text',
                                    }
                                }
                            />
                            <FormikInput
                                labelTranslationId={'picking.order.show.customerShippingEdit.form.label.postalCode'}
                                inputProps={
                                    {
                                        name: 'postalCode',
                                        type: 'text',
                                    }
                                }
                            />
                            <FormikInput
                                labelTranslationId={'picking.order.show.customerShippingEdit.form.label.city'}
                                inputProps={
                                    {
                                        name: 'city',
                                        type: 'text',
                                    }
                                }
                            />
                            <FormikInput
                                labelTranslationId={'picking.order.show.customerShippingEdit.form.label.country'}
                                inputProps={
                                    {
                                        name: 'country',
                                        type: 'text',
                                        disabled: true,
                                    }
                                }
                            />
                            <FormikInput
                                labelTranslationId={'picking.order.show.customerShippingEdit.form.label.phone'}
                                inputProps={
                                    {
                                        name: 'phone',
                                        type: 'text',
                                    }
                                }
                            />
                            <FormikInput
                                labelTranslationId={'picking.order.show.customerShippingEdit.form.label.email'}
                                inputProps={
                                    {
                                        name: 'email',
                                        type: 'text',
                                        disabled: true,
                                    }
                                }
                            />
                        </form>
                        {errorMessageId !== '' && (
                            <div className={classes.errorMessage}>
                                <FormattedMessage id={errorMessageId} />
                            </div>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                resetForm();
                                onClose();
                            }}
                            className={classes.actionButton}
                        >
                            <FormattedMessage id={'picking.order.show.customerShippingEdit.action.cancel'} />
                        </Button>
                        <Button
                            onClick={submitForm}
                            disabled={isSubmitting || (!isValid || !dirty)}
                            className={classNames(classes.actionButton, classes.validatedButton)}
                        >
                            <FormattedMessage id={'picking.order.show.customerShippingEdit.action.confirm'} />
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </Formik>
    );
};

ModalCustomerShippingEdit.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    order: PropTypes.object.isRequired,
    refreshOrder: PropTypes.func.isRequired,
};
