import React from "react";
import {Chip, } from "@mui/material";
import {withStyles} from "@mui/styles";
import {FormattedMessage} from "react-intl";
import productLabelStyle from "../../../../assets/jss/views/product/productLabelStyle";

function ProductStatusLabel({product, classes}) {
    const label = product.status ? "retailer.product.list.label.active" : "retailer.product.list.label.inactive"
        return (
            <Chip
                label={<FormattedMessage id={label}/>}
                variant="outlined"
                size="small"
                className={classes.baseLabel}
            />
        );
}

export default withStyles(productLabelStyle)(ProductStatusLabel);
