import React, { Component } from 'react';
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";

// @mui/material components
import {withStyles} from "@mui/styles";
import miniCardDetailsStyle from "assets/jss/views/product/miniCardDetailsStyle";

class MiniCardDetails extends Component {

    componentDidMount() {
        const { isSelected } = this.props;

        if (isSelected)
            this.props.selectVariation();
    }

    getContainerClassName = () => {
        const { classes, onClick } = this.props;

        if (typeof onClick === "function") {
            return classNames({
                [classes.container]: true,
                [classes.containerClickable]: true,
            });
        }

        return classes.container;
    };

    render() {
        const { classes, title, leftTop, leftMiddle, leftBottom, rightTop, rightBottom, onClick } = this.props;
        const containerClassName = this.getContainerClassName();

        return (
            <div className={containerClassName} onClick={onClick}>
                <div className={classes.title}>{title.label}</div>
                <div className={classes.columns}>
                    {(leftTop || leftBottom) &&
                        <ul>
                            {leftTop &&
                                <li>
                                    <span>{`${leftTop.label} : `}</span><span><b>{leftTop.value}</b></span>
                                </li>
                            }
                            {leftMiddle &&
                                <li>
                                    <span>{`${leftMiddle.label} : `}</span><span><b>{leftMiddle.value}</b></span>
                                </li>
                            }
                            {(leftBottom && leftBottom.value) &&
                                <li>
                                    <span>{`${leftBottom.label} : `}</span><span><b>{leftBottom.value}</b></span>
                                </li>
                            }
                        </ul>
                    }
                    {(rightTop || rightBottom) &&
                        <ul>
                            {rightTop &&
                                <li>
                                    <span>{`${rightTop.label} : `}</span><span><b>{rightTop.value}</b></span>
                                </li>
                            }
                            {rightBottom &&
                                <li>
                                    <span>{`${rightBottom.label} : `}</span><span><b>{rightBottom.value}</b></span>
                                </li>
                            }
                        </ul>
                    }
                </div>
            </div>
        );
    }
}

MiniCardDetails.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
    title: PropTypes.object.isRequired,
    leftTop: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ])
    }),
    leftBottom: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ])
    }),
    rightTop: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.object,
        ])
    }),
    rightBottom: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ])
    }),
    onClick: PropTypes.func,
};

export default withStyles(miniCardDetailsStyle)(MiniCardDetails);
