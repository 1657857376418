import React, { useEffect } from "react";
import { connect }  from "react-redux";
import { FormattedMessage } from "react-intl";

// @mui/material components
import { Dialog, DialogTitle, DialogContent } from "@mui/material";

// core components
import Form from './form';

function CreateDialog(props) {
    const { category, closeDialog, created, isOpen, isSubCategory } = props;

    useEffect(() => {
        if (created) {
            closeDialog();
        }
    }, [created]);

    return (
        <Dialog
            open={isOpen}
            onClose={closeDialog}
            maxWidth={'sm'}
            scroll={'body'}
        >
            <DialogTitle>
                <FormattedMessage id={isSubCategory ? "category.form.sub.create.title" : "category.form.create.title"} />
            </DialogTitle>
            <DialogContent>
                <Form
                    closeDialog={closeDialog}
                    category={isSubCategory && category}
                    isSubCategory={isSubCategory}
                />
            </DialogContent>
        </Dialog>
    )
}

const mapStateToProps = state => {
    return {
        created: state.category.create.created,
    }
};

export default connect(
    mapStateToProps,
    null,
)(CreateDialog);
