import React from "react";
import {withStyles} from "@mui/styles";

import {Field, getIn} from 'formik';
import classNames from "classnames";
import '@ckeditor/ckeditor5-build-classic/build/translations/fr';

import fieldStyle from "../../../assets/jss/views/formik/fieldStyle";

class FormikInputRenderer extends React.Component {
    static supports(props) {
        return ['textarea', 'text', 'select', 'number', 'checkbox'].includes(props.as ?? props.type);
    }

    render() {
        const { formikContext, name, classes, fieldClassName } = this.props;
        const errors = formikContext.errors;
        const fieldIsInError = getIn(errors, name);
        const value = getIn(formikContext.values, name);
        const isFieldTouched = getIn(formikContext.touched, name);

        const fieldClasses = classNames({
            [" " + classes.field]: true,
            [" " + classes.fieldInvalid]: !!fieldIsInError && isFieldTouched,
            [" " + (fieldClassName ?? '')]: true,
        });

        const tmpProps = {...this.props};
        delete tmpProps.containerClassName;
        delete tmpProps.fieldClassName;
        delete tmpProps.formikContext;

        tmpProps.value = value === null ? '' : value;
        if (this.props.type === 'checkbox') {
            delete tmpProps.value; // The value must not be filled on checkbox fields
        }

        return (
            <Field
                className={fieldClasses}
                {...tmpProps}
            />
        );
    }
}

export default withStyles(fieldStyle)(FormikInputRenderer);
