import {dangerColor, primaryColor, successColor} from "../../main";

const openingDaysStyle = theme => ({
    hourBadge: {
        margin: "0 6px",
    },
    openingHours: {
        display: "inline",
    },
    title: {
        color: primaryColor,
        fontWeight: 'bold',
        fontSize: '20px',
        display: "inline",
    },
    dayRow: {
        marginBottom: "10px",
    },
    addPeriod: {
        width: "20px",
        minHeight: 'unset',
        height: "20px",
        marginLeft: "7px",
    },
    addExceptionalDay: {
        width: "30px",
        minHeight: 'unset',
        height: "30px",
        marginLeft: "7px",
        marginBottom: "8px",
    },
    hourContainer: {
        display: "flex",
        width: 'max-content',
        flex: 2,
        padding: "6px",
        alignItems: 'center',
    },
    exceptionalDay: {
        alignItems: 'center',
        display: "flex",
        width: 'max-content',
    },
    exceptionalDayHours: {
        margin: "10px 0 10px 20px",
    },
    timeField: {
        marginRight: "6px",
    },
    savePeriod: {
        background: successColor,
    },
    deleteButton: {
        background: dangerColor,
    }
});


export default openingDaysStyle;
