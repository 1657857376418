import {fetch, normalize} from "../../utils/dataAccess";

export function error(error) {
    return {type: "ORDER_UPDATE_ERROR", error};
}

export function loading(loading) {
    return {type: "ORDER_UPDATE_LOADING", loading};
}

export function success(success) {
    return {type: "ORDER_UPDATE_SUCCESS", success};
}

export const update = (item, values) => dispatch => {
    dispatch(loading(true));

    return fetch(item["@id"], {
        method: 'PUT',
        headers: new Headers({'Content-Type': 'application/ld+json'}),
        body: JSON.stringify(normalize(values))
    })
        .then(response => {
            response.json().then(retrieved => {
                dispatch(loading(false));
                dispatch(success(retrieved));
            })
        })
        .catch((e) => {
            dispatch(loading(false));
            dispatch(error(e));
        });
};

export const confirm = (orderId) => dispatch => {
    dispatch(loading(true));

    return fetch(`${orderId}/confirm`, {
        method: "PUT",
        headers: new Headers({"Content-Type": "application/ld+json"}),
        body: JSON.stringify({})
    })
    .then(() => {
        dispatch(loading(false));
        dispatch(success(true))
    })
    .catch((e) => {
        dispatch(loading(false));
        dispatch(error(e.message))
    });
};

export const reserve = (orderId) => dispatch => {
    dispatch(loading(true));

    return fetch(`${orderId}/reserve`, {
        method: "PUT",
        headers: new Headers({"Content-Type": "application/ld+json"}),
        body: JSON.stringify({})
    })
        .then(() => {
            dispatch(loading(false));
            dispatch(success(true))
        })
        .catch((e) => {
            dispatch(loading(false));
            dispatch(error(e.message))
        });
};

export const assign = (orderId, data) => dispatch => {
    dispatch(loading(true));

    return fetch(`${orderId}/assign`, {
        method: "PUT",
        headers: new Headers({"Content-Type": "application/ld+json"}),
        body: JSON.stringify(data)
    })
    .then(() => {
        dispatch(loading(false));
        dispatch(success(true))
    })
    .catch((e) => {
        dispatch(loading(false));
        dispatch(error(e.message))
    });
};

export const cancellationReason = (order, reason, unavailableDays = 0) => dispatch => {
    dispatch(loading(true))

    const values = {
        reason: reason,
        unavailableDays : unavailableDays,
    }

    return fetch(`${order['@id']}/cancellation-reason`, {
        method: "PUT",
        body: JSON.stringify(values),
        headers: new Headers({'Content-Type': 'application/ld+json'}),
    }).then(() => {
        dispatch(loading(false))
        dispatch(success(true))
    })
    .catch((e) => {
        dispatch(loading(false))
        dispatch(error(e.message))
    });
}

export function reset() {
    return dispatch => {
        dispatch(error(false));
        dispatch(success(null));
        dispatch(loading(null));
    };
}
