import React, {Component} from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import PropTypes from "prop-types";
import {FormattedMessage, injectIntl} from "react-intl";

// @mui/material components
import {withStyles} from "@mui/styles";

// @mui/icons-material
import {
    ArrowBackIos,
    ChevronLeft,
    ChevronRight,
    FirstPage,
    LastPage,
    NotificationsActive,
} from "@mui/icons-material";

import {ITEM_PER_PAGE} from "config/_pagination";

// core components
import Snackbar from "components/snackbar/snackbar";
import GridContainer from "components/grid/gridContainer";
import GridItem from "components/grid/gridItem";
import Card from "components/card/card";
import CardHeader from "components/card/cardHeader";
import CardBody from "components/card/cardBody";
import ItemsPerPageSelect from "components/select/itemsPerPageSelect";
import CardFooter from "../../../../../components/card/cardFooter";
import Pagination from "../../../../../components/pagination/pagination";
import Table from "./table";

// styles
import listStyle from "assets/jss/views/report/listStyle";

// actions
import {list, reset} from "actions/report/list";

import abort from "utils/abort";
import Button from "@mui/material/Button";
import {objectSize} from "../../../../../utils/objectSize";
import {encodePercentageCharacter} from "utils/encodePercentageCharacter"

class List extends Component {
    static propTypes = {
        retrieved: PropTypes.array,
        list: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            page: 1,
            itemsPerPage: this.props.itemsPerPage !== null ? this.props.itemsPerPage : ITEM_PER_PAGE,
        };
    }

    componentDidMount() {
        this.props.list(decodeURIComponent(this.props.match.params.fid) + '&order[createdAt]=desc&page=1&itemsPerPage=' + ITEM_PER_PAGE + '&pagination=true');
    }

    componentWillUnmount() {
        this.props.reset();
        abort.abortRequests();
    }

    handleChangeItemsPerPage = event => {
        this.props.list(decodeURIComponent(this.props.match.params.fid) + '&order[createdAt]=desc&page=1&itemsPerPage=' + event.target.value + '&pagination=true');
        this.setState({page: 1});
    };

    pagination(itemsPerPage) {
        const view = this.props.retrieved && this.props.retrieved["hydra:view"];
        if (!view || objectSize(view) <= 2 || this.props.retrieved["hydra:totalItems"] <= itemsPerPage || (this.props.match.params.params && decodeURIComponent(encodePercentageCharacter(this.props.match.params.params)).search("pagination=false") !== -1)) return;

        const {
            "hydra:first": first,
            "hydra:previous": previous,
            "hydra:next": next,
            "hydra:last": last
        } = view;

        const handlePagination = (page) => {
            let params = page.split("&");
            let newPage = 1;
            params.forEach((param, key) => {
                if (param.search("page") !== -1) {
                    let split = param.split("=");
                    newPage = split[1];
                }
            });
            this.props.list(decodeURIComponent(this.props.match.params.fid) + '&order[createdAt]=desc&page=' + newPage + '&itemsPerPage=' + this.state.itemsPerPage + '&pagination=true');
            this.setState({page: newPage});
        };

        const page = this.state.page + " / " + Math.ceil(this.props.retrieved["hydra:totalItems"] / itemsPerPage);

        return (
            <Pagination
                pages={[
                    {
                        text: <FirstPage/>,
                        action: () => (function() {handlePagination(first)}),
                        disabled: !previous
                    },
                    {
                        text: <ChevronLeft/>,
                        action: () => (function() {handlePagination(previous)}),
                        disabled: !previous
                    },
                    {active: true, text: page},
                    {
                        text: <ChevronRight/>,
                        action: () => (function() {handlePagination(next)}),
                        disabled: !next
                    },
                    {
                        text: <LastPage/>,
                        action: () => (function() {handlePagination(last)}),
                        disabled: !next
                    }
                ]}
                color="info"
            />
        );
    }

    render() {
        const {classes, retrieved, intl} = this.props;
        const {itemsPerPage} = this.state;

        return (
            <div className={classes.containerWithSidebar}>
                <div className={classes.container}>
                    {this.props.error && (
                        <Snackbar
                            open={this.state.errorNotification}
                            close
                            closeNotification={() => this.setState({errorNotification: false})}
                            place={"bl"}
                            color={"danger"}
                            icon={function () {
                                return <NotificationsActive/>
                            }}
                            message={this.props.error}
                        />
                    )}

                    <GridContainer>
                        <GridItem xs={12}>
                            <Card className={classes.card}>
                                <CardHeader color="info">
                                    <GridContainer>
                                        <GridItem xs={8} sm={8}>
                                            <h3><FormattedMessage id={"feed.history.title"}/></h3>
                                        </GridItem>
                                        {retrieved && retrieved["hydra:member"].length > 0 &&
                                        <GridItem xs={4} sm={4} className={classes.totalContainer}>
                                            <span className={classes.totalSpan}>
                                                {retrieved && retrieved["hydra:member"] ? intl.formatMessage({id: "feed.history.list.label.totalImport"}) + retrieved["hydra:totalItems"] : null}
                                            </span>
                                            <ItemsPerPageSelect onChange={(event)=> this.handleChangeItemsPerPage(event)} />
                                        </GridItem>
                                        }
                                    </GridContainer>
                                </CardHeader>
                                <CardBody>
                                    <Table tableData={retrieved} history={this.props.history} match={this.props.match}/>
                                </CardBody>
                                <CardFooter center>
                                    {retrieved && retrieved["hydra:member"].length > 0 &&
                                    this.pagination(itemsPerPage)
                                    }
                                </CardFooter>
                                <div className={classes.actionsContainer}>
                                    <Button className={classes.buttonReturn} component={Link} to={`..`}>
                                        <ArrowBackIos/>
                                        <FormattedMessage id={"feed.form.back"}/>
                                    </Button>
                                </div>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        retrieved: state.report.list.retrieved,
        loading: state.report.list.loading,
        error: state.report.list.error,
        itemsPerPage: state.itemsPerPage.itemsPerPage
    };
};

const mapDispatchToProps = dispatch => ({
    list: id => dispatch(list(id)),
    reset: () => dispatch(reset()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(listStyle)(injectIntl(List)));
