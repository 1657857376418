import {
    primaryColor,
    defaultFont
} from "../../main";

const listStyle = () => ({
    primaryTableHeader: {
        color: primaryColor,
        fontSize: "16px",
        fontWeight: "400"
    },
    tableHeadCell: {
        ...defaultFont,
    },
    checkbox: {
        color: primaryColor,
        "&$checked": {
            color: primaryColor,
        },
    },
    checked: {
        color: primaryColor,
    }
});

export default listStyle;
