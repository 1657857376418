import React from "react";
import {TableCell, TableHead, TableRow} from "@mui/material";
import {FormattedMessage} from "react-intl";
import {withStyles} from "@mui/styles";
import detailStyle from "../../../../../assets/jss/views/quote/detailStyle";

const QuoteDetailGridHeader = ({ classes }) => {
    const tableHead = [
        {id: 'product'},
        {id: 'productName'},
        {id: 'quantity'},
        {id: 'unitPrice', subTitle: <><FormattedMessage id={"picking.quote.detail.gridHeader.taxExcluded"}/> / <strong><FormattedMessage id={"picking.quote.detail.gridHeader.taxIncluded"}/></strong></>},
        {id: 'taxRate'},
        {id: 'discount'},
        {id: 'subTotal', subTitle: <><FormattedMessage id={"picking.quote.detail.gridHeader.taxExcluded"}/> / <strong><FormattedMessage id={"picking.quote.detail.gridHeader.taxIncluded"}/></strong></>},
        {id: 'actions'},
    ];

    return (
        <TableHead>
            <TableRow>
                {tableHead.map((prop, key) => {
                    return (
                        <TableCell
                            className={classes.tableCell + " " + classes.tableHeadCell}
                            key={key}
                        >
                            <FormattedMessage id={"picking.quote.detail.gridHeader." + prop.id}/>
                            {!!prop.subTitle && (
                                <div className={classes.tableSubContent}>
                                    {prop.subTitle}
                                </div>
                            )}
                        </TableCell>
                    );
                })}
            </TableRow>
        </TableHead>
    );
};

export default withStyles(detailStyle)(QuoteDetailGridHeader);
