import {fetch} from "../../utils/dataAccess";

export function downloadExport(startDate = null, endDate = null, organization = null) {
    let params = '';

    if (null !== startDate) params += `&startDate=${startDate}`;
    if (null !== endDate) params += `&endDate=${endDate}`;

    return dispatch => {
        fetch("/picking/quote/export?" + params, {method: 'GET'}).catch(error => {
            console.log(error)
        });
    }
}

export function downloadExportLineItem(startDate = null, endDate = null, organization = null) {
    let params = '';

    if (null !== startDate) params += `&startDate=${startDate}`;
    if (null !== endDate) params += `&endDate=${endDate}`;

    return dispatch => {
        fetch("/picking/quote/lineItem/export?" + params, {method: 'GET'}).catch(error => {
            console.log(error)
        });
    }
}
