import {fetch} from "../../../utils/dataAccess";

export function error(error) {
    return {type: "RETAILER_STRIPE_LINK_ERROR", error};
}

export function loading(loading) {
    return {type: "RETAILER_STRIPE_LINK_LOADING", loading};
}

export function success(created) {
    return {type: "RETAILER_STRIPE_LINK_SUCCESS", created};
}

export function invitationLink(retailerId) {

    return dispatch => {
        dispatch(loading(true));

        fetch("/retailer/stripe_invitation_link?retailer_id=" + retailerId, {
            method: "GET",
            }, true)
            .then(response => {
                dispatch(loading(false));
                return response.json();
            })
            .then(retrieved => dispatch(success(retrieved)))
            .catch(e => {
                dispatch(loading(false));
                dispatch(error(e));
            });
    };
}

export function reset() {
    return dispatch => {
        dispatch({type: "RETAILER_STRIPE_LINK_RESET"});
        dispatch(loading(false));
        dispatch(error(null));
    };
}
