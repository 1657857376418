import React, {useContext} from "react";
import QuoteHistoryLine from "./gridHistoryLine";
import {QuoteContext} from "contexts/quoteContext";
import {
    ALL_QUOTES_WITHOUT_QUOTE_CREATION,
    QUOTE_CREATION,
} from "../../../enums/historyType";

const QuoteHistoryContent = () => {
    const { quote } = useContext(QuoteContext);

    return (
        <div>
            {quote.quoteActivities.filter(log => ALL_QUOTES_WITHOUT_QUOTE_CREATION.includes(log['@type'])).map((log, index) => {
                return (
                    <QuoteHistoryLine log={log} key={index}/>
                );
            })}
            {quote.quoteActivities.filter(log => QUOTE_CREATION.includes(log['@type'])).map((log) => {
                return (
                    <QuoteHistoryLine log={log} key={QUOTE_CREATION}/>
                );
            })}
        </div>
    );
}

export default QuoteHistoryContent;
