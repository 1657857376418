import React, {useEffect, useState} from 'react'
import {makeStyles} from '@mui/styles'
import {
    Button,
    createTheme,
    Dialog,
    DialogActions,
    DialogContent,
    StyledEngineProvider,
    TextField,
    ThemeProvider
} from '@mui/material'

import RadioButton from "./RadioButton";

import {FormattedMessage, injectIntl} from 'react-intl'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {borderGrayColor, dangerColor, grayColor, infoColor, infoColorDisabled, infoColorHover} from 'assets/jss/main'
import ship from 'api/order/ship';

import {LineItemStatus} from "utils/LineItemStatus";
import {ShippingMethods} from "utils/ShippingMethods";
import {connect} from "react-redux";

const useStyles = makeStyles(theme => ({
    paper: {
        display: 'inline-flex',
        padding: 24,
        gap: '32px',
        flexDirection: 'column',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
        boxShadow: '0px 4px 22px 0px rgba(0, 0, 0, 0.12)',
        borderRadius: '8px',
        '& .MuiDialogContent-root': {
            padding: 0,
        },
        '& .MuiDialogContent-root h2': {
            padding: 0,
            margin: 0,
            marginBottom: '32px',
            color: '#333',
            fontSize: '20px',
            fontWeight: 700,
            lineHeight: '28px',
            letterSpacing: '0.15px',
        },
        '& .MuiDialogActions-root > :not(:first-of-type) ': {
            marginLeft: '12px',
        },
        '& .MuiDialogActions-root': {
            padding: 0,
            justifyContent: 'flex-end',
        },
    },
    errorMessage: {
        color: dangerColor,
        marginBottom: '10px',
    },
    selector: {
        width: '496px',
        display: 'flex',
        gap: '24px',
        alignItems: 'center',
        padding: '16px 24px',
        marginBottom: '16px',
        borderRadius: '8px',
        border: `1px solid ${borderGrayColor}`,
        cursor: 'pointer',
        '&:last-child': {
            marginBottom: 0,
        },
        '& h3': {
            padding: 0,
            margin: 0,
            marginBottom: '12px',
            color: '#333',
            fontSize: '16px',
            fontWeight: 600,
            lineHeight: '28px',
            letterSpacing: '0.15px',
        },
        '& p': {
            padding: 0,
            margin: 0,
            color: '#333',
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '24px',
            letterSpacing: '0.15px',
        },
        '& .MuiInput-input': {
            color: grayColor,
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '16px',
            borderColor: grayColor,
        },
    },
    selected: {
        borderColor: '#000',
    },
    selectorShipping: {
        '& .MuiTextField-root': {
            marginTop: '28px',
        },
    },
    selectorSelfShipping: {
        '& .MuiTextField-root': {
            marginTop: '8px',
        },
    },
    actionButton: {
        padding: '12px 16px',
        color: '#333',
        fontSize: '14px',
        lineHeight: '14px',
        fontWeight: 400,
        textTransform: 'none',
        borderRadius: 8,
        border: `1px solid ${borderGrayColor}`,
        '&:hover': {
            backgroundColor: '#F1F1F1',
        },
    },
    validatedButton: {
        color: '#FFF',
        backgroundColor: infoColor,
        '&:hover': {
            backgroundColor: infoColorHover,
        },
        '&.Mui-disabled': {
            color: '#FFF',
            backgroundColor: infoColorDisabled,
        },
    },
}))

const theme = createTheme({
    palette: {
        primary: {main: '#007DFF'},
    },
})

function ModalWaybillSelector(props) {
    const {
        lineItems,
        lineItemSelectedIds,
        selectedShipment,
        open,
        onClose
    } = props

    const [selectedSelector, setSelectedSelector] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const [selfShippingNumber, setSelfShippingNumber] = useState('')
    const [shipmentLoading, setShipmentLoading] = useState(false);

    const shippingMethod = lineItems[0].shippingMethod;
    const shippingMethodObject = lineItems[0].shippingMethodObject;

    const isClickAndCollectType =
        shippingMethod === ShippingMethods.CLICK_COLLECT
        || shippingMethod === ShippingMethods.DRIVE
        || shippingMethod === ShippingMethods.LOCKER
    ;
    
    const isLocker = shippingMethod === ShippingMethods.LOCKER;

    const shouldPrint = shippingMethodObject.printReturnLabel || shippingMethodObject.printReturnForm || shippingMethodObject.clickAndCollectTwoStepsForced;
    const isShipWithAPI = lineItems
        .map(li => li.shippingMethodObject.wsdl)
        .filter(wsdl => typeof wsdl === 'string')
        .length >= 1
    ;

    const shippingSelector = 'shipping'
    const selfShippingSelector = 'self-shipping'
    const customerSelector = 'customer'

    const classes = useStyles()

    const dialogTitleMessageId = () => {
        if (isClickAndCollectType) {
            if (shouldPrint) {
                if (isLocker) {
                    return 'picking.order.show.waybillSelector.title_locker_prepare'
                }

                return 'picking.order.show.waybillSelector.title_ClickAndCollect_prepare'
            }

            if (isLocker) {
                return 'picking.order.show.waybillSelector.title_locker'
            }

            return 'picking.order.show.waybillSelector.title_ClickAndCollect'
        }

        return 'picking.order.show.waybillSelector.title'
    }

    const clickAndCollectSelectorContentText = () => {
        let title = 'picking.order.show.waybillSelector.customer.title';
        let subtitle = 'picking.order.show.waybillSelector.customer.subtitle';

        if (shouldPrint) {
            title = 'picking.order.show.waybillSelector.generate.title';
            subtitle = 'picking.order.show.waybillSelector.generate.subtitle';
        }

        if (isLocker) {
            title = 'picking.order.show.waybillSelector.customer.locker.title';
            subtitle = 'picking.order.show.waybillSelector.customer.locker.subtitle'
        }

        return (
            <>
                <h3><FormattedMessage id={title} /></h3>
                <p><FormattedMessage id={subtitle} /></p>
            </>
        )
    }

    const submitButtonTitleMessageId = () => {
        if (isClickAndCollectType) {
            if (shouldPrint) {
                return 'picking.order.show.waybillSelector.action.confirm.generate'
            }

            if (isLocker) {
                return 'picking.order.show.waybillSelector.action.confirm.locker'
            }

            return 'picking.order.show.waybillSelector.action.confirm.clickcollect'
        }

        return 'picking.order.show.waybillSelector.action.confirm'
    }

    function getSelectedLineItem() {
        return lineItems.filter(li => lineItemSelectedIds.includes(li['@id']))
    }

    const [shippingWeight, setShippingWeight] = useState(0)

    useEffect(() => {
        const weight = getSelectedLineItem()
            .map(li => li.status === LineItemStatus.TO_SHIP ? (li?.variation?.weight ?? 0.3) : 0)
            .reduce((acc, value) => acc + value, 0)

        setShippingWeight(weight.toFixed(2));
    }, [lineItemSelectedIds])

    function onSubmit() {
        setShipmentLoading(true);
        const selectedLineItems = getSelectedLineItem();
        const selectedLineItemIds = selectedLineItems.map(lineItem => lineItem.id);

        const values = {
            'preferredPrinters': JSON.parse(localStorage.getItem(`${props.organization.id}_preferredPrinters`)),
            'lineItems': selectedLineItemIds,
        }

        if (selectedSelector === shippingSelector) {
            values.weight = shippingWeight;
            values.shouldPrint = true;
        } else {
            values.trackingNumber = selectedSelector === selfShippingSelector ? selfShippingNumber : 'no-tracking'
            values.shouldPrint = false;
        }

        if (selectedShipment) {
            values.shipmentId = selectedShipment['id']
        }

       ship(lineItems[0].order, values).then(() => {
           onClose();
           setShipmentLoading(false);
           props.refreshOrder(2000);
       }).catch(() => {
           setShipmentLoading(false);
           setErrorMessage('picking.order.show.waybillSelector.error');
       })
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            slotProps={{ backdrop: { style: { backgroundColor: 'rgba(51, 51, 51, 0.20)' } } }}
            classes={{ paper: classes.paper }}
        >
            <DialogContent>
                <h2>
                    <FormattedMessage id={dialogTitleMessageId()} />
                </h2>

                {errorMessage !== '' && (
                    <div className={classes.errorMessage}><FormattedMessage id={errorMessage} /></div>
                )}

                {!isClickAndCollectType && (
                    <>
                        {isShipWithAPI && (
                            <div
                                className={classNames(
                                    classes.selector,
                                    classes.selectorShipping,
                                    {[classes.selected]: selectedSelector === shippingSelector}
                                )}
                                onClick={(e) => {
                                    e.preventDefault()
                                    setSelectedSelector(shippingSelector)
                                }}
                            >
                                <RadioButton checked={selectedSelector === shippingSelector} />
                                <div>
                                    <h3><FormattedMessage id={'picking.order.show.waybillSelector.shipping.title'} /></h3>
                                    <p><FormattedMessage id={'picking.order.show.waybillSelector.shipping.subtitle'} /></p>
                                    {selectedSelector === shippingSelector && (
                                        <StyledEngineProvider injectFirst>
                                            <ThemeProvider theme={theme}>
                                                <TextField
                                                    variant='standard'
                                                    label={<FormattedMessage id={'picking.order.show.waybillSelector.shipping.weight.label'} />}
                                                    onChange={e => {
                                                        const weight = e.target.value.replace(',', '.');
                                                        setShippingWeight(weight);
                                                    }}
                                                    defaultValue={shippingWeight}
                                                    color='primary'
                                                    fullWidth
                                                />
                                            </ThemeProvider>
                                        </StyledEngineProvider>
                                    )}
                                </div>
                            </div>
                        )}
                        <div
                            className={classNames(
                                classes.selector,
                                classes.selectorSelfShipping,
                                {[classes.selected]: selectedSelector === selfShippingSelector}
                            )}
                            onClick={(e) => {
                                e.preventDefault()
                                setSelectedSelector(selfShippingSelector)
                            }}
                        >
                            <RadioButton checked={selectedSelector === selfShippingSelector} />
                            <div>
                                <h3><FormattedMessage id={'picking.order.show.waybillSelector.self_shipping.title'} /></h3>
                                <p><FormattedMessage id={'picking.order.show.waybillSelector.self_shipping.subtitle'} /></p>
                                {selectedSelector === selfShippingSelector && (
                                    <StyledEngineProvider injectFirst>
                                        <ThemeProvider theme={theme}>
                                            <TextField
                                                variant='standard'
                                                label={<FormattedMessage id={'picking.order.show.waybillSelector.self_shipping.number.label'} />}
                                                onChange={e => setSelfShippingNumber(e.target.value)}
                                                defaultValue={selfShippingNumber}
                                                color='primary'
                                                fullWidth
                                            />
                                        </ThemeProvider>
                                    </StyledEngineProvider>
                                )}
                            </div>
                        </div>
                    </>
                )}
                {isClickAndCollectType && (
                    <div
                        className={classNames(
                            classes.selector,
                            classes.selectorCustomer,
                            {[classes.selected]: selectedSelector === customerSelector}
                        )}
                        onClick={(e) => {
                            e.preventDefault()
                            setSelectedSelector(customerSelector)
                        }}
                    >
                        <RadioButton checked={selectedSelector === customerSelector} />
                        <div>
                            {clickAndCollectSelectorContentText()}
                        </div>
                    </div>
                )}
            </DialogContent>
            <DialogActions className={classes.dialogAction}>
                <Button
                    onClick={onClose}
                    className={classes.actionButton}
                >
                    <FormattedMessage id={'picking.order.show.waybillSelector.action.cancel'} />
                </Button>
                <Button
                    onClick={onSubmit}
                    className={classNames(classes.actionButton, classes.validatedButton)}
                    disabled={
                        shipmentLoading ||
                        selectedSelector === ''
                        || shippingWeight.indexOf(' ') >= 0
                        || selfShippingNumber.indexOf(' ') >= 0
                        || (selectedSelector === shippingSelector && shippingWeight === '')
                        || (selectedSelector === selfShippingSelector && selfShippingNumber === '')
                    }
                >
                    <FormattedMessage id={submitButtonTitleMessageId()} />
                </Button>
            </DialogActions>
        </Dialog>
    )
}

ModalWaybillSelector.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    lineItems: PropTypes.array.isRequired,
    lineItemSelectedIds: PropTypes.array.isRequired,
}

export default connect(
    state => ({
        organization: state.currentOrganization.retrieved,
    })
)(injectIntl(ModalWaybillSelector));
