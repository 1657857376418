import React, {useContext} from 'react'
import {makeStyles} from '@mui/styles'
import {FormattedMessage} from 'react-intl'

import {borderGrayColor} from '../../../../assets/jss/main'
import {RecommendationContext} from '../../context/RecommendationContext'
import Loader from '../fields/components/Loader'
import OverrodeRecommendation from './components/OverrodeRecommendation'

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '32px',
        border: `1px solid ${borderGrayColor}`,
        borderRadius: '8px',
        marginLeft: 'unset',
        marginRight: 'unset',
        padding: '24px',
    },
    title: {
        margin: 0,
        padding: 0,
        paddingBottom: '12px',
        color: '#333',
        fontSize: '1.25rem',
        fontWeight: 600,
    },
}))

export default function Index(props) {
    const {apiLoading, recommendations} = useContext(RecommendationContext)
    const overrodeRecommendations = recommendations.filter(recommendation => recommendation.overrodeCategory !== null)
    const classes = useStyles()

    return (
        <>
            <Loader loading={apiLoading} />
            {overrodeRecommendations.length !== 0 && (
                <div className={classes.container}>
                    <h2 className={classes.title}>
                        <FormattedMessage id={'recommendations.overrode_recommendations.title'} />
                    </h2>

                    {overrodeRecommendations.map((recommendation, index) => (
                        <OverrodeRecommendation
                            key={index}
                            recommendation={recommendation}
                        />
                    ))}
                </div>
            )}
        </>
    )
}
