import React, {Component} from "react";
import {withStyles} from "@mui/styles";
import {IconButton, TextField} from "@mui/material";
import {Clear, Search} from "@mui/icons-material";
import {injectIntl} from "react-intl";

import debounce from "lodash.debounce";

import listStyle from "../../../../assets/jss/views/quote/listStyle";
import {SEARCH_QUERY} from "../../enums/urlParametersIdentifier";


class QuoteListSearch extends Component {
    constructor(props) {
        super(props);

        this.debouncer = debounce(() => this.updateUrl(), 1000);
        let params = new URLSearchParams(this.props.history.location.search);
        if (params.has(SEARCH_QUERY)) {
            this.state = {
                search: params.get(SEARCH_QUERY),
            };
            return;
        }

        this.state = {
            search: '',
        };
    }

    updateUrl() {
        let params = new URLSearchParams(this.props.history.location.search);
        params.set(SEARCH_QUERY, this.state.search);
        const newUrl = `${this.props.history.location.pathname}?${params.toString()}`;
        this.props.history.push(newUrl); // This will trigger component "container->setStateFromSearchParams" function
    }

    handleSearch(search) {
        this.setState({search});
        this.debouncer();
    }

    clearSearch() {
        this.setState({search: ''}, this.updateUrl);
    }

    render() {
        const { classes, intl } = this.props;
        return (
            <div className={classes.search}>
                <TextField
                    id="search-input"
                    placeholder={intl.formatMessage({id: "picking.quote.listing.search"})}
                    onChange={(event) => this.handleSearch(event.target.value)}
                    value={this.state.search}
                    variant="outlined"
                    InputProps={{
                        endAdornment: (
                            this.state.search.length > 0 ?
                                <IconButton color="primary" onClick={() => this.clearSearch()} size="large">
                                    <Clear />
                                </IconButton> :
                                <Search color={"disabled"}/>
                        ),
                    }}
                    className={classes.search}
                    type={"string"}
                />
            </div>
        );
    }
}

export default withStyles(listStyle)(injectIntl(QuoteListSearch));
