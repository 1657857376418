import React from "react";
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";

// @mui/material components
import {Dialog, DialogTitle, DialogContent} from "@mui/material";

// core components
import UpdateForm from './components/updateForm'

// actions
import {list, reset} from "actions/authorization/list";
import {update} from "actions/role/update";

class EditDialog extends React.Component {
    componentDidMount() {
        this.props.list()
    }

    componentWillUnmount() {
        this.props.reset()
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if(this.props.updated !== nextProps.updated) {
            this.props.closeDialog()
        }
    }

    update = (values) => {
        this.props.update(this.props.role, values)
    };

    render() {
        const {closeDialog, editDialog, authorizationsList, authorizationsLoading, role} = this.props;

        return (
            <Dialog
                open={editDialog}
                onClose={closeDialog}
                aria-labelledby="responsive-dialog-title"
                fullWidth={true}
                maxWidth={'md'}
                scroll={'body'}
            >
                <DialogTitle id="max-width-dialog-title">
                    <div>
                        <FormattedMessage id={"role.update.form.title"}/>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <UpdateForm
                        onSubmit={this.update}
                        authorizationsList={authorizationsList}
                        closeDialog={closeDialog}
                        authorizationsLoading={authorizationsLoading}
                        initialValues={role}
                    />
                </DialogContent>
            </Dialog>
        )
    }
}


const mapStateToProps = state => {
    return {
        authorizationsList: state.authorization.list.retrieved,
        authorizationsLoading: state.authorization.list.loading,
        updated: state.role.update.updated
    }
};

const mapDispatchToProps = dispatch => ({
    list: () => dispatch(list()),
    reset: () => dispatch(reset()),
    update: (item, values) => dispatch(update(item, values))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(EditDialog);
