import React from "react";
import {connect} from "react-redux";
import {DragDropContext, Droppable} from "react-beautiful-dnd";

// @mui/material components
import {makeStyles} from "@mui/styles";

// core components
import Sector from './sector';

// styles
const useStyles = makeStyles(theme => ({
    boxesList: {
        position: "relative",
        background: "green !important"
    },
    removeStyleType: {
        listStyleType: "none",
    },
    removePadding :{
        listStyleType: "none",
        paddingInlineStart: "0px"
    }
}));

function Boxes (props) {
    const { allSectors, search } = props;

    const classes = useStyles();

    return (
        <DragDropContext className={classes.boxesList}>
            <Droppable droppableId={"list"}>
                {provided => (
                    <ul className={classes.removePadding}>
                        <div ref={provided.innerRef} {...provided.droppableProps}>
                            {allSectors.map((sector, index) => {
                                if (search.length > 0 ? sector : true) {
                                    return (
                                        <Sector
                                            key={sector.id}
                                            data={sector}
                                            provided={provided}
                                            draggableId={sector.id}
                                            index={index}
                                            {...props}
                                        />
                                    )
                                } else return null;
                            })}
                        </div>
                        {provided.placeholder}
                    </ul>
                )}
            </Droppable>
        </DragDropContext>
    )
}

const mapStateToProps = (state) => {
    return {
        created: state.sector.create.created,
        deleted: state.sector.deleteSector.deleted,
        updated: state.sector.update.updated,
    };
};

export default connect(mapStateToProps)(Boxes);
