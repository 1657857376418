import { fetch } from "utils/dataAccess";
import { setOrganization } from "utils/dataAccessOrganizationHandler";
import normalizeTranslations from "../translations";

export function error(error) {
    return { type: "COLOR_CREATE_ERROR", error };
}

export function loading(loading) {
    return { type: "COLOR_CREATE_LOADING", loading };
}

export function success(created) {
    return { type: "COLOR_CREATE_SUCCESS", created };
}

export function create(values) {
    setOrganization(values);

    values = normalizeTranslations(values);

    return dispatch => {
        dispatch(loading(true));

        return fetch("/colors", {method: "POST", body: JSON.stringify(values)})
            .then(response => {
                dispatch(loading(false));

                return response.json();
            })
            .then(retrieved => dispatch(success(retrieved)))
            .catch(e => {
                dispatch(loading(false));
                dispatch(error(e));
            });
    };
}

export function reset() {
    return dispatch => {
        dispatch({type: "COLOR_CREATE_RESET"});
        dispatch(loading(false));
        dispatch(error(null));
        dispatch(success(null))
    };
}
