import {SubmissionError} from "redux-form";
import {fetch, normalize} from "../../utils/dataAccess";

import {success as listSuccess} from "./list";

export function retrieveError(retrieveError) {
    return {type: "PHYSICAL_ENTRY_UPDATE_RETRIEVE_ERROR", retrieveError};
}

export function retrieveLoading(retrieveLoading) {
    return {type: "PHYSICAL_ENTRY_UPDATE_RETRIEVE_LOADING", retrieveLoading};
}

export function retrieveSuccess(retrieved) {
    return {type: "PHYSICAL_ENTRY_UPDATE_RETRIEVE_SUCCESS", retrieved};
}

export function retrieve(id) {
    return dispatch => {
        dispatch(retrieveLoading(true));
        dispatch(retrieveError(""));

        return fetch(id)
            .then(response =>
                response.json()
            )
            .then(({retrieved}) => {
                retrieved = normalize(retrieved);

                dispatch(retrieveLoading(false));
                dispatch(retrieveSuccess(retrieved));
            })
            .catch(e => {
                dispatch(retrieveLoading(false));
                dispatch(retrieveError(e.message));
            });
    };
}

export function updateError(updateError) {
    return {type: "PHYSICAL_ENTRY_UPDATE_UPDATE_ERROR", updateError};
}

export function updateLoading(updateLoading) {
    return {type: "PHYSICAL_ENTRY_UPDATE_UPDATE_LOADING", updateLoading};
}

export function updateSuccess(updated) {
    return {type: "PHYSICAL_ENTRY_UPDATE_UPDATE_SUCCESS", updated};
}

export function update(item, values) {
    return dispatch => {
        dispatch(listSuccess(null));
        dispatch(updateError(""));
        dispatch(updateSuccess(null));
        dispatch(updateLoading(true));

        return fetch(item["@id"], {
            method: "PUT",
            headers: new Headers({"Content-Type": "application/ld+json"}),
            body: JSON.stringify(values)
        })
            .then(response =>
                response.json()
            )
            .then((retrieved) => {
                retrieved = normalize(retrieved);

                dispatch(updateLoading(false));
                dispatch(updateSuccess(retrieved));
            })
            .catch(e => {
                dispatch(updateLoading(false));

                if (e instanceof SubmissionError) {
                    dispatch(updateError(e.errors._error));
                    throw e;
                }

                dispatch(updateError(e.message));
            });
    };
}

export function reset() {
    return dispatch => {
        dispatch({type: "PHYSICAL_ENTRY_UPDATE_RESET"});
    };
}
