import React from "react";
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import FilterChip from "views/marketplace/merchandising/filter/filterChip";
import {getFilterLabel} from "domain/helpers/filterTranslations";

export default function SimpleFilterPanel({items}) {

    function getFiltersColumns(items, itemsPerCol) {
        var cols = [];
        for (var index = 0; index < items.length; index+= itemsPerCol) {
            cols.push(items.slice(index, index + itemsPerCol) );
        }
        return cols;
    }

    return getFiltersColumns(items, 5).map(function (item, i) {
        var entry = item.map(function (element, j) {
            return ( 
                <FilterChip key={j} label={getFilterLabel(element)} item={element} />
            );
        });
        return (
            <Grid key={i} sx={{ borderRight: 1, borderColor: 'divider' }}>
                <Stack key={i} spacing={2} sx={{ paddingX: '15px' }}>
                    {entry}
                </Stack>
            </Grid>
        );
    });
}
