import React, {Component} from "react";
import {Field, reduxForm} from "redux-form";
import {FormattedMessage, injectIntl} from "react-intl";
import {connect} from "react-redux";
import {BeatLoader} from 'react-spinners';
import Select from 'react-select'

// @mui/material components
import {withStyles} from "@mui/styles";
import { FormControl} from "@mui/material";

// core components
import GridItem from "components/grid/gridItem";
import Input from "components/input/input";
import ActionsContainer from "components/form/ActionsContainer";
import FormContainer from "views/configuration/components/FormContainer";
import CancelButton from "views/configuration/components/CancelButton";
import SubmitButton from "views/configuration/components/SubmitButton";

// styles
import inviteFormStyle from "assets/jss/views/member/inviteFormStyle";
import {primaryColor} from 'assets/jss/main'

// actions
import {list as listRetailers} from "actions/retailer/list";
import {list as listRoles} from "actions/role/list";

// helpers
import {capitalize} from "utils/capitalize";
import {Authorizations} from "../../../utils/authorizations";

class InviteForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isRetailerSelected: false,
            error: ""
        };

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        this.props.listRetailers(`order[name]=asc&pagination=false`);
        this.props.listRoles(`order[name]=asc&pagination=false`);
    }

    renderField = data => {
        const {classes} = this.props;

        return (
            <div className={classes.fieldContainer}>
                <label htmlFor={`member_${data.input.name}`} className={classes.fieldLabel}>
                    {data.label}
                </label>
                <Input
                    {...data.input}
                    type={data.type}
                    step={data.step}
                    required={data.required}
                    id={`member_${data.input.name}`}
                    inputProps={{
                        placeholder: data.placeholder,
                        type: data.type,
                        required: data.required,
                        ...data.input,
                        autoComplete: data.autoComplete
                    }}
                    formControlProps={{
                        className: classes.formControl
                    }}
                />
            </div>
        );
    };

    renderSelectRetailer = (data) => {
        const {classes, initialValues, retailersList, retailersLoading} = this.props;

        const options = retailersList && retailersList['hydra:member'].map(retailer => ({
            value: retailer['@id'],
            label: capitalize(retailer.name)
        }));

        const memberRetailers = initialValues && initialValues.retailers.length > 0 && initialValues.retailers.map(retailer => ({
            value: retailer['@id'],
            label: capitalize(retailer.name)
        }));

        return (
            <div className={classes.fieldContainer}>
                <label htmlFor={`member_${data.input.name}`} className={classes.fieldLabel}>
                    {data.label}
                    <BeatLoader
                        sizeUnit={"px"}
                        size={4}
                        color={primaryColor}
                        loading={retailersLoading}
                    />
                </label>
                {!retailersLoading &&
                <FormControl variant="standard" component={"div"} className={classes.formControl}>
                    <Select
                        options={options}
                        defaultValue={memberRetailers}
                        id={`member_${data.input.name}`}
                        onChange={(event) => {
                            this.setState({isRetailerSelected: !!event});
                            data.input.onChange(event);
                        }}
                        placeholder={data.placeholder}
                        isClearable
                        isMulti
                    />
                </FormControl>
                }
            </div>
        );
    };

    renderSelectRole = (data) => {
        const {classes, initialValues, roles, rolesLoading} = this.props;

        const memberRoles = initialValues && initialValues.roles.length > 0 && initialValues.roles.map(role => ({
            value: role['@id'],
            label: capitalize(role && role['name']),
            authorizations: role["authorizations"]
        }));

        const options = roles && roles["hydra:member"].map(role => ({
            value: role['@id'],
            label: capitalize(role && role['name']),
            authorizations: role["authorizations"]
        }));

        return (
            <div className={classes.fieldContainer}>
                <label htmlFor={`role_${data.input.name}`} className={classes.fieldLabel}>
                    {data.label}
                    <BeatLoader
                        sizeUnit={"px"}
                        size={4}
                        color={primaryColor}
                        loading={rolesLoading}
                    />
                </label>
                {this.state.error !== "" && this.state.error["roles"] &&
                    <span className={classes.errorMessages}>
                        <FormattedMessage id={this.state.error["roles"]}/>
                    </span>
                }
                {!rolesLoading &&
                    <FormControl variant="standard" component={"div"} className={classes.formControl}>
                        <Select
                            defaultValue={memberRoles}
                            options={options}
                            id={`role_${data.input.name}`}
                            onChange={(event) => {
                                data.input.onChange(event);
                            }}
                            isMulti
                            placeholder={data.placeholder}
                            required={data.required}
                        />
                    </FormControl>
                }
            </div>
        );
    };

    filterRolesByAuthorizations = (roles) => {
        const topLevelAuthorization = [
            "global.organization.management",
            "marketplace.retailer.view",
            "marketplace.retailer.management",
            "personal_shopper.management",
            "stock_easy.management",
        ];

        let authorizedRole = [];
        let hasTopLevelAuthorization;

        if (this.state.isRetailerSelected) {
            roles.forEach(role => {
                hasTopLevelAuthorization = false;

                role.authorizations.forEach(authorization => {
                    if(topLevelAuthorization.includes(authorization.name)) {
                        hasTopLevelAuthorization = true;
                    }
                });

                if(!hasTopLevelAuthorization) {
                    authorizedRole.push(role);
                }
            });

            return authorizedRole;
        }

        return roles;
    };

    handleSubmit = (e) => {
        const pickedRoles = this.props.fields.values.roles ? this.props.fields.values.roles : [];

        const hasRetailerRole = !!pickedRoles.find(role => {
            const pickingRole = role.authorizations.find(authorization => authorization.name === Authorizations.PICKING_RETAILER)
            if (!pickingRole) { // it's not a picking retailer role
                return false;
            }

            // Check if role contains only picking authorization or stock easy
            let isValidPickingRole = true;
            role.authorizations.forEach(authorization => {
                if (-1 === authorization.name.includes('picking.') && authorization.name !== 'stock_easy.access') {
                    isValidPickingRole = false;
                }
            })

            return isValidPickingRole;
        });

        e.preventDefault();

        const hasRetailersPicked = this.props.fields.values.retailers && this.props.fields.values.retailers.length > 0;

        if (hasRetailerRole && !hasRetailersPicked) {
            const msg = this.props.intl.formatMessage({id: "user.confirm.memberWithPickingRetailerButWithoutRetailerPicked"});

            if (!window.confirm(msg)) {
                e.preventDefault();
                return;
            }
        }

        if (this.props.fields.values && this.props.fields.values.roles && this.props.fields.values.roles.length > 0) {
            this.props.handleSubmit();
        } else {
            this.setState({
                error:  { "roles": "member.form.error.roles.notBlank"}
            })
        }
    };

    render() {
        const {classes, fields, intl, closeDialog, submitLoading, initialValues, submitting} = this.props;
        const isFormValid = !! (fields && fields.values && ((!initialValues ? fields.values.email : true ) && (fields.values.roles && fields.values.roles.length)));

        return (
            <form onSubmit={this.handleSubmit} className={classes.form}>
                <FormContainer>
                    <GridItem xs={12} sm={6}>
                        <Field
                            component={this.renderField}
                            name={"email"}
                            label={<FormattedMessage id={"addMember.form.label.email"}/>}
                            type={"email"}
                            format={(value) => initialValues ? initialValues.user.email : value ? value.toLowerCase() : ""}
                            parse={(value) => initialValues ? initialValues.user.email : value ? value.toLowerCase() : ""}
                            placeholder={intl.formatMessage({id: "addMember.form.placeholder.email"})}
                            required={!initialValues}
                            disabled={!!initialValues}
                        />

                    </GridItem>
                    <GridItem xs={6} sm={6}>
                        <Field
                            component={this.renderSelectRetailer}
                            name={"retailers"}
                            label={<FormattedMessage id={"addMember.form.label.magasin"}/>}
                            type={"select"}
                            placeholder={intl.formatMessage({id: "addMember.form.placeholder.magasin"})}
                        />
                    </GridItem>
                    <GridItem xs={6} sm={6}>
                        <Field
                            component={this.renderSelectRole}
                            name={`roles`}
                            label={<FormattedMessage id={"addMember.form.label.roles"}/>}
                            type={"select"}
                            placeholder={intl.formatMessage({id: "addMember.form.placeholder.roles"})}
                            required
                        />
                    </GridItem>
                </FormContainer>
                <ActionsContainer>
                    <CancelButton onClick={closeDialog} />
                    <SubmitButton
                        disabled={!isFormValid || submitting}
                        loading={submitLoading}
                    />
                </ActionsContainer>
            </form>
        );
    }
}

const mapStateToProps = state => {
    return {
        retailersList: state.retailer.list.retrieved,
        retailersLoading: state.retailer.list.loading,
        roles: state.role.list.retrieved,
        rolesLoading: state.role.list.loading,
        fields: state.form && state.form.addMember,
    }
};

const mapDispatchToProps = dispatch => ({
    listRetailers: params => dispatch(listRetailers(params)),
    listRoles: params => dispatch(listRoles(params)),
});

export default reduxForm({
    form: "addMember",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
})(connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(inviteFormStyle)(injectIntl(InviteForm))));
