import React from "react";
import PropTypes from "prop-types";
import ImageZoom from 'react-medium-image-zoom';

import {makeStyles} from "@mui/styles";

const useStyles = makeStyles(theme => ({
    imgContainer: {
        marginTop: 10,
        display: 'flex',
        justifyContent: 'space-evenly',
    },
    img: {
        width: 100
    },
}));

export default function ProductPreview(props) {
    const classes = useStyles();

    const { links } = props;

    return (
        <div className={classes.imgContainer}>
            {links && links.map((link, index) => (
                <ImageZoom key={index}>
                    <img
                        src={link}
                        alt={"product preview"}
                        className={classes.img}
                    />
                </ImageZoom>
            ))}
        </div>
    )
}

ProductPreview.propTypes = {
    links: PropTypes.arrayOf(PropTypes.string),
};
