import {infoColor, defaultFont} from "../../main";

const stickyNotificationStyle = {
    card: {
        ...defaultFont,
        position: "fixed",
        width: "600px",
        height: "100px",
        right: "-5px",
        zIndex: "1",
        transition: "all 500ms linear",
        backgroundColor: infoColor,
        opacity: "0.95",
        textAlign: "center",
        color: "#FFFFFF"
    },
    cardHidden: {
        right: "-560px"
    },
    cardDestroyed: {
        right: "-640px",
    },
    cardHeader: {
        padding: "5px",
    },
    cardHeaderTitle: {
        fontSize: "18px",
        fontWeight: "bold",
        textTransform: "uppercase",
    },
    cardHeaderOpenBtn: {
        cursor: "pointer",
        float: "left"
    },
    cardHeaderCloseBtn: {
        cursor: "pointer",
        position: "absolute"
    },
    cardBody: {
        padding: "5px",
        overflow: "hidden"
    },
    cardBodyClosed: {
        padding: "0px"
    },
    cardBodyForm: {
        display: "flex",
        justifyContent: "space-around",
        paddingTop: "2px",
        "& input": {
            textAlign: "center"
        }
    },
    datePicker: {
        "& div": {
            color: "#FFFFFF",
            cursor: "pointer",
            width: "150px",
            "& input": {
                cursor: "pointer",
                padding: "0px 0px 0px 5px",
            }
        }
    },
    formSubmit: {
        padding: "0px",
        marginLeft: "20px"
    }
};

export default stickyNotificationStyle;
