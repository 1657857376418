import {fetch} from "../../utils/dataAccess";

export default function (params = "pagination=false&draft=false") {
    return new Promise((resolve, reject) => {
        fetch(`/categories?${params}`)
            .then(response => {
                response.json().then(response => {
                    resolve(response["hydra:member"]);
                });
            })
            .catch(error => reject(error));
    });
}
