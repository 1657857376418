import {getTranslation} from "../domain/helpers/translations";

function getHierarchy(category, categories, hierarchy = null) {
    if (hierarchy === null) {
        hierarchy = [
            {id: category.id, name: getTranslation(category).name}
        ];
    } else {
        hierarchy.unshift({id: category.id, name: getTranslation(category).name});
    }

    if (category.parent) {
        let parentCategory = null;
        categories.forEach((potentialParentCategory) => {
            if (potentialParentCategory.id === category.parent.id) {
                parentCategory = potentialParentCategory;
            }
        });
        if(null !== parentCategory) {
            return getHierarchy(parentCategory, categories, hierarchy);
        }
    }

    return hierarchy;
}

function addSingleCategoryFullPath(category, categories) {
    const hierarchy = getHierarchy(category, categories);
    category.hierarchy = hierarchy;
    category.fullPath = hierarchy.map((category) => category.name).join(' > ');
}

export function addCategoryFullPath(categoriesOrCategory, categories) {
    if (!categoriesOrCategory) {
        throw new Error('You must specify either a category or an array of categories');
    }

    if (categoriesOrCategory instanceof Array) {
        categoriesOrCategory.forEach((category => addSingleCategoryFullPath(category, categoriesOrCategory)));
        return;
    }

    if (!categories) {
        throw new Error('If you give a single category, you need to specifies other categories data.');
    }

    addSingleCategoryFullPath(categoriesOrCategory, categories);
}
