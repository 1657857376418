import React from "react";
import {Route} from "react-router-dom";
import {
    List,
    CsvLocalCreate,
    CsvLocalUpdate,
    XmlLocalCreate,
    XmlLocalUpdate,
    JsonLocalCreate,
    JsonLocalUpdate,
    CsvRemoteCreate,
    CsvRemoteUpdate,
    XmlRemoteCreate,
    XmlRemoteUpdate,
    JsonRemoteCreate,
    JsonRemoteUpdate,
    FTPFeedCreate,
    FTPFeedUpdate,
    XmlFtpFeedCreate,
    XmlFtpFeedUpdate,
    JsonFtpFeedCreate,
    JsonFtpFeedUpdate,
    GoogleSpreadsheetCreate,
    GoogleSpreadsheetUpdate,
    ApiCreate,
    ApiUpdate,
    MapperUpdate,
    AttributeUpdate,
    HistoryList,
    HistoryShow,
} from "../../views/marketplace/retailer/feed/index";
import RequireAuthentication from "../../hoc/authentication/requireAuthentication";
import RetailerLoader from "../../hoc/retailer/retailerLoader";
import * as Cookies from "js-cookie";
import {Authorizations, hasAuthorization} from "../../utils/authorizations";

let routes = [];
const applications = Cookies.get("_a") ? JSON.parse(Cookies.get("_a").toString()) : null;
const authorizations = Cookies.get("_b") ? JSON.parse(Cookies.get("_b").toString()) : [];

if (applications && applications["marketplace"] && hasAuthorization(authorizations, Authorizations.MARKETPLACE_FEED_MANAGEMENT)) {
    routes.push([
        <Route
            path="/retailers/show/:id/feeds/"
            component={RequireAuthentication(RetailerLoader(List))}
            exact
            strict
            key="list"
        />,
        <Route
            path="/retailers/show/:id/feeds/csv-local/create"
            component={RequireAuthentication(RetailerLoader(CsvLocalCreate))}
            exact
            key="csvLocalCreate"
        />,
        <Route
            path="/retailers/show/:id/feeds/csv-local/edit/:fid"
            component={RequireAuthentication(RetailerLoader(CsvLocalUpdate))}
            exact
            key="csvLocalUpdate"
        />,
        <Route
            path="/retailers/show/:id/feeds/xml-local/create"
            component={RequireAuthentication(RetailerLoader(XmlLocalCreate))}
            exact
            key="xmlLocalCreate"
        />,
        <Route
            path="/retailers/show/:id/feeds/xml-local/edit/:fid"
            component={RequireAuthentication(RetailerLoader(XmlLocalUpdate))}
            exact
            key="xmlLocalUpdate"
        />,
        <Route
            path="/retailers/show/:id/feeds/json-local/create"
            component={RequireAuthentication(RetailerLoader(JsonLocalCreate))}
            exact
            key="jsonLocalCreate"
        />,
        <Route
            path="/retailers/show/:id/feeds/json-local/edit/:fid"
            component={RequireAuthentication(RetailerLoader(JsonLocalUpdate))}
            exact
            key="jsonLocalUpdate"
        />,
        <Route
            path="/retailers/show/:id/feeds/csv-ftp/create"
            component={RequireAuthentication(RetailerLoader(FTPFeedCreate))}
            exact
            key="FTPFeedCreate"
        />,
        <Route
            path="/retailers/show/:id/feeds/csv-ftp/edit/:fid"
            component={RequireAuthentication(RetailerLoader(FTPFeedUpdate))}
            exact
            key="FTPFeedUpdate"
        />,
        <Route
            path="/retailers/show/:id/feeds/xml-ftp/create"
            component={RequireAuthentication(RetailerLoader(XmlFtpFeedCreate))}
            exact
            key="XmlFtpFeedCreate"
        />,
        <Route
            path="/retailers/show/:id/feeds/xml-ftp/edit/:fid"
            component={RequireAuthentication(RetailerLoader(XmlFtpFeedUpdate))}
            exact
            key="XmlFtpFeedUpdate"
        />,
        <Route
            path="/retailers/show/:id/feeds/json-ftp/create"
            component={RequireAuthentication(RetailerLoader(JsonFtpFeedCreate))}
            exact
            key="JsonFtpFeedCreate"
        />,
        <Route
            path="/retailers/show/:id/feeds/json-ftp/edit/:fid"
            component={RequireAuthentication(RetailerLoader(JsonFtpFeedUpdate))}
            exact
            key="JsonFtpFeedUpdate"
        />,
        <Route
            path="/retailers/show/:id/feeds/csv-remote/create"
            component={RequireAuthentication(RetailerLoader(CsvRemoteCreate))}
            exact
            key="csvRemoteCreate"
        />,
        <Route
            path="/retailers/show/:id/feeds/csv-remote/edit/:fid"
            component={RequireAuthentication(RetailerLoader(CsvRemoteUpdate))}
            exact
            key="csvRemoteUpdate"
        />,
        <Route
            path="/retailers/show/:id/feeds/xml-remote/create"
            component={RequireAuthentication(RetailerLoader(XmlRemoteCreate))}
            exact
            key="xmlRemoteCreate"
        />,
        <Route
            path="/retailers/show/:id/feeds/xml-remote/edit/:fid"
            component={RequireAuthentication(RetailerLoader(XmlRemoteUpdate))}
            exact
            key="xmlRemoteUpdate"
        />,
        <Route
            path="/retailers/show/:id/feeds/json-remote/create"
            component={RequireAuthentication(RetailerLoader(JsonRemoteCreate))}
            exact
            key="jsonRemoteCreate"
        />,
        <Route
            path="/retailers/show/:id/feeds/json-remote/edit/:fid"
            component={RequireAuthentication(RetailerLoader(JsonRemoteUpdate))}
            exact
            key="jsonRemoteUpdate"
        />,
        <Route
            path="/retailers/show/:id/feeds/google-sheets-remote/create"
            component={RequireAuthentication(RetailerLoader(GoogleSpreadsheetCreate))}
            exact
            key="googleSpreadsheetCreate"
        />,
        <Route
            path="/retailers/show/:id/feeds/google-sheets-remote/edit/:fid"
            component={RequireAuthentication(RetailerLoader(GoogleSpreadsheetUpdate))}
            exact
            key="googleSpreadsheetUpdate"
        />,
        <Route
            path="/retailers/show/:id/feeds/api/create"
            component={RequireAuthentication(RetailerLoader(ApiCreate))}
            exact
            key="historyList"
        />,
        <Route
            path="/retailers/show/:id/feeds/api/edit/:fid"
            component={RequireAuthentication(RetailerLoader(ApiUpdate))}
            exact
            key="apiUpdate"
        />,
        <Route
            path="/retailers/show/:id/feeds/:fid/mapper/edit/:mid"
            component={RequireAuthentication(RetailerLoader(MapperUpdate))}
            exact
            key="mapperUpdate"
        />,
        <Route
            path="/retailers/:id/attributes-mapping"
            component={RequireAuthentication(RetailerLoader(AttributeUpdate))}
            exact
            key="attributeUpdate"
        />,
        <Route
            path="/retailers/show/:id/feeds/:fid/history"
            component={RequireAuthentication(RetailerLoader(HistoryList))}
            exact
            key="historyList"
        />,
        <Route
            path="/retailers/show/:id/feeds/:fid/history/:hid"
            component={RequireAuthentication(HistoryShow)}
            exact
            key="historyShow"
        />,
    ])
}

export default routes;
