import React, { useEffect, useState } from "react";
import connect from "react-redux/es/connect/connect";
import {Redirect} from "react-router-dom";
import { FormattedMessage } from "react-intl";

// @mui/material components
import { Tooltip, Fab } from "@mui/material";

// @mui/icons-material
import { AddRounded } from "@mui/icons-material";

// core components
import Card from 'components/card/card';
import CardBody from 'components/card/cardBody';
import CardHeader from 'components/card/cardHeader';
import CreateDialog from './components/createDialog';
import GlobalAction from "./components/globalAction";
import GridContainer from 'components/grid/gridContainer';
import GridItem from 'components/grid/gridItem';
import ListLoader from '../configuration/components/listLoader';
import Notification from "./components/notification";
import Search from '../configuration/components/search';
import TableHead from '../configuration/components/tableHead';
import TableBody  from "./components/tableBody";

// styles
import listStyle from "assets/jss/views/configuration/defaultListStyle";

// actions
import { genderList } from "actions/gender/list";

// utils
import {Authorizations, hasAuthorization} from "../../utils/authorizations";
import {withStyles} from "@mui/styles";

function List (props) {
    const {
        genderList,
        classes,
        created,
        deleted,
        deletedSelection,
        loading,
        retrieved,
        updated,
        updatedPosition,
        updatePositionLoading,
    } = props;

    const [createDialog, showCreateDialog] = useState(false);
    const [showNotification, setShowNotification] = useState(false);
    const [search, setSearch] = useState('');
    const [checkedGenders, setCheckedGenders] = useState([]);

    useEffect(() => {
        genderList("retailer=&order[position]=asc&pagination=false&draft=false");
        setShowNotification(true);
    }, [created, deleted, updated, deletedSelection, updatedPosition, genderList]);

    if (false === hasAuthorization(props.authorizations, Authorizations.GLOBAL_ORGANIZATION_ESHOP_MANAGEMENT)) {
        return <Redirect to={'/'} />;
    }
    
    return (
        <div className={classes.containerWithSidebar}>
            <div className={classes.container}>
                <Notification isOpen={showNotification} closeNotification={() => setShowNotification(false)} />
                <GridContainer>
                    <GridItem xs={12}>
                        <Card className={classes.card}>
                            <CardHeader color="info">
                                <GridContainer>
                                    <GridItem xs={9} sm={10}>
                                        <h3>
                                            <FormattedMessage id={"gender.title"} />
                                        </h3>
                                    </GridItem>
                                    <GridItem xs={3} sm={2} className={classes.mainActionsContainer}>
                                        <Fab onClick={() => showCreateDialog(true)} color={"primary"}>
                                            <Tooltip title={<FormattedMessage id={"gender.add.gender"} />}>
                                                <AddRounded fontSize={"large"} />
                                            </Tooltip>
                                        </Fab>
                                    </GridItem>
                                </GridContainer>
                            </CardHeader>
                            <CardBody>
                                <div className={classes.subTableContainer}>
                                    {checkedGenders.length > 0 && (
                                        <GlobalAction genders={checkedGenders} />
                                    )}
                                    <Search
                                        attributeName={"gender"}
                                        search={search}
                                        setSearch={setSearch}
                                    />
                                </div>
                                <div className={classes.spacer}></div>
                                <div className={classes.tableWrapper}>
                                    {!retrieved && <ListLoader loading={loading || updatePositionLoading} />}
                                    <div>
                                        <TableHead
                                            listLoading={retrieved && (loading || updatePositionLoading)}
                                            headers={[
                                                "gender.table.header.name",
                                                "gender.table.header.date",
                                            ]}
                                        />
                                        <TableBody
                                            retrieved={retrieved}
                                            search={search}
                                            checkedGenders={checkedGenders}
                                            setCheckedGenders={setCheckedGenders}
                                        />
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
            <CreateDialog closeDialog={() => showCreateDialog(false)} isOpen={createDialog} />
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        retrieved: state.gender.list.retrieved,
        loading: state.gender.list.loading,
        created: state.gender.create.created,
        deleted: state.gender.deleteGender.deleted,
        updated: state.gender.update.updated,
        updatedPosition: state.gender.updatePosition.updated,
        updatePositionLoading: state.gender.updatePosition.loading,
        deletedSelection: state.gender.deleteSelection.deleted,
        authorizations: state.authentication.authorizations,
    };
};

const mapDispatchToProps = dispatch => ({
    genderList: params => dispatch(genderList(params)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(listStyle)(List));
