import * as yup from "yup";
import {messages} from './messages';

const validation = {
    mixed: {
        default: messages['yup.mixed.default'],
        required: messages['yup.mixed.required'],
        oneOf: messages['yup.mixed.oneOf'],
        notOneOf: messages['yup.mixed.notOneOf'],
        defined: messages['yup.mixed.defined'],
    },
    string: {
        length: messages['yup.string.length'],
        min: messages['yup.string.min'],
        max: messages['yup.string.max'],
        matches: messages['yup.string.matches'],
        email: messages['yup.string.email'],
        url: messages['yup.string.url'],
        uuid: messages['yup.string.uuid'],
        lowercase: messages['yup.string.lowercase'],
        uppercase: messages['yup.string.uppercase'],
    },
    number: {
        min: messages['yup.number.min'],
        max: messages['yup.number.max'],
        lessThan: messages['yup.number.lessThan'],
        moreThan: messages['yup.number.moreThan'],
        positive: messages['yup.number.positive'],
        negative: messages['yup.number.negative'],
        integer: messages['yup.number.integer'],
    },
    date: {
        min: messages['yup.date.min'],
        max: messages['yup.date.max'],
    },
    boolean: {
        isValue: messages['yup.boolean.isValue'],
    },
    object: {
        noUnknown: messages['yup.object.noUnknown'],
    },
    array:  {
        min: messages['yup.array.min'],
        max: messages['yup.date.max'],
        length: messages['yup.date.length'],
    },
};
yup.setLocale(validation);

export default yup;
