import React, { useEffect } from "react";
import { connect }  from "react-redux";
import { FormattedMessage } from "react-intl";

// @mui/material components
import { Dialog, DialogTitle, DialogContent } from "@mui/material";

// core components
import Form from './form';

function CreateDialog(props) {
    const { color, closeDialog, created, isOpen, isSubColor, position, allColors } = props;

    useEffect(() => {
        if (created) {
            closeDialog();
        }
    }, [created]);

    return (
        <Dialog
            open={isOpen}
            onClose={closeDialog}
            maxWidth={'sm'}
            scroll={'body'}
        >
            <DialogTitle>
                <FormattedMessage id={isSubColor ? "color.form.sub.create.title" : "color.form.create.title"} />
            </DialogTitle>
            <DialogContent>
                <Form
                    closeDialog={closeDialog}
                    color={isSubColor && color}
                    isSubColor={isSubColor}
                    allColors={allColors}
                    position={position}
                />
            </DialogContent>
        </Dialog>
    )
}

const mapStateToProps = state => {
    return {
        created: state.color.create.created,
    }
};

export default connect(
    mapStateToProps,
    null,
)(CreateDialog);
