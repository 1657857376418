import React, {useContext, useState} from 'react'
import {Radio} from '@mui/material'
import {makeStyles} from '@mui/styles'
import {useFormikContext} from 'formik'
import {FormattedMessage} from 'react-intl'
import PropTypes from 'prop-types'

import CornerSelector from './CornerSelector'
import CategorySelector from './CategorySelector'
import SelectorContainer from './SelectorContainer'
import {RecommendationFormContext} from '../../../../../context/RecommendationFormContext'
import {borderGrayColor, greyColorHover, infoColor, radioCircleGrey} from '../../../../../../../assets/jss/main'
import { ReactComponent as ArrowDown } from 'assets/img/arrow-down.svg'
import { ReactComponent as Box } from 'assets/img/box.svg'
import { ReactComponent as Chart } from 'assets/img/chart.svg'
import { ReactComponent as ShoppingBag } from 'assets/img/shoppingbag.svg'

const useStyles = makeStyles(theme => ({
    container: {
        position: 'relative',
    },
    selector: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '1.5rem 1rem',
        cursor: 'pointer',
        border: `1px solid ${borderGrayColor}`,
        borderBottom: '1px solid white',
        background: 'white',
        '&:first-child': {
            borderTopLeftRadius: '8px',
            borderTopRightRadius: '8px',
        },
        '&:last-child': {
            borderBottomLeftRadius: '8px',
            borderBottomRightRadius: '8px',
            borderBottomColor: borderGrayColor,
        },
        '&:hover': {
            backgroundColor: greyColorHover,
            border: `1px solid ${infoColor}`,
        },
        '& .MuiRadio-root': {
            padding: '0',
        },
    },
    name: {
        display: 'flex',
        alignItems: 'center',
        fontWeight: '400',
        color: '#333',
        '& svg': {
            width: '20px',
            height: '20px',
            marginRight: '5px',
        },
    },
}))

const RadioButtonIcon = ({ checked }) => (
    <svg
        width="20px"
        height="20px"
        viewBox="0 0 24 24"
        fontSize="20px"
    >
        <circle
            cx="50%"
            cy="50%"
            r="11px"
            stroke={radioCircleGrey}
            fill="none"
        />
        {checked && (
            <circle
                cx="50%"
                cy="50%"
                r="6px"
                fill={infoColor}
            />
        )}
    </svg>
)

export default function Selectors(props) {
    const {onSelectedNode, formFieldName} = props
    const {getValueByFormFieldName} = useContext(RecommendationFormContext)
    const formikContext = useFormikContext()
    const selectedNode = getValueByFormFieldName(formFieldName)
    const [openedCategories, setOpenCategories] = useState(false)
    const [openedCorners, setOpenCorners] = useState(false)
    const classes = useStyles()

    function handleSelectAll() {
        formikContext.setFieldValue(formFieldName, null)
        onSelectedNode()
    }

    return (
        <div className={classes.container}>
            {!openedCategories && !openedCorners && (
                <SelectorContainer>
                    <div onClick={() => handleSelectAll()} className={classes.selector}>
                        <span className={classes.name}>
                            <Box /> <FormattedMessage id={"recommendations.form.recommended_product.all_products"} />
                        </span>
                        <Radio
                            icon={<RadioButtonIcon />}
                            checkedIcon={<RadioButtonIcon checked />}
                            checked={selectedNode === null}
                        />
                    </div>
                    <div onClick={() => setOpenCategories(true)} className={classes.selector}>
                        <span className={classes.name}>
                            <Chart /> <FormattedMessage id={"recommendations.form.recommended_product.categories.name"} />
                        </span>
                        <ArrowDown />
                    </div>
                    <div onClick={() => setOpenCorners(true)} className={classes.selector}>
                        <span className={classes.name}>
                            <ShoppingBag /> <FormattedMessage id={"recommendations.form.recommended_product.corners.name"} />
                        </span>
                        <ArrowDown />
                    </div>
                </SelectorContainer>
            )}
            {openedCategories && (
                <CategorySelector
                    onClickBack={() => setOpenCategories(false)}
                    onSelectedCategory={(category) => {
                        formikContext.setFieldValue(formFieldName, category)
                        onSelectedNode()
                        setOpenCategories(false)
                    }}
                    formFieldName={formFieldName}
                />
            )}
            {openedCorners && (
                <CornerSelector
                    onClickBack={() => setOpenCorners(false)}
                    onSelectedCorner={(corner) => {
                        formikContext.setFieldValue(formFieldName, corner)
                        onSelectedNode()
                        setOpenCorners(false)
                    }}
                    formFieldName={formFieldName}
                />
            )}
        </div>
    )
}

Selectors.propTypes = {
    onSelectedNode: PropTypes.func.isRequired,
    formFieldName: PropTypes.string.isRequired,
}
