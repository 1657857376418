import React, {Component} from 'react';
import {Draggable, DragDropContext, Droppable} from 'react-beautiful-dnd'
import {FormattedMessage} from "react-intl";

// @mui/material components
import {
    Dialog,
    DialogActions,
    DialogContent,
} from "@mui/material";

// core components
import GridContainer from "components/grid/gridContainer";
import Button from "components/button/button";
import GridItem from "components/grid/gridItem";
import Card from "components/card/card";
import CardBody from "components/card/cardBody";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";

export default class MultiChoiceDialog extends Component {
    render() {
        const {open, handleModalClose, options, defaultValue, onDragEnd, classes} = this.props;
        let resultOption = [];
        let defaultValues = defaultValue.split(">");
        let draggableOptions = options;

        defaultValues && defaultValues.map(value => {
            return draggableOptions = draggableOptions.filter(option => {
                if (option.label === value) {
                    resultOption.push(option);
                    return false
                }
                return true
            })
        })

        return (
            <React.Fragment>
                <Dialog
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={open}
                    onClose={handleModalClose}
                    aria-labelledby="max-width-dialog-title"
                    classes={{paper: classes.draggableNoScroll}}
                >
                    <DialogTitle><FormattedMessage id={'mapper.label.combination.title'}/></DialogTitle>
                    <DialogContent classes={{root: classes.draggableNoScroll}}>
                        <DialogContentText>
                            <FormattedMessage id={'mapper.label.combination.explanation'}/>
                        </DialogContentText>
                        <DragDropContext onDragEnd={(event) => onDragEnd(resultOption, draggableOptions, event)}>
                            <GridContainer>
                                <GridItem xs={6} sm={6}>
                                    <Card className={classes.draggableCardMasterLeft}>
                                        <CardBody className={classes.draggableStyle}>
                                            <Droppable droppableId={"options"}>
                                                {(provided, snapshot) => {
                                                    return (<div {...provided.droppableProps} ref={provided.innerRef}>
                                                        {draggableOptions && draggableOptions.map((option, index) => (
                                                            <Draggable key={option.id} draggableId={option.id} index={index}>
                                                                {(provided) => (
                                                                    <Card
                                                                        className={classes.draggableCard}
                                                                        key={`list_${index}`}
                                                                        draggableProps={{
                                                                            ref: provided.innerRef,
                                                                            ...provided.draggableProps,
                                                                            ...provided.dragHandleProps,
                                                                        }}
                                                                    >
                                                                        <CardBody className={classes.draggableCardBody}>
                                                                            <span className={classes.draggableLabels}>{option.label}</span>
                                                                        </CardBody>
                                                                    </Card>
                                                                )}
                                                            </Draggable>
                                                        ))}
                                                        {provided.placeholder}
                                                    </div>)
                                                }}
                                            </Droppable>
                                        </CardBody>
                                    </Card>
                                </GridItem>
                                <GridItem xs={6} sm={6}>
                                    <Card className={classes.draggableCardMasterRight}>
                                        <CardBody className={classes.draggableStyle}>
                                            <Droppable droppableId={"combineds"}>

                                                {(provided) => (
                                                    <div {...provided.droppableProps} ref={provided.innerRef}>
                                                        {resultOption && resultOption.map((option, index) => (
                                                            <Draggable key={option.id} draggableId={option.id} index={index}>
                                                                {(provided) => (
                                                                    <Card
                                                                        className={classes.draggableCard}
                                                                        key={`combined_${index}`}
                                                                        draggableProps={{
                                                                            ref: provided.innerRef,
                                                                            ...provided.draggableProps,
                                                                            ...provided.dragHandleProps,
                                                                        }}
                                                                    >
                                                                        <CardBody className={classes.draggableCardBody}>
                                                                            <span className={classes.draggableLabels}>{option.label}</span>
                                                                        </CardBody>
                                                                    </Card>
                                                                )}
                                                            </Draggable>
                                                        ))}
                                                        {provided.placeholder}
                                                    </div>
                                                )}
                                            </Droppable>
                                        </CardBody>
                                    </Card>
                                </GridItem>
                            </GridContainer>
                        </DragDropContext>
                    </DialogContent>
                    <DialogActions>
                        <div className={classes.actionsContainer}>
                            <Button round color="success" type="submit" onClick={handleModalClose}>
                                <FormattedMessage id={"mapper.form.action.submit"}/>
                            </Button>
                        </div>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}
