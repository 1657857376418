import React from "react";
import {BeatLoader} from "react-spinners";

import {infoColor} from "assets/jss/main";

export default function Loader(props) {
    return (
        <BeatLoader
            color={infoColor}
            {...props}
        />
    )
}
