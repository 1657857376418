import {LineItemStatus} from "./LineItemStatus";

export const LineItemTransitions = {
    RESERVE : 'reserve',
    COLLECT : 'collect',
    SHIP : 'ship',
    CANCEL : 'cancel',
    UNAVAILABLE : 'unavailable',
    RETURN_RUNNER : 'return_runner',
    RETURN_RETAILER : 'return_retailer',
    CANCEL_RETURNING_RUNNER : 'cancel_returning_runner',
    CANCEL_RETURNING_RETAILER : 'cancel_returning_retailer',
    RETURN : 'return',
    CONFIRM_RETURN : 'confirm_return',
}

export function getTransitionsThatCanApplyCancellationReasonByStatus(status) {
    let cancellationTransitions = []

    switch (status) {
        case LineItemStatus.PENDING:
        case LineItemStatus.RESERVED:
            cancellationTransitions = [LineItemTransitions.CANCEL, LineItemTransitions.UNAVAILABLE];
            break;
        case LineItemStatus.TO_SHIP:
            cancellationTransitions = [LineItemTransitions.CANCEL];
            break;
        case LineItemStatus.CONFIRMED:
            cancellationTransitions = [
                LineItemTransitions.CANCEL,
                LineItemTransitions.RETURN_RUNNER,
            ];
            break;
        case LineItemStatus.RETURNING_RUNNER:
            cancellationTransitions = [
                LineItemTransitions.CANCEL_RETURNING_RUNNER,
            ]
            break;
        case LineItemStatus.RETURNING_RETAILER:
            cancellationTransitions = [
                LineItemTransitions.CANCEL_RETURNING_RETAILER,
            ]
            break;
    }

    return cancellationTransitions
}

// according to workflow.yaml in MP-API some transition does not trigger a refund
export function getCancellationTransitionsThatNotRefund() {
    return [
        LineItemTransitions.RETURN_RETAILER,
        LineItemTransitions.CANCEL_RETURNING_RUNNER,
        LineItemTransitions.CANCEL_RETURNING_RETAILER,
    ];
}
