import {fetch} from "utils/dataAccess";

export default function update(id, comment) {
    const body = {
        comment: comment
    }

    return new Promise((resolve, reject) => {
        fetch(`/quote_comments/${id}`, {
            method: 'PUT',
            body:  JSON.stringify(body)
        })
        .then(response => {
            response.json().then(response => {
                resolve(response);
            });
        })
        .catch(error => reject(error));
    });
}
