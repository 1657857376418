import React from 'react'
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";

import Button from "components/button/button";
import Loader from "./loader";

SubmitButton.defaultProps = {
    disabled: false,
    isEditing: false,
    loading: false
}

SubmitButton.propTypes = {
    disabled: PropTypes.bool,
    isEditing: PropTypes.bool,
    loading: PropTypes.bool
}

export default function SubmitButton({disabled, isEditing, loading}) {
    return (
        <Button
            round
            color={"success"}
            type="submit"
            disabled={disabled}
        >
            <FormattedMessage id={isEditing ? "configuration.form.update.submit" : "configuration.form.submit"} />
            {loading && <Loader />}
        </Button>
    )
}
