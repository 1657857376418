import {dangerColor, lightGrayColor, primaryColor} from "../../../../../main";

const CreateProductFormStyle = () => ({
    strong: {
        color: primaryColor,
        fontWeight: "bold",
    },
    crossed: {
      textDecoration: "line-through"
    },
    center: {
        textAlign: "center",
    },
    dialogContentBarcode: {
        maxWidth: "600px",
        overflow: "visible"
    },
    section: {
      marginBottom: "1rem",
    },
    textField: {
        marginBottom: "1.6rem",
        '& label': {
            color: primaryColor,
            fontSize: "1.2rem",
            // Label is positioned absolutely by react
            top: "-12px",
            left: "-13px",
        },
        '& legend > span': {
            display: 'none',
        }
    },
    modalTextField: {
        marginTop: "1.2rem",
        marginBottom: "1.6rem",
        '& label': {
            color: primaryColor,
            fontSize: "1.2rem",
            // Label is positioned absolutely by react
            top: "-12px",
            left: "-13px",
        },
        '& legend > span': {
            display: 'none',
        },
        '& input': {
            padding: "11px 14px",
        }
    },
    label: {
        color: primaryColor,
        fontWeight: "400"
    },
    labelWrapper: {
        minHeight: "45px",
        display: "flex",
        alignItems: "center"
    },
    variationTextFieldWrapper: {
        paddingBottom: "1rem",
        marginTop: "1rem",
        marginBottom: "2rem",
        borderBottom: "1px solid " + lightGrayColor
    },
    actionButton: {
        marginTop: "-25px",
        marginBottom: "1rem"
    },
    scanButton: {
        height: '30px',
        width: '60px',
        cursor: 'pointer',
        paddingTop: '18px',
        paddingLeft: '15px',
        backgroundColor: 'white !important',
        border: 'gray 1px solid',
    },
    scanIcon: {
        width: '100% !important',
        height: '35px !important',
    },
    detailsAction: {
        textAlign: "right",
    },
    errorMessage: {
        color: dangerColor,
        textAlign: "center",
        fontWeight: "bold",
    },
    stepContainer: {
        paddingBottom: "3rem",
    },
    chip: {
        maxWidth: "120px",
    },
    chipContainer: {
        margin: "0 20px 20px 0",
    },
    deleteColor: {
        cursor:"pointer",
        paddingBottom: "1px",
    },
    hide: {
        display: "none"
    },

    // VARIATION
    variation: {
        borderTop: "1px solid " + lightGrayColor,
        paddingTop: "1rem"
    },
    variationName: {
        marginBottom: "1rem",
    },
    chipsContainer: {
        marginBottom: "1rem",
    },

    // BARCODE
    barcodeCard: {
        borderRadius: 0,
        minHeight: window.innerHeight * 0.94
    },
    loader: {
        width: "100%",
        textAlign: "center",
        marginTop: "15px",
    },
    cardHeader: {
        padding: 0,
        borderRadius: 0
    },
    dialogActions: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
    },
    dialogVariationActions: {
        display: "flex",
        flexDirection: "column",
        marginTop: "100px"
    },
    error:{
        color: "red"
    },
    referenceButton: {
        textAlign: "center",
        marginTop: "0.3rem"
    },
    scannerError: {
        top: "2rem",
        margin: "auto",
        maxWidth: "400px",
        "& p": {
            textTransform: "none",
            textAlign: "center",
        }
    },
    barcodeWrapper: {
        maxWidth: "400px",
        margin: "auto",
    },
    scannerWrapper: {
        maxWidth: "400",
        margin: "auto"
    },

    // PRICES
    globalPriceInfos: {
        display: "flex",
        flexDirection: "row"
    },
    variationPriceInfos:{
        display: "flex",
        flexDirection: "row"
    },

    stepTitle: {
        fontWeight: "bold",
        fontSize: "1.1rem",
        color: primaryColor,
        marginBottom: "1rem",
    },
    description: {
        marginTop: "2rem",
        maxWidth: "650px"
    },

    // STOCK
    stockHeader: {
        textAlign: "center",
        marginBottom: "2rem",
        '& h2': {
            fontSize: "1.2rem",
            fontWeight: "bold",
            color: primaryColor,
            marginBottom: 0,
        },
        '& h3': {
            fontSize: "1rem",
            fontWeight: "normal",
            margin: 0,
            marginBottom: "1rem",
        }
    },
    stockColorTitle: {
        textAlign: "center",
        marginTop: "0.5rem",
        marginBottom: "1rem",
    },

    // CONFIRMATION STEP
    confirmationTitle: {
        fontWeight: "bold",
        fontSize: "1rem",
        color: primaryColor,
        textAlign: "center",
        marginBottom: "0.5rem",
    },
    confirmationWrapper: {
        margin: "auto",
        "& article": {
            margin: "auto",
        }
    },
    confirmationMain: {
        margin: "auto",
        maxWidth: "800px",
        "& header": {
            maxWidth: "620px",
            margin: "0.5rem auto 1rem",
        },
        "& $description": {
            margin: "1rem auto 1rem",
        },
    },
    confirmationInfos: {
        margin: "auto",
        "& div": {
            maxWidth: "200px",
            margin: "auto",
        },
    },

    // CONGRATULATIONS
    congratsContainer: {
        display: "flex",
        alignItems: "center",
    },
    congratsImage: {
        margin: "auto",
        maxWidth: "90%",
    },
    congratsHeader: {
        '& h3': {
            color: primaryColor,
        }
    },
    congratsInfo: {
        color: primaryColor,
        marginBottom: "2rem",
    },

    // MEDIA QUERIES & RESPONSIVITY
    "@media (min-width: 600px)": {
        mobileOnly: {
            display: "none"
        },
        desktopOnly: {
            display: "visible"
        },
        congratsImage: {
            margin: "auto",
            maxWidth: "450px",
        },
        priceChip: {
            maxWidth: "120px",
        },
    },
    "@media (max-width: 599px)": {
        mobileOnly: {
            display: "visible"
        },
        desktopOnly: {
            display: "none"
        },
        priceChip: {
            maxWidth: "70px",
        },
        chipContainer: {
            margin: "0"
        },
        chipsContainer: {
            marginLeft: "0",
            marginRight: "0",
        }
    },

    // IMAGE DRAG AND DROP
    imageSlider: {
        marginBottom: '30px',
        display: 'flex'
    },
    droppable: {
        display: 'flex',
        overflow: 'auto'
    },
    imageContainer: {
        border: '2px solid #e0e0e0',
        width: 'auto',
        height: '150px',
        display: 'flex',
        justifyContent: 'center',
        margin: '0px 10px',
        overflow: 'clip',
        '& img': {
            alignSelf: 'center',
            maxWidth: '150px',
            maxHeight: '150px',
            display: 'inline-block'
        },
    },
    addImageInput: {
        display: "none"
    },
    slickRemove : {
        color: primaryColor,
        cursor: "pointer",
    },

    // BUTTONS
    buttonAction: {
        width: '100%'
    },
    buttonWrapper: {
        maxWidth: "220px",
    },
    buttonsWrapper: {
        display: "flex",
        justifyContent: "center",
        width: "100%"
    },
});

export default CreateProductFormStyle;
