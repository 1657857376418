import {combineReducers} from "redux";

export function retrieveError(state = null, action) {
    switch (action.type) {
        case "DASHBOARD_DATA_RETRIEVE_ERROR":
            return action.retrieveError;

        case "DASHBOARD_DATA_RESET":
            return null;

        default:
            return state;
    }
}

export function retrieveOrdersDataLoading(state = false, action) {
    switch (action.type) {
        case "DASHBOARD_ORDERS_DATA_RETRIEVE_LOADING":
            return action.retrieveOrdersDataLoading;

        case "DASHBOARD_DATA_RESET":
            return false;

        default:
            return state;
    }
}

export function retrieveQuotesDataLoading(state = false, action) {
    switch (action.type) {
        case "DASHBOARD_QUOTES_DATA_RETRIEVE_LOADING":
            return action.retrieveQuotesDataLoading;

        case "DASHBOARD_DATA_RESET":
            return false;

        default:
            return state;
    }
}

export function retrieveAnalyticsDataLoading(state = false, action) {
    switch (action.type) {
        case "DASHBOARD_ANALYTICS_DATA_RETRIEVE_LOADING":
            return action.retrieveAnalyticsDataLoading;

        case "DASHBOARD_DATA_RESET":
            return false;

        default:
            return state;
    }
}

export function retrieveOpeDataLoading(state = false, action) {
    switch (action.type) {
        case "DASHBOARD_OPE_DATA_RETRIEVE_LOADING":
            return action.retrieveOpeDataLoading;

        default:
            return state;
    }
}


export function retrieveAnalyticsData(state = null, action) {
    switch (action.type) {
        case "DASHBOARD_ANALYTICS_DATA_RETRIEVE_SUCCESS":
            return action.retrieveAnalyticsData;

        case "DASHBOARD_DATA_RESET":
            return null;

        default:
            return state;
    }
}

export function retrievedOrdersData(state = null, action) {
    switch (action.type) {
        case "DASHBOARD_ORDERS_DATA_RETRIEVE_SUCCESS":
            return action.retrievedOrdersData;

        case "DASHBOARD_DATA_RESET":
            return null;

        default:
            return state;
    }
}

export function retrievedQuotesData(state = null, action) {
    switch (action.type) {
        case "DASHBOARD_QUOTES_DATA_RETRIEVE_SUCCESS":
            return action.retrievedQuotesData;

        case "DASHBOARD_DATA_RESET":
            return null;

        default:
            return state;
    }
}

export function retrievedOpeData(state = null, action) {
    switch (action.type) {
        case "DASHBOARD_OPE_DATA_RETRIEVE_SUCCESS":
            return action.retrievedOpeDataSuccess;

        case "DASHBOARD_DATA_RESET":
            return null;

        default:
            return state;
    }
}

export function retrieveRetailersRankingDataLoading(state = false, action) {
    switch (action.type) {
        case "DASHBOARD_RETAILER_RANKING_DATA_RETRIEVE_LOADING":
            return action.retrieveRetailersRankingDataLoading;

        case "DASHBOARD_DATA_RESET":
            return false;

        default:
            return state;
    }
}

export function retrievedRetailersRankingData(state = null, action) {
    switch (action.type) {
        case "DASHBOARD_RETAILERS_RANKING_DATA_RETRIEVE_SUCCESS":
            return action.retrievedRetailersRankingData;

        case "DASHBOARD_DATA_RESET":
            return null;

        default:
            return state;
    }
}

export function retrievedProductsRankingData(state = null, action) {
    switch (action.type) {
        case "DASHBOARD_PRODUCTS_RANKING_DATA_RETRIEVE_SUCCESS":
            return action.retrievedProductsRankingData;

        case "DASHBOARD_DATA_RESET":
            return null;

        default:
            return state;
    }
}

export function retrieveProductsRankingDataLoading(state = false, action) {
    switch (action.type) {
        case "DASHBOARD_PRODUCTS_RANKING_DATA_RETRIEVE_LOADING":
            return action.retrieveProductsRankingDataLoading;

        case "DASHBOARD_DATA_RESET":
            return false;

        default:
            return state;
    }
}

export default combineReducers({
    retrieveError,
    retrieveOrdersDataLoading,
    retrieveQuotesDataLoading,
    retrieveAnalyticsDataLoading,
    retrieveAnalyticsData,
    retrievedOrdersData,
    retrievedQuotesData,
    retrievedOpeData,
    retrieveOpeDataLoading,
    retrieveRetailersRankingDataLoading,
    retrievedRetailersRankingData,
    retrievedProductsRankingData,
    retrieveProductsRankingDataLoading,
});
