import React, {Component} from "react";
import {injectIntl} from "react-intl";

import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import {withStyles} from "@mui/styles";

import listStyle from "../../../assets/jss/views/accounting/listStyle";

import Row from "./row";
import Totals from "./totals";

class List extends Component {

    render () {
        const { rows, totals, intl, currentOrganization } = this.props;

        return (
            <TableContainer component={Paper}>
                <Table aria-label="collapsible table">
                    <TableHead>
                        <TableRow>
                            <TableCell/>
                            <TableCell align="left">{intl.formatMessage({id: "accounting.payout_list.column_header.payout_arrival_date"})}</TableCell>
                            <TableCell align="left">{intl.formatMessage({id: "accounting.payout_list.column_header.payout_date"})}</TableCell>
                            <TableCell align="right">{intl.formatMessage({id: "accounting.payout_list.column_header.payout_amount"})}</TableCell>
                            <TableCell align="right">{intl.formatMessage({id: "accounting.payout_list.column_header.status"})}</TableCell>
                            <TableCell align="right">{intl.formatMessage({id: "accounting.payout_list.column_header.payout_reference"})}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row, idx) => <Row key={idx} row={row} currentOrganization={currentOrganization} />)}
                        <Totals
                            amount={totals.amount}
                            commissionDebit={totals['commission']['debit']}
                            commissionCredit={totals['commission']['credit']}
                            currency={currentOrganization?.currency}
                        />
                    </TableBody>
                </Table>
            </TableContainer>
        );
    }
}

export default withStyles(listStyle)(injectIntl(List));
