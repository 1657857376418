import React from "react";
import { FormattedMessage } from "react-intl";

// @mui/material components
import { Dialog, DialogTitle, DialogContent } from "@mui/material";

// core components
import Form from './form';

function CornerDialog(props) {
    const { corner, closeDialog, isOpen, needReload } = props;
    const isEditing = !!corner;

    return (
        <Dialog
            open={isOpen}
            onClose={closeDialog}
            maxWidth={'sm'}
            scroll={'body'}
        >
            <DialogTitle>
                <FormattedMessage id={isEditing ? "corner.form.update.title" : "corner.form.create.title" } />
            </DialogTitle>
            <DialogContent>
                <Form
                    submitted={() => {
                        closeDialog();
                        needReload();
                    }}
                    corner={corner}
                    closeDialog={closeDialog}
                />
            </DialogContent>
        </Dialog>
    )
}

export default CornerDialog;
