import React, {Component} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import { injectIntl, FormattedMessage} from "react-intl";

// @mui/material components
import {withStyles} from "@mui/styles";

// @mui/icons-material
import {NotificationsActive} from "@mui/icons-material";

// core components
import Form from "./form";
import Snackbar from "components/snackbar/snackbar";

// styles
import updateStyle from "assets/jss/views/profile/updateStyle";

// actions
import {retrieve, update} from "actions/profile/update";

// helpers
import abort from "utils/abort";

class Update extends Component {
    static propTypes = {
        retrieved: PropTypes.object,
        loading: PropTypes.bool.isRequired,
        error: PropTypes.string,
        retrieve: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            errorNotification: false,
            updatedNotification: false
        };
    }

    componentDidMount() {
        this.props.retrieve(this.props.member.userId)
    }

    componentWillUnmount() {
        abort.abortRequests();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.updated !== nextProps.updated) {
            this.setState({ updatedNotification: true });
        }

        if (this.props.updateError !== nextProps.updateError) {
            this.setState({ errorNotification: true })
        }
    }

    render() {
        const {classes} = this.props;
        const item = this.props.updated || this.props.retrieved;

        return (
            <div className={classes.containerWithSidebar}>
                <div className={classes.container}>
                    {this.props.updated && (
                        <Snackbar
                            open={this.state.updatedNotification}
                            close
                            closeNotification={() => this.setState({updatedNotification: false})}
                            place={"bl"}
                            color={"success"}
                            icon={function () {
                                return <NotificationsActive/>
                            }}
                            message={<FormattedMessage id={"user.form.profile.updated"}/>}
                        />
                    )}
                    {this.props.updateError && (
                        <Snackbar
                            open={this.state.errorNotification}
                            close
                            closeNotification={() => this.setState({errorNotification: false})}
                            place={"bl"}
                            color={"danger"}
                            icon={function () {
                                return <NotificationsActive/>
                            }}
                            message={<FormattedMessage id={"user.form.profile.error"}/>}
                        />
                    )}
                    {item && (
                        <Form
                            selectedLocale={(locale) => this.setState({selectedLocale: locale})}
                            initialValues={item}
                            onSubmit={values => {
                                values.locale = this.state.selectedLocale;
                                this.props.update(item, values);
                            }}
                        />
                    )}
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    retrieved: state.profile.update.retrieved,
    loading: state.profile.update.loading,
    member: state.authentication.member,
    updated: state.profile.update.updated,
    updateError: state.profile.update.updateError
});

const mapDispatchToProps = dispatch => ({
    retrieve: id => dispatch(retrieve(id)),
    update: (item, values) => dispatch(update(item, values)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(updateStyle)(injectIntl(Update)));
