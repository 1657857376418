import React, {useContext, useState} from "react";
import PropTypes from 'prop-types'
import {useFormikContext} from "formik";

import {ClickAwayListener} from "@mui/material";

import FakeSelectInput from "components/radioGroup/FakeSelectInput";

import Breadcrumb from "./RecommendedProduct/components/Breadcrumb";
import CategorySelector from "./RecommendedProduct/components/CategorySelector";
import ErrorMessage from "./ErrorMessage";

import {makeStyles} from "@mui/styles";

import {RecommendationFormContext} from "../../../context/RecommendationFormContext";

import {getTranslation} from "domain/helpers/translations";

const useStyles = makeStyles(theme => ({
    container: {
        position: 'relative',
    },
}))

export default function CategoryOverload(props) {

    const {formFieldName} = props;

    const {getValueByFormFieldName} = useContext(RecommendationFormContext)
    const formikContext = useFormikContext()

    const classes = useStyles();

    const [isOpen, toggleOpen] = useState(false);

    const selectedNode = getValueByFormFieldName(formFieldName)

    return (
        <ClickAwayListener onClickAway={() => toggleOpen(false)}>
            <div>
                <FakeSelectInput
                    text={selectedNode instanceof Object && getTranslation(selectedNode).name}
                    onClick={() => toggleOpen(!isOpen)}
                    open={isOpen}
                />
                {isOpen && (
                    <div className={classes.container}>
                        <CategorySelector
                            onClickBack={() => toggleOpen(false)}
                            onSelectedCategory={(category) => {
                                formikContext.setFieldValue(formFieldName, category)
                                toggleOpen(false)
                            }}
                            formFieldName={formFieldName}
                        />
                    </div>
                )}
                {selectedNode instanceof Object && <Breadcrumb node={selectedNode} />}
                {formikContext.errors[formFieldName] && (
                    <ErrorMessage errorTranslationMessageId={formikContext.errors[formFieldName]} />
                )}
            </div>
        </ClickAwayListener>

    )
}

CategoryOverload.propTypes = {
    formFieldName: PropTypes.string.isRequired,
}
