import React from 'react';
import PropTypes from 'prop-types';
import {createTheme, ThemeProvider} from "@mui/material/styles";

import CustomDate from "../NoteBlock/CustomDate";
import TitleBlock from "../NoteBlock/TitleBlock";

import {styled} from "@mui/styles";

HistoryNoteHeader.propTypes = {
    date: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
};

function HistoryNoteHeader({date, subtitle, title}) {
    const historyNoteTheme = createTheme({
        palette: {
            primary: {
                main: '#000',
            },
        },
    });

    const HistoryNoteHeaderContainer = styled('div')(({theme}) => ({
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        padding: '20px 32px 12px 32px',
        [theme.breakpoints.down('md')]: {
            padding: '24px 16px 0px 16px'
        },
    }));

    return !!date && !!subtitle && !!title && (
        <HistoryNoteHeaderContainer>
            <div style={{ display: 'flex'}}>
                <ThemeProvider theme={historyNoteTheme}>
                    <TitleBlock subtitle={subtitle} title={title} />
                </ThemeProvider>
            </div>
            <CustomDate date={date} />
        </HistoryNoteHeaderContainer>
    );
}

export default HistoryNoteHeader;
