import {fetch, normalize} from "../../utils/dataAccess";
import normalizeTranslations from "../translations";

export function updateError(updateError) {
    return {type: "VARIATION_UPDATE_UPDATE_ERROR", updateError};
}

export function updateLoading(updateLoading) {
    return {type: "VARIATION_UPDATE_UPDATE_LOADING", updateLoading};
}

export function updateSuccess(updateSuccess) {
    return {type: "VARIATION_UPDATE_UPDATE_SUCCESS", updateSuccess};
}

export function update(item, values) {

    const data = {};
    const available_data = {
        "name"              : "string",
        "externalId"        : "string",
        "outletPrice"       : "float",
        "discountedPrice"   : "float",
        "price"             : "float",
        "ecoTax"            : "float",
        "stock"             : "int",
        "url"               : "string",
        "sku"               : "string",
        "color"             : "@id",
        "size"              : "@id",
        "status"            : "bool",
        "pictures"          : "pictures",
        "unavailableBefore" : "date",
        "weight"            : "float",
    };

    Object.keys(available_data).forEach(function(key,index) {
        if(undefined !== values[key]){
            switch (available_data[key]) {
                default: break;
                case "string":
                    data[key] = (values[key])? values[key] : null;
                    break;
                case "float":
                    if (values[key]) {
                        if (typeof values[key] === 'string') {
                            values[key] = values[key].replace(',', '.');
                        }
                        values[key] = parseFloat(values[key]);
                    } else {
                        values[key] = null;
                    }
                    data[key] = values[key];
                    break;
                case "int":
                    data[key] = (values[key])? parseInt(values[key]) : 0;
                    break;
                case "bool":
                    data[key] = (values[key] !== null)? values[key] : false;
                    break;
                case "array":
                    data[key] = values[key];
                    break;
                case "@id":
                    if(values[key] === null){
                        return null;
                    }
                    if(Array.isArray(values[key]) || typeof values[key] === "object"){
                        if(values[key]["@id"]) {
                            data[key] = values[key]["@id"];
                        }
                    }else{
                        data[key] = values[key];
                    }
                    break;
                case "pictures":
                    if(Array.isArray(values[key]) || typeof values[key] === "object"){
                        data[key] = values[key].map(function (value, index) {
                            return {"@id": value["@id"], "position": index};
                        });
                    }
                    break;
                case "date":
                    if (values[key]) {
                        if (values[key] instanceof Date) {
                            data[key] = values[key];
                        } else if (/[0-9]{4}-[0-9]{2}-[0-9]{2}/.test(values[key])) {
                            data[key] = new Date(values[key]);
                        } else {
                            data[key] = null;
                        }
                    } else {
                        data[key] = null;
                    }
                    break;
            }
        }
    });
    normalizeTranslations(values);
    data.translations = values.translations;

    return dispatch => {
        dispatch(updateLoading(true));
        return fetch(item["@id"], {
            method: "PUT",
            headers: new Headers({"Content-Type": "application/ld+json"}),
            body: JSON.stringify(data)
        }).then(response =>
            response.json()
        ).then((retrieved) => {
            dispatch(updateLoading(false));
            dispatch(updateSuccess(retrieved));
        })
            .catch(e => {
                dispatch(updateLoading(false));
                dispatch(updateError(e.message));
            });

    };
}

export function retrieve(id) {
    return dispatch => {
        dispatch(updateLoading(true));

        return fetch(id)
            .then(response =>
                response.json()
            )
            .then((retrieved) => {
                retrieved = normalize(retrieved);

                dispatch(updateLoading(false));
                dispatch(updateSuccess(retrieved));

            })
            .catch(e => {
                dispatch(updateLoading(false));
                dispatch(updateError(e.message));
            });
    };
}

export function reset() {
    return dispatch => {
        dispatch(updateError(false));
        dispatch(updateSuccess(null));
        dispatch(updateLoading(null));
    };
}
