import React from "react";
import {Route} from "react-router-dom";

import RetailerProfileList from "views/retailerProfiles"
import RetailerProfileEdit from "views/retailerProfiles/Edit";

import RequireAuthentication from "hoc/authentication/requireAuthentication";
import RetailerUnloader from "hoc/retailer/retailerUnloader";

const routes = [
    <Route
        path="/retailer_profiles"
        component={RequireAuthentication(RetailerUnloader(RetailerProfileList))}
        exact
        strict={true}
        key="retailer_profiles"
    />,
    <Route
        path="/retailer_profiles/:id"
        component={RequireAuthentication(RetailerUnloader(RetailerProfileEdit))}
        exact
        strict={true}
        key="retailer_profiles_id"
    />,
];

export default routes;
