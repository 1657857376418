import {daysMap} from "views/marketplace/retailer/openingDays";

export default function persist(values) {
    if (!!values.openingDays) {
        Object.keys(daysMap).forEach(day => {
            let hours = values.openingDays[day];
            if (Array.isArray(hours)) {
                hours = hours.filter(hour => Array.isArray(hour) && hour.length === 2);
                values.openingDays[day] = hours;
            }
        });
    }

    return {
        rent: parseFloat(values?.rent, 10),
        surface: parseFloat(values?.surface, 10),
        sellers: parseFloat(values?.sellers, 10),
        stockThreshold: parseFloat(values?.stockThreshold, 10),
        city: values.city !== '' ? values.city : null,
        contactName: values.contactName !== '' ? values.contactName : null,
        smsPhone: values.smsPhone !== '' ? values.smsPhone : null,
        paymentAccount: {
            tradeName: values.paymentAccount.tradeName !== '' ? values.paymentAccount.tradeName : null,
            siret: values.paymentAccount.siret !== '' ? values.paymentAccount.siret : null,
            vatNumber: values.paymentAccount.tradeName !== '' ? values.paymentAccount.tradeName : null,
            email: values.paymentAccount.email !== '' ? values.paymentAccount.email : null,
            ...values.paymentAccount
        },
        ...values
    };
}
