import React, {useContext} from "react";

import CustomerNote from "./components/NoteTypes/CustomerNote";
import {QuoteContext} from "../../../../../contexts/quoteContext";
import CommentNote from "./components/NoteTypes/CommentNote";
import InternalNote from "./components/NoteTypes/InternalNote";

export default function QuoteNotes() {
    const { quote } = useContext(QuoteContext);

    return (
        <>
            <CustomerNote
                content={quote.customerNote}
                createdAt={quote.createdAt}
                customerName={quote.organizationCustomer.firstName + ' ' + quote.organizationCustomer.lastName}
                email={quote.customerShippingEmail ?? quote.customerBillingEmail ?? null}
                externalNumber={quote.organizationCustomer?.externalNumber}
            />

            <CommentNote comments={quote.quoteComments} />

            <InternalNote
                internalNote={quote.quoteInternalNote}
                internalNotes={quote.quoteInternalNotes}
            />
        </>
    )
}
