import React from 'react';
import {FormattedMessage} from 'react-intl';

import {infoColor, sixteenGrey} from 'assets/jss/main';

export default function Title(props) {
    return (
        <div>
             <span style={{
                 color: infoColor,
                 fontSize: '16px',
                 fontWeight: 'bold'
             }}>
                    <FormattedMessage id={'picking.quote.detail.addCommentNote.title'} />
                </span>
            <br/>
            <span style={{
                color: sixteenGrey,
                fontWeight: 400
            }}>
               <FormattedMessage id={'picking.quote.detail.addCommentNote.subtitle'} />
            </span>
        </div>
    );
}
