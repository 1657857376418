import React, {useContext} from "react";
import {FormattedMessage} from "react-intl";

// @mui/material components
import {
    TextField
} from "@mui/material";

// context
import {CancellationReasonContext} from "contexts/CancellationReasonContext";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles(theme => ({
    input: {
        '& .MuiInputBase-root': {
            background: 'white',
            padding: '10px',
            borderRadius: '10px'
        },
        '& .MuiInput-underline::after': {
            borderBottom: 'none',
        },
        '& .MuiInput-underline::before': {
            borderBottom: 'none',
        },
        '& .MuiInput-underline:hover:not(.Mui-disabled)::before': {
            borderBottom: 'none',
        },
        '& .MuiInputBase-input' : {
            '-moz-appearance': 'textfield',
            '-webkit-outer-spin-button': 'none',
            '-webkit-inner-spin-button': 'none'
        }
    }
}));

export default function UnavailableDaysForm() {
    const classes = useStyles();
    const maxDay = 365;

    const { dayCount, setDayCount } = useContext(CancellationReasonContext);

    return <>
        <p>
            <FormattedMessage id={"picking.order.show.cancellationReason.dialog.unavailableDays.title"} />
        </p>
        <p>
            <b>
                <FormattedMessage id={"picking.order.show.cancellationReason.dialog.unavailableDays.input.label"} />
            </b>
        </p>
        <TextField
            variant="standard"
            id={"input-edit-unavailable"}
            autoFocus
            margin="dense"
            type="number"
            inputProps={{
                min: 0,
                max: maxDay,
                type: 'number',
                onChange: (e) => setDayCount(
                    Math.floor(e.target.value) > maxDay ? maxDay : Math.floor(e.target.value)
                )
            }}
            fullWidth
            defaultValue={dayCount}
            className={classes.input} />
    </>;
}
