import React from 'react';
import PropTypes from "prop-types";

import {styled} from "@mui/styles";

import CenterContentContainer from "./CenterContentContainer";
import CustomDate from "./CustomDate";
import TitleBlock from "../NoteBlock/TitleBlock";
import Icon from "../Icon";

import {QUOTE_NOTE_TYPES} from "../../../../../enums/quoteNoteType";

Header.propTypes = {
    centerContent: PropTypes.any,
    date: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    title: PropTypes.string.isRequired,
    noteType: PropTypes.oneOf(QUOTE_NOTE_TYPES).isRequired
};

export default function Header({centerContent, date = '', subtitle = '', title = '', noteType}) {
    const TitleBlockContainer = styled('div')(({theme}) => ({
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'center',
        padding: '20px 32px 20px 12px',
        [theme.breakpoints.down('md')]: {
            padding: '16px 12px'
        },
    }));

    return !!title && !!date && (
        <div style={{ display: 'flex'}}>
            <Icon type={noteType} />
            <TitleBlockContainer>
                <TitleBlock
                    subtitle={subtitle}
                    title={title}
                />
                <CenterContentContainer>
                    {centerContent}
                </CenterContentContainer>
                <CustomDate date={date} />
            </TitleBlockContainer>
        </div>
    );
}
