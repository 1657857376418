import React from 'react';
import PropTypes from 'prop-types';
import {CircularProgress} from "@mui/material";

Loading.propTypes = {
    loading: PropTypes.bool
};

function Loading({loading}) {
    if (!loading) {
        return null;
    }

    return (
        <div style={{
            position: 'absolute',
            top: '12px',
            right: '24px'
        }}>
            <CircularProgress size={24} />
        </div>
    );
}

export default Loading;
