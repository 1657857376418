import React from "react"
import PropTypes from "prop-types";

import ShipmentTable from "./components/ShipmentTable";

export default function ShipmentList({shipments}) {
    return Object.keys(shipments).map((shipmentId, index) => (
        <ShipmentTable
            shipment={shipments[shipmentId]}
            key={index}
            incrementalNumber={index + 1}
        />
    ))
}

ShipmentList.defaultProps = {
    shipments: {}
};

ShipmentList.propTypes = {
    shipments: PropTypes.object
};

