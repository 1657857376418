const cardFooterStyle = {
    cardFooter: {
        display: "flex",
        alignItems: "center",
        backgroundColor: "transparent",
        padding: "0.9375rem 1.875rem"
    },
    center: {
        justifyContent: "center"
    }
};

export default cardFooterStyle;
