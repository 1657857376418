import React, {useContext} from "react";
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import NavTree from "views/marketplace/merchandising/nav/navTree";
import {ProductDataContext} from "contexts/productDataContext";

export default function CornersNav(props) {

    const { cornersLoaded, cornersFilters } = useContext(ProductDataContext);
    const topNodeLabel = "merchandising.show.label.nav.corners";
    const topNodeIcon = ShoppingBagOutlinedIcon;

    return (
        <>
            {cornersLoaded && <NavTree 
                items={cornersFilters.tabContent.items}
                topItems={cornersFilters.tabContent.items}
                topNodeLabel={topNodeLabel}
                topNodeIcon={topNodeIcon}
                nodeId={props.nodeId}
                type={'Corner'}
                {...props}
            />}
        </>
    );
}
