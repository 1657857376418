import React, { Component } from "react";
import CreatableSelect from 'react-select/creatable';

// style
import { withStyles } from "@mui/styles";
import SelectStyle from 'assets/jss/components/select/selectStyle';

class CustomCreatableSelect extends Component {
    getLabelClass() {
        const {classes, error} = this.props;
        if (error) {
            return classes.errorLabel;
        } else {
            return classes.label;
        }
    }

    getSelectClass() {
        const {classes, error} = this.props;
        if (error) {
            return classes.errorSelect + ' ' + classes.select;
        }

        return classes.select;
    }

    render() {
        const {id, label, onChange, options, defaultValue, formatCreateLabel, value, isMulti, maxMenuHeight, noOptionsMessage, placeholder, disallowCreateNewOption} = this.props;

        let customProps = {};
        if (!!isMulti) {
            customProps['isMulti'] = 'isMulti';
        }

        if (!!maxMenuHeight) {
            customProps['maxMenuHeight'] = maxMenuHeight;
        }

        customProps['classNamePrefix'] = 'app';

        return (
            <div>
                <label htmlFor={id}
                    className={this.getLabelClass()}
                >
                    {label}
                </label>

                <CreatableSelect
                    id={id}
                    required
                    onChange={onChange}
                    options={options}
                    defaultValue={defaultValue}
                    value={value}
                    formatCreateLabel={formatCreateLabel}
                    className={this.getSelectClass()}
                    noOptionsMessage={() => noOptionsMessage}
                    placeholder={placeholder}
                    isValidNewOption={() => !disallowCreateNewOption}
                    {...customProps}
                />
            </div>
        )
    }
}

export default (withStyles(SelectStyle)(CustomCreatableSelect));
