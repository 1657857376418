import React from 'react';
import PropTypes from 'prop-types';

import {styled} from "@mui/styles";

BoldTitle.propTypes = {
    children: PropTypes.any.isRequired
};

export default function BoldTitle(props) {
    const StyledTitle = styled('span')(({ theme }) => ({
        fontWeight: 'bold',
        color: theme.palette.primary.main,
    }));

    return !!props.children && (
        <StyledTitle>
            {props.children}
        </StyledTitle>
    );
}
