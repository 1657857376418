import React from 'react';
import PropTypes from 'prop-types';
import {FormattedDate, FormattedTime} from "react-intl";

Date.propTypes = {
    date: PropTypes.string.isRequired
};

export default function CustomDate({date = ''}) {
    return !!date && (
        <div style={{
            textAlign: 'right',
            display: 'flex',
            flexDirection: 'column',
            fontWeight: 400,
        }}>
             <b>
                 <FormattedDate
                     value={new Date(date)}
                     year={"numeric"}
                     month={"numeric"}
                     day={"numeric"}
                />
             </b>
            <span style={{ marginTop: '8px' }}>
                <FormattedTime
                    value={new Date(date)}
                    hour={"numeric"}
                    minute={"numeric"}
                />
            </span>
        </div>
    );
}
