import {combineReducers} from "redux";

export function error(state = null, action) {
    switch (action.type) {
        case "PROFILE_SHOW_ERROR":
            return action.error;

        case "PROFILE_SHOW_RESET":
            return null;

        default:
            return state;
    }
}

export function loading(state = false, action) {
    switch (action.type) {
        case "PROFILE_SHOW_LOADING":
            return action.loading;

        case "PROFILE_SHOW_RESET":
            return false;

        default:
            return state;
    }
}

export function retrieved(state = null, action) {
    switch (action.type) {
        case "PROFILE_SHOW_SUCCESS":
            return action.retrieved;

        case "PROFILE_SHOW_RESET":
            return null;

        default:
            return state;
    }
}

export function updateError(state = null, action) {
    switch (action.type) {
        case "PROFILE_UPDATE_UPDATE_ERROR":
            return action.updateError;

        case "PROFILE_UPDATE_RESET":
            return null;

        default:
            return state;
    }
}

export function updateLoading(state = false, action) {
    switch (action.type) {
        case "PROFILE_UPDATE_UPDATE_LOADING":
            return action.updateLoading;

        case "PROFILE_UPDATE_RESET":
            return false;

        default:
            return state;
    }
}

export function updated(state = null, action) {
    switch (action.type) {
        case "PROFILE_UPDATE_UPDATE_SUCCESS":
            return action.updated;

        case "PROFILE_UPDATE_RESET":
            return null;

        default:
            return state;
    }
}

export default combineReducers({
    error,
    loading,
    retrieved,
    updateError,
    updateLoading,
    updated,
});