import {fetch} from "../../utils/dataAccess";

export function error(error) {
    return {type: "REPORT_GET_ERROR", error};
}

export function loading(loading) {
    return {type: "REPORT_GET_LOADING", loading};
}

export function success(retrieved) {
    return {type: "REPORT_GET_SUCCESS", retrieved};
}


export function get(importReportId) {
    return dispatch => {
        dispatch(loading(true));
        dispatch(error(""));

        fetch("/import_reports/" + importReportId)
            .then(response => response.json())
            .then((report) => {
                dispatch(loading(false));
                dispatch(success(report));
            })
            .catch(e => {
                dispatch(loading(false));
                dispatch(error(e.message));
            });
    };
}

export function reset() {
    return dispatch => {
        dispatch({type: "REPORT_GET_RESET"});
    };
}
