import {
    Checkbox,
    Chip,
    chipClasses,
    TableCell,
    TableRow,
    Tooltip,
    Hidden
} from "@mui/material";
import {Authorizations, hasAuthorization} from "../../../utils/authorizations";
import {getFirstProductImage} from "../../../utils/getFirstProductImage";
import {ImageSharp} from "@mui/icons-material";
import AvTimerIcon from '@mui/icons-material/AvTimer';
import CustomDate from "../../../components/date/date";
import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import listStyle from "../../../assets/jss/views/product/listStyle";
import {FormattedDate, FormattedMessage, injectIntl} from "react-intl";
import {sortOn} from "../../../utils/sortOn";
import FormattedNumberWithCurrency from "../../../components/formattedNumberWithCurrencies/formattedNumberWithCurrency";
import {marketPlaceOffer} from "../../enum/offers";
import {handleSelectProduct} from "../../../actions/product/selection";
import {UserAgentUtils} from "../../../utils/useragent";
import {capitalize} from "../../../utils/capitalize";
import {getTranslation} from "../../helpers/translations";
import {withStyles} from "@mui/styles";

class ProductRow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isSelected: false,
        }
    }

    renderName = (name, id, sku, eshop) => {
        const {classes} = this.props;

        return (
            <div>
                <div>
                    <span className={classes.name}>{name}</span>
                </div>
                {this.props.offer === marketPlaceOffer && (
                    <Hidden smDown>
                        <div className={classes.nameInfos}>
                            <div>
                                <span>SKU: {sku}</span>
                            </div>

                            {eshop &&
                            <div>
                                <span>eShop ID: {eshop}</span>
                            </div>
                            }
                        </div>
                    </Hidden>
                )}
            </div>
        );
    };

    renderCategories = (categories) => {
        const {classes} = this.props;

        return (
            <div>
                {categories.sort(sortOn("name")).map(category => {
                    return <Chip key={category.id} label={getTranslation(category).name} className={category.draft ? classes.categoryDraft : classes.category} />
                })}
            </div>
        )
    };

    renderStock = (offer, status, sizeStock) => {
        const {classes} = this.props;
        let stockType;
        let sizeChip = [];
        let statusChip;

        if (offer === marketPlaceOffer) {
            statusChip =
                <Chip
                    label={<FormattedMessage
                        id={status ? "retailer.product.list.label.active" : "retailer.product.list.label.inactive"}/>}
                    className={status ? classes.status : classes.statusOff}
                    variant="outlined"
                    size="small"
                />;
        }

        if (Object.keys(sizeStock).length > 0) {
            for (let size in sizeStock) {
                if (sizeStock[size].stock === 0) {
                    stockType = classes.noStock
                } else if (sizeStock[size].stock > 0 && sizeStock[size].stock <= 5) {
                    stockType = classes.middleStock
                } else {
                    stockType = classes.inStock
                }

                let unavailableBeforeTooltip = null;

                if (sizeStock[size].unavailableBefore) {
                    const tooltipTitle = (
                        <>
                            <FormattedMessage id={'product.show.attribute.unavailableBefore'}/>
                            &nbsp;
                            <FormattedDate
                                value={new Date(sizeStock[size].unavailableBefore)}
                                year={"numeric"}
                                month={"numeric"}
                                day={"numeric"}
                            />
                        </>
                    )

                    unavailableBeforeTooltip = (
                        <Tooltip title={tooltipTitle}>
                            <AvTimerIcon />
                        </Tooltip>
                    )
                }

                sizeChip.push(
                    <Chip
                        key={sizeStock[size].name}
                        label={sizeStock[size].name.toUpperCase() + " (" + sizeStock[size].stock + ")"}
                        className={stockType}
                        size="small"
                        icon={unavailableBeforeTooltip}
                        sx={{[`& .${chipClasses.icon}`]: {
                            color: "#ffffff"
                        }}}
                    />);
            }
        }

        return (
            <div className={classes.stockStatus}>
                {offer === marketPlaceOffer &&
                    <div>
                        {statusChip}
                    </div>
                }
                <div className={classes.stock}>
                    {sizeChip}
                </div>
            </div>
        );
    };

    renderPrice = (highestPrice, lowestPrice) => {
        const {classes} = this.props;

        return (
            <div>
                <div className={classes.price}>
                    <FormattedNumberWithCurrency value={lowestPrice} currency={this.props.currentOrganization?.currency} />
                </div>
                <div className={classes.xsPrice}>
                    <FormattedNumberWithCurrency value={highestPrice} currency={this.props.currentOrganization?.currency} />
                </div>
            </div>
        );
    };

    displayErrorOnPushWarning(product) {
        const { classes, intl } = this.props;

        if (product.errorOnPush && product.lastErrorDate > product.updatedAt) {
            return (
                <Tooltip title={intl.formatMessage({id: "retailer.product.list.label.errorOnPush"})}>
                    <span className={classes.errorOnPush}></span>
                </Tooltip>
            )
        }
    }

    displayValidation(offer, product, classes) {
        if (offer === marketPlaceOffer) {
            return (
                <Hidden smDown>
                    <TableCell>
                        {product.validated ?
                            <span className={classes.validated}>ok</span> :
                            <Tooltip title={product.validationErrors}>
                                <span className={classes.notValidated}>ko</span>
                            </Tooltip>
                        }
                        {this.displayErrorOnPushWarning(product)}
                    </TableCell>
                </Hidden>
            );
        }
    }

    handleSelect = (item, selected) => {
        this.props.handleSelectProduct(item, selected);
    }


    handleEditProduct = (product, currentPage, currentOrderBy) => {
        if (window.getSelection().toString().length > 0) {
            return;
        }

        (hasAuthorization(this.props.authorizations, Authorizations.MARKETPLACE_PRODUCT_MANAGEMENT) || hasAuthorization(this.props.authorizations, Authorizations.STOCK_EASY_ACCESS)) &&
        this.props.history.push({
            pathname:`${this.props.baseParamsUrl + this.props.match.params.id}/products/show/${product.id}`,
            currentPage:currentPage,
            currentOrderBy:currentOrderBy,
            type:"parent",
        })
    }

    render() {
        const product = this.props.product;
        const classes = this.props.classes;
        const currentPage = this.props.currentPage;
        const currentOrderBy = this.props.currentOrderBy;
        const isSelected = this.state.isSelected;
        const selected = this.props.selected;
        let hasHover = false;

        if (hasAuthorization(this.props.authorizations, Authorizations.MARKETPLACE_PRODUCT_MANAGEMENT) || hasAuthorization(this.props.authorizations, Authorizations.STOCK_EASY_ACCESS)) {
            hasHover = true;
        }

        return (
            <TableRow
                onClick={() => this.handleEditProduct(product, currentPage, currentOrderBy)}
                hover={hasHover}
                tabIndex={-1}
                key={product.id}
                aria-checked={isSelected}
                selected={isSelected}
                classes={{hover: classes.tableRowHover}}
            >
                {!UserAgentUtils.isMobile() && <TableCell>
                    <Checkbox
                        checked={this.props.isChecked}
                        classes={{
                            root: classes.checkbox,
                            checked: classes.checked
                        }}
                        color={"primary"}
                        onClick={(e) => {
                            e.stopPropagation();
                            this.handleSelect(product, selected)
                        }}
                    />
                </TableCell>}
                <TableCell className={classes.imageCell + UserAgentUtils.isMobile() ? ' ' + classes.tableCell : ''} >
                    {product.pictures.length > 0 ?
                        <img
                            alt="product_image"
                            src={getFirstProductImage(product.pictures)}
                            className={classes.image}
                        /> :
                        <ImageSharp color={"disabled"} className={classes.imagePlaceholder}/>
                    }
                </TableCell>
                <Hidden smDown>
                    <TableCell className={classes.brandCell}>
                        {capitalize(product.brand.name.toLowerCase())}
                    </TableCell>
                </Hidden>
                <TableCell className={UserAgentUtils.isMobile() ? classes.tableCell : ''}>
                    {this.renderName(getTranslation(product).name, product.externalId, product.sku, product.eshopId)}
                </TableCell>
                <Hidden smDown>
                    <TableCell className={classes.categoryContainer}>
                        {this.renderCategories(product.categories)}
                    </TableCell>
                </Hidden>
                <Hidden smDown>
                    <TableCell>
                        <CustomDate showRelativeDate={true} date={product.updatedAt} />
                    </TableCell>
                </Hidden>
                <Hidden smDown>
                    <TableCell>
                        {this.renderPrice(product.highestPrice, product.lowestPrice, product.currency.code)}
                    </TableCell>
                </Hidden>
                <Hidden smDown>
                    <TableCell>
                        {this.renderStock(this.props.offer, product.status, product.sizeStock)}
                    </TableCell>
                </Hidden>
                    {this.displayValidation(this.props.offer, product, classes)}
            </TableRow>
        );
    }
}

ProductRow.propTypes = {
    classes: PropTypes.object.isRequired,
    selected: PropTypes.array,
};

const mapStateToProps = state => {
    return {
        authorizations: state.authentication.authorizations,
        retailer: state.retailer.show.retrieved,
        selected: state.product.selection.selected,
        isSelectAll: state.product.selection.isSelectAll,
        currentOrganization: state.currentOrganization.retrieved ?? null,
    };
};

const mapDispatchToProps = dispatch => ({
    handleSelectProduct: (product, selected) => dispatch(handleSelectProduct(product, selected)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(listStyle)(injectIntl(ProductRow)));
