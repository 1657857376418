import React, { useContext, useEffect, useLayoutEffect } from "react";
import connect from "react-redux/es/connect/connect";
import {FormattedMessage} from "react-intl";

// @mui/material components
import {withStyles} from "@mui/styles";

// core components
import DashboardDateRangePicker from "components/dashboardDateRangePicker";
import GridContainer from "components/grid/gridContainer";
import GridItem from "components/grid/gridItem";

import Analytics from "./components/analytics/analytics";
import AverageCart from "./components/averageCart";
import DetailsOnlineOrders from "./components/detailsOnlineOrders";
import GreetMessage from "./components/greetMessage";
import OrderCount from "./components/orderCount";
import RetailersRanking from "./components/retailersRanking/retailersRanking";
import SectorGraph from "./components/graphs/sectorGraph";
import SalesByValueGraph from "./components/graphs/salesByValueGraph";
import SalesByVolumeGraph from "./components/graphs/salesByVolumeGraph";
import Turnover from "./components/turnover";

// styles
import dashboardStyle from "assets/jss/views/dashboard/dashboardStyle.js";

// actions
import {retrieveOrdersData, reset} from "actions/dashboard";

// helpers
import abort from "utils/abort";
import {Authorizations, hasAuthorization} from "utils/authorizations";
import AmountCanceled from "./components/amountCanceled";
import ProductsRanking from "../retailer/dashboard/productsRanking";

// context
import {DashboardDateRangePickerContext} from "contexts/dasboardDateRangePickerContext";

function Dashboard(props) {
    const {
        applications,
        authorizations,
        classes,
        currentOrganization,
        dashboardOrdersData,
        dashboardOrdersDataLoading,
        dashboardAnalyticsList,
        dashboardAnalyticsData,
        dashboardAnalyticsDataLoading,
        member
    } = props;

    const {
        startDate,
        endDate,
        unit,
        dateChangedTrigger,
        unitParser,
        unitTooltip
    } = useContext(DashboardDateRangePickerContext)

    useEffect(() => {
        props.retrieveOrdersData(
            startDate.format("Y-M-D"),
            endDate.format("Y-M-D"),
            unit
        );
    }, [dateChangedTrigger]);

    // componentWillUnmount
    useLayoutEffect(() => {
        return () => {
            abort.abortRequests();
            props.reset();
        }
    }, [])

    const isRetailer =
        hasAuthorization(authorizations, Authorizations.PICKING_RETAILER)
        && member.retailers.length > 0
    ;

    let retailers = null;
    if (isRetailer) {
        retailers = member.retailers.map((retailer) => retailer['@id'].replace("/retailers/", ""));
    }

    return (
        <div className={classes.containerWithSidebar}>
            <div className={classes.container}>
                <div className={classes.dashboardHeader}>
                    <GreetMessage name={member["givenName"]} />
                    <div>
                        <DashboardDateRangePicker />
                    </div>
                </div>
                <GridContainer>
                    <GridItem xs={12} md={9} lg={10}>
                        {/* Remove condition to enable analytics dashboard */}
                        {(dashboardAnalyticsDataLoading || dashboardAnalyticsData) && (
                            <Analytics
                                startDate={startDate}
                                endDate={endDate}
                                unit={unit}
                            />
                        )}
                        <GridContainer>
                            <GridItem xs={12} sm={6} className={classes.cardContainer}>
                                <SalesByValueGraph
                                    isRetailer={isRetailer}
                                    onlineTurnoverGraph={dashboardOrdersData?.["online"]["turnoverGraph"]}
                                    physicalTurnoverGraph={dashboardOrdersData?.["physical"]["turnoverGraph"]}
                                    loading={dashboardOrdersDataLoading}
                                    unit={unit}
                                    unitParser={unitParser}
                                    unitTooltip={unitTooltip}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={6} className={classes.cardContainer}>
                                <SalesByVolumeGraph
                                    isRetailer={isRetailer}
                                    onlineCountGraph={dashboardOrdersData?.["online"]["countGraph"]}
                                    physicalCountGraph={dashboardOrdersData?.["physical"]["countGraph"]}
                                    loading={dashboardOrdersDataLoading}
                                    unit={unit}
                                    unitParser={unitParser}
                                    unitTooltip={unitTooltip}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={isRetailer ? 3 : 4} className={classes.cardContainer}>
                                <AverageCart
                                    isRetailer={isRetailer}
                                    currency={currentOrganization?.currency}
                                    loading={dashboardOrdersDataLoading}
                                    onlineOrdersCount={dashboardOrdersData?.["online"]["countTotal"]}
                                    onlineOrdersTurnover={dashboardOrdersData?.["online"]["turnoverTotal"]}
                                    physicalOrdersCount={dashboardOrdersData?.["physical"]["countTotal"]}
                                    physicalOrdersTurnover={dashboardOrdersData?.["physical"]["turnoverTotal"]}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={isRetailer ? 3 : 4} className={classes.cardContainer}>
                                <OrderCount
                                    isRetailer={isRetailer}
                                    loading={dashboardOrdersDataLoading}
                                    onlineOrdersCount={dashboardOrdersData?.["online"]["countTotal"]}
                                    physicalOrdersCount={dashboardOrdersData?.["physical"]["countTotal"]}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={isRetailer ? 3 : 4} className={classes.cardContainer}>
                                <Turnover
                                    isRetailer={isRetailer}
                                    currency={currentOrganization?.currency}
                                    loading={dashboardOrdersDataLoading}
                                    onlineOrdersTurnover={dashboardOrdersData?.["online"]["turnoverTotal"]}
                                    physicalOrdersTurnover={dashboardOrdersData?.["physical"]["turnoverTotal"]}
                                />
                            </GridItem>
                            {isRetailer && (
                                <GridItem xs={12} sm={12} md={3} className={classes.cardContainer}>
                                    <AmountCanceled
                                        amount={dashboardOrdersData?.["online"]["amountCanceledItems"]}
                                        loading={dashboardOrdersDataLoading}
                                    />
                                </GridItem>
                            )}
                            {isRetailer && (
                                <>
                                    <GridItem xs={12} className={classes.rankingTableTitle}>
                                        <FormattedMessage id={"dashboard.productRank.topCard.title"} values={{limit: 20}}/>
                                    </GridItem>
                                    <GridItem xs={12}>
                                        <ProductsRanking
                                            analyticsData={dashboardAnalyticsList.retrieved}
                                            startDate={startDate}
                                            endDate={endDate}
                                            retailers={retailers}
                                            maxResult={20}
                                        />
                                    </GridItem>
                                </>
                            )}
                            {!isRetailer && (
                                <GridItem xs={12}>
                                    <SectorGraph
                                        loading={dashboardOrdersDataLoading}
                                        sectors={dashboardOrdersData?.sector}
                                    />
                                </GridItem>
                            )}
                            {!isRetailer && (
                                <>
                                    <GridItem xs={12} className={classes.rankingTableTitle}>
                                        <FormattedMessage id={"dashboard.retailersRanking.title"} />
                                    </GridItem>
                                    <GridItem xs={12}>
                                        <RetailersRanking
                                            analyticsData={dashboardAnalyticsList.retrieved}
                                            startDate={startDate}
                                            endDate={endDate}
                                        />
                                    </GridItem>
                                </>
                            )}
                        </GridContainer>
                    </GridItem>
                    <GridItem xs={12} md={3} lg={2}>
                        <DetailsOnlineOrders
                            pending={dashboardOrdersData?.["statusCount"]["pending"]}
                            reserved={dashboardOrdersData?.["statusCount"]["reserved"]}
                            toShip={dashboardOrdersData?.["statusCount"]["to-ship"]}
                            confirmed={dashboardOrdersData?.["statusCount"]["confirmed"]}
                            canceled={dashboardOrdersData?.["statusCount"]["canceled"]}
                            returning={dashboardOrdersData?.["statusCount"]["returning"]}
                            canClick={applications["picking"]}
                            loading={dashboardOrdersDataLoading}
                        />
                    </GridItem>
                </GridContainer>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        member: state.authentication.member,
        authorizations: state.authentication.authorizations,
        applications: state.authentication.applications,
        dashboardOrdersData: state.dashboard.retrievedOrdersData,
        dashboardOrdersDataLoading: state.dashboard.retrieveOrdersDataLoading,
        dashboardAnalyticsData: state.analytics.dashboardAnalyticsData,
        dashboardAnalyticsDataLoading: state.analytics.dashboardAnalyticsDataLoading,
        dashboardAnalyticsList: state.analytics.list,
        currentOrganization: state.currentOrganization.retrieved ?? null,
    };
};

const mapDispatchToProps = dispatch => ({
    retrieveOrdersData: (startDate, endDate, unit) => dispatch(retrieveOrdersData(startDate, endDate, unit)),
    reset: () => dispatch(reset()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(dashboardStyle)(Dashboard));
