import {CKEditor} from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import React from "react";
import {getIn} from "formik";

export const CKEditorConfig = {
    language: 'fr',
    toolbar: ['heading', '|', 'bold', 'italic', 'link', 'numberedList', 'bulletedList', '|', 'undo', 'redo',],
    heading: {
        options: [
            { model: 'paragraph', title: 'Paragraphe', class: 'ck-heading_paragraph' },
            { model: 'heading2', view: 'h2', title: 'Titre 2', class: 'ck-heading_heading2' },
            { model: 'heading3', view: 'h3', title: 'Titre 3', class: 'ck-heading_heading3' },
            { model: 'heading4', view: 'h4', title: 'Titre 4', class: 'ck-heading_heading4' },
            { model: 'heading5', view: 'h5', title: 'Titre 5', class: 'ck-heading_heading5' },
        ]
    }
};

export const CKEditorConfigSimple = {
    language: 'fr',
    toolbar: [ 'bold', 'italic', '|', 'undo', 'redo' ],
};

export const CKEditorConfigSimpleWithTitles = {
    language: 'fr',
    toolbar: ['heading', '|', 'bold', 'italic', '|', 'undo', 'redo'],
    heading: {
        options: [
            { model: 'heading2', view: 'h2', title: 'Titre 2', class: 'ck-heading_heading2' },
            { model: 'heading3', view: 'h3', title: 'Titre 3', class: 'ck-heading_heading3' },
            { model: 'heading4', view: 'h4', title: 'Titre 4', class: 'ck-heading_heading4' },
            { model: 'heading5', view: 'h5', title: 'Titre 5', class: 'ck-heading_heading5' },
            { model: 'heading6', view: 'h6', title: 'Titre 6', class: 'ck-heading_heading6' },
        ]
    }
};

class WysiwygInputRenderer extends React.Component {
    static supports(props) {
        return ['wysiwyg'].includes(props.as ?? props.type);
    }

    render() {
        const { formikContext, name } = this.props;
        const value = getIn(formikContext.values, name);

        return (
            <CKEditor
                editor={ClassicEditor}
                data={value}
                config={CKEditorConfig}
                onChange={(event, editor) => {
                    const data = editor.getData();
                    formikContext.setFieldValue(name, data);
                }}
            />
        );
    }
}

export default WysiwygInputRenderer;
