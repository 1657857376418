import {
    // Add your imported colors below
    primaryColor,
} from "../../main";

const miniCardExpansionStyle = theme => ({
    container: {
        border: "0px",
        borderRadius: "5px",
        marginTop: "0px",
        overflow: "hidden",
    },
    cardHeader: {
        border: "none",
        padding: "12px",
        backgroundColor: "#fff",
        fontSize: "14px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    cardHeaderTitle: {
        fontSize: "16px",
        fontWeight: "400",
    },
    accordion: {
        borderTop: "1px solid",
        borderColor: "#ddd",
        boxShadow: "none",
        margin: 0,
        padding: 0,
    },
    variationItemDisabled: {
        backgroundColor: "#ccc",
    },
    accordionSummary: {
        padding: "0 12px",
    },
    accordionIcon: {
        '& span' : {
            margin: "0!important"
        }
    },
    variationGroupHeader: {
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
        paddingRight: "0!important",
        '& img': {
            width: "30px",
            height: "30px",
            border: "1px solid #EEE"
        },
        '& span': {
            verticalAlign: "top",
            marginLeft: "10px",
            lineHeight: "12px",
            color: primaryColor,
            fontSize: "14px",
            fontWeight: "400",
            textTransform: "capitalize"
        }
    },
    accordionDetails: {
        display: "block",
        padding: 0,
    },
    accordionDetailsItem: {
        borderTop: "1px solid",
        borderColor: "#ddd",
        padding: "8px 12px",
    },
    accordionDetailsItemSelected: {
        border: "0px solid",
        borderRadius: "0px",
        backgroundColor: primaryColor,
        color: "#FFF",
    },
    addVariationButton: {
        width: '36px',
        height: '36px'
    }
});

export default miniCardExpansionStyle;
