import {fetch} from "utils/dataAccess";

export function loading(loading) {
    return {type: "MEMBER_INVITE_LOADING", loading};
}

export function success(member) {
    return {type: "MEMBER_INVITE_SUCCESS", member};
}

export function error(error) {
    return {type: "MEMBER_INVITE_ERROR", error};
}

export function send(values) {
    return dispatch => {
        dispatch(loading(true));

        return fetch("/users/invite", {method: "POST", body: JSON.stringify(values)})
            .then(response => {
                return response.json();
            })
            .then(function (data) {
                dispatch(loading(false));
                dispatch(success(data));
            })
            .catch(e => {
                dispatch(loading(false));
                dispatch(error(e.message));
            });
    };
}

export function reSend(member) {
    return dispatch => {
        dispatch(loading(true));

        return fetch("/users/invite/resend", {method: "POST", body: JSON.stringify(member)})
            .then(response => {
                return response.json();
            })
            .then(function (data) {
                dispatch(loading(false));
                dispatch(success(data));
            })
            .catch(e => {
                dispatch(loading(false));
                dispatch(error(e.message));
            });
    };
}

export function reset() {
    return dispatch => {
        dispatch({type: "INVITE_MEMBER_RESET"});
    };
}