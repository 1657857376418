import React from "react";
import {Authorizations, hasAuthorization} from "../../utils/authorizations";
import * as Cookies from "js-cookie";
import {Route} from "react-router-dom";
import {Merchandising} from "../../views/marketplace/merchandising/index";
import RequireAuthentication from "../../hoc/authentication/requireAuthentication";

let routes = [];
const applications = Cookies.get("_a") ? JSON.parse(Cookies.get("_a").toString()) : null;
const authorizations = Cookies.get("_b") ? JSON.parse(Cookies.get("_b").toString()) : [];

if (applications && applications['marketplace']) {
    if (hasAuthorization(authorizations, Authorizations.MARKETPLACE_PRODUCT_VIEW) || hasAuthorization(authorizations, Authorizations.MARKETPLACE_PRODUCT_MANAGEMENT)) {
        routes.push([
            <Route
                component={RequireAuthentication(Merchandising)}
                path="/merchandising/products"
                key="merchandisingProducts"
            />
        ]);
    }
}

export default routes;
