import {
    fetch
} from "../../utils/dataAccess";

export function error(error) {
    return {type: "REPORT_LIST_ERROR", error};
}

export function loading(loading) {
    return {type: "REPORT_LIST_LOADING", loading};
}

export function success(retrieved) {
    return {type: "REPORT_LIST_SUCCESS", retrieved};
}

export function list(feed) {
    return dispatch => {
        dispatch(loading(true));
        dispatch(error(""));

        fetch("/import_reports?feed.id=" + feed)
            .then(response =>
                response.json()
            )
            .then((retrieved) => {
                const reports = retrieved;

                dispatch(loading(false));
                dispatch(success(reports));
            })
            .catch(e => {
                dispatch(loading(false));
                dispatch(error(e.message));
            });
    };
}

export function reset() {
    return dispatch => {
        dispatch({type: "REPORT_LIST_RESET"});
    };
}
