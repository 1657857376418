import React from 'react';
import {styled} from "@mui/styles";

export default function CenterContentContainer(props) {
    const StyledCenterContentContainer = styled('div')(({theme}) => ({
        [theme.breakpoints.down('md')]: {
            display: 'none'
        },
    }))
    return (
        <StyledCenterContentContainer>
            {props.children}
        </StyledCenterContentContainer>
    );
}
