import React, {useState} from "react";
import {Draggable} from "react-beautiful-dnd";

// @mui/material components
import {Checkbox, IconButton, } from "@mui/material";
import {withStyles} from "@mui/styles";

// @mui/icons-material
import { MoreVert, SubdirectoryArrowRightRounded } from "@mui/icons-material";

// core components
import CustomDate from 'components/date/date';

// styles
import sizeStyle from "assets/jss/views/configuration/defaultAttributeRowStyle";

// helper
import {getTranslation} from "../../../domain/helpers/translations";

import CreateBelow from '../../configuration/components/CreateBelow';
import CreateDialog from './createDialog';

function Size (props) {
    const {
        allSizes,
        checkedSizes,
        classes,
        data,
        draggableId,
        index,
        openPopover,
        search,
        selectSize,
        setSizeTablePosition,
        setCheckedSizes,
    } = props;

    const isChecked = checkedSizes.some(item => item['@id'] === data['@id']);

    const [createDialog, showCreateDialog] = useState(false);
    const [createBelow, showCreateBelow] = useState(false);

    function showPopover(e) {
        e.stopPropagation();
        selectSize(data);
        setSizeTablePosition(e.currentTarget);
        openPopover();
    }

    function checkSize(item) {
        const isItemAlreadyChecked = checkedSizes.some(itemAdded => itemAdded['@id'] === item['@id']);

        if (!isItemAlreadyChecked) {
            setCheckedSizes([...checkedSizes, item]);
        } else {
            let newSelected = checkedSizes.filter((d) => !(d['@id'] === item['@id']));

            setCheckedSizes(newSelected);
        }
    }

    function isSizeMatchWithSearch(size) {
        return !!getTranslation(size).name.toLowerCase().includes(search);
    }

    function elementClasses(size) {
        if(search.length === 0){
            return classes.element
        }

        if (search.length > 0 && isSizeMatchWithSearch(size) ) {
            return classes.elementMatch
        } else {
            return classes.elementHidden
        }
    }


    function nameClasses() {
        return classes.attributeNameSelected;
    }

    return (
        <>
            <Draggable index={index} draggableId={draggableId}>
                {provided => (
                    <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                        {data.parent && <SubdirectoryArrowRightRounded className={classes.actionsButtonContainer} />}
                        <li
                            className={elementClasses(data)}
                            onMouseEnter={() => showCreateBelow(true)}
                            onMouseLeave={() => showCreateBelow(false)}
                        >
                            <div className={classes.name}>
                                <Checkbox
                                    checked={isChecked}
                                    classes={{ root: classes.checkbox, checked: classes.checked }}
                                    onClick={(e) => e.stopPropagation()}
                                    onChange={() => checkSize(data)}
                                />
                                <span className={nameClasses()}>
                                    {getTranslation(data).name}
                                </span>
                            </div>
                            <div className={classes.actionsButtonContainer}>
                                <CustomDate showRelativeDate={true} date={data.updatedAt} />
                                <IconButton
                                    className={classes.iconButtonMargin}
                                    onClick={(e) => showPopover(e)}
                                    size={"small"}
                                >
                                    <MoreVert />
                                </IconButton>
                            </div>
                            <CreateDialog
                                closeDialog={() => {
                                    showCreateBelow(false);
                                    showCreateDialog(false);
                                }}
                                isOpen={createDialog}
                                allSizes={allSizes}
                                position={index + 1}
                            />
                            {createBelow && (
                                <CreateBelow
                                    onClick={() => showCreateDialog(true)}
                                />
                            )}
                        </li>
                    </div>
                )}
            </Draggable>
        </>
    );
}

export default withStyles(sizeStyle)(Size);
