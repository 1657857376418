import React, {Component} from "react";
import {withStyles} from "@mui/styles";
import {Tab, Tabs} from "@mui/material";
import {FormattedMessage} from "react-intl";

import listStyle from "assets/jss/views/quote/listStyle";
import {TAB_QUERY} from "../../enums/urlParametersIdentifier";
import {
    WAITING_REVIEW,
    ACCEPTED,
    UPDATING_QUOTE,
    REFUSED,
    WAITING_CUSTOMER,
    REVIEW_IN_PROGRESS,
    EXPIRED
} from "../../enums/state";
import QuoteExporter from "./exporter";

export const tabs = [
    {
        class: "tabAwaiting",
        containerClass: "awaiting",
        translationKey: "picking.quote.listing.gridHeaderState.awaiting",
        dataKey: WAITING_REVIEW,
        singleDataKey: "awaiting",
    },
    {
        class: "tabInternalAction",
        containerClass: "internalAction",
        translationKey: "picking.quote.listing.gridHeaderState.internalAction",
        dataKey: REVIEW_IN_PROGRESS,
        singleDataKey: "internalAction",
    },
    {
        class: "tabCustomerAction",
        containerClass: "customerAction",
        translationKey: "picking.quote.listing.gridHeaderState.customerAction",
        dataKey: UPDATING_QUOTE + ',' + WAITING_CUSTOMER,
        singleDataKey: "customerAction",
    },
    {
        class: "tabValidated",
        containerClass: "validated",
        translationKey: "picking.quote.listing.gridHeaderState.validated",
        dataKey: ACCEPTED,
        singleDataKey: "validated",
    },
    {
        class: "tabCancelled",
        containerClass: "cancelled",
        translationKey: "picking.quote.listing.gridHeaderState.cancelled",
        dataKey: REFUSED + ',' + EXPIRED,
        singleDataKey: "cancelled",
    },
];

class QuoteStateSwitcher extends Component {
    constructor(props) {
        super(props);

        let params = new URLSearchParams(this.props.history.location.search);
        if (params.has(TAB_QUERY)) {
            const index = tabs.findIndex(tab => params.get(TAB_QUERY) === tab.dataKey);
            this.state = {
                activeTab: index ?? 0,
            };
            return;
        }
        this.state = {
            activeTab: 0,
        };
    }

    handleChangeTab(tabIndex) {
        if (tabIndex === this.state.activeTab) {
            return;
        }

        this.setState({activeTab: tabIndex});
        if (!tabs[tabIndex].dataKey) {
            return;
        }

        let params = new URLSearchParams(this.props.history.location.search);
        params.set(TAB_QUERY, tabs[tabIndex].dataKey);
        params.set('page', 1);
        const newUrl = `${this.props.history.location.pathname}?${params.toString()}`;
        this.props.history.push(newUrl); // This will trigger component "container->setStateFromSearchParams" function
    }

    getCountForTab(tab, counts) {
        if (!counts) {
            return 0;
        }

        const states = tab.dataKey.split(',');
        let count = 0;
        states.forEach(state => {
            if (counts[state]) {
                count += counts[state];
            }
        });

        return count;
    }

    render() {
        const { classes, counts, loading } = this.props;

        return (
            <div>
                <div color="info" className={classes.cardHeaderTop}>
                    <h3 style={{fontWeight: "bold"}}>{<FormattedMessage id={"picking.quote.title"}/>}</h3>
                    { !loading &&
                        <QuoteExporter
                            startDate={this.props.startDate}
                            endDate={this.props.endDate}
                        />
                    }
                </div>
                <Tabs
                    value={this.state.activeTab}
                    onChange={(event, tabIndex) => this.handleChangeTab(tabIndex)}
                    classes={{ root: classes.tabsRoot, scrollable: classes.tabsScrollable, indicator: classes.tabsIndicator }}
                    variant={"scrollable"}
                >
                    {tabs.map(tab => (
                        <Tab
                            disableRipple
                            key={tab.translationKey}
                            classes={{ root: classes[tab.class], selected: classes.tabSelected }}
                            label={<FormattedMessage id={tab.translationKey} values={{qty: this.getCountForTab(tab, counts)}}/>}
                            className={classes[tab.containerClass]}
                        />
                    ))}
                </Tabs>
            </div>
        );
    }
}

export default withStyles(listStyle)(QuoteStateSwitcher);
