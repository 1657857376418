import {container, defaultFont} from "../../main";

const updateStyle = theme => ({
    containerWithSidebar: {
        [theme.breakpoints.up("md")]: {
            marginLeft: "240px",
        }
    },
    container: {
        ...container,
        ...defaultFont
    },
    card: {
        minHeight: window.innerHeight * 0.94
    },
    cardHeader: {
        minHeight: "68px"
    },
    mainActionsContainer: {
        margin: "12px 0px",
        textAlign: "right"
    },
    fieldContainer: {
        display: "flex",
        width: "40%",
        justifyContent: "space-between"
    },
    propagateLoader: {
        position: "absolute",
        left: "0",
        right: "0",
        margin: "0 auto",
        top: "48%",
        display: "flex",
        justifyContent: "center"
    }
});

export default updateStyle;
