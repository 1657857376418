import React from "react";
import {FormattedMessage} from "react-intl";

// @mui/material components
import {Dialog, DialogTitle, DialogContent} from "@mui/material";

// core components
import Form from "./inviteForm";

class InviteDialog extends React.Component {
    render() {
        const {closeDialog, openDialog, submitLoading, submitAction, member} = this.props;

        return (
            <Dialog
                open={openDialog}
                onClose={closeDialog}
                aria-labelledby="responsive-dialog-title"
                fullWidth={true}
                maxWidth={'md'}
            >
                <DialogTitle id="max-width-dialog-title">
                    <div>
                        <FormattedMessage id={"member.dialog.title.add.member"}/>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <Form
                        closeDialog={closeDialog}
                        onSubmit={submitAction}
                        submitLoading={submitLoading}
                        initialValues={member}
                    />
                </DialogContent>
            </Dialog>
        )
    }
}

export default InviteDialog;
