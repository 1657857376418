import React from 'react';

import makeStyles from "@mui/styles/makeStyles";

const useStyle = makeStyles({
    tableResponsive: {
        width: "100%",
    }
});

export default function StyledTableResponsive({children}) {
    const classes = useStyle();

    return (
        <div className={classes.tableResponsive}>
            {children}
        </div>
    )
}
