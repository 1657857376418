import React, {useState} from "react";
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import OutlinedInput from "@mui/material/OutlinedInput"
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import FormControl from '@mui/material/FormControl';
import {FormattedMessage} from "react-intl";
import DeleteProductsActionModal from "domain/components/merchandising/batchActions/DeleteProductsActionModal";
import UpdateBrandActionModal from "domain/components/merchandising/batchActions/UpdateBrandActionModal";
import AddCornerActionModal from "domain/components/merchandising/batchActions/AddCornerActionModal";
import RemoveCornerActionModal from "domain/components/merchandising/batchActions/RemoveCornerActionModal";
import UpdateGenderActionModal from "domain/components/merchandising/batchActions/UpdateGenderActionModal";
import ActivateOfferActionModal from "domain/components/merchandising/batchActions/ActivateOfferActionModal";
import DeactivateOfferActionModal from "domain/components/merchandising/batchActions/DeactivateOfferActionModal";

export default function SelectBatchAction(props) {
    const [action, setAction] = useState('');
    const [openModal, setOpenModal] = useState(false);

    const components = {
        activate: ActivateOfferActionModal,
        deactivate: DeactivateOfferActionModal,
        updateGender: UpdateGenderActionModal,
        updateBrand: UpdateBrandActionModal,
        addCorner: AddCornerActionModal,
        removeCorner: RemoveCornerActionModal,
        deleteProducts: DeleteProductsActionModal,
    };

    const handleChange = (event) => {
        setAction(event.target.value);
        setOpenModal(true);
    };

    function displayModal() {
        if (action === '') {
            return;
        }

        const ModalComponent = components[action];
        return (
            <ModalComponent
                selectedProducts={props.selectedProducts}
                setSelectAction={setAction}
                setOpen={setOpenModal}
                open={openModal}
                selectAll={props.selectAll}
                buildBody={props.buildBody}
            />
        );
    }

    return (
        <FormControl size="small">
            <Select
                variant="standard"
                input={<OutlinedInput value="actions" name="actions"/>}
                IconComponent={UnfoldMoreIcon}
                displayEmpty={true}
                autoWidth={true}
                disabled={props.isActionsDisabled}
                onChange={handleChange}
                value={action}
                sx={{
                    maxWidth: '200px'
                }}
            >
                <MenuItem disabled value="">
                    <FormattedMessage id={"merchandising.show.label.actions.placeholder"}/>
                </MenuItem>
                {Object.keys(components).map(key => 
                    <MenuItem key={key} value={key}>
                        <FormattedMessage id={"merchandising.show.label.actions." + key}/>
                    </MenuItem>
                )}
            </Select>
            {displayModal()}
        </FormControl>
    );
}
