import {fetch} from "../../utils/dataAccess";
import {ITEM_PER_PAGE} from "config/_pagination";

export function error(error) {
    return {type: "ORDER_LIST_ERROR", error};
}

export function loading(loading) {
    return {type: "ORDER_LIST_LOADING", loading};
}

export function success(retrieved) {
    return {type: "ORDER_LIST_SUCCESS", retrieved};
}

export function countLoading(loading) {
    return {type: "ORDER_LIST_COUNT_LOADING", loading};
}

export function countSuccess(retrieved) {
    return {type: "ORDER_LIST_COUNT_SUCCESS", retrieved};
}

export function list(params = `order[createdAt]=asc&itemsPerPage=${ITEM_PER_PAGE}&status=pending&page=1`) {
    return dispatch => {
        dispatch(loading(true));

        fetch("/orders?" + params).then(response => {
            response.json().then(retrieved => {
                dispatch(loading(false));
                dispatch(success(retrieved));
            }).catch(() => {
                dispatch(loading(false));
            });
        }).catch(error => {
            // canceled
        });
    }
}

export function count(param) {
    return dispatch => {
        dispatch(countLoading(true));

        fetch("/picking/order/count?"+ param).then(response => {
            response.json().then(retrieved => {
                dispatch(countLoading(false));
                dispatch(countSuccess(retrieved));
            }).catch(() => {
                dispatch(countLoading(false));
            });
        }).catch(error => {
            // canceled
        });
    }
}

export function reset() {
    return dispatch => {
        dispatch({type: "ORDER_LIST_RESET"});
    };
}
