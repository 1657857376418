import {fetch} from "../../../utils/dataAccess";

export function error(error) {
    return {type: "RETAILER_STRIPE_CONFIRM_ERROR", error};
}

export function loading(loading) {
    return {type: "RETAILER_STRIPE_CONFIRM_LOADING", loading};
}

export function success(created) {
    return {type: "RETAILER_STRIPE_CONFIRM_SUCCESS", created};
}

export function confirm(data) {

    return dispatch => {
        dispatch(loading(true));

        fetch("/callbacks/retailer/stripe_confirm", {
            method: "POST",
            body: JSON.stringify(data)
            }, true)
            .then(response => {
                dispatch(loading(false));
                return response.json();
            })
            .then(retrieved => dispatch(success(retrieved)))
            .catch(e => {
                dispatch(loading(false));
                dispatch(error(e));
            });
    };
}

export function reset() {
    return dispatch => {
        dispatch({type: "RETAILER_STRIPE_CONFIRM_RESET"});
        dispatch(loading(false));
        dispatch(error(null));
    };
}
