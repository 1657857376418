import React, {useContext} from "react";
import {FormattedMessage} from "react-intl";

// @mui/material components
import {
    Button
} from "@mui/material";

// context
import {CancellationReasonContext} from "contexts/CancellationReasonContext";
import {OrderContext} from "contexts/OrderContext";

// style
import {blackMagicColor, blueColor} from "assets/jss/main";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles(theme => ({
    confirmButton: {
        background: blueColor,
        borderRadius: '10px',
        textTransform: 'none',
    },
    cancelButton: {
        color: blackMagicColor,
        border: "none",
        background: "white",
        borderRadius: '10px',
        textTransform: 'none',
    }
}));

export default function ActionButtons() {
    const classes = useStyles();

    const {
        confirmCancellationAction,
        closeCancellationReasonDialog,
    } = useContext(OrderContext);

    const {
        selectedReason,
        dayCount
    } = useContext(CancellationReasonContext);

    return (
        <>
            <Button
                onClick={() => closeCancellationReasonDialog()}
                variant="outlined"
                className={classes.cancelButton}
            >
                <FormattedMessage id={"picking.order.show.cancellationReason.dialog.action.cancel"} />
            </Button>
            <Button
                onClick={() => confirmCancellationAction(selectedReason, dayCount)}
                color="primary"
                className={classes.confirmButton}
                variant="contained"
                disabled={selectedReason === null}
                disableElevation
            >
                <FormattedMessage id={"picking.order.show.cancellationReason.dialog.action.confirm"} />
            </Button>
        </>
    );
}
