import React, {Component} from "react";
import {Field, reduxForm} from "redux-form";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import {FormattedHTMLMessage, FormattedMessage, injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import Dropzone from "react-dropzone";
import {handleFileUpload, removeFile} from "actions/upload";
import {BeatLoader} from 'react-spinners';

// @mui/material components
import {
    CircularProgress,
    FormControl,
    MenuItem,
    Select,
    Switch,
    TextField
} from "@mui/material";

// @mui/icons-material
import {ArrowBackIos, CloudUpload, Delete, UnfoldMore} from "@mui/icons-material";

// core components
import Input from "components/input/input";
import GridContainer from "components/grid/gridContainer";
import GridItem from "components/grid/gridItem";
import Button from "components/button/button";
import MetadataContainer from "components/metadata/metadatas";
import OpeningDays from "../retailer/openingDays";

// styles
import formStyle from "assets/jss/views/retailer/formStyle";

// actions
import {sectorList as list, reset} from "actions/sector/list";
import {list as listCities, reset as resetCities} from "actions/city/list";

// helpers
import {capitalize} from "utils/capitalize";
import {Authorizations, hasAuthorization} from "utils/authorizations";

// translations
import FormTranslator from "../../../components/translator/formTranslator";
import {withStyles} from "@mui/styles";

//utils
import {CKEditor} from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {CKEditorConfig} from "../../../components/input/inputRenderer/wysiwygInputRenderer";

class Form extends Component {
    lastUploadedMediaObject = null;

    static propTypes = {
        handleSubmit: PropTypes.func.isRequired,
        error: PropTypes.object,
        deletedItem: PropTypes.object,
        list: PropTypes.func.isRequired,
        listCities: PropTypes.func.isRequired,
        reset: PropTypes.func.isRequired,
        resetCities: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);

        this.state = {
            errorNotification: true,
            deletedNotification: true,
            isPushActive: false,
            clickAndCollectEnabled: false,
            clickAndCollectOnly: false,
            taxRateZero: false,
            allowedToSwitchProductParent: false,
            catalogOnly: false,
            privateProducts: false,
            privatePrices: false,
            hiddenPrices: false,
        };
    }

    componentDidMount() {
        this.props.list(`order[name]=asc&pagination=false`);
        this.props.listCities(`organization.id=`+this.props.member['organization']+`&order[name]=asc&pagination=false`);
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if ((this.props.initialValues === null && nextProps.initialValues !== null)) {
            if (nextProps.initialValues.pushActive === true) {
                this.setState({isPushActive: true});
            }

            if (nextProps.initialValues.taxRateZero === true) {
                this.setState({taxRateZero: true});
            }
            if (nextProps.initialValues.catalogOnly === true) {
                this.setState({catalogOnly: true});
            }
            if (nextProps.initialValues.privateProducts === true) {
                this.setState({privateProducts: true});
            }
            if (nextProps.initialValues.privatePrices === true) {
                this.setState({privatePrices: true});
            }
            if (nextProps.initialValues.hiddenPrices === true) {
                this.setState({hiddenPrices: true});
            }

            const hasClickAndCollectInstructions = () => Object.values(nextProps.initialValues.translations).find(translation => {
               return null !== translation.clickAndCollectInstructions && '' !== translation.clickAndCollectInstructions.trim();
            });
            if (!!nextProps.initialValues.translations && hasClickAndCollectInstructions()) {
                this.setState({clickAndCollectEnabled: true});
            }

            if (nextProps.initialValues.clickAndCollectOnly) {
                this.setState({clickAndCollectOnly: true});
            }

            if (nextProps.initialValues.allowedToSwitchProductParent === true) {
                this.setState({allowedToSwitchProductParent: true});
            }
        }
    }

    componentWillUnmount() {
        this.props.reset();
        this.props.resetCities();
    }

    handleSubmit = (e) => {
        e.preventDefault();

        this.props.fields.values.pushActive = this.state.isPushActive;
        this.props.fields.values.allowedToSwitchProductParent = this.state.allowedToSwitchProductParent;
        this.props.fields.values.clickAndCollectOnly = this.state.clickAndCollectOnly;
        this.props.fields.values.taxRateZero = this.state.taxRateZero;
        this.props.fields.values.catalogOnly = this.state.catalogOnly;
        this.props.fields.values.privateProducts = this.state.privateProducts;
        this.props.fields.values.privatePrices = this.state.privatePrices;
        this.props.fields.values.hiddenPrices = this.state.hiddenPrices;

        if (!this.state.clickAndCollectEnabled) {
            this.props.currentOrganization.locales.forEach((locale) => {
                if (!this.props.fields.values.translations) {
                    this.props.fields.values.translations = [];
                }
                if (!this.props.fields.values.translations[locale]) {
                    this.props.fields.values.translations[locale] = {};
                }
                this.props.fields.values.translations[locale].clickAndCollectInstructions = null;
            })
            this.props.fields.values.clickAndCollectOnly = false;
        }

        this.props.handleSubmit();
    };

    renderField = data => {
        const {classes, createError, updateError, update} = this.props;
        const fieldName = data.input.name;

        return (
            <div className={classes.fieldContainer}>
                <label
                    htmlFor={`retailer_${data.input.name}`}
                    className={classes.fieldLabel}
                >
                    {data.label}
                </label>

                {data.type === "textarea" && (
                    <TextField
                        variant="standard"
                        className={`${classes.formControl} ${classes.inputTextarea}`}
                        placeholder={data.placeholder}
                        {...data.input}
                        required={data.required}
                        id={`retailer_${data.input.name}`}
                        multiline
                        rows={4}>
                        {data.input.value}
                    </TextField>
                )}
                {(data.type && data.type === "wysiwyg") &&
                    <CKEditor
                        name={data.input.name}
                        id={`retailer_${data.input.name}_${data.code}`}
                        config={Object.assign(CKEditorConfig, {link: {addTargetToExternalLinks: true}})}
                        editor={ClassicEditor}
                        data={data.input.value}
                        onChange={( event, editor ) => {
                            data.input.onChange(editor.getData());
                        }}
                    />
                }
                {data.type !== "textarea" && data.type !== "file" && data.type !== "wysiwyg" && (
                    <Input
                        {...data.input}
                        type={data.type}
                        step={data.step}
                        required={data.required}
                        id={`retailer_${data.input.name}`}
                        inputRootCustomClasses={classes.input}
                        inputProps={{
                            placeholder: data.placeholder,
                            type: data.type,
                            required: data.required,
                            disabled: data.disabled,
                            ...data.input,
                            autoComplete: data.autoComplete
                        }}
                        formControlProps={{
                            className: classes.formControl
                        }}
                    />
                )}
                {!update ? createError && createError.errors && createError.errors[fieldName] && <span className={classes.errorMessages}><FormattedMessage id={`${createError.errors[fieldName]}`}/></span> :
                    updateError && updateError.errors && updateError.errors[fieldName] && <span className={classes.errorMessages}><FormattedMessage id={`${updateError.errors[fieldName]}`}/></span>

                }
            </div>
        );
    };

    renderSwitch = data => {
        return (
            <Switch
                checked={this.state.isPushActive}
                onChange={() => this.setState({isPushActive: !this.state.isPushActive})}
                color="primary"
                inputProps={{ 'aria-label': 'primary checkbox' }}
                value={this.state.isPushActive}
            />
        )
    };

    renderSwitchParent = data => {
        return (
            <Switch
                checked={this.state.allowedToSwitchProductParent}
                onChange={() => this.setState({allowedToSwitchProductParent: !this.state.allowedToSwitchProductParent})}
                color="primary"
                inputProps={{ 'aria-label': 'primary checkbox' }}
                value={this.state.allowedToSwitchProductParent}
            />
        )
    };

    renderSwitchCatalog = ({ input }) => {
        return (
            <Switch
                checked={this.state[input.name]}
                onChange={() => this.setState({[input.name]: !this.state[input.name]})}
                color="primary"
                inputProps={{ 'aria-label': 'primary checkbox' }}
                value={this.state[input.name]}
            />
        );
    };

    renderTaxRateZero = () => {
        return (
            <Switch
                checked={this.state.taxRateZero}
                onChange={() => this.setState({taxRateZero: !this.state.taxRateZero})}
                color="primary"
                inputProps={{ 'aria-label': 'primary checkbox' }}
                value={this.state.taxRateZero}
            />
        )
    };

    renderSelect = data => {
        const {classes, retrieved, cities, createError, updateError, update} = this.props;
        const fieldName = data.input.name;

        let currentSelectOptions;
        if ('city' === fieldName) {
            currentSelectOptions = cities.retrieved;
            if (currentSelectOptions) {
                currentSelectOptions = currentSelectOptions['hydra:member'];
            }
        } else {
            currentSelectOptions = retrieved;
        }

        return (
            <div className={classes.fieldContainer}>
                <label
                    htmlFor={`retailer_${data.input.name}`}
                    className={classes.fieldLabel}
                >
                    {data.label}
                </label>
                <FormControl variant="standard" component={"div"} className={classes.formControl}>
                    <Select
                        variant="standard"
                        {...data.input}
                        type={data.type}
                        value={data.input.value}
                        id={`retailer_${data.input.name}`}
                        displayEmpty
                        onChange={(event) => {
                            data.input.onChange(event.target.value);
                            this.setState({selectedItem:event.target.value})
                        }}
                        IconComponent={UnfoldMore}>
                        <MenuItem component={"div"} button={false} value="" disabled>
                            <span className={classes.placeholder} >
                                {data.placeholder}
                            </span>
                        </MenuItem>
                        {currentSelectOptions && currentSelectOptions.map(item => {
                            var value = '';
                            if (item.translations) {
                                value = this.props.currentOrganization && item.translations[this.props.currentOrganization.defaultLocale] ? item.translations[this.props.currentOrganization.defaultLocale].name : '';
                            } else {
                                value = item.name;
                            }
                            return (<MenuItem component={"div"} button={true} key={item['@id']} value={item['@id']}
                                        classes={{
                                            root: classes.customMenuItem,
                                            selected: classes.customMenuItemSelected
                                        }}>{value}</MenuItem>
                                    )
                        })}
                    </Select>
                </FormControl>
                {!update ? createError && createError.errors && createError.errors[fieldName] &&
                    <span className={classes.errorMessages}>
                        <FormattedMessage id={`${createError.errors[fieldName]}`}/>
                    </span> :
                    updateError && updateError.errors && updateError.errors[fieldName] &&
                    <span className={classes.errorMessages}>
                        <FormattedMessage id={`${updateError.errors[fieldName]}`}/>
                    </span>
                }
            </div>
        );
    };

    render() {
        const {classes} = this.props;

        const renderDropzoneInput = (field) => {
            let preview = null;

            if(
              typeof field.input.name !== 'undefined'
              && this.props.uploaded !== null
              && field.input.name === this.props.uploaded.uploaded_object_id
            ) {
                if(this.lastUploadedMediaObject !== this.props.uploaded) {
                    this.lastUploadedMediaObject = this.props.uploaded;

                    field.input.value = this.props.uploaded;
                    preview = this.props.uploaded.contentUrl;

                    if(this.props.fields) {
                        let value = this.props.fields.values;
                        if (value) {
                            let nameParts = field.input.name.split('.');
                            let lastPart = nameParts.splice(-1).pop();
                            for (let part of nameParts) {
                                if (!value[part]) {
                                    value[part] = {};
                                }
                                value = value[part];
                            }
                            value[lastPart] = this.props.uploaded;
                        }
                    }
                }
            }

            if (this.props.initialValues) {
                let initialValue = this.props.initialValues;
                for (let part of field.input.name.split('.')) {
                    if(!initialValue[part]) {
                        initialValue = null;
                        break;
                    }
                    initialValue = initialValue[part];
                }
                if(initialValue) {
                    preview = initialValue.contentUrl;
                }
            }

            const files = field.input.value;
            if (files[0]) {
                preview = URL.createObjectURL(files[0])
            }


            const deletePicture = (e) => {
                e.stopPropagation();
                if (field.input.value && field.input.value["@id"]) {
                    removeFile(field.input.value["@id"]);

                    field.input.value = null;
                    preview = null;

                    let value = this.props.fields.values;
                    if (value) {
                        let nameParts = field.input.name.split('.');
                        let lastPart = nameParts.splice(-1).pop();
                        for (let part of nameParts) {
                            if (!value[part]) {
                                value[part] = {};
                            }
                            value = value[part];
                        }
                        value[lastPart] = null;
                    }
                    // Set state to force a rerender of the form
                    this.setState({});
                }
            }

            const renderPreview = (preview) => {
                if(preview) {
                    return <img src={preview} alt={""} className={classes.imgPreview} />;
                }
            }

            return (
                <div className={classes.dropzoneContainer}>
                    <label
                        htmlFor={`retailer_${field.input.name}`}
                        className={classes.fieldLabel}
                    >
                        <FormattedMessage id={`retailer.form.label.${field.input.name.split('.').slice(-1)}`}/>
                    </label>
                    <div className={classes.pictureContainer}>
                        {this.props.uploadLoading && <CircularProgress className={classes.pictureLoading}/>}
                        <Dropzone
                            name={field.input.name}
                            accept="image/*"
                            multiple={false}
                            className={classes.dropzoneRetailerImage}
                            onDrop={(filesToUpload) => this.props.upload(filesToUpload, field.input.name.split('.').slice(-1)[0], field.input.name)}
                        >
                            {({getRootProps, getInputProps}) => {
                                return (
                                    <div className={classes.pictureBlock} {...getRootProps()}>
                                        <input {...getInputProps()} />

                                        <div>
                                            <div className={classes.pictureActions}>
                                                <CloudUpload fontSize={"large"} className={classes.pictureIcon}/>
                                                <Delete fontSize={"large"} onClick={(e) => deletePicture(e)} className={classes.delete} />
                                            </div>
                                            <div className={classes.pictureName}>
                                                {
                                                    field.input.value && field.input.value.contentUrl
                                                    ? field.input.value.contentUrl.split('/').splice(-1)
                                                    : this.props.intl.formatMessage({id: "retailer.form.placeholder.pictureEmpty"})
                                                }
                                            </div>
                                        </div>

                                        {renderPreview(preview)}
                                    </div>
                                )
                            }}
                        </Dropzone>
                        {field.meta.touched &&
                        field.meta.error &&
                        <span className="error">{field.meta.error}</span>}
                    </div>
                </div>
            );
        };

        const renderRetailerTranslationFormTab = (index, code) => {
            return <>
                {this.state.clickAndCollectEnabled && (
                    <Field
                        component={this.renderField}
                        name={`translations.${code}.clickAndCollectInstructions`}
                        label={<FormattedMessage id={"retailer.form.label.clickAndCollectInstructions"}/>}
                        type={"textarea"}
                        placeholder={this.props.intl.formatMessage({id: "retailer.form.placeholder.clickAndCollectInstructions"})}
                        required={true}
                    />
                )}
                <Field
                    component={this.renderField}
                    name={`translations.${code}.description`}
                    label={<FormattedMessage id={"retailer.form.label.description"}/>}
                    type={"wysiwyg"}
                    code={code}
                    placeholder={this.props.intl.formatMessage({id: "retailer.form.placeholder.description"})}
                />
                <Field name={`translations.${code}.picture`} component={renderDropzoneInput}/>
                <Field name={`translations.${code}.logo`} component={renderDropzoneInput}/>
            </>;
        }

        return (
            <form onSubmit={this.handleSubmit}>
                <GridContainer className={classes.formContainer}>
                    <GridItem xs={12} sm={6}>
                        <Field
                            component={this.renderField}
                            name={"name"}
                            label={<FormattedMessage id={"retailer.form.label.name"}/>}
                            type={"text"}
                            placeholder={this.props.intl.formatMessage({id: "retailer.form.placeholder.name"})}
                            required
                        />
                        <Field
                            component={this.renderField}
                            name={"email"}
                            label={<FormattedMessage id={"retailer.form.label.email"}/>}
                            type={"email"}
                            format={(value) => value ? value.toLowerCase() : ""}
                            parse={(value) => value ? value.toLowerCase() : ""}
                            placeholder={this.props.intl.formatMessage({id: "retailer.form.placeholder.email"})}
                            required
                        />
                        <Field
                            component={this.renderField}
                            name={"address"}
                            label={<FormattedMessage id={"retailer.form.label.address"}/>}
                            type={"text"}
                            placeholder={this.props.intl.formatMessage({id: "retailer.form.placeholder.address"})}
                            required
                        />
                        <Field
                            component={this.renderField}
                            name={"phone"}
                            label={<FormattedMessage id={"retailer.form.label.phone"}/>}
                            type={"text"}
                            placeholder={this.props.intl.formatMessage({id: "retailer.form.placeholder.phone"})}
                            required
                        />
                        <Field
                            component={this.renderField}
                            name={"whatsappPhone"}
                            label={<FormattedMessage id={"retailer.form.label.whatsappphone"}/>}
                            type={"text"}
                            placeholder={this.props.intl.formatMessage({id: "retailer.form.placeholder.whatsappphone"})}
                        />
                        <Field
                            component={this.renderField}
                            name={"smsPhone"}
                            label={<FormattedMessage id={"retailer.form.label.smsphone"}/>}
                            type={"text"}
                            placeholder={this.props.intl.formatMessage({id: "retailer.form.placeholder.smsphone"})}
                        />
                        <Field
                            component={this.renderField}
                            name={"paymentAccount['accountId']"}
                            label={<FormattedMessage id={"retailer.form.label.stripeId"}/>}
                            type={"text"}
                            disabled={true}
                        />
                        <Field
                            component={this.renderField}
                            name={"siret"}
                            label={<FormattedMessage id={"retailer.form.label.siret"}/>}
                            type={"text"}
                            placeholder={this.props.intl.formatMessage({id: "retailer.form.placeholder.siret"})}
                        />
                        <Field
                            component={this.renderField}
                            name={"contactName"}
                            label={<FormattedMessage id={"retailer.form.label.contactName"}/>}
                            type={"text"}
                            placeholder={this.props.intl.formatMessage({id: "retailer.form.placeholder.contactName"})}
                        />
                        <Field
                            component={this.renderField}
                            name={"commissionRate"}
                            label={<FormattedMessage id={"retailer.form.label.commissionRate"}/>}
                            type={"number"}
                            step={"any"}
                            required
                        />
                        <Field
                            component={this.renderField}
                            name={"coniqLocationId"}
                            label={<FormattedMessage id={"retailer.form.label.coniqLocationId"}/>}
                            type={"text"}
                        />
                        {this.props.intl.formatMessage({id: "retailer.form.placeholder.enableClickAndCollect"})}
                        <Switch
                            checked={this.state.clickAndCollectEnabled}
                            onChange={() => this.setState({clickAndCollectEnabled: !this.state.clickAndCollectEnabled})}
                            color="primary"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                            value={this.state.clickAndCollectEnabled}
                        />
                        {this.state.clickAndCollectEnabled && (
                            <div>
                                <FormattedHTMLMessage id={"retailer.form.placeholder.clickAndCollectOnly"}/>
                                <Switch
                                    checked={this.state.clickAndCollectOnly}
                                    onChange={(event, checked) => this.setState({clickAndCollectOnly: checked})}
                                    name={"clickAndCollectOnly"}
                                    color="primary"
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                />
                            </div>
                        )}
                        {this.props.currentOrganization && (
                            <FormTranslator formGenerator={renderRetailerTranslationFormTab} />
                        )}
                    </GridItem>
                    <GridItem xs={12} sm={6}>
                        <Field
                            component={this.renderField}
                            name={"rent"}
                            label={<FormattedMessage id={"retailer.form.label.rent"}/>}
                            type={"number"}
                            placeholder={this.props.intl.formatMessage({id: "retailer.form.placeholder.rent"})}
                        />
                        <Field
                            component={this.renderField}
                            name={"surface"}
                            label={<FormattedMessage id={"retailer.form.label.surface"}/>}
                            type={"number"}
                            placeholder={this.props.intl.formatMessage({id: "retailer.form.placeholder.surface"})}
                            required
                        />
                        <Field
                            component={this.renderField}
                            name={"sellers"}
                            label={<FormattedMessage id={"retailer.form.label.sellers"}/>}
                            type={"number"}
                            placeholder={this.props.intl.formatMessage({id: "retailer.form.placeholder.sellers"})}
                            required
                        />
                        <Field
                            component={this.renderField}
                            name={"stockThreshold"}
                            label={<FormattedMessage id={"retailer.form.label.stock.threshold"}/>}
                            type={"number"}
                            placeholder={this.props.intl.formatMessage({id: "retailer.form.label.stock.threshold"})}
                            required
                        />
                        <Field
                            component={this.renderSelect}
                            name={"sector"}
                            label={<FormattedMessage id={"retailer.form.label.sector"}/>}
                            type={"select"}
                            placeholder={this.props.intl.formatMessage({id: "retailer.form.placeholder.sector"})}
                            format={(value) => value ? capitalize(value) : ""}
                            parse={(value) => value ? value.toLowerCase() : ""}
                            required
                        />
                        <Field
                            component={this.renderSelect}
                            name={"city"}
                            label={<FormattedMessage id={"retailer.form.label.city"}/>}
                            type={"select"}
                            placeholder={this.props.intl.formatMessage({id: "retailer.form.placeholder.city"})}
                            format={(value) => value ? capitalize(value) : ""}
                            parse={(value) => value ? value.toLowerCase() : ""}
                        />
                        <FormattedMessage id={"retailer.form.label.pushActive"}/>
                        <Field
                            component={this.renderSwitch}
                            name={"pushActive"}
                            label={<FormattedMessage id={"retailer.form.label.pushActive"}/>}
                            type={"switch"}
                        />
                        <div>
                            <FormattedMessage id={"retailer.form.label.allowedToSwitchProductParent"}/>
                            <Field
                                component={this.renderSwitchParent}
                                name={"allowedToSwitchProductParent"}
                                label={<FormattedMessage id={"retailer.form.label.allowedToSwitchProductParent"}/>}
                                type={"switch"}
                            />
                        </div>
                        {hasAuthorization(this.props.authorizations, Authorizations.GLOBAL_ORGANIZATION_ESHOP_MANAGEMENT) &&
                            <>
                                <FormattedMessage id={"retailer.form.label.taxRateZero"}/>
                                <Field
                                    component={this.renderTaxRateZero}
                                    name={"taxRateZero"}
                                    label={<FormattedMessage id={"retailer.form.label.taxRateZero"}/>}
                                    type={"switch"}
                                />
                            </>
                        }
                        <div>
                            <FormattedMessage id={"retailer.form.label.catalogOnly"}/>
                            <Field
                                component={this.renderSwitchCatalog}
                                name={"catalogOnly"}
                                label={<FormattedMessage id={"retailer.form.label.catalogOnly"}/>}
                                type={"switch"}
                            />
                        </div>
                        <div>
                            <FormattedMessage id={"retailer.form.label.privateProducts"}/>
                            <Field
                                component={this.renderSwitchCatalog}
                                name={"privateProducts"}
                                label={<FormattedMessage id={"retailer.form.label.privateProducts"}/>}
                                type={"switch"}
                            />
                        </div>
                        <div>
                            <FormattedMessage id={"retailer.form.label.privatePrices"}/>
                            <Field
                                component={this.renderSwitchCatalog}
                                name={"privatePrices"}
                                label={<FormattedMessage id={"retailer.form.label.privatePrices"}/>}
                                type={"switch"}
                            />
                        </div>
                        <div>
                            <FormattedMessage id={"retailer.form.label.hiddenPrices"}/>
                            <Field
                                component={this.renderSwitchCatalog}
                                name={"hiddenPrices"}
                                label={<FormattedMessage id={"retailer.form.label.hiddenPrices"}/>}
                                type={"switch"}
                            />
                        </div>
                        {this.props.updateLogo
                            // && <Field name={"file"} component={renderDropzoneInput}/>
                        }

                        {this.props.retailer && (
                            <OpeningDays
                                openingDays={!!this.props.fields.values.openingDays ? this.props.fields.values.openingDays : {}}
                                openingDaysChanged={newOpeningDays => this.props.fields.values.openingDays = newOpeningDays}
                            />
                        )}

                        {this.props.retailer && (
                            <MetadataContainer
                                initialMetadata={!!this.props.fields.values.metadata ? this.props.fields.values.metadata : []}
                                metadataChanged={metadata => this.props.fields.values.metadata = metadata}
                            />
                        )}
                        <Field
                            component={this.renderField}
                            name={'metaTitle'}
                            label={<FormattedMessage id={'retailer.form.label.metaTitle'}/>}
                            type={'text'}
                        />
                        <Field
                            component={this.renderField}
                            name={'metaDescription'}
                            label={<FormattedMessage id={'retailer.form.label.metaDescription'}/>}
                            type={'text'}
                        />
                        {this.props.retailer?.paymentAccount && (
                            <>
                                <span className={classes.billingInfosTitle}>
                                    <FormattedMessage id={"retailer.form.paymentAccount.title"}/>
                                </span>
                                <p className={classes.billingActive}>
                                    {this.props.retailer.paymentAccount.activeBilling ? (
                                        <FormattedHTMLMessage id={"retailer.form.paymentAccount.enabled"}/>
                                    ) : (
                                        <FormattedHTMLMessage id={"retailer.form.paymentAccount.disabled"}/>
                                    )}
                                </p>
                                <Field
                                    component={this.renderField}
                                    name={"paymentAccount['tradeName']"}
                                    label={<FormattedMessage id={"retailer.form.label.paymentAccount.tradeName"}/>}
                                    type={"text"}
                                    placeholder={this.props.intl.formatMessage({id: "retailer.form.placeholder.paymentAccount.tradeName"})}
                                />
                                <Field
                                    component={this.renderField}
                                    name={"paymentAccount['siret']"}
                                    label={<FormattedMessage id={"retailer.form.label.paymentAccount.siret"}/>}
                                    type={"text"}
                                    placeholder={this.props.intl.formatMessage({id: "retailer.form.placeholder.paymentAccount.siret"})}
                                />
                                <Field
                                    component={this.renderField}
                                    name={"paymentAccount['vatNumber']"}
                                    label={<FormattedMessage id={"retailer.form.label.paymentAccount.vatNumber"}/>}
                                    type={"text"}
                                    placeholder={this.props.intl.formatMessage({id: "retailer.form.placeholder.paymentAccount.vatNumber"})}
                                />
                                <Field
                                    component={this.renderField}
                                    name={"paymentAccount['email']"}
                                    label={<FormattedMessage id={"retailer.form.label.paymentAccount.email"}/>}
                                    type={"email"}
                                    placeholder={this.props.intl.formatMessage({id: "retailer.form.placeholder.paymentAccount.email"})}
                                />
                                <Field
                                    component={this.renderField}
                                    name={"paymentAccount['address']"}
                                    label={<FormattedMessage id={"retailer.form.label.paymentAccount.address"}/>}
                                    type={"text"}
                                    placeholder={this.props.intl.formatMessage({id: "retailer.form.placeholder.paymentAccount.address"})}
                                />
                            </>
                        )}
                    </GridItem>
                </GridContainer>
                <div className={this.props.classes.actionsContainer}>
                    <Button color={"info"} simple component={Link}
                            to={"/retailers/" + encodeURIComponent("order[pushActive]=desc&order[name]=asc&pagination=true&itemsPerPage=500&page=1")}><ArrowBackIos/><FormattedMessage
                        id={"user.form.back"}/></Button>
                    <Button round color={"success"} type="submit">
                        <FormattedMessage id={"user.form.submit"}/>
                        <div className={classes.submitLoading}>
                            <BeatLoader
                                sizeUnit={"px"}
                                size={4}
                                color={"#FFF"}
                                loading={this.props.createLoading || this.props.updateLoading}
                            />
                        </div>
                    </Button>
                </div>
            </form>
        );
    }
}

const mapStateToProps = state => {
    const {
        updateLoading,
    } = state.retailer.update;

    const {
        retrieved,
        reset,
    } = state.sector.list;

    const {
        member,
        organizations
    } = state.authentication;

    const {
        success,
        loading,
        error,
    } = state.upload;

    const createLoading = state.retailer.create.loading;
    const createError = state.retailer.create.error;
    const updateError = state.retailer.update.updateError;

    const retailer = state?.retailer?.update.retrieved

    return {
        retrieved,
        cities: state.city.list,
        reset,
        updateLoading,
        uploaded: success,
        uploadLoading: loading,
        uploadError: error,
        createLoading,
        member,
        retailer,
        organizations,
        createError,
        updateError,
        fields: state.form.retailer,
        currentOrganization: state.currentOrganization.retrieved,
        authorizations: state.authentication.authorizations,
    };
};

const mapDispatchToProps = dispatch => ({
    list: params => dispatch(list(params)),
    listCities: params => dispatch(listCities(params)),
    reset: () => dispatch(reset()),
    resetCities: () => dispatch(resetCities()),
    upload: (file, field_type, field_id) => dispatch(handleFileUpload(file, field_type === 'logo' ? "image_retailer_translation_logo" : "image_retailer_translation_cover", field_id)),
    removeFile: (id) => dispatch(removeFile(id))
});

export default reduxForm({
    form: "retailer",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
}, mapStateToProps)(connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(formStyle)(injectIntl(Form))));
