import React from 'react';
import { FormattedMessage } from "react-intl";
import { BeatLoader } from 'react-spinners';

// @mui/material components
import { makeStyles } from '@mui/styles';

// style
import { primaryColor } from "../../../assets/jss/main";

const useStyles = makeStyles(theme => ({
    tableHeadContainer:{
        display: "flex",
        justifyContent: "space-between",
        width: "100%"
    },
    headContainer:{
        display: "flex",
        justifyContent: "space-between",
        width: "94%",
        "& div:first-child": {
            paddingLeft: "60px"
        }
    },
    primaryTableHeader: {
        color: primaryColor,
        fontSize: "16px",
        fontWeight: "400",
    },
    loaderContainer: {
        color: primaryColor,
        fontSize: "16px",
        fontWeight: "400",
    },
}));

export default function EnhancedTableHead (props) {
    const classes = useStyles();

    return (
        <div className={classes.tableHeadContainer}>
            <div className={classes.headContainer}>
                {props.headers.map((header, index) => (
                    <div key={index} className={classes.primaryTableHeader}>
                        {header !== '' && <FormattedMessage id={header} />}
                    </div>
                ))}
            </div>
            <div className={classes.loaderContainer}>
                <BeatLoader
                    sizeUnit={"px"}
                    size={12}
                    color={primaryColor}
                    loading={props.listLoading}
                />
            </div>
        </div>
    );
}
