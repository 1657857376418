export default function payloadFactory(payload) {
    if (payload.overrodeCategory) {
        payload.overrodeCategory = payload.overrodeCategory['@id'];
    }

    payload.category = null;
    payload.corner = null;

    if (!payload.recommendedProduct) {
        return payload;
    }

    if (payload.recommendedProduct['@type'] === 'Corner') {
        payload.corner = payload.recommendedProduct['@id'];
    } else if (payload.recommendedProduct['@type'] === 'Category') {
        payload.category = payload.recommendedProduct['@id'];
    }

    return payload
}
