import React, {Component} from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {FormattedMessage, injectIntl} from "react-intl";
import {BeatLoader} from 'react-spinners';

// @mui/material components
import {Chip, Grid, } from "@mui/material";
import {withStyles} from "@mui/styles";

// core components
import Card from "components/card/card";
import CardBody from "components/card/cardBody";
import Table from "./dashboard/table";
import TimeBlock from "./dashboard/timeBlock";
import OrderStatisticBlock from "./dashboard/orderStatisticBlock";
import TopRunnerBlock from "./dashboard/topRunnerBlock";

// styles
import dashboardStyle from "assets/jss/views/picking/dashboard/dashboardStyle";
import {primaryColor} from "assets/jss/main";

// actions
import {retrieveDashboardData, reset} from "actions/picking/dashboard";
import {list as organizationShippingMethodsList} from "api/shippingMethod/list";

// helpers
import abort from "utils/abort";
import {Authorizations, hasAuthorization} from "../../utils/authorizations";

class Dashboard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            organizationShippingMethods: []
        };
    };

    componentDidMount() {
        this.props.retrieveDashboardData();

        organizationShippingMethodsList().then((res) => {
            this.setState({
                organizationShippingMethods: res
            })
        })
    }

    componentWillUnmount() {
        this.props.reset();
        abort.abortRequests();
    };

    render() {
        const {classes, retrieved} = this.props;

        let tableHeadData = [
            <FormattedMessage id={"picking.order.list.table.header.order"}/>,
            <FormattedMessage id={"picking.order.list.table.header.customer"}/>,
            <FormattedMessage id={"picking.order.list.table.header.type"}/>,
            <FormattedMessage id={"picking.order.list.table.header.total"}/>,
            <FormattedMessage id={"picking.order.list.table.header.quantity"}/>,
        ];

        if (!hasAuthorization(this.props.authorizations, Authorizations.PICKING_RETAILER)) {
            tableHeadData.push(<FormattedMessage id={"picking.order.list.table.header.affectedTo"}/>);
        }

        return (
            <div className={classes.containerWithSidebar}>
                <div className={classes.container}>
                    <Grid container>
                        <Grid item xs={12} md={8}>
                            <Card>
                                <CardBody>
                                    <Grid
                                        className={classes.labelPending}
                                        container
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                    >
                                        <Chip className={classes.pendingChip} label={<FormattedMessage id={"picking.dashboard.tab.label.pending"}/>} component={"span"}/>
                                        <BeatLoader
                                            sizeUnit={"px"}
                                            size={10}
                                            color={primaryColor}
                                            loading={this.props.loading}
                                        />
                                        {(retrieved && retrieved.orders.pending && retrieved.orders.pending.length) && <FormattedMessage id={"picking.dashboard.tab.pending.qte"} values={{qty:retrieved && retrieved.ordersCount.pending.toLocaleString()}}/>}
                                    </Grid>
                                    <Table
                                        tableHead={tableHeadData}
                                        tableHeaderColor="primary"
                                        tableData={retrieved && retrieved.orders.pending}
                                        match={this.props.match}
                                        history={this.props.history}
                                        organizationShippingMethods={this.state.organizationShippingMethods}
                                    />
                                    <Grid
                                        className={classes.labelShowMoreCommands}
                                        container
                                        direction="row"
                                        justifyContent="flex-end"
                                        alignItems="center"
                                    >
                                        <Link className={classes.labelLink}
                                              to={`/picking/orders/${encodeURIComponent(`order[createdAt]=asc&status=pending&itemsPerPage=5&page=1`)}`}
                                        >
                                            <FormattedMessage id={"picking.dashboard.table.show.more"}/>
                                        </Link>
                                    </Grid>
                                </CardBody>
                            </Card>
                            <Card>
                                <CardBody className={classes.cardBody}>
                                    <Grid
                                        className={classes.labelPending}
                                        container
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                    >
                                        <Chip className={classes.toShipChip} label={<FormattedMessage id={"picking.dashboard.tab.label.toShip"}/>} component={"span"}/>
                                        <BeatLoader
                                            sizeUnit={"px"}
                                            size={10}
                                            color={primaryColor}
                                            loading={this.props.loading}
                                        />
                                        {(retrieved && retrieved.orders['to-ship'] && retrieved.orders['to-ship'].length) && <FormattedMessage id={"picking.dashboard.tab.toShip.qte"} values={{qty:retrieved && retrieved.ordersCount['to-ship'].toLocaleString()}}/>}
                                    </Grid>
                                    <Table
                                        tableHead={tableHeadData}
                                        tableHeaderColor="primary"
                                        tableData={retrieved && retrieved.orders['to-ship']}
                                        match={this.props.match}
                                        history={this.props.history}
                                        organizationShippingMethods={this.state.organizationShippingMethods}
                                    />
                                    <Grid
                                        className={classes.labelShowMoreCommands}
                                        container
                                        direction="row"
                                        justifyContent="flex-end"
                                        alignItems="center"
                                    >
                                        <Link className={classes.labelLink}
                                              to={`/picking/orders/${encodeURIComponent(`order[createdAt]=asc&status=to-ship&itemsPerPage=5&page=1`)}`}
                                        >
                                            <FormattedMessage id={"picking.dashboard.table.show.more"}/>
                                        </Link>
                                    </Grid>
                                </CardBody>
                            </Card>
                        </Grid>
                        <Grid item  xs={12} md={4}>
                            <TimeBlock number={retrieved && retrieved.averageOrderTreatmentTime} />
                            <OrderStatisticBlock ordersCount={retrieved && retrieved.ordersCount}/>
                            {((retrieved && retrieved.topThreeRunners.length > 1) && !hasAuthorization(this.props.authorizations, Authorizations.PICKING_RETAILER)) && <TopRunnerBlock topThreeRunners={retrieved && retrieved.topThreeRunners}/>}
                        </Grid>
                    </Grid>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    const {retrieved, loading} = state.pickingDashboard;
    const {authorizations} = state.authentication;

    return {retrieved, loading, authorizations};
};

const mapDispatchToProps = dispatch => ({
    retrieveDashboardData: () => dispatch(retrieveDashboardData()),
    reset: () => dispatch(reset()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(dashboardStyle)(injectIntl(Dashboard)));
