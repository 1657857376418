import React from 'react';

export default function ActionsContainer(props) {
    return (
        <div style={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: '12px',
            alignItems: 'baseline',
        }}>
            {props.children}
        </div>
    );
}

