import {
    container,
    defaultFont,
    primaryColor,
    successColor,
    dangerColor,
    thunderGrayColor
} from "../../main";

const avatar = {
    width: 50,
    height: 50,
};

const avatarText = {
    textTransform: "uppercase",
    fontSize: "20px",
    fontWeight: "bold",
    background: primaryColor
};

const memberStyle = theme => ({
    containerWithSidebar: {
        [theme.breakpoints.up("md")]: {
            marginLeft: "240px",
        }
    },
    container: {
        ...container,
        ...defaultFont
    },
    mainActionsContainer: {
        margin: "8px 0px",
        textAlign: "right"
    },
    card: {
        height: window.innerHeight * 0.94,
        background: "transparent",
        boxShadow: "none"
    },
    root: {
        "& button": {
            textTransform: "none",
            fontSize: "14px",
            width: "200px"
        }
    },
    padding: {
        padding: theme.spacing(6),
    },
    tabsBorder: {
        borderBottom: "1px solid #ddd",
        textTransform: "none",
    },
    subTableContainer: {
        display: "flex",
        width: "100%",
        justifyContent: "flex-end",
        alignItems: "flex-end",
        paddingTop: "10px",
    },
    table: {
        borderSpacing: "0 20px",
        borderCollapse: "separate",
        "& thead": {
            "& tr": {
                "& th": {
                    border: "none"
                }
            }
        },
        "& tbody": {
            "& tr": {
                "& td": {
                    border: "none",
                }
            }
        }
    },
    tableBody: {
        background: "white",
    },
    enabledTableRow: {
        height: "65px",
        borderRadius: "5px",
        background: "transparent",
        overflow: "none",
        cursor: "pointer",
    },
    disabledTableRow: {
        height: "65px",
        borderRadius: "5px",
        background: "transparent",
        overflow: "none",
        cursor: "pointer",
    },
    selectedTableRow: {
        boxShadow: "0px 0px 13px 1px #c6cace",
    },
    leftTableCell: {
        borderTopLeftRadius: "5px",
        borderBottomLeftRadius: "5px"
    },
    rightTableCell: {
        borderTopRightRadius: "5px",
        borderBottomRightRadius: "5px"
    },
    avatarEnabled: {
        ...avatar,
        border: `3px solid ${successColor}`
    },
    avatarDisabled: {
        ...avatar,
        border: `3px solid ${dangerColor}`
    },
    avatarTextEnabled: {
        ...avatar,
        ...avatarText,
        border: `3px solid ${successColor}`
    },
    avatarTextDisabled: {
        ...avatar,
        ...avatarText,
        border: `3px solid ${dangerColor}`
    },
    checkbox: {
        color: "grey",
        "&$checked": {
            color: primaryColor,
            "&:hover": {
                backgroundColor: "rgba(0, 53, 245, 0.08)"
            },
        },
        "&:hover": {
            backgroundColor: "rgba(0, 53, 245, 0.08)"
        },
    },
    updateMemberStatusLoading: {
        marginLeft: "5px",
        color: "#FFFFFF"
    },
    updateMemberStatusButtonContainer: {
        position: "absolute",
        right: "5px",
        bottom: "0px",
        textAlign: "right",
        "& Button": {
            textTransform: "none",
        },
    },
    paginationContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%"
    },
    dialogTitleRoot: {
        display: "flex",
        flex: 1,
        flexDirection: "row",
        justifyContent: "space-between"
    },
    typography: {
        padding: theme.spacing(2),
        cursor: "pointer",
        "&:hover": {
            background: primaryColor,
            color: "white",
        },
    },
    icon: {
        margin: theme.spacing(2),
        cursor: "pointer"
    },
    beatLoader: {
        position: "absolute",
        right: "30px",
        margin: "0 auto",
        top: "26px",
        display: "flex",
        justifyContent: "center"
    },
    propagateLoader: {
        position: "absolute",
        left: "0",
        right: "0",
        margin: "0 auto",
        top: "48%",
        display: "flex",
        justifyContent: "center"
    },
    checked: {
        color: primaryColor,
    },
    validateAction: {
        color: "#FFFFFF",
        fontSize: "30px",
    },
    cancelAction: {
        color: "#FFFFFF",
        fontSize: "30px",
    },
    invActionsMargin: {
        marginRight: "10px"
    },
    tableCellNoResult: {
        fontSize: "16px",
        color: thunderGrayColor,
        position: "absolute",
        left: "0",
        right: "0",
        margin: "0 auto",
        top: "48%",
        display: "flex",
        justifyContent: "center",
        fontWeight: "bold",
    },
    badgePadding:{
        padding: theme.spacing(0, 1)
    },
    badgeColor: {
        backgroundColor: dangerColor,
        color: "white"
    }
});


export default memberStyle;
