import {fetch} from "../../utils/dataAccess";

export function error(error) {
    return {type: "NOTIFICATION_DELETE_ERROR", error};
}

export function loading(loading) {
    return {type: "NOTIFICATION_DELETE_LOADING", loading};
}

export function success(success) {
    return {type: "NOTIFICATION_DELETE_SUCCESS", success};
}

export function del(item) {
    return dispatch => {
        dispatch(loading(true));
        dispatch(success(false));

        return fetch(item["@id"], {method: "DELETE"})
            .then(() => {
                dispatch(loading(false));
                dispatch(success(item));
            })
            .catch((e) => {
                dispatch(loading(false));
                dispatch(error(e.message));
            });
    }
};
