import {container, defaultFont, primaryColor} from "../main";

const loginStyle = {
    container: {
        ...container,
        ...defaultFont,
        minHeight: window.innerHeight * 0.93,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        color: primaryColor,
        fontWeight: "bold",

        "& span": {
            paddingLeft: "15px",
            paddingBottom: "10px"
        }
    },
    logoutButton: {
        fontSize: ".7em",
        fontWeight: "400",
        marginTop: "5em",
        position: "absolute",
        bottom: "0",
        color: "inherit",
        textDecoration: "none",
    }
};

export default loginStyle;
