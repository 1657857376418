import React from 'react';
import IconContainer from "./IconContainer";

import {ReactComponent as CommentSvgIcon} from "assets/img/comment.svg";

export default function CommentIcon () {
    return (
        <IconContainer>
            <CommentSvgIcon width={16} height={16}/>
        </IconContainer>
    )
}
