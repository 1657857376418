import {combineReducers} from "redux";

export function loading(state = false, action) {
    switch (action.type) {
        case "MEMBER_INVITE_LOADING":
            return action.loading;

        case "MEMBER_INVITE_RESET":
            return null;

        default:
            return state;
    }
}

export function invited(state = null, action) {
    switch (action.type) {
        case "MEMBER_INVITE_SUCCESS":
            return action.member;

        case "MEMBER_INVITE_RESET":
            return null;

        default:
            return state;
    }
}

export function error(state = null, action) {
    switch (action.type) {
        case "MEMBER_INVITE_ERROR":
            return action.error;

        case "MEMBER_INVITE_RESET":
            return null;

        default:
            return state;
    }
}

export default combineReducers({loading, invited, error});
