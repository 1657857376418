import {fetch} from "../../../utils/dataAccess";

export function error(error) {
    return {type: "ATTRIBUTE_UPDATE_ERROR", error};
}

export function loading(loading) {
    return {type: "ATTRIBUTE_UPDATE_LOADING", loading};
}

export function success(success) {
    return {type: "ATTRIBUTE_UPDATE_SUCCESS", success};
}

export function update(values, retailerId) {
    Object.keys(values).map((key) => {
        if (!values[key]) {
            delete values[key]
        }

        return key;
    });

    return dispatch => {
        dispatch(loading(true));
        let arrayValues = {};
        let arrayCategories = {};
        let arrayGenders = {};
        let arrayColors = {};
        let arraySizes = {};
        let trashCategories = [];
        let untrashCategories = [];

        Object.values(values).forEach(function (item) {
            if (item.value === 'categories') {
                arrayCategories[item['name']] = item['@id'];
            }

            if (item.value === 'genders') {
                arrayGenders[item['name']] = item['@id'];
            }

            if (item.value === 'colors') {
                arrayColors[item['name']] = item['@id'];
            }

            if (item.value === 'sizes') {
                arraySizes[item['name']] = item['@id'];
            }

            if (!!item.trashCategories) {
                trashCategories = trashCategories.concat(item.trashCategories);
            }

            if (!!item.untrashCategories) {
                untrashCategories = untrashCategories.concat(item.untrashCategories);
            }
        });

        arrayValues['categories'] = arrayCategories;
        arrayValues['genders'] = arrayGenders;
        arrayValues['sizes'] = arraySizes;
        arrayValues['colors'] = arrayColors;
        if (trashCategories.length !== 0) {
            arrayValues['trashCategories'] = trashCategories.filter((value, index, self) => self.indexOf(value) === index);
        }
        if (untrashCategories.length !== 0) {
            arrayValues['untrashCategories'] = untrashCategories.filter((value, index, self) => self.indexOf(value) === index);
        }

        return fetch("/feed_attributes/missing", {
            method: "POST",
            headers: new Headers({"Content-Type": "application/ld+json"}),
            body: JSON.stringify(arrayValues)
        }).then(() => {
            dispatch(success(true));
            dispatch(loading(false));
        }).catch(e => {
            dispatch(loading(false));
            dispatch(error(true));
        });

    };
}

export function reset() {
    return dispatch => {
        dispatch(error(false));
        dispatch(success(null));
        dispatch(loading(null));
    };
}
