import {fetch} from "../../utils/dataAccess";

export function error(error) {
    return {type: "NOTIFICATION_MARK_ALL_AS_READ_ERROR", error};
}

export function loading(loading) {
    return {type: "NOTIFICATION_MARK_ALL_AS_READ_LOADING", loading};
}

export function success(success) {
    return {type: "NOTIFICATION_MARK_ALL_AS_READ_SUCCESS", success};
}

export function markAllAsRead(member) {
    return dispatch => {
        dispatch(loading(true));
        dispatch(success(false));

        return fetch(`/notifications/mark_all_as_read?member=${member}`, {method: "GET"})
            .then(() => {
                dispatch(loading(false));
                dispatch(success(true));
            })
            .catch((e) => {
                dispatch(loading(false));
                dispatch(error(e.message));
            });
    }
};
