import {fetch} from "../../utils/dataAccess";
const FileDownload = require('js-file-download');

export function exportLoading(exportLoading) {
    return {type: "RETAILER_EXPORT_LOADING", exportLoading};
}

export function importError(importError) {
    return {type: "RETAILER_IMPORT_ERROR", importError};
}

export function importLoading(importLoading) {
    return {type: "RETAILER_IMPORT_LOADING", importLoading};
}

export function importSuccess(imported) {
    return {type: "RETAILER_IMPORT_SUCCESS", imported};
}

export function downloadExport(retailerId) {
    return dispatch => {
        dispatch(exportLoading(true));
        fetch(retailerId+"/export", {method: 'GET'}).then(response => {
            response.text().then(retrieved => {
                dispatch(exportLoading(false));
                FileDownload(retrieved, `${retailerId}.json`)
            })
        }).catch(error => {
            dispatch(exportLoading(false));
            console.log(error)
        });
    }
}

export function importRetailer(retailerId, file, config) {
    let formData = new FormData();
    formData.append("file", file);
    formData.append('config', JSON.stringify(config));

    return dispatch => {
        dispatch(importLoading(true));
        dispatch(importSuccess(null));

        return fetch(retailerId+"/import", {
            method: 'POST',
            body: formData
        }).then(response => {
            dispatch(importLoading(false));
            dispatch(importSuccess(response));
        }).catch(error => {
            dispatch(importLoading(false));
            dispatch(importError(error));
            console.log(error);
        });
    }
}
