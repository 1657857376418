import React, {Component} from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import PropTypes from "prop-types";
import {FormattedMessage, injectIntl} from "react-intl";
import {capitalize} from "utils/capitalize";

// @mui/material components
import {Tooltip, Fab} from "@mui/material"
import {withStyles} from "@mui/styles";

// @mui/icons-material
import {
    NotificationsActive,
    AddRounded
} from "@mui/icons-material";

// core components
import Snackbar from "components/snackbar/snackbar";
import GridContainer from "components/grid/gridContainer";
import GridItem from "components/grid/gridItem";
import Card from "components/card/card";
import CardHeader from "components/card/cardHeader";
import CardBody from "components/card/cardBody";
import Table from "./table";
import CardFooter from "components/card/cardFooter";
import PaginationContainer from "../../../components/pagination/paginationContainer";

// styles
import listStyle from "assets/jss/views/retailer/listStyle";

// actions
import {list, reset} from "actions/retailer/list";
import {create} from "actions/retailer/stripe/create";
import {del} from "actions/retailer/delete";

// helpers
import {Authorizations, hasAuthorization} from "utils/authorizations";
import abort from "utils/abort";

class List extends Component {
    static propTypes = {
        retrieved: PropTypes.object,
        loading: PropTypes.bool.isRequired,
        error: PropTypes.string,
        deletedItem: PropTypes.object,
        list: PropTypes.func.isRequired,
        reset: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);

        this.state = {
            errorNotification: true,
            deletedNotification: true,
            updatedNotification: true,
            createdNotification: true,
            stripeInvitedNotification: true,
        };
    }

    componentDidMount() {
        this.props.list(
            this.props.match.params.params &&
            decodeURIComponent(this.props.match.params.params)
        );
    }

    UNSAFE_componentWillReceiveProps(nextProps) {

        this.setState({errorNotification: true});
        this.setState({deletedNotification: true});

        if ((this.props.match.params.params !== nextProps.match.params.params) || (this.props.deletedItem !== nextProps.deletedItem))
            nextProps.list(
                nextProps.match.params.params &&
                decodeURIComponent(nextProps.match.params.params)
            );
    }

    componentWillUnmount() {
        this.props.reset();
        this.props.resetUpdate();
        abort.abortRequests();
    }

    del = (item) => {
        if (window.confirm(this.props.intl.formatMessage({id: "retailer.window.confirm.delete"})))
            this.props.del(item);
    };

    createStripe = (item) => {

        this.setState({loadingStripeId: item['id']})
        this.props.create(item['id']);
    }

    render() {
        const {classes} = this.props;

        return (
            <div>
                <div className={classes.containerWithSidebar}>
                    <div className={classes.container}>
                        {this.props.deletedItem && (
                            <Snackbar
                                open={this.state.deletedNotification}
                                close
                                closeNotification={() => this.setState({deletedNotification: false})}
                                place={"bl"}
                                color={"success"}
                                message={<div className={classes.snackbarMessage}><NotificationsActive/><FormattedMessage id={"retailer.notifications.retailerDeleted"}
                                                           values={{name: capitalize(this.props.deletedItem["name"])}}/></div>}
                            />
                        )}

                        {this.props.error && (
                            <Snackbar
                                open={this.state.errorNotification}
                                close
                                closeNotification={() => this.setState({errorNotification: false})}
                                place={"bl"}
                                color={"danger"}
                                message={<div className={classes.snackbarMessage}><NotificationsActive/> {this.props.error}</div>}
                            />
                        )}

                        {this.props.created && (
                            <Snackbar
                                open={this.state.createdNotification}
                                close
                                closeNotification={() => this.setState({createdNotification: false})}
                                place={"bl"}
                                color={"success"}
                                message={<div className={classes.snackbarMessage}><NotificationsActive/><FormattedMessage id={"retailer.notifications.retailerCreated"}
                                                           values={{name: capitalize(this.props.created["name"])}}/></div>}
                            />
                        )}

                        {this.props.updated && (
                            <Snackbar
                                open={this.state.updatedNotification}
                                close
                                closeNotification={() => this.setState({updatedNotification: false})}
                                place={"bl"}
                                color={"success"}
                                message={<div className={classes.snackbarMessage}><NotificationsActive/><FormattedMessage id={"retailer.notifications.retailerUpdated"}
                                                                values={{name: capitalize(this.props.updated["name"]) }}/></div>}
                            />
                        )}

                        {this.props.stripeInvited && (
                            <Snackbar
                                open={this.state.stripeInvitedNotification}
                                close
                                closeNotification={() => this.setState({stripeInvitedNotification: false})}
                                place={"bl"}
                                color={"success"}
                                message={<div className={classes.snackbarMessage}><NotificationsActive/><FormattedMessage id={"retailer.notifications.retailerInvited"}/></div>}
                            />
                        )}

                        <GridContainer>
                            <GridItem xs={12}>
                                <Card className={classes.card}>
                                    <CardHeader color="info">
                                        <GridContainer>
                                            <GridItem xs={9} sm={10}><h3><FormattedMessage id={"retailer.list.title"}/>
                                            </h3></GridItem>
                                            {(hasAuthorization(this.props.authorizations, Authorizations.MARKETPLACE_RETAILER_MANAGEMENT) && ((!this.props.loading && !this.props.deleteLoading) || !this.props.retrieved)) &&
                                            <GridItem xs={3} sm={2} className={classes.mainActionsContainer}>
                                                <Fab component={Link} to="create" color={"primary"}><Tooltip
                                                    title={<FormattedMessage
                                                        id={"retailer.list.tooltip.create"}/>}><AddRounded
                                                    fontSize={"large"}
                                                    className={classes.createUser}/></Tooltip></Fab>
                                            </GridItem>
                                            }
                                        </GridContainer>
                                    </CardHeader>
                                    <CardBody>
                                        <Table
                                            tableHead={[
                                                <FormattedMessage id={"retailer.list.table.header.name"}/>,
                                                <FormattedMessage id={"retailer.list.table.header.warning.mappings"}/>,
                                                <FormattedMessage id={"retailer.list.table.header.warning.update"}/>,
                                                <FormattedMessage id={"retailer.list.table.header.warning.products"}/>,
                                            ]}
                                            tableHeaderColor="primary"
                                            tableData={this.props.retrieved && this.props.retrieved["hydra:member"]}
                                            match={this.props.match}
                                            del={this.del}
                                            createStripe={this.createStripe}
                                        />
                                    </CardBody>
                                    <CardFooter center>
                                        <PaginationContainer
                                            itemsPerPage={500}
                                            urlParams={this.props.match.params.params}
                                            retrieved={this.props.retrieved}
                                        />
                                    </CardFooter>
                                </Card>
                            </GridItem>
                        </GridContainer>
                    </div>
                </div>
            </div>
        );
    }

    renderLinks = (type, items) => {
        if (Array.isArray(items)) {
            return items.map((item, i) => (
                <div key={i}>{this.renderLinks(type, item)}</div>
            ));
        }

        return (
            <Link to={`${type}/show/${encodeURIComponent(items)}`}>{items}</Link>
        );
    };
}

const mapStateToProps = state => {
    const {
        retrieved,
        loading,
        error,
    } = state.retailer.list;

    const {
        deleted
    } = state.retailer.del;

    const deleteLoading = state.retailer.del.loading;
    const stripeLoading = state.retailer.invite.loading;
    const stripeInvited = state.retailer.invite.invite;

    return {
        retrieved,
        loading,
        error,
        deleteLoading,
        stripeLoading,
        stripeInvited,
        deletedItem: deleted,
        created: state.retailer.create.created,
        updated: state.retailer.update.updated,
        authorizations: state.authentication.authorizations
    };
};

const mapDispatchToProps = dispatch => ({
    list: params => dispatch(list(params)),
    create: params => dispatch(create(params)),
    del: item => dispatch(del(item)),
    reset: () => dispatch(reset()),
    resetUpdate: () => dispatch({type: "RETAILER_UPDATE_RESET"})
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(listStyle)(injectIntl(List)));
