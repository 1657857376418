export const CategoryFilter = {
    SAME: 'same',
    OTHERS: 'others',
    ALL: 'all'
}

export const ColorFilter = {
    SAME: 'same',
    ALL: 'all'
}

export const RetailerFilter = {
    SAME: 'same',
    OTHERS: 'others',
    ALL: 'all'
}

export const Sort = {
    BEST_SELLER: 'best_seller',
    CREATED_AT_DESC: 'created_at_desc',
    PRICE_ASC: 'price_asc',
    PRICE_DESC: 'price_desc',
    PROMOTION_ASC: 'promotion_asc',
    PROMOTION_DESC: 'promotion_desc',
}

export const Type = {
    PRODUCT: 'product',
    CART: 'cart'
}
