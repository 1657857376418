import React from "react";
import {Route} from "react-router-dom";

import RequireAuthentication from "../hoc/authentication/requireAuthentication";
import QuotesList from "../views/quote/list";
import QuoteDetail from "../views/quote/detail";

let routes = [
    <Route
        path="/quotes"
        strict={false}
        component={RequireAuthentication(QuotesList)}
        key="quotes"
    />,
    <Route
        path="/quote/:id"
        component={RequireAuthentication(QuoteDetail)}
        exact
        strict={true}
        key="quote-detail"
    />,
];

export default routes;
