import React, {Component} from "react";
import {injectIntl} from "react-intl";

import CustomModal from "../../../components/customModal";
import {ReactComponent as BackIcon} from "../../../../../../assets/img/backIcon.svg";

class BackBrowserModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
        }

        // We need to push at least one history (the current url), because if we come
        // directly from the url or by a refresh, it won't work.
        if (!window.history.state) {
            window.history.pushState({fromSECreateProduct: true}, document.title, window.location.href);
        }
        window.addEventListener('popstate', this.handleBackBrowser);
    }

    componentWillUnmount() {
        window.removeEventListener('popstate', this.handleBackBrowser);
    }

    handleBackBrowser = () => {
        const {step} = this.props;

        // step 0 is barcode, step 6 is product created
        if (step > 0 && step < 6) {
            this.setState({open: true});
        }
    }

    handleStayOnPage = () => {
        this.handleClose();
        window.history.pushState(null, document.title, window.location.href);
    }

    handleLeavePage = () => {
        this.handleClose();
        window.history.go(-1);
    }

    handleClose = () => {
        this.setState({open: false});
    };

    render() {
        const {open} = this.state;

        return (
            <CustomModal
                open={open}
                title={this.props.intl.formatMessage({id:"stockeasy.step.leave.modal.title"})}
                contentText={this.props.intl.formatMessage({id:"stockeasy.step.leave.modal.content"})}
                cancelButtonText={this.props.intl.formatMessage({id:"stockeasy.step.leave.modal.leave"})}
                validateButtonText={this.props.intl.formatMessage({id:"stockeasy.step.leave.modal.stay"})}
                cancelButtonAction={() => {this.handleLeavePage()}}
                validateButtonAction={() => {this.handleStayOnPage()}}
                icon={<BackIcon/>}
            />
        );
    }
}

export default injectIntl(BackBrowserModal);
