import {combineReducers} from "redux";

export function error(state = null, action) {
    switch (action.type) {
        case "RETAILER_CREATE_ERROR":
            return action.error;

        case "RETAILER_CREATE_RESET":
            return null;

        default:
            return state;
    }
}

export function loading(state = false, action) {
    switch (action.type) {
        case "RETAILER_CREATE_LOADING":
            return action.loading;

        case "RETAILER_CREATE_RESET":
            return false;

        default:
            return state;
    }
}

export function created(state = null, action) {
    switch (action.type) {
        case "RETAILER_CREATE_SUCCESS":
            return action.created;

        case "RETAILER_CREATE_RESET":
            return null;
            
        default:
            return state;
    }
}

export default combineReducers({error, loading, created});
