import {fetch} from "utils/dataAccess";
import normalizeTranslations from "../../actions/translations";
import addImages from "./addImages";
import persister from "./persister";
import hydrator from "./hydrator";

export default async function update(corner, values, files)  {
    values = normalizeTranslations(values);

    let toPersist = persister(corner.id, values);
    toPersist = await addImages(toPersist, files);
    const response = await fetch('/corners/'+corner.id, {
        method: "PUT",
        body: JSON.stringify(toPersist),
        headers: new Headers({'Content-Type': 'application/ld+json'}),
    });

    return hydrator(await response.json());
}
