import React from 'react'
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";

import StyledSpanCellBold from "components/TableCells/StyledSpanCellBold";

export default function Brand({lineItem}) {
    return (
        <>
            <StyledSpanCellBold>
                {lineItem.brand ? lineItem.brand : <FormattedMessage id={"picking.order.show.table.na"} />}
            </StyledSpanCellBold>
            {lineItem.variation?.retailer?.name}
        </>
    );
}

Brand.defaultProps = {
    lineItem: {}
};

Brand.propTypes = {
    lineItem: PropTypes.object
};
