import {fetch} from "../../utils/dataAccess";

export default function (id) {
    return new Promise((resolve, reject) => {
        fetch('/quotes/' + id)
            .then(response => {
                response.json().then(response => {
                    resolve(response);
                });
            })
            .catch(error => reject(error));
    });
}
