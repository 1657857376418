import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from "react-intl";

import BoldTitle from "../NoteBlock/BoldTitle";

CustomerAddCommentMessage.propTypes = {
    customerName: PropTypes.string
};

export default function CustomerAddCommentMessage({customerName}) {
    return !!customerName && (
        <div style={{ fontWeight: 400 }}>
            <BoldTitle>{customerName}</BoldTitle>&nbsp;
            <FormattedMessage id={'picking.quote.history.comment.added'} />&nbsp;
            <b><FormattedMessage id={'picking.quote.history.comment.aComment'} /></b>
        </div>
    );
}
