import React from 'react'
import {makeStyles} from '@mui/styles'

const useStyles = makeStyles(theme => ({
    selectorContainer: {
        alignContent: 'start',
        marginTop: '12px',
        position: 'absolute',
        zIndex: 1,
        width: '100%',
        boxShadow: '0px 4px 22px 0px rgba(0, 0, 0, 0.06)',
    },
}))

export default function SelectorContainer(props) {
    const classes = useStyles()

    return (
        <div className={classes.selectorContainer}>
            {props.children}
        </div>
    )
}
