import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {Link} from "react-router-dom";
import {ITEM_PER_PAGE} from "config/_pagination";
import {FormattedMessage} from "react-intl";
import DrawerDashboard from "./lineItems/DrawerDashboard";
import DrawerHelp from "./lineItems/DrawerHelp";
import StockEasyMenu from "../../domain/components/sidebar/StockEasyMenu";
import PickingMenu from "../../domain/components/sidebar/PickingMenu";
import DrawerConfiguration from "./lineItems/DrawerConfiguration";
// @mui/material components
import {
    Collapse,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
} from "@mui/material";

// @mui/icons-material
import {
    StoreOutlined,
    ExpandMore,
    ExpandLess,
} from "@mui/icons-material";

// styles
import sidebarStyle from "assets/jss/components/sidebar/sidebarStyle";

// utils
import {capitalize} from "utils/capitalize";
import {Authorizations, hasAuthorization} from "utils/authorizations";
import {stockEasyOffer, marketPlaceOffer, pickingOffer, statisticsOffer} from "../../domain/enum/offers";
import MarketPlaceMenuAdmin from "../../domain/components/sidebar/MarketPlaceMenuAdmin";
import StatisticsMenu from "../../domain/components/sidebar/StatisticsMenu";
import {withStyles} from "@mui/styles";
import DrawerRetailerProfiles from "./lineItems/DrawerRetailerProfiles";

class DrawerList extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            productTabOpen: this.props.match.path.startsWith("/retailers/show/:id/feeds/") || this.props.match.path.startsWith("/retailers/show/:id/products/") || this.props.match.path.startsWith("/retailers/show/:id/variations/"),
            configurationTabOpen: this.props.match.path.startsWith("/members") || this.props.match.path.startsWith("/roles") || this.props.match.path.startsWith("/categories") || this.props.match.path.startsWith("/sizes") || this.props.match.path.startsWith("/colors") || this.props.match.path.startsWith("/genders"),
            memberTabOpen: this.props.match.path.startsWith("/members"),
            itemsPerPage: this.props.itemsPerPage ? this.props.itemsPerPage : ITEM_PER_PAGE,
            offer: marketPlaceOffer,
        };
    }

    componentDidMount() {
        const {path} = this.props.match;

        if (path.startsWith("/picking") || path.startsWith("/quote")) {
            this.setState({
                offer: pickingOffer,
            });
        } else if (path.startsWith("/stockeasy")) {
            this.setState({
                offer: stockEasyOffer,
            });
        } else if (path.startsWith("/statistics")) {
            this.setState({
                offer: statisticsOffer,
            });
        } else {
            this.setState({
                offer: marketPlaceOffer,
            });
        }
    }

    handleProductTabChange = () => {
        this.setState(state => ({productTabOpen: !state.productTabOpen}));
        this.setState({userTabOpen: false});
    };

    getDashboardLink() {
        const {applications, authorizations, member} = this.props;

        let dashboardLink = "/";

        if (this.state.offer === pickingOffer) {
            dashboardLink = "/picking";
        }

        if (this.state.offer === statisticsOffer) {
            dashboardLink = "/statistics";
        }

        // marketplace access only for retailer
        if (
            null !== member["retailers"]
            && 1 === member["retailers"].length
            && !hasAuthorization(authorizations, Authorizations.PICKING_RUNNER)
            && applications['marketplace']
        ) {
            dashboardLink = `/retailers/show/${encodeURIComponent(member["retailers"][0]["@id"])}`;
        }

        return dashboardLink;
    }

    memberHasRetailer() {
        const {applications, authorizations, member} = this.props;

        let retailer = null;

        // if member has at least one retailer and access to marketplace
        if (
            null !== member["retailers"]
            && 0 < member["retailers"].length
            && !hasAuthorization(authorizations, Authorizations.PICKING_RUNNER)
            && !applications['marketplace']
        ) {
            retailer = member["retailers"][0]["@id"];
        }

        return retailer;
    }

    // WARNING : this code renderRetailers has not been tested and is not really used for the moment : retailers have no access to Market Place for the moment without been admin
    // Later, this code could be exported in a MarketPlaceMenuRetailer Component like for MarketPlaceMenuAdmin
    renderRetailers = (retailer) => {
        const {authorizations, classes, match, retailerRetrieved} = this.props;
        const {productTabOpen, itemsPerPage} = this.state;

        const isInRetailerProductPath = (match.path.startsWith("/retailers/show/:id/products/") || match.path.startsWith("/retailers/show/:id/variations/"));
        const isInRetailerFeedPath = match.path.startsWith("/retailers/show/:id/feeds/");

        const isInRetailerAdminPath = (
            match.path === "/retailers/:params"
            || match.path === "/retailers/create"
            || match.path === "/retailers/edit/:id"
            || Boolean(retailerRetrieved)
        );

        let dashboardLink = this.getDashboardLink();
        const isInDashboardPath = match.path === "/" || (match.path === "/retailers/show/:id" && retailer) || match.path === "/picking";

        return(
            <div>
                <DrawerDashboard
                    classes={classes}
                    dashboardLink={dashboardLink}
                    isInDashboardPath={isInDashboardPath}
                />

                {/* button display retailer list*/}
                {(hasAuthorization(authorizations, Authorizations.MARKETPLACE_RETAILER_MANAGEMENT) || hasAuthorization(authorizations, Authorizations.MARKETPLACE_RETAILER_VIEW)) &&
                    <div>
                        <ListItem
                            button
                            className={classes.listItem}
                            classes={{selected: (isInRetailerAdminPath) ? classes.selected : classes.subListSelected}}
                            component={Link}
                            to={"/retailers/" + encodeURIComponent("order[pushActive]=desc&order[name]=asc&pagination=true&itemsPerPage=" + 500 + "&page=1")}
                            key={"retailerList"}
                            selected={isInRetailerAdminPath}
                        >
                            <ListItemIcon>
                                <StoreOutlined color={isInRetailerAdminPath ? "primary" : "secondary"} />
                            </ListItemIcon>
                            <ListItemText
                                primaryTypographyProps={{color: isInRetailerAdminPath ? "primary" : "secondary"}}
                                primary={<FormattedMessage id={"sidebar.item.retailerList"}/>}
                            />
                            {retailerRetrieved && <ExpandLess color={"primary"}/>}
                        </ListItem>
                        {/* show selected retailer name*/}
                        <Collapse in={Boolean(retailerRetrieved)}>
                            {retailerRetrieved &&
                            <ListItemText
                                primaryTypographyProps={{color: "initial"}}
                                primary={capitalize(retailerRetrieved["name"])}
                                className={classes.retailerTitle}
                            />
                            }
                            {retailerRetrieved &&
                            <div className={classes.retailerSubMenu}>
                                <ListItem
                                    button
                                    className={classes.listItem}
                                    classes={{selected: classes.selected}}
                                    component={Link}
                                    to={`/retailers/show/${encodeURIComponent(retailerRetrieved["@id"])}`}
                                    key={"retailerDashboard"}
                                    selected={match.path === "/retailers/show/:id"}
                                >
                                    <ListItemText
                                        primaryTypographyProps={{color: match.path === "/retailers/show/:id" ? "primary" : "secondary"}}
                                        primary={<FormattedMessage id={"sidebar.item.dashboard"} />}
                                    />
                                </ListItem>

                                {(hasAuthorization(authorizations, Authorizations.MARKETPLACE_FEED_MANAGEMENT) || hasAuthorization(authorizations, Authorizations.MARKETPLACE_PRODUCT_VIEW) || hasAuthorization(authorizations, Authorizations.MARKETPLACE_PRODUCT_MANAGEMENT)) &&
                                <div>
                                    <ListItem
                                        button
                                        className={classes.listItem}
                                        classes={{selected: classes.subListSelected}}
                                        onClick={this.handleProductTabChange}
                                        key={"products"}
                                        selected={isInRetailerFeedPath || isInRetailerProductPath}
                                    >
                                        <ListItemText
                                            primaryTypographyProps={{color: isInRetailerFeedPath || isInRetailerProductPath ? "primary" : "secondary"}}
                                            primary={<FormattedMessage id={"sidebar.item.products"}/>}
                                        />
                                        {productTabOpen ? (
                                            <ExpandLess color={"primary"}/>
                                        ) : (
                                            <ExpandMore color={"secondary"}/>
                                        )
                                        }
                                    </ListItem>
                                    <Collapse in={productTabOpen}>
                                        <List className={classes.listContainer} disablePadding>
                                            {(hasAuthorization(authorizations, Authorizations.MARKETPLACE_PRODUCT_VIEW) || hasAuthorization(authorizations, Authorizations.MARKETPLACE_PRODUCT_MANAGEMENT)) &&
                                            <ListItem
                                                button
                                                className={classes.listItem}
                                                classes={{selected: classes.selected}}
                                                component={Link}
                                                to={`/retailers/show/${encodeURIComponent(retailerRetrieved["@id"])}/products/${encodeURIComponent(`retailer.id=${retailerRetrieved["id"]}&order[translations.name]=asc&itemsPerPage=50&page=1`)}`}
                                                key={"productList"}
                                                selected={isInRetailerProductPath}
                                            >
                                                <ListItemText
                                                    className={classes.subListText}
                                                    inset
                                                    primaryTypographyProps={{color: match.path.startsWith("/retailers/show/:id/products") ? "primary" : "secondary"}}
                                                    primary={<FormattedMessage id={"sidebar.item.productList"}/>}
                                                />
                                            </ListItem>
                                            }
                                            {hasAuthorization(authorizations, Authorizations.MARKETPLACE_FEED_MANAGEMENT) &&
                                            <ListItem
                                                button
                                                className={classes.listItem}
                                                classes={{selected: classes.selected}}
                                                component={Link}
                                                to={`/retailers/show/${encodeURIComponent(retailerRetrieved["@id"])}/feeds/`}
                                                key={"feeds"}
                                                selected={isInRetailerFeedPath}
                                            >
                                                <ListItemText
                                                    className={classes.subListText}
                                                    inset
                                                    primaryTypographyProps={{color: match.path.startsWith("/retailers/show/:id/feeds") ? "primary" : "secondary"}}
                                                    primary={<FormattedMessage id={"sidebar.item.feeds"}/>}
                                                />
                                            </ListItem>
                                            }
                                        </List>
                                    </Collapse>
                                </div>}
                            </div>}
                        </Collapse>
                        <DrawerConfiguration
                            classes={classes}
                            match={match}
                            authorizations={authorizations}
                        />
                    </div>
                }
                <DrawerRetailerProfiles classes={classes} />
                {(hasAuthorization(authorizations, Authorizations.OTHER_HELPDESK_MERCHAND) || hasAuthorization(authorizations, Authorizations.OTHER_HELPDESK_CLIENT)) &&
                    <DrawerHelp
                        classes={classes}
                        authorizations={authorizations}
                    />
                }
            </div>
        );
    }
    // END OF WARNING

    render() {
        const {classes, match, authorizations, member, retailerRetrieved} = this.props;
        const {offer, itemsPerPage} = this.state;
        let retailer = this.memberHasRetailer();

        return (
            <List className={classes.listContainerDrawer}>
                {/* Market Place */}
                {/* Market Place Retailer with a member, WARNING : this part is not supposed to be used and code has not been tested */}
                {(offer === marketPlaceOffer && retailer) && this.renderRetailers(retailer)}
                {/*END OF WARNING*/}
                {/* MarketPlace Admin */}
                {(offer === marketPlaceOffer && !retailer) &&
                    <MarketPlaceMenuAdmin
                        authorizations={authorizations}
                        classes={classes}
                        match={match}
                        getDashboardLink={this.getDashboardLink()}
                        retailerRetrieved = {retailerRetrieved}
                    />
                }

                {/* Stock Easy */}
                {(offer === stockEasyOffer) &&
                    <StockEasyMenu
                        authorizations={authorizations}
                        classes={classes}
                        match={match}
                        retailer={retailer}
                        member={member}
                    />
                }

                {/* Picking */}
                {offer === pickingOffer &&
                    <PickingMenu
                        authorizations={authorizations}
                        classes={classes}
                        match={match}
                        retailer={retailer}
                        dashboardLink={this.getDashboardLink()}
                        itemsPerPage={itemsPerPage}
                        currentOrganization={this.props.currentOrganization}
                    />
                }

                {/* statistics */}
                {offer === statisticsOffer &&
                <StatisticsMenu
                    authorizations={authorizations}
                    classes={classes}
                    match={match}
                    retailer={retailer}
                    dashboardLink={this.getDashboardLink()}
                    itemsPerPage={itemsPerPage}
                    currentOrganization={this.props.currentOrganization}
                />
                }
            </List>
        );
    }
}

const mapStateToProps = state => {
    return {
        member: state.authentication.member,
        authorizations: state.authentication.authorizations,
        organizations: state.authentication.organizations,
        retailerRetrieved: state.retailer.show.retrieved,
    }
};

export default connect(
    mapStateToProps,
    null
)(withStyles(sidebarStyle)(DrawerList));
