import React, {useContext} from "react";
import {injectIntl} from "react-intl";
import PropTypes from 'prop-types'
import {makeStyles} from "@mui/styles";

import {Dialog, DialogContent} from "@mui/material";
import RecommendationOverloadForm from "./RecommendationOverloadForm";
import {RecommendationContext} from "../context/RecommendationContext";

const useStyles = makeStyles({
    root: {
        paddingTop: '24px',
        paddingLeft: '9px',

    },
    paper: {
        boxShadow: '0px 4px 22px 0px rgba(0, 0, 0, 0.04)',
        position: "absolute",
        borderRadius: '8px',
        width: '40%',
        marginTop: '0px'
    },
    dialogContent: {
        paddingTop:'24px',
        paddingLeft: '9px',
        paddingRight: '9px',
        paddingBottom: '0px'
    }
})

function ModalRecommendationOverloadForm(props) {
    const classes = useStyles();
    const {open} = props;

    const { closeRecommendationOverloadModal } = useContext(RecommendationContext);

    return (
        <Dialog
            open={open}
            onClose={closeRecommendationOverloadModal}
            slotProps={{ backdrop: { style: { backgroundColor: 'rgba(51, 51, 51, 0.20)' } } }}
            classes={{ paper: classes.paper, root: classes.root }}
            maxWidth={false}
        >
            <DialogContent className={classes.dialogContent}>
                <RecommendationOverloadForm />
            </DialogContent>
        </Dialog>
    )
}

ModalRecommendationOverloadForm.propTypes = {
    open: PropTypes.bool.isRequired,
}

export default injectIntl(ModalRecommendationOverloadForm);
