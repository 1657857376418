import React, {useContext} from "react";
import StorefrontIcon from '@mui/icons-material/Storefront';
import NavTree from "views/marketplace/merchandising/nav/navTree";
import {ProductDataContext} from "contexts/productDataContext";

export default function RetailersNav(props) {

    const { retailersLoaded, retailersFilters } = useContext(ProductDataContext);
    const topNodeLabel = "merchandising.show.label.nav.retailers";
    const topNodeIcon = StorefrontIcon;

    return (
        <>
            {retailersLoaded && <NavTree 
                items={retailersFilters.tabContent.items}
                topItems={retailersFilters.tabContent.items}
                topNodeLabel={topNodeLabel}
                topNodeIcon={topNodeIcon}
                nodeId={props.nodeId}
                type={'Retailer'}
                {...props}
            />}
        </>
    );
}
