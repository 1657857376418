import * as Cookies from "js-cookie";
import Pusher from 'pusher-js';

class PusherClient {
    constructor() {
        const token = Cookies.get("_f") ? JSON.parse(Cookies.get("_f").toString()) : null;

        if(token) {
            this.pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
                cluster: "eu",
                forceTLS: true,
                authEndpoint: (new URL( '/pusher/auth', process.env.REACT_APP_API_ENTRYPOINT)).href,
                auth: {
                    headers: {
                        Authorization: 'Bearer ' + token
                    }
                }
            });

            this.subscribe = this.subscribe.bind(this);
        }
    }

    subscribe(channelName) {
        return this.pusher.subscribe('private-' + channelName);
    }
}
const pusherClient = new PusherClient();

export default pusherClient;
