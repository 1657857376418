import {useEffect, useState} from "react";
import {fetch} from "utils/dataAccess";
import TabFilterPanel from "views/marketplace/merchandising/filter/tabFilterPanel";

const Filter = (tabTitle, tabContent) => { return { tabTitle: tabTitle, tabContent: tabContent } }

export default function useCategories() {

    const [categoriesFilters, setCategoriesFilters] = useState(null);
    const [categoriesLoaded, setCategoriesLoaded] = useState(false);

    useEffect(() => {
        fetch("/categories?pagination=false&draft=false&translatedOrder[name]=asc").then(
            response => {
                response.json().then(retrieved => {

                    setCategoriesFilters(
                        new Filter(
                            "categories",
                            {
                                component: TabFilterPanel,
                                items: retrieved['hydra:member'],
                            }
                        )
                    );

                    setCategoriesLoaded(true);
                });
            }
        );
    }, []);

    return {
        categoriesLoaded,
        categoriesFilters
    };
}
