import {fetch} from "../../utils/dataAccess";

export function loading(loading) {
    return {type: "PICKING_DASHBOARD_DATA_LOADING", loading};
}

export function success(retrieved) {
    return {type: "PICKING_DASHBOARD_DATA_SUCCESS", retrieved};
}
export function error(error) {
    return {type: "PICKING_DASHBOARD_DATA_ERROR", error};
}

export function retrieveDashboardData() {
    return dispatch => {
        dispatch(loading(true));

        return fetch("/picking/dashboard", {method: "GET"})
            .then(response => {
                dispatch(loading(false));
                return response.json();
            })
            .then(function (data) {
                dispatch(success(data));
            })
            .catch(e => {
                dispatch(error(e));
                dispatch(loading(false));
            });
    };
}

export function reset() {
    return dispatch => {
        dispatch({type: "PICKING_DASHBOARD_DATA_RESET"});
    };
}
