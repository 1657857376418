import React from 'react';

function ActionContainer(props) {
    return (
        <div style={{
            display: 'flex',
            justifyContent: 'right',
            gap: '12px',
            marginRight: '16px',
            marginLeft: '16px',
            position: 'absolute',
            bottom: '16px',
            right: 0,
        }}>
            {props.children}
        </div>
    );
}

export default ActionContainer;
