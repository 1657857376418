import React, { useState } from "react";

// @mui/material components
import { IconButton } from "@mui/material";
import {makeStyles} from "@mui/styles";

// @mui/icons-material
import { MoreVert } from "@mui/icons-material";

// core components
import GlobalDeleteDialog from "./globalDeleteDialog";
import GlobalPopover from "../../configuration/components/globalPopover";

const useStyles = makeStyles(theme => ({
    iconButton: {
        "&:hover": {
            backgroundColor: "rgba(0, 53, 245, 0.08)"
        }
    },
}));

export default function GlobalAction (props) {
    const classes = useStyles();

    const { genders } = props;

    const [deleteDialog, showDeleteDialog] = useState(false);
    const [openPopover, setOpenPopover] = useState(false);
    const [position, setPosition] = useState(null);

    function openGlobalPopover(position) {
        setPosition(position);
        setOpenPopover(true);
    }

    return (
        <>
            <IconButton
                className={classes.iconButton}
                onClick={(e) => openGlobalPopover(e.currentTarget)}
                size={"small"}
            >
                <MoreVert />
            </IconButton>
            <GlobalPopover
                isPopoverOpen={openPopover}
                closePopover={() => setOpenPopover(false)}
                openDeleteDialog={() => showDeleteDialog(true)}
                position={position}
            />
            <GlobalDeleteDialog
                genders={genders}
                closeDialog={() => showDeleteDialog(false)}
                isOpen={deleteDialog}
            />
        </>
    );
}
