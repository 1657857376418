import {
    container,
    defaultFont,
    primaryColor,
} from "../../main";

const merchandisingStyle = theme => ({
    containerWithSidebar: {
        [theme.breakpoints.up("md")]: {
            marginLeft: "240px"
        }
    },
    container: {
        ...container,
        ...defaultFont
    },
    stocksLabels: {
        display: "flex",
        justifyContent: "flex-start",
        flexDirection: "row",
        flexWrap: "wrap"
    },
    stocksList: {
        overflowY: "auto",
        "&::-webkit-scrollbar": {
            WebkitAppearance: "none",
        },
        "&::-webkit-scrollbar:vertical" :{
            width: "11px",
        },
        "&::-webkit-scrollbar:horizontal": {
            height: "11px",
        },
        "&::-webkit-scrollbar-thumb": {
            borderRadius: "8px",
            border: "2px solid white", /* should match background, can"t be transparent */
            backgroundColor: "rgba(0, 0, 0, .5)",
        }
    },
    stockStatus :{
        display: "flex",
        justifyContent: "flex-start",
        flexDirection: "column",
        maxWidth: "160px",
        maxHeight: "200px"
    },
    productName: {
        fontWeight: "400",
        fontSize: "16px",
        display: "-webkit-box",
        "-webkit-line-clamp": 3,
        "-webkit-box-orient": "vertical",
        overflow: "hidden"
    },
    productInfos: {
        fontWeight: "300",
        fontSize: "12px"
    },
    'navigationIsInHierarchy': {
        color: primaryColor,
    },
});

export default merchandisingStyle
