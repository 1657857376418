import React from 'react'
import PropTypes from 'prop-types'

import SelectTitle from "components/radioGroup/SelectTitle";

import RecommendedProduct from './components/RecommendedProduct'

export default function RecommendedProductField(props) {
    const {formFieldName} = props

    return (
        <>
            <SelectTitle
                titleTranslationKey={'recommendations.form.recommended_product.title'}
                subtitleTranslationKey={'recommendations.form.recommended_product.subtitle'}
            />

            <RecommendedProduct
                formFieldName={formFieldName}
            />
        </>
    )
}

RecommendedProductField.propTypes = {
    formFieldName: PropTypes.string.isRequired,
}
