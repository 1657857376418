import React, {useEffect, useState} from 'react';

import Grid from '@mui/material/Grid';

import Page from 'components/page/Page';

import ProfileCard from './components/ProfileCard';

import {listProfiles} from 'api/retailer/list';

function Index() {
    const [retailers, setRetailers] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);

        listProfiles().then((response) => {
            setRetailers(response);
            setLoading(false);
        });
    }, [])

    return (
        <Page
            titleId="retailer.profile.list.title"
            subtitleId="retailer.profile.list.subtitle"
            loading={loading}
        >
            <Grid container spacing={2}>
                {retailers.map((retailer) => (
                    <Grid
                        key={retailer.id}
                        item
                        xs={12}
                        md={6}
                        lg={4}
                        xl={3}
                    >
                        <ProfileCard retailer={retailer} />
                    </Grid>
                ))}
            </Grid>
        </Page>
    );
}

export default Index;
