import {fetch} from "utils/dataAccess";
import {ITEM_PER_PAGE} from "config/_pagination";

export function error(error) {
    return {type: "CITY_LIST_ERROR", error};
}

export function loading(loading) {
    return {type: "CITY_LIST_LOADING", loading};
}

export function success(retrieved) {
    return {type: "CITY_LIST_SUCCESS", retrieved};
}

export function list(params = `order[name]=asc&pagination=true&itemsPerPage=${ITEM_PER_PAGE}&page=1`) {
    const url = "/cities?" + params;

    return dispatch => {
        dispatch(loading(true));
        dispatch(error(""));

        fetch(url)
            .then(response => response.json().then(retrieved => {
                dispatch(loading(false));
                dispatch(success(retrieved));
            }))
            .catch(e => {
                dispatch(loading(false));
                dispatch(error(e.message));
            });
    };
}

export function reset() {
    return dispatch => {
        dispatch({type: "CITY_LIST_RESET"});
    };
}

