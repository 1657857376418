import React from "react";
import connect from "react-redux/es/connect/connect";
import {FormattedMessage} from "react-intl";
import classNames from "classnames";
import * as moment from "moment";

// @mui/material components
import {Hidden, } from "@mui/material";
import {withStyles} from "@mui/styles";

// @mui/icons-material
import {RemoveCircleOutline, KeyboardArrowLeft} from "@mui/icons-material";

// core components
import Card from "../card/card";
import CardBody from "../card/cardBody";
import CardHeader from "../card/cardHeader";
import StickyNotificationForm from "./stickyNotificationForm";

// styles
import stickyNotificationStyle from "../../assets/jss/components/notification/stickyNotificationStyle";

// actions
import {list} from "../../actions/physicalEntry/list";
import {create} from "../../actions/physicalEntry/create";
import {update} from "../../actions/physicalEntry/update";

class StickyNotification extends React.Component {
    state = {
        date: moment(),
        right: false,
        toDestroy: false,
        destroyed: false
    };

    componentDidMount() {
        setTimeout(this.toggleDrawer("right", true), 1000);
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.created) {
            this.setState({destroyed: true});
        }
    }

    toggleDrawer = (side, open) => () => {
        this.setState({
            [side]: open,
        });
    };

    handleDateChange = (date) => {
        this.setState({date: date});
        this.props.list(`retailer.id=${decodeURIComponent(this.props.match.params.id)}&date=${date.format("Y-M-D")}`)
    };

    handleCreate = (values) => {
        this.setState({toDestroy: true}, function() {
            this.props.create(values);
        });
    };

    render() {
        const {classes} = this.props;

        const cardClasses = classNames({
            [classes.card]: true,
            [classes.cardHidden]: !this.state.right,
            [classes.cardDestroyed]: this.state.toDestroy

        });

        let item = {retailer: this.props.retailer && this.props.retailer["@id"], author: "/members/" + this.props.member["id"], date: this.state.date};
        let isUpdate = false;

        if (this.props.listRetrieved && this.props.listRetrieved["hydra:totalItems"] !== 0) {
            item = this.props.listRetrieved["hydra:member"][0];
            isUpdate = true;
        }

        if (this.props.updated) {
            item = this.props.updated;
            isUpdate = true;
        }

        return (
            <Hidden smDown implementation="css">
                {!this.state.destroyed &&
                <Card className={cardClasses}>
                    <CardHeader className={classes.cardHeader}>
                        {this.state.right && <div onClick={this.toggleDrawer("right", false)} className={classes.cardHeaderCloseBtn}><RemoveCircleOutline/></div>}
                        <div className={classes.cardHeaderTitle}><FormattedMessage id={"stickyNotification.message.title"}/></div>
                    </CardHeader>
                    {this.state.right ?
                        <CardBody className={classes.cardBody}>
                            <FormattedMessage id={"stickyNotification.message.body"}/>
                            <StickyNotificationForm
                                onSubmit={values => isUpdate ? this.props.update(item, values) : this.handleCreate(values)}
                                handleDateChange={this.handleDateChange}
                                initialValues={item}
                            />
                        </CardBody> :
                        <CardBody className={classes.cardBodyClosed} onClick={this.toggleDrawer("right", !this.state.right)}>
                            <KeyboardArrowLeft className={classes.cardHeaderOpenBtn} fontSize={"large"}/>
                        </CardBody>
                    }
                </Card>
                }
            </Hidden>
        );
    }
}

const mapStateToProps = state => {
    return {
        listRetrieved: state.physicalEntry.list.retrieved,
        listRetrieveLoading: state.physicalEntry.list.loading,
        updated: state.physicalEntry.update.updated,
        created: state.physicalEntry.create.created,
        retailer: state.retailer.show.retrieved,
        member: state.authentication.member
    }
};

const mapDispatchToProps = dispatch => ({
    create: (values) => dispatch(create(values)),
    update: (item, values) => dispatch(update(item, values)),
    list: params => dispatch(list(params))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(stickyNotificationStyle)(StickyNotification));
