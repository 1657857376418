import React from "react";
import {FormattedMessage} from "react-intl";
import {withStyles} from "@mui/styles";
import AuthenticationStyle from "../../../../assets/jss/views/authentication/authenticationStyle";

const CompleteProfileForm = ({classes}) => {
  return (
    <>
      <h1 className={classes.h1}>
        <FormattedMessage id={"authentication.invitation.completeProfile"}/>
      </h1>
    </>
  );
};

export default withStyles(AuthenticationStyle)(CompleteProfileForm);
