import React from "react";

import {withStyles} from "@mui/styles";
import classNames from "classnames";
import '@ckeditor/ckeditor5-build-classic/build/translations/fr';

import {getIn} from 'formik';

import fieldStyle from "../../../assets/jss/views/formik/fieldStyle";
import NumberFormat from "react-number-format";

class PercentageInputRenderer extends React.Component {
    static supports(props) {
        return 'percentage' === (props.as ?? props.type);
    }

    render() {
        const {name, formikContext, classes, fieldClassName} = this.props;
        const errors = formikContext.errors;
        const fieldIsInError = getIn(errors, name);
        const value = getIn(formikContext.values, name);

        const fieldClasses = classNames({
            [" " + classes.field]: true,
            [" " + classes.fieldInvalid]: !!fieldIsInError && formikContext.touched,
            [" " + (fieldClassName ?? '')]: true,
        });

        const tmpProps = {...this.props};
        delete tmpProps.containerClassName;
        delete tmpProps.fieldClassName;
        return (
            <NumberFormat
                value={value}
                displayType={"input"}
                onValueChange={(value) => formikContext.setFieldValue(name, value.floatValue)}
                className={fieldClasses}
                suffix={" %"}
                {...tmpProps}
            />
        );
    }
}

export default withStyles(fieldStyle)(PercentageInputRenderer);
