import React from "react";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";

import {secondsToReadableTime} from "utils/secondsToReadableTime";

export default function AverageProcessTime({seconds}) {
    const time = secondsToReadableTime(seconds);

    const formatMinutes = (minutes) => {
        if (minutes < 10) {
            minutes = `0${minutes}`
        }

        return minutes;
    }

    if (seconds === 0) {
        return (
            <FormattedMessage id={"dashboard.ope.na"} />
        )
    }

    if (seconds < 60) {
        return (
            <>1 <FormattedMessage id={"dashboard.ope.minutes"} /></>
        )
    }

    return (
        <span>
            {time.days > 0 && (<>{time.days}<FormattedMessage id={"dashboard.ope.days"} /> </>)}
            {time.hours > 0 && (<> {time.hours}<FormattedMessage id={"dashboard.ope.hours"} /></>)}
            {time.min > 0 &&(<> {formatMinutes(time.min)}<FormattedMessage id={"dashboard.ope.minutes"} /></>)}
        </span>
    )
}

AverageProcessTime.defaultProps = {
    seconds: 0
}
AverageProcessTime.propTypes = {
    seconds: PropTypes.number,
};

