import DrawerDashboard from "../../../components/sidebar/lineItems/DrawerDashboard";
import {ListItem, ListItemIcon, ListItemText} from "@mui/material";
import {Link} from "react-router-dom";
import {ShoppingCartOutlined, StoreOutlined} from "@mui/icons-material";
import {FormattedMessage} from "react-intl";
import {Authorizations, hasAuthorization} from "../../../utils/authorizations";
import DrawerConfiguration from "../../../components/sidebar/lineItems/DrawerConfiguration";
import DrawerHelp from "../../../components/sidebar/lineItems/DrawerHelp";
import React, {Component} from "react";
import DrawerPwa from "../../../components/sidebar/lineItems/DrawerPwa";
import DrawerAccounting from "../../../components/sidebar/lineItems/DrawerAccounting";
import DrawerQuote from "../../../components/sidebar/lineItems/DrawerQuote";
import DrawerDashboardQuotes from "../../../components/sidebar/lineItems/DrawerDashboardQuotes";
import DrawerRetailerProfiles from "../../../components/sidebar/lineItems/DrawerRetailerProfiles";
import DrawerPrinters from "../../../components/sidebar/lineItems/DrawerPrinters";

class PickingMenu extends Component {
    render() {
        const {authorizations, classes, match, retailer, dashboardLink, itemsPerPage} = this.props;

        const isInDashboardPath = match.path === "/" || (match.path === "/retailers/show/:id" && retailer) || match.path === "/picking";

        const isInDashboardQuotesPath = match.path === "/picking/statistics/quotes";

        return (
            <div>
                <DrawerDashboard
                    classes={classes}
                    dashboardLink={dashboardLink}
                    isInDashboardPath={isInDashboardPath}
                />

                {/*Orders Item*/}
                <ListItem
                    button
                    className={classes.listItem}
                    classes={{selected: classes.selected}}
                    component={Link}
                    to={`/picking/orders/${encodeURIComponent(`order[createdAt]=asc&status=pending&itemsPerPage=${itemsPerPage}&page=1`)}`}
                    key={"productList"}
                    selected={match.path.startsWith("/picking/orders")}
                >
                    <ListItemIcon>
                        <ShoppingCartOutlined color={match.path.startsWith("/picking/orders") ? "primary" : "secondary"} />
                    </ListItemIcon>
                    <ListItemText
                        primaryTypographyProps={{color: match.path.startsWith("/picking/orders") ? "primary" : "secondary"}}
                        primary={<FormattedMessage id={"sidebar.item.orders"}/>}
                    />
                </ListItem>

                {/*Shops Item*/}
                {!hasAuthorization(authorizations, Authorizations.PICKING_RETAILER) &&
                  <ListItem
                      button
                      className={classes.listItem}
                      classes={{selected: classes.selected}}
                      component={Link}
                      to={`/picking/retailers/${encodeURIComponent(`order[name]=asc&pagination=true&itemsPerPage=99999&retailer_has_order=true`)}`}
                      key={"retailerList"}
                      selected={match.path.startsWith('/picking/retailers/')}
                  >
                      <ListItemIcon>
                          <StoreOutlined color={match.path.startsWith("/picking/retailers/") ? "primary" : "secondary"} />
                      </ListItemIcon>
                      <ListItemText
                          primaryTypographyProps={{color: match.path.startsWith('/picking/retailers/') ? "primary" : "secondary"}}
                          primary={<FormattedMessage id={"sidebar.item.retailers"}/>}
                      />
                  </ListItem>
                }

                {hasAuthorization(this.props.authorizations, Authorizations.PICKING_RETAILER) && retailer && (
                    <ListItem
                        button
                        className={classes.listItem}
                        classes={{selected: classes.selected}}
                        component={Link}
                        to={`/picking${retailer}/order/${encodeURIComponent(`retailer.id=${retailer}&itemsPerPage=${itemsPerPage}&page=1&pagination=true&status[1]=pending&status[2]=reserved&order[status]=asc&order[createdAt]=asc`)}`}
                        key={"pickingProductList"}
                        selected={match.path.startsWith('/picking/retailers/')}
                    >
                        <ListItemIcon>
                            <StoreOutlined
                                color={match.path.startsWith("/picking/retailers/") ? "primary" : "secondary"}
                            />
                        </ListItemIcon>
                        <ListItemText
                            primaryTypographyProps={{color: match.path.startsWith('/picking/retailers/') ? "primary" : "secondary"}}
                            primary={<FormattedMessage id={"sidebar.item.products"}/>}/>
                    </ListItem>
                )}

                {hasAuthorization(authorizations, Authorizations.PICKING_RETAILER_QUOTE) && (
                    <DrawerQuote classes={classes}/>
                )}

                {hasAuthorization(authorizations, Authorizations.PICKING_STATISTICS_QUOTES) &&
                    <DrawerDashboardQuotes
                        classes={classes}
                        dashboardQuotesLink={'/picking/statistics/quotes'}
                        isInDashboardQuotesPath={isInDashboardQuotesPath}
                    />
                }

                {hasAuthorization(authorizations, Authorizations.PICKING_PRINTERS)
                    && this.props.currentOrganization?.printNodeKey !== null && (
                    <DrawerPrinters classes={classes} />
                )}

                <DrawerConfiguration
                    classes={classes}
                    match={match}
                    authorizations={authorizations}
                />

                {(
                    (hasAuthorization(authorizations, Authorizations.GLOBAL_ORGANIZATION_ACCOUNTING_MANAGEMENT)) ||
                    (hasAuthorization(authorizations, Authorizations.GLOBAL_ORGANIZATION_ESHOP_MANAGEMENT)) ||
                    (hasAuthorization(authorizations, Authorizations.PICKING_ACCOUNTING))) &&
                        <DrawerAccounting classes={classes} path={'/picking/accounting'} />
                }

                {(hasAuthorization(authorizations, Authorizations.OTHER_HELPDESK_MERCHAND) || hasAuthorization(authorizations, Authorizations.OTHER_HELPDESK_CLIENT)) &&
                  <DrawerHelp
                    classes={classes}
                    authorizations={authorizations}
                  />
                }
                <DrawerRetailerProfiles classes={classes} />
                <DrawerPwa classes={classes} />
            </div>
        )
    }
}

export default PickingMenu;
