import React, {Component} from "react";
import {Field, reduxForm, change} from "redux-form";
import PropTypes from "prop-types";
import connect from "react-redux/es/connect/connect";
import {FormattedMessage} from "react-intl";
import Dropzone from "react-dropzone";
import Select from 'react-select';

// @mui/material components
import {CircularProgress} from "@mui/material";

// @mui/icons-material
import {CloudUpload} from "@mui/icons-material";

// core components
import Input from "components/input/input";
import GridContainer from "components/grid/gridContainer";
import GridItem from "components/grid/gridItem";
import ActionsContainer from "components/form/ActionsContainer";
import CancelButton from "../components/CancelButton";
import SubmitButton from "../components/SubmitButton";

// styles
import formStyle from "assets/jss/views/feed/formStyle";
import {withStyles} from "@mui/styles";

// actions
import {handleFileUpload} from "actions/feed/upload";

class Form extends Component {
    static propTypes = {
        handleSubmit: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            filename: null,
        };
    }

    componentDidMount() {
        if (this.props.uploaded) {
            this.setState({filename: this.props.uploaded.contentUrl})
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.uploaded) {
            this.setState({filename: nextProps.uploaded.contentUrl})
        }
    }

    renderField = data => {
        const {classes} = this.props;

        const filedName = data.input.name;

        return (
            <div className={classes.fieldContainer}>
                <label
                    htmlFor={`csvLocalFeed_${data.input.name}`}
                    className={classes.fieldLabel}
                >
                    {data.label}
                </label>
                {data.type === "checkbox" &&
                <input
                    {...data.input}
                    type={data.type}
                    step={data.step}
                    required={data.required}
                    placeholder={data.placeholder}
                    id={`csvLocalFeed_${data.input.name}`}
                    className={classes.fieldFloatRight}
                    checked={data.input.value}
                />
                }
                {(data.options && data.type === "select") &&
                <Select
                    id={`csvLocalFeed_${data.name}_select`}
                    name={`${data.name}_select`}
                    options={data.options}
                    value={this.state[data.input.name] || {"label":data.input.value, "value": data.input.value} }
                    onChange={(value) => {
                        this.props.dispatch(change("csvLocalFeed", data.input.name, value.value));
                        this.setState({[data.input.name]: value});
                    }}
                    onBlurResetsInput={false}
                />
                }
                {(data.type !== "select" && data.type !== "checkbox") &&
                <Input
                    {...data.input}
                    type={data.type}
                    step={data.step}
                    required={data.required}
                    id={`csvLocalFeed_${data.input.name}`}
                    inputRootCustomClasses={classes.input}
                    inputProps={{
                        placeholder: data.placeholder,
                        type: data.type,
                        required: data.required,
                        ...data.input,
                        autoComplete: data.autoComplete
                    }}
                    formControlProps={{
                        className: classes.formControl
                    }}
                />
                }
                {this.props.createError && this.props.createError.errors && this.props.createError.errors[filedName] && <span className={classes.errorMessages}><FormattedMessage id={this.props.createError.errors[filedName]}/></span>}
            </div>
        );
    };

    render() {
        const {classes} = this.props;

        const renderDropzoneInput = (field) => {
            return (
                <div className={classes.fieldContainer}>
                    <label
                        htmlFor={`csvLocalFeed_${field.input.name}`}
                        className={classes.fieldLabel}
                    >
                        <FormattedMessage id={"feed.form.label.file.csv"}/>
                    </label>
                    <Dropzone
                        className={classes.fileUploadContainer}
                        name={field.input.name}
                        accept={[".csv", ".xls", ".xlsx"]}
                        multiple={false}
                        onDrop={(filesToUpload) => this.props.upload(filesToUpload)}
                    >
                        {({getRootProps, getInputProps}) => {
                            return (
                                <div
                                    {...getRootProps()}
                                >
                                    <input id={`csvLocalFeed_${field.input.name}`} name={field.input.name} {...getInputProps()} />
                                    {
                                        this.state.filename ?
                                            <div className={classes.fieldUpload}>{this.state.filename}</div>
                                            :
                                            <div className={classes.fieldUpload}>{this.props.uploadLoading ?
                                                <CircularProgress size={24}/> : <CloudUpload/>}
                                                <div className={classes.fileUploadText}><FormattedMessage
                                                    id={"feed.form.file.upload"}/></div>
                                            </div>

                                    }
                                </div>
                            )
                        }}
                    </Dropzone>
                    {this.props.createError && this.props.createError.errors && this.props.createError.errors[field.input.name] && <span className={classes.errorMessages}><FormattedMessage id={this.props.createError.errors[field.input.name]}/></span>}
                </div>
            );
        };

        return (
            <form onSubmit={this.props.handleSubmit}>
                <GridContainer className={this.props.classes.formContainer}>
                    <GridItem xs={12} sm={6}>
                        <Field
                            component={this.renderField}
                            name={"name"}
                            label={<FormattedMessage id={"feed.form.label.name"}/>}
                            type={"text"}
                            required
                        />
                        <Field
                            type={"select"}
                            component={this.renderField}
                            options={[{"label":",", "value":","}, {"label":";", "value":";"}, {"label":"|", "value":"|"}, {"label":"Tabulation", "value":"\t"}]}
                            name={"configuration['separator']"}
                            label={<FormattedMessage id={"feed.form.label.separator"}/>}
                            selected={{"label":",", "value":","}}
                            required
                        />
                        <Field
                            type={"select"}
                            component={this.renderField}
                            options={[{"label":"'", "value":"'"}, {"label":"\"", "value":"\""}, {"label":"|", "value":"|"}]}
                            name={"configuration['enclosure']"}
                            label={<FormattedMessage id={"feed.form.label.enclosure"}/>}
                            selected={{"label":"\"", "value":"\""}}
                            required
                        />
                    </GridItem>
                    <GridItem xs={12} sm={6}>
                        <Field
                            component={this.renderField}
                            name={"disableVariationNotSeenInFeed"}
                            label={<FormattedMessage id={"feed.form.label.disable.not.seen"}/>}
                            type={"checkbox"}
                        />
                        <Field
                            component={this.renderField}
                            name={"configuration['noHeaderCSV']"}
                            label={<FormattedMessage id={"feed.form.label.no.header"}/>}
                            type={"checkbox"}
                        />
                        <Field name={"configuration['path']"} component={renderDropzoneInput}/>
                        <Field
                            component={this.renderField}
                            label={<FormattedMessage id={"feed.form.label.skipRule"} />}
                            name={"skipRule"}
                            type={"text"}
                        />
                    </GridItem>
                </GridContainer>
                <ActionsContainer>
                    <CancelButton retailerId={this.props.retailer && this.props.retailer['@id']} />
                    <SubmitButton loading={this.props.createLoading || this.props.updateLoading} />
                </ActionsContainer>
            </form>
        );
    }
}

const mapStateToProps = state => {
    return {
        createLoading: state.feed.create.loading,
        createError: state.feed.create.error,
        updateLoading: state.feed.update.updateLoading,
        uploaded: state.feed.upload.uploaded,
        uploadLoading: state.feed.upload.loading,
        uploadError: state.feed.upload.error,
        retailer: state.retailer.show.retrieved
    };
};

const mapDispatchToProps = dispatch => ({
    upload: (file) => dispatch(handleFileUpload(file)),
});

export default reduxForm({
    form: "csvLocalFeed",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
}, mapStateToProps)(connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(formStyle)(Form)));
