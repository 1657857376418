import React from 'react';
import PropTypes from "prop-types";

import {BeatLoader} from "react-spinners";

BeatLoaderMini.propTypes = {
    loading: PropTypes.bool
};

export default function BeatLoaderMini(props) {
    return (
        <BeatLoader
            sizeUnit={"px"}
            size={4}
            color={"#FFF"}
            loading={props.loading}
        />
    );
}
