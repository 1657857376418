import React, {Component} from "react";
import {withStyles} from "@mui/styles";

// Components
import CardHeader from "../../../../components/card/cardHeader";
import GridContainer from "../../../../components/grid/gridContainer";
import GridItem from "../../../../components/grid/gridItem";
import CardBody from "../../../../components/card/cardBody";
import Card from "../../../../components/card/card";

import listStyle from "../../../../assets/jss/views/quote/listStyle";

import QuoteGridContent from "./gridContent";
import QuoteGridPagination from "./gridPagination";
import QuoteStateSwitcher from "./stateSwitcher";
import PaginationContainer from "../../../../components/pagination/paginationContainer";

class QuoteListGrid extends Component {
    render() {
        const { classes, loading, quotes, history, counts, itemsPerPage, retrieved } = this.props;

        return (
            <GridContainer>
                <GridItem xs={12}>
                    <Card>
                        <CardHeader
                            className={classes.cardHeader}
                            color="info"
                        >
                            <QuoteStateSwitcher
                                history={history}
                                counts={counts}
                                loading={loading}
                                startDate={this.props.startDate}
                                endDate={this.props.endDate}
                            />
                        </CardHeader>
                        <CardBody className={classes.cardBody}>
                            <QuoteGridContent
                                history={history}
                                loading={loading}
                                quotes={quotes}
                            />
                            <GridContainer justifyContent={"space-between"} className={classes.footer}>
                                <GridItem md={4}>
                                    <QuoteGridPagination
                                        history={history}
                                    />
                                </GridItem>
                                <GridItem md={4} className={classes.pagination}>
                                    <PaginationContainer
                                        itemsPerPage={itemsPerPage}
                                        urlParams={history.location.search}
                                        retrieved={retrieved}
                                        encodeURI={false}
                                    />
                                </GridItem>
                                <GridItem md={4}></GridItem>
                            </GridContainer>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        );
    }
}

export default withStyles(listStyle)(QuoteListGrid);
