import { fetch } from "../../utils/dataAccess";
import {getCurrentOrganizationId} from "../../utils/dataAccessOrganizationHandler";
import hydrator from "./hydrator";

export function cornerList(params = "enabled=true") {
    return new Promise((resolve, reject) => {
        params += '&organization.id=' + getCurrentOrganizationId()
        fetch(`/corners?${params}&pagination=false`)
            .then(response => {
                response.json().then(response => {
                    const items = response["hydra:member"];

                    resolve(items.map(cornerItem => hydrator(cornerItem)))
                })
            })
            .catch(error => reject(error));
    });
}
