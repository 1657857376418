import React from "react";

const QuoteDetailHeaderInfosAddress = ({ address }) => {
    return (
        <span>
            {!!address.company && (
                <>
                    {address.company}<br/>
                </>
            )}
            {address.street}<br/>
            {address.postalCode} {address.city}<br/>
            {!!address.provinceName && (
                <>
                    {address.provinceName + ' '}
                </>
            )}
            {address.country}
        </span>
    );
};

export default QuoteDetailHeaderInfosAddress;
