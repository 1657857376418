import {fetch} from "../../utils/dataAccess";
import {updateSuccess as productUpdateSuccess} from "../product/update";
import {updateSuccess as variationUpdateSuccess} from "../variation/update";
import {ITEM_PER_PAGE} from "config/_pagination";
import {paramsToObject} from "../../utils/paramsToObject";

export function error(error) {
    return {type: "VARIATION_LIST_ERROR", error};
}

export function loading(loading) {
    return {type: "VARIATION_LIST_LOADING", loading};
}

export function success(retrieved) {
    return {type: "VARIATION_LIST_SUCCESS", retrieved};
}

export function exportLoading(loading) {
    return {type: "VARIATION_LIST_EXPORT_LOADING", loading};
}

export function exportSuccess(success) {
    return {type: "VARIATION_LIST_EXPORT_SUCCESS", success};
}

export function list(params = `order[translations.name]=asc&itemsPerPage=${ITEM_PER_PAGE}&page=1`) {
    return dispatch => {
        dispatch(loading(true));

        fetch("/variations?" + params).then(response => {
            response.json().then(retrieved => {
                dispatch(loading(false));
                dispatch(success(retrieved));
            }).catch(() => {
                dispatch(loading(false));
            });
        }).catch(error => {
            // canceled
        });
    }
}

export function downloadExport(retailer, uriParams) {
    let params = '';
    uriParams = paramsToObject(uriParams);
    Object.keys(uriParams).forEach(function (uriParam) {
        switch (uriParam) {
            case 'categories.id':
                uriParams[uriParam].substring(1, uriParams[uriParam].length-1).split(',').forEach((category) => {
                    params += `&categories[]=${category}`
                });

                break;
            case 'color.id':
                uriParams[uriParam].substring(2, uriParams[uriParam].length-2).split('","').forEach((color) => {
                    params += `&colors[]=${color}`
                });

                break;
            case 'brand':
                params += `&brands[]=${uriParams[uriParam].substring(2, uriParams[uriParam].length-2)}`;

                break;
            case 'gender.id':
                uriParams[uriParam].substring(2, uriParams[uriParam].length-2).split('","').forEach((category) => {
                    params += `&genders[]=${category}`
                });
                break;
            case 'size.id':
                uriParams[uriParam].substring(2, uriParams[uriParam].length-2).split('","').forEach((category) => {
                    params += `&sizes[]=${category}`
                });
                break;
            case 'status':
                params += `&status[]=${uriParams[uriParam]}`;

                break;
            case 'validated':
                params += `&validated[]=${uriParams[uriParam]}`;

                break;
            case 'outletPrice[between]':
                params += `&outletPrice[between]=${uriParams[uriParam]}`;

                break;
            case 'stock[gt]':
                params += `&stock[gt]=${uriParams[uriParam]}`;

                break;
            case 'stock[lte]':
                params += `&stock[lte]=${uriParams[uriParam]}`;

                break;
            case 'lastSeenInFeedAt[before]':
                params += `&lastSeenInFeedAt[before]=${uriParams[uriParam]}`;

                break;
            default:
                // This is a little refactoring, some unsupported field may actually pass here
                // I do not want to make the existing code fail. But if you see anything like that you may want to fix it.
                console.log(`Unexpected field ${uriParam}, please consider a fix.`);
        }
    });

    return dispatch => {
        dispatch(exportLoading(true));

        fetch("/variations/export?retailer=" + retailer.id + params, {method: 'GET'}).then(response => {
            response.json().then(() => {
                dispatch(exportLoading(false));
                dispatch(exportSuccess(true));
            }).catch(error => {
                dispatch(exportLoading(false));
                dispatch(exportSuccess(false));
            })
        }).catch(error => {
            // canceled
        });
    }
}

export function reset() {
    return dispatch => {
        dispatch({type: "VARIATION_LIST_RESET"});
        dispatch(variationUpdateSuccess(null));
        dispatch(productUpdateSuccess(null));
        dispatch(exportSuccess(null));
        dispatch(loading(false));
    };
}
