import React from 'react';
import {styled} from '@mui/material/styles';
import {borderGrayColor} from 'assets/jss/main';
import {UserAgentUtils} from 'utils/useragent';

const StyledDiv = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '48px',
    alignSelf: 'stretch',
    width: 'calc(100% - 319px)',
    paddingRight: '30px',
    borderRight: `1px solid ${borderGrayColor}`,
});

export default function GridComment({...props}) {
    if (UserAgentUtils.isMobile()) {
        return props.children;
    }

    return (
        <StyledDiv>
            {props.children}
        </StyledDiv>
    );
}
