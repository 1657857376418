import React from "react";
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import {FormattedMessage} from "react-intl";

export default function AutocompleteItemList(props) {

    const {setSelectedValue, inputLabel, ...others} = props;

    const handleChange = (event, newValue) => {
        props.setSelectedValue(newValue)
    };

    return (
        <Autocomplete
            disableCloseOnSelect
            renderOption={(props, option, { selected }) => (
                <li {...props} value={option.id}>
                    <Checkbox
                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                        style={{ marginRight: 8 }}
                        checked={selected}
                        value={option.id}
                    />
                    {option.label}
                </li>
            )}
            renderInput={(params) => (
                <TextField 
                    label={<FormattedMessage id={inputLabel}/>}
                    {...params}
                />
            )}
            onChange={handleChange}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            {...others}
        />
    );
}
