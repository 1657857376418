import React, {Component} from "react";
import connect from "react-redux/es/connect/connect";
import {FormattedMessage} from "react-intl";

// @mui/material components
import {Badge, Fab, Tab, Tabs, Tooltip, } from "@mui/material";
import {withStyles} from "@mui/styles";

// @mui/icons-material
import {AddRounded, NotificationsActive} from "@mui/icons-material";

// core components
import GridContainer from "components/grid/gridContainer";
import GridItem from "components/grid/gridItem";
import Card from "components/card/card";
import CardHeader from "components/card/cardHeader";
import CardBody from "components/card/cardBody";
import AllMembersList from "./allMembersList";
import InviteDialog from "./components/inviteDialog";
import InvitationList from "./invitationList";
import Snackbar from "components/snackbar/snackbar";
import InvitationRequestList from "./requestList";

// styles
import memberStyle from "assets/jss/views/member/memberStyle";

// actions
import {reset, send} from "actions/member/invite";
import {requestCount} from "actions/member/list";
import {update} from "actions/member/update";

class Members extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentTab: 0,
            memberFormDialog: false,
            memberSelected: null,
            inviteNotification: true,
            deleteMemberNotification: true,
        };
    }

    componentDidMount() {
        this.props.requestCount();
    }

    UNSAFE_componentWillMount() {
        this.props.reset()
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.invited !== nextProps.invited) {
            this.setState({currentTab: 1})
        }

        if((this.props.updated !== nextProps.updated) || (this.props.deleted !== nextProps.deleted)) {
            this.props.requestCount();
        }
    };

    handleChange = (event, value) => {
        this.setState({currentTab: value})
    };

    renderTabsContent() {
        const {currentTab} = this.state;
        const {history, match} = this.props;

        switch (currentTab) {
            case 0:
                return <AllMembersList
                    match={match}
                    history={history}
                    openMemberFormDialog={(selectedMember) => this.openMemberFormDialog(selectedMember)}
                />;

            case 1:
                return <InvitationList match={match} history={history}/>;

            default:
                return <InvitationRequestList match={match} history={history}/>;
        }
    }

    openMemberFormDialog(selectedMember = null) {
        this.setState({
            selectedMember: selectedMember,
            memberFormDialog: true
        });
    }

    closeMemberFormDialog() {
        this.setState({
            memberFormDialog: false,
            selectedMember: null,
        });
    }

    send = (values) => {
        const data = {
            "email": values.email,
            "roles": values.roles.map(role => role.value ? role.value : role["@id"]),
            "retailers": values.retailers ? values.retailers.map(retailer => retailer.value ? retailer.value : retailer && retailer["@id"]) : []
        }

        if (this.state.selectedMember !== null) {
           this.props.update(this.state.selectedMember, data);
        } else {
            this.props.send(data);
        }

        this.closeMemberFormDialog();
    };

    render() {
        const {classes, invited, inviteLoading, requestListCount} = this.props;
        const {inviteNotification, memberFormDialog, currentTab} = this.state;

        return (
            <div className={classes.containerWithSidebar}>
                <div className={classes.container}>
                    {invited && (
                        <Snackbar
                            open={inviteNotification}
                            close
                            closeNotification={() => this.setState({inviteNotification: false})}
                            place={"bl"}
                            color={invited.message === "user.invite.sent" ? "success" : "danger"}
                            icon={() => <NotificationsActive/>}
                            message={<FormattedMessage id={invited.message}/>}
                        />
                    )}
                    <GridContainer>
                        <GridItem xs={12}>
                            <Card className={classes.card}>
                                <CardHeader color="info">
                                    <GridContainer>
                                        <GridItem xs={9} sm={10}>
                                            <h3><FormattedMessage id={"member.title"}/></h3>
                                        </GridItem>
                                        <GridItem xs={3} sm={2} className={classes.mainActionsContainer}>
                                            <Fab onClick={() => this.openMemberFormDialog()} color={"primary"}>
                                                <Tooltip title={<FormattedMessage id={"member.add.member"}/>}>
                                                    <AddRounded fontSize={"large"}/>
                                                </Tooltip>
                                            </Fab>
                                        </GridItem>
                                    </GridContainer>
                                </CardHeader>
                                <CardBody>
                                    <div className={classes.root}>
                                        <Tabs
                                            value={currentTab}
                                            onChange={this.handleChange}
                                            className={classes.tabsBorder}
                                            indicatorColor="primary"
                                        >
                                            <Tab label={<FormattedMessage id={"member.tab.all.member"}/>}/>
                                            <Tab label={<FormattedMessage id={"member.tab.invitation.sent"}/>}/>
                                            <Tab label={
                                                <Badge
                                                    classes={{root: classes.badgePadding, badge: classes.badgeColor}}
                                                    badgeContent={requestListCount && requestListCount}
                                                >
                                                    <FormattedMessage id={"member.tab.invitation.received"}/>
                                                </Badge>
                                            }/>
                                        </Tabs>
                                    </div>
                                    {this.renderTabsContent()}
                                </CardBody>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </div>
                {memberFormDialog &&
                    <InviteDialog
                        openDialog={memberFormDialog}
                        closeDialog={() => this.closeMemberFormDialog()}
                        submitLoading={inviteLoading}
                        submitAction={this.send}
                        member={this.state.selectedMember}
                    />
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        invited: state.member.invite.invited,
        updated: state.member.update.updated,
        inviteLoading: state.member.invite.loading,
        requestListCount: state.member.list.requestCountRetrieved
    };
};

const mapDispatchToProps = dispatch => ({
    reset: () => dispatch(reset()),
    send: (values) => dispatch(send(values)),
    requestCount: (params) => dispatch(requestCount(params)),
    update: (item, values) => dispatch(update(item, values))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(memberStyle)(Members));
