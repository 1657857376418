import {fetch} from "utils/dataAccess";

export default async function ship(order, values)  {
    const response = await fetch(`/orders/${order.id}/ship`, {
        method: "POST",
        body: JSON.stringify(values),
    });

    return await response.json();
}
