import React from 'react';

export default function LabeledSwitchContainer(props) {
    return (
        <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginRight:' 25px'
        }}>
            {props.children}
        </div>
    );
}
