import React from "react";
import PropTypes from "prop-types";
import {injectIntl} from "react-intl";

import {createTheme} from "@mui/material/styles";

import CommentWillAppearMessage from "../Messages/CommentWillAppearMessage";
import Header from "../NoteBlock/Header";
import HistoryNotes from "../HistoryNotes";
import HistoryNoteDetail from "../HistoryNotes/HistoryNoteDetail";
import LastNoteContent from "../NoteBlock/LastNoteContent";
import NoteBlock from "../NoteBlock";
import NoteCardActions from "../NoteBlock/NoteCardActions";
import NoteCardContent from "../NoteBlock/NoteCardContent";

import {infoColor} from "assets/jss/main";

import {QUOTE_COMMENT} from "../../../../../enums/quoteNoteType";

CommentNote.propTypes = {
    comments: PropTypes.arrayOf(PropTypes.object).isRequired
};

function CommentNote({comments = [], ...props}) {
    const { intl } = props;

    if (comments.length === 0) {
        return false;
    }

    const titleWithVersion = (version) => (
        `${intl.formatMessage({id: 'picking.quote.history.comment'})} ${version}`
    )

    const commentTheme = createTheme({
        palette: { primary: { main: infoColor } },
    });

    const lastComment = comments[0];
    const commentHistory = comments.slice(1);

    return (
        <NoteBlock theme={commentTheme}>
            <NoteCardContent>
                <Header
                    date={lastComment.createdAt}
                    subtitle={lastComment.member?.user.fullName}
                    title={titleWithVersion(comments.length)}
                    noteType={QUOTE_COMMENT}
                    centerContent={<CommentWillAppearMessage />}
                />
                <LastNoteContent content={lastComment.comment} />
            </NoteCardContent>
            {commentHistory.length > 0 && (
                <NoteCardActions>
                    <HistoryNotes noteType={QUOTE_COMMENT}>
                        {commentHistory.map((comment, index) => {
                            const version = commentHistory.length - index;

                            return (
                                <HistoryNoteDetail
                                    key={index}
                                    date={comment.createdAt}
                                    subtitle={comment.member?.user.fullName}
                                    title={titleWithVersion(version)}
                                    content={comment.comment}
                                />
                            )
                        })}
                    </HistoryNotes>
                </NoteCardActions>
            )}
        </NoteBlock>
    )
}

export default injectIntl(CommentNote);
