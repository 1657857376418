class Abort {
    constructor() {
        const abortController = new AbortController();

        this.abortRequests = this.abortRequests.bind(this, abortController);
        this.getAbortController = this.getAbortController.bind(this, abortController);
    }

    getAbortController = () => {
        if (!this.abortController) {
            this.abortController = new AbortController();
        }

        if (this.abortController.signal.aborted) {
            this.abortRequests();
        }
        
        return this.abortController;
    }

    abortRequests() {
        if (this.abortController) {
            this.abortController.abort();
        }
        
        this.abortController = new AbortController();
    }
}

const abort = new Abort();

export default abort;
