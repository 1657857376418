import React from "react";
import PropTypes from 'prop-types';
import {FormattedMessage} from "react-intl";

import CardStatsFooter from "./cards/cardStatsFooter";
import CardStatsTitle from "./cards/cardStatsTitle";
import CardStatsValue from "./cards/cardStatsValue";
import CardStatsBody from "./cards/cardStatsBody";
import CardStats from "./cards/cardStats";
import Loader from "./loader";

export default function OrderCount(props) {
    const { isRetailer, physicalOrdersCount, onlineOrdersCount, loading } = props

    return (
        <CardStats>
            <Loader loading={loading} />
            <CardStatsTitle>
                <FormattedMessage id={"dashboard.card.title.orders"} />
            </CardStatsTitle>
            <CardStatsBody>
                <CardStatsValue>
                    {(physicalOrdersCount + onlineOrdersCount).toLocaleString()}
                </CardStatsValue>
            </CardStatsBody>
            {!isRetailer && (
                <CardStatsFooter>
                    <div>
                        <span>
                            <FormattedMessage id={"dashboard.card.info.online"} />
                        </span>
                        {onlineOrdersCount.toLocaleString()}
                    </div>
                    <div>
                    <span>
                        <FormattedMessage id={"dashboard.card.info.physical"} />
                    </span>
                        {physicalOrdersCount.toLocaleString()}
                    </div>
                </CardStatsFooter>
            )}
        </CardStats>
    )
}

OrderCount.defaultProps = {
    onlineOrdersCount: 0,
    physicalOrdersCount: 0
}

OrderCount.propTypes = {
    loading: PropTypes.bool,
    onlineOrdersCount: PropTypes.number.isRequired,
    physicalOrdersCount:  PropTypes.number.isRequired
};
