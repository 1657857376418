import React, {Component} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {PropagateLoader} from "react-spinners";

// @mui/material components
import {
    AppBar,
    Grid,
    Tab,
    Tabs,
} from "@mui/material";

// @mui/icons-material
import { NotificationsActive } from "@mui/icons-material";

// core components
import Snackbar from "components/snackbar/snackbar";
import MiniCardImage from "./components/miniCardImage";
import MiniCardExpansion from "./components/miniCardExpansion";
import Card from "components/card/card";
import Form from "./components/form";
import CreateVariationForm from "./components/createVariationForm";
import {FormattedMessage, injectIntl} from "react-intl";
import classNames from "classnames";

// styles
import showStyle from "assets/jss/views/product/showStyle";
import 'assets/css/slick.css';
import {primaryColor} from "assets/jss/main";

// actions
import { retrieve, reset } from "actions/product/show";
import { update as updateProduct, reset as resetProduct } from "actions/product/update";
import { update as updateVariation, reset as resetVariation} from "actions/variation/update";
import { update as updateProductLock} from "actions/product/lock/update";
import { update as updateVariationLock} from "actions/variation/lock/update";
import { create as createProductLock, reset as resetCreateProductLock} from "actions/product/lock/create";
import { create as createVariationLock, reset as resetCreateVariationLock} from "actions/variation/lock/create";
import { handleFileUpload, removeFile, reset as resetUpload} from "actions/upload";
import {reset as resetCreateVariation} from "actions/variation/create";
import {cornerList} from "../../../../api/corner/list";
import categoriesList from "api/category/list";
import brandList from "api/brand/list";
import colorList from "api/color/list";
import sizeList from "api/size/list";
import genderList from "api/gender/list";
import taxRatesList from "api/taxRates/list";
import PriceChart from "./components/priceChart";
import {create as createVariationMediaObject, reset as resetVariationMediaObject} from "actions/variation/variationMediaObject";
import {capitalize} from "../../../../utils/capitalize";
import {getTranslation} from "../../../../domain/helpers/translations";
import {withStyles} from "@mui/styles";
import {Authorizations, hasAuthorization} from "../../../../utils/authorizations";

class Show extends Component {
    static propTypes = {
        retrieved: PropTypes.object,
        retrieve: PropTypes.func.isRequired,
        reset: PropTypes.func.isRequired,
        resetProduct: PropTypes.func.isRequired,
        resetVariation: PropTypes.func.isRequired,
        updateProduct: PropTypes.func.isRequired,
        updateProductLock: PropTypes.func.isRequired,
        createProductLock: PropTypes.func.isRequired,
        updateVariation: PropTypes.func.isRequired,
        updateVariationLock: PropTypes.func.isRequired,
        createVariationLock: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            errorNotification: true,
            productId: null,
            selectedOption: null,
            currentType: null,
            uploadOrRemoveImageUpdateForm: true,
            showCreateVariationForm: false,
            notificationCreateSuccess: false,
            activeTab: 0,
        };

        this.variationFormToFocus = React.createRef()
    }

    componentDidMount() {
        const isRetailer = hasAuthorization(this.props.authorizations, Authorizations.PICKING_RETAILER);
        this.props.retrieve(decodeURIComponent(this.props.match.params.prid) + '?partial=true');
        categoriesList().then(categories => {
            let options = categories.map((key) => {
                return {"value": key["@id"], "label": getTranslation(key).name, translations: key.translations};
            });

            this.setState({
                categoryList: options.sort((a, b) =>
                    a.label.localeCompare(b.label)
                )
            }, () => this.setSelectedCategories())
        })
        brandList().then(brands => {
            let options = brands.map((key) => {
                return {"value": key["@id"], "label": capitalize(key.name.toLowerCase())};
            });

            this.setState({
                brandList: options.sort((a, b) =>
                    a.label.localeCompare(b.label)
                )
            }, () => this.setSelectedBrand())
        })
        colorList().then(colors => {
            let options = colors.map((key) => {
                return {"value": key["@id"], "label": getTranslation(key).name, translations: key.translations};
            });

            this.setState({
                colorList: options.sort((a, b) =>
                    a.label.localeCompare(b.label)
                )
            }, () => this.setSelectedColor())
        })
        sizeList().then(sizes => {
            let options = sizes.map((key) => {
                return {"value": key["@id"], "label": getTranslation(key).name, translations: key.translations};
            });

            this.setState({
                sizeList: options.sort((a, b) =>
                    a.label.localeCompare(b.label)
                )
            }, () => this.setSelectedSize())
        })
        genderList().then(genders => {
            let options = genders.map((key) => {
                return {"value": key["@id"], "label": getTranslation(key).value, translations: key.translations};
            });

            this.setState({
                genderList: options.sort((a, b) =>
                    a.label.localeCompare(b.label)
                )
            }, () => this.setSelectedGender())
        })
        taxRatesList().then(taxRates => {
            let options = taxRates.map((key) => {
                return {"value": key["@id"], "label": key.value + "%"};
            });

            this.setState({
                taxRateList: options.sort((a, b) =>
                    a.label.localeCompare(b.label)
                )
            }, () => this.setSelectedTaxRate())
        })
        cornerList(`enabled=true&order[name]=asc${isRetailer ? '&public=true' : ''}`).then(corners => {
            let options = corners.map((corner) => {
                return {"value": corner["@id"], "label": getTranslation(corner).name, translations: corner.translations};
            });

            this.setState({
                cornerList: options.sort((a, b) =>
                    a.label.localeCompare(b.label)
                ),
            }, () => this.setSelectedCorners())
        })
        this.setState({productId: decodeURIComponent(this.props.match.params.prid)});
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContent) {
        // variationCreated
        if (nextProps.createVariationSuccess !== null) {
            window.scrollTo(0, 0);
            this.props.reset();
            this.props.resetCreateVariation();

            this.setState({
                notificationCreateSuccess: true,
                showCreateVariationForm: false,
                productId: null,
                currentProduct: null,
                currentVariation: null,
                currentType: null,
                variations: null
            });

            this.props.retrieve(decodeURIComponent(this.props.match.params.prid));
        }

        // productLock created
        if (nextProps.createProductLockSuccess) {
            let tmp_product = this.state.currentProduct;
            tmp_product.productLock = nextProps.createProductLockSuccess;

            this.setState({currentProduct: tmp_product});

            return;
        }

        // variationLock created
        if (nextProps.createVariationLockSuccess) {
            let tmp_variation = this.state.currentVariation;
            tmp_variation.variationLock = nextProps.createVariationLockSuccess;

            this.setState({currentVariation: tmp_variation});

            return;
        }

        if (nextProps.removeSuccess && this.state.uploadOrRemoveImageUpdateForm) {
            const {currentVariation, variations} = this.state;
            currentVariation.pictures = this.state.currentVariation.pictures
                .map(key => key["@id"] !== nextProps.removeSuccess ? key : null)
                .filter(element => element !== null);

            const variationIndex = variations.findIndex(variation => variation.sku === currentVariation.sku);

            if (variationIndex !== -1) {
                variations[variationIndex].pictures = currentVariation.pictures;
                this.setState({currentVariation: currentVariation, variations: variations});
                this.props.resetUpload();
            }
        }

        if (nextProps.uploadSuccess && this.state.uploadOrRemoveImageUpdateForm) {
            this.props.createVariationMediaObject(
                this.state.currentVariation['@id'],
                nextProps.uploadSuccess["@id"],
                this.state.currentVariation.pictures.length+1
            );
            this.props.resetUpload();

            return;
        }

        this.setState({errorNotification: true});

        // on loading do nothing
        if (nextProps.updateVariationLoading || nextProps.updateProductLoading ) {
            return false;
        }

        // has error on update variation or product
        if ((this.state.currentType === "variation" && nextProps.updateVariationError) ||
            (this.state.currentType !== "variation" && nextProps.updateProductError )) {
            this.setState({notificationError: true});
            this.setState({notificationSuccess: false});
            return;
        }

        // has success on update variation or product
        if ((this.state.currentType === "variation" && nextProps.updateVariationSuccess) ||
            (this.state.currentType !== "variation" && nextProps.updateProductSuccess )) {
            this.setState({notificationError: false});
            this.setState({notificationSuccess: true});
        }

        if (nextProps.variationMediaObjectSuccess) {
            const {currentVariation, variations} = this.state;

            currentVariation.pictures.push(nextProps.variationMediaObjectSuccess);

            const variationIndex = variations.findIndex(variation => variation.sku === currentVariation.sku);

            if (variationIndex !== -1) {
                variations[variationIndex].pictures = currentVariation.pictures;
                this.props.resetVariationMediaObject();
                this.setState({currentVariation: currentVariation, variations: variations});
            }
        }

        // on retrieve refresh variations array
        let updated = (!nextProps.updateVariationLoading || !nextProps.updateProductLoading) &&
            (nextProps.updateVariationSuccess || nextProps.updateProductSuccess) &&
            (typeof nextProps.updateVariationSuccess === "object" || typeof nextProps.updateProductSuccess === "object" || typeof nextProps.variationMediaObjectSuccess === "object")
        ;

        if (nextProps.retrieved || updated) {
            let variations;

            if (!updated && !this.state.variations) {
                variations = nextProps.retrieved.variations;
                this.setState({variations: variations});
                this.setState({currentVariation: JSON.parse(JSON.stringify(nextProps.retrieved.variations[0]))});
            } else {
                variations = this.state.variations;

                if (this.state.currentType === "variation" && nextProps.updateVariationSuccess) {
                    this.setSelectedColor();
                    this.setSelectedSize();

                    let currentVariation = nextProps.updateVariationSuccess;

                    // replace old variation by the updated one
                    Object.keys(variations).forEach(function(key) {
                        if(variations[key]["id"] === currentVariation.id){
                            variations[key] = currentVariation;

                        }
                    });

                    this.setState({variations: variations});

                }
            }

            // calculate stock and lower price
            let stock_variation = 0;
            let lower_price = 0;
            let lower_outlet_price = 0;
            let product_status = false;

            Object.keys(variations).forEach(function(key) {
                    product_status = (!(variations[key].status === false && !product_status));
                    stock_variation += variations[key].stock;
                    lower_price = (variations[key].price < lower_price || lower_price === 0)? variations[key].price : lower_price;
                    lower_outlet_price = (variations[key].outletPrice < lower_outlet_price || lower_outlet_price === 0)? variations[key].outletPrice : lower_outlet_price;
                }
            );

            if (updated) {
                if (this.state.currentType !== "variation") {
                    nextProps.updateProductSuccess.stock = stock_variation;
                    nextProps.updateProductSuccess.price = lower_price;
                    nextProps.updateProductSuccess.outletPrice = lower_outlet_price;
                    nextProps.updateProductSuccess.status = product_status;

                    this.setState({currentProduct: nextProps.updateProductSuccess}, () => {
                        this.setSelectedCategories();
                        this.setSelectedCorners();
                        this.setSelectedBrand();
                        this.setSelectedTaxRate();
                        this.setSelectedGender();
                    });
                } else {
                    let tmp_product = this.state.currentProduct;
                    tmp_product.stock = stock_variation;
                    tmp_product.price = lower_price;
                    tmp_product.outletPrice = lower_outlet_price;
                    tmp_product.status = product_status;

                    this.setState({currentProduct: tmp_product});
                }
            } else {
                let tmp_product = nextProps.retrieved;
                tmp_product.stock = stock_variation;
                tmp_product.price = lower_price;
                tmp_product.outletPrice = lower_outlet_price;
                tmp_product.status = product_status;

                // to make the form works, we need add at least one entry
                const additionalMappingFields = null !== tmp_product.retailer ? tmp_product.retailer.additional_mapping_fields : null;
                if (additionalMappingFields) {
                    Object.keys(tmp_product.translations).map(locale => {
                        let localExecuted = false;
                        if (tmp_product.translations[locale].additionalAttributes.length <= 0) {
                            Object.keys(additionalMappingFields).forEach(key => {
                                if (!localExecuted) {
                                    tmp_product.translations[locale].additionalAttributes = {};
                                    tmp_product.translations[locale].additionalAttributes[key] = '';
                                    localExecuted = true;
                                }
                            });
                        }
                    })
                }

                this.setState({currentProduct: tmp_product}, () => {
                    this.setSelectedCategories();
                    this.setSelectedCorners();
                    this.setSelectedBrand();
                    this.setSelectedTaxRate();
                    this.setSelectedGender();
                });
            }

            if (!updated && !this.state.currentType) {
                this.setState({currentType: "parent"});
            }
        }
    }

    normalize(data) {
        let nextData = {};

        if (data && Array.isArray(data)) {
            data.map(item => {
                return nextData = Object.assign({[item.sku]: item}, nextData);
            });
        }

        return nextData;
    }

    setSelectedColor = () => {
        if (this.state.currentType === "variation" && this.state.colorList && this.state.currentVariation) {
            let selectedColor = [];

            //format colorList
            if (this.state.currentVariation.color) {
                selectedColor.push({"value": this.state.currentVariation.color["@id"], "label": this.state.currentVariation.color.name, translations: this.state.currentVariation.color.translations});
            }

            this.setState({selectedColorOption : selectedColor});
        }
    };

    setSelectedSize = () => {
        if (this.state.currentType === "variation" && this.state.sizeList && this.state.currentVariation) {
            let selectedSize = [];

            if (this.state.currentVariation.size) {
                selectedSize.push({"value": this.state.currentVariation.size["@id"], "label": this.state.currentVariation.size.name, translations: this.state.currentVariation.size.translations});
            }

            this.setState({selectedSizeOption : selectedSize});
        }
    };

    setSelectedGender = () => {
        if (this.state.currentType !== "variation" && this.state.genderList && this.state.currentProduct) {
            let selectedGender = [];

            if (this.state.currentProduct.gender) {
                selectedGender.push({"value": this.state.currentProduct.gender["@id"], "label": this.state.currentProduct.gender.value, translations: this.state.currentProduct.gender.translations});
            }

            this.setState({selectedGenderOption : selectedGender});
        }
    };

    setSelectedBrand = () => {
        if (this.state.currentType !== "variation" && this.state.brandList && this.state.currentProduct) {

            let selectedBrand = [];

            //format brandList
            if (this.state.currentProduct.brand) {
                selectedBrand.push({"value": this.state.currentProduct.brand["@id"], "label": capitalize(this.state.currentProduct.brand.name.toLowerCase())});
            }

            this.setState({selectedBrandOption : selectedBrand});
        }
    };

    setSelectedTaxRate = () => {
        if (this.state.currentType !== "variation" && this.state.taxRateList && this.state.currentProduct) {
            let selectedTaxRate = [];

            //format taxRateList
            if (this.state.currentProduct.taxRate) {
                selectedTaxRate.push({"value": this.state.currentProduct.taxRate["@id"], "label": this.state.currentProduct.taxRate.value + "%"});
            }

            this.setState({selectedTaxRateOption : selectedTaxRate});
        }
    };

    setSelectedCategories = () => {
        if (this.state.currentType !== "variation" && this.state.categoryList && this.state.currentProduct) {

            let selectedCategories = [];

            this.state.categoryList.forEach((key) => {
                if (this.state.currentProduct.categories.filter(function (a) { return a["@id"] === key.value }).length) {
                    selectedCategories.push(key);
                }
            });

            this.setState({selectedCategoriesOption : selectedCategories});
        }
    };

    setSelectedCorners = () => {
        if (this.state.currentType !== "variation" && this.state.cornerList && this.state.currentProduct) {
            let selectedCorners = [];
            this.state.cornerList.forEach((key) => {
                if (this.state.currentProduct.corners.filter(function (a) { return a["@id"] === key.value }).length) {
                    selectedCorners.push(key);
                }
            });

            this.setState({selectedCornersOption : selectedCorners});
        }
    }

    onVariationChange = (nextVariationSku) => {
        const currentVariation = this.state.variations.find(variation => variation.sku === nextVariationSku);

        this.setState({currentVariation: currentVariation}, () => {
            this.setSelectedColor();
            this.setSelectedSize();
        });

        this.setState({currentType: "variation"})
    };

    onProductTypeChange = () => {
        this.setState({currentType: "parent"})
    };

    handleTabChange = (event, newValue) => {
        this.setState({activeTab: newValue})
    }

    componentWillUnmount() {
        this.props.reset();
        this.props.resetVariation();
        this.props.resetProduct();
        this.props.resetUpload();
        this.props.resetCreateProductLock();
        this.props.resetCreateVariationLock();
        this.props.resetCreateVariation();
        this.setState({
            errorNotification: true,
            productId: null,
            selectedOption: null,
            currentProduct: null,
            currentVariation: null,
            currentType: null,
            variations: null
        });
    }

    // need to create 2 specifics function because upload image on create variation view
    // trigger nextProps.uploadSuccess on line 122
    // and this trigger is specific for product/variation update form
    // so I added a bool state to make the diff
    uploadImageCreateForm(file, type, object_id, position) {
        this.setState({uploadOrRemoveImageUpdateForm: false })
        this.props.handleFileUpload(file, type, object_id, position)
    }

    uploadImageUpdateForm(file, type, object_id, position) {
        this.setState({uploadOrRemoveImageUpdateForm: true })
        this.props.handleFileUpload(file, type, object_id, position)
    }

    // same thing with removeImage function
    removeImageCreateForm(file) {
        this.setState({uploadOrRemoveImageUpdateForm: false })
        this.props.removeFile(file)
    }

    removeImageUpdateForm(file) {
        this.setState({uploadOrRemoveImageUpdateForm: true })
        this.props.removeFile(file)
    }

    scrollToCreateVariationForm = () => {
        this.setState({showCreateVariationForm: true}, () => {
            //.current is verification that your element has rendered
            if (this.variationFormToFocus.current) {
                this.variationFormToFocus.current.scrollIntoView({
                    behavior: "smooth",
                    block: "nearest"
                });
            }
        })
    }

    showProductForm() {
        this.setState({
            showCreateVariationForm: false
        }, () =>  window.scrollTo(0, 0))
    }

    resetErrors() {
        this.setState({notificationError: null});
        this.props.resetUpload();
    }

    renderForm() {
        if (this.state.showCreateVariationForm === false) {
            if (this.state.currentType === "variation") {
                return (
                    <Form
                        onSubmit={
                            values => this.props.updateVariation(this.state.currentVariation, values)
                        }
                        currentType={this.state.currentType}
                        currentProduct={this.state.currentProduct}
                        initialValues={this.state.currentVariation}
                        colorList={this.state.colorList}
                        selectedColor={this.state.selectedColorOption}
                        sizeList={this.state.sizeList}
                        selectedSize={this.state.selectedSizeOption}
                        upload={(file, type, object_id, position) => this.uploadImageUpdateForm(file, type, object_id, position)}
                        removeFile={(file) => this.removeImageUpdateForm(file)}
                        retailer={decodeURIComponent(this.props.match.params.id).split("/retailers/").pop()}
                        updateVariationLock={this.props.updateVariationLock}
                        createVariationLock={this.props.createVariationLock}
                        currentPage={this.props.location.currentPage}
                        currentOrderBy={this.props.location.currentOrderBy}
                        type={this.props.location.type}
                    />

                )
            } else {
                return (
                    <Form
                        categoryList={this.state.categoryList}
                        selectedCategories={this.state.selectedCategoriesOption}
                        cornerList={this.state.cornerList}
                        selectedCorners={this.state.selectedCornersOption}
                        brandList={this.state.brandList}
                        genderList={this.state.genderList}
                        selectedBrand={this.state.selectedBrandOption}
                        selectedGender={this.state.selectedGenderOption}
                        taxRateList={this.state.taxRateList}
                        onSubmit={values => {
                            this.props.updateProduct(this.state.currentProduct, values)
                        }}
                        selectedTaxRate={this.state.selectedTaxRateOption}
                        updateProductLock={this.props.updateProductLock}
                        createProductLock={this.props.createProductLock}
                        currentType={this.state.currentType}
                        currentProduct={this.state.currentProduct}
                        initialValues={this.state.currentProduct}
                        retailer={decodeURIComponent(this.props.match.params.id).split("/retailers/").pop()}
                        currentPage={this.props.location.currentPage}
                        currentOrderBy={this.props.location.currentOrderBy}
                        type={this.props.location.type}
                    />
                );
            }
        } else if (
            !!this.state.currentVariation
            && !!this.state.variations
            && !!this.state.currentProduct
            && this.state.showCreateVariationForm
        ) {
            return (
                <div ref={this.variationFormToFocus}>
                    <CreateVariationForm
                        productId={this.state.currentProduct['@id']}
                        variationsList={this.props.retrieved.variations}
                        colorList={this.state.colorList}
                        sizeList={this.state.sizeList}
                        retailer={decodeURIComponent(this.props.match.params.id).split("/retailers/").pop()}
                        handleFileUpload={(file, type, object_id, position) => this.uploadImageCreateForm(file, type, object_id, position)}
                        removeFile={(file) => this.removeImageCreateForm(file)}
                        uploadOrRemoveImageUpdateForm={this.state.uploadOrRemoveImageUpdateForm}
                        showProductForm={() => this.showProductForm()}
                    />
                </div>
            )
        }
    }

    render() {
        const {classes} = this.props;

        let classNameFirst = classNames({
            [classes.card]: true,
            [classes.cardTop]: true
        });

        let classContainerLoading = classNames({
            [classes.container]: true,
            [classes.containerInLoading]: true,
        });

        return (
            <div>
                <div className={classes.containerWithSidebar}>
                    <div className={classes.container}>
                    {(this.state.notificationError || this.props.uploadError) && (
                        <Snackbar
                            autoHideDuration={5000}
                            onClose={() => this.resetErrors()}
                            open={this.props.uploadError || this.state.notificationError}
                            close
                            closeNotification={() => this.resetErrors()}
                            place={"bl"}
                            color={"danger"}
                            icon={function () {
                                return <NotificationsActive/>
                            }}
                            message={<FormattedMessage id={this.props.uploadError ? this.props.uploadError  : "product.form.message.error"}/>}
                        />
                    )}
                    {this.state.notificationSuccess && (
                        <Snackbar
                            open={this.state.notificationSuccess}
                            autoHideDuration={3000}
                            onClose={() => this.setState({notificationSuccess: null})}
                            close
                            closeNotification={() => this.setState({notificationSuccess: null})}
                            place={"bl"}
                            color={"success"}
                            icon={function () {
                                return <NotificationsActive/>
                            }}
                            message={<FormattedMessage id={"product.form.message.success"}/>}
                        />
                    )}
                    {this.state.notificationCreateSuccess && (
                        <Snackbar
                            open
                            autoHideDuration={3000}
                            onClose={() => this.setState({notificationCreateSuccess: false})}
                            place={"bl"}
                            color={"success"}
                            icon={() => <NotificationsActive />}
                            message={<FormattedMessage id={"variation.form.message.success"}/>}
                        />)
                    }
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={3}>
                                <Card className={classNameFirst}>
                                    <MiniCardImage
                                        onProductTypeChange={this.onProductTypeChange}
                                        variations={this.state.variations}
                                        currentProduct={this.state.currentProduct}
                                        currentType={this.state.currentType}
                                        stock={this.state.currentProduct && this.state.currentProduct.stock}
                                        price={this.state.currentProduct && this.state.currentProduct.price}
                                    />
                                </Card>
                                <Card className={classes.card}>
                                    <MiniCardExpansion
                                        data={this.state.variations}
                                        variationData={this.props.location.variation ? this.props.location.variation : false}
                                        currentVariation={this.state.currentVariation}
                                        currentType={this.state.currentType}
                                        onVariationChange={this.onVariationChange}
                                        scrollToCreateVariationForm={this.scrollToCreateVariationForm}
                                        disabled={this.props.uploadLoading || this.props.variationMediaObjectLoading}
                                    />
                                </Card>
                            </Grid>
                            {!!this.state.currentVariation && !!this.state.variations ?
                                <Grid item xs={12} sm={9}>
                                    <AppBar position="static" color="default" className={classes.appBar}>
                                        <Tabs
                                            value={this.state.activeTab}
                                            onChange={this.handleTabChange}
                                            indicatorColor="primary"
                                            textColor="primary"
                                            aria-label="scrollable auto tabs"
                                        >
                                            <Tab
                                                label={this.props.intl.formatMessage({id: 'product.show.tab.information'})}
                                                id="simple-tab-0"
                                                aria-controls="simple-tabpanel-0"
                                            />
                                            {(this.state.currentVariation && this.state.currentType === "variation" && !this.state.showCreateVariationForm) &&
                                                <Tab
                                                    label={this.props.intl.formatMessage({id: 'product.show.tab.priceAndStockHistory'})}
                                                    id="simple-tab-1"
                                                    aria-controls="simple-tabpanel-1"
                                                />
                                            }
                                        </Tabs>
                                    </AppBar>
                                    <div role="tabpanel" hidden={this.state.activeTab !== 0} id={`simple-tabpanel-${0}`} aria-labelledby={`simple-tab-${0}`}>
                                        <Card className={classes.card}>
                                            <Grid item xs={12}>
                                                {this.renderForm()}
                                            </Grid>
                                        </Card>
                                    </div>

                                    {(this.state.currentVariation && this.state.currentType === "variation" && !this.state.showCreateVariationForm) &&
                                    <div
                                        role="tabpanel"
                                        hidden={this.state.activeTab !== 1}
                                        id={`simple-tabpanel-${1}`}
                                        aria-labelledby={`simple-tab-${1}`}
                                        >
                                            <Card className={classes.card}>
                                                <Grid item xs={12}>
                                                    <PriceChart
                                                        currentVariation={this.state.currentVariation}
                                                    />
                                                </Grid>
                                            </Card>
                                        </div>
                                    }
                                </Grid>:
                                <Grid item xs={12} sm={9}>
                                    <Card className={classes.card}>
                                        <Grid item xs={12}>
                                            <div className={classContainerLoading}>
                                                <div className={classes.propagateLoader}>
                                                    <PropagateLoader
                                                        sizeUnit={"px"}
                                                        size={16}
                                                        color={primaryColor}
                                                        loading={true}
                                                    />
                                                </div>
                                            </div>
                                        </Grid>
                                    </Card>
                                </Grid>
                            }
                        </Grid>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    retrieved: state.product.show.retrieved,
    brandList: state.brand.list.brandList,
    genderList: state.gender.list.genderList,
    colorList: state.color.list.colorList,
    sizeList: state.size.list.sizeList,
    error: state.product.show.error,
    loading: state.product.show.loading,
    updateVariationLoading: state.variation.update.updateLoading,
    updateVariationSuccess: state.variation.update.updateSuccess,
    updateVariationError: state.variation.update.updateError,
    variationMediaObjectSuccess: state.variation.variationMediaObject.success,
    variationMediaObjectLoading: state.variation.variationMediaObject.loading,
    updateProductLoading: state.product.update.updateLoading,
    updateProductSuccess: state.product.update.updateSuccess,
    updateProductError: state.product.update.updateError,
    uploadError: state.upload.error,
    uploadSuccess: state.upload.success,
    uploadLoading: state.upload.loading,
    removeSuccess: state.upload.removeSuccess,
    createVariationLockSuccess: state.variationLock.createLock.createSuccess,
    createProductLockSuccess: state.productLock.createLock.createSuccess,
    createVariationSuccess: state.variation.create.success,
    authorizations: state.authentication.authorizations,
});

const mapDispatchToProps = dispatch => ({
    retrieve: id => dispatch(retrieve(id)),
    reset: () => dispatch(reset()),
    resetProduct: () => dispatch(resetProduct()),
    resetVariation: () => dispatch(resetVariation()),
    resetUpload: () => dispatch(resetUpload()),
    resetCreateProductLock: () => dispatch(resetCreateProductLock()),
    resetCreateVariationLock: () => dispatch(resetCreateVariationLock()),
    updateProduct: (item, values) => dispatch(updateProduct(item, values)),
    updateVariation: (item, values) => dispatch(updateVariation(item, values)),
    createProductLock: (item, values) => dispatch(createProductLock(item, values)),
    updateProductLock: (item, values) => dispatch(updateProductLock(item, values)),
    updateVariationLock: (item, values) => dispatch(updateVariationLock(item, values)),
    createVariationLock: (item, values) => dispatch(createVariationLock(item, values)),
    handleFileUpload: (file, type, object_id, position) => dispatch(handleFileUpload(file, type, object_id, position)),
    removeFile: (file) => dispatch(removeFile(file)),
    resetCreateVariation: () => dispatch(resetCreateVariation()),
    createVariationMediaObject: (variationId, mediaObjectId, position) => dispatch(createVariationMediaObject(variationId, mediaObjectId, position)),
    resetVariationMediaObject: () => dispatch(resetVariationMediaObject()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(showStyle)(injectIntl(Show)));
