import React from "react";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { styled } from '@mui/material/styles';

const StyledArrowBackIcon = styled(ArrowBackIosIcon)(({ theme }) => ({
    transform: 'rotate(-45deg)',
    fontSize: 14,
    color: "rgba(0, 0, 0, .125)"
}));

export default function LastChildIcon() {
    return (
        <StyledArrowBackIcon />
    );
}
