import React from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';

import {useFormikContext} from 'formik';

import Grid from '@mui/material/Grid';

import Label from 'components/input/new/Label';
import InputTextField from 'components/input/new/InputTextField';

import FormBloc from './components/FormBloc';

function BillForm({intl}) {
    const {values, handleChange, handleBlur, touched, errors} = useFormikContext();
    
    return (
        <FormBloc titleId="retailer.profile.edit.form.title.bill">
           <Grid container rowSpacing={3}>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <Label htmlFor="paymentAccount.tradeName">
                                <FormattedMessage id="retailer.profile.edit.form.field.paymentAccount.tradeName" />
                            </Label>
                            <InputTextField
                                name="paymentAccount.tradeName"
                                placeholder={intl.formatMessage({id: "retailer.profile.edit.form.field.paymentAccount.tradeName.placeholder"})}
                                value={values.paymentAccount.tradeName}
                                onChange={handleChange}
                                error={touched.paymentAccount?.tradeName && Boolean(errors.paymentAccount?.tradeName)}
                                helperText={touched.paymentAccount?.tradeName && errors.paymentAccount?.tradeName}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Label htmlFor="paymentAccount.siret">
                                <FormattedMessage id="retailer.profile.edit.form.field.paymentAccount.siret" />
                            </Label>
                            <InputTextField
                                name="paymentAccount.siret"
                                placeholder={intl.formatMessage({id: "retailer.profile.edit.form.field.paymentAccount.siret.placeholder"})}
                                value={values.paymentAccount.siret}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.paymentAccount?.siret && Boolean(errors.paymentAccount?.siret)}
                                helperText={touched.paymentAccount?.siret && errors.paymentAccount?.siret}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Label htmlFor="paymentAccount.vatNumber">
                                <FormattedMessage id="retailer.profile.edit.form.field.paymentAccount.vatNumber" />
                            </Label>
                            <InputTextField
                                name="paymentAccount.vatNumber"
                                placeholder={intl.formatMessage({id: "retailer.profile.edit.form.field.paymentAccount.vatNumber.placeholder"})}
                                value={values.paymentAccount.vatNumber}
                                onChange={handleChange}
                                error={touched.paymentAccount?.vatNumber && Boolean(errors.paymentAccount?.vatNumber)}
                                helperText={touched.paymentAccount?.vatNumber && errors.paymentAccount?.vatNumber}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Label htmlFor="paymentAccount.email">
                                <FormattedMessage id="retailer.profile.edit.form.field.paymentAccount.email" />
                            </Label>
                            <InputTextField
                                name="paymentAccount.email"
                                placeholder={intl.formatMessage({id: "retailer.profile.edit.form.field.paymentAccount.email.placeholder"})}
                                value={values.paymentAccount.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.paymentAccount?.email && Boolean(errors.paymentAccount?.email)}
                                helperText={touched.paymentAccount?.email && errors.paymentAccount?.email}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Label htmlFor="paymentAccount.address">
                                <FormattedMessage id="retailer.profile.edit.form.field.paymentAccount.address" />
                            </Label>
                            <InputTextField
                                name="paymentAccount.address"
                                placeholder={intl.formatMessage({id: "retailer.profile.edit.form.field.paymentAccount.address.placeholder"})}
                                value={values.paymentAccount.address}
                                onChange={handleChange}
                                error={touched.paymentAccount?.address && Boolean(errors.paymentAccount?.address)}
                                helperText={touched.paymentAccount?.address && errors.paymentAccount?.address}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </FormBloc>
    );
}

export default injectIntl(BillForm);
