import React from 'react'
import classNames from "classnames";

import makeStyles from "@mui/styles/makeStyles";

import TableCell from "@mui/material/TableCell";

import {defaultFont} from "assets/jss/main";

const useStyle = makeStyles({
    tableCell: {
        ...defaultFont,
        lineHeight: "1.42857143",
        verticalAlign: "middle",
        borderBottom: "none",
        "&:first-child": {
            borderTop: "none",
        },
    }
})
export default function StyledTableCell({className, children}) {
    const classes = useStyle();

    return (
        <TableCell className={classNames(className, classes.tableCell)}>
            {children}
        </TableCell>
    )
}
