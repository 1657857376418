import React, {useContext} from "react";
import PropTypes from "prop-types";
import {injectIntl} from "react-intl";
import {useFormikContext} from "formik";

import RadioGroup from "components/radioGroup/RadioGroup";
import FormControlLabel from "components/radioGroup/FormControlLabel";
import SelectTitle from "components/radioGroup/SelectTitle";

import {ColorFilter} from "utils/recommendation";

import {RecommendationFormContext} from "../../context/RecommendationFormContext";

function ColorFilterField(props) {
    const { formFieldName, intl } = props;

    const formikContext = useFormikContext();

    const {getValueByFormFieldName} = useContext(RecommendationFormContext);

    let value = getValueByFormFieldName(formFieldName);

    return (
        <>
            <SelectTitle
                titleTranslationKey={'recommendations.form.filter.color.title'}
                subtitleTranslationKey={'recommendations.form.filter.select_filter'}
            />
            <RadioGroup
                selectedValueLabel={intl.formatMessage({id: `recommendations.form.filter.color.${value}`})}
                name={formFieldName}
                value={value}
                onChange={(event) => {
                    formikContext.setFieldValue(formFieldName, event.currentTarget.value)
                }}
                alignListToRight
            >
                {Object.values(ColorFilter).map((filterName, index) => (
                    <FormControlLabel
                        key={index}
                        value={filterName}
                        label={intl.formatMessage({id: `recommendations.form.filter.color.${filterName}`})}
                    />
                ))}
            </RadioGroup>
        </>
    )
}

ColorFilterField.propTypes = {
    formFieldName: PropTypes.string.isRequired
}

export default injectIntl(ColorFilterField);
