import React, { useState } from "react";

import { injectIntl } from "react-intl";

import { IconButton, TextField} from "@mui/material";
import { Clear, Search as SearchIcon } from "@mui/icons-material";
import abort from "../../../utils/abort";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles(theme => ({
    search: {
        paddingRight: "15px",
        "& input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button": {
            WebkitAppearance: "none",
            margin: 0
        },
        "& div": {
            height: "38px",
            backgroundColor: "white",
        },
        "& button": {
            padding: "2px"
        }
    },
}));

function Search(props) {
    const classes = useStyles();

    const [search, setSearch] = useState('');

    function handlePageReset(params) {
        params = params.split("&");

        params.map((param, key) => {
            if (param.search("page") !== -1) {
                params[key] = "page=1";
            }
            return true;
        });

        params = params.filter(param => !(param.search("user.email") !== -1));

        return params;
    }

    function handleSearch(value) {
        abort.abortRequests();

        setSearch(value);

        let params = handlePageReset(decodeURIComponent(props.params));
        let newParams = "";

        if (value.length > 0) {
            newParams = `user.email=${value.toLowerCase()}&`;
        }

        params.map((param) => {
            newParams += `${param}&`;

            return true;
        });

        newParams = newParams.substring(0, newParams.length - 1);

        props.history.push(encodeURIComponent(newParams));
    }

    function clearSearch() {
        setSearch('');
        handleSearch("");
    }

    return (
        <div className={classes.subTableContainer}>
            <TextField
                id="search-input"
                placeholder={props.intl.formatMessage({id: "member.list.search.placeholder"})}
                onChange={(event) => handleSearch(event.target.value)}
                value={search}
                variant="outlined"
                InputProps={{
                    endAdornment: (
                        search.length > 0 ? (
                            <IconButton color="primary" onClick={() => clearSearch()} size="large">
                                <Clear />
                            </IconButton>
                        ) : <SearchIcon color={"disabled"} />
                    ),
                }}
                className={classes.search}
                type={"text"}
            />
        </div>
    );
}

export default injectIntl(Search);
