import {fetch} from "utils/dataAccess";

export default function deleteQuote(id) {
    return new Promise((resolve, reject) => {
        fetch(`/quote_comments/${id}`, {
            method: 'DELETE',
        })
        .then(() => {
            resolve(true);
        })
        .catch(error => reject(error));
    });
}
