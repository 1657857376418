import React from "react";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import {makeStyles} from "@mui/styles";
import Tooltip from "@mui/material/Tooltip";
import SubdirectoryArrowLeft from "@mui/icons-material/SubdirectoryArrowLeft";
import FabButton from "./FabButton";

const useStyle = makeStyles({
    icon: {
        color: "#FFFFFF",
        fontSize: "24px",
        paddingRight: '2px',
    },
})

export default function ReturnButton({onClick}) {
    const classes = useStyle();

    if (process.env.REACT_APP_RETURN_ENABLED) {
        return (
            <FabButton
                color="warning"
                size={'small'}
                onClick={onClick}
            >
                <Tooltip title={<FormattedMessage id={"button.return"} />}>
                    <SubdirectoryArrowLeft classes={{root: classes.icon}} />
                </Tooltip>
            </FabButton>
        )
    }

    return null
}

ReturnButton.defaultProps = {
    onClick: () => {}
}

ReturnButton.propTypes = {
    onClick: PropTypes.func,
}
