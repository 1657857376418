import React from "react";
import PropTypes from "prop-types";
import {FormattedMessage, injectIntl} from "react-intl";
import {Line} from "react-chartjs-2";

import CardBody from "components/card/cardBody";

import CardStats from "views/marketplace/dashboard/components/cards/cardStats";
import CardStatsGraphTitle from "views/marketplace/dashboard/components/cards/cardStatsGraphTitle";
import Loader from "views/marketplace/dashboard/components/loader";

import {blueLine, defaultOptions,} from "config/graph/defaultLineGraphConfig";

function QuotesByVolumeGraph(props) {
    const {
        intl,
        loading,
        onlineCountGraph,
        unit,
        unitParser,
        unitTooltip,
    } = props

    let newOnlineCountGraph = [];

    Object.keys(onlineCountGraph).map((key) => {
        return newOnlineCountGraph.push({x: key, y: onlineCountGraph[key]});
    });

    let cloneBlue = JSON.parse(JSON.stringify(blueLine));

    const data = {
        datasets : [cloneBlue]
    };

    data.datasets[0].label = intl.formatMessage({id: "dashboard.graph.label.online"});
    data.datasets[0].data = newOnlineCountGraph;

    const cloneDefaultOptions = JSON.parse(JSON.stringify(defaultOptions));


    cloneDefaultOptions.scales.xAxes[0].time.unit = unit;
    cloneDefaultOptions.scales.xAxes[0].time.parser = unitParser;
    cloneDefaultOptions.scales.xAxes[0].time.tooltipFormat = unitTooltip;
    cloneDefaultOptions.scales.yAxes[0].scaleLabel.labelString =  intl.formatMessage({id: "dashboard.graph.label.ordersCount"});

    return (
        <CardStats>
            <Loader loading={loading} />
            <CardStatsGraphTitle>
                <FormattedMessage id={"dashboard.quotes.graph.quote.title"} />
            </CardStatsGraphTitle>
            <CardBody>
                <Line
                    data={data}
                    options={cloneDefaultOptions}
                    width={400}
                    height={150}
                />
            </CardBody>
        </CardStats>
    )
}

export default injectIntl(QuotesByVolumeGraph)

QuotesByVolumeGraph.propTypes = {
    loading: PropTypes.bool,
    onlineCountGraph: PropTypes.object.isRequired,
    unit: PropTypes.oneOf(['day', 'month', 'year']).isRequired,
    unitParser: PropTypes.oneOf(['YYYY', 'YYYYMM', 'YYYYMMDD']).isRequired,
    unitTooltip: PropTypes.oneOf(['YYYY','MMMM YYYY','D MMMM YYYY']).isRequired
};
