import React, {useContext} from "react";
import {injectIntl} from "react-intl";
import GridItem from "../../../../../components/grid/gridItem";
import QuoteDetailHeaderInfosAddress from "./headerAddress";
import {objectEmpty} from "../../../../../utils/objectEmpty";
import {QuoteContext} from "../../../../../contexts/quoteContext";
import detailStyle from "../../../../../assets/jss/views/quote/detailStyle";
import {withStyles} from "@mui/styles";

const QuoteDetailHeaderInfosBillingAddress = ({ classes, intl }) => {
    const { quote } = useContext(QuoteContext);

    const billingAddress = {
        firstname: quote.customerBillingFirstname ?? quote.customerShippingFirstname ?? null,
        lastname: quote.customerBillingLastname ?? quote.customerShippingLastname ?? null,
        company: quote.customerBillingCompany ?? quote.customerShippingCompany ?? null,
        street: quote.customerBillingAddress?.street ?? quote.customerShippingAddress?.street ?? null,
        postalCode: quote.customerBillingAddress?.postalCode ?? quote.customerShippingAddress?.postalCode ?? null,
        city: quote.customerBillingAddress?.city ?? quote.customerShippingAddress?.city ?? null,
        provinceName: quote.customerBillingAddress?.provinceName ?? quote.customerShippingAddress?.provinceName ?? null,
        country: quote.customerBillingAddress?.country ?? quote.customerShippingAddress?.country ?? null,
    };

    if(objectEmpty(billingAddress)) {
        return null;
    }

    return (
        <GridItem md={6}>
            <p>
                {intl.formatMessage({id: "picking.quote.detail.header.billingAddress"})}<br/>
                <small className={classes.cardHeaderAddress}>
                    <QuoteDetailHeaderInfosAddress address={billingAddress}/>
                </small>
            </p>
        </GridItem>
    );
};

export default withStyles(detailStyle)(injectIntl(QuoteDetailHeaderInfosBillingAddress));
