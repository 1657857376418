import React, {Component} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {FormattedMessage, injectIntl} from "react-intl";

// @mui/material components
import {withStyles} from "@mui/styles";
import {Tooltip} from "@mui/material";

// @mui/icons-material
import {Delete, NotificationsActive} from "@mui/icons-material";

// core components
import CardLoader from "../components/CardLoader";
import GridContainer from "components/grid/gridContainer";
import GridItem from "components/grid/gridItem";
import Card from "components/card/card";
import CardHeader from "components/card/cardHeader";
import CardBody from "components/card/cardBody";
import Form from "./form";
import Snackbar from "components/snackbar/snackbar";

// styles
import updateStyle from "assets/jss/views/feed/updateStyle";

// actions
import {retrieve, update, reset} from "actions/feed/update";
import {del} from "actions/feed/delete";

class Update extends Component {
    static propTypes = {
        retrieved: PropTypes.object,
        retrieveLoading: PropTypes.bool.isRequired,
        retrieveError: PropTypes.string,
        updateLoading: PropTypes.bool.isRequired,
        updateError: PropTypes.object,
        deleteLoading: PropTypes.bool.isRequired,
        deleteError: PropTypes.string,
        updated: PropTypes.object,
        deleted: PropTypes.object,
        retrieve: PropTypes.func.isRequired,
        update: PropTypes.func.isRequired,
        del: PropTypes.func.isRequired,
        reset: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);

        this.state = {
            retrieveErrorNotification: true,
            updatedNotification: true,
            createdNotification: true,
            updateErrorNotification: true,
            deleteErrorNotification: true,
        };
    }

    componentDidMount() {
        this.props.retrieve(decodeURIComponent(this.props.match.params.fid));
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({retrieveErrorNotification: true});
        this.setState({updateErrorNotification: true});
        this.setState({updatedNotification: true});
        this.setState({deleteErrorNotification: true});

        if((this.props.updated !== nextProps.updated) || (this.props.deleted !== nextProps.deleted && nextProps.deleted !== null)) {
            this.props.reset();
            window.location = `/retailers/show/${this.props.match.params.id}/feeds/`;
        }
    }

    del = () => {
        if (window.confirm(this.props.intl.formatMessage({id: "feed.window.confirm.delete"})))
            this.props.del(this.props.retrieved);
    };

    render() {
        const item = this.props.updated ? this.props.updated : this.props.retrieved;
        const {retrieveLoading, updateLoading, deleteLoading, classes} = this.props;

        const isLoading = retrieveLoading || updateLoading || deleteLoading;

        return (
            <div className={classes.containerWithSidebar}>
                <div className={classes.container}>
                    {this.props.created && (
                        <Snackbar
                            open={this.state.createdNotification}
                            close
                            closeNotification={() => this.setState({createdNotification: false})}
                            place={"bl"}
                            color={"success"}
                            icon={function () {
                                return <NotificationsActive/>
                            }}
                            message={<FormattedMessage id={"feed.notifications.feedCreated"}
                                                       values={{name: this.props.created["name"]}}/>}
                        />
                    )}
                    {this.props.updateError && this.props.updateError.errors &&(
                        <Snackbar
                            open={this.state.updateErrorNotification}
                            close
                            closeNotification={() => this.setState({updateErrorNotification: false})}
                            place={"bl"}
                            color={"danger"}
                            icon={function () {
                                return <NotificationsActive/>
                            }}
                            message={<FormattedMessage id={this.props.updateError.errors[Object.keys(this.props.updateError.errors)[1]]}/>}
                        />
                    )}

                    {this.props.retrieveError && (
                        <Snackbar
                            open={this.state.retrieveErrorNotification}
                            close
                            closeNotification={() => this.setState({retrieveErrorNotification: false})}
                            place={"bl"}
                            color={"danger"}
                            icon={function () {
                                return <NotificationsActive/>
                            }}
                            message={this.props.retrieveError}
                        />
                    )}

                    {this.props.deleteError && (
                        <Snackbar
                            open={this.state.deleteErrorNotification}
                            close
                            closeNotification={() => this.setState({deleteErrorNotification: false})}
                            place={"bl"}
                            color={"danger"}
                            icon={function () {
                                return <NotificationsActive/>
                            }}
                            message={this.props.deleteError}
                        />
                    )}

                    <GridContainer>
                        <GridItem xs={12}>
                            <Card className={classes.card}>
                                <CardLoader loading={isLoading} />
                                <CardHeader color="info" className={classes.cardHeader}>
                                    <GridContainer justifyContent={"space-between"}>
                                        <GridItem xs={10}>
                                            <h3><FormattedMessage id={"feed.update.title"}/></h3>
                                        </GridItem>
                                        {(!this.props.retrieveLoading && !this.props.updateLoading && !this.props.deleteLoading) &&
                                        <GridItem xs={2} className={classes.mainActionsContainer}>
                                                <span style={{cursor: "pointer"}} onClick={this.del}><Tooltip
                                                    title={<FormattedMessage
                                                        id={"feed.update.action.delete"}/>}><Delete
                                                    color={"error"}/></Tooltip></span>
                                        </GridItem>
                                        }
                                    </GridContainer>
                                </CardHeader>
                                <CardBody>
                                    <Form
                                        onSubmit={values => this.props.update(item, values)}
                                        passwordEnabled={true}
                                        recurrenceUnit={item && {"label": item.recurrenceUnit, "value": item.recurrenceUnit}}
                                        initialValues={item && item}
                                    />
                                </CardBody>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    retrieved: state.feed.update.retrieved,
    retrieveError: state.feed.update.retrieveError,
    retrieveLoading: state.feed.update.retrieveLoading,
    updateError: state.feed.update.updateError,
    updateLoading: state.feed.update.updateLoading,
    deleteError: state.feed.del.error,
    deleteLoading: state.feed.del.loading,
    created: state.feed.create.created,
    deleted: state.feed.del.deleted,
    updated: state.feed.update.updated,
});

const mapDispatchToProps = dispatch => ({
    retrieve: id => dispatch(retrieve(id)),
    update: (item, values) => dispatch(update(item, values)),
    del: item => dispatch(del(item)),
    reset: () => dispatch(reset())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(updateStyle)(injectIntl(Update)));
