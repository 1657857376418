import {primaryColor} from "../../main";

const inviteFormStyle = {
    fieldContainer: {
        padding: "5px"
    },
    fieldLabel: {
        color: primaryColor,
        fontWeight: "bold"
    },
    formControl: {
        padding: "0px",
        width: "100%"
    },
    errorMessages:{
        color:'red',
        marginLeft: 10
    },
    form: {
        paddingBottom: window.innerHeight * 0.40
    }
};

export default inviteFormStyle;
