import {fetch} from "../../../utils/dataAccess";

export function error(error) {
    return {type: "PRODUCT_REQUEST_ERROR", error};
}

export function loading(loading) {
    return {type: "PRODUCT_REQUEST_LOADING", loading};
}

export function success(retrieved) {
    return {type: "PRODUCT_REQUEST_SUCCESS", retrieved};
}

export function request(barcode, retailerId) {
    return dispatch => {
        dispatch(error(false));
        dispatch(loading(true));

        let url = '/stockeasy/products/request?barcode=' + barcode + '&retailerId=' + retailerId;

        fetch(url)
            .then(response =>
                response.json()
            )
            .then(retrieved => {
                retrieved.pictures = retrieved.pictures['hydra:member'];
                retrieved.categories = retrieved.categories['hydra:member'];
                retrieved.defaultSizes = retrieved.defaultSizes['hydra:member'];
                retrieved.variations = retrieved.variations['hydra:member'];

                dispatch(loading(false));
                dispatch(success(retrieved));
            }).catch(() => {
                dispatch(loading(false));
                dispatch(error(true));
        });
    }
}

export function saveChanges(data) {
    return dispatch => {
        dispatch(success(data));
    }
}

export function reset() {
    return dispatch => {
        dispatch({type: "PRODUCT_LIST_RESET"});
    };
}
