import React from "react";
import {Route} from "react-router-dom";
import Dashboard from "../../views/picking/dashboard";
import RequireAuthentication from "../../hoc/authentication/requireAuthentication";
import RetailerUnloader from "../../hoc/retailer/retailerUnloader";
import * as Cookies from "js-cookie";

let routes = [];
const applications = Cookies.get("_a") ? JSON.parse(Cookies.get("_a").toString()) : null;

if (applications && applications["picking"]) {
    routes.push([
        <Route
            path="/picking"
            component={RequireAuthentication(RetailerUnloader(Dashboard))}
            exact
            key="dashboard"
        />,
    ])
}

export default routes;
