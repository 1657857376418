import {combineReducers} from "redux";
import list from './list'
import update from "./update";
import create from "./create";
import updateLock from "./lock/update";
import createLock from "./lock/create";
import variationMediaObject from "./variationMediaObject";
import priceStockData from "./priceStockData";

export default combineReducers({ update, list, updateLock, createLock, create, variationMediaObject, priceStockData});
