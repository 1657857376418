import {
    primaryColor,
    dangerColor,
    successColor,
    defaultFont
} from "../../main";

const customInputStyle = theme => ({
    disabled: {
        "&:before": {
            borderColor: "transparent !important"
        }
    },
    labelRoot: {
        ...defaultFont,
        color: "#AAAAAA !important",
        fontWeight: "400",
        fontSize: "14px",
        lineHeight: "1.42857",
        top: "10px",
        "&": {
            marginTop: "0px"
        }
    },
    labelRootError: {
        color: dangerColor + " !important",
    },
    labelRootSuccess: {
        color: successColor + " !important",
    },
    formControl: {
        margin: "0 0 17px 0",
        paddingTop: "27px",
        position: "relative",
        "& svg,& .fab,& .far,& .fal,& .fas,& .material-icons": {
            color: "#495057"
        },
        "& .is-invalid": {
            "& input": {
                border: '2px solid'+dangerColor
            }
        },
        "& textarea": {
            boxSizing: "border-box",
            height: "70px!important",
            overflow: "visible!important",
            "&:disabled":{
                background: "transparent!important",
                border: "none",
                paddingTop: "0px",
                paddingLeft: "0px"
            }
        }
    },
    fullWidth: {
      width: "100%"
    },
    input: {
        borderRadius: 4,
        position: "relative",
        backgroundColor: theme.palette.common.white,
        border: "2px solid #eee",
        textAlign: "left",
        fontSize: 14,
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        fontWeight: "400",
        lineHeight: "1.42857",
        padding: "10px 12px",
        opacity: "1",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        "&:focus": {
          "&:invalid": {
              border: '2px solid'+dangerColor,
          },
          borderRadius: 4,
          borderColor: primaryColor,
          boxShadow: primaryColor
        },
        color: "#495057",
        "&,&::placeholder": {
            textAlign: "left",
            fontSize: "14px",
            fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
            fontWeight: "400",
            lineHeight: "1.42857",
            opacity: "1"
        },
        "&::placeholder": {
            color: "#AAAAAA"
        },
        "&:disabled": {
            backgroundColor: "#eee",
            color: "#000"
        }
    },
    whiteInput: {
        "&,&::placeholder": {
            color: "#FFFFFF",
            opacity: "1"
        }
    }
});

export default customInputStyle;
