import React from "react";
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";

import {makeStyles} from "@mui/styles";
import {dangerColor} from "assets/jss/main";

const useStyle = makeStyles({
    fieldError: {
        color: dangerColor,
        fontSize: '12px'
    }
})

export default function ErrorMessage(props) {
    const classes = useStyle();

    return (
        <span className={classes.fieldError}>
            <FormattedMessage id={props.errorTranslationMessageId} />
        </span>
    )
}

ErrorMessage.propTypes = {
    errorTranslationMessageId: PropTypes.string.isRequired
}
