import {
    container,
    defaultFont,
    primaryColor,
    thunderGrayColor
} from "../../main";

const listStyle = theme => ({
    containerWithSidebar: {
        [theme.breakpoints.up("md")]: {
            marginLeft: "240px",
        }
    },
    container: {
        ...container,
        ...defaultFont
    },
    mainActionsContainer: {
        margin: "8px 0px",
        textAlign: "right"
    },
    card: {
        height: window.innerHeight * 0.94,
        background: "transparent",
        boxShadow: "none"
    },
    subTableContainer: {
        display: "flex",
        width: "100%",
        justifyContent: "flex-end",
        alignItems: "flex-end",
        paddingTop: "10px",
    },
    table: {
        borderSpacing: "0 20px",
        borderCollapse: "separate",
        "& thead": {
            "& tr": {
                "& th": {
                    border: "none"
                }
            }
        },
        "& tbody": {
            "& tr": {
                "& td": {
                    border: "none",
                }
            }
        }
    },
    tableRow: {
        height: "65px",
        borderRadius: "5px",
        background: "transparent",
        overflow: "none",
        cursor: "pointer",
    },
    tableBody: {
        background: "white",
    },
    selectedTableRow: {
        boxShadow: "0px 0px 13px 1px #c6cace",
    },
    leftTableCell: {
        borderTopLeftRadius: "5px",
        borderBottomLeftRadius: "5px"
    },
    checkbox: {
        color: "grey",
        "&$checked": {
            color: primaryColor,
            "&:hover": {
                backgroundColor: "rgba(0, 53, 245, 0.08)"
            },
        },
        "&:hover": {
            backgroundColor: "rgba(0, 53, 245, 0.08)"
        },
    },
    checked: {
        color: primaryColor,
    },
    typography: {
        padding: theme.spacing(2),
        cursor: "pointer",
        "&:hover": {
            background: primaryColor,
            color: "white",
        },
    },
    icon: {
        margin: theme.spacing(2),
        cursor: "pointer"
    },
    iconAll: {
        cursor: "pointer",
        margin: "0px 16px",
        verticalAlign: "middle"
    },
    propagateLoader: {
        position: "absolute",
        left: "0",
        right: "0",
        margin: "0 auto",
        top: "48%",
        display: "flex",
        justifyContent: "center"
    },
    actionsButtonContainer:{
        display:'flex',
        justifyContent: 'flex-end'
    },
    paginationContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%"
    },
    tableCellNoResult: {
        fontSize: "16px",
        color: thunderGrayColor,
        position: "absolute",
        left: "0",
        right: "0",
        margin: "0 auto",
        top: "48%",
        display: "flex",
        justifyContent: "center",
        fontWeight: "bold",
    },
});

export default listStyle;
