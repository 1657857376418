import { fetch } from "utils/dataAccess";

export function list(params = "") {
    return new Promise((resolve, reject) => {
        fetch(`/recommendations?${params}`)
            .then(response => {
                response.json().then(response => {
                    resolve(response["hydra:member"])
                })
            })
            .catch(error => reject(error));
    });
}
