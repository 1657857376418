import React from "react";
import PropTypes from "prop-types";
import {FormattedMessage, injectIntl} from "react-intl";
import {Line} from "react-chartjs-2";

import CardBody from "components/card/cardBody";

import CardStats from "../cards/cardStats";
import CardStatsGraphLegend from "../cards/CardStatsGraphLegend";
import CardStatsGraphTitle from "../cards/cardStatsGraphTitle";
import LegendDotBue from "./legendDotBue";
import LegendDotPurple from "./legendDotPurple";
import Loader from "../loader";

import {blueLine, purpleLine, defaultOptions} from "config/graph/defaultLineGraphConfig";

function SalesByValueGraph(props) {
    const {
        intl,
        isRetailer,
        loading,
        onlineTurnoverGraph,
        physicalTurnoverGraph,
        unit,
        unitParser,
        unitTooltip,
    } = props

    let newOnlineTurnoverGraph = [];
    let newPhysicalTurnoverGraph = [];

    Object.keys(onlineTurnoverGraph).map((key) => {
        return newOnlineTurnoverGraph.push({
            x: key,
            y: onlineTurnoverGraph[key]
        });
    });

    Object.keys(physicalTurnoverGraph).map((key) => {
        return newPhysicalTurnoverGraph.push({
            x: key,
            y: physicalTurnoverGraph[key]
        });
    });

    let cloneBlue = JSON.parse(JSON.stringify(blueLine));

    const data = {
        datasets : [cloneBlue]
    };

    data.datasets[0].label = intl.formatMessage({id: "dashboard.graph.label.online"});
    data.datasets[0].data = newOnlineTurnoverGraph;

    if (!isRetailer) {
        let clonePurple = JSON.parse(JSON.stringify(purpleLine));

        data.datasets.push(clonePurple);
        data.datasets[1].label = intl.formatMessage({id: "dashboard.graph.label.physical"});
        data.datasets[1].data = newPhysicalTurnoverGraph;
    }

    const cloneDefaultOptions = JSON.parse(JSON.stringify(defaultOptions));

    cloneDefaultOptions.scales.xAxes[0].time.unit = unit;
    cloneDefaultOptions.scales.xAxes[0].time.parser = unitParser;
    cloneDefaultOptions.scales.xAxes[0].time.tooltipFormat = unitTooltip;
    cloneDefaultOptions.scales.yAxes[0].scaleLabel.labelString =  intl.formatMessage({id: "dashboard.graph.label.turnover"});

    return (
        <CardStats>
            <Loader loading={loading} />
            <CardStatsGraphTitle>
                <FormattedMessage id={"dashboard.graph.turnover.title"} />
            </CardStatsGraphTitle>
            <CardBody>
                <Line
                    data={data}
                    options={cloneDefaultOptions}
                    width={400}
                    height={150}
                />
            </CardBody>
            <CardStatsGraphLegend>
                <LegendDotBue title={"dashboard.graph.legend.online"} />
                {!isRetailer && (
                    <LegendDotPurple title={"dashboard.graph.legend.physical"} />
                )}
            </CardStatsGraphLegend>
        </CardStats>
    )
}

export default injectIntl(SalesByValueGraph)

SalesByValueGraph.defaultProps = {
    onlineTurnoverGraph: {},
    physicalTurnoverGraph: {},
}

SalesByValueGraph.propTypes = {
    isRetailer: PropTypes.bool,
    loading: PropTypes.bool,
    onlineTurnoverGraph: PropTypes.object.isRequired,
    physicalTurnoverGraph: PropTypes.object.isRequired,
    unit: PropTypes.oneOf(['day', 'month', 'year']).isRequired,
    unitParser: PropTypes.oneOf(['YYYY', 'YYYYMM', 'YYYYMMDD']).isRequired,
    unitTooltip: PropTypes.oneOf(['YYYY','MMMM YYYY','D MMMM YYYY']).isRequired
};
