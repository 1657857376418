
export default function persist(id, values) {
    values = Object.assign({}, values);

    for (const [locale, translation] of Object.entries(values.translations)) {
        if (translation.image === '') {
            values.translations[locale].image = null;
        }
        if (translation.description === '') {
            values.translations[locale].description = null;
        }
    }

    return values;
}
