import React from "react";
import PropTypes from 'prop-types';
import {FormattedMessage} from "react-intl";
import {useHistory} from "react-router-dom";

import {Chip} from "@mui/material";

import GridItem from "components/grid/gridItem";
import CardBody from "components/card/cardBody";
import GridContainer from "components/grid/gridContainer";

import Loader from "./loader";
import CardStats from "./cards/cardStats";

import {
    irisBlueColor,
    lightGrayColor,
    mantisGreenColor,
    primaryColor,
    terraCottaColor,
    thunderGrayColor,
    yellowColor
} from "assets/jss/main";

import {ITEM_PER_PAGE} from "config/_pagination";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles(theme => ({
    rightCardOnlineOrdersTitle: {
        textAlign: "left",
        padding: "15px",
        fontSize: "16px",
        color: thunderGrayColor,
        fontWeight: "400"
    },
    rightCardOnlineOrdersContainer: {
        textAlign: "left",
        "& > div": {
            padding: "5px 0px",
            borderTop: "1px solid " + lightGrayColor,
            textAlign: "right",
            color: primaryColor,
            fontSize: "18px",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            "& > a": {
                width: "100%",
                fontWeight: "bold"
            }
        }
    },
    pendingChip: {
        backgroundColor: "rgba(230, 194, 41, 0.2)",
        color: yellowColor,
        "&:hover": {
            color: "#C1A635",
            backgroundColor: "rgba(230, 194, 41, 0.2)",
        }
    },
    confirmedChip: {
        backgroundColor: "rgba(120, 188, 97, 0.2)",
        color: mantisGreenColor,
        "&:hover": {
            color: "#43832E",
            backgroundColor: "rgba(120, 188, 97, 0.2)",
        }
    },
    toShipChip: {
        backgroundColor: "rgba(0, 172, 193, 0.2)",
        color: irisBlueColor,
        "&:hover": {
            color: "#058898",
            backgroundColor: "rgba(0, 172, 193, 0.2)",
        }
    },
    canceledChip: {
        backgroundColor: "rgba(237, 106, 90, 0.2)",
        color: terraCottaColor,
        "&:hover": {
            color: "#BD2A1F",
            backgroundColor: "rgba(237, 106, 90, 0.2)",
        }
    },
    reservedChip: {
        backgroundColor: "rgba(105, 41, 230, 0.2)",
        color: "#976ef5",
        "&:hover": {
            color: "#6F4DBF",
            backgroundColor: "rgba(105, 41, 230, 0.2)",
        }
    },
    returningChip: {
        backgroundColor: "#FFE6C9",
        color: "#FF8E5E",
        "&:hover": {
            color: "#E27947",
            backgroundColor: "#FFE6C9",
        }
    }

}));

export default function DetailsOnlineOrders(props) {
    const classes = useStyles();

    const history = useHistory();

    const { canClick, loading } = props

    const status = {
        'pending': props.pending,
        'reserved': props.reserved,
        'to-ship': props.toShip,
        'confirmed': props.confirmed,
        'returning': props.returning,
        'canceled': props.canceled,
    };

    let pickingUrl = `/picking/orders/order[createdAt]=asc&itemsPerPage=${ITEM_PER_PAGE}&page=1`

    function handleRedirect(status) {
        if (canClick) {
            history.push(`${pickingUrl}&status=${status}`);
        }
    }

    return (
        <CardStats>
            <div className={classes.rightCardOnlineOrdersTitle}>
                <FormattedMessage id={"dashboard.card.title.onlineOrders"} />
            </div>
            <Loader loading={loading} />
            <CardBody>
                <GridContainer className={classes.rightCardOnlineOrdersContainer}>
                    {Object.keys(status).map((statusName, index) => {
                        const statusNameBis = statusName === 'to-ship' ? 'toShip' : statusName;

                        if (!process.env.REACT_APP_RETURN_ENABLED && statusNameBis === 'returning') {
                            return '';
                        }

                        return (
                            <React.Fragment key={index}>
                                <GridItem xs={8}>
                                    <Chip
                                        onClick={() => handleRedirect(statusName)}
                                        component={"a"}
                                        className={classes[`${statusNameBis}Chip`]}
                                        label={<FormattedMessage id={`dashboard.onlineOrders.label.${statusNameBis}`} />}
                                    />
                                </GridItem>
                                <GridItem xs={4}>
                                    {status[statusName].toLocaleString()}
                                </GridItem>
                            </React.Fragment>
                        )
                    })}
                </GridContainer>
            </CardBody>
        </CardStats>
    )
}

DetailsOnlineOrders.defaultProps = {
    pending: 0,
    reserved: 0,
    toShip: 0,
    confirmed: 0,
    canceled: 0,
    returning: 0,
}

DetailsOnlineOrders.propTypes = {
    loading: PropTypes.bool,
    pending: PropTypes.number.isRequired,
    reserved: PropTypes.number.isRequired,
    toShip: PropTypes.number.isRequired,
    confirmed: PropTypes.number.isRequired,
    canceled: PropTypes.number.isRequired,
    returning: PropTypes.number.isRequired,
    canClick: PropTypes.bool,
};
