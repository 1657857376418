import {primaryColor} from "../main";

const completeProfileFormStyle = theme => ({
    card: {
        minHeight: window.innerHeight * 0.5
    },
    inputContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '16px',
        marginRight: '16px',
        marginBottom: '10px',
    },
    profileInputs: {
        padding: "0px",
        width: "100%"
    },
    inputLabel: {
        color: primaryColor,
        fontWeight: "bold"
    },
    actionsContainer: {
        position: "absolute",
        right: "20px",
        bottom: "15px",
        textAlign: "right"
    },
    submitLoading: {
        marginLeft: "5px",
        color: "#FFFFFF"
    },
});

export default completeProfileFormStyle;
