import {combineReducers} from "redux";

export function error(state = null, action) {
    switch (action.type) {
        case "ORDER_NOTE_LIST_ERROR":
            return action.error;

        case "ORDER_NOTE_LIST_RESET":
            return null;

        default:
            return state;
    }
}

export function loading(state = false, action) {
    switch (action.type) {
        case "ORDER_NOTE_LIST_LOADING":
            return action.loading;

        case "ORDER_NOTE_LIST_RESET":
            return null;

        default:
            return state;
    }
}

export function retrieved(state = false, action) {
    switch (action.type) {
        case "ORDER_NOTE_LIST_SUCCESS":
            return action.retrieved;

        case "ORDER_NOTE_LIST_RESET":
            return null;

        default:
            return state;
    }
}

export default combineReducers({error, loading, retrieved});
