import React, {useContext} from 'react'
import {makeStyles} from '@mui/styles'
import {FormattedMessage} from 'react-intl'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import {RecommendationContext} from '../../../../../context/RecommendationContext'
import {RecommendationFormContext} from '../../../../../context/RecommendationFormContext'
import {getTranslation} from '../../../../../../../domain/helpers/translations'
import FormControlLabel from '../../../../../../../components/radioGroup/FormControlLabel'
import SelectorContainer from './SelectorContainer'
import SelectorTitle from './SelectorTitle'
import ScrollableSelector from './ScrollableSelector'
import Loader from '../../Loader'
import { ReactComponent as ArrowLeft } from 'assets/img/arrow-left.svg'
import {borderGrayColor} from '../../../../../../../assets/jss/main'

const useStyles = makeStyles(theme => ({
    label: {
        '&:first-of-type': {
            borderTopLeftRadius: '0px',
            borderTopRightRadius: '0px',
        },
    },
    info: {
        textAlign: 'center',
        backgroundColor: 'white',
        padding: '16px',
        border: `1px solid ${borderGrayColor}`,
        borderBottomLeftRadius: '8px',
        borderBottomRightRadius: '8px',
    },
    loader: {
        paddingBottom: '8px',
    },
}))

export default function CornerSelector(props) {
    const {onClickBack, onSelectedCorner, formFieldName} = props
    const {corners, apiCornersLoading} = useContext(RecommendationContext)
    const {getValueByFormFieldName} = useContext(RecommendationFormContext)
    const selectedCorner = getValueByFormFieldName(formFieldName)
    const classes = useStyles()

    return (
        <SelectorContainer>
            <SelectorTitle>
                <ArrowLeft onClick={() => onClickBack()} />
                <span><FormattedMessage id={"recommendations.form.recommended_product.corners.name"} /></span>
            </SelectorTitle>
            <ScrollableSelector>
                {apiCornersLoading && (
                    <div className={classNames(classes.info, classes.loader)}>
                        <Loader />
                    </div>
                )}
                {!apiCornersLoading && corners.length === 0 && (
                    <div className={classes.info}>
                        <FormattedMessage id={"recommendations.form.recommended_product.corners.empty"} />
                    </div>
                )}
                {corners.map(corner => <FormControlLabel
                    key={corner.id}
                    className={classes.label}
                    label={getTranslation(corner).name}
                    checked={selectedCorner && selectedCorner['@type'] === 'Corner' && selectedCorner.id === corner.id}
                    onClick={() => {
                        onSelectedCorner(corner)
                    }}
                />)}
            </ScrollableSelector>
        </SelectorContainer>
    )
}

CornerSelector.propTypes = {
    onClickBack: PropTypes.func.isRequired,
    onSelectedCorner: PropTypes.func.isRequired,
    formFieldName: PropTypes.string.isRequired,
}
