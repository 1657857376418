import {fetch} from "../../../utils/dataAccess";

export function createError(createError) {
    return {type: "PRODUCT_LOCK_CREATE_ERROR", createError};
}

export function createLoading(createLoading) {
    return {type: "PRODUCT_LOCK_CREATE_LOADING", createLoading};
}

export function createSuccess(createSuccess) {
    return {type: "PRODUCT_LOCK_CREATE_SUCCESS", createSuccess};
}

export function create(item, values) {
    values["product"] = item;
    return dispatch => {
        dispatch(createLoading(true));
        return fetch("/product_locks", {
            method: "POST",
            body: JSON.stringify(values)
        }).then(response =>
            response.json()
        ).then((retrieved) => {
            dispatch(createSuccess(retrieved));
            dispatch(createLoading(false));
        })
            .catch(e => {
                dispatch(createLoading(false));
                dispatch(createError(e.message));
            });
    };
}

export function reset() {
    return dispatch => {
        dispatch(createError(false));
        dispatch(createSuccess(null));
        dispatch(createLoading(null));
    };
}
