import React, {Component} from "react";
import {Field, reduxForm} from "redux-form";
import PropTypes from "prop-types";
import {FormattedMessage, injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import Dropzone from "react-dropzone";
import {BeatLoader} from 'react-spinners';
import Select from 'react-select';

// @mui/material components
import {CircularProgress, Grid, } from "@mui/material";
import {withStyles} from "@mui/styles";

// @mui/icons-material
import {CloudUpload} from "@mui/icons-material";

// core components
import Button from "components/button/button";
import Input from "components/input/input";
import GridContainer from "components/grid/gridContainer";
import GridItem from "components/grid/gridItem";
import Card from "components/card/card";
import CardHeader from "components/card/cardHeader";
import CardBody from "components/card/cardBody";

// styles
import updateStyle from "assets/jss/views/profile/updateStyle";
import {primaryColor} from "assets/jss/main";

// actions
import {handleFileUpload} from "actions/upload";
import {SUPPORTED_LOCALES} from "../../domain/enum/locales";

class Form extends Component {
    constructor(props) {
        super(props);
        this.locales = Object.entries(SUPPORTED_LOCALES).filter(([code, locale]) => locale.translated).map(([code, locale]) => {
            return {label: `${locale.name}  ${locale.flag}`, value: code};
        });
        this.state = {
            selectedLocale: this.locales.find(option => option.value === props.initialValues.locale),
        }
        this.props.selectedLocale(props.initialValues.locale);
    }
    static propTypes = {
        handleSubmit: PropTypes.func.isRequired,
        error: PropTypes.string
    };

    localeSelected = locale => {
        const foundLocale = this.locales.find(option => option.value === locale);
        if (!foundLocale) {
            return;
        }

        this.setState({selectedLocale: foundLocale});
        this.props.selectedLocale(locale);
    }

    renderField = data => {
        const {classes} = this.props;
        return (
            <div className={classes.inputContainer}>
                <span className={classes.inputLabel}>{data.label}:</span>
                {(data.options && data.input.name === "locale") &&
                <Select
                    id={`profile_${data.name}`}
                    name={`${data.name}`}
                    options={data.options}
                    value={this.state?.selectedLocale}
                    onChange={(locale) => this.localeSelected(locale.value)}
                />}
                {(data.input.name !== "locale") &&
                <Input
                    {...data.input}
                    disableUnderline
                    inputProps={{
                        placeholder: data.placeholder,
                        type: data.type,
                        disabled: data.disabled,
                        required: data.required,
                        ...data.input,
                        autoComplete: data.autoComplete,
                    }}
                    type={data.type}
                    required={data.required}
                    id={`profile_${data.input.name}`}
                    formControlProps={{
                        className: classes.profileInputs
                    }}
                    value={data.input.value}
                />}
            </div>
        );
    };

    render() {
        const {classes} = this.props;
        const renderDropzoneInput = (field) => {
            let preview = null;

            if (this.props.initialValues && this.props.initialValues.picture) {
                preview = this.props.initialValues.picture;
            }

            return (
                <div className={classes.inputContainer}>
                    <span className={classes.inputLabel}>{field.label}:</span>
                    <div className={classes.pictureContainer}>
                        {this.props.uploadLoading ?
                            <CircularProgress className={classes.pictureLoading}/> :
                            <div className={classes.picturePlaceholderContent}>
                                <CloudUpload fontSize={"large"} className={classes.pictureIcon}/>
                                <span className={classes.pictureText}><FormattedMessage
                                    id={"retailer.form.action.upload"}/></span>
                            </div>
                        }
                        <Dropzone
                            name={field.input.name}
                            accept="image/*"
                            multiple={false}
                            className={classes.dropzoneRetailerImage}
                            onDrop={(filesToUpload) => {
                                this.props.upload(filesToUpload, this.props.member.userId)
                            }}
                        >
                            {({getRootProps, getInputProps}) => {
                                return (
                                    <div
                                        {...getRootProps()}
                                    >
                                        <input {...getInputProps()} />
                                        {
                                            preview ?
                                                <div className={classes.pictureContentFlex}>
                                                    <img src={preview} alt={""}/>
                                                </div>
                                                :
                                                <div className={classes.picturePlaceholder}/>

                                        }
                                    </div>
                                )
                            }}
                        </Dropzone>
                    </div>
                </div>
            );
        };

        return (
            <form onSubmit={this.props.handleSubmit}>
                <GridContainer>
                    <GridItem xs={12}>
                        <Card className={classes.card}>
                            <div className={classes.beatLoader}>
                                <BeatLoader
                                    sizeUnit={"px"}
                                    size={12}
                                    color={primaryColor}
                                    loading={this.props.loading}
                                />
                            </div>
                            <CardHeader color="info">
                                <GridContainer>
                                    <GridItem xs={9} sm={10}><h3><FormattedMessage id={"user.profile.show.title"}/>
                                    </h3></GridItem>
                                </GridContainer>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <Grid item xs={6}>
                                        <Field
                                            component={this.renderField}
                                            label={<FormattedMessage id={"user.profile.show.input.userName"}/>}
                                            name="fullName"
                                            type="text"
                                            required
                                            disabled={true}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Field
                                            component={this.renderField}
                                            label={<FormattedMessage id={"user.profile.show.input.email"}/>}
                                            name="email"
                                            type="text"
                                            required
                                            disabled={true}
                                        />
                                    </Grid>
                                </GridContainer>
                                <GridContainer>
                                    <Grid item xs={6}>
                                        <Field
                                            component={this.renderField}
                                            label={<FormattedMessage id={"user.profile.show.input.firstName"}/>}
                                            name="givenName"
                                            type="text"
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Field
                                            component={this.renderField}
                                            label={<FormattedMessage id={"user.profile.show.input.lastName"}/>}
                                            name="familyName"
                                            type="text"
                                            required
                                        />
                                    </Grid>
                                </GridContainer>
                                <GridContainer>
                                    <Grid item xs={6}>
                                        <Field
                                           name="locale"
                                           defaultValue={this.state?.selectedLocale}
                                           options={this.locales}
                                           component={this.renderField}
                                           type={'select'}
                                           label={<FormattedMessage id={"user.profile.show.input.locale"}/>}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Field
                                            component={this.renderField}
                                            label={<FormattedMessage id={"user.profile.show.input.mobilePhoneNumber"}/>}
                                            name="phoneNumber"
                                            type="text"
                                            placeholder={this.props.intl.formatMessage({id: "user.profile.show.placeholder.mobilePhoneNumber"})}
                                        />
                                    </Grid>
                                </GridContainer>
                                <GridContainer>
                                    <Grid item xs={6}>
                                        <Field
                                           name="picture"
                                           component={renderDropzoneInput}
                                           label={<FormattedMessage id={"user.profile.show.input.profilePicture"}/>}
                                           type="file"/>
                                    </Grid>
                                </GridContainer>
                            </CardBody>
                            <div className={classes.actionsContainer}>
                                <Button round color={"success"} type="submit">
                                    <FormattedMessage id={'user.profile.show.save.button'}/>
                                    <div className={classes.submitLoading}>
                                        <BeatLoader
                                            sizeUnit={"px"}
                                            size={4}
                                            color={"#FFF"}
                                            loading={this.props.updateLoading}
                                        />
                                    </div>
                                </Button>
                            </div>
                        </Card>
                    </GridItem>
                </GridContainer>
            </form>
        );
    }
}

const mapStateToProps = state => ({
    uploadLoading: state.upload.loading,
    updateLoading: state.profile.update.updateLoading,
    uploadError: state.upload.error,
    member: state.authentication.member,
    loading: state.profile.update.loading,
});

const mapDispatchToProps = dispatch => ({
    upload: (file, id) => dispatch(handleFileUpload(file, "image_user_picture", id))
});

export default reduxForm({
    form: "profile",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
}, mapStateToProps)(connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(updateStyle)(injectIntl(Form))));
