import React from "react";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";

import CardStats from "views/marketplace/dashboard/components/cards/cardStats";
import CardStatsTitle from "views/marketplace/dashboard/components/cards/cardStatsTitle";
import CardBody from "components/card/cardBody";
import CurrencyCodeToSymbol from "components/formattedNumberWithCurrencies/currencyCodeToSymbol";
import Loader from "views/marketplace/dashboard/components/loader";
import SortedTable from "../../sortedTable";

export default function ReturnedItemsTable(props) {
    const { currency, data, loading } = props;

    return (
        <CardStats>
            <Loader loading={loading} />
            <CardStatsTitle>
                <FormattedMessage id={"dashboard.ope_returned_items.title"} />
            </CardStatsTitle>
            <CardBody>
                <SortedTable
                    data={data}
                    orderBy={'turnover'}
                    headers={[
                        {
                            key: 'name',
                            label: 'dashboard.ope_cancelled_items.table_header.retailer',
                        },
                        {
                            key: 'itemsOrdered',
                            label: 'dashboard.ope_cancelled_items.table_header.products_ordered',
                        },
                        {
                            key: 'itemsCanceled',
                            label: 'dashboard.ope_returned_items.table_header.products_cancelled',
                        },
                        {
                            key: 'cancelRate',
                            label: 'dashboard.ope_returned_items.table_header.cancel_rate',
                            suffix: '%'
                        },
                        {
                            key: 'amountItemsCanceled',
                            label: "dashboard.ope_returned_items.table_header.sale_revenue",
                            suffix: CurrencyCodeToSymbol(currency),
                            values: {currency: CurrencyCodeToSymbol(currency)},
                        },
                    ]}
                />
            </CardBody>
        </CardStats>
    )
}

ReturnedItemsTable.propTypes = {
    currency: PropTypes.string,
    data: PropTypes.array,
    loading: PropTypes.bool.isRequired,
};
