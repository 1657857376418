import React, {useContext, useState} from 'react';

import {FormattedMessage} from 'react-intl';

import Grid from '@mui/material/Grid';

import {Field, Formik} from 'formik';

import BeatLoaderMini from 'components/loaders/BeatLoaderMini';
import CancelButtonStyled from 'components/button/CancelButtonStyled';
import CommentField from './components/CommentField';
import DeleteButton from 'components/button/DeleteButton';
import SaveButton from './components/SaveButton';
import Title from './components/Title';

import create from 'api/quote/quoteComment/create';
import deleteQuote from 'api/quote/quoteComment/delete';
import update from 'api/quote/quoteComment/update';

import {QuoteContext} from 'contexts/quoteContext';

import {
    WAITING_REVIEW,
    REVIEW_IN_PROGRESS,
} from '../../../../../enums/state';
import ActionContainer from './components/ActionContainer';

function AddCommentNote(props) {
    const { quote, refreshQuote } = useContext(QuoteContext);

    const [isEditing, setIsEditing] = useState(false);
    const [isActionLoading, setActionLoading] = useState(false);

    function canEditComment() {
        return !![WAITING_REVIEW, REVIEW_IN_PROGRESS].includes(quote.state);
    }

    function submit(values) {
        setActionLoading(true);

        if (quote.quoteComment?.comment) {
            update(quote.quoteComment.id, values.commentNote).then(() => refreshQuote())
        } else {
            create(quote['@id'], values.commentNote).then(() => refreshQuote());
        }
    }

    function deleteComment(id) {
        setActionLoading(true);

        deleteQuote(id).then(() => refreshQuote());
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <Title />
            </Grid>
            <Grid container sx={{ marginTop: '24px'}}>
                <Grid item xs={12}>
                    <Formik
                        initialValues={{ commentNote: quote.quoteComment?.comment ?? ''}}
                        onSubmit={(values) => submit(values)}
                    >
                        {({
                              values,
                              handleChange,
                              submitForm,
                              setFieldValue
                        }) => (
                            <div style={{ position: 'relative' }}>
                                <Field render={({ field, form }) => (
                                    <CommentField
                                        isEditing={isEditing}
                                        onChange={handleChange}
                                        value={values.commentNote}
                                        onFocus={() => canEditComment() && setIsEditing(true)}
                                        disabled={!canEditComment()}
                                    />
                                )}/>
                                <ActionContainer>
                                    {canEditComment() && !isEditing && quote.quoteComment?.comment ? (
                                        <DeleteButton
                                            disabled={isActionLoading}
                                            onClick={() => deleteComment(quote.quoteComment.id)}
                                        >
                                            <FormattedMessage id={'picking.quote.detail.addCommentNote.delete'} />
                                            <BeatLoaderMini loading={isActionLoading} />
                                        </DeleteButton>
                                    ) : (canEditComment() && (isEditing || values.commentNote?.length !== 0)) && (
                                        <>
                                            <CancelButtonStyled
                                                disabled={isActionLoading}
                                                onClick={() => {
                                                    setFieldValue('commentNote', quote.quoteComment?.comment ?? '')
                                                    setIsEditing(false);
                                                }}
                                            >
                                                <FormattedMessage id={'picking.quote.detail.addCommentNote.cancel'} />
                                            </CancelButtonStyled>
                                            <SaveButton
                                                disabled={values.commentNote?.length === 0 || isActionLoading}
                                                onClick={submitForm}
                                            >
                                                <FormattedMessage id={'picking.quote.detail.addCommentNote.save'} />
                                                <BeatLoaderMini loading={isActionLoading} />
                                            </SaveButton>
                                        </>
                                    )}
                                </ActionContainer>
                            </div>
                        )}
                    </Formik>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default AddCommentNote;
