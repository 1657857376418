import {fetch} from "../../utils/dataAccess";
import normalizeTranslations from "../translations";

export function error(error) {
    return {type: "VARIATION_CREATE_ERROR", error};
}

export function loading(loading) {
    return {type: "VARIATION_CREATE_LOADING", loading};
}

export function success(success) {
    return {type: "VARIATION_CREATE_SUCCESS", success};
}

export function create(values) {
    const data = {};
    const available_data = {
        "product"           : "@id",
        "translations"      : "translations",
        "outletPrice"       : "float",
        "discountedPrice"   : "float",
        "ecoTax"            : "float",
        "price"             : "float",
        "stock"             : "int",
        "sku"               : "string",
        "color"             : "@id",
        "size"              : "@id",
        "barcode"           : "string",
        "weight"            : "float",
    };

    Object.keys(available_data).forEach(function(key, index) {
        if (undefined !== values[key]){
            switch (available_data[key]) {
                default: break;
                case "string":
                    data[key] = (values[key])? values[key] : null;
                    break;
                case "float":
                    data[key] = (values[key])? parseFloat(values[key]) : null;
                    break;
                case "int":
                    data[key] = (values[key])? parseInt(values[key]) : 0;
                    break;

                case "@id":
                    data[key] = values[key];
                    break;
                case "translations":
                    const translations = {};
                    Object.entries(values[key]).forEach(([locale, translation]) => {
                        // Because there is only name translated
                        if (translation.name) {
                            translations[locale] = translation;
                        }
                    });

                    data[key] = translations;
            }
        }
    });

    return dispatch => {
        dispatch(loading(true));

        return fetch('/variations', {
            method: "POST",
            headers: new Headers({"Content-Type": "application/ld+json"}),
            body: JSON.stringify(normalizeTranslations(data)),
        }).then(response => response.json())
        .then((retrieved) => {
            if (values.pictures.length > 0) {
                createVariationMediaObjects(retrieved, values.pictures, dispatch);
            } else {
                dispatch(loading(false));
                dispatch(success(retrieved));
            }
        })
        .catch(e => {
            dispatch(loading(false));
            dispatch(error(e.errors));
        });
    };
}

function createVariationMediaObjects(variationCreated, pictures, dispatch)
{
    const variationId = variationCreated['@id']

    const promises = pictures.map((picture, position) => {
        const values = {
            variation: variationId,
            mediaObject: picture['@id'],
            position: !!position ? position : 0,
        };

        return fetch('/variation_media_objects', {
            method: "POST",
            headers: new Headers({"Content-Type": "application/ld+json"}),
            body: JSON.stringify(values),
        });
    });

    Promise.all(promises).then(() => {
        dispatch(loading(false));
        dispatch(success(variationCreated));
    }).catch(e => {
        dispatch(loading(false));
        dispatch(error(e.errors));
    });
}

export function reset() {
    return dispatch => {
        dispatch(error(false));
        dispatch(success(null));
        dispatch(loading(null));
    };
}
