import React from 'react';
import PropTypes from 'prop-types';

import Content from "../NoteBlock/Content";
import HistoryNoteHeader from "./HistoryNoteHeader";

import {lightGrayColor} from "assets/jss/main";

HistoryNoteDetail.propTypes = {
    content: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
};

export default function HistoryNoteDetail({content, date, subtitle, title}) {
    return !!content && (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            borderBottom: `1px solid ${lightGrayColor}`
        }}>
            <HistoryNoteHeader
                date={date}
                subtitle={subtitle}
                title={title}
            />
            <Content content={content} />
        </div>
    );
}
