import React, {useState} from "react";
import {Chip, Fab, } from "@mui/material";
import {withStyles} from "@mui/styles";
import {Add, Close, Done} from "@mui/icons-material";

import {injectIntl} from "react-intl";
import moment from "moment";
import {Formik} from "formik";

import yup from "../../../assets/translations/validation";
import openingDaysStyle from "../../../assets/jss/views/retailer/openingDaysStyle";

import FormikInput from "../../../components/input/formikInput";

const schema = yup.object({
    date: yup.date().required('Date is mandatory'),
    start: yup.string(),
    end: yup
        .string()
        .test("is-greater", "end time should be greater", function() {
            const { start, end, isOpen } = this.parent;
            if (isOpen === false) {
                return true;
            }

            return moment(end, "HH:mm").isAfter(moment(start, "HH:mm"));
        })
});

function ExceptionalDays (props) {
    const { exceptionalDays, classes, intl, addExceptionalDay, removeExceptionalHour } = props;
    const [isOpenAddDay, displayAddDayPicker] = useState(false);

    const onAddExceptionalDay = (values) => {
        addExceptionalDay(
            values.date.format("YYYY-MM-DD"),
            values.isOpen,
            values.isOpen ? values.start : null,
            values.isOpen ? values.end : null,
        );
    };

    const onDeleteExceptionalHour = (day, start, end) => {
        removeExceptionalHour(
            day,
            start,
            end,
        );
    };

    return (
        <div>
            <div className={classes.title}>{intl.formatMessage({'id': 'retailer.form.openingDays.exceptional_day.title'})}</div>
            <Fab color={isOpenAddDay ? "secondary" : "primary"} size={'small'} aria-label="add" onClick={() => displayAddDayPicker(!isOpenAddDay)} className={classes.addExceptionalDay}>
                {!isOpenAddDay && <Add fontSize={'small'}/>}
                {isOpenAddDay && <Close fontSize={'small'}/>}
            </Fab>

            <Formik
                initialValues={{
                    date: '',
                    isOpen: true,
                    start: '',
                    end: '',
                }}
                validationSchema={schema}
                validateOnChange
                onSubmit={onAddExceptionalDay}
            >{({
                   submitForm,
                   values
               }) => (
                <React.Fragment>
                    {isOpenAddDay && (
                        <div className={classes.exceptionalDay} key={'add-exceptional-day-container'}>
                            <FormikInput
                                inputProps={
                                    {
                                        name: 'date',
                                        type: 'date',
                                        containerClassName: classes.timeField,
                                    }
                                }
                            />
                            <FormikInput
                                labelText={intl.formatMessage({id: 'retailer.form.openingDays.exceptional_day.isOpen.label'})}
                                inputProps={
                                    {
                                        name: 'isOpen',
                                        type: 'checkbox',
                                        containerClassName: classes.timeField,
                                    }
                                }
                            />
                            { values.isOpen === true && <>
                                <FormikInput
                                    inputProps={
                                        {
                                            name: 'start',
                                            type: 'time',
                                            emptyLabel: intl.formatMessage({id: 'retailer.form.openingDays.hour_select.empty_label_start_date'}),
                                            choicesInterval: {
                                                minutes: 15,
                                            },
                                            containerClassName: classes.timeField,
                                        }
                                    }
                                />

                                <FormikInput
                                    inputProps={
                                        {
                                            name: 'end',
                                            type: 'time',
                                            emptyLabel:  intl.formatMessage({id: 'retailer.form.openingDays.hour_select.empty_label_end_date'}),
                                            choicesInterval: {
                                                minutes: 15,
                                            },
                                            containerClassName: classes.timeField,
                                        }
                                    }
                                />
                            </>}

                            <Fab color="primary" aria-label="add" onClick={submitForm} className={classes.savePeriod} size={'small'}>
                                <Done fontSize={'small'}/>
                            </Fab>
                        </div>
                    )}
                </React.Fragment>
            )}
            </Formik>

            <ul key={'exceptionalDays'} className={classes.openingHours}>
                {exceptionalDays.map(day => {
                    const {date, hours} = day;
                    const isOpen = Array.isArray(hours) && hours.length > 0;
                    let label = intl.formatMessage({'id': isOpen ? 'retailer.form.openingDays.exceptional_day.open.label' : 'retailer.form.openingDays.exceptional_day.close.label'})
                    if (!isOpen) {
                        return (
                            <li className={classes.exceptionalDayHours} key={'close-day-'+date}>
                                {label + ' ' + date}
                                <Fab color="primary" size={'small'} aria-label="add" onClick={() => onDeleteExceptionalHour(day.date, null, null)} className={classes.addPeriod}>
                                    <Close fontSize={'small'}/>
                                </Fab>
                            </li>);
                    }

                    return <li className={classes.exceptionalDayHours} key={'open-day-' + date}>
                        {label + ' ' + date}
                        {hours.map((hour, indexIt) => {
                            return <Chip
                                key={'hour-' + day + '_' + indexIt}
                                label={hour[0] + ' : ' + hour[1]}
                                color={"primary"}
                                onDelete={() => onDeleteExceptionalHour(date, hour[0], hour[1])}
                                className={classes.hourBadge}
                            />
                        })}
                    </li>;
                })}
            </ul>
        </div>
    );
}


export default withStyles(openingDaysStyle)(injectIntl(ExceptionalDays));
