import React, {Component} from "react";
import {ListItem, ListItemIcon, ListItemText} from "@mui/material";
import {Link} from "react-router-dom";
import {FormattedMessage} from "react-intl";
import {Note} from "@mui/icons-material";

class DrawerDashboardQuotes extends Component {
    render() {
        const dashboardQuotesLink = this.props.dashboardQuotesLink;
        const classes = this.props.classes;
        const isInDashboardQuotesPath = this.props.isInDashboardQuotesPath;

        return (
            <ListItem
                button
                className={classes.listItem}
                classes={{selected: classes.selected}}
                component={Link}
                to={dashboardQuotesLink}
                key={"dashboard"}
                selected={isInDashboardQuotesPath}
            >
                <ListItemIcon>
                    <Note color={isInDashboardQuotesPath ? "primary" : "secondary"} />
                </ListItemIcon>
                <ListItemText
                    primaryTypographyProps={{color: isInDashboardQuotesPath ? "primary" : "secondary"}}
                    primary={<FormattedMessage id={"sidebar.item.dashboard.quotes"}/>}
                />
            </ListItem>
        )
    }
}

export default DrawerDashboardQuotes;
