import React, { createContext, useState, useEffect } from 'react';
import pusherClient from "utils/pusherClient";

export const PusherContext = createContext();

export function PusherProvider({ children }) {
    const [organization, setOrganization] = useState(null);
    const [batchActionChannel, setBatchActionChannel] = useState(null);

    useEffect(() => {
        if (!organization) return;
        setBatchActionChannel(pusherClient.subscribe('batch_actions' + organization.id));
    }, [organization]);

    return (
        <PusherContext.Provider value={{batchActionChannel, setOrganization}}>
            {children}
        </PusherContext.Provider>
    );
}
