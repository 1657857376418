import {fetch} from "utils/dataAccess";

export function error(error) {
    return {type: "SIZE_UPDATE_POSITION_ERROR", error};
}

export function loading(loading) {
    return {type: "SIZE_UPDATE_POSITION_LOADING", loading};
}

export function success(updated) {
    return {type: "SIZE_UPDATE_POSITION_SUCCESS", updated};
}

export function updatePosition(params) {
    return dispatch => {
        dispatch(loading(true));

        return fetch('/sizes/update_position', {method: "POST", body: JSON.stringify(params)})
            .then(() => {
                dispatch(loading(false));
                dispatch(success(params));
            })
            .catch((e) => {
                dispatch(loading(false));
                dispatch(error(e.message));
            });
    };
}