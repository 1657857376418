import {
    fetch,
    normalize,
} from "../utils/dataAccess";

import {success as physicalEntryListSuccess} from "./physicalEntry/list";

export function retrieveError(retrieveError) {
    return {type: "DASHBOARD_DATA_RETRIEVE_ERROR", retrieveError};
}

export function retrieveOrdersDataLoading(retrieveOrdersDataLoading) {
    return {type: "DASHBOARD_ORDERS_DATA_RETRIEVE_LOADING", retrieveOrdersDataLoading};
}

export function retrieveQuotesDataLoading(retrieveQuotesDataLoading) {
    return {type: "DASHBOARD_QUOTES_DATA_RETRIEVE_LOADING", retrieveQuotesDataLoading};
}

export function retrieveOpeDataLoading(retrieveOpeDataLoading) {
    return {type: "DASHBOARD_OPE_DATA_RETRIEVE_LOADING", retrieveOpeDataLoading};
}

export function retrieveAnalyticsDataLoading(retrieveAnalyticsDataLoading) {
    return {type: "DASHBOARD_ANALYTICS_DATA_RETRIEVE_LOADING", retrieveAnalyticsDataLoading};
}

export function retrieveAnalyticsDataSuccess(retrieveAnalyticsData) {
    return {type: "DASHBOARD_ANALYTICS_DATA_RETRIEVE_SUCCESS", retrieveAnalyticsData};
}

export function retrieveOrdersDataSuccess(retrievedOrdersData) {
    return {type: "DASHBOARD_ORDERS_DATA_RETRIEVE_SUCCESS", retrievedOrdersData};
}

export function retrieveQuotesDataSuccess(retrievedQuotesData) {
    return {type: "DASHBOARD_QUOTES_DATA_RETRIEVE_SUCCESS", retrievedQuotesData};
}

export function retrieveOpeDataSuccess(retrievedOpeDataSuccess) {
    return {type: "DASHBOARD_OPE_DATA_RETRIEVE_SUCCESS", retrievedOpeDataSuccess};
}

export function retrieveRetailersRankingDataLoading(retrieveRetailersRankingDataLoading) {
    return {type: "DASHBOARD_RETAILER_RANKING_DATA_RETRIEVE_LOADING", retrieveRetailersRankingDataLoading};
}

export function retrieveRetailersRankingDataSuccess(retrievedRetailersRankingData) {
    return {type: "DASHBOARD_RETAILERS_RANKING_DATA_RETRIEVE_SUCCESS", retrievedRetailersRankingData};
}

export function retrieveProductsRankingDataSuccess(retrievedProductsRankingData) {
    return {type: "DASHBOARD_PRODUCTS_RANKING_DATA_RETRIEVE_SUCCESS", retrievedProductsRankingData};
}

export function retrieveProductsRankingDataLoading(retrieveProductsRankingDataLoading) {
    return {type: "DASHBOARD_PRODUCTS_RANKING_DATA_RETRIEVE_LOADING", retrieveProductsRankingDataLoading};
}

export function retrieveOrdersData(startDate, endDate, unit, retailer = null) {
    return dispatch => {
        dispatch(retrieveOrdersDataLoading(true));
        dispatch(retrieveError(""));

        let url = `/dashboard/orders_data?startDate=${startDate}&endDate=${endDate}&unit=${unit}`;

        if (retailer) {
            url += `&retailer=${retailer.replace("/retailers/", "")}`
        }

        return fetch(url, {method: "GET"})
            .then(response =>
                response.json()
            )
            .then(retrieved => {
                retrieved = normalize(retrieved);

                dispatch(retrieveOrdersDataLoading(false));
                dispatch(retrieveOrdersDataSuccess(retrieved));

            })
            .catch(e => {
                dispatch(retrieveOrdersDataLoading(false));
                dispatch(retrieveError(e.message));
            });
    };
}

export function retrieveQuotesData(startDate, endDate, unit, retailer = null) {
    return dispatch => {
        dispatch(retrieveQuotesDataLoading(true));
        dispatch(retrieveError(""));

        let url = `/dashboard/quotes_data?startDate=${startDate}&endDate=${endDate}&unit=${unit}`;

        if (retailer) {
            url += `&retailer=${retailer.replace("/retailers/", "")}`
        }

        return fetch(url, {method: "GET"})
            .then(response =>
                response.json()
            )
            .then(retrieved => {
                retrieved = normalize(retrieved);

                dispatch(retrieveQuotesDataLoading(false));
                dispatch(retrieveQuotesDataSuccess(retrieved));

            })
            .catch(e => {
                dispatch(retrieveQuotesDataLoading(false));
                dispatch(retrieveError(e.message));
            });
    };
}

export function retrieveOpeData(startDate, endDate, unit) {
    return dispatch => {
        dispatch(retrieveOpeDataLoading(true));
        dispatch(retrieveError(""));

        let url = `/dashboard/ope_data?startDate=${startDate}&endDate=${endDate}&unit=${unit}`;

        return fetch(url, {method: "GET"})
            .then(response =>
                response.json()
            )
            .then(retrieved => {
                retrieved = normalize(retrieved);
                dispatch(retrieveOpeDataLoading(false));
                dispatch(retrieveOpeDataSuccess(retrieved));

            })
            .catch(e => {
                dispatch(retrieveOpeDataLoading(false));
                dispatch(retrieveError(e.message));
            });
    };
}

export function retrieveAnalyticsData(startDate, endDate, unit, retailer = null) {
    return dispatch => {
        dispatch(retrieveAnalyticsDataLoading(true));
        dispatch(retrieveError(""));

        let url = `/dashboard/analytics_data?startDate=${startDate}&endDate=${endDate}&unit=${unit}`;

        if (retailer) {
            url += `&retailer=${retailer.replace("/retailers/", "")}`
        }

        return fetch(url, {method: "GET"})
            .then(response =>
                response.json()
            )
            .then(retrieved => {
                retrieved = normalize(retrieved);

                dispatch(retrieveAnalyticsDataLoading(false));
                dispatch(retrieveAnalyticsDataSuccess(retrieved));

            })
            .catch(e => {
                dispatch(retrieveAnalyticsDataLoading(false));
                dispatch(retrieveError(e.message));
            });
    };
}

export function retrieveRetailersRankingData(startDate, endDate, rankingBy, order = 'desc') {
    return dispatch => {
        dispatch(retrieveRetailersRankingDataLoading(true));
        dispatch(retrieveError(""));

        const params = new URLSearchParams()
        params.set('startDate', startDate)
        params.set('endDate', endDate)
        params.set('rankingBy', rankingBy)
        params.set('order', order)

        return fetch(`/dashboard/retailers_ranking_data?${params.toString()}`, {method: "GET"})
            .then(response =>
                response.json()
            )
            .then(retrieved => {
                retrieved = normalize(retrieved);

                dispatch(retrieveRetailersRankingDataLoading(false));
                dispatch(retrieveRetailersRankingDataSuccess(retrieved));

            })
            .catch(e => {
                dispatch(retrieveRetailersRankingDataLoading(false));
                dispatch(retrieveError(e.message));
            });
    };
}


export function retrieveProductsRankingData(startDate, endDate, retailers, maxResult = 10) {
    return dispatch => {
        dispatch(retrieveProductsRankingDataLoading(true));
        dispatch(retrieveError(""));

        return fetch(`/dashboard/products_ranking_data?startDate=${startDate}&endDate=${endDate}&retailers=${JSON.stringify(retailers)}&maxResult=${maxResult}`, {method: "GET"})
            .then(response =>
                response.json()
            )
            .then(retrieved => {
                retrieved = normalize(retrieved);

                dispatch(retrieveProductsRankingDataLoading(false));
                dispatch(retrieveProductsRankingDataSuccess(retrieved));

            })
            .catch(e => {
                dispatch(retrieveProductsRankingDataSuccess(false));
                dispatch(retrieveError(e.message));
            });
    };
}
export function reset() {
    return dispatch => {
        dispatch(physicalEntryListSuccess(null));
        dispatch({type: "DASHBOARD_DATA_RESET"});
    };
}
