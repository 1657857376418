import React from "react";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";

import FormattedNumberWithCurrency
    from "components/formattedNumberWithCurrencies/formattedNumberWithCurrency";

import CardStatsTitle from "./cards/cardStatsTitle";
import CardStatsValue from "./cards/cardStatsValue";
import CardStatsBody from "./cards/cardStatsBody";
import CardStats from "./cards/cardStats";
import Loader from "./loader";

export default function AmountCanceled(props) {
    const { amount, currency, loading } = props

    return (
        <CardStats>
            <Loader loading={loading} />
            <CardStatsTitle>
                <FormattedMessage id={"dashboard.ope.amount.canceled"}/>
            </CardStatsTitle>
            <CardStatsBody>
                <CardStatsValue>
                    <FormattedNumberWithCurrency
                        value={amount}
                        currency={currency}
                    />
                </CardStatsValue>
            </CardStatsBody>
        </CardStats>
    )
}


AmountCanceled.defaultProps = {
    amount: 0,
}

AmountCanceled.propTypes = {
    amount: PropTypes.number.isRequired,
    currency: PropTypes.string,
    loading: PropTypes.bool,
};
