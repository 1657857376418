const {useFormikContext} = require("formik");
const {useEffect} = require("react");
const FormObserver = ({onChange}) => {
    const { values } = useFormikContext();

    useEffect(() => {
        onChange(values);
    }, [values]);

    return null;
};

export default FormObserver;
