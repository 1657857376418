import {SubmissionError} from "redux-form";
import {fetch} from "../../utils/dataAccess";
import {uploadSuccess} from "./upload";

export function error(error) {
    return {type: "FEED_IMPORT_ERROR", error};
}

export function loading(loading) {
    return {type: "FEED_IMPORT_LOADING", loading};
}

export function success(importing) {
    return {type: "FEED_IMPORT_SUCCESS", importing};
}

export function importFeed(feed) {
    const feedId = feed["id"];

    let param = feedId;

    if (feed["forceUpdate"] !== undefined) {
        param = feedId + "&forceUpdate=true";
    }

    return dispatch => {
        dispatch(loading(true));
        dispatch(error(""));

        return fetch("/import/products/feed?id=" + param)
            .then(response => {
                dispatch(loading(false));

                return response.json();
            })
            .then(retrieved => dispatch(success(retrieved)))
            .catch(e => {
                dispatch(loading(false));

                if (e instanceof SubmissionError) {
                    dispatch(error(e.errors._error));
                    throw e;
                }

                dispatch(error(e.message));
            });
    };
}

export function reset() {
    return dispatch => {
        dispatch(loading(false));
        dispatch(error(null));
        dispatch(uploadSuccess(null));
    };
}
