import {fetch} from "utils/dataAccess";

export async function disable(id) {
    const params = {
        enabled: false,
    }

    await fetch('/corners/' + id, {
        method: "PUT",
        body: JSON.stringify(params),
        headers: new Headers({'Content-Type': 'application/ld+json'}),
    });
}
