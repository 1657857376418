import {
    fetch
} from "../../../utils/dataAccess";

export function error(error) {
    return {type: "REPORT_ERRORS_LIST_ERROR", error};
}

export function loading(loading) {
    return {type: "REPORT_ERRORS_LIST_LOADING", loading};
}

export function success(retrieved) {
    return {type: "REPORT_ERRORS_LIST_SUCCESS", retrieved};
}

export function successGet(retrieved) {
    return {type: "REPORT_ERRORS_GET_SUCCESS", retrieved};
}

export function list(reportId, page) {
    return dispatch => {
        dispatch(loading(true));
        dispatch(error(""));

        fetch("/import_report_errors?order[line]=asc&itemsPerPage=100&report.id=" + reportId + "&page=" + page)
            .then(response =>
                response.json()
            )
            .then((reports) => {
                dispatch(loading(false));
                dispatch(success(reports));
            })
            .catch(e => {
                dispatch(loading(false));
                dispatch(error(e.message));
            });
    };
}

export function reset() {
    return dispatch => {
        dispatch({type: "REPORT_ERRORS_LIST_RESET"});
    };
}
