import React, {useContext} from "react";
import {styled} from "@mui/material/styles";
import {FormattedMessage} from "react-intl";

import {QuoteContext} from "contexts/quoteContext";
import FormattedNumberWithCurrency
    from "../../../../../components/formattedNumberWithCurrencies/formattedNumberWithCurrency";
import {StoreContext} from "contexts/storeContext";
import {getLineCalculator, roundPrice} from "services/quote/lineCalculator";
import {
    REVIEW_IN_PROGRESS,
    WAITING_REVIEW,
} from "../../../enums/state";
import AddCommentNote from "../notes/components/AddCommentNote";
import GridCommentTotal from "./GridCommentTotal";
import GridAddInternalNote from "./GridAddInternalNote";
import AddANewProductButton from "../notes/components/AddANewProductButton";
import PriceDetailLine from "../notes/components/PriceDetailLine";
import GridPriceDetailLine from "./GridPriceDetailLine";
import GridComment from "./GridComment";
import GridDetails from "./GridDetails";

const StyledDivContainer = styled('div')(({theme}) => ({
    display: 'flex',
    padding: '24px 30px',
    alignItems: 'flex-start',
    gap: '30px',
    [theme.breakpoints.down('sm')]: {
        display: 'grid',
        padding: '16px',
        gap: '16px',
        gridTemplateAreas: `"addProduct"
  "comment"
  "price"
  "addInternalNote"`,
    },
}));

const QuoteDetailGridTotals = () => {
    const { openNewLineModal, lineBeingEdited, quote } = useContext(QuoteContext);
    const store = useContext(StoreContext);
    const currency = store.getState()?.currentOrganization?.retrieved?.currency;

    let totalQuantity = 0;
    let totalTaxExcludedWithoutDiscount = 0;
    let totalWithoutDiscount = 0;
    let totalTaxExcluded = 0;
    let total = 0;
    for (const line of quote.lines) {
        if (null !== lineBeingEdited && lineBeingEdited.id === line.id && !!lineBeingEdited.totalWithoutTaxesAndDiscount) {
            totalTaxExcludedWithoutDiscount += lineBeingEdited.totalWithoutTaxesAndDiscount;
            totalWithoutDiscount += lineBeingEdited.totalWithTaxesWithoutDiscount;
            totalTaxExcluded += lineBeingEdited.totalWithoutTaxes;
            total += lineBeingEdited.totalWithTaxes;
            totalQuantity += lineBeingEdited.quantity;
            continue;
        }

        const { getTotalTaxExcluded, getTotalWithTax, quantity} =  getLineCalculator(
            line.unitPrice / 100,
            line.taxRate.value,
            line.units.length,
            line.discount,
        );

        totalTaxExcludedWithoutDiscount += roundPrice(getTotalTaxExcluded(false));
        totalWithoutDiscount += getTotalWithTax(false);
        totalTaxExcluded += getTotalTaxExcluded();
        total += getTotalWithTax();
        totalQuantity += quantity;
    }

    let discountPercentage = 0;
    if (total !== 0) {
        discountPercentage = roundPrice(100 - (total * 100 / totalWithoutDiscount));
    }

    const isModifiable = [WAITING_REVIEW, REVIEW_IN_PROGRESS].includes(quote.state);

    return (
        <StyledDivContainer>
            <GridComment>
                <GridCommentTotal>
                    <AddCommentNote />
                </GridCommentTotal>
                <GridAddInternalNote />
            </GridComment>
            <GridDetails>
                <AddANewProductButton
                    onClick={openNewLineModal}
                    disabled={!isModifiable}
                />
                <GridPriceDetailLine>
                    <PriceDetailLine>
                        <FormattedMessage id={'picking.quote.detail.gridContent.totalTaxIncluded'} />
                        <FormattedNumberWithCurrency value={totalWithoutDiscount} currency={currency}/>
                    </PriceDetailLine>
                    <PriceDetailLine>
                        <FormattedMessage id={'picking.quote.detail.gridContent.totalTaxExcluded'} />
                        <FormattedNumberWithCurrency value={totalTaxExcludedWithoutDiscount} currency={currency}/>
                    </PriceDetailLine>
                    <PriceDetailLine>
                        <FormattedMessage id={'picking.quote.detail.gridContent.productNumber'} />
                        <span>{totalQuantity}</span>
                    </PriceDetailLine>
                    <PriceDetailLine>
                        <FormattedMessage id={'picking.quote.detail.gridContent.discount'} />
                        <FormattedNumberWithCurrency value={discountPercentage} currency={'%'} />
                    </PriceDetailLine>
                    <PriceDetailLine
                        bold={true}
                    >
                        <FormattedMessage id={'picking.quote.detail.gridContent.totalTaxIncludedDiscounted'} />
                        <FormattedNumberWithCurrency value={total} currency={currency} />
                    </PriceDetailLine>
                    <PriceDetailLine
                        bold={true}
                    >
                        <FormattedMessage id={'picking.quote.detail.gridContent.totalTaxExcludedDiscounted'} />
                        <FormattedNumberWithCurrency value={totalTaxExcluded} currency={currency} />
                    </PriceDetailLine>
                </GridPriceDetailLine>
            </GridDetails>
        </StyledDivContainer>
    );
};

export default QuoteDetailGridTotals;
