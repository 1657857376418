import React, {useState, useEffect, useContext} from "react";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import LinearProgress from '@mui/material/LinearProgress';
import Collapse from '@mui/material/Collapse';
import {FormattedMessage} from "react-intl";
import useBatchActions from "views/marketplace/merchandising/hook/useBatchActions";
import {PusherContext} from "contexts/pusherContext";

export default function RunningActions({reloadHandler}) {
    const { batchActionChannel } = useContext(PusherContext);
    const { runningActions } = useBatchActions();
    const [open, setOpen] = useState(true);

    useEffect(() => {
        if (!batchActionChannel) return;

        // Make sure this is only called once
        batchActionChannel.bind('action_completed', () => {
            // A simple refresh should be enough. No specific action needed.
            reloadHandler();
        });
    }, [batchActionChannel]);

    return runningActions.map(action =>
        <Collapse key={action.id} in={open}>
            <Alert severity="info" onClose={() => setOpen(false)}>
                <AlertTitle>
                    <FormattedMessage id={"merchandising.show.label.actions.running_title"} />
                </AlertTitle>
                <FormattedMessage id={"merchandising.show.label.actions.running_content"} values={{ actionType: action.actionType}} />
                <LinearProgress sx={{ marginTop: '10px'}} />
            </Alert>
        </Collapse>
    )
}
