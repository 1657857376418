import React, { useEffect, useState } from "react";
import { connect }  from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";

// @mui/material components
import {
    Collapse,
    Dialog,
    DialogTitle,
    DialogContent,
    Divider,
    Input,
    List,
    ListItem,
    ListItemText,
    Radio,
} from "@mui/material";
import {makeStyles} from "@mui/styles";

// @mui/icons-material
import { KeyboardArrowDown, KeyboardArrowRight } from "@mui/icons-material";

// core components
import GridItem from "components/grid/gridItem";
import Hierarchy from '../../configuration/components/hierarchy';
import ActionsContainer from "components/form/ActionsContainer";
import FormContainer from "views/configuration/components/FormContainer";
import CancelButton from "views/configuration/components/CancelButton";
import MoveButton from "views/configuration/components/MoveButton";

// action
import { update } from "actions/color/update";

// styles
import {grayColor} from "../../../assets/jss/main";

const useStyles = makeStyles(theme => ({
    dialogSearch:{
        width: "100%",
        padding: "10px 20px",
    },
    dialogContent: {
        padding: "0 !important",
    },
    list: {
        padding: "10px 5px",
        height: 400,
        overflowY: "scroll"
    },
    tableCellNoResult: {
        fontSize: "16px",
        textAlign: "center",
        fontWeight: "bold",
        verticalAlign: "middle",
        marginTop: "20px"
    },
    colorListItem: {
        padding: 0,
        cursor: "pointer"
    },
    childrenColors: {
        marginLeft: 30,
    },
    setTopParent: {
       backgroundColor: "#FFFFFF",
        color: grayColor,
        width: "1px",
        height: "1px",
        border: `1px solid ${grayColor}`,
        marginLeft: "11px",
        verticalAlign: "sub",
        padding: "9px",
        "&:hover": {
            backgroundColor:"rgba(0, 82, 203, 0.08)",
        }
    },
    hierarchy: {
        padding: "10px"
    }
}));

function MoveDialog(props) {
    const { allColors, colorSelected, closeDialog, isOpen, updated, intl, update, updateLoading, submitting } = props;
    const classes = useStyles();

    const [search, setSearch] = useState('');
    const [openParents, setOpenParents] = useState([]);
    const [newParent, setNewParent] = useState(null);

    useEffect(() => {
        closeDialog();
    }, [updated]);

    useEffect(() => {
        if (colorSelected.parent !== null) {
            setNewParent(colorSelected.parent);
        } else {
            setNewParent(colorSelected)
        }
    }, [colorSelected]);

    function isNewParentIsAChildOfColor(newParent, colorSelected) {
        let parent = newParent;

        while(parent !== null) {
            if (parent.id === colorSelected.id) {
                return true
            }

            parent = parent.parent;
        }

        return false
    }

    function submit(e) {
        e.preventDefault();

        if (isNewParentIsAChildOfColor(newParent, colorSelected)) {
            update(newParent, {parent: colorSelected.parent}).then(() => {
                update(colorSelected, {parent: newParent ? newParent["@id"] : null});
            });
        } else {
            if (colorSelected) {
                update(colorSelected, {parent: newParent ? newParent["@id"] : null});
            }
        }
    }

    function checkParentOpen(colorId) {
        for (let i = 0; i < openParents.length; i++) {
            if (openParents[i] === colorId) {
                return true;
            }
        }

        return false;
    }

    function handleShowChildren(colorId) {
        const isParentAlreadyOpen = checkParentOpen(colorId);

        if (!isParentAlreadyOpen) {
            setOpenParents((openParents) => [...openParents, colorId]);
        } else {
            let newOpened = openParents.filter((parent) => !(parent === colorId));

            setOpenParents(newOpened);
        }
    }

    function renderSetTopParent() {
        return (
            <ListItem className={classes.colorListItem}>
                <Radio
                    color="primary"
                    disabled={colorSelected.parent === null}
                    onChange={() => setNewParent(null)}
                    classes={{checked: classes.checked}}
                    checked={newParent === null}
                />
                <ListItemText
                    className={classes.setTopParentText}
                    primary={intl.formatMessage({id: "color.form.move.parent"})}
                />
            </ListItem>
        )
    }

    function renderColorChildren(colors) {
        if (colors.length > 0) {
            return colors.map(color => {
                const hasChildren = allColors.filter(item => item.parent && item.parent.id === color.id).length > 0;
                const isParentOpen = checkParentOpen(color.id);

                return (
                    <div key={color.id}>
                        <ListItem className={classes.colorListItem} button={hasChildren}>
                            <Radio
                                color="primary"
                                disabled={colorSelected.id === color.id}
                                onChange={() => setNewParent(color)}
                                classes={{checked: classes.checked}}
                                checked={!!(newParent && newParent.id === color.id)}
                            />
                            <ListItemText
                                primary={color.name}
                                onClick={() => handleShowChildren(color.id)}
                            />
                            {hasChildren && (isParentOpen ? <KeyboardArrowDown/> : <KeyboardArrowRight/>)}
                        </ListItem>
                        <Collapse
                            className={classes.childrenColors}
                            in={hasChildren && isParentOpen}
                            timeout="auto"
                            unmountOnExit
                        >
                            {renderColorChildren(allColors.filter(cat => cat.parent !== null && cat.parent.id === color.id))}
                        </Collapse>
                    </div>
                );
            })
        } else {
            return (
                <div className={classes.tableCellNoResult}>
                    <FormattedMessage id={"retailer.list.table.no_result"}/>
                </div>
            )
        }
    }

    return (
        <Dialog
            open={isOpen}
            fullWidth={true}
            scroll={'paper'}
            onClose={closeDialog}
        >
            <DialogTitle>
                <FormattedMessage id={"color.form.move.title"} />
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <FormContainer>
                    <GridItem xs={12} sm={12}>
                        <Divider />
                        <Input
                            placeholder={intl.formatMessage({id: "color.form.move.search"})}
                            onChange={(e) => setSearch(e.target.value.toLowerCase())}
                            className={classes.dialogSearch}
                            autoFocus
                            disableUnderline
                        />
                        <Divider />
                    </GridItem>
                    <GridItem xs={12} sm={12}>
                        <List disablePadding className={classes.list}>
                            {renderSetTopParent()}
                            {search.length > 0 ?
                                renderColorChildren(allColors.filter(color => color.name.toLowerCase().includes(search)))
                                :
                                renderColorChildren(allColors.filter(color => color.parent === null))
                            }
                        </List>
                    </GridItem>
                    {colorSelected && (
                        <GridItem xs={12} sm={12}>
                            <Divider />
                            <div className={classes.hierarchy}>
                                <Hierarchy
                                    moveAttribute={true}
                                    newParent={newParent}
                                    attributeSelected={colorSelected}
                                    attributeName={'color'}
                                />
                            </div>
                            <Divider />
                        </GridItem>
                    )}
                </FormContainer>
                <ActionsContainer>
                    <CancelButton onClick={closeDialog} />
                    <MoveButton
                        onClick={(e) => submit(e)}
                        disabled={submitting}
                        loading={updateLoading}
                    />
                </ActionsContainer>
                <Divider />
            </DialogContent>
        </Dialog>
    )
}

const mapStateToProps = state => {
    return {
        updated: state.color.update.updated,
        updateLoading: state.color.update.loading,
    }
};

const mapDispatchToProps = dispatch => ({
    update: (item, values) => dispatch(update(item, values)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(injectIntl(MoveDialog));
