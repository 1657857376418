import React, {Component} from "react";
import {Field, reduxForm} from "redux-form";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import {BeatLoader} from 'react-spinners';

// @mui/material components
import {Grid} from "@mui/material";

// core components
import Button from "components/button/button";
import Input from "components/input/input";
import GridContainer from "components/grid/gridContainer";
import GridItem from "components/grid/gridItem";
import Card from "components/card/card";
import CardHeader from "components/card/cardHeader";
import CardBody from "components/card/cardBody";

// styles
import completeProfileFormStyle from "assets/jss/views/completeProfileFormStyle";
import {withStyles} from "@mui/styles";

class CompleteProfileForm extends Component {
    static propTypes = {
        handleSubmit: PropTypes.func.isRequired,
        error: PropTypes.string
    };

    renderField = data => {
        const {classes} = this.props;

        return (
            <div className={classes.inputContainer}>
                <span className={classes.inputLabel}>{data.label}:</span>
                <Input
                    {...data.input}
                    disableUnderline
                    inputProps={{
                        placeholder: data.placeholder,
                        type: data.type,
                        disabled: data.disabled,
                        required: data.required,
                        ...data.input,
                        autoComplete: data.autoComplete,
                    }}
                    type={data.type}
                    required={data.required}
                    id={`profile_${data.input.name}`}
                    formControlProps={{
                        className: classes.profileInputs
                    }}
                    value={data.input.value}
                />
            </div>
        );
    };

    render() {
        const {classes} = this.props;
        const isLoading = this.props.initialValues.loading;

        return (
            <form onSubmit={this.props.handleSubmit}>
                <GridContainer>
                    <GridItem xs={12}>
                        <Card className={classes.card}>
                            <CardHeader color="info">
                                <GridContainer>
                                    <GridItem xs={9} sm={10}>
                                        <h3><FormattedMessage id={"user.profile.show.title"}/></h3>
                                    </GridItem>
                                </GridContainer>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <Grid item xs={12}>
                                        <Field
                                            component={this.renderField}
                                            label={<FormattedMessage id={"user.profile.show.input.firstName"}/>}
                                            name="givenName"
                                            type="text"
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Field
                                            component={this.renderField}
                                            label={<FormattedMessage id={"user.profile.show.input.lastName"}/>}
                                            name="familyName"
                                            type="text"
                                            required
                                        />
                                    </Grid>
                                </GridContainer>
                            </CardBody>
                            <div className={classes.actionsContainer}>
                                <Button round color={"success"} type="submit">
                                    <FormattedMessage id={'user.profile.show.save.button'}/>
                                    <div className={classes.submitLoading}>
                                        <BeatLoader
                                            sizeUnit={"px"}
                                            size={4}
                                            color={"#FFF"}
                                            loading={isLoading}
                                        />
                                    </div>
                                </Button>
                            </div>
                        </Card>
                    </GridItem>
                </GridContainer>
            </form>
        );
    }
}

export default reduxForm({
    form: "completeProfile",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
})(withStyles(completeProfileFormStyle)(CompleteProfileForm));
