import {
    warningColor,
    primaryColor,
    dangerColor,
    successColor,
    infoColor,
    roseColor,
    grayColor,
    defaultFont,
    blackMagicColor,
} from "../../main";

const tableStyle = theme => ({
    link: {
        "&": {
            backgroundColor: "transparent",
            boxShadow: "none",
            textDecoration: "none",
            color: "#252422",
        },
        "&:hover,&:focus": {
            color: "#0052cb",
            fontWeight: "300",
        },

    },
    warningTableHeader: {
        color: warningColor
    },
    primaryTableHeader: {
        color: primaryColor
    },
    dangerTableHeader: {
        color: dangerColor
    },
    successTableHeader: {
        color: successColor
    },
    infoTableHeader: {
        color: infoColor
    },
    roseTableHeader: {
        color: roseColor
    },
    grayTableHeader: {
        color: grayColor
    },
    hidden: {
        display: "none"
    },
    dialogClose: {
        marginRight: "30px",
        marginBottom: "30px"
    },
    table: {
        marginBottom: "0",
        width: "100%",
        maxWidth: "100%",
        backgroundColor: "transparent",
        borderSpacing: "0",
        borderCollapse: "collapse"
    },
    tableHeadCell: {
        ...defaultFont,
        color: "inherit",
        fontSize: "1em",
        fontWeight: "400!important"
    },
    tableCellCenter: {
        ...defaultFont,
        lineHeight: "1.42857143",
        padding: "12px 8px",
        verticalAlign: "middle",
        textAlign: "center",
    },
    tableCellXL: {
        ...defaultFont,
        lineHeight: "1.42857143",
        padding: "2px",
        verticalAlign: "middle",
        width: "180px"
    },
    tableRow: {
        cursor: "pointer",
    },
    tableCellNoResult: {
        ...defaultFont,
        textAlign: "center",
        fontWeight: "bold",
        verticalAlign: "middle"
    },
    tableResponsive: {
        width: "100%",
        marginTop: theme.spacing(3),
    },
    statusIconEnabled: {
        color: successColor,
    },
    statusIconDisabled: {
        color: dangerColor,
    },
    beatLoader: {
        position: "absolute",
        right: "30px",
        margin: "0 auto",
        top: "26px",
        display: "flex",
        justifyContent: "center"
    },
    propagateLoader: {
        position: "absolute",
        left: "0",
        right: "0",
        margin: "0 auto",
        top: "48%",
        display: "flex",
        justifyContent: "center"
    },
    tableCellComplementaryContent: {
        fontSize: "0.6rem",
        color: blackMagicColor
    },
    image: {
        maxWidth: "60px",
        maxHeight: "60px",
    },
    typography: {
        padding: theme.spacing(2),
        cursor: "pointer",
        "&:hover": {
            background: primaryColor,
            color: "white",
        },
    },
    mobileActionLink: {
        color: blackMagicColor,
        textDecoration: "none"
    },
    "@media (min-width: 600px)": {
        mobileOnly: {
            display: "none"
        },
        desktopOnly: {
            display: "visible"
        },
    },
    "@media (max-width: 599px)": {
        mobileOnly: {
            display: "visible"
        },
        desktopOnly: {
            display: "none"
        },
    }
});

export default tableStyle;
