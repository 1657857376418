import React, {Component} from "react";
import {connect} from "react-redux";
import {injectIntl, FormattedMessage} from "react-intl";
import {Field, reduxForm} from "redux-form";
import {PropagateLoader} from 'react-spinners';

// @mui/material components
import {withStyles} from "@mui/styles";
import { Switch} from "@mui/material";

// core components
import Card from "components/card/card";
import CardBody from "components/card/cardBody";
import GridContainer from "components/grid/gridContainer";
import GridItem from "components/grid/gridItem";
import Input from "components/input/input";
import ActionsContainer from "components/form/ActionsContainer";
import FormContainer from "views/configuration/components/FormContainer";
import CancelButton from "views/configuration/components/CancelButton";
import SubmitButton from "views/configuration/components/SubmitButton";

// styles
import formStyle from "assets/jss/views/roles/formStyle";
import {primaryColor} from "assets/jss/main";

// helpers
import {capitalize} from "utils/capitalize";
import {Authorizations} from "../../../utils/authorizations";

class UpdateForm extends Component {
    state = {
        selectedAuthorization: []
    };

    componentDidMount() {
        const {selectedAuthorization} = this.state;
        const {initialValues} = this.props;

        if(selectedAuthorization.length === 0) {
            let newAuthorizations = [];

            initialValues.authorizations.forEach(authorization => {
                newAuthorizations.push(authorization["@id"]);
            });

            this.setState({selectedAuthorization: newAuthorizations});
        }
    }

    renderTitle = data => {
        const {classes, updateError} = this.props;

        return (
            <div className={classes.fieldContainer}>
                <label htmlFor={`title_${data.input.name}`} className={classes.fieldLabel}>
                    {data.label}
                </label>
                <Input
                    {...data.input}
                    type={data.type}
                    required={data.required}
                    id={`title_${data.input.name}`}
                    defaultValue={data.defaultValue}
                    inputProps={{
                        placeholder: data.placeholder,
                        type: data.type,
                        required: data.required,
                        ...data.input,
                        autoComplete: data.autoComplete,
                    }}
                    formControlProps={{
                        className: classes.formControl
                    }}
                />
                {updateError && updateError.errors && updateError.errors["name"] &&
                <span className={classes.errorMessages}>
                    <FormattedMessage id={updateError.errors["name"]}/>
                </span>
                }
            </div>
        );
    };

    renderAuthorizations = data => {
        return (
            <Switch
                color="primary"
                onChange={() => this.handleChangeSwitch(data)}
                checked={data.checked}
                value={data.id}
            />
        );
    };

    handleChangeSwitch(data) {
        const {selectedAuthorization} = this.state;

        let index = selectedAuthorization.indexOf(data.id);

        if (index === -1) {
            selectedAuthorization.push(data.id);
        } else {
            selectedAuthorization.splice(index, 1);
        }

        data.input.onChange(selectedAuthorization);

        this.setState({selectedAuthorization});
    }

    getAuthorizationGroup(name) {
        for (let item in Authorizations) {
            let authorizationName = Authorizations[item];

            if (authorizationName === name) {
                return authorizationName.substring(0, authorizationName.indexOf("."))
            }
        }
    }

    isChecked(id) {
        const {selectedAuthorization} = this.state;

        let isChecked = false;

        selectedAuthorization.forEach(authorization => {
            if(authorization === id) {
                isChecked = true;
            }
        });

        return isChecked;
    };

    render() {
        const {classes, intl, values, authorizationsLoading, authorizationsList, closeDialog, updateLoading, submitting} = this.props;

        const isFormValid = !! (values && values.name && (values.authorizations && values.authorizations.length > 0));

        let authorizationsOption = [];
        authorizationsOption["global"] = [];
        authorizationsOption["marketplace"] = [];
        authorizationsOption["picking"] = [];
        authorizationsOption["personal_shopper"] = [];
        authorizationsOption["stock_easy"] = [];
        authorizationsOption["statistics"] = [];
        authorizationsOption["other"] = [];

        authorizationsList && authorizationsList["hydra:member"].forEach(authorization => {
            if (!process.env.REACT_APP_RETURN_ENABLED && (authorization.name === Authorizations.PICKING_RETURN_MANAGEMENT || authorization.name === Authorizations.PICKING_RETURN_ADMIN)) {
                return;
            }
            const authorizationGroup = this.getAuthorizationGroup(authorization.name);

            if (authorizationsOption[authorizationGroup]) {
                authorizationsOption[authorizationGroup].push(authorization);
            }
        });

        return (
            <form onSubmit={this.props.handleSubmit}>
                <FormContainer>
                    <GridItem xs={12} sm={12}>
                        <Field
                            component={this.renderTitle}
                            name={"name"}
                            label={intl.formatMessage({id: "role.form.label.title"})}
                            type={"text"}
                            format={(value) => value ? capitalize(value) : ""}
                            placeholder={"Titre"}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12}>
                        <h4>{<FormattedMessage id={"role.form.label.authorizations"}/>}</h4>
                    </GridItem>
                    <GridItem xs={12} sm={12}>
                        {authorizationsLoading &&
                        <div className={classes.propagateLoader}>
                            <PropagateLoader
                                sizeUnit={"px"}
                                size={16}
                                color={primaryColor}
                                loading={authorizationsLoading}
                            />
                        </div>
                        }
                        {!authorizationsLoading && Object.keys(authorizationsOption).map((key) => (
                            <div className={classes.fieldContainer} key={key}>
                                <label className={classes.fieldLabel}>
                                    {<FormattedMessage id={`role.form.label.${key}`}/>}:
                                </label>
                                <GridContainer>
                                    {authorizationsOption[key].map(authorization => (
                                        <GridItem xs={12} sm={6} key={authorization["@id"]}>
                                            <Card className={classes.card}>
                                                <CardBody>
                                                    <GridContainer>
                                                        <GridItem className={classes.authorizationName} xs={10} sm={10}>
                                                            <span>{authorization.name}</span>
                                                        </GridItem>
                                                        <GridItem xs={2} sm={2}>
                                                            <Field
                                                                checked={this.isChecked(authorization["@id"])}
                                                                id={authorization["@id"]}
                                                                component={this.renderAuthorizations}
                                                                name={"authorizations"}
                                                            />
                                                        </GridItem>
                                                    </GridContainer>
                                                </CardBody>
                                            </Card>
                                        </GridItem>
                                    ))}
                                </GridContainer>
                            </div>
                        ))}
                    </GridItem>
                </FormContainer>
                <ActionsContainer>
                    <CancelButton onClick={closeDialog} />
                    <SubmitButton
                        isEditing={true}
                        disabled={!isFormValid || submitting}
                        loading={updateLoading}
                    />
                </ActionsContainer>
            </form>
        )
    }
}

const mapStateToProps = state => {
    return {
        updateError: state.role.update.error,
        updateLoading: state.role.update.loading,
        values: state.form && state.form.updateRole && state.form.updateRole.values
    }
};


export default reduxForm({
    form: "updateRole",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
})(connect(
    mapStateToProps,
    null
)(withStyles(formStyle)(injectIntl(UpdateForm))));
