import {fetch} from "../../utils/dataAccess";

export function downloadExport(startDate = null, endDate = null, organization = null) {
    let params = '';

    if (null !== startDate) params += `&startDate=${startDate.format("Y-M-D")}`;
    if (null !== endDate) params += `&endDate=${endDate.format("Y-M-D 23:59:59")}`;

    return dispatch => {
        fetch("/picking/order/export?" + params, {method: 'GET'}).catch(error => {
            console.log(error)
        });
    }
}

export function downloadExportLineItem(startDate = null, endDate = null, organization = null) {
    let params = '';

    if (null !== startDate) params += `&startDate=${startDate.format("Y-M-D")}`;
    if (null !== endDate) params += `&endDate=${endDate.format("Y-M-D 23:59:59")}`;

    return dispatch => {
        fetch("/picking/lineItem/export?" + params, {method: 'GET'}).catch(error => {
            console.log(error)
        });
    }
}
