import React from 'react';
import {styled} from '@mui/material/styles';
import {borderGrayColor} from 'assets/jss/main';

const StyledDiv = styled('div')(({theme}) => ({
    display: 'flex',
    gridArea: 'price',
    flexDirection: 'column',
    width: '100%',
    padding: 0,
    '& > span:nth-child(odd)': {
        paddingTop: '24px',
        borderTop: `1px solid ${borderGrayColor}`,
    },
    '& > span:nth-child(even)': {
        paddingBottom: '24px',
    },
    '& > span:last-child': {
        padding: 0,
        [theme.breakpoints.down('sm')]: {
            paddingBottom: '32px',
            borderBottom: `1px solid ${borderGrayColor}`,
        },
    },
    [theme.breakpoints.down('sm')]: {
        '& > span:first-child': {
            paddingTop: '32px',
        },
    },
}));

export default function GridPriceDetailLine({...props}) {
    return (
        <StyledDiv>
            {props.children}
        </StyledDiv>
    );
}
