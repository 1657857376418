import {combineReducers} from "redux";

export function retrieveVariationPriceStockDataError(state = null, action) {
    switch (action.type) {
        case "VARIATION_PRICE_STOCK_DATA_RETRIEVE_ERROR":
            return action.retrieveError;

        case "VARIATION_PRICE_STOCK_DATA_RESET":
            return null;

        default:
            return state;
    }
}

export function retrieveVariationPriceStockDataLoading(state = false, action) {
    switch (action.type) {
        case "VARIATION_PRICE_STOCK_DATA_RETRIEVE_LOADING":
            return action.retrieveLoading;

        case "VARIATION_PRICE_STOCK_DATA_RESET":
            return false;

        default:
            return state;
    }
}

export function retrieveVariationPriceStockData(state = null, action) {
    switch (action.type) {
        case "VARIATION_PRICE_STOCK_DATA_RETRIEVE_SUCCESS":
            return action.retrieved;

        case "VARIATION_PRICE_STOCK_DATA_RESET":
            return null;

        default:
            return state;
    }
}

export default combineReducers({
    retrieveVariationPriceStockDataError,
    retrieveVariationPriceStockDataLoading,
    retrieveVariationPriceStockData,
});
