import React from 'react';
import PropTypes from 'prop-types';

import {grayColor} from "assets/jss/main";

PrinterName.propTypes = {
    printer: PropTypes.object.isRequired
};

export default function PrinterName({printer}) {
    return (
        <span style={!printer.isOnline ? {color: grayColor} : {}}>
            {printer.name}
        </span>
    );
}
