import {fetch} from 'utils/dataAccess';

export default async function create(quoteId, note) {
    const body = {
        quote: quoteId,
        note: note,
    };

    return new Promise((resolve, reject) => {
        fetch(`/quote_internal_notes`, {
            method: 'POST',
            body: JSON.stringify(body)
        })
            .then(response => {
                response.json().then(response => {
                    resolve(response);
                });
            })
            .catch(error => reject(error));
    });
}
