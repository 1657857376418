export const purpleLine = {
        label: '',
        fill: true,
        borderColor: "#762EB9",
        pointBorderColor: "#762EB9",
        backgroundColor: "rgba(118, 46, 185,0.4)",
        pointRadius: 3,
        pointHoverRadius: 6,
        pointBorderWidth: 6,
        data: []
};

export const blueLine =  {
    label: '',
    fill: true,
    borderColor: "#0052cb",
    pointBorderColor: "#0052cb",
    backgroundColor: "rgba(60, 128, 251,0.4)",
    pointRadius: 3,
    pointHoverRadius: 6,
    pointBorderWidth: 6,
    data: []
};

export const defaultData = {
    datasets: [blueLine, purpleLine]
}

export const defaultOptions = {
    legend: {
        display: false
    },
    tooltips: {
        mode: "point"
    },
    scales: {
        xAxes: [{
            type: "time",
            time: {
                unit: "day",
                parser: "YYYYMMDD",
                displayFormats: {
                    millisecond: "D MMMM",
                    second: "D MMMM",
                    minute: "D MMMM",
                    hour: "D MMMM",
                    day: "D MMMM"
                },
                tooltipFormat: "D MMMM YYYY",
            },
            distribution: "linear",
        }],
        yAxes: [{
            ticks: {
                beginAtZero: true,
                callback(value) {
                    return Number(value).toLocaleString()
                },
            },
            scaleLabel: {
                display: true,
                labelString: ''
            }
        }]
    }
}
