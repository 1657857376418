import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import {FormattedMessage} from "react-intl";
import {fetch} from "utils/dataAccess";
import {useSnackbar} from "notistack";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import EditIcon from '@mui/icons-material/Edit';

export default function ActivateOfferActionModal(props) {
    const {open, setOpen, setSelectAction, selectedProducts, selectAll} = props;
    const { enqueueSnackbar } = useSnackbar();

    const handleAction = () => {
        handleClose();

        let headers = new Headers();
        headers.set('Accept', 'application/json');

        let body = props.buildBody();
        if (selectAll) {
            body = {
                ...body,
                batchAction: 'activate'
            };
        } else {
            body = {
                batchAction: 'activate',
                ids: selectedProducts
            };
        }

        fetch("/merchandising/batch", {method: "POST", body: JSON.stringify(body), headers})
            .then(response => {
                if (response.ok) {
                    enqueueSnackbar(
                        <FormattedMessage
                            id={"merchandising.batch_action.card.success"}
                        />,
                        {
                            variant: 'success'
                        }
                    );
                } else {
                    enqueueSnackbar(
                        <FormattedMessage
                            id={"merchandising.batch_action.card.error"}
                        />,
                        {
                            variant: 'error'
                        }
                    );
                }
            });
    };

    const handleClose = () => {
        setOpen(false);
        setSelectAction('');
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle
                id="alert-dialog-title"
                sx={{
                    marginX: 'auto'
                }}
            >
                <FormattedMessage id={"merchandising.batch_action.activate.modal.title"}/>
            </DialogTitle>
            <Divider />
            <DialogContent>
                <DialogContentText
                    id="alert-dialog-description"
                    sx={{
                        textAlign: 'center'
                    }}
                >
                    <FormattedMessage id={"merchandising.batch_action.activate.modal.body"}/>
                </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ padding: '15px'}}>
                <Button
                    onClick={handleClose}
                    startIcon={<ArrowBackIosIcon />}
                >
                    <FormattedMessage id={"merchandising.batch_action.modal.cancel"}/>
                </Button>
                <Button
                    onClick={handleAction}
                    variant="contained"
                    autoFocus
                    startIcon={<EditIcon/>}
                    sx={{
                        borderRadius: 28
                    }}
                >
                    <FormattedMessage id={"merchandising.batch_action.activate.modal.save"}/>
                </Button>
            </DialogActions>
        </Dialog>
    );
}
