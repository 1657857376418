import {fetch, normalize} from "../../utils/dataAccess";
import {SubmissionError} from "redux-form";

export function uploadError(error) {
    return {type: "FEED_UPLOAD_ERROR", error};
}

export function uploadLoading(loading) {
    return {type: "FEED_UPLOAD_LOADING", loading};
}

export function uploadSuccess(uploaded) {
    return {type: "FEED_UPLOAD_SUCCESS", uploaded};
}

export function handleFileUpload(file) {
    let formData = new FormData();
    formData.append("file", file[0]);
    formData.append("type", "csv_feed");

    return dispatch => {
        dispatch(uploadLoading(true));

        return fetch("/media_objects", {
            method: "POST",
            body: formData
        })
            .then((response) => {
                response.json()
                .then(retrieved => {
                    dispatch(uploadSuccess(normalize(retrieved)));
                    dispatch(uploadLoading(false));
                })
            })
            .catch(e => {
                dispatch(uploadLoading(false));

                if (e instanceof SubmissionError) {
                    dispatch(uploadError(e.errors._error));
                    throw e;
                }

                dispatch(uploadError(e.message));
            });
    }
}