import React, {useContext, useEffect} from "react";
import PropTypes from "prop-types";
import {injectIntl} from "react-intl";
import {useFormikContext} from "formik";

import RadioGroup from "components/radioGroup/RadioGroup";
import FormControlLabel from "components/radioGroup/FormControlLabel";
import SelectTitle from "components/radioGroup/SelectTitle";

import {CategoryFilter} from "utils/recommendation";

import {RecommendationFormContext} from "../../context/RecommendationFormContext";

function CategoryFilterField(props) {
    const { formFieldName, intl, formName, type } = props;

    const formikContext = useFormikContext();

    const {getValueByFormFieldName} = useContext(RecommendationFormContext);

    const value = getValueByFormFieldName(formFieldName);
    const recommendedProduct = getValueByFormFieldName(`${formName}.${type}.recommendedProduct`)
    const disabled = recommendedProduct && recommendedProduct['@type'] === 'Category'

    useEffect(() => {
        if (disabled) {
            formikContext.setFieldValue(formFieldName, CategoryFilter.SAME)
        }
    }, [disabled])

    return (
        <>
            <SelectTitle
                titleTranslationKey={'recommendations.form.filter.category.title'}
                subtitleTranslationKey={'recommendations.form.filter.select_filter'}
                disabled={disabled}
            />
            <RadioGroup
                disabled={disabled}
                selectedValueLabel={intl.formatMessage({id: `recommendations.form.filter.category.${value}`})}
                name={formFieldName}
                value={value}
                onChange={(event) => {
                    formikContext.setFieldValue(formFieldName, event.currentTarget.value)
                }}
            >
                {Object.values(CategoryFilter).map((filterName, index) => (
                    <FormControlLabel
                        key={index}
                        value={filterName}
                        label={intl.formatMessage({id: `recommendations.form.filter.category.${filterName}`})}
                    />
                ))}
            </RadioGroup>
        </>
    )
}

CategoryFilterField.propTypes = {
    formFieldName: PropTypes.string.isRequired,
    formName: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
}

export default injectIntl(CategoryFilterField);
