import React from "react";
import {BeatLoader} from 'react-spinners';

// @mui/material components
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles(theme => ({
    loader: {
        marginLeft: "10px",
        color: "#FFFFFF"
    },
}));

export default function ListLoader (props) {
    const classes = useStyles();

    return (
        <div className={classes.loader}>
            <BeatLoader
                sizeUnit={"px"}
                size={4}
                color={"#FFF"}
                loading={true}
            />
        </div>
    );
}
