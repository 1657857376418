
const rowStyle = theme => ({
    row: {
        margin: '10px 0',
        display: 'flex',
        padding: '10px',
        backgroundColor: 'white',
    },
    one: {
        width: '10%',
        textAlign: 'left',
    },
    two: {
        width: '20%',
        textAlign: 'left',
    },
    bold: {
        fontWeight: 'bold',
    },
});
export default rowStyle;
