import React, {useState, useEffect, useContext} from 'react';
import {FilterDispatchContext, FilterContext} from "contexts/filterContext";
import SubTabFilterPanel from "views/marketplace/merchandising/filter/subTabFilterPanel";
import TabsContainer from "views/marketplace/merchandising/filter/tabsContainer";

export function accessibiltyProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

export function renderChildrenTab(parent, allItems, lastSelected, setLastSelected) {
    const children = allItems.filter(child => child.parent && child.parent.id === parent.id);
    return (
        <SubTabFilterPanel
            items={children}
            allItems={allItems}
            selectedParent={parent}
            lastSelected={lastSelected}
            setLastSelected={setLastSelected}
        />
    );
}

export default function TabFilterPanel({items, lastSelected, setLastSelected}) {
    const allItems = items;
    const topItems = allItems.filter(item => item.parent === null);
    const [selected, setSelected] = useState(null);
    const dispatch = useContext(FilterDispatchContext);
    const {filters} = useContext(FilterContext);

    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleClick = (item) => {
        setSelected(item);
        setLastSelected(item);

        const children = allItems.filter(child => child.parent && child.parent.id === item.id);
        children.forEach(child => {
            dispatch({
                type: 'filter_remove',
                item: child
            });
        });
    };

    useEffect(() => {
        setValue(0);
        if (lastSelected) {
            let item = lastSelected;
            while (item.parent !== null) {
                item = item.parent;
            }
            setSelected(item);
        }

        if (filters.length === 0) {
            setSelected(null);
            setValue(0);
        }
    }, [filters]);

    return (
        <TabsContainer
            items={topItems}
            handleClick={handleClick}
            value={value}
            handleChange={handleChange}
        >
            {selected && renderChildrenTab(selected, allItems, lastSelected, setLastSelected)}
        </TabsContainer>
    );
}
