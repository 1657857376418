import React, {useState} from "react";
import { FormattedMessage } from "react-intl";

// @mui/material components
import { Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText } from "@mui/material";

// core components
import Button from "components/button/button";
import Loader from '../../configuration/components/loader';

// actions
import {disable} from "../../../api/corner/disable";
import {getTranslation} from "../../../domain/helpers/translations";

function DisableDialog(props) {
    const { corner, closeDialog, isOpen, disabled } = props;

    const [loading, setLoading] = useState(false);

    const disableCorner = () => {
        if (!corner) {
            return;
        }
        setLoading(true);
        disable(corner.id).then(() => disabled()).finally(() => closeDialog(true));
    };

    return (
        <Dialog
            open={isOpen}
            onClose={closeDialog}
            maxWidth={'xs'}
            scroll={'body'}
        >
            <DialogTitle>
                <FormattedMessage
                    id={"corner.disable.title"}
                    values={{ corner: getTranslation(corner).name }}
                />
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <FormattedMessage id={"corner.disable.text"} />
                </DialogContentText>
            </DialogContent>
                <DialogActions>
                    <Button color={"info"} simple onClick={closeDialog} disabled={loading}>
                        <FormattedMessage id={"corner.delete.cancel"} />
                    </Button>
                    <Button
                        round
                        color={"danger"}
                        type="submit"
                        disabled={loading}
                        onClick={() => disableCorner(corner)}
                    >
                        <FormattedMessage id={"corner.disable.submit"} />
                        {loading && <Loader />}
                    </Button>
                </DialogActions>
        </Dialog>
    )
}

export default DisableDialog;
