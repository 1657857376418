export const ProductFilter = {
    lock: {
        type: 'Lock',
        property: 'lock',
        values: {
            ALL: 'all',
            PRODUCT: 'product',
            NAME: 'name',
            CATEGORIES: 'categories',
            DESCRIPTION: 'description'
        }
    }
}

export const VariationFilter = {
    lock: {
        type: 'Lock',
        property: 'lock',
        values: {
            ALL: 'all',
            VARIATION: 'variation',
            NAME: 'name',
            STOCK: 'stock',
            WEIGHT: 'weight',
            PRICE: 'price',
            OUTLET_PRICE: 'outletPrice',
            DISCOUNTED_PRICE: 'discountedPrice'
        }
    }
}
