import {SubmissionError} from "redux-form";
import {
    fetch,
    normalize,
} from "../../../utils/dataAccess";
import {success as createSuccess} from "./create";
import {loading, error} from "./delete";
import {retrieveSuccess as feedRetrieveSuccess} from "../update";

export function retrieveError(retrieveError) {
    return {type: "MAPPER_UPDATE_RETRIEVE_ERROR", retrieveError};
}

export function retrieveLoading(retrieveLoading) {
    return {type: "MAPPER_UPDATE_RETRIEVE_LOADING", retrieveLoading};
}

export function retrieveSuccess(retrieved) {
    return {type: "MAPPER_UPDATE_RETRIEVE_SUCCESS", retrieved};
}

export function retrieve(id) {
    return dispatch => {
        dispatch(retrieveLoading(true));
        dispatch(retrieveError(""));

        return fetch(id)
            .then(response =>
                response.json()
            )
            .then((retrieved) => {
                retrieved = normalize(retrieved);

                dispatch(retrieveLoading(false));
                dispatch(retrieveSuccess(retrieved));
            })
            .catch(e => {
                dispatch(retrieveLoading(false));
                dispatch(retrieveError(e.message));
            });
    };
}

export function updateError(updateError) {
    return {type: "MAPPER_UPDATE_UPDATE_ERROR", updateError};
}

export function updateLoading(updateLoading) {
    return {type: "MAPPER_UPDATE_UPDATE_LOADING", updateLoading};
}

export function updateSuccess(updated) {
    return {type: "MAPPER_UPDATE_UPDATE_SUCCESS", updated};
}

export function update(item, values, fid, type) {
    const apiValues = ["@context", "@id", "@type", "configuration", "feed", "feedId","feedPlatform"];

    Object.keys(values).map((item) => {
        if (!apiValues.includes(item)) {
            values.configuration[item]["value"] = values[item] !== "none" ? values[item] : null;
            delete values[item]
        }

        return values;
    });

    for (var value in values.configuration) {
        if (values.configuration[value].selectedLabel) {
            delete values.configuration[value].selectedLabel
        }
    }

    const bindex = fid.indexOf("/")
    const eindex = fid.lastIndexOf("/") + 1

    values.feedType = type;
    values.feedId = fid.replace(fid.substring(bindex, eindex), "");

    return dispatch => {
        dispatch(updateError(""));
        dispatch(error(""));
        dispatch(updateSuccess(null));
        dispatch(updateLoading(true));

        return fetch(item["@id"], {
            method: "PUT",
            headers: new Headers({"Content-Type": "application/ld+json"}),
            body: JSON.stringify(values)
        })
            .then(response =>
                response.json()
            )
            .then((retrieved) => {
                retrieved = normalize(retrieved);

                dispatch(updateLoading(false));
                dispatch(updateSuccess(retrieved));
            })
            .catch(e => {
                dispatch(updateLoading(false));

                if (e instanceof SubmissionError) {
                    dispatch(updateError(e.errors._error));
                    throw e;
                }

                dispatch(updateError(e.message));
            });
    };
}

export function reset() {
    return dispatch => {
        dispatch({type: "MAPPER_UPDATE_RESET"});
        dispatch(feedRetrieveSuccess(null));
        dispatch(error(null));
        dispatch(loading(false));
        dispatch(createSuccess(null));
    };
}
