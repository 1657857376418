import React, { useContext, useEffect, useLayoutEffect } from "react";
import connect from "react-redux/es/connect/connect";
import 'react-dates/initialize';

// @mui/material components
import {withStyles} from "@mui/styles";

// core components
import DashboardDateRangePicker from "components/dashboardDateRangePicker";
import GridContainer from "components/grid/gridContainer";
import GridItem from "components/grid/gridItem";

// styles
import dashboardStyle from "assets/jss/views/dashboard/dashboardStyle.js";

// actions
import {
    reset,
    retrieveQuotesData
} from "actions/dashboard";

import {reset as resetAnalyticsList} from "actions/analytics/list";

// helpers
import abort from "utils/abort";

import AverageQuote from "./components/averageQuote";
import DetailsQuotes from "./components/detailsQuotes";
import QuotesByValueGraph from "./components/graphs/quotesByValueGraph";
import QuotesByVolumeGraph from "./components/graphs/quotesByVolumeGraph";
import QuoteCount from "./components/quoteCount";
import GreetMessage from "views/marketplace/dashboard/components/greetMessage";
import SectorGraph from "views/marketplace/dashboard/components/graphs/sectorGraph";
import Turnover from "./components/turnover";

// context
import {DashboardDateRangePickerContext} from "contexts/dasboardDateRangePickerContext";

function Dashboard(props) {
    const {
        applications,
        classes,
        dashboardQuotesData,
        dashboardQuotesDataLoading,
        member
    } = props;

    const {
        startDate,
        endDate,
        unit,
        dateChangedTrigger,
        unitParser,
        unitTooltip
    } = useContext(DashboardDateRangePickerContext);

    useEffect(() => {
        props.retrieveQuotesData(
            startDate.format("Y-M-D"),
            endDate.format("Y-M-D"),
            unit
        );
    }, [dateChangedTrigger]);

    // componentWillUnmount
    useLayoutEffect(() => {
        return () => {
            abort.abortRequests();
            props.reset();
        }
    }, [])

    return (
        <div className={classes.containerWithSidebar}>
            <div className={classes.container}>
                <div className={classes.dashboardHeader}>
                    <GreetMessage name={member['givenName']} />
                    <div>
                        <DashboardDateRangePicker />
                    </div>
                </div>
                <GridContainer>
                    <GridItem xs={12} md={9} lg={10}>
                        <GridContainer>
                            <GridItem xs={12} sm={6} className={classes.cardContainer}>
                                <QuotesByValueGraph
                                    onlineTurnoverGraph={dashboardQuotesData?.["online"]["turnoverGraph"] ?? []}
                                    loading={dashboardQuotesDataLoading}
                                    unit={unit}
                                    unitParser={unitParser}
                                    unitTooltip={unitTooltip}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={6} className={classes.cardContainer}>
                                <QuotesByVolumeGraph
                                    onlineCountGraph={dashboardQuotesData?.["online"]["countGraph"] ?? []}
                                    loading={dashboardQuotesDataLoading}
                                    unit={unit}
                                    unitParser={unitParser}
                                    unitTooltip={unitTooltip}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4} className={classes.cardContainer}>
                                <AverageQuote
                                    loading={dashboardQuotesDataLoading}
                                    quotesCount={dashboardQuotesData?.["online"]["countTotal"]}
                                    quotesTurnover={dashboardQuotesData?.["online"]["turnoverTotal"]}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4} className={classes.cardContainer}>
                                <QuoteCount
                                    loading={dashboardQuotesDataLoading}
                                    quotesCount={dashboardQuotesData?.["online"]["countTotal"]}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4} className={classes.cardContainer}>
                                <Turnover
                                    loading={dashboardQuotesDataLoading}
                                    quotesTurnover={dashboardQuotesData?.["online"]["turnoverTotal"]}
                                />
                            </GridItem>
                            <GridItem xs={12}>
                                <SectorGraph
                                    loading={dashboardQuotesDataLoading}
                                    sectors={dashboardQuotesData?.sector}
                                    title={"dashboard.quotes.sector.title"}
                                    isQuoteDashboard={true}
                                />
                            </GridItem>
                        </GridContainer>
                    </GridItem>
                    <GridItem xs={12} md={3} lg={2}>
                        <DetailsQuotes
                            canClick={applications["picking"]}
                            loading={dashboardQuotesDataLoading}
                            states={dashboardQuotesData?.["statusCount"]}
                        />
                    </GridItem>
                </GridContainer>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        member: state.authentication.member,
        applications: state.authentication.applications,
        dashboardQuotesData: state.dashboard.retrievedQuotesData,
        dashboardQuotesDataLoading: state.dashboard.retrieveQuotesDataLoading,
        currentOrganization: state.currentOrganization.retrieved ?? null,
    };
};

const mapDispatchToProps = dispatch => ({
    retrieveQuotesData: (startDate, endDate, unit) => dispatch(retrieveQuotesData(startDate, endDate, unit)),
    reset: () => dispatch(reset()),
    resetAnalyticsList: () => dispatch(resetAnalyticsList())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(dashboardStyle)(Dashboard));
