import React from 'react';
import MerchandisingContainer from "views/marketplace/merchandising/table";
import {MerchandisingProvider} from "contexts/merchandisingContext";
import {FilterProvider} from "contexts/filterContext";
import {ProductDataProvider} from "contexts/productDataContext";
import {PusherProvider} from "contexts/pusherContext";
import {SnackbarProvider} from "notistack";

function Merchandising() {
    return (
        <PusherProvider>
            <SnackbarProvider>
                <ProductDataProvider>
                    <MerchandisingProvider>
                        <FilterProvider>
                            <MerchandisingContainer />
                        </FilterProvider>
                    </MerchandisingProvider>
                </ProductDataProvider>
            </SnackbarProvider>
        </PusherProvider>
    );
}
export {Merchandising};
