import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";

// @mui/material components
import {withStyles} from "@mui/styles";

// core components
import Button from "@mui/material/Button";

// styles
import buttonStyle from "../../assets/jss/components/button/buttonStyle";

function RegularButton({...props}) {
    const {
        classes,
        color,
        round,
        children,
        fullWidth,
        transparent,
        disabled,
        simple,
        size,
        block,
        link,
        justIcon,
        className,
        ...rest
    } = props;
    const btnClasses = classNames({
        [classes.button]: true,
        [classes[size]]: size,
        [classes[color]]: color,
        [classes.round]: round,
        [classes.fullWidth]: fullWidth,
        [classes.transparent]: transparent,
        [classes.disabled]: disabled,
        [classes.simple]: simple,
        [classes.block]: block,
        [classes.link]: link,
        [classes.justIcon]: justIcon,
        [className]: className
    });
    return (
        <Button {...rest} className={btnClasses}>
            {children}
        </Button>
    );
}

RegularButton.propTypes = {
    classes: PropTypes.object.isRequired,
    color: PropTypes.oneOf([
        "primary",
        "secondary",
        "info",
        "success",
        "warning",
        "danger",
        "rose",
        "white",
        "black",
        "transparent"
    ]),
    size: PropTypes.oneOf(["sm", "lg", "medium"]),
    simple: PropTypes.bool,
    transparent: PropTypes.bool,
    round: PropTypes.bool,
    fullWidth: PropTypes.bool,
    disabled: PropTypes.bool,
    block: PropTypes.bool,
    link: PropTypes.bool,
    justIcon: PropTypes.bool
};

export default withStyles(buttonStyle)(RegularButton);
