import React from "react";
import {Route} from "react-router-dom";
import {VariationList, ProductShow, ProductList} from "../../views/marketplace/retailer/product/index";
import RequireAuthentication from "../../hoc/authentication/requireAuthentication";
import RetailerLoader from "../../hoc/retailer/retailerLoader";
import {Authorizations, hasAuthorization} from "../../utils/authorizations";
import * as Cookies from "js-cookie";

let routes = [];
const applications = Cookies.get("_a") ? JSON.parse(Cookies.get("_a").toString()) : null;
const authorizations = Cookies.get("_b") ? JSON.parse(Cookies.get("_b").toString()) : [];

if (applications && applications["marketplace"]) {
    if (hasAuthorization(authorizations, Authorizations.MARKETPLACE_PRODUCT_VIEW) || hasAuthorization(authorizations, Authorizations.MARKETPLACE_PRODUCT_MANAGEMENT)) {
        routes.push([
            <Route
                path="/retailers/show/:id/products/variations/:params"
                component={RequireAuthentication(RetailerLoader(VariationList))}
                exact
                strict
                key="variationList"
            />,
            <Route
                path="/retailers/show/:id/products/:params"
                component={RequireAuthentication(RetailerLoader(ProductList))}
                exact
                strict
                key="list"
            />,
        ])
    }

    if (hasAuthorization(authorizations, Authorizations.MARKETPLACE_PRODUCT_MANAGEMENT)) {
        routes.push([
            <Route
                path="/retailers/show/:id/products/show/:prid"
                component={RequireAuthentication(RetailerLoader(ProductShow))}
                exact
                strict
                key="productShow"
            />
        ])
    }
}

export default routes;
