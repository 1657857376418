import React, {useState} from "react";
import { ThemeProvider } from '@mui/material/styles';
import {createTheme, Divider, Fab, TextField, StyledEngineProvider} from "@mui/material";
import {withStyles} from "@mui/styles";

import metadataStlye from "../../assets/jss/views/components/metadatasStyle";
import Metadata from "./metadata";
import {Add, Close, Done} from "@mui/icons-material";
import {dangerColor, primaryColor, successColor} from "../../assets/jss/main";

function MetadataContainer ({initialMetadata, metadataChanged, classes}) {
    const [metadata, setMetadata] = useState(initialMetadata);
    const [addFormDisplayed, displayForm] = useState(false);
    const [keyInputValue, setKeyInputValue] = useState('');
    const [valueInputValue, setValueInputValue] = useState('');

    const onDeleteMetadataItem = (key) => {
        if (!metadata[key]) {
            return;
        }

        const tmpMetadata = {...metadata};
        delete tmpMetadata[key];
        setMetadata(tmpMetadata);
        metadataChanged(tmpMetadata);
    };

    const onAddMetadataItem = () => {
        const tmpMetadata = {...metadata};
        tmpMetadata[keyInputValue] = valueInputValue;
        setMetadata(tmpMetadata);
        setKeyInputValue('');
        setValueInputValue('');
        metadataChanged(tmpMetadata);
    };

    const onMetadataChanged = (newKey, value, currentKey) => {
        Object.keys(metadata).forEach(keyIt => {
            if (keyIt === currentKey) {
                const tmpMetadata = {...metadata};
                // update the value
                if (newKey === currentKey) {
                    tmpMetadata[currentKey] = value;
                } else { // update the key
                    delete tmpMetadata[currentKey];
                    tmpMetadata[newKey] = value;
                }

                setMetadata(tmpMetadata);
                metadataChanged(tmpMetadata);
            }
        });
    };

    const theme = createTheme({
        palette: {
            primary: {main: primaryColor},
            success: {main: successColor},
            secondary: {main: dangerColor},
        },
    });

    return (
        <div className={classes.metadataContainer}>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <span className={classes.title}>Metadatas</span>
                    <Fab
                        color={addFormDisplayed ? 'secondary' : 'primary'}
                        aria-label="add"
                        onClick={() => displayForm(!addFormDisplayed)} className={classes.addMetadata}
                        size={'small'}
                    >
                        {!addFormDisplayed && <Add fontSize={'small'}/>}
                        {addFormDisplayed && <Close fontSize={'small'}/>}
                    </Fab>

                    {addFormDisplayed && (
                        <div className={classes.newMetadata}>
                            <TextField
                                variant="standard"
                                className={classes.input}
                                placeholder={'Nouvelle clé'}
                                value={keyInputValue}
                                onChange={(e) => setKeyInputValue(e.target.value)} />

                            <TextField
                                variant="standard"
                                className={classes.input}
                                placeholder={'Nouvelle valeur'}
                                value={valueInputValue}
                                onChange={(e) => setValueInputValue(e.target.value)} />

                            <Fab
                                aria-label="add"
                                onClick={onAddMetadataItem}
                                size={'small'}
                                style={{backgroundColor: successColor, color: "white"}}
                            >
                                <Done fontSize={'small'}/>
                            </Fab>
                            <Divider className={classes.divider}/>
                        </div>
                    )}
                    <div>
                        {Object.keys(metadata).map(key => (
                            <Metadata
                                key={key}
                                metadataKey={key}
                                metadataValue={metadata[key]}
                                metadataChanged={(newKey, value) => onMetadataChanged(newKey, value, key)}
                                onDelete={onDeleteMetadataItem}
                            />
                        ))}
                    </div>
                </ThemeProvider>
            </StyledEngineProvider>
        </div>
    );
}

export default withStyles(metadataStlye)(MetadataContainer);
