import { fetch } from "../../utils/dataAccess";

export function error(error) {
    return {type: "COLOR_LIST_ERROR", error};
}

export function loading(loading) {
    return {type: "COLOR_LIST_LOADING", loading};
}

export function success(retrieved) {
    return {type: "COLOR_LIST_SUCCESS", retrieved};
}

export function colorList(params = "pagination=false&draft=false") {
    return dispatch => {
        dispatch(loading(true));

        fetch(`/colors?${params}`).then(response => {
            response.json().then(retrieved => {
                if(params.includes('retailer=') && retrieved && retrieved["hydra:member"].length > 0) {
                    retrieved["hydra:member"] =  orderColorRetrieved(retrieved["hydra:member"]);
                }

                dispatch(loading(false));
                dispatch(success(retrieved["hydra:member"]));
            })
        }).catch(error => {
            // canceled
        });
    };
}

export function orderColorRetrieved(colorRetrieved) {
    let colorTree = [];

    colorRetrieved.forEach(color => {
        if(!colorExist(color, colorTree)) {
            colorTree.push(color);

            if (color.parent !== null) {
                colorTree = getColorParents(color.parent, colorTree)
            }
        }
    });

    return colorTree
}

export function getColorParents(color, colorTree) {
    if(!colorExist(color, colorTree)) {
        colorTree.push(color);

        if(color.parent !== null) {
            colorTree = getColorParents(color.parent, colorTree);
        }
    }

    return colorTree;
}

export function colorExist(color, colorTree) {
    let i;

    for (i = 0; i < colorTree.length; i++) {

        if (colorTree !== "undefined" && color !== undefined && colorTree[i].id === color.id) {
            return true;
        }
    }

    return false;
}

export function reset() {
    return dispatch => {
        dispatch({type: "COLOR_LIST_RESET"});
        dispatch(loading(false));
        dispatch(error(null));
        dispatch(success(null))
    };
}
