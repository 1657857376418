import React, {Component} from 'react';

import DrawerHelp from "../../../components/sidebar/lineItems/DrawerHelp";
import {ITEM_PER_PAGE} from "config/_pagination";
import DrawerRetailers from "../../../components/sidebar/lineItems/DrawerRetailers";
import {stockEasyOffer} from "../../enum/offers";
import DrawerPwa from "../../../components/sidebar/lineItems/DrawerPwa";
import {Authorizations, hasAuthorization} from "../../../utils/authorizations";
import DrawerRetailerProfiles from "../../../components/sidebar/lineItems/DrawerRetailerProfiles";

class StockEasyMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            productTabOpen: this.props.match.path.startsWith("/retailers/show/:id/feeds/") || this.props.match.path.startsWith("/retailers/show/:id/products/") || this.props.match.path.startsWith("/retailers/show/:id/variations/"),
            itemsPerPage: this.props.itemsPerPage ? this.props.itemsPerPage : ITEM_PER_PAGE,
        };
    }

    render() {
        const {authorizations, classes, match, member} = this.props;

        return (
            <div>
                <DrawerRetailers
                    authorizations = {authorizations}
                    match = {match}
                    classes = {classes}
                    offer={stockEasyOffer}
                    member={member}
                />

                {(hasAuthorization(authorizations, Authorizations.OTHER_HELPDESK_MERCHAND) || hasAuthorization(authorizations, Authorizations.OTHER_HELPDESK_CLIENT)) &&
                    <DrawerHelp
                        classes={classes}
                        authorizations={authorizations}
                    />
                }
                <DrawerRetailerProfiles classes={classes} />
                <DrawerPwa classes={classes} />
            </div>
        )
    }
}

export default StockEasyMenu;
