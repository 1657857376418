import React from "react";
import PropTypes from "prop-types";
import {createTheme} from "@mui/material/styles";

import {styled} from "@mui/styles";

import Content from "../NoteBlock/Content";
import CustomerAddCommentMessage from "../Messages/CustomerAddCommentMessage";
import Header from "../NoteBlock/Header";
import NoteBlock from "../NoteBlock";
import NoteCardContent from "../NoteBlock/NoteCardContent";

import {lighterBlack, lightGrayColor} from "assets/jss/main";

import {QUOTE_CUSTOMER_NOTE} from "../../../../../enums/quoteNoteType";

CustomerNote.propTypes = {
    content: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    customerName: PropTypes.string.isRequired,
    email: PropTypes.string,
    externalNumber: PropTypes.string,
};

export default function CustomerNote({content = '', createdAt = '', customerName = '', email, externalNumber = '' }) {

    const customerNoteTheme = createTheme({
        palette: { primary: { main: lighterBlack } },
    });

    const CustomerAddCommentMessageMobileContainer = styled('div')(({ theme }) => ({
        padding: '24px 16px 0px 16px',
        [theme.breakpoints.up('md')]: {
            display: 'none'
        },
    }));

    return !!content && (
        <NoteBlock theme={customerNoteTheme}>
            <NoteCardContent>
                <Header
                    subtitle={email}
                    title={externalNumber}
                    date={createdAt}
                    centerContent={<CustomerAddCommentMessage customerName={customerName} />}
                    noteType={QUOTE_CUSTOMER_NOTE}
                />
                <div style={{ borderTop: `1px solid ${lightGrayColor}`}}>
                    <CustomerAddCommentMessageMobileContainer>
                        <CustomerAddCommentMessage customerName={customerName} />
                    </CustomerAddCommentMessageMobileContainer>
                    <Content content={content} />
                </div>
            </NoteCardContent>
        </NoteBlock>
    )
}
