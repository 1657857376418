import React, {useState, useContext, useEffect} from "react";
import {
    Checkbox,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@mui/material";
import MerchandisingRow from "./MerchandisingRow";
import {FormattedMessage} from "react-intl";
import CircularProgress from '@mui/material/CircularProgress';
import {primaryColor} from "assets/jss/main";
import TableSortLabel from '@mui/material/TableSortLabel';
import {MerchandisingDispatchContext} from "contexts/merchandisingContext";

export default function MerchandisingTableContent({products, selectedProducts, handleSelectSingle, handleGlobalCheckbox, globalCheck, loading})
{
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('ranking');
    const noProducts = products && products.length === 0;
    const dispatch = useContext(MerchandisingDispatchContext);

    const tableHeads = [
        {
            id: 'ranking',
            align: 'left',
            numeric: true,
            label: 'merchandising.show.column.position',
            enableSorting: true,
        },
        {
            id: 'article',
            align: 'left',
            numeric: false,
            label: 'merchandising.show.column.article',
            enableSorting: false,
        },
        {
            id: 'productName',
            align: 'left',
            numeric: false,
            label: 'merchandising.show.column.name',
            enableSorting: false,
        },
        {
            id: 'brand',
            align: 'left',
            numeric: false,
            label: 'merchandising.show.column.brand',
            enableSorting: false,
        },
        {
            id: 'updatedAt',
            align: 'left',
            numeric: false,
            label: 'merchandising.show.column.updated_at',
            enableSorting: false,
        },
        {
            id: 'highestPrice',
            align: 'left',
            numeric: false,
            label: 'merchandising.show.column.priceWithTax',
            enableSorting: false,
        },
        {
            id: 'status',
            align: 'left',
            numeric: false,
            label: 'merchandising.show.column.stock_status',
            enableSorting: false,
        },
    ];

    const handleSorting = (property) => () => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    useEffect(() => {
        dispatch({
            type: 'sort',
            sortField: orderBy,
            sortDirection: order,
        });
    }, [order, orderBy]);

    function renderRow(products) {
        if (noProducts) {
            if (loading == false) {
                return (
                    <TableRow>
                        <TableCell colSpan={8} align="center">
                            <FormattedMessage id={"merchandising.show.products.empty"}/>
                        </TableCell>
                    </ TableRow>
                );
            } else {
                return (<CircularProgress sx={{color: primaryColor}} />);
            }
        } else {
            return (
                <>
                    {products.map((item) => {
                        const isSelected = (selectedProducts.indexOf(item.id) !== -1);
                        return <MerchandisingRow
                            product={item}
                            key={item.id}
                            isSelected={isSelected}
                            handleSelectSingle={handleSelectSingle}
                        />
                    })}
                </>
            );
        }
    }

    return (
        <>
            <TableHead sx={{'& .MuiTableCell-head': {'paddingX': '4px'}}}>
                <TableRow>
                    <TableCell>
                        <Checkbox
                            disabled={noProducts}
                            onChange={handleGlobalCheckbox}
                            checked={globalCheck}
                        />
                    </TableCell>
                    {tableHeads.map((tableHead) => (
                        <TableCell
                            key={tableHead.id}
                            align={tableHead.align}
                            sortDirection={orderBy === tableHead.id ? order : false}
                        >
                            {tableHead.enableSorting ?
                                <TableSortLabel
                                    active={orderBy === tableHead.id}
                                    direction={orderBy === tableHead.id ? order : 'asc' }
                                    onClick={handleSorting(tableHead.id)}
                                >
                                    <FormattedMessage id={tableHead.label}/>
                                </TableSortLabel>
                                :
                                <FormattedMessage id={tableHead.label}/>
                            }
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {loading
                    ?
                    <TableRow>
                        <TableCell colSpan={8} align="center">
                            <CircularProgress sx={{color: primaryColor}} />
                        </TableCell>
                    </ TableRow>
                    :
                    renderRow(products)
                }
            </TableBody>
        </>
    )
}
