import React, {Component} from "react";
import {Authorizations, hasAuthorization} from "utils/authorizations";
import {Collapse, ListItem, ListItemIcon, ListItemText} from "@mui/material";
import {ExpandLess, StoreOutlined} from "@mui/icons-material";
import {FormattedMessage} from "react-intl";
import {capitalize} from "utils/capitalize";
import {Link} from "react-router-dom";

// domain helpers
import {getStockEasyLink} from "domain/helpers/StockEasyHelper";

class StockEasyRetailersDrawer extends Component {
    renderShops = (retailersRetrieved) => {
        const {classes} = this.props;

        // in case there are more than one shops associated to the user retailer
        const retailersRendered = retailersRetrieved.map(retailer => {
            return (
                <ListItem
                    button
                    className={classes.listItem}
                    classes={{selected: classes.selected}}
                    component={Link}
                    to={getStockEasyLink(retailer['@id']).replace('/retailers/', '')}
                    key={"retailer" + retailer['@id']}
                    selected={this.props.match.path === "/retailers/show/:id"}
                >
                    <ListItemText
                        primaryTypographyProps={{color: "initial"}}
                        primary={capitalize(retailer["name"])}
                        className={classes.stockEasyRetailerTitle}
                    />
                </ListItem>
            );
        });

        return (
            <div>
                {retailersRendered}
            </div>
        )
    }

    render() {
        const {authorizations, classes, match} = this.props;
        const retailersRetrieved = this.props.member.retailers;
        // todo: if I want to get the names of the retailers, I need to get it differently

        const isInStockEasyPath = (
            match.path === "/retailers/:params"
            || match.path === "/stockeasy/show/retailers/:id/products/:params"
            || match.path === "stockeasy/show/retailers/:id/products/variations/:params"
            || match.path === "/stockeasy/retailers/:id/product/create"
        );

        if (hasAuthorization(authorizations, Authorizations.STOCK_EASY_ACCESS)) {
            return (
                <div>
                    <ListItem
                        className={classes.listItem}
                        classes={{selected: (isInStockEasyPath) ? classes.selected : classes.subListSelected}}
                        key={"easyStockRetailerList"}
                        selected={isInStockEasyPath}
                    >
                        <ListItemIcon>
                            <StoreOutlined color={isInStockEasyPath ? "primary" : "secondary"}/>
                        </ListItemIcon>
                        <ListItemText
                            primaryTypographyProps={{color: isInStockEasyPath ? "primary" : "secondary"}}
                            primary={<FormattedMessage id={"sidebar.item.stockEasyList"}/>}
                        />
                        {retailersRetrieved && <ExpandLess color={"primary"}/>}
                    </ListItem>
                    <Collapse in={Boolean(retailersRetrieved)}>
                        {retailersRetrieved &&
                            this.renderShops(retailersRetrieved)
                        }
                    </Collapse>

                </div>
            )
        }
    }
}

export default StockEasyRetailersDrawer;
