import {container, darkGrayColor, defaultFont, primaryColor} from "../../main";

const updateStyle = theme => ({
    containerWithSidebar: {
        [theme.breakpoints.up("md")]: {
            marginLeft: "240px",
        }
    },
    container: {
        ...container,
        ...defaultFont,
    },
    card: {
        minHeight: window.innerHeight * 0.94
    },
    cardHeader: {
        minHeight: "68px"
    },
    profileName: {
        marginTop: "20px",
        fontWeight: "800"
    },
    mainActionsContainer: {
        marginTop: "20px",
        textAlign: "right",
        padding: "0px"
    },
    gridContainer: {
        borderBottom: "1px solid #eee",
        padding: "5px 0"
    },
    label: {
        color: darkGrayColor,
        fontWeight: "400"
    },
    itemValue: {
        textAlign: "right"
    },
    itemDescription: {
        maxWidth: "50%"
    },
    "@media (max-width: 599px)": {
        profileName: {
            fontSize: "10px"
        }
    },
    inputContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '16px',
        marginRight: '16px',
        marginBottom: '10px',
    },
    profileInputs: {
        padding: "0px",
        width: "100%"
    },
    inputLabel: {
        color: primaryColor,
        fontWeight: "bold"
    },
    saveButton: {
        width: '180px',
        borderRadius: '40px',
        backgroundColor: '#58c75c',
        height: '36px',
        color: '#ffff'

    },
    pictureContainer: {
        position: "relative",
        marginTop: "20px",
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        borderRadius: "25px",
        "& div:last-child": {
            borderWidth: "0px!important",
            width: "140px!important",
            height: "140px!important",
            overflow: "hidden",
            "& img": {
                width: "140px",
                maxHeight: "140px",
                maxWidth: "140px",
                borderRadius: "18%"
            },
            "& input": {
                display: "none"
            }
        }
    },
    picturePlaceholderContent: {
        display: "flex",
        alignItems: "center",
        textShadow: "1px 0px 0px #444",
        "& svg": {
            filter: "drop-shadow( 1px 1px 1px #444)"
        }
    },
    pictureIcon: {
        position: "absolute",
        zIndex: "1",
        color: "#FFFFFF",
        pointerEvents: "none",
        marginLeft:"50px",
        textAlign: "center",
        marginTop: "-12px"
    },
    pictureContentFlex: {
        display: "flex",
        alignItems: "center"
    },
    pictureText: {
        color: "#FFFFFF",
        zIndex: "1",
        position: "absolute",
        fontSize: "large",
        pointerEvents: "none",
        fontWeight: "400",
        marginLeft:"16px",
        textAlign: "center",
        marginTop: "12px"
    },
    picturePlaceholder: {
        borderRadius: "25%",
        width: "140px",
        height: "140px",
        background: "linear-gradient(60deg, #377dff, #0052cb)",
    },
    pictureLoading: {
        position: "absolute",
        zIndex: "1",
        color: primaryColor,
        left: "50px",
        pointerEvents: "none",
    },
    actionsContainer: {
        position: "absolute",
        right: "20px",
        bottom: "15px",
        textAlign: "right"
    },
    submitLoading: {
        marginLeft: "5px",
        color: "#FFFFFF"
    },
    beatLoader: {
        position: "absolute",
        right: "30px",
        margin: "0 auto",
        top: "35px",
        display: "flex",
        justifyContent: "center"
    },
});

export default updateStyle;
