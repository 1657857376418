import React from "react";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";

import FormattedNumberWithCurrency
    from "components/formattedNumberWithCurrencies/formattedNumberWithCurrency";

import {
    TableCell,
    TableRow
} from "@mui/material";

export default function Totals(props) {
    const { amount, commissionDebit, commissionCredit, currency } = props

    const emptyCells = (
        <>
            <TableCell/>
            <TableCell/>
            <TableCell/>
            <TableCell/>
            <TableCell/>
        </>
    );

    return (
        <>
            <TableRow >
                {emptyCells}
                <TableCell align="right">
                    <strong>
                        <FormattedMessage id={"accounting.payout_list.footer.totals.amount"} /> :&nbsp;
                    </strong>
                    <FormattedNumberWithCurrency
                        value={amount / 100}
                        currency={currency}
                    />
                </TableCell>
            </TableRow>
            <TableRow>
                {emptyCells}
                <TableCell align={"right"}>
                    <strong>
                        <FormattedMessage id={"accounting.payout_list.footer.totals.commissionDebit"} /> :&nbsp;
                    </strong>
                    <FormattedNumberWithCurrency
                        value={commissionDebit / 100}
                        currency={currency}
                    />
                </TableCell>
            </TableRow>
            <TableRow>
                {emptyCells}
                <TableCell align={"right"}>
                    <strong>
                        <FormattedMessage id={"accounting.payout_list.footer.totals.commissionCredit"} /> :&nbsp;
                    </strong>
                    <FormattedNumberWithCurrency
                        value={commissionCredit / 100}
                        currency={currency}
                    />
                </TableCell>
            </TableRow>
        </>
    )
}

Totals.defaultProps = {
    amount: 0,
    commissionDebit: 0,
    commissionCredit: 0,
    currency: 'EUR'
}

Totals.propTypes = {
    amount: PropTypes.number.isRequired,
    commissionDebit: PropTypes.number.isRequired,
    commissionCredit: PropTypes.number.isRequired,
    currency: PropTypes.string
};
