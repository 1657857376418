import { fetch } from "../../utils/dataAccess";

export function error(error) {
    return {type: "GENDER_LIST_ERROR", error};
}

export function loading(loading) {
    return {type: "GENDER_LIST_LOADING", loading};
}

export function success(retrieved) {
    return {type: "GENDER_LIST_SUCCESS", retrieved};
}

export function genderList(params = "pagination=false&draft=false") {
    return dispatch => {
        dispatch(loading(true));

        fetch(`/genders?${params}`).then(response => {
            response.json().then(retrieved => {
                dispatch(loading(false));
                dispatch(success(retrieved["hydra:member"]));
            })
        }).catch(error => {
            // canceled
        });
    };
}

export function reset() {
    return dispatch => {
        dispatch({type: "GENDER_LIST_RESET"});
        dispatch(loading(false));
        dispatch(error(null));
        dispatch(success(null))
    };
}
