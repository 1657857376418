import React from 'react'
import PropTypes from "prop-types";

import {makeStyles} from "@mui/styles";

import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import TrackingNumbers from "./TrackingNumbers";

const useStyle = makeStyles({
    downloadIcon: {
        paddingBottom: '3px',
        paddingLeft: '2px',
        cursor: 'pointer'
    }
})

export default function DownloadShipmentFile({shipmentLabelLink}) {
    const classes = useStyle();

    function openUrlInOtherTab(url) {
        window.open(url, '_blank', 'noreferrer')
    }

    return (
        <FileDownloadOutlinedIcon
            onClick={() => openUrlInOtherTab(shipmentLabelLink)}
            className={classes.downloadIcon}
        />
    )
}

TrackingNumbers.defaultProps = {
    shipmentLabelLink: '',
}

TrackingNumbers.propTypes = {
    shipmentLabelLink: PropTypes.string.isRequired,
}
