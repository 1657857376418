import {fetch} from "utils/dataAccess";

export default async function ship(shipment)  {
    const response = await fetch(`/shipments/${shipment.id}/ship`, {
        method: "POST",
        body: JSON.stringify({}),
    });

    return await response.json();
}
