import React, {useContext} from "react";
import {injectIntl} from "react-intl";
import GridItem from "../../../../../components/grid/gridItem";
import QuoteDetailHeaderInfosAddress from "./headerAddress";
import {objectEmpty} from "../../../../../utils/objectEmpty";
import {QuoteContext} from "../../../../../contexts/quoteContext";
import {withStyles} from "@mui/styles";
import detailStyle from "../../../../../assets/jss/views/quote/detailStyle";

const QuoteDetailHeaderInfosShippingAddress = ({ classes, intl }) => {
    const { quote } = useContext(QuoteContext);

    let shippingAddress = {
        firstname: quote.customerShippingFirstname ?? null,
        lastname: quote.customerShippingLastname ?? null,
        company: quote.customerShippingCompany ?? null,
        street: quote.customerShippingAddress?.street ?? null,
        postalCode: quote.customerShippingAddress?.postalCode ?? null,
        city: quote.customerShippingAddress?.city ?? null,
        provinceName: quote.customerShippingAddress?.provinceName ?? null,
        country: quote.customerShippingAddress?.country ?? null,
    };

    if (objectEmpty(shippingAddress)) {
        return null;
    }

    return (
        <GridItem md={6}>
            <p>
                {intl.formatMessage({id: "picking.quote.detail.header.shippingAddress"})}<br/>
                <small className={classes.cardHeaderAddress}>
                    <QuoteDetailHeaderInfosAddress address={shippingAddress}/>
                </small>
            </p>
        </GridItem>
    );
};

export default withStyles(detailStyle)(injectIntl(QuoteDetailHeaderInfosShippingAddress));
