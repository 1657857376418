import React, {useContext} from 'react';

import {red500} from "assets/jss/main";

import {ImageDropFieldContext} from "../context/ImageDropFieldContext";

function Errors(props) {
    const  {fileRejections} = useContext(ImageDropFieldContext);

    return (
        <div style={{ color: red500, fontSize: '12px', fontWeight: 400 }}>
            {fileRejections.map(({ file, errors }) => (
                <li key={file.path} style={{ listStyleType:'none' }}>
                    <ul style={{ padding: 0, margin: 0}}>
                        {errors.map(e => (
                            <li key={e.code} style={{ listStyleType:'none' }}>
                                {e.message}
                            </li>
                        ))}
                    </ul>
                </li>
            ))}
        </div>
    );
}

export default Errors;
