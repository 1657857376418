import React from "react";

import {Button} from "@mui/material";

import {styled} from '@mui/material/styles';
import {
    infoColorHover,
    infoColor,
    infoColorDisabled
} from "assets/jss/main";

const StyledButton = styled(Button)({
    backgroundColor: infoColor,
    borderRadius: '8px',
    textTransform: 'none',
    '&:hover': {
        backgroundColor: infoColorHover
    },
    '&.Mui-disabled' : {
        backgroundColor: infoColorDisabled,
        color: '#fff'
    }
})

export default function SaveButton({...props}) {
    return (
        <StyledButton
            variant="contained"
            disableElevation
            {...props}
        >
            {props.children}
        </StyledButton>
    )
}
