import {getTranslation} from "../../domain/helpers/translations";
import store from '../../store';

export default function hydrator(values) {
    const isPushed = values.eshopId !== null;
    let cornerEshopUrl = null;
    if (isPushed) {
        const slug = getTranslation(values).slug;
        const currentOrganization = store.getState()?.currentOrganization?.retrieved;
        if (!!slug && !!currentOrganization) {
            cornerEshopUrl = `${currentOrganization.eshopUrl}/taxons/corners/${slug}`;
        }
    }
    values.eshopUrl = cornerEshopUrl;

    for (const [locale, translation] of Object.entries(values.translations)) {
        if (translation.description === null) {
            values.translations[locale].description = '';
        }
    }

    return values;
}
