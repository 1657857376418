import React from "react";
import {BeatLoader} from 'react-spinners';

// @mui/material components
import {makeStyles} from "@mui/styles";

// style
import {primaryColor} from "assets/jss/main";
import PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
    loader: {
        position: "absolute",
        right: "10px",
        top: "5px"
    },
}));

export default function Loader(props) {
    const classes = useStyles();

    return (
        <div className={classes.loader}>
            <BeatLoader
                sizeUnit={"px"}
                size={10}
                color={primaryColor}
                loading={props.loading}
            />
        </div>
    );
}

Loader.defaultProps = {
    loading: false
};

Loader.propTypes = {
    loading: PropTypes.bool,
};
