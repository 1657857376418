import React, {Component} from "react";
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";
import PropTypes from "prop-types";

// @mui/material components
import {withStyles} from "@mui/styles";

// @mui/icons-material
import {NotificationsActive} from "@mui/icons-material";

// core components
import Form from "./form";
import Snackbar from "components/snackbar/snackbar";
import CreateCard from "../components/CreateCard";

// styles
import updateStyle from "assets/jss/views/feed/updateStyle";

// actions
import {create, reset} from "actions/feed/create";
import {change} from "redux-form";

class Create extends Component {
    static propTypes = {
        loading: PropTypes.bool.isRequired,
        created: PropTypes.object,
        create: PropTypes.func.isRequired,
        reset: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);

        this.state = {
            createErrorNotification: true
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.uploaded) {
            this.props.dispatch(change("xmlLocalFeed", "configuration['path']", nextProps.uploaded.contentUrl));
        }

        this.setState({createErrorNotification: true})
    }

    componentWillUnmount() {
        this.props.reset();
    }

    render() {
        if (this.props.created)
            return (
                <Redirect
                    to={".."}
                />
            );

        const {classes} = this.props;

        return (
            <div className={classes.containerWithSidebar}>
                <div className={classes.container}>
                    {this.props.error &&  !this.props.error.errors &&(
                        <Snackbar
                            open={this.state.createErrorNotification}
                            close
                            closeNotification={() => this.setState({createErrorNotification: false})}
                            place={"bl"}
                            color={"danger"}
                            icon={function () {
                                return <NotificationsActive/>
                            }}
                            message={`${this.props.error}`}
                        />
                    )}

                     <CreateCard>
                         {this.props.retailerRetrieved && (
                             <Form
                                 initialValues={{
                                     configuration: {path: this.props.uploaded && this.props.uploaded.contentUrl},
                                     retailer: this.props.member.retailer || this.props.retailerRetrieved["@id"]
                                 }}
                                 onSubmit={this.props.create}
                             />
                         )}
                     </CreateCard>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    const {created, error, loading} = state.feed.create;
    const {member} = state.authentication;
    const {retrieved} = state.retailer.show;
    const {uploaded} = state.feed.upload;

    return {created, error, loading, member, retailerRetrieved: retrieved, uploaded};
};

const mapDispatchToProps = dispatch => ({
    create: values => {
        values.dataType = 'xml';
        values.transportType = 'local';
        dispatch(create(values));
    },
    reset: () => dispatch(reset())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(updateStyle)(Create));
