import React, {Component} from "react";
import {withStyles} from "@mui/styles";
import {injectIntl} from "react-intl";
import {DateRangePicker} from "react-dates";

import moment from "moment";

import listStyle from "assets/jss/views/quote/listStyle";
import {FROM_DATE_QUERY, TO_DATE_QUERY} from "../../enums/urlParametersIdentifier";

class QuoteListDatepicker extends Component {
    constructor(props) {
        super(props);
        let params = new URLSearchParams(this.props.history.location.search);
        if (params.has(FROM_DATE_QUERY) && params.has(TO_DATE_QUERY)) {
            this.state = {
                startDate: moment(params.get(FROM_DATE_QUERY)),
                endDate: moment(params.get(TO_DATE_QUERY)),
            };
            this.handleDatesChange();

            return;
        }

        this.state = {
            startDate: moment().subtract(1, 'months'),
            endDate: moment(),
        };

        this.handleDatesChange();
    }

    handleDatesChange = () => {
        this.props.onSelectDates(this.state.startDate, this.state.endDate);
    }

    updateUrl() {
        let params = new URLSearchParams(this.props.history.location.search); 
        if (!!this.state.startDate && !!this.state.endDate && this.state.startDate <= this.state.endDate) {
            params.set(FROM_DATE_QUERY, this.state.startDate.format("YYYY-M-D"));
            params.set(TO_DATE_QUERY, this.state.endDate.format("YYYY-M-D"));
            const newUrl = `${this.props.history.location.pathname}?${params.toString()}`;
            this.props.history.push(newUrl); // This will trigger component "container->setStateFromSearchParams" function
            this.handleDatesChange();
        }
    }

    render() {
        const { classes, intl } = this.props;

        return (
            <div className={classes.datepicker}>
                <DateRangePicker
                    startDateId="startDate"
                    endDateId="endDate"
                    startDatePlaceholderText={intl.formatMessage({id: "accounting.payout_list.datepicker.from"})}
                    endDatePlaceholderText={intl.formatMessage({id: "accounting.payout_list.datepicker.to"})}
                    startDate={this.state.startDate}
                    endDate={this.state.endDate}
                    onDatesChange={({startDate, endDate}) => {
                        if (null === startDate && null === endDate) { // User clicked on X to clear the input
                            this.setState({startDate: null, endDate: null}, this.updateUrl);
                            return;
                        }

                        this.setState({startDate, endDate}, this.updateUrl)
                    }}
                    isOutsideRange={() => false}
                    focusedInput={this.state.focusedInput}
                    onFocusChange={(focusedInput) => {
                        this.setState({focusedInput})
                    }}
                    onClose={(dates) => this.setState({startDate: dates.startDate, endDate: dates.endDate}, this.updateUrl)}
                    displayFormat={"D MMMM YYYY"}
                    block={true}
                    showDefaultInputIcon={true}
                    showClearDates={true}
                    reopenPickerOnClearDates={true}
                    required={true}
                    hideKeyboardShortcutsPanel={true}
                    withFullScreenPortal={window.innerWidth < 768}
                    numberOfMonths={2}
                    orientation={window.innerWidth < 768 ? "vertical" : "horizontal"}
                    anchorDirection={"left"}
                    minimumNights={0}
                />
            </div>
        );
    }
}

export default withStyles(listStyle)(injectIntl(QuoteListDatepicker));
