import {
    primaryColor,
    lightGrayColor,
    defaultFont
} from "../../main";

const listStyle = theme => ({
   
    primaryTableHeader: {
        color: primaryColor,
        fontSize: "16px",
        fontWeight: "400"
    },
    tableHeadCell: {
        ...defaultFont,
        borderTop: '1px solid',
        borderBottom: '1px solid',
        borderColor: lightGrayColor,
    },
    checkbox: {
        color: primaryColor,
        '&$checked': {
            color: primaryColor,
        },
    },
    checked: {},
    noClick: {
        cursor: 'initial',
        "&:hover, &:focus": {color: primaryColor}
    }
});

export default listStyle;
