import {combineReducers} from "redux";

export function retrieved(state = null, action) {
    switch (action.type) {
        case "ORGANIZATION_CURRENT":
            return action.retrieved;
        default:
            return state;
    }
}

export default combineReducers({retrieved});
