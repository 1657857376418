import React from "react";

import Dashboard from "./dashboard";

import {DashboardDateRangePickerContextProvider} from "contexts/dasboardDateRangePickerContext";

export default function Index() {
    return (
        <DashboardDateRangePickerContextProvider>
            <Dashboard />
        </DashboardDateRangePickerContextProvider>
    )
}
