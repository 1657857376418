import React from "react";

import classNames from "classnames";
import moment from "moment";
import {Field, getIn} from 'formik';

import {withStyles} from "@mui/styles";

import fieldStyle from "../../../assets/jss/views/formik/fieldStyle";

class TimepickerRenderer extends React.Component {
    static supports(props) {
        return 'time' === (props.as ?? props.type);
    }

    render() {
        const {choicesInterval, choices, emptyLabel, classes, formikContext, fieldClassName, name} = this.props;

        let choicesToDisplay = null;
        if (Array.isArray(choices) && choices.length > 0) {
            choicesToDisplay = choices;
        } else if (!!choicesInterval) {
            const midnightToday = moment().hour(0).minute(0).second(0);
            const midnightTomorrow = moment().hour(0).minute(0).second(0).add(1, 'day');

            let relativeTime = midnightToday;
            const tmpChoices = {};

            if (!!emptyLabel) {
                tmpChoices[emptyLabel] = null;
            }
            while (relativeTime <= midnightTomorrow) {
                const time = relativeTime.format('HH:mm');
                tmpChoices[time] = time;
                relativeTime = relativeTime.add(choicesInterval);
            }
            choicesToDisplay = tmpChoices;
        } else {
            throw new Error('You must provide a list of choices')
        }

        const errors = formikContext.errors;
        const fieldIsInError = getIn(errors, name);

        const fieldClasses = classNames({
            [" " + classes.field]: true,
            [" " + classes.fieldInvalid]: !!fieldIsInError && formikContext.touched,
            [" " + (fieldClassName ?? '')]: true,
        });

        return (
            <Field
                as="select"
                name={name}
                className={fieldClasses}
            >
                {Object.keys(choicesToDisplay).map((choiceKey, idx) => <option key={idx} value={choicesToDisplay[choiceKey]}>{choiceKey}</option> )}
            </Field>
        );
    }
}

export default withStyles(fieldStyle)(TimepickerRenderer);
