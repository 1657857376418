import React, {useContext} from "react";
import { } from "@mui/material";
import {withStyles} from "@mui/styles";

import detailStyle from "../../../../../assets/jss/views/quote/detailStyle";
import FormikInput from "../../../../../components/input/formikInput";
import {
    ACCEPTED,
    EXPIRED,
    REFUSED,
} from "../../../enums/state";
import {QuoteContext} from "../../../../../contexts/quoteContext";

const QuoteDetailGridUpdatableCell = ({ classes, value, name, children = null, type = 'number', editActive = false, as = undefined , customInputProps = {}}) => {
    const { quote } = useContext(QuoteContext);
    const isQuoteEditable = (quote.state !== ACCEPTED && quote.state !== REFUSED && quote.state !== EXPIRED);
    return (
        <>
            {!isQuoteEditable && (
                <div>
                    {value}
                </div>
            )}
            {(!editActive && isQuoteEditable) && (
                <div className={classes.formFieldDisplay}>
                    {value}
                </div>
            )}
            {(!!editActive && isQuoteEditable) && (
                <FormikInput
                    inputProps={
                        {
                            name: name,
                            type: type,
                            as: as,
                            containerClassName: classes.formFieldContainer,
                            fieldClassName: classes.formField,
                            children: children,
                            inputMode: 'numeric',
                            ...customInputProps
                        }
                    }
                />
            )}
        </>
    );

};

export default withStyles(detailStyle)(QuoteDetailGridUpdatableCell);
