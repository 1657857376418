import React, {Component} from "react";
import {FormattedMessage, injectIntl} from "react-intl";

// @mui/material components
import {
    Fab,
    Tooltip
} from "@mui/material";

// @mui/icons-material
import {
    Delete,
    KeyboardArrowDown,
    KeyboardArrowUp,
    Publish
} from "@mui/icons-material";

// core components
import Card from "components/card/card";
import CardBody from "components/card/cardBody";
import GridContainer from "components/grid/gridContainer";
import GridItem from "components/grid/gridItem";
import ProductPreview from "./productPreview";
import Select from "react-select";

// styles
import {dangerColor, primaryColor} from "assets/jss/main";
import cardAttributeStyle from "assets/jss/views/attribute/cardAttributeStyle";

// utils
import {capitalize} from "utils/capitalize";
import {withStyles} from "@mui/styles";

class CardAttribute extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            visible: true,
            selectedValue: null,
        }
    }

    getOptions = attributeName => {
        const {colors, categories, genders, sizes} = this.props.attributeRetrieved;

        if (attributeName === "categories" && categories) {
            return categories;
        } else if (attributeName === "colors" && colors) {
            return colors;
        } else if (attributeName === "genders" && genders) {
            genders.forEach((item) => {
                item['name'] = item.value;
            });
            return genders;
        } else if (attributeName === "sizes" && sizes) {
            return sizes;
        }
    };

    listFocused() {
        this.setState({
            open: !this.state.open
        });
    }

    onBlurSelect() {
        this.setState({
            open: false
        });
    }

    tagCategoryAsTrash(item) {
        if (item.trash === true) {
            console.log('category is already trash');
            return false;
        }

        if (!window.confirm(this.props.intl.formatMessage({id: "mapper.form.action.tag_category_as_trash"}))) {
            return false;
        }

        let data = {
            '@id': '',
            'name': item['category_dictionary_id'],
            'trash': true,
        };

        this.handleChange(data);
    }

    tagCategoryAsUntrash(item) {
        if (item.trash === false) {
            console.log('category is not trash');
            return false;
        }

        if (!window.confirm(this.props.intl.formatMessage({id: "mapper.form.action.tag_category_as_untrash"}))) {
            return false;
        }

        let data = {
            '@id': '',
            'name': item['category_dictionary_id'],
            'untrash': true,
        };

        this.handleChange(data);
    }

    displayTrashCategoriesStuff(data) {
        const {classes, activeTab} = this.props;
        const isMappedView = activeTab === 1;

        let content = '';
        if (isMappedView) {
            if (data.trash === false) {
                return;
            }

            content = (
                <div>
                    <Tooltip
                        title={<FormattedMessage id={"mapper.label.untrash_category"}/>}
                    >
                        <Publish onClick={() => this.tagCategoryAsUntrash(data)}/>
                    </Tooltip>
                </div>
            );
        } else {
            content = (<Delete onClick={() => this.tagCategoryAsTrash(data)}/>);
        }

        return (
            <div className={classes.tagCategoryAsTrash}>
                {content}
            </div>
        );
    }

    handleChange = (value) => {
        this.setState({ open: false });
        if (this.props.activeTab !== 1 || value.untrash === true) {
            this.setState({ visible: false });
        } else {
            this.setState({ selectedValue: value });
        }
        this.props.handleChange(value);
    }

    render() {
        const { item, labels, labelName, labelNameId, labelData, attributeRetrieved, valuesFromProps } = this.props;

        const {intl, classes, activeStep} = this.props;
        const {visible} = this.state;
        const isCategoryStep = activeStep === 0;

        const options = this.getOptions(labels["attributeName"]);
        let mappedValues = false;

        if (labels['attributeName'] === 'categories' ) {
            if (options.filter(function (options) {return options[labelNameId] === item[labelNameId]}).length > 0) {
                mappedValues = options.filter(function (options) {return options[labelNameId] === item[labelNameId]})[0];
                const label = mappedValues.name[mappedValues.name.length - 1];
                mappedValues = {[item[labelName]]: {'@id': mappedValues[labelNameId], 'name': item[labelName], 'label': label, 'value': labels["attributeName"]}};
            }
        } else if (labels['attributeName'] === 'colors') {
            if (options.filter(function (options) {return options[labelNameId] === item[labelNameId]}).length > 0) {
                mappedValues = options.filter(function (options) {return options[labelNameId] === item[labelNameId]})[0];
                const label = mappedValues.name[mappedValues.name.length - 1];
                mappedValues = {[item[labelName]]: {'@id': mappedValues[labelNameId], 'name': item[labelName], 'label': label, 'value': labels["attributeName"]}};
            }
        } else if (labels['attributeName'] === 'genders') {
            if (options.filter(function (options) {return options[labelNameId] === item.gender[labelNameId]}).length > 0) {
                mappedValues = options.filter(function (options) {return options[labelNameId] === item.gender[labelNameId]})[0];
                const label = mappedValues.name;
                mappedValues = {[item[labelName]]: {'@id': mappedValues[labelNameId], 'name': item[labelName], 'label': label, 'value': labels["attributeName"]}};
            }
        } else if (labels['attributeName'] === 'sizes') {
            if (options.filter(function (options) {return options[labelNameId] === item.size[labelNameId]}).length > 0) {
                mappedValues = options.filter(function (options) {return options[labelNameId] === item.size[labelNameId]})[0];
                const label = mappedValues.name;
                mappedValues = {[item[labelName]]: {'@id': mappedValues[labelNameId], 'name': item[labelName], 'label': label, 'value': labels["attributeName"]}};
            }
        }

        // This code is not fixable
        // Increment the counter
        // to warn the next guy.

        // hours_wasted = 15
        let isValue = false;
        if (mappedValues && true !== this.state[item[labelName]] && false !== this.state[item[labelName]]) {
            isValue = true;
        }

        if (!visible) {
            return (<div/>);
        }

        if (options && options.length > 0) {
            for(let i = 0; i <  options.length; i++) {
                if (options[i]["@id"] === "") {
                    options.splice(i, 1);
                }
            }

            options.unshift({"@id": "", "name": intl.formatMessage({id: "mapper.form.options.none"})});
        } else {
            options.push({"@id": "", "name": intl.formatMessage({id: "mapper.form.options.none"})});
        }

        let values = mappedValues && true === isValue ? mappedValues : valuesFromProps;
        let defaultValue = values && values[item[labelName]] ? values[item[labelName]].label : '';
        defaultValue = this.state.selectedValue ? this.state.selectedValue.label : defaultValue;
        let optionsValues = [];
        let labelId = labelNameId;
        let label = '';

        const attributeType = labelData;
        const attributeId = item[labelName];
        const imgUrlsByAttribute = attributeRetrieved?.['imgUrlsByAttributes']?.[attributeType]?.[attributeId];

        options && options.map((item) => {
            if (defaultValue && defaultValue === item["@id"]) {
                defaultValue = (item['name'][item['name'].length-1])
            }

            if (Array.isArray(item['name'])) {
                if ('categories' === labelData) {
                    label = item['name'].join(' > ');
                } else {
                    label = item['name'][item['name'].length-1];
                }
            } else {
                label = item['name'];
            }

            optionsValues.push({
                "@id": item[labelId],
                name: attributeId,
                label: label.toLowerCase(),
                value: item.id
            })

            return item;
        });

        if (optionsValues && typeof optionsValues !== undefined) {
            optionsValues = optionsValues.sort(function (a, b) {
                return a.label.length - b.label.length || a.label.localeCompare(b.label)
            });
        }

        const lineColor = defaultValue ? primaryColor : dangerColor;
        const labelLeft = item['name'] ? item['name'] : item['value'];

        return (
            <Card className={classes.fieldContainer}>
                <CardBody>
                    <GridContainer>
                        <GridItem xs={4} sm={4}>
                            <label
                                htmlFor={`attribute_${labelNameId}`}
                                className={classes.fieldAttributeLabel}
                            >
                                {capitalize(typeof labelLeft === "string"? labelLeft : labelLeft.join('->'))}
                            </label>
                        </GridItem>
                        <GridItem className={classes.lineContainer} xs={2} sm={3}>
                            <div>
                                {defaultValue ?
                                    <FormattedMessage id={'mapper.label.configured'}/> :
                                    <FormattedMessage id={'mapper.label.notConfigured'}/>
                                }
                                <div className={classes.line} style={{borderBottom: '2px solid' + lineColor}}>
                                    <div>
                                        <div className={classes.round} style={{background: lineColor}}></div>
                                        <div className={classes.round} style={{background: lineColor}}></div>
                                    </div>
                                </div>
                            </div>
                            <ProductPreview links={imgUrlsByAttribute} />
                        </GridItem>
                        <GridItem xs={6} sm={5} className={classes.spaceEvenlyGrid}>
                            {isCategoryStep && this.displayTrashCategoriesStuff(item)}
                            <GridItem xs={3} sm={3}>
                                <Fab onClick={() => this.listFocused()}
                                     className={classes.listButton}
                                     color="default">
                                    {!this.state.open ?
                                        <KeyboardArrowDown/>
                                        :
                                        <KeyboardArrowUp/>
                                    }
                                </Fab>
                            </GridItem>
                            <GridItem xs={9} sm={9}>
                                {this.state.open && <Select
                                    options={optionsValues}
                                    filterOption={(option, search) => option.label.includes(search.toLowerCase())}
                                    placeholder={<FormattedMessage id={'mapper.select.placeholder'}/>}
                                    id={`attribute_${labelName}`}
                                    onChange={this.handleChange}
                                    defaultMenuIsOpen={true}
                                    className={classes.selectField}
                                    autoFocus={true}
                                    onBlur={() => this.onBlurSelect()}
                                />}
                                {!this.state.open &&
                                    <b className={classes.defaultValueText}>{defaultValue && capitalize(defaultValue)}</b>
                                }
                            </GridItem>
                        </GridItem>
                    </GridContainer>
                </CardBody>
            </Card>
        );
    }
}

export default (withStyles(cardAttributeStyle)(injectIntl(CardAttribute)));
