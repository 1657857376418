import React from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { Field, reduxForm } from "redux-form";

// core components
import AllTranslationsRequiredMessage from "../../configuration/components/AllTranslationsRequiredMessage";
import FormFields from './formFields';
import GridItem from "components/grid/gridItem";
import Hierarchy from '../../configuration/components/hierarchy';
import ActionsContainer from "components/form/ActionsContainer";
import FormContainer from "views/configuration/components/FormContainer";
import CancelButton from "views/configuration/components/CancelButton";
import SubmitButton from "views/configuration/components/SubmitButton";

// action
import { update } from "actions/color/update";

// styles
import FormTranslator from "../../../components/translator/formTranslator";
import {getTranslation} from "../../../domain/helpers/translations";

function EditForm(props) {
    const { color, closeDialog, intl, update, updateLoading, submitting, values } = props;

    const isFormValid = !! (values && getTranslation(values).name);

    function submit(e) {
        e.preventDefault();

        if (color) {
            if (values.description === null) {
                values.description = '';
            }

            if (color.parent === null) {
                delete values.parent;
            } else {
                values.parent = color.parent && color.parent["@id"];
            }

            update(color, values);
        }
    }

    const renderTranslationForm = (index, code) => {
        return (
            <div>
                <GridItem xs={12} sm={12}>
                    <Field
                        component={FormFields}
                        name={`translations.${code}.name`}
                        label={intl.formatMessage({id: color.parent ? "color.form.label.sub.name" : "color.form.label.name"})}
                        type={"text"}
                        placeholder={intl.formatMessage({id: "color.form.placeholder.name"})}
                        required
                    />
                </GridItem>
            </div>
        );
    };


    return (
        <form onSubmit={(e) => submit(e)}>
            <FormContainer>
                <AllTranslationsRequiredMessage />
                {color && (
                    <GridItem xs={12} sm={12}>
                        <Hierarchy attributeName={'color'} attributeSelected={color} />
                    </GridItem>
                )}
                <GridItem xs={12} sm={12}>
                    <FormTranslator formGenerator={renderTranslationForm} />
                </GridItem>
            </FormContainer>
            <ActionsContainer>
                <CancelButton onClick={closeDialog} />
                <SubmitButton
                    isEditing={true}
                    disabled={!isFormValid || submitting}
                    loading={updateLoading}
                />
            </ActionsContainer>
        </form>
    )
}

const mapStateToProps = state => {
    return {
        updateLoading: state.color.update.loading,
        values: state.form && state.form.editColor && state.form.editColor.values,
    }
};

const mapDispatchToProps = dispatch => ({
    update: (item, values) => dispatch(update(item, values)),
});

export default reduxForm({
    form: "editColor",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
})(connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(EditForm)));
