import React from "react";
import PropTypes from 'prop-types';

import {FormattedMessage} from "react-intl";
import {Doughnut} from "react-chartjs-2";

import {makeStyles} from "@mui/styles";

import CardBody from "components/card/cardBody";

import Loader from "../loader";
import CardStats from "../cards/cardStats";
import CardStatsGraphTitle from "../cards/cardStatsGraphTitle";

import {defaultOptions, palette} from "config/graph/defaultDoughnutGraphConfig";

const useStyles = makeStyles(theme => ({
    sectorContainer: {
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
    },
    doughnutContainer: {
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    doughnutLegendContainer: {
        display: 'flex',
        flex: 1,
        justifyContent: 'space-around',
        alignItems: 'flex-start',
        flexDirection: 'row',
        flexWrap: 'wrap',
        paddingTop: '10px'
    },
    doughnutLegendRow: {
        display: 'flex',
        width: '50%',
        alignItems: 'flex-start',
        flexDirection: 'row',
    },
    doughnutLegendRectangle: {
        height: "20px",
        width: "20px",
        marginRight: "20px"
    },
    doughnutLegendLabel: {
        fontSize: '14px',
        color: '#bebebe'
    }
}));

export default function SectorGraph(props) {
    const classes = useStyles();

    const { loading,  sectors, title, isQuoteDashboard } = props

    const sectorKeys = sectors ? Object.keys(sectors) : [];
    let sectorValues = sectors ? Object.values(sectors) : [];

    if (isQuoteDashboard) {
        sectorValues = sectors ? Object.keys(sectors).map((key) => {
                return sectors[key] / 100;
            }) : []
        ;
    }

    const sectorData = {
        datasets: [{
            data: sectorValues,
            backgroundColor: function (context) {
                return palette[context.dataIndex % palette.length];
            },
            labels: sectorKeys
        }]
    };

    return (
        <CardStats>
            <Loader loading={loading} />
            <CardStatsGraphTitle>
                <FormattedMessage id={title ?? "dashboard.sector.title"} />
            </CardStatsGraphTitle>
            <CardBody>
                <div className={classes.sectorContainer}>
                    <div className={classes.doughnutContainer}>
                        <Doughnut
                            data={sectorData}
                            options={defaultOptions}
                        />
                    </div>
                    <div className={classes.doughnutLegendContainer}>
                        {sectorKeys.map((item, key) => {
                            return (
                                <div className={classes.doughnutLegendRow} key={key}>
                                    <div
                                        className={classes.doughnutLegendRectangle}
                                        style={{backgroundColor: palette[key]}}
                                    >
                                    </div>
                                    <div>
                                        <span className={classes.doughnutLegendLabel}>
                                            {item}
                                        </span>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </CardBody>
        </CardStats>
    )
}

SectorGraph.defaultProps = {
    sectors: [],
    isQuoteDashboard: false
}

SectorGraph.propTypes = {
    sectors: PropTypes.array.isRequired,
    loading: PropTypes.bool,
    title: PropTypes.string,
    isQuoteDashboard: PropTypes.bool
};
