import React, {Component} from "react";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import {connect} from "react-redux";

// @mui/material components
import {withStyles} from "@mui/styles";
import {Table, TableHead, TableBody, TableRow, TableCell} from "@mui/material";

// core components
import CustomDate from 'components/date/date';

// styles
import rankingStyle from "assets/jss/views/dashboard/rankingStyle";

// helpers
import {capitalize} from "utils/capitalize";

class RankingTable extends Component {

    render() {
        const {classes, tableHead, tableData, analytics, showTotalViews} = this.props;

        return (
            <div className={classes.tableResponsive}>
                <Table className={classes.table}>
                    {tableHead !== undefined ? (
                        <TableHead>
                            <TableRow>
                                {tableHead.map((prop, key) => {
                                    return (
                                        <TableCell
                                            className={classes.tableCell + " " + classes.tableHeadCell}
                                            key={key}
                                        >
                                            {prop}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        </TableHead>
                    ) : null}
                    {tableData &&
                    <TableBody>
                        {tableData.length > 0 ?
                            tableData.map((item, key) => {
                                return (
                                    <TableRow key={key}>
                                        <TableCell className={classes.tableCell + " " + classes.tableImageCell}>
                                            <img
                                                src={item["picture"]}
                                                alt={capitalize(item["name"])}
                                                className={classes.productImage}
                                            />
                                        </TableCell>
                                        <TableCell className={classes.tableCell}>
                                            <div className={classes.tableCellColumn + " " + classes.name}>
                                                <span className={classes.tableCellBold}>{item.name}</span>
                                                <span>
                                                        <FormattedMessage
                                                            id={"dashboard.productRank.label.externalId"}/>: &nbsp;
                                                    {item.externalProductId ?
                                                        item.externalProductId
                                                        :
                                                        <b><FormattedMessage id={"dashboard.productRank.label.na"}/></b>
                                                    }
                                                </span>
                                                <span>
                                                        <FormattedMessage
                                                            id={"dashboard.productRank.label.sku"}/>: &nbsp;
                                                    {item.sku ?
                                                        item.sku :
                                                        <b><FormattedMessage id={"dashboard.productRank.label.na"}/></b>
                                                    }
                                                </span>
                                                <span className={classes.tableCellDate}>
                                                        <FormattedMessage
                                                            id={"dashboard.productRank.label.date"}/>: &nbsp;
                                                    {item.createdAt ?
                                                        <CustomDate date={item.createdAt} /> :
                                                        <b><FormattedMessage id={"dashboard.productRank.label.na"}/></b>
                                                    }
                                                </span>
                                            </div>
                                        </TableCell>
                                        <TableCell className={classes.tableCell}>
                                            <div>
                                                <span
                                                    className={item["status"] ? classes.actived : classes.deactivated}>
                                                    {
                                                        item["status"] ?
                                                            <FormattedMessage
                                                                id={"dashboard.productRank.table.status.actived"}/>
                                                            :
                                                            <FormattedMessage
                                                                id={"dashboard.productRank.table.status.deactivated"}/>
                                                    }
                                                </span>
                                            </div>
                                            <div className={classes.stocks}>
                                                <span>
                                                   <FormattedMessage id={"dashboard.productRank.table.stocks"}
                                                                     values={{stock: item["stock"]}}/>
                                                </span>
                                            </div>
                                        </TableCell>
                                        <TableCell className={classes.tableCell}>
                                            <span>{item["sales"]}</span>
                                        </TableCell>
                                        {

                                            showTotalViews ?
                                                <TableCell className={classes.tableCell}>
                                                    <span>{item["totalViews"]}</span>
                                                </TableCell>
                                                : <TableCell className={classes.tableCell}/>
                                        }
                                    </TableRow>
                                );
                            }) :
                            <TableRow>
                                <TableCell colSpan={analytics && analytics !== false ? 7 : 6}
                                           className={classes.tableCellNoResult}><FormattedMessage
                                    id={"dashboard.productsRanking.table.no_result"}/>
                                </TableCell>
                            </TableRow>
                        }
                    </TableBody>
                    }
                </Table>
            </div>
        );
    }
}

RankingTable.propTypes = {
    classes: PropTypes.object.isRequired,
    tableHead: PropTypes.arrayOf(PropTypes.object),
    tableData: PropTypes.array
};

export default connect(
    null,
    null
)(withStyles(rankingStyle)(RankingTable));
