import React from "react";

import Table from "@mui/material/Table";

import QuoteDetailGridHeader from "./gridHeader";
import QuoteDetailGridContent from "./gridContent";
import QuoteDetailGridTotals from "./gridTotals";

const QuoteDetailGrid = () => {
    return (
        <div>
            <Table>
                <QuoteDetailGridHeader />
                <QuoteDetailGridContent />
            </Table>
            <QuoteDetailGridTotals />
        </div>
    );
};

export default QuoteDetailGrid;
