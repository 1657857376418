import {createContext} from "react";

export const CancellationReasonListContext = createContext({
    loading: false,
    setLoading: () => {},
    isListNeedToBeRefresh: false,
    refreshCancellationReasonList: () => {},
    isCancellationReasonDialogOpen: false,
    openCancellationReasonDialog: () => {},
    closeCancellationReasonDialog: () => {},
    selectedCancellationReason: null,
    setSelectedCancellationReason: () => {},
    cancellationReasons: []
})
