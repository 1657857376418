import React, { Component } from "react";
import {connect} from "react-redux";
import * as Cookies from "js-cookie";
import {PropagateLoader} from "react-spinners";
import {FormattedMessage} from "react-intl";

// @mui/material components
import {withStyles} from "@mui/styles";

// core components
import {fetch} from "../dataAccess";

import { putSessionOrganizations } from "../../actions/authentication";

// styles
import {primaryColor} from "../../assets/jss/main";
import loginStyle from "assets/jss/views/loginStyle";

import {putMemberRetailersInShittyCookie} from "../../actions/authentificationMember";
import {Link} from "react-router-dom";

export function successToken(token) {
    return {type: "LOGIN_SUCCESS_TOKEN", token};
}

export function successApplications(applications) {
    return {type: "LOGIN_SUCCESS_APPLICATIONS", applications};
}

export function successAuthorizations(authorizations) {
    return {type: "LOGIN_SUCCESS_AUTHORIZATIONS", authorizations};
}

export function successMember(member) {
    return {type: "LOGIN_SUCCESS_MEMBER", member};
}

export function successOrganizations(organizations) {
    return {type: "LOGIN_SUCCESS_ORGANIZATIONS", organizations};
}

export function successInvitations(invitations) {
    return {type: "LOGIN_SUCCESS_INVITATIONS", invitations};
}
class Callback extends Component {
    async componentDidMount() {
        const token = window.location.hash.split("&")[0].replace("#access_token=", "");

        await fetch("/login_sync", {method: "GET", headers: new Headers({"authorization": "Bearer " + token})})
            .then(response => {
                return response.json();
            })
            .then(data => {
                const dataString = JSON.stringify(data["syncedData"]);

                if (!data["syncedData"]["organizations"]) {
                    Cookies.set("_s", dataString, {domain: process.env.REACT_APP_HOST, expires: 0.02});

                    let url = "auth." + process.env.REACT_APP_HOST;
                    let protocol = window.location.protocol;
                    let host = window.location.host;
                    let parts = host.split(":");

                    if (parts[0] === url) {
                        // in-case we have a port number in the host url. (For dev environment)
                        if (parts.length === 2) {
                            url += ":" + parts[1]
                        }

                        if (!data["syncedData"]["givenName"] && !data["syncedData"]["familyName"]) {
                            window.location = protocol + "//" + url + "/completeprofile";
                        } else if (data["syncedData"]["invitations"]) {
                            window.location = protocol + "//" + url + "/invitation";
                        } else {
                            window.location = protocol + "//" + url + "/signin";
                        }
                    }
                } else {
                    Cookies.set( "_a", JSON.stringify(data["syncedData"]["applications"]), {domain: process.env.REACT_APP_HOST, expires: 0.4});
                    Cookies.set( "_b", JSON.stringify(data["syncedData"]["authorizations"]), {domain: process.env.REACT_APP_HOST, expires: 0.4});
                    const member = data.syncedData?.member;
                    putMemberRetailersInShittyCookie(member?.retailers);
                    if (!!member) {
                        delete member['retailers'];
                        Cookies.set( "_c", JSON.stringify(member), {domain: process.env.REACT_APP_HOST, expires: 0.4});
                    }
                    putSessionOrganizations(data["syncedData"]["organizations"]);
                    Cookies.set( "_e", JSON.stringify(data["syncedData"]["invitations"]), {domain: process.env.REACT_APP_HOST, expires: 0.4});
                    Cookies.set( "_f", JSON.stringify(data["syncedData"]["token"]), {domain: process.env.REACT_APP_HOST, expires: 0.4});

                    this.props.successApplications(data["syncedData"]["applications"]);
                    this.props.successAuthorizations(data["syncedData"]["authorizations"]);
                    this.props.successMember(data["syncedData"]["member"]);
                    this.props.successOrganizations(data["syncedData"]["organizations"]);
                    this.props.successInvitations(data["syncedData"]["invitations"] ? data["syncedData"]["invitations"] : null);
                    this.props.successToken(data["syncedData"]["token"]);
                }
            }).catch(error => {
                let url = "auth." + process.env.REACT_APP_HOST;
                let protocol = window.location.protocol;
                let host = window.location.host;
                let parts = host.split(":");

                if (parts[0] === url) {
                    // in-case we have a port number in the host url. (For dev environment)
                    if (parts.length === 2) {
                        url += ":" + parts[1]
                    }
                }
                window.location = protocol + "//" + url + "/validateaccount";
            });
    }

    render() {
        return (
            <div className={this.props.classes.container}>
                <FormattedMessage id={"login.loading"}/>
                <PropagateLoader sizeUnit={"px"} size={16} color={primaryColor} loading={true}/>
                <Link className={this.props.classes.logoutButton} to={`/logout`}>
                    <FormattedMessage id={"login.logout"}/>
                </Link>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        successToken: (token) => dispatch(successToken(token)),
        successApplications: (applications) => dispatch(successApplications(applications)),
        successAuthorizations: (authorizations) => dispatch(successAuthorizations(authorizations)),
        successMember: (member) => dispatch(successMember(member)),
        successOrganizations: (organizations) => dispatch(successOrganizations(organizations)),
        successInvitations: (invitations) => dispatch(successInvitations(invitations)),
    };
};

export default connect(null, mapDispatchToProps)(withStyles(loginStyle)(Callback));
