import React, {Component} from "react";

import QuoteListHeader from "./header";
import QuoteListGrid from "./grid";
import {tabs} from "./stateSwitcher";
import list from "../../../../api/quote/list";
import {
    FROM_DATE_QUERY,
    ITEMS_PER_PAGE_QUERY, PAGE_QUERY,
    SEARCH_QUERY,
    TAB_QUERY,
    TO_DATE_QUERY
} from "../../enums/urlParametersIdentifier";

class ListContainer extends Component {
    unlistenUrlChange = null;

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            search: '',
            itemsPerPage: 10,
            page: 1,
            from: '',
            to: '',
            tab: 0,
            quotes: null,
            counts: null,
            retrieved: null,
            startDate: null,
            endDate: null
        };
    }

    componentWillUnmount() {
        if (!!this.unlistenUrlChange) {
            this.unlistenUrlChange();
        }
    }

    componentDidMount() {
        this.fetchQuotes();
    }

    fetchQuotes = () => {
        if (this.state.startDate && this.state.endDate) {
            this.setStateFromSearchParams(this.loadQuotes);
            this.unlistenUrlChange = this.props.history.listen(() => this.setStateFromSearchParams(this.loadQuotes));
        }
    }

    setStateFromSearchParams(callback) {
        const queryParameters = new URLSearchParams(this.props.history.location.search);
        const state = {};
        if (queryParameters.has(ITEMS_PER_PAGE_QUERY)) {
            state.itemsPerPage = queryParameters.get(ITEMS_PER_PAGE_QUERY);
        }
        if (queryParameters.has(PAGE_QUERY)) {
            state.page = queryParameters.get(PAGE_QUERY);
        }
        if (queryParameters.has(SEARCH_QUERY)) {
            state.search = queryParameters.get(SEARCH_QUERY);
        }
        if (queryParameters.has(FROM_DATE_QUERY)) {
            state.from = queryParameters.get(FROM_DATE_QUERY) + " 00:00:01";
        } else if (this.state.startDate) {
            state.from = this.state.startDate;
        }
        if (queryParameters.has(TO_DATE_QUERY)) {
            state.to = queryParameters.get(TO_DATE_QUERY) + " 23:59:59";
        } else if (this.state.endDate) {
            state.to = this.state.endDate;
        }
        if (queryParameters.has(TAB_QUERY) && typeof queryParameters.get(TAB_QUERY) === 'string') {
            const tabIdentifiers = queryParameters.get(TAB_QUERY).split(',');
            if (Array.isArray(tabIdentifiers)) {
                state.tab = tabs.findIndex(tab => {
                    const states = tab.dataKey.split(',');
                    let allStatesFound = true;
                    states.forEach(state => {
                        if (tabIdentifiers.indexOf(state) === -1) {
                            allStatesFound = false;
                        }
                    });
                    return allStatesFound;
                });
                if (state.tab === -1) {
                    delete state.tab;
                }
            }
        }

        if (callback) {
            this.setState(state, callback);
        } else {
            this.setState(state);
        }
    }

    handleSelectDates = (startDate, endDate) => {
        let hasToFetchFromStart = (!this.state.startDate && !this.state.endDate);
        this.setState({
            startDate: startDate.format('YYYY-M-D 00:00:01'),
            endDate: endDate.format('YYYY-M-D 23:59:59'),
        }, function(){
            if (hasToFetchFromStart) {
                this.fetchQuotes();
            }
        });
    }

    loadQuotes() {
        this.setState({loading: true});
        const tabKey = tabs[this.state.tab].dataKey;
        list(
            tabKey,
            this.state.search,
            this.state.from,
            this.state.to,
            this.state.itemsPerPage,
            this.state.page,
        ).then(quotes => {
            this.setState({
                loading: false,
                quotes: quotes.list,
                counts: quotes.counts,
                retrieved: quotes.retrieved,
            });
        });
    }

    render() {
        return (
            <div>
                <QuoteListHeader
                    history={this.props.history}
                    onSelectDates={this.handleSelectDates}
                />
                <QuoteListGrid
                    loading={this.state.loading}
                    history={this.props.history}
                    quotes={this.state.quotes}
                    counts={this.state.counts}
                    itemsPerPage={this.state.itemsPerPage}
                    retrieved={this.state.retrieved}
                    startDate={this.state.startDate}
                    endDate={this.state.endDate}
                />
            </div>
        );
    }
}

export default ListContainer;
