import React, {useContext} from "react";
import {injectIntl} from "react-intl";
import PropTypes from "prop-types";

// @mui/material components
import {Switch, Tooltip} from "@mui/material";

// context
import {CancellationReasonListContext} from "../../../contexts/CancellationReasonListContext";

// actions
import update from "api/cancellationReason/update";

function ActivationSwitch(props) {
    const { cancellationReason, intl } = props;

    const {
        refreshCancellationReasonList,
        setLoading,
    } = useContext(CancellationReasonListContext);

    function toggleActivation() {
        setLoading(true);

        const values = {enabled: !cancellationReason.enabled};

        update(cancellationReason, values).then((res) => {
            refreshCancellationReasonList()
        });
    }

    const tooltipTitle = cancellationReason.enabled ?
        'cancellation_reason.table.row.tooltip.disable'
        : 'cancellation_reason.table.row.tooltip.enable'
    ;

    return (
        <Tooltip title={intl.formatMessage({id: tooltipTitle})}>
            <Switch
                checked={cancellationReason.enabled}
                onChange={() => toggleActivation()}
                color="primary"
            />
        </Tooltip>
    );
}

ActivationSwitch.propTypes = {
    cancellationReason: PropTypes.object
};

export default injectIntl(ActivationSwitch);
