import React from 'react';
import PropTypes from 'prop-types';
import BeatLoaderRegular from "./BeatLoaderRegular";

TopRightLoader.propTypes = {
    loading: PropTypes.bool
};

export default function TopRightLoader(props) {
    return (
        <div style={{
            position: "absolute",
            right: '30px',
            top: '15px',
        }}>
            <BeatLoaderRegular {...props} />
        </div>
    );
}
