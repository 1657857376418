import React, {Component} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {FormattedMessage, injectIntl} from "react-intl";
import {DateRangePicker} from 'react-dates';
import {ITEM_PER_PAGE} from "config/_pagination";
import moment from "moment";

// @mui/material components
import {
    Tabs,
    Tab,
    TextField,
    IconButton,
    Tooltip,
    Chip,
} from "@mui/material"

// @mui/icons-material
import {
    Search,
    Clear,
    GetApp,
    RemoveRounded,
    AddRounded,
    NotificationsActive
} from "@mui/icons-material";

// core components
import GridContainer from "components/grid/gridContainer";
import GridItem from "components/grid/gridItem";
import Card from "components/card/card";
import CardHeader from "components/card/cardHeader";
import CardBody from "components/card/cardBody";
import ItemsPerPageSelect from "components/select/itemsPerPageSelect";
import Table from "./table";
import PaginationContainer from "../../../components/pagination/paginationContainer";

// styles
import listStyle from "assets/jss/views/picking/order/listStyle";

// api
import {list as cancellationReasonList} from "api/cancellationReason/list";

// actions
import {list, count, reset} from "actions/order/list";
import {downloadExport, downloadExportLineItem} from "actions/order/export";
import {shippingMethodsList} from "../../../actions/lineItem/shippingMethods/list";
import {list as organizationShippingMethodsList} from "api/shippingMethod/list";

// helpers
import abort from "utils/abort";
import {Authorizations, hasAuthorization} from "../../../utils/authorizations";
import {sortOn} from "../../../utils/sortOn";
import Snackbar from "../../../components/snackbar/snackbar";
import {getAllStatus} from "../../../utils/LineItemStatus";
import {getTranslation} from "../../../domain/helpers/translations";
import {withStyles} from "@mui/styles";
import {paramsToObject} from "../../../utils/paramsToObject";
import {UserAgentUtils} from "../../../utils/useragent";

class List extends Component {
    static propTypes = {
        error: PropTypes.string,
        list: PropTypes.func.isRequired,
        shippingMethodsList: PropTypes.func,
    };

    constructor(props) {
        super(props);

        this.state = {
            errorNotification: true,
            deletedNotification: true,
            createNotification: true,
            exported: false,
            value: 0,
            page: 1,
            itemsPerPage: this.props.itemsPerPage !== null ? this.props.itemsPerPage : ITEM_PER_PAGE,
            order: "asc",
            status: "pending",
            startDate: moment().subtract(14, 'days'),
            endDate: moment(),
            search: "",
            hideFilter: UserAgentUtils.isMobileOrTablet(),
            filterMenuSelected: "LineItemStatus",
            selectedFilters: [],
            organizationShippingMethods: [],
            organizationCancellationReasons: [],
            organizationLineItemStatus: [],
            timer: null
        };
    }

    componentDidMount() {
        let params = decodeURIComponent(this.props.match.params.params).split("&");

        let hasDateBeforeFromUrl = false;
        let hasDateAfterFromUrl = false;
        let tab = "pending";
        const selectedFiltersFromUrls = []
        let selectedMenuFromUrls = ''
        let hasSearch = false;

        params.map((param) => {
            if (param.search("search=") !== -1) {
                hasSearch = true;
            }
            if (param.search("status=") !== -1) {
                tab = param.replace("status=", "");
            }
            if (param.search("createdAt\\[after\\]=") !== -1) {
                let dateFromUrl = param.substr(17);
                this.setState({'startDate': moment(dateFromUrl, 'YYYY-MM-DD H:i:s')});
                hasDateAfterFromUrl = true;
            }
            if (param.search("createdAt\\[before\\]=") !== -1) {
                let dateFromUrl = param.substr(18);
                this.setState({'endDate': moment(dateFromUrl, 'YYYY-MM-DD H:i:s')});
                hasDateBeforeFromUrl = true;
            }

            if (param.search('lineItemStatus=') === 0) {
                selectedMenuFromUrls = 'LineItemStatus'
                param
                    .replace('lineItemStatus=', '')
                    .split(',')
                    .forEach(lineItemStatus => selectedFiltersFromUrls.push({
                        '@type': 'LineItemStatus',
                        'id': lineItemStatus,
                        'name': <FormattedMessage id={'picking.order.lineItemStatus.' + lineItemStatus}/>,
                    }))
            }
            if (param.search('cancellation_reason.name') === 0) {
                const cancellationReasonName = param.replace('cancellation_reason.name[]=', '')
                selectedMenuFromUrls = 'CancellationReason'
                selectedFiltersFromUrls.push({
                    '@type': 'CancellationReason',
                    'id': cancellationReasonName,
                    'name': cancellationReasonName,
                })
            }
            if (param.search('shippingMethod=') === 0) {
                selectedMenuFromUrls = 'ShippingMethod'
                param
                    .replace('shippingMethod=', '')
                    .split(',')
                    .forEach(shippingMethod => selectedFiltersFromUrls.push({
                        '@type': 'ShippingMethod',
                        'id': shippingMethod,
                        'name': <FormattedMessage id={'picking.order.shippingMethod.' + shippingMethod}/>,
                    }))
            }

            return true;
        });

        if (selectedMenuFromUrls !== '') {
            this.setState({filterMenuSelected: selectedMenuFromUrls})
        }
        this.setState({selectedFilters: selectedFiltersFromUrls})

        if (null !== this.state.endDate && !hasDateBeforeFromUrl && !hasSearch) {
            this.props.match.params.params += `&createdAt[before]=${this.state.endDate.endOf('day').format("YYYY-M-D HH:mm:ss")}`;
        }

        if (null !== this.state.startDate && !hasDateAfterFromUrl && !hasSearch) {
            this.props.match.params.params += `&createdAt[after]=${this.state.startDate.startOf('day').format("YYYY-M-D HH:mm:ss")}`;
        }

        this.props.list(
            this.props.match.params.params &&
            decodeURIComponent(this.props.match.params.params)
        );

        if (this.props.member && this.props.member.retailers) {
            this.props.shippingMethodsList(
                'retailersIds=' + this.props.member.retailers.map((retailer) => {
                    return retailer['@id'].replace('/retailers/', '');
                }).join()
            );
        } else {
            this.props.shippingMethodsList();
        }

        cancellationReasonList().then((res) => {
            const organizationCancellationReasons = []
            const alreadyListedCancellationReason = []

            res.forEach(cancellationReason => {
                if (alreadyListedCancellationReason.indexOf(getTranslation(cancellationReason).name) < 0) {
                    organizationCancellationReasons.push(cancellationReason)
                    alreadyListedCancellationReason.push(getTranslation(cancellationReason).name)
                }
            })

            this.setState({
                organizationCancellationReasons: organizationCancellationReasons.sort((a, b) => {
                    const aTranslated = getTranslation(a).name
                    const bTranslated = getTranslation(b).name

                    return aTranslated.localeCompare(bTranslated)
                })
            })
        })

        organizationShippingMethodsList().then((res) => {
            this.setState({
                organizationShippingMethods: res
            })
        })

        this.setState({
            organizationLineItemStatus: getAllStatus()
        })

        this.props.count(
            this.props.match.params.params &&
            decodeURIComponent(this.props.match.params.params));

        switch (tab) {
            case "pending":
                tab = 0;
                break;
            case "reserved":
                tab = 1;
                break;
            case "to-ship":
                tab = 2;
                break;
            case "confirmed":
                tab = 3;
                break;
            case "canceled":
                tab = process.env.REACT_APP_RETURN_ENABLED ? 5 : 4;
                break;
            case "returning":
                tab = 4;
                break;
            default:
                tab = 0;
                break;
        }

        this.setState({value: tab});

        this.updateStoreParameters(this.props.match.params.params);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({errorNotification: true});
        this.setState({deletedNotification: true});

        if(this.props.created !== nextProps.created) {
            this.setState({createNotification: true});
        }

        if (this.props.match.params.params !== nextProps.match.params.params) {
            nextProps.list(
                nextProps.match.params.params &&
                decodeURIComponent(nextProps.match.params.params)
            );
            nextProps.count(decodeURIComponent(nextProps.match.params.params));

            this.updateStoreParameters(nextProps.match.params.params);
        }
    };

    componentWillUnmount() {
        this.props.reset();
        abort.abortRequests();
    };

    updateStoreParameters(params) {
        this.setState(paramsToObject(params));
    };

    handleRouteReset() {
        this.setState({search: ""})
        this.props.history.push(`/picking/orders/order[createdAt]=asc&status=pending&itemsPerPage=${this.state.itemsPerPage}&page=1`);
    }

    handlePageReset = (params) => {
        params = params.split("&")
        params = params.filter(param => param !== '')

        params
            .map((param, key) => {
                if (param.search("page") !== -1) {
                    params[key] = "page=1"
                }

                return true
            })

        return params
    };

    handleChangeTab = (event, value) => {
        this.setState({ value });

        let status = this.state.status;

        if(value === 0) {
            status = "pending";
        } else if(value === 1) {
            status = "reserved";
        } else if (value === 2) {
            status = "to-ship";
        } else if (value === 3) {
            status = "confirmed";
        } else if (value === 4) {
            status = process.env.REACT_APP_RETURN_ENABLED ? "returning" : "canceled";
        } else if (value === 5) {
            status = "canceled";
        }

        let params = this.handlePageReset(decodeURIComponent(this.props.match.params.params));

        let newParams = `status=${status}`;

        params.map((param) => {
            if (param.search("status") === -1) {
                newParams += `&${param}`;
            }
            return true;
        });

        // change order
        params = this.handlePageReset(decodeURIComponent(newParams));

        newParams = "order[createdAt]=asc"
        this.setState({order: "asc"})

        params.map((param => {
            if(param.search("order") === -1) {
                newParams +=`&${param}`
            }
            return true
        }));

        this.props.history.push(`/picking/orders/${encodeURIComponent(newParams)}`);
        this.setState({status});
    };

    handleDateChange(dates) {
        this.setState({startDate: dates.startDate, endDate: dates.endDate}, function() {
            if (dates.startDate === null || dates.endDate === null) {
                return;
            }
            let params = this.handlePageReset(decodeURIComponent(this.props.match.params.params));
            let newParams = 'order[createdAt]=asc'

            params.map((param) => {
                if (param.search("createdAt") === -1) {
                    newParams += `&${param}`;
                }

                return true;
            });

            let startDate = dates.startDate.clone();
            let endDate = dates.endDate.clone();

            if (null !== dates.endDate) {
                newParams += `&createdAt[before]=${endDate.endOf('day').format("YYYY-M-D HH:mm:ss")}`;
            }
            if (null !== dates.startDate) {
                newParams += `&createdAt[after]=${startDate.startOf('day').format("YYYY-M-D HH:mm:ss")}`;
            }

            this.props.history.push(`/picking/orders/${encodeURIComponent(newParams)}`);
        });
    };

    handleClearSearch = () => {
        this.setState({
            search: ""
        }, () =>  this.handleSearch(""));
    };

    handleSearch = (value) => {
        abort.abortRequests();

        this.setState({"search": value});

        clearTimeout(this.state.timer);

        const newTimer = setTimeout(() => {
            let params = this.handlePageReset(decodeURIComponent(this.props.match.params.params));

            params = params.join('&');
            params = new URLSearchParams(params);

            if (value.length > 0) {
                params.set('search', value);
                params.delete('createdAt[before]');
                params.delete('createdAt[after]');
            } else {
                params.delete('search');
            }

            this.props.history.push(`/picking/orders/${encodeURIComponent(params.toString())}`);
        }, 500)

        this.setState({timer: newTimer});
    };

    handleSort = () => {
        let params = this.handlePageReset(decodeURIComponent(this.props.match.params.params));
        let order = this.state.order;
        let newParams = new URLSearchParams();

        params.map((param) => {
            newParams.set(param.split("=")[0], param.split("=")[1]);
            if (param.search("order[createdAt]") !== -1) {
                order = param.split("=")[1];
            }
        });

        if (order !== undefined) {
            order = ("asc" === order) ? "desc" : "asc";
            newParams.set('order[createdAt]', order);
            this.setState({order});
        }

        this.props.history.push(`/picking/orders/${encodeURIComponent(newParams.toString())}`);
    };

    renderFilterMenu = () => {
        const {classes, shippingMethodsRetrieved} = this.props;
        const {filterMenuSelected, organizationCancellationReasons, organizationLineItemStatus} = this.state;

        let filters={};

        if (organizationLineItemStatus && organizationLineItemStatus.length > 0) {
            const lineItemStatus = organizationLineItemStatus.map((lineItemStatus) => {
                return {
                    "@type": "LineItemStatus",
                    "id": lineItemStatus,
                    "name": <FormattedMessage id={"picking.order.lineItemStatus." + lineItemStatus}/>
                };
            })
            Object.assign(filters,  {"LineItemStatus": lineItemStatus});
        }

        if (organizationCancellationReasons
            && organizationCancellationReasons.length > 0) {
            Object.assign(filters,  {"CancellationReason": organizationCancellationReasons.map(cancellationReason => {
                    return {
                        "@type": "CancellationReason",
                        "id": getTranslation(cancellationReason).name,
                        "name": getTranslation(cancellationReason).name,
                    }
                })})
        }

        if (shippingMethodsRetrieved && shippingMethodsRetrieved.length > 0) {
            const shippingMethods = shippingMethodsRetrieved.map((shippingMethod) => {
                return {
                    "@type": "ShippingMethod",
                    "id": shippingMethod.shippingMethod,
                    "name": <FormattedMessage id={"picking.order.shippingMethod." + shippingMethod.shippingMethod}/>
                };
            })

            Object.assign(filters,  {"ShippingMethod": shippingMethods});
        }

        return (
            <div>
                <div className={classes.filterMenu}>
                    <Tabs
                        variant="scrollable"
                        value={Object.keys(filters).indexOf(filterMenuSelected)}
                        classes={{root: classes.tabsRoot, indicator: classes.tabsIndicator}}
                    >
                        {Object.keys(filters).map(name => {
                            return (
                                <Tab
                                    key={name}
                                    onClick={() => this.setState({filterMenuSelected: name})}
                                    disableRipple
                                    classes={{root: classes.tabRoot, selected: classes.tabSelected}}
                                    label={<FormattedMessage id={"retailer.product.list.filter." + name.toLowerCase()}/>}
                                />
                            )
                        })}
                    </Tabs>
                </div>
                <div className={classes.filters}>
                    {this.renderFilterElement(filters)}
                </div>
            </div>
        );
    };

    renderFilterElement = (filters) => {
        const {classes} = this.props;
        const {filterMenuSelected} = this.state;

        if (filters[filterMenuSelected]) {
            filters[filterMenuSelected] = filters[filterMenuSelected].sort(sortOn("name"));

            return filters[filterMenuSelected].map(element => {
                return <Chip
                    label={element.name || element.value}
                    key={element.id}
                    onClick={() => this.handleAddSelectedFilter(element)}
                    variant={this.checkIfFilterExist(element) ? "default" : "outlined"}
                    classes={{root: classes.filterItem}}
                />
            })
        }

        return [];
    };

    checkIfFilterExist = (element) => {
        const {selectedFilters} = this.state;

        for (let i = 0; i < selectedFilters.length; i++) {
            if (selectedFilters[i].id === element.id) {
                return true;
            }
        }

        return false;
    };

    handleAddSelectedFilter = element => {
        if (!this.checkIfFilterExist(element)) {
            this.setState({selectedFilters: [...this.state.selectedFilters, element]}, () => this.handleUpdateAddFilter(element, this.state.selectedFilters));
        }
    };

    handleUpdateAddFilter(element, selectedFilters) {
        let params = this.handlePageReset(decodeURIComponent(this.props.match.params.params));
        let newParams = "order[createdAt]=asc&";

        params.map((param) => {
            if ((param.search('shippingMethod') === -1 || element['@type'] !== 'ShippingMethod')
                && (param.search('cancellation_reason.name') === -1 || element['@type'] !== 'CancellationReason')
                && (param.search('lineItemStatus') === -1 || element['@type'] !== 'LineItemStatus')
            ) {
                newParams += `${param}&`
            }

            return true;
        });

        switch (element["@type"]) {
            case "LineItemStatus":
                const newLineItemStatus = selectedFilters
                    .filter(filter => filter["@type"] === "LineItemStatus")
                    .map(lineItemStatus => lineItemStatus.id);
                newParams += `lineItemStatus=${newLineItemStatus}&`;
                break;
            case "CancellationReason":
                const newCancellationReasons = selectedFilters
                    .filter(filter => filter["@type"] === "CancellationReason")
                    .map(cancellationReason => cancellationReason.id);
                for (const name of newCancellationReasons) {
                    newParams += `cancellation_reason.name[]=${name}&`
                }
                break
            case "ShippingMethod":
                let newShippingMethods = selectedFilters
                    .filter(filter => filter["@type"] === "ShippingMethod")
                    .map(shippingMethod => shippingMethod.id);
                newParams += `shippingMethod=${newShippingMethods}&`;
                break;
            default:
                newParams = "order[createdAt]=asc";
        }

        if (newParams.substr(newParams.length - 1) === "&") {
            newParams = newParams.substring(0, newParams.length - 1)
        }

        this.props.history.push(`/picking/orders/${encodeURIComponent(newParams)}`);
    }

    handleDeleteSelectedFilter = element => {
        let selectedFilters = this.state.selectedFilters;

        if (this.checkIfFilterExist(element)) {
            let index = selectedFilters.indexOf(element);

            selectedFilters.splice(index, 1);
            this.setState({selectedFilters});

            let params = this.handlePageReset(decodeURIComponent(this.props.match.params.params));
            let newParams = "order[createdAt]=asc&";

            let alreadyShippingMethod = false
            let alreadyCancellationReason = false
            let alreadyLineItemStatus = false
            params.map((param) => {
                if (param.search("lineItemStatus") >= 0 && element["@type"] === "LineItemStatus") {
                    if (alreadyLineItemStatus === false) {
                        const newLineItemStatus = selectedFilters
                            .filter(filter => filter["@type"] === "LineItemStatus")
                            .map(lineItemStatus => lineItemStatus.id);
                        if (newLineItemStatus.length > 0) {
                            newParams += `lineItemStatus=${newLineItemStatus}&`
                        }
                        alreadyLineItemStatus = true
                    }
                } else if (param.search("cancellation_reason.name") >= 0 && element["@type"] === "CancellationReason") {
                    if (alreadyCancellationReason === false) {
                        const newCancellationReasons = selectedFilters
                            .filter(filter => filter["@type"] === "CancellationReason")
                            .map(cancellationReason => cancellationReason.id);
                        for (const name of newCancellationReasons) {
                            newParams += `cancellation_reason.name[]=${name}&`
                        }
                        alreadyCancellationReason = true
                    }
                } else if (param.search("shippingMethod") >= 0 && element["@type"] === "ShippingMethod") {
                    if (alreadyShippingMethod === false) {
                        const newShippingMethods = selectedFilters
                            .filter(filter => filter["@type"] === "ShippingMethod")
                            .map(shippingMethod => shippingMethod.id);
                        if (newShippingMethods.length > 0) {
                            newParams += `shippingMethod=${newShippingMethods}&`
                        }
                        alreadyShippingMethod = true
                    }
                }  else  {
                    newParams += `${param}&`
                }

                return true;
            });

            if (newParams.substr(newParams.length - 1) === "&") {
                newParams = newParams.substring(0, newParams.length - 1)
            }

            this.props.history.push(`/picking/orders/${encodeURIComponent(newParams)}`);
        }
    };

    renderSelectedFilter = () => {
        const {selectedFilters} = this.state;
        const {classes} = this.props;

        return (
            <div className={classes.filterSelectedContainer}>
                {selectedFilters.map(element => {
                    return <Chip
                        key={element.id}
                        label={element.name || element.value}
                        color="primary"
                        onDelete={() => this.handleDeleteSelectedFilter(element)}
                        className={classes.filterSelectedItem}
                    />
                })}
            </div>
        );
    };

    render() {
        const {classes,intl, countRetrieved} = this.props;
        const {selectedFilters, hideFilter} = this.state;

        let status = this.state.status === 'to-ship' ? 'toShip' : this.state.status;

        const handleSubmit = () => {
            this.props.export(this.state.startDate, this.state.endDate, this.props.organizations[this.props.member["organization"]]["name"])
            this.setState({exported: true});
        };

        const handleSubmitLineItem = () => {
            this.props.exportLineItem(this.state.startDate, this.state.endDate, this.props.organizations[this.props.member["organization"]]["name"])
            this.setState({exported: true});
        };

        let tableHeadData = [
            <FormattedMessage id={"picking.order.list.table.header.order"}  onClick={() => this.handleSort()} sortDirection={this.state.order}/>,
            <FormattedMessage id={"picking.order.list.table.header.customer"}/>,
            <FormattedMessage id={"picking.order.list.table.header.type"}/>,
            <FormattedMessage id={"picking.order.list.table.header.total"}/>,
            <FormattedMessage id={"picking.order.list.table.header.quantity"}/>,
        ];

        if (!hasAuthorization(this.props.authorizations, Authorizations.PICKING_RETAILER)) {
            tableHeadData.push(<FormattedMessage id={"picking.order.list.table.header.affectedTo"}/>)
        }

        return (
            <div className={classes.containerWithSidebar}>
                <div className={classes.container}>
                    <GridContainer direction="row" justifyContent="space-between" alignItems="baseline" className={classes.header} >
                        <GridItem xs={12} sm={12} md={6} lg={4}  className={classes.dateRangePicker}>
                            <DateRangePicker
                                startDateId="startDate"
                                endDateId="endDate"
                                startDatePlaceholderText={intl.formatMessage({id: "picking.order.list.datepicker.from"})}
                                endDatePlaceholderText={intl.formatMessage({id: "picking.order.list.datepicker.to"})}
                                startDate={this.state.startDate}
                                endDate={this.state.endDate}
                                onDatesChange={({startDate, endDate}) => {
                                    this.setState({startDate, endDate})
                                }}
                                focusedInput={this.state.focusedInput}
                                onFocusChange={(focusedInput) => {
                                    this.setState({focusedInput})
                                }}
                                onClose={(dates) => this.handleDateChange(dates)}
                                displayFormat={"D MMMM YYYY"}
                                block={true}
                                readOnly={true}
                                isOutsideRange={() => false}
                                showDefaultInputIcon={true}
                                showClearDates={true}
                                reopenPickerOnClearDates={true}
                                required={true}
                                hideKeyboardShortcutsPanel={true}
                                withFullScreenPortal={window.innerWidth < 768}
                                numberOfMonths={2}
                                orientation={window.innerWidth < 768 ? "vertical" : "horizontal"}
                                anchorDirection={"right"}
                                minimumNights={0}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6} lg={4}>
                            <TextField
                                id="search-input"
                                placeholder={intl.formatMessage({id: "picking.order.list.search.placeholder"})}
                                onChange={(event) => this.handleSearch(event.target.value)}
                                value={this.state.search}
                                variant="outlined"
                                InputProps={{
                                    endAdornment: (
                                        this.state.search.length > 0 ?
                                        <IconButton color="primary" onClick={() => this.handleClearSearch()} size="large">
                                            <Clear />
                                        </IconButton> :
                                        <Search color={"disabled"}/>
                                    ),
                                }}
                                className={classes.search}
                                type={"string"}
                            />
                        </GridItem>
                    </GridContainer>
                    <Card className={classes.card}>
                        <CardHeader color="info" className={classes.cardHeader}>
                            <h3><FormattedMessage id={"picking.order.list.title"}/></h3>
                            <span>
                                {!this.props.loading && (
                                    <Tooltip title={intl.formatMessage({id: "picking.order.list.export"})}>
                                        <IconButton onClick={handleSubmit} color="primary" size="large">
                                            <GetApp/>
                                        </IconButton>
                                    </Tooltip>
                                )}
                                {!this.props.loading && (
                                    <Tooltip title={intl.formatMessage({id: "picking.line.item.list.export"})}>
                                        <IconButton onClick={handleSubmitLineItem} color="primary" size="large">
                                            <GetApp/>
                                        </IconButton>
                                    </Tooltip>
                                )}
                                {this.state.exported && (
                                    <Snackbar
                                    open={this.state.createNotification}
                                    close
                                    closeNotification={() => this.setState({exported: false})}
                                    place={"bl"}
                                    color={"success"}
                                    icon={() => <NotificationsActive/>}
                                    message={<FormattedMessage id={"picking.order.list.export.success"}/>}
                                    />
                                )}
                            </span>
                        </CardHeader>
                        <CardBody className={classes.cardBody}>
                            <Tabs
                                value={this.state.value}
                                onChange={this.handleChangeTab}
                                classes={{ root: classes.tabsRoot, scrollable: classes.tabsScrollable, indicator: classes.tabsIndicator, flexContainer: classes.tabsFlexContainer }}
                                variant={"scrollable"}
                                >
                                <Tab
                                    disableRipple
                                    classes={{ root: classes.tabPending, selected: classes.tabSelected }}
                                    label={<FormattedMessage id={"picking.order.list.tab.pending"} values={{qty: countRetrieved["pending"] ? countRetrieved["pending"].toLocaleString() : 0}}/>}
                                    className={classes.pending}
                                />
                                <Tab
                                    disableRipple
                                    classes={{ root: classes.tabReserved, selected: classes.tabSelected }}
                                    label={<FormattedMessage id={"picking.order.list.tab.reserved"} values={{qty: countRetrieved["reserved"] ? countRetrieved["reserved"].toLocaleString() : 0}}/>}
                                    className={classes.reserved}
                                />
                                <Tab
                                    disableRipple
                                    classes={{ root: classes.tabtoShip, selected: classes.tabSelected }}
                                    label={<FormattedMessage id={"picking.order.list.tab.toShip"} values={{qty: countRetrieved["toShip"] ? countRetrieved["toShip"].toLocaleString() : 0}}/>}
                                    className={classes.toShip}
                                />
                                <Tab
                                    disableRipple
                                    classes={{ root: classes.tabConfirmed, selected: classes.tabSelected }}
                                    label={<FormattedMessage id={"picking.order.list.tab.confirmed"} values={{qty: countRetrieved["confirmed"] ? countRetrieved["confirmed"].toLocaleString() : 0}}/>}
                                    className={classes.confirmed}
                                />
                                { process.env.REACT_APP_RETURN_ENABLED &&
                                    <Tab
                                        disableRipple
                                        classes={{ root: classes.tabReturning, selected: classes.tabSelected }}
                                        label={<FormattedMessage id={"picking.order.list.tab.returning"} values={{qty: countRetrieved["returning"] ? countRetrieved["returning"].toLocaleString() : 0}}/>}
                                        className={classes.returning}
                                    />
                                }
                                <Tab
                                    disableRipple
                                    classes={{ root: classes.tabCanceled, selected: classes.tabSelected }}
                                    label={<FormattedMessage id={"picking.order.list.tab.canceled"} values={{qty: countRetrieved["canceled"] ? countRetrieved["canceled"].toLocaleString() : 0}}/>}
                                    className={classes.canceled}
                                />
                            </Tabs>
                            <CardHeader>
                                <div className={classes.filterBarContainer}>
                                    <FormattedMessage id={"retailer.product.list.filter.title"}/>
                                    {selectedFilters.length > 0 && " (" + selectedFilters.length + ")"}
                                    <IconButton
                                        className={classes.filterButton}
                                        onClick={() => this.setState({hideFilter: !hideFilter})}
                                        size="large">
                                        {hideFilter ? <AddRounded/> : <RemoveRounded/>}
                                    </IconButton>
                                    <div hidden={hideFilter}>
                                        <div className={classes.filter}>
                                            {this.renderFilterMenu()}
                                            {selectedFilters.length > 0 && this.renderSelectedFilter()}
                                        </div>
                                    </div>
                                </div>
                            </CardHeader>
                            <Table
                                tableHead={tableHeadData}
                                tableHeaderColor="primary"
                                tableData={this.props.retrieved && this.props.retrieved["hydra:member"]}
                                match={this.props.match}
                                history={this.props.history}
                                organizationShippingMethods={this.state.organizationShippingMethods}
                            />
                        </CardBody>
                        <GridContainer justifyContent={"space-between"} className={classes.footer}>
                            <GridItem xs={4}>
                                <ItemsPerPageSelect
                                    prefixUrl={`/picking/orders/`}
                                    history={this.props.history}
                                    match={this.props.match}
                                />
                            </GridItem>
                            <GridItem xs={4} className={classes.pagination}>
                                <PaginationContainer
                                    itemsPerPage={this.state.itemsPerPage}
                                    urlParams={this.props.match.params.params}
                                    retrieved={this.props.retrieved}
                                    prefix={'/picking'}
                                />
                            </GridItem>
                            <GridItem xs={4} className={classes.total}>
                                <FormattedMessage id={`picking.order.list.label.total.${this.state.status}`} values={{qty: countRetrieved[status]}}/>
                            </GridItem>
                        </GridContainer>
                    </Card>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    const {
        retrieved,
        loading,
        error,
        countRetrieved,
    } = state.order.list;

    const {itemsPerPage} = state.itemsPerPage;

    const {organizations, member, authorizations} = state.authentication;

    return {
        retrieved,
        loading,
        error,
        organizations,
        member,
        authorizations,
        countRetrieved,
        itemsPerPage,
        shippingMethodsRetrieved: state.lineItem.shippingMethods.retrieved,
    };
};

const mapDispatchToProps = dispatch => ({
    list: params => dispatch(list(params)),
    export: (status, startDate, endDate, organization) => dispatch(downloadExport(status, startDate, endDate, organization)),
    exportLineItem: (status, startDate, endDate, organization) => dispatch(downloadExportLineItem(status, startDate, endDate, organization)),
    count: params => dispatch(count(params)),
    reset: () => dispatch(reset()),
    shippingMethodsList: params => dispatch(shippingMethodsList(params)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(listStyle)(injectIntl(List)));
