import React from 'react';
import PropTypes from 'prop-types';

import CommentIcon from "./CommentIcon";
import CustomerNoteIcon from "./CustomerNoteIcon";
import InternalNoteIcon from "./InternalNoteIcon";

import {
    QUOTE_COMMENT,
    QUOTE_CUSTOMER_NOTE,
    QUOTE_INTERNAL_NOTE,
    QUOTE_NOTE_TYPES
} from "../../../../../enums/quoteNoteType";

Index.propTypes = {
    type: PropTypes.oneOf(QUOTE_NOTE_TYPES).isRequired
};

export default function Index(props) {
    switch (props.type) {
        case QUOTE_CUSTOMER_NOTE:
            return <CustomerNoteIcon />
        case QUOTE_COMMENT:
            return <CommentIcon />
        case QUOTE_INTERNAL_NOTE:
            return <InternalNoteIcon />
        default:
            return false;
    }
}
