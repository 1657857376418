import React, {useContext, useState} from "react";
import {ArrowDownward, CheckRounded, CloseRounded, LockOpen, WarningOutlined} from "@mui/icons-material";
import {
    Button,
    createTheme,
    Dialog, DialogActions,
    DialogContent, DialogContentText,
    DialogTitle,
    Fab,
    IconButton,
    Tooltip
} from "@mui/material";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";

import {FormattedMessage, injectIntl, FormattedHTMLMessage} from "react-intl";

import {QuoteContext} from "../../../../../contexts/quoteContext";
import {dangerColor, sidebarColor, successColor} from "../../../../../assets/jss/main";
import detailStyle from "../../../../../assets/jss/views/quote/detailStyle";
import {REVIEW_IN_PROGRESS, WAITING_CUSTOMER, WAITING_REVIEW} from "../../../enums/state";
import {downloadQuotePdf} from "../../../../../api/quote";
import {withStyles} from "@mui/styles";

const QuoteDetailHeaderActions = ({ classes, intl }) => {
    const theme = createTheme({
        palette: {
            default: {main: sidebarColor},
            primary: {main: successColor},
            secondary: {main: dangerColor},
        },
    });

    const [dialogOpened, setDialogOpened] = useState(false);
    const [dialogType, setDialogType] = useState('validate');

    const {quote, isProcessing, forceCustomerValidateQuote, validateQuote, refuseQuote, unlockQuote, isSubmitting} = useContext(QuoteContext);
    const [downloadingPdf, setDownloadingPdf] = useState(false);
    if (!quote) {
        return null;
    }

    const downloadPdf = async () => {
        if (downloadingPdf) {
            return;
        }

        setDownloadingPdf(true);
        await downloadQuotePdf(quote.id, quote.number);
        setDownloadingPdf(false);
    }

    const openDialogType = function (type){
        setDialogType(type);
        setDialogOpened(true);
    }

    const closeDialog = () => {
      setDialogOpened(false);
    }

    const handleAction = (action) => {
      if('forceCustomerValidation' === action) {
          forceCustomerValidateQuote();
      }
      if('validate' === action) {
          validateQuote();
      }
      if('refuse' === action) {
          refuseQuote();
      }
      if('edit' === action) {
          unlockQuote();
      }
      setDialogOpened(false);
    }

    const canAccept = [
        WAITING_REVIEW,
        REVIEW_IN_PROGRESS,
    ].indexOf(quote.state) !== -1;

    const actionButtonsDisabled = !canAccept || isProcessing || isSubmitting;

    const renderMessageContent = () => {
        if (dialogType === 'forceCustomerValidation') {
            return (
                <div className={classes.alignFlexCenter}>
                    <WarningOutlined className={classes.warningForceCustomerValidation}/>
                    <FormattedHTMLMessage id={"picking.quote.detail.dialog.content." + dialogType} />
                </div>
            );
        }

        return <FormattedMessage id={"picking.quote.detail.dialog.content." + dialogType} />
    }

    return (
        <div>
            <div>
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={theme}>
                        <span className={classes.orderActions}>
                            <span>
                                {intl.formatMessage({id: "picking.quote.detail.header.actions"})} :
                            </span>
                            {
                                quote.state !== WAITING_CUSTOMER && (
                                    <>
                                        <Fab
                                            disabled={actionButtonsDisabled}
                                            color="primary"
                                            size={'small'}
                                            className={classes.validationButton}
                                            onClick= {() => {openDialogType('validate')}}
                                        >
                                            <Tooltip title={<FormattedMessage id={'picking.quote.detail.header.actions.accept'}/>}>
                                                <CheckRounded classes={{root: classes.validateAction}}/>
                                            </Tooltip>
                                        </Fab>
                                        <Fab
                                            disabled={actionButtonsDisabled}
                                            color="secondary"
                                            size={'small'}
                                            className={classes.cancelButton}
                                            onClick={() => openDialogType('refuse')}
                                        >
                                            <Tooltip title={<FormattedMessage id={"picking.quote.detail.header.actions.refuse"}/>}>
                                                <CloseRounded classes={{root: classes.cancelAction}}/>
                                            </Tooltip>
                                        </Fab>
                                    </>
                                )
                            }
                            {
                                quote.state === WAITING_CUSTOMER && (
                                    <>
                                        <Fab
                                            disabled={isProcessing || isSubmitting}
                                            color="default"
                                            size={'small'}
                                            className={classes.editButton}
                                            onClick={() => openDialogType('edit')}
                                        >
                                            <Tooltip title={<FormattedMessage id={"picking.quote.detail.header.actions.retailer_edit"}/>}>
                                                <LockOpen classes={{root: classes.editAction}}/>
                                            </Tooltip>
                                        </Fab>
                                        <Fab
                                            color="primary"
                                            size={'small'}
                                            className={classes.validationButton}
                                            onClick= {() => {openDialogType('forceCustomerValidation')}}
                                        >
                                            <Tooltip title={<FormattedMessage id={'picking.quote.detail.header.actions.accept'}/>}>
                                                <CheckRounded classes={{root: classes.validateAction}}/>
                                            </Tooltip>
                                        </Fab>
                                    </>
                                )
                            }
                        </span>
                    </ThemeProvider>
                </StyledEngineProvider>
            </div>

            <Dialog open={dialogOpened} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title-validation">
                    <FormattedMessage id={"picking.quote.detail.dialog.title." + dialogType}/>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {renderMessageContent()}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => closeDialog()} color="primary">
                        <FormattedMessage id={"picking.quote.detail.dialog.button.cancel"}/>
                    </Button>
                    <Button onClick={() => handleAction(dialogType)} color="primary">
                        <FormattedMessage id={"picking.quote.detail.dialog.button.confirm"}/>
                    </Button>
                </DialogActions>
            </Dialog>

            <div>
                <span className={classes.orderActions}>
                    <span>
                        {intl.formatMessage({id: "picking.quote.detail.header.downloadQuote"})} :
                    </span>
                    <Tooltip
                        title={canAccept ? intl.formatMessage({id: "picking.quote.detail.header.pdf_not_synchronized"}) : ''}
                    >
                        <IconButton disabled={downloadingPdf} onClick={downloadPdf} size="large">
                            <ArrowDownward className={classes.downloadPdf}/>
                        </IconButton>
                    </Tooltip>
                </span>
            </div>
        </div>
    );
};

export default withStyles(detailStyle)(injectIntl(QuoteDetailHeaderActions));
