import React, {Component} from "react";
import ProductsTable from "../../../../domain/components/products/ProductsTable";

class MarketPlaceProductsTables extends Component {
    render() {
        const offer = 'marketPlace';
        return <ProductsTable match={this.props.match} history={this.props.history} offer={offer}/>
    }
}

export default MarketPlaceProductsTables;