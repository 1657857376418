import React from 'react';

import {styled} from "@mui/styles";

import Grid from "@mui/material/Grid";
import {borderGrayColor} from 'assets/jss/main';

function GridCommentTotal(props) {
    const StyledGrid = styled(Grid)(({theme}) => ({
        width: '100%',
        gridArea: 'comment',
        [theme.breakpoints.down('sm')]: {
            padding: '32px 0px',
            borderTop: `1px solid ${borderGrayColor}`,
        },
    }));

    return (
        <StyledGrid>
            {props.children}
        </StyledGrid>
    );
}

export default GridCommentTotal;
