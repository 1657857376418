import {container,infoColor} from "../main";

const errorPageStyle = {
    container: {
        ...container
    },
    card: {
        overflow: "hidden",
        minHeight: window.innerHeight * 0.93,
        display: "flex",
        justifyContent: "center"
    },
    cardContainer: {
        alignItems: "center"
    },
    pictoContainer: {
        minWidth: "480px",
        minHeight: "inherit",
        marginTop: "-50px",
        marginBottom: "-50px"
    },
    picto: {
        margin: "-100px"
    },
    textContainer: {
        minWidth: "480px"
    },
    textContent: {
        margin: "50px"
    },
    errorCode: {
        color: infoColor,
        fontFamily: "Roboto",
        fontSize: 180,
        fontWeight: "bold"
    },
    title: {
        color: "black",
        fontFamily: "Roboto",
        fontSize: 25,
        fontWeight: "bold"
    },
    underlinedTitle: {
        borderColor: infoColor,
        borderWidth: "0.5px",
    },
    description: {
        marginTop: "15px",
        marginBottom: "15px",
        fontFamily: "Roboto",
        fontSize: 15,
        fontWeight: 300
    },
    textLink: {
        fontFamily: "Roboto",
        fontSize: 15,
        fontWeight: 300,
    },
    linkRedirection: {
        fontFamily: "Roboto",
        fontSize: 15,
        fontWeight: 400,
        color: infoColor,
        textDecoration: "none"
    },
    buttonRedirection: {
        paddingTop: "20px",
        fontFamily: "Roboto",
        fontSize: 15,
        fontWeight: 300
    },
    oopsLabel:{
        color: infoColor,
        fontFamily: "Roboto",
        fontSize: 120,
        fontWeight: "bold"
    }
};

export default errorPageStyle;