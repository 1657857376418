export function filterReducer(filters, action) {

    function removeFromFilters(filters, item) {
        return filters.filter(function(filter) {
            return filter.id !== item.id;
        });
    }

    switch (action.type) {
        case 'filter': {
            let f = filters.find(f => f.id === action.item.id);
            if (f !== undefined) {
                return removeFromFilters(filters, action.item);
            }
            return [...filters, action.item];
        }
        case 'filter_remove': {
            return removeFromFilters(filters, action.item);
        }
        case 'filters_reset': {
            return [];
        }

        default: {
            throw Error('Unknown action: ' + action.type);
        }
    }
}
