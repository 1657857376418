import React from "react";
import {FormattedMessage, FormattedHTMLMessage} from "react-intl";

// @mui/icons-material
import {Badge} from "@mui/material";
import {ArrowForward} from "@mui/icons-material";

// enum
import {statusChange} from "../../../domain/enum/lineItem/lineItemActivityType";
import {getTranslation} from "../../../domain/helpers/translations";

function LineItemActivity(props) {
    const { classes, lineItemActivity } = props;

    function getLineItemStatusClass(status) {
        let statusClass = status === "to-ship" ? classes.toShip : classes[status];
        return classes.lineItemActivityStatusChanges + ' ' + statusClass;
    }

    function renderOldData(lineItemActivity) {
        let isNotReturning = true
        let badgeContent = ''
        let itemStatus = lineItemActivity.oldData
        if (['returning-runner', 'returning-retailer'].includes(lineItemActivity.oldData)) {
            badgeContent = lineItemActivity.oldData === 'returning-runner' ? 'R' : 'M'
            isNotReturning = false
            itemStatus = 'returning'
        }
        if (lineItemActivity.type === statusChange) {
            return (
                <Badge className={classes.returningBadge} badgeContent={badgeContent} color="primary" invisible={isNotReturning}>
                    <span className={getLineItemStatusClass(itemStatus)}>
                        <FormattedMessage id={"picking.order.activity.status." + itemStatus}/>
                    </span>
                </Badge>
            )
        }

        return <span>{lineItemActivity.oldData}</span>;
    }

    function renderNewData(lineItemActivity) {
        let isNotReturning = true
        let badgeContent = ''
        let itemStatus = lineItemActivity.newData
        if (['returning-runner', 'returning-retailer'].includes(lineItemActivity.newData)) {
            badgeContent = lineItemActivity.newData === 'returning-runner' ? 'R' : 'M'
            isNotReturning = false
            itemStatus = 'returning'
        }
        if (lineItemActivity.type === statusChange) {
            return (
                <Badge className={classes.returningBadge} badgeContent={badgeContent} color="primary" invisible={isNotReturning}>
                    <span className={getLineItemStatusClass(itemStatus)}>
                        <FormattedMessage id={"picking.order.activity.status." + itemStatus}/>
                    </span>
                </Badge>
            )
        }

        return <span>{lineItemActivity.newData}</span>;
    }

    return (
        <div className={classes.lineItemActivityStatus}>
            <div>
                {lineItemActivity.type === 'LineItemCustomerShippingChange' ? (
                    <FormattedMessage id={`picking.order.show.table.lineItem.activity.${lineItemActivity.type}.${lineItemActivity.field}`} />
                ) : (
                    <>
                        <FormattedMessage id={`picking.order.show.table.lineItem.activity.${lineItemActivity.type}`} />:
                        <b>{lineItemActivity.lineItem.name}</b>
                    </>
                )}
                {lineItemActivity.cancellationReason &&
                    <span className={classes.lineItemActivityCancellationReason}>
                        <FormattedHTMLMessage
                            id={"picking.order.show.table.lineItem.activity.cancellationReason"}
                            values={{
                                cancellationReason: getTranslation(lineItemActivity.cancellationReason).name,
                            }}
                        />
                    </span>
                }
            </div>
            <div className={classes.lineItemActivityStatusChangesBox}>
                {renderOldData(lineItemActivity)}
                <ArrowForward className={classes.lineItemActivityStatusArrow}/>
                {renderNewData(lineItemActivity)}
            </div>
        </div>
    )
}

export default LineItemActivity;
