import React from "react";
import GridContainer from "../../../../../components/grid/gridContainer";
import QuoteDetailHeaderInfosBillingAddress from "./headerBillingAddress";
import QuoteDetailHeaderInfosShippingAddress from "./headerShippingAddress";

const QuoteDetailHeaderAddresses = () => {
    return (
        <GridContainer>
            <QuoteDetailHeaderInfosBillingAddress/>
            <QuoteDetailHeaderInfosShippingAddress/>
        </GridContainer>
    );
};

export default QuoteDetailHeaderAddresses;
