import React from 'react';
import { FormattedMessage } from "react-intl";

// @mui/material components
import {makeStyles, withStyles} from '@mui/styles';

// style
import { primaryColor } from "../../../assets/jss/main";
import rowStyle from "../../../assets/jss/views/corner/rowStyle";

const useStyles = makeStyles(theme => ({
    tableHeadContainer:{
        display: "flex",
        justifyContent: "space-between",
        width: "100%"
    },
    headContainer:{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
    },
    primaryTableHeader: {
        color: primaryColor,
        fontSize: "16px",
        fontWeight: "400",
    },
    loaderContainer: {
        color: primaryColor,
        fontSize: "16px",
        fontWeight: "400",
    },
}));

function EnhancedTableHead (props) {
    const componentClasses = useStyles();
    const { classes } = props;

    const headers = [
        {
            translation: "corner.table.header.name",
            sizeClass: "three",
        },
        {
            translation: "corner.table.header.description",
            sizeClass: "five",
        },
        {
            translation: "corner.table.header.products_count",
            sizeClass: "one",
        },
        {
            translation: "corner.table.header.public",
            sizeClass: "one",
        },
        {
            translation: "corner.table.header.enabled",
            sizeClass: "one",
        },
        {
            translation: "corner.table.header.actions",
            sizeClass: "one",
        },
    ];

    return (
        <div className={componentClasses.tableHeadContainer}>
            <div className={componentClasses.headContainer}>
                {headers.map((header, index) => (
                    <div key={index} className={componentClasses.primaryTableHeader + ' ' + classes[header.sizeClass]}>
                        <FormattedMessage id={header.translation} />
                    </div>
                ))}
            </div>
        </div>
    );
}
export default withStyles(rowStyle)(EnhancedTableHead);
