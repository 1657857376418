import {fetch} from "../../../utils/dataAccess";

export function error(error) {
    return {type: "ATTRIBUTE_LIST_ERROR", error};
}

export function loading(loading) {
    return {type: "ATTRIBUTE_LIST_LOADING", loading};
}

export function success(retrieved) {
    return {type: "ATTRIBUTE_LIST_SUCCESS", retrieved};
}

export function reset() {
    return {type: "ATTRIBUTE_LIST_RESET"};
}

export function list(retailerId, missing, inStock, inValid) {
    return dispatch => {
        dispatch(loading(true));

        const params = new URLSearchParams();
        params.set('missing', missing ? 'true' : 'false');
        if (inStock) {
            params.set('inStock', 'true');
        }
        if (inValid) {
            params.set('inValid', 'true');
        }

        fetch(`/retailers/${retailerId}/attributes?${params.toString()}`).then(response => {
            response.json().then(retrieved => {
                dispatch(loading(false));
                dispatch(success(retrieved));
            })
        }).catch(e => {
            dispatch(loading(false));
            dispatch(error(true));
        });
    };
}
