import {FormattedMessage} from "react-intl";
import React, {Component} from "react";
import {Link} from "react-router-dom";

import {ListItem, ListItemIcon, ListItemText} from "@mui/material";
import {Note} from "@mui/icons-material";

class DrawerQuote extends Component {
    render() {
        const classes = this.props.classes;
        const isSelected = window.location.pathname.indexOf('/quote') === 0;

        return (
            <div>
                <ListItem
                    button
                    className={classes.listItem}
                    classes={{selected: classes.selected}}
                    key={"quote"}
                    selected={isSelected}
                    component={Link}
                    to={'/quotes'}
                >
                    <ListItemIcon>
                        <Note color={isSelected ? "primary" : "secondary"} />
                    </ListItemIcon>
                    <ListItemText
                        primaryTypographyProps={{color: isSelected ? "primary" : "secondary"}}
                        primary={<FormattedMessage id={"sidebar.item.quote"}/>}
                    />
                </ListItem>
            </div>
        )
    }
}

export default DrawerQuote;
