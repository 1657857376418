import React from "react";

import {Button} from "@mui/material";

import {styled} from '@mui/material/styles';

import {
    black100,
    black500,
    borderGrayColor,
    grey200,
    grey500,
} from "assets/jss/main";

const StyledButton = styled(Button)(({ theme }) => ({
    backgroundColor: '#ffffff',
    borderRadius: '8px',
    textTransform: 'none',
    padding:'12px 16px',
    borderColor: borderGrayColor,
    color: black500,
    lineHeight: 'normal',
    [theme.breakpoints.down('md')]: {
        maxWidth: '100%'
    },
    '&:hover': {
        backgroundColor: grey500,
        borderColor: borderGrayColor,
    },
    '&.Mui-disabled' : {
        borderColor: grey200,
        backgroundColor: grey200,
        color: black100,
    },
}));

export default function CancelButtonStyled({...props}) {
    return (
        <StyledButton
            variant="outlined"
            disableElevation
            {...props}
        >
            {props.children}
        </StyledButton>
    )
}
