import {
    primaryColor,
    dangerColor,
    grayColor,
    lightGrayColor,
} from "../../main";

const notificationStyle = theme => ({
        notificationBadge: {
            position: 'absolute',
            marginLeft: 120,
            zIndex: 3,
            background: "#FFF",
            border: "2px solid " + primaryColor,
            borderRadius: "50%",
            '&:hover': {
                filter: "brightness(85%)"
            },
        },
        notificationList: {
            width: '100%',
            backgroundColor: theme.palette.background.paper,
            boxShadow: "0 2px 2px 0 rgba(0, 0, 0, 0), 0 3px 1px -2px rgba(0, 0, 0, 0.01), 0 1px 5px 0 rgba(0, 0, 0, 0.13)",
            borderRadius: "6px",
            position: 'absolute',
            marginLeft: 140,
            marginTop: 20,
            zIndex: 3,
            overflow: 'auto',
            maxHeight: 720,
        },
        notificationHeader: {
            backgroundColor: lightGrayColor,
        },
        notificationItem:{
            wordBreak: "break-word",
            paddingLeft: 5,
        },
        newNotificationItem:{
            wordBreak: "break-word",
            paddingLeft: 5,
        },
        notificationItemText:{
            marginLeft: 5
        },
        notificationIconStack: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "21px",
        },
        newNotificationIcon: {
            color: primaryColor,
            fontSize: "small",
        },
        errorNotificationIcon: {
            color: dangerColor,
            fontSize: "small",
        },
        notificationHeaderAction: {
            fontSize:"small",
            textAlign: "end",
            lineHeight: "normal",
            paddingBottom: 10,
            fontStyle: "italic",
            cursor: "pointer",
            '&:hover': {
                color: primaryColor
            }
        },
        notificationDeleteAction: {
            cursor: "pointer",
            '&:hover': {
                color: dangerColor,
            },
        },
        notificationItemDate :{
            fontSize: '12px',
            color: grayColor
        },
        noNotification:{
            textAlign: "center",
            margin: "0 24px"
        },
        noNotificationIcon:{
            color:lightGrayColor,
            fontSize: 120
        },
        noNotificationText:{
            fontWeight: 500
        },
    })
;

export default notificationStyle;
