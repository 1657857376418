import React, {useContext} from "react";
import {FormattedMessage} from "react-intl";
import {withStyles} from "@mui/styles";
import {InvitationContext} from "../../../../contexts/invitationContext";
import AuthenticationStyle from "../../../../assets/jss/views/authentication/authenticationStyle";

const AuthenticationAcceptInvitationPage = ({classes}) => {
  const {invitedMember, changeMethod} = useContext(InvitationContext);

  return (
    <>
      <h1 className={classes.h1}>
        <FormattedMessage id={"authentication.invitation.acceptInvitationFrom"} values={{marketplace: invitedMember?.organizationName}}/>
      </h1>

      <div>
        <button className={[classes.btn, classes.btnBlock].join(' ')} onClick={() => changeMethod('google')}>
          <FormattedMessage id={"authentication.invitation.continueWithGoogle"} values={{email: invitedMember?.invitationEmail}}/>
        </button>
        <div className={classes.textSeparator}>
          <FormattedMessage id={"authentication.invitation.or"}/>
        </div>
        <button className={[classes.btn, classes.btnBlock].join(' ')} onClick={() => changeMethod('email')}>
          <FormattedMessage id={"authentication.invitation.continueWithEmail"} values={{email: invitedMember?.invitationEmail}}/>
        </button>
      </div>
    </>
  );
};

export default withStyles(AuthenticationStyle)(AuthenticationAcceptInvitationPage);
