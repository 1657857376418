import React, {useEffect, useState} from "react";
import { connect } from "react-redux";
import { injectIntl, FormattedMessage } from "react-intl";
import { Field, reduxForm } from "redux-form";

// @mui/material components
import {FormControlLabel, Switch} from "@mui/material";

// core components
import AllTranslationsRequiredMessage from "../../configuration/components/AllTranslationsRequiredMessage";
import FormFields from './formFields';
import GridItem from "components/grid/gridItem";
import Hierarchy from '../../configuration/components/hierarchy';
import ActionsContainer from "components/form/ActionsContainer";
import FormContainer from "views/configuration/components/FormContainer";
import CancelButton from "views/configuration/components/CancelButton";
import SubmitButton from "views/configuration/components/SubmitButton";
import TagForm from "views/tag/components/tagForm";
import store from '../../../store';

// action
import { update } from "actions/category/update";
import {removeFile, reset as resetPicture} from "actions/upload"

// styles
import FormTranslator from "../../../components/translator/formTranslator";
import {getTranslation} from "../../../domain/helpers/translations";

function EditForm(props) {
    const { category, closeDialog, intl, update, updateLoading, uploaded, uploadLoading, submitting, values, resetPicture } = props;

    const isFormValid = !! (values && getTranslation(values).name);

    const [pictures, setPictures] = useState(null);
    const [locale, setLocale] = useState(null);
    const [descriptions, setDescriptions] = useState(null);
    const [longDescriptions, setLongDescriptions] = useState(null);
    const [tagEnabled, setTagEnabled] = useState(category.tagEnabled ?? false);

    // Fetching available tag colors configured in organization
    const tagColors = store.getState().currentOrganization?.retrieved?.tagColors ?? [];

    // Modal has a tab-index.The ckeditor wysiwyg link component can't work inside a modal with a tab-index
    const modal = document.getElementsByClassName('MuiDialog-container')[0];
    if (modal) {
        modal.removeAttribute('tabIndex');
    }

    useEffect(() => {
        if (props.uploaded !== null) {
            // We need to reset the "uploaded" global state because it's reused in others forms
            // I assume it's a bad design at the very first start of this component that still hit us.
            resetPicture();
        }
    }, [props.uploaded]);

    if (pictures === null) {
        let translatedPictures = {};
        for (let code in values.translations) {
            translatedPictures[code] = values.translations[code].picture;
        }
        setPictures(translatedPictures);
    }

    if (descriptions === null) {
        let descriptions = {};
        for (let code in values.translations) {
            descriptions[code] = values.translations[code].description;
        }
        setDescriptions(descriptions);
    }

    if (longDescriptions === null) {
        let longDescriptions = {};
        for (let code in values.translations) {
            longDescriptions[code] = values.translations[code].longDescription;
        }
        setLongDescriptions(longDescriptions);
    }

    let translatedPictures = {...pictures};
    if (uploaded !== null && translatedPictures[locale] !== uploaded) {
        translatedPictures[locale] = uploaded;
        setPictures(translatedPictures);
    }

    const onDeletePicture = (code) => {
        const translatedPictures = {...pictures};
        translatedPictures[code] = null;
        setPictures(translatedPictures);
    }

    async function submit(e) {
        e.preventDefault();

        if (category) {
            let newValues = {...values};
            if (newValues.description === null) {
                newValues.description = '';
            }

            if (category.parent === null) {
                delete newValues.parent;
            } else {
                newValues.parent = category.parent && category.parent["@id"];
            }

            for (let code in values.translations) {
                if (isCategoryTranslationImageDeleted(pictures[code], values.translations[code].picture)) {
                    await props.removeFile(values.translations[code].picture['@id']);
                }

                newValues.translations[code].picture = pictures[code];
                newValues.translations[code].description = descriptions[code];
                newValues.translations[code].longDescription = longDescriptions[code];
            }

            newValues.tagEnabled = tagEnabled;

            update(category, newValues);
        }
    }

    function isCategoryTranslationImageDeleted(newImage, actualImage) {
        if (newImage || actualImage === null) {
            return false;
        }

        if (newImage === null && actualImage) {
            return true;
        }
    }

    const renderSwitches = ({ input, label }) => (
        <FormControlLabel
            control={<Switch
                checked={input.value ? true : false} // Looks stupid but documented as it
                onChange={input.onChange}
                color="primary"
                inputProps={{ 'aria-label': 'primary checkbox' }}
                value={category ? category[input.name] : false}
            />}
            label={label}
        />
    );

    const renderTranslationForm = (index, code) => {
        return (
            <div>
                <GridItem xs={12} sm={12}>
                    <Field
                        component={FormFields}
                        name={`translations.${code}.name`}
                        label={intl.formatMessage({id: category.parent ? "category.form.label.sub.name" : "category.form.label.name"})}
                        type={"text"}
                        placeholder={intl.formatMessage({id: "category.form.placeholder.name"})}
                        required
                    />
                </GridItem>
                <GridItem xs={12} sm={12}>
                    <Field
                        component={FormFields}
                        name={`translations.${code}.description`}
                        label={intl.formatMessage({id: "category.form.label.description"})}
                        type={"wysiwyg"}
                        onChange={(value => {
                            descriptions[code] = value;
                            setDescriptions(descriptions);
                        })}
                        placeholder={intl.formatMessage({id: "category.form.placeholder.description"})}
                    />
                </GridItem>
                <GridItem xs={12} sm={12}>
                    <Field
                        component={FormFields}
                        name={`translations.${code}.longDescription`}
                        label={intl.formatMessage({id: "category.form.label.longDescription"})}
                        type={"wysiwyg"}
                        onChange={(value => {
                            longDescriptions[code] = value;
                            setLongDescriptions(longDescriptions);
                        })}
                        placeholder={intl.formatMessage({id: "category.form.placeholder.longDescription"})}
                    />
                </GridItem>
                <GridItem xs={12} sm={12}>
                    <p>ℹ️ <FormattedMessage id="category.form.helptext"/></p>
                    <Field
                        component={FormFields}
                        name={`translations.${code}.picture`}
                        label={intl.formatMessage({id: "category.form.label.image"})}
                        type={"file"}
                        placeholder={"image"}
                        picture={pictures[code]}
                        onDeletePicture={() => {onDeletePicture(code)}}
                    />
                </GridItem>
                <GridItem xs={12} sm={12}>
                    <p>ℹ️ <FormattedMessage id="category.form.seo.helptext"/></p>
                    <Field
                        component={FormFields}
                        name={`translations.${code}.metaTitle`}
                        label={intl.formatMessage({id: "category.form.label.metaTitle"})}
                        type={"text"}
                        placeholder={intl.formatMessage({id: "category.form.placeholder.metaTitle"})}
                    />
                </GridItem>
                <GridItem xs={12} sm={12}>
                    <Field
                        component={FormFields}
                        name={`translations.${code}.metaDescription`}
                        label={intl.formatMessage({id: "category.form.label.metaDescription"})}
                        type={"textarea"}
                        placeholder={intl.formatMessage({id: "category.form.placeholder.metaDescription"})}
                    />
                </GridItem>
                {tagEnabled &&
                    <GridItem xs={12} sm={12}>
                        <Field
                            component={FormFields}
                            name={`translations.${code}.tagLabel`}
                            label={intl.formatMessage({id: "category.form.label.tagLabel"})}
                            type={"text"}
                            placeholder={intl.formatMessage({id: "category.form.placeholder.tagLabel"})}
                            required={true}
                        />
                    </GridItem>
                }
            </div>
        );
    };


    return (
        <form onSubmit={(e) => submit(e)}>
            <FormContainer>
              <AllTranslationsRequiredMessage />
                {category && (
                    <GridItem xs={12} sm={12}>
                        <Hierarchy attributeName={'category'} attributeSelected={category} />
                    </GridItem>
                )}
                <GridItem xs={12} sm={12}>
                    <FormTranslator onLocaleChange={(locale) => setLocale(locale.code)} formGenerator={renderTranslationForm} />
                </GridItem>
                <GridItem xs={12} sm={12}>
                    <TagForm tagEnabled={tagEnabled} tagColors={tagColors} />
                </GridItem>
                <GridItem xs={12} sm={12}>
                    <Field
                        component={renderSwitches}
                        name={"restricted"}
                        label={intl.formatMessage({id: "category.form.label.restricted"})}
                    />
                </GridItem>
                <GridItem xs={12} sm={12}>
                    <Field
                        component={renderSwitches}
                        name={"catalogOnly"}
                        label={intl.formatMessage({id: "category.form.label.catalogOnly"})}
                    />
                </GridItem>
                <GridItem xs={12} sm={12}>
                    <Field
                        component={renderSwitches}
                        name={"privateProducts"}
                        label={intl.formatMessage({id: "category.form.label.privateProducts"})}
                    />
                </GridItem>
                <GridItem xs={12} sm={12}>
                    <Field
                        component={renderSwitches}
                        name={"privatePrices"}
                        label={intl.formatMessage({id: "category.form.label.privatePrices"})}
                    />
                </GridItem>
                <GridItem xs={12} sm={12}>
                    <Field
                        component={renderSwitches}
                        name={"hiddenPrices"}
                        label={intl.formatMessage({id: "category.form.label.hiddenPrices"})}
                    />
                </GridItem>
                <GridItem xs={12} sm={12}>
                    <Field
                        component={renderSwitches}
                        onChange={e => {
                            setTagEnabled(e.target.checked)
                        }}
                        name={"tagEnabled"}
                        label={intl.formatMessage({id: "category.form.label.tagEnabled"})}
                    />
                </GridItem>
            </FormContainer>
            <ActionsContainer>
                <CancelButton onClick={closeDialog} />
                <SubmitButton
                    isEditing={true}
                    disabled={!isFormValid || submitting || uploadLoading}
                    loading={updateLoading}
                />
            </ActionsContainer>
        </form>
    );
}

const mapStateToProps = state => {
    return {
        updateLoading: state.category.update.loading,
        values: state.form && state.form.editCategory && state.form.editCategory.values,
        uploaded: state.upload.success,
        uploadLoading: state.upload.loading
    }
};

const mapDispatchToProps = dispatch => ({
    update: (item, values) => dispatch(update(item, values)),
    resetPicture: () => dispatch(resetPicture()),
    removeFile: (file_id) => dispatch(removeFile(file_id))
});

export default reduxForm({
    form: "editCategory",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
})(connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(EditForm)));
