import {primaryColor} from "../../main";

const metadataStlye = theme => ({
    field: {
        height: "25px",
        marginRight: "10px",
        marginLeft: "5px",
    },
    metadataContainer: {
        marginTop: "15px",
        marginBottom: '40px',
    },
    title: {
        color: primaryColor,
        fontWeight: 'bold',
        fontSize: '20px',
        marginBottom: '15px',
        display: 'inline',
    },
    metadata: {
        marginBottom: "10px",
    },
    newMetadata: {
        marginBottom: "15px",
    },
    divider: {
        marginTop: "10px",
    },
    removeItem: {
        width: "20px",
        minHeight: 'unset',
        height: "20px",
        marginLeft: "7px",
    },
    input: {
        border: "2px solid #eee",
        borderRadius: "4px",
        width: "30%",
        padding: "8px 10px",
        boxShadow: "none",
        outline: "0",
        marginRight: "15px",
        marginLeft: "5px",
        resize: "none",
        maxHeight: "200px",
        fontSize: "14px",
        "&:focus": {
            borderColor: "#0857cc !important",
        },
        "&::placeholder": {
            fontSize: "14px",
            color: "#acacac",
            fontWeight: "400",
            textAlign: "left",
            fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
            opacity: "1",
            lineHeight: "1.42857",
        },

        "& .MuiInput-root": {
            border: "none",
            "&::before": {
                borderBottom: "none",
            },
            "& textarea": {
                fontSize: "14px",
            }
        },

        "& .MuiInput-underline:hover:not(.Mui-disabled)::before": {
            borderBottom: "none",
        },

        "& .MuiInput-underline::after": {
            borderBottom: "none",
        }
    },

    addMetadata: {
        width: "30px",
        minHeight: 'unset',
        height: "30px",
        marginLeft: "7px",
        marginBottom: "8px",
    },

});

export default metadataStlye;
