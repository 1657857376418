import React, {useEffect, useState} from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import Dropzone from "react-dropzone";

import {Formik} from "formik";

// @mui/material components
import {makeStyles} from "@mui/styles";
import Switch from "@mui/material/Switch";

// @mui/icons-material
import {AddPhotoAlternateOutlined, Delete} from "@mui/icons-material";

// core components
import AllTranslationsRequiredMessage from "../../configuration/components/AllTranslationsRequiredMessage";
import GridItem from "components/grid/gridItem";
import FormTranslator from "components/translator/formTranslator";
import FormikInput from "../../../components/input/formikInput";
import ActionsContainer from "components/form/ActionsContainer";
import FormContainer from "views/configuration/components/FormContainer";
import CancelButton from "views/configuration/components/CancelButton";
import SubmitButton from "views/configuration/components/SubmitButton";
import FormikTagForm from "views/tag/components/formik/formikTagForm";

// styles
import {dangerColor, darkGrayColor, infoColor, successColor} from "../../../assets/jss/main";

import store from '../../../store';

// api
import create from '../../../api/corner/create';
import update from '../../../api/corner/update';
import GridContainer from "../../../components/grid/gridContainer";
import { TagPosition } from "../../tag/components/tagPosition";

const useStyles = makeStyles(theme  => ({
    colorSwitchBase: {
        color: dangerColor,
        '&$colorChecked': {
            color: dangerColor,
            '& + $colorBar': {
                backgroundColor: dangerColor,
            },
        },
    },
    colorBar: {
        backgroundColor: dangerColor,
    },
    colorChecked: {
        color: successColor,
        '&$colorChecked': {
            color: successColor,
            '& + $colorBar': {
                backgroundColor: successColor,
            },
        },
    },
    fieldLabel: {
        color: infoColor,
    },
    image: {
        maxWidth: "150px",
        maxHeight: "150px",
        display: "inline-block"
    },
    pictureContainer: {
        position: "relative",
        float: "right",
        display: "flex",
        justifyContent: "flex-end",
        cursor: "pointer",
        borderRadius: "15px",
        border: "2px dashed #E0E0E0",
        "& div:last-child": {
            borderWidth: "0px!important",
            width: "145px!important",
            height: "145px!important",
            overflow: "hidden",
            "& img": {
                width: "140px",
                maxHeight: "140px",
                maxWidth: "140px",
                borderRadius: "18%"
            },
            "& input": {
                display: "none"
            }
        }
    },
    picturePlaceholderContent: {
        display: "flex",
        alignItems: "center",
    },
    pictureIcon: {
        position: "absolute",
        zIndex: "1",
        color: darkGrayColor,
        pointerEvents: "none",
        width: "100%",
        textAlign: "center",
        marginTop: "-12px"
    },
    pictureText: {
        color: darkGrayColor,
        zIndex: "1",
        position: "absolute",
        fontSize: "12px",
        pointerEvents: "none",
        fontWeight: "400",
        width: "100%",
        textAlign: "center",
        marginTop: "12px"
    },
    picturePlaceholder: {
        borderRadius: "25%",
        width: "140px",
        height: "140px",
        background: "linear-gradient(60deg, #377dff, #0052cb)",
    },
    pictureLoading: {
        position: "absolute",
        zIndex: "1",
        color: darkGrayColor+"!important",
        right: "50px",
        top: "50px",
        pointerEvents: "none",
    },
    slickRemove : {
        width: "30px",
        height: "20px",
        position: "absolute",
        right: "5px",
        top: "5px",
        color: darkGrayColor,
        cursor: "pointer",
        backgroundColor: "#FFF",
        borderRadius: "35px"
    },
}));

const fileReader = new FileReader();

function Form(props) {
    const { closeDialog, intl, submitted, corner } = props;
    const classes = useStyles();
    const [locales, setLocales] = useState(null);
    const [currentLocale, setCurrentLocale] = useState(locales ? locales[0] : null);
    const isEditing = !!corner;
    const [tagEnabled, setTagEnabled] = useState(corner?.tagEnabled ?? false);
    const [images, setImages] = useState(() => {
        if (!isEditing) {
            return {};
        }
        const imagesTmp = {};
        for (const [locale, translation] of Object.entries(corner.translations)) {
            if (!!translation.image && translation.image.contentUrl) {
                imagesTmp[locale] = translation.image.contentUrl;
            }
        }
        return imagesTmp;
    }); // Backend already know images as media object, or have been uploaded by the user and are loaded in browser memory
    const [files, setFiles] = useState({}); // Files are uploaded by user but not submitted to backend

    // Fetching available tag colors configured in organization
    const tagColors = store.getState().currentOrganization?.retrieved?.tagColors ?? [];

    useEffect(
        () => setLocales([...store.getState().currentOrganization?.retrieved?.locales]),
        [store.getState().currentOrganization?.retrieved?.locales]
    );
    useEffect(() => {
        fileReader.onload = function() {
            const imageBase64 = this.result;
            const imagesTmp = {
                ...images,
            };
            imagesTmp[currentLocale] = imageBase64;
            setImages(imagesTmp);
        };
    }, [images, currentLocale]);

    if (!locales) {
        return <></>;
    }

    const translations = {};
    if (isEditing) {
        locales.forEach(locale => translations[locale] = {
            image: corner.translations[locale]?.image?.contentUrl,
            name: corner.translations[locale]?.name,
            description: corner.translations[locale]?.description,
            longDescription: corner.translations[locale]?.longDescription,
            metaDescription: corner.translations[locale]?.metaDescription,
            metaTitle: corner.translations[locale]?.metaTitle,
            slug: corner.translations[locale]?.slug,
            tagLabel: corner.translations[locale]?.tagLabel,
        });
    } else {
        locales.forEach(locale => translations[locale] = {
            image: '',
            name: '',
            description: '',
            slug: '',
            longDescription: '',
            metaDescription: '',
            metaTitle: '',
            tagLabel: '',
        });
    }

    const initialValues = {
        enabled: !!corner ? corner.enabled : true,
        public: !!corner ? corner.public : true,
        catalogOnly: !!corner ? corner.catalogOnly : false,
        privateProducts: !!corner ? corner.privateProducts : false,
        privatePrices: !!corner ? corner.privatePrices : false,
        hiddenPrices: !!corner ? corner.hiddenPrices : false,
        tagEnabled: !!corner ? corner.tagEnabled : false,
        // tagPosition and tagColor can be null in the corner (optional values) but Formik does not want null values
        tagPosition: !!corner ? (corner.tagPosition ?? '') : '',
        tagColor: !!corner ? (corner.tagColor ?? '') : '',
        tagPriority: !!corner ? corner.tagPriority : 1,
        translations: translations,
    };

    const validate = values => {
        const errors = {};

        locales.forEach(locale => {
            if (!!values.translations[locale]) {
                const translation = values.translations[locale];
                if (!translation.name || translation.name.trim() === '') {
                    if (!errors.translations) errors.translations = {};
                    if (!errors.translations[locale]) errors.translations[locale] = {};
                    errors.translations[locale].name = intl.formatMessage({id: "corner.form.error.name.missing"});
                }
                if (!translation.slug || translation.slug.trim() === '') {
                    if (!errors.translations) errors.translations = {};
                    if (!errors.translations[locale]) errors.translations[locale] = {};
                    errors.translations[locale].slug = intl.formatMessage({id: "corner.form.error.slug.missing"});
                }
                if (translation.metaDescription && translation.metaDescription.length > 255) {
                    if (!errors.translations) errors.translations = {};
                    if (!errors.translations[locale]) errors.translations[locale] = {};
                    errors.translations[locale].metaDescription = intl.formatMessage({id: "corner.form.error.metaDescription.too"});
                }
            }
        });

        return errors;
    };

    const renderTranslationForm = (index, code) => {

        function removeImage() {
            if (!images[currentLocale]) {
                return;
            }

            const imagesTmp = {...images};
            const filesTmp = {...files};
            delete imagesTmp[currentLocale];
            delete filesTmp[currentLocale];
            setImages(imagesTmp);
            setFiles(filesTmp);
        }

        function renderImage() {
            let image = images[currentLocale];
            if (!image) {
                return;
            }

            if (!!image['@id'] && !!image.contentUrl) {
                image = image.contentUrl;
            }

            return (
                <div>
                    <Delete className={classes.slickRemove} onClick={() => {removeImage()}}/>
                    <img className={classes.image} src={image} alt={'Image du corner'}/>
                </div>
            );
        }

        return (
            <div>
                <GridItem xs={12} sm={12}>
                    <FormikInput
                        labelText={intl.formatMessage({id: "corner.form.label.name"})}
                        inputProps={
                            {
                                name: `translations.${code}.name`,
                                type:"text",
                                placeholder: intl.formatMessage({id: "corner.form.placeholder.name"})
                            }
                        }
                    />
                </GridItem>
                <GridItem xs={12} sm={12}>
                    <FormikInput
                        labelText={intl.formatMessage({id: "corner.form.label.description"})}
                        inputProps={
                            {
                                name: `translations.${code}.description`,
                                type:"wysiwyg",
                                placeholder: intl.formatMessage({id: "corner.form.placeholder.description"}),
                            }
                        }
                    />
                </GridItem>
                <GridItem xs={12} sm={12}>
                    <FormikInput
                        labelText={intl.formatMessage({id: "corner.form.label.longDescription"})}
                        inputProps={
                            {
                                name: `translations.${code}.longDescription`,
                                type:"wysiwyg",
                                placeholder: intl.formatMessage({id: "corner.form.placeholder.longDescription"}),
                            }
                        }
                    />
                </GridItem>
                <GridItem xs={12} sm={12}>
                    <FormikInput
                        labelText={intl.formatMessage({id: "corner.form.label.metaTitle"})}
                        inputProps={
                            {
                                name: `translations.${code}.metaTitle`,
                                type:"text",
                                placeholder: intl.formatMessage({id: "corner.form.placeholder.metaTitle"})
                            }
                        }
                    />
                </GridItem>
                <GridItem xs={12} sm={12}>
                    <FormikInput
                        labelText={intl.formatMessage({id: "corner.form.label.metaDescription"})}
                        inputProps={
                            {
                                name: `translations.${code}.metaDescription`,
                                as:"textarea",
                                placeholder: intl.formatMessage({id: "corner.form.placeholder.metaDescription"})
                            }
                        }
                    />
                </GridItem>
                <GridItem xs={12} sm={12}>
                    <FormikInput
                        labelText={intl.formatMessage({id: "corner.form.label.slug"})}
                        inputProps={
                            {
                                name: `translations.${code}.slug`,
                                disabled: corner?.eshopId !== null && typeof corner?.eshopId === 'string',
                                type:"text",
                                placeholder: intl.formatMessage({id: "corner.form.placeholder.slug"})
                            }
                        }
                    />
                </GridItem>
                <GridItem xs={12} sm={12} sx={{ overflow: 'hidden', clear: 'both' }}>
                    <div className={classes.pictureContainer}>
                        {!images[currentLocale] &&
                            <div className={classes.picturePlaceholderContent}>
                                <AddPhotoAlternateOutlined fontSize={"large"} className={classes.pictureIcon}/>
                                <span className={classes.pictureText}>
                                    <FormattedMessage id={"product.form.action.upload"}/>
                                </span>
                            </div>
                        }

                        <Dropzone
                            name={`translations.${code}.image`}
                            accept="image/*"
                            multiple={false}
                            // There is a listener on file read initialized in the init of this component
                            onDrop={(filesToUpload) => {
                                fileReader.readAsDataURL(filesToUpload[0]);
                                const filesTmp = {...files};
                                filesTmp[currentLocale] = filesToUpload[0];
                                setFiles(filesTmp);
                            }}
                        >
                            {({getRootProps, getInputProps}) => {
                                return (
                                    <div
                                        {...getRootProps()}
                                    >
                                        <input {...getInputProps()} />
                                    </div>
                                )
                            }}
                        </Dropzone>
                        {renderImage(corner)}
                    </div>
                </GridItem>
                { tagEnabled &&
                    <GridItem xs={12} sm={12}>
                        <FormikInput
                            labelText={intl.formatMessage({id: "corner.form.label.tagLabel"})}
                            inputProps={
                                {
                                    name: `translations.${code}.tagLabel`,
                                    type:"text",
                                    placeholder: intl.formatMessage({id: "corner.form.placeholder.tagLabel"}),
                                    required: true
                                }
                            }
                        />
                    </GridItem>
                }
            </div>
        );
    };

    return (
        <Formik
            initialValues={initialValues}
            validate={validate}
            validateOnMount={true}
            onSubmit={(values, {setSubmitting}) => {
                setSubmitting(true);
                let promise;
                if (isEditing) {
                    for (const [locale, translation] of Object.entries(corner.translations)) {
                        values.translations[locale].id = translation.id;
                        values.translations[locale]['@id'] = translation['@id'];
                    }
                    promise = update(corner, values, files);
                } else {
                    promise = create(values, files);
                }

                locales.forEach(locale => {
                    const hasPreviousImage = !!corner?.translations[locale]?.image;
                    const hasNullDescription = values.translations[locale].description === null;
                    if (hasPreviousImage) {
                        if (!files[locale] && !images[locale]) {
                            values.translations[locale].image = null;
                        } else {
                            values.translations[locale].image = corner.translations[locale]?.image['@id'];
                        }
                    } else if (!!files[locale]) {
                        if (files[locale] instanceof File) {
                            values.translations[locale].image = files[locale];
                        }
                    }

                    if (hasNullDescription) {
                        values.translations[locale].description = ''
                    }
                });

                promise.finally(() => {
                    submitted(values);
                    setSubmitting(false);
                });
            }}
        >
            {({
                values,
                handleChange,
                handleSubmit,
                isSubmitting,
                isValid,
            }) => (
                    <form onSubmit={handleSubmit}>
                        <FormContainer>
                            <AllTranslationsRequiredMessage/>
                            <GridContainer>
                                <GridItem xs={6} sm={6}>
                                    <label htmlFor={`enabled`} className={classes.fieldLabel}>
                                        {intl.formatMessage({id: "corner.form.label.enabled"})}
                                    </label>
                                    <Switch
                                        checked={!!values ? values.enabled : initialValues.enabled}
                                        onChange={handleChange}
                                        classes={{
                                            switchBase: classes.colorSwitchBase,
                                            checked: classes.colorChecked,
                                            track: classes.colorBar,
                                        }}
                                        name={'enabled'}
                                    />
                                </GridItem>
                                <GridItem xs={6} sm={6}>
                                    <label htmlFor={`public`} className={classes.fieldLabel}>
                                        {intl.formatMessage({id: "corner.form.label.public"})}
                                    </label>
                                    <Switch
                                        checked={!!values ? values.public : initialValues.public}
                                        onChange={handleChange}
                                        classes={{
                                            switchBase: classes.colorSwitchBase,
                                            checked: classes.colorChecked,
                                            track: classes.colorBar,
                                        }}
                                        name={'public'}
                                    />
                                </GridItem>
                                <GridItem xs={6} sm={6}>
                                    <label htmlFor={`catalogOnly`} className={classes.fieldLabel}>
                                        {intl.formatMessage({id: "corner.form.label.catalogOnly"})}
                                    </label>
                                    <Switch
                                        checked={!!values ? values.catalogOnly : initialValues.catalogOnly}
                                        onChange={handleChange}
                                        classes={{
                                            switchBase: classes.colorSwitchBase,
                                            checked: classes.colorChecked,
                                            track: classes.colorBar,
                                        }}
                                        name={'catalogOnly'}
                                    />
                                </GridItem>
                                <GridItem xs={6} sm={6}>
                                    <label htmlFor={`privateProducts`} className={classes.fieldLabel}>
                                        {intl.formatMessage({id: "corner.form.label.privateProducts"})}
                                    </label>
                                    <Switch
                                        checked={!!values ? values.privateProducts : initialValues.privateProducts}
                                        onChange={handleChange}
                                        classes={{
                                            switchBase: classes.colorSwitchBase,
                                            checked: classes.colorChecked,
                                            track: classes.colorBar,
                                        }}
                                        name={'privateProducts'}
                                    />
                                </GridItem>
                                <GridItem xs={6} sm={6}>
                                    <label htmlFor={`privatePrices`} className={classes.fieldLabel}>
                                        {intl.formatMessage({id: "corner.form.label.privatePrices"})}
                                    </label>
                                    <Switch
                                        checked={!!values ? values.privatePrices : initialValues.privatePrices}
                                        onChange={handleChange}
                                        classes={{
                                            switchBase: classes.colorSwitchBase,
                                            checked: classes.colorChecked,
                                            track: classes.colorBar,
                                        }}
                                        name={'privatePrices'}
                                    />
                                </GridItem>
                                <GridItem xs={6} sm={6}>
                                    <label htmlFor={`hiddenPrices`} className={classes.fieldLabel}>
                                        {intl.formatMessage({id: "corner.form.label.hiddenPrices"})}
                                    </label>
                                    <Switch
                                        checked={!!values ? values.hiddenPrices : initialValues.hiddenPrices}
                                        onChange={handleChange}
                                        classes={{
                                            switchBase: classes.colorSwitchBase,
                                            checked: classes.colorChecked,
                                            track: classes.colorBar,
                                        }}
                                        name={'hiddenPrices'}
                                    />
                                </GridItem>
                                <GridItem xs={6} sm={6}>
                                    <label htmlFor={`tagEnabled`} className={classes.fieldLabel}>
                                        {intl.formatMessage({id: "corner.form.label.tagEnabled"})}
                                    </label>
                                    <Switch
                                        checked={tagEnabled}
                                        onChange={e => {
                                            handleChange(e)
                                            setTagEnabled(e.target.checked)
                                        }}
                                        classes={{
                                            switchBase: classes.colorSwitchBase,
                                            checked: classes.colorChecked,
                                            track: classes.colorBar,
                                        }}
                                        name={'tagEnabled'}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12}>
                                    <FormTranslator formGenerator={renderTranslationForm}
                                        onLocaleChange={localeConfig => setCurrentLocale(localeConfig.code)}/>
                                </GridItem>
                                <GridItem xs={12} sm={12}>
                                    <FormikTagForm tagEnabled={tagEnabled} tagColors={tagColors} />
                                </GridItem>
                            </GridContainer>
                        </FormContainer>
                        <ActionsContainer>
                            <CancelButton onClick={closeDialog}/>
                            <SubmitButton
                                isEditing={isEditing}
                                disabled={!isValid || isSubmitting}
                                loading={isSubmitting}
                            />
                        </ActionsContainer>
                    </form>
                )}
        </Formik>
    );
}

export default injectIntl(Form);
