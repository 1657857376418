import {fetch} from "../../utils/dataAccess";

export function error(error) {
    return {type: "NOTIFICATION_UPDATE_ERROR", error};
}

export function loading(loading) {
    return {type: "NOTIFICATION_UPDATE_LOADING", loading};
}

export function success(success) {
    return {type: "NOTIFICATION_UPDATE_SUCCESS", success};
}

export function update (item, values){
    return dispatch => {
        dispatch(loading(true));
        dispatch(success(false));

        return fetch(item["@id"], {
            method: "PUT",
            headers: new Headers({"Content-Type": "application/ld+json"}),
            body: JSON.stringify(values)
        })
        .then(() => {
            dispatch(loading(false));
            dispatch(success(item));
        })
        .catch((e) => {
            dispatch(loading(false));
            dispatch(error(e.message));
        });
    }
};
