import {primaryColor, lightBlueColor, dangerColor} from "../../main";

const createVariationFormStyle = theme => ({
    container: {
        borderRadius: "5px",
        backgroundColor: "#fff",
        fontSize: "12px",
        "& .custom__control":{
            borderWidth: "2px",
            borderColor: "#EEE",
            minHeight: "43px",
        },
        "& .custom__control.custom__control--is-focused, .custom__control.custom__control--is-opened":{
            borderWidth: "2px",
            borderColor: primaryColor + "!important",
            boxShadow: "none"
        },
        "& .custom__control:hover":{
            borderWidth: "2px",
            borderColor: "#EEE"
        },
        "& .custom__multi-value":{
            border: "0px solid ",
            backgroundColor: lightBlueColor,
            borderRadius: "3px",
            "& .custom__multi-value__remove":{
                color: "#FFF!important",
            },
            "& .custom__multi-value__remove:hover":{
                backgroundColor: "#8ab8d6!important",
                cursor: "pointer"
            },
            "& .custom__multi-value__label":{
                color: "#FFF!important",
                fontWeight: "bold",
                fontSize: "12px"
            },
        }
    },
    formContainerLock: {
        alignItems: "center",
        display: "flex",
        justifyContent: "space-between"
    },
    formSelect: {
        width: "100%"
    },
    sectionBody: {
        padding: "10px",
        "& hr": {
            margin: "10px 0 10px 0"
        }
    },
    actionsContainer: {
        display: 'flex',
        justifyContent: 'end'
    },
    actionsGridContainer: {
        display: 'flex',
        justifyContent: 'end',
        marginRight: 'inherit',
    },
    fieldLabel: {
        color: primaryColor,
        fontWeight: "bold"
    },
    formControl: {
        paddingTop: "0px",
        margin: "0 0 8px 0"
    },
    submitLoading: {
        marginLeft: "10px",
        color: "#FFFFFF"
    },
    input: {
        display: 'flex',
        padding: 0,
    },
    "& .custom__multi-value":{
        border: "3px solid green"
    },
    inputError: {
        color: dangerColor
    },
    // IMAGE DRAG AND DROP
    imageSlider: {
        marginBottom: '30px',
        display: 'flex'
    },
    droppable: {
        display: 'flex',
        overflow: 'auto'
    },
    imageContainer: {
        border: '2px solid #e0e0e0',
        width: 'auto',
        height: '150px',
        display: 'flex',
        justifyContent: 'center',
        margin: '0px 10px',
        overflow: 'clip',
        '& img': {
            alignSelf: 'center',
            maxWidth: '150px',
            maxHeight: '150px',
            display: 'inline-block'
        },
    },
    addImageInput: {
        display: "none"
    },
    slickRemove : {
        color: primaryColor,
        cursor: "pointer",
    },
    positionRelative: {
        position: 'relative',
    },
    scanButton: {
        height: '37px',
        width: '45px',
        cursor: 'pointer',
        paddingTop: '18px',
        paddingLeft: '0px',
        backgroundColor: 'transparent !important',
        marginBottom: '10px',
        position: 'absolute',
        right: '0',
        top: 'calc(50% - 17px)',
        zIndex: '1',
        boxShadow: 'none !important',
        borderRadius: '0',
        borderLeft: '2px #eee solid',
    },
    scanIcon: {
        width: '100% !important',
        height: '35px !important',
        position: 'relative',
        right: '-8px',
    },
});

export default createVariationFormStyle;
