export function navReducer(filter, action) {

    function getType(item) {
        switch (item['@type']) {
            case 'Category':
                return 'categories';
            case 'Corner':
                return 'corners';
            case 'Retailer':
                return 'retailers';
        }
    }

    switch (action.type) {
        case 'expand': {
            let item = action.navigationItem ?? filter.navigationItem;
            return ({
                navigationType: getType(action.expandingItem),
                navigationItem: item,
                expandingItem: action.expandingItem,
                sortField: filter.sortField,
                sortDirection: filter.sortDirection,
                shouldReset: action.shouldReset ?? false,
                shouldReload: action.shouldReload ?? true,
                loading: true,
            });
        }
        case 'nav': {

            let item = action.navigationItem ?? filter.navigationItem;

            return ({
                navigationType: getType(item),
                navigationValue: item.id,
                navigationItem: item,
                expandingItem: action.expandingItem ?? item,
                sortField: filter.sortField,
                sortDirection: filter.sortDirection,
                shouldReset: action.shouldReset ?? false,
                shouldReload: action.shouldReload ?? true,
                loading: true,
            });
        }

        case 'sort': {
            return ({
                navigationType: filter.navigationType,
                navigationValue: filter.navigationValue,
                navigationItem: filter.navigationItem,
                sortField: action.sortField,
                sortDirection: action.sortDirection,
                shouldReset: action.shouldReset ?? false,
                shouldReload: action.shouldReload ?? true,
                loading: true,
            });
        }

        default: {
            throw Error('Unknown action: ' + action.type);
        }
    }
}
