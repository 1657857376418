import React, {useContext} from "react";
import PropTypes from "prop-types";
import {injectIntl} from "react-intl";
import {useFormikContext} from "formik";

import RadioGroup from "components/radioGroup/RadioGroup";
import FormControlLabel from "components/radioGroup/FormControlLabel";

import {Sort} from "utils/recommendation";

import {RecommendationFormContext} from "../../context/RecommendationFormContext";

function SortField(props) {
    const { formFieldName, intl  } = props;

    const formikContext = useFormikContext();

    const {getValueByFormFieldName} = useContext(RecommendationFormContext);

    let value = getValueByFormFieldName(formFieldName);

    return (
        <>
            <RadioGroup
                selectedValueLabel={intl.formatMessage({id: `recommendations.form.sort.recommended_product.${value}`})}
                name={formFieldName}
                value={value}
                onChange={(event) => {
                    formikContext.setFieldValue(formFieldName, event.currentTarget.value)
                }}
                alignListWithButton
            >
                {Object.values(Sort).map((sortName, index) => (
                    <FormControlLabel
                        key={index}
                        value={sortName}
                        label={intl.formatMessage({id: `recommendations.form.sort.recommended_product.${sortName}`})}
                    />
                ))}
            </RadioGroup>
        </>
    )
}

SortField.propTypes = {
    formFieldName: PropTypes.string.isRequired
}

export default injectIntl(SortField);
