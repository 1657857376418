import React from 'react';

import Accordion from "@mui/material/Accordion";

export default function HistoryNoteAccordion(props) {
    return (
        <Accordion
            disableGutters={true}
            sx={{
                display: 'flex',
                flexDirection: 'column-reverse',
                boxShadow: 'unset',
                border: 'unset',
                padding: 'unset',
                width: '100%'
            }}
        >
            {props.children}
        </Accordion>
    );
}
