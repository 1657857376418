import React, {Component} from "react";
import {Field, reduxForm, change} from "redux-form";
import PropTypes from "prop-types";
import connect from "react-redux/es/connect/connect";
import {FormattedMessage} from "react-intl";
import Select from 'react-select';

// @mui/material components
import {Switch} from "@mui/material";

// core components
import Input from "components/input/input";
import GridContainer from "components/grid/gridContainer";
import GridItem from "components/grid/gridItem";
import ActionsContainer from "components/form/ActionsContainer";
import CancelButton from "../components/CancelButton";
import SubmitButton from "../components/SubmitButton";

// styles
import formStyle from "assets/jss/views/feed/formStyle";
import {withStyles} from "@mui/styles";

class Form extends Component {
    static propTypes = {
        handleSubmit: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            recurrenceUnit: null,
            recurrenceChecked: false
        };

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if((this.props.initialValues === null && nextProps.initialValues !== null) && nextProps.initialValues.recurrence > 0) {
            this.setState({recurrenceChecked: true});
        }
    }

    renderField = data => {
        const {classes} = this.props;
        const filedName = data.input.name;

        return (
            <div className={classes.fieldContainer}>
                <label
                    htmlFor={`csvLocalFeed_${data.input.name}`}
                    className={classes.fieldLabel}
                >
                    {data.label}
                </label>
                {data.type === "checkbox" &&
                <input
                    {...data.input}
                    type={data.type}
                    step={data.step}
                    required={data.required}
                    placeholder={data.placeholder}
                    id={`csvRemoteFeed_${data.input.name}`}
                    className={classes.fieldFloatRight}
                    checked={data.input.value}
                />
                }
                {(data.options && data.type === "select") &&
                <Select
                    id={`csvRemoteFeed_${data.name}_select`}
                    name={`${data.name}_select`}
                    options={data.options}
                    value={this.state[data.input.name] || {"label":data.input.value, "value": data.input.value} }
                    onChange={(value) => {
                        this.props.dispatch(change("csvRemoteFeed", data.input.name, value.value));
                        this.setState({[data.input.name]: value});
                    }}
                    onBlurResetsInput={false}
                />
                }
                {(data.type !== "select" && data.type !== "checkbox") &&
                <Input
                    {...data.input}
                    type={data.type}
                    step={data.step}
                    required={data.required}
                    id={`csvRemoteFeed_${data.input.name}`}
                    inputRootCustomClasses={classes.input}
                    inputProps={{
                        placeholder: data.placeholder,
                        type: data.type,
                        required: data.required,
                        ...data.input,
                        autoComplete: data.autoComplete
                    }}
                    formControlProps={{
                        className: classes.formControl
                    }}
                />
                }
                {this.props.createError && this.props.createError.errors && this.props.createError.errors[filedName] && <span className={classes.errorMessages}><FormattedMessage id={this.props.createError.errors[filedName]}/></span>}

            </div>
        );
    };

    handleSubmit = (e) => {
        const {fields} = this.props;

        e.preventDefault();

        if (fields.values && this.state.recurrenceChecked === false) {
            fields.values.recurrence = 0;
            fields.values.recurrenceUnit = "min";
        }

        this.props.handleSubmit();
    };

    render() {
        const {classes} = this.props;
        const parse = value => (value === "" || value < 0) ? 0 : parseInt(value);

        return (
            <form onSubmit={this.handleSubmit}>
                <GridContainer className={this.props.classes.formContainer}>
                    <GridItem xs={12} sm={6}>
                        <Field
                            component={this.renderField}
                            name={"name"}
                            label={<FormattedMessage id={"feed.form.label.name"}/>}
                            type={"text"}
                            required
                        />
                        <Field
                            component={this.renderField}
                            name={"configuration['url']"}
                            label={<FormattedMessage id={"feed.form.label.url"}/>}
                            type={"url"}
                            required
                        />
                        <Field
                            type={"select"}
                            component={this.renderField}
                            options={[{"label":",", "value":","}, {"label":";", "value":";"}, {"label":"|", "value":"|"}, {"label":"Tabulation", "value":"\t"}]}
                            name={"configuration['separator']"}
                            label={<FormattedMessage id={"feed.form.label.separator"}/>}
                            selected={{"label":",", "value":","}}
                            required
                        />
                        <Field
                            type={"select"}
                            component={this.renderField}
                            options={[{"label":"'", "value":"'"}, {"label":"\"", "value":"\""}, {"label":"|", "value":"|"}]}
                            name={"configuration['enclosure']"}
                            label={<FormattedMessage id={"feed.form.label.enclosure"}/>}
                            selected={{"label":"\"", "value":"\""}}
                            required
                        />
                    </GridItem>
                    <GridItem xs={12} sm={6}>
                        <Field
                            component={this.renderField}
                            name={"disableVariationNotSeenInFeed"}
                            label={<FormattedMessage id={"feed.form.label.disable.not.seen"}/>}
                            type={"checkbox"}
                        />
                        <Field
                            component={this.renderField}
                            name={"configuration['noHeaderCSV']"}
                            label={<FormattedMessage id={"feed.form.label.no.header"}/>}
                            type={"checkbox"}
                        />
                        <div className={classes.recurrence}>
                            <label className={classes.fieldLabel}>
                                <FormattedMessage id={"feed.form.label.recurrence"}/>
                            </label>
                            <Switch
                                checked={this.state.recurrenceChecked}
                                onChange={() => this.setState({recurrenceChecked: !this.state.recurrenceChecked})}
                                color="primary"
                            />
                        </div>
                        {this.state.recurrenceChecked &&
                        <div>
                            <Field
                                component={this.renderField}
                                name={"recurrence"}
                                type={"number"}
                                parse={parse}
                                required
                            />
                            <Field
                                type={"select"}
                                component={this.renderField}
                                options={[
                                    {"label":<FormattedMessage id={"feed.form.label.recurrenceUnit.min"}/>, "value":"min"},
                                    {"label":<FormattedMessage id={"feed.form.label.recurrenceUnit.hour"}/>, "value":"hour"},
                                    {"label":<FormattedMessage id={"feed.form.label.recurrenceUnit.day"}/>, "value":"day"}]}
                                name={"recurrenceUnit"}
                                label={<FormattedMessage id={"feed.form.label.recurrenceUnit"}/>}
                                selected={
                                    this.props.recurrenceUnit ?
                                        {"label": <FormattedMessage id={"feed.form.label.recurrenceUnit." + this.props.recurrenceUnit.label}/>, "value": this.props.recurrenceUnit.value} :
                                        {"label":<FormattedMessage id={"feed.form.label.recurrenceUnit.min"}/>, "value":"min"}
                                }
                                required
                            />
                        </div>
                        }
                        <div>
                            <Field
                                component={this.renderField}
                                label={<FormattedMessage id={"feed.form.label.skipRule"} />}
                                name={"skipRule"}
                                type={"text"}
                            />
                        </div>
                    </GridItem>
                </GridContainer>
                <ActionsContainer>
                    <CancelButton retailerId={this.props.retailer && this.props.retailer['@id']} />
                    <SubmitButton loading={this.props.createLoading || this.props.updateLoading} />
                </ActionsContainer>
            </form>
        );
    }
}

const mapStateToProps = state => {
    return {
        createLoading:  state.feed.create.loading,
        createError: state.feed.create.error,
        updateLoading: state.feed.update.updateLoading,
        fields: state.form.csvRemoteFeed,
        retailer: state.retailer.show.retrieved
    };
};

export default reduxForm({
    form: "csvRemoteFeed",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
}, mapStateToProps)(connect(
    mapStateToProps,
    null
)(withStyles(formStyle)(Form)));
