import { container, defaultFont, primaryColor } from "../../main";

const listStyle = theme => ({
    containerWithSidebar: {
        [theme.breakpoints.up("md")]: {
            marginLeft: "240px",
        }
    },
    container: {
        ...container,
        ...defaultFont
    },
    mainActionsContainer: {
        margin: "8px 0px",
        textAlign: "right"
    },
    card: {
        height: window.innerHeight * 0.94,
        background: "transparent",
        boxShadow: "none"
    },
    spacer: {
        width: "100%",
        padding: "10px"
    },
    subTableContainer: {
        display: "flex",
        width: "100%",
        justifyContent: "flex-end",
        alignItems: "center",
        paddingTop: "10px",
    },
    typography: {
        padding: theme.spacing(2),
        cursor: "pointer",
        "&:hover": {
            background: primaryColor,
            color: "white",
        },
    },
    tableWrapper: {
        marginTop: '20px',
    }
});

export default listStyle;
