import { fetch } from '../../utils/dataAccess';
import { uploadSuccess } from './upload';

export function error(error) {
    return { type: 'FEED_CREATE_ERROR', error };
}

export function loading(loading) {
    return { type: 'FEED_CREATE_LOADING', loading };
}

export function success(created) {
    return { type: 'FEED_CREATE_SUCCESS', created };
}

export function create(values) {
    return dispatch => {
        dispatch(loading(true));
        dispatch(error(null));

        let url = "/feeds";

        return fetch(url, { method: 'POST', body: JSON.stringify(values) })
            .then(response => {
                dispatch(loading(false));

                return response.json();
            })
            .then(retrieved => dispatch(success(retrieved)))
            .catch(e => {
                dispatch(loading(false));
                dispatch(error(e));
            });
    };
}

export function reset() {
    return dispatch => {
        dispatch(loading(false));
        dispatch(error(null));
        dispatch(uploadSuccess(null));
    };
}