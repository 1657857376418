import React, {useContext} from 'react'
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import {BeatLoader} from "react-spinners";

import {makeStyles} from "@mui/styles";

import Tooltip from "@mui/material/Tooltip";

import {CheckRounded, CloseRounded} from "@mui/icons-material";

import FabButton from "components/button/FabButton";
import ReturnButton from "components/button/returnButton";
import RunnerAssignButton from "./RunnerAssignButton";

import {primaryColor} from "assets/jss/main";

import {OrderContext} from "contexts/OrderContext";

import {Authorizations, hasAuthorization} from "utils/authorizations";
import {LineItemTransitions} from "utils/lineItemTransitions";
import {LineItemStatus} from "utils/LineItemStatus";

const useStyle = makeStyles({
    actionsCell: {
        display: "flex",
        justifyContent: "center",
        gap: '24px',
    },
    actionButton: {
        color: "#FFFFFF",
        fontSize: "24px",
    },
})

export default function LineItemActions({item}) {
    const classes = useStyle();

    const {
        currentOrganization,
        authorizations,
        member,
        updateLoadingLineItem,
        updateLoadingOrder,
        openCancellationDialogByTransitionAndItem,
        handleReserve,
        handleConfirm,
        handleConfirmReturnRunnerToRetailer,
        handleConfirmReturn,
    } = useContext(OrderContext);


    const haveRunner = !!item['runner'];
    const runnerIsTheUser = haveRunner && (`/members/${member["id"]}` === item['runner']['@id']);

    const statusIsPending = item && item["status"] === LineItemStatus.PENDING;
    const statusIsReserved = item && item["status"] === LineItemStatus.RESERVED;
    const statusIsToShip = item && item["status"] === LineItemStatus.TO_SHIP;
    const statusIsConfirmed = item && item["status"] === LineItemStatus.CONFIRMED;
    const statusIsReturningRunner = item && item["status"] === LineItemStatus.RETURNING_RUNNER;
    const statusIsReturningRetailer = item && item["status"] === LineItemStatus.RETURNING_RETAILER;

    const isRetailer = hasAuthorization(authorizations, Authorizations.PICKING_RETAILER);
    const isRunner = hasAuthorization(authorizations, Authorizations.PICKING_RUNNER)
    const isManager = hasAuthorization(authorizations, Authorizations.PICKING_MANAGER);
    const isReturnAdmin = hasAuthorization(authorizations, Authorizations.PICKING_RETURN_ADMIN);
    const canReturn = hasAuthorization(authorizations, Authorizations.PICKING_RETURN_MANAGEMENT);
    const canCancel = hasAuthorization(authorizations, Authorizations.PICKING_RETAILER_CANCEL);
    const canRefund = hasAuthorization(authorizations, Authorizations.PICKING_RETAILER_REFUND);
    const isPickingRetailerCC = hasAuthorization(authorizations, Authorizations.PICKING_RETAILER_C_C);
    const isPickingRetailerDrive = hasAuthorization(authorizations, Authorizations.PICKING_RETAILER_DRIVE);
    const isOrganizationRetailersReturnEnabled = currentOrganization?.retailersReturnEnabled
    const isClickCollectMethod = "click-collect" === item.shippingMethod;

    if (!runnerIsTheUser && haveRunner && statusIsPending && !isRetailer) {
        return null
    }

    if (updateLoadingOrder) {
        return null
    }

    if (hasAuthorization(authorizations, Authorizations.PICKING_READ_ONLY)) {
        return null;
    }

    if (updateLoadingLineItem) {
        return <BeatLoader
            sizeUnit={"px"}
            size={4}
            color={primaryColor}
            loading={true}
        />
    }

    if (statusIsPending || statusIsReserved || statusIsConfirmed || statusIsReturningRunner || statusIsReturningRetailer || statusIsToShip) {
        let primaryFunction = null;
        let primaryTooltipMessageId = null;
        let secondaryFunction = null;
        let secondaryTooltipMessageId = null;

        if (statusIsPending) {
            if (
                isRetailer
                || (isClickCollectMethod && (isPickingRetailerCC || isPickingRetailerDrive))
                || isManager
                || (haveRunner && runnerIsTheUser)
            ) {
                primaryFunction = () => handleReserve(item);
                primaryTooltipMessageId = "picking.order.show.table.action.product.reserve";
            }
            if (isManager || (haveRunner && runnerIsTheUser) || canCancel) {
                secondaryFunction = () => {
                    openCancellationDialogByTransitionAndItem(item, [
                        LineItemTransitions.CANCEL, LineItemTransitions.UNAVAILABLE
                    ]);
                }
                secondaryTooltipMessageId = "picking.order.show.table.action.product.cancel";
            } else if (isRetailer) {
                secondaryFunction = () => {
                    openCancellationDialogByTransitionAndItem(item, [
                        LineItemTransitions.UNAVAILABLE,
                    ])
                }
                secondaryTooltipMessageId = "picking.order.show.table.action.product.cancel"
            }
        } else if (statusIsReserved && (runnerIsTheUser || canCancel)) {
            primaryFunction = () => handleConfirm(item);
            primaryTooltipMessageId = "picking.order.show.table.action.product.confirm";
            secondaryFunction = () => {
                openCancellationDialogByTransitionAndItem(item, [
                    LineItemTransitions.CANCEL, LineItemTransitions.UNAVAILABLE
                ]);
            }
            secondaryTooltipMessageId = "picking.order.show.table.action.product.cancel";
        } else if (statusIsConfirmed && (!isRetailer || canRefund)) {
            secondaryFunction = () => {
                openCancellationDialogByTransitionAndItem(item, [
                    LineItemTransitions.CANCEL
                ]);
            }
            secondaryTooltipMessageId = "picking.order.show.table.action.product.refund.return";
        } else if (statusIsPending && !runnerIsTheUser) {
            return <RunnerAssignButton lineItem={item} memberId={member['id']} />
        } else if (statusIsToShip && (runnerIsTheUser || canCancel) ){
            secondaryFunction = () => {
                openCancellationDialogByTransitionAndItem(item, [
                    LineItemTransitions.CANCEL
                ]);
            }
            secondaryTooltipMessageId = "picking.order.show.table.action.product.cancel";
        } else if (process.env.REACT_APP_RETURN_ENABLED
            && statusIsReturningRunner && ((canReturn && !isRetailer && (isManager || haveRunner)) || isReturnAdmin)
        ) {
            if (isOrganizationRetailersReturnEnabled) {
                primaryFunction = () => handleConfirmReturnRunnerToRetailer(item);
                primaryTooltipMessageId = "picking.order.show.table.action.product.return.confirm";
            } else {
                primaryFunction = () => handleConfirmReturn(item)
                primaryTooltipMessageId = "picking.order.show.table.action.product.return.confirm";
            }
            secondaryFunction = () => {
                openCancellationDialogByTransitionAndItem(item, [
                    LineItemTransitions.CANCEL_RETURNING_RUNNER,
                ]);
            }
            secondaryTooltipMessageId = "picking.order.show.table.action.product.return.cancel";
        } else if (process.env.REACT_APP_RETURN_ENABLED
            && statusIsReturningRetailer && ((!isRunner && !isManager && canReturn && isRetailer) || isReturnAdmin)
        ) {
            primaryFunction = () => handleConfirmReturn(item)
            primaryTooltipMessageId = "picking.order.show.table.action.product.return.retailer.confirm";
            secondaryFunction = () => {
                openCancellationDialogByTransitionAndItem(item, [
                    LineItemTransitions.CANCEL_RETURNING_RETAILER,
                ]);
            }
            secondaryTooltipMessageId = "picking.order.show.table.action.product.return.retailer.cancel";
        }

        return (
            <div className={classes.actionsCell}>
                {primaryFunction !== null && (
                    <FabButton
                        color="primary"
                        size={'small'}
                        onClick={primaryFunction}
                    >
                        <Tooltip title={<FormattedMessage id={primaryTooltipMessageId} />}>
                            <CheckRounded classes={{root: classes.actionButton}} />
                        </Tooltip>
                    </FabButton>
                )}
                {secondaryFunction !== null && (
                    <FabButton
                        color="secondary"
                        size={'small'}
                        onClick={secondaryFunction}
                    >
                        <Tooltip title={<FormattedMessage id={secondaryTooltipMessageId}/>}>
                            <CloseRounded classes={{root: classes.actionButton}} />
                        </Tooltip>
                    </FabButton>
                )}
                {statusIsConfirmed && ((canReturn && !isRetailer && (isManager || haveRunner)) || isReturnAdmin) && (
                    <ReturnButton
                        onClick={() => openCancellationDialogByTransitionAndItem(item, [
                            LineItemTransitions.RETURN_RUNNER,
                        ])}
                    />
                )}
            </div>
        )
    }

    return null;
}

LineItemActions.defaultProps = {
    item: {}
}

LineItemActions.propTypes = {
    item: PropTypes.object.isRequired
}
