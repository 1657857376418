import React, { useContext, useEffect, useLayoutEffect } from "react";
import connect from "react-redux/es/connect/connect";

// @mui/material components
import {withStyles} from "@mui/styles";

// core components
import AverageProcessTime from "views/marketplace/dashboard/components/AverageProcessTime";
import CancelledItemsTable from "./components/cancelledItemsTable";
import CardStatsOpe from "./components/cardStatsOpe";
import CategoryProductTable from "./components/categoryProductTable";
import DashboardDateRangePicker from "components/dashboardDateRangePicker";
import FormattedNumberWithCurrency from "components/formattedNumberWithCurrencies/formattedNumberWithCurrency";
import GreetMessage from "views/marketplace/dashboard/components/greetMessage";
import GridContainer from "components/grid/gridContainer";
import GridItem from "components/grid/gridItem";
import OpeValidProductsTable from "./components/opeValidProductsTable";
import ReturnedItemsTable from "./components/returnedItemsTable";

// styles
import dashboardStyle from "assets/jss/views/dashboard/dashboardStyle.js";

// actions
import { retrieveOpeData, reset } from "actions/dashboard";

// helpers
import abort from "utils/abort";

// contexts
import {DashboardDateRangePickerContext} from "contexts/dasboardDateRangePickerContext";

function Dashboard(props) {
    const {classes, currentOrganization, dashboardOpeData, dashboardOpeDataLoading, member } = props;

    const {startDate, endDate, unit, dateChangedTrigger } = useContext(DashboardDateRangePickerContext)

    useEffect(() => {
        props.retrieveOpeData(
            startDate.format("Y-M-D"),
            endDate.format("Y-M-D"),
            unit
        );
    }, [dateChangedTrigger]);

    // componentWillUnmount
    useLayoutEffect(() => {
        return () => {
            abort.abortRequests();
            props.reset();
        }
    }, [])

    return (
        <div className={classes.containerWithSidebar}>
            <div className={classes.container}>
                <div className={classes.dashboardHeader}>
                    <GreetMessage name={member['givenName']} />
                    <div>
                        <DashboardDateRangePicker />
                    </div>
                </div>
                <GridContainer>
                    <GridItem xs={12} sm={3} className={classes.cardContainer}>
                        <CardStatsOpe
                            loading={dashboardOpeDataLoading}
                            title={"dashboard.ope.average.daily.order.amount"}
                        >
                            <FormattedNumberWithCurrency
                                value={dashboardOpeData?.averageDailyOrderAmount}
                                currency={currentOrganization?.currency}
                            />
                        </CardStatsOpe>
                    </GridItem>
                    <GridItem xs={12} sm={3} className={classes.cardContainer}>
                        <CardStatsOpe
                            loading={dashboardOpeDataLoading}
                            title={"dashboard.ope.average.daily.order"}
                        >
                            {dashboardOpeData?.averageOrders}
                        </CardStatsOpe>
                    </GridItem>
                    <GridItem xs={12} sm={3} className={classes.cardContainer}>
                        <CardStatsOpe
                            loading={dashboardOpeDataLoading}
                            title={"dashboard.ope.amount.canceled"}
                        >
                            <FormattedNumberWithCurrency
                                value={dashboardOpeData?.amountCanceledOrder}
                                currency={currentOrganization?.currency}
                            />
                        </CardStatsOpe>
                    </GridItem>
                    <GridItem xs={12} sm={3} className={classes.cardContainer}>
                        <CardStatsOpe
                            loading={dashboardOpeDataLoading}
                            title={"dashboard.ope.orders.canceled"}
                        >
                            {dashboardOpeData?.numberOfCanceledOrder}
                        </CardStatsOpe>
                    </GridItem>
                    <GridItem xs={12} sm={3} className={classes.cardContainer}>
                        <CardStatsOpe
                            loading={dashboardOpeDataLoading}
                            title={"dashboard.ope.average.order.time"}
                        >
                            <AverageProcessTime seconds={dashboardOpeData?.averageOrderTime} />
                        </CardStatsOpe>
                    </GridItem>
                    <GridItem xs={12} sm={3} className={classes.cardContainer}>
                        <CardStatsOpe
                            loading={dashboardOpeDataLoading}
                            title={"dashboard.ope.valid.products"}
                        >
                            { dashboardOpeData?.numberOfValidProducts}
                        </CardStatsOpe>
                    </GridItem>
                    <GridItem xs={12} sm={3} className={classes.cardContainer}>
                        <CardStatsOpe
                            loading={dashboardOpeDataLoading}
                            title={"dashboard.ope.invalid.products"}
                        >
                            {dashboardOpeData?.numberOfInvalidProducts}
                        </CardStatsOpe>
                    </GridItem>
                    <GridItem xs={12} sm={3} className={classes.cardContainer}>
                        <CardStatsOpe
                            loading={dashboardOpeDataLoading}
                            title={"dashboard.ope.retailers"}
                        >
                            {dashboardOpeData?.numberOfRetailers}
                        </CardStatsOpe>
                    </GridItem>
                    <GridItem xs={12} sm={12} className={classes.cardContainer}>
                        <CancelledItemsTable
                            currency={currentOrganization?.currency}
                            data={dashboardOpeData?.dashboardCancelledData}
                            loading={dashboardOpeDataLoading}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} className={classes.cardContainer}>
                        <ReturnedItemsTable
                            currency={currentOrganization?.currency}
                            data={dashboardOpeData?.dashboardReturnData}
                            loading={dashboardOpeDataLoading}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} className={classes.cardContainer}>
                        <CategoryProductTable
                            data={dashboardOpeData?.productCountByCategory}
                            loading={dashboardOpeDataLoading}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} className={classes.cardContainer}>
                        <OpeValidProductsTable
                            data={dashboardOpeData?.productValidCount}
                            loading={dashboardOpeDataLoading}
                        />
                    </GridItem>
                </GridContainer>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        member: state.authentication.member,
        dashboardOpeDataLoading: state.dashboard.retrieveOpeDataLoading,
        dashboardOpeData: state.dashboard.retrievedOpeData,
        currentOrganization: state.currentOrganization.retrieved ?? null,
    };
};

const mapDispatchToProps = dispatch => ({
    retrieveOpeData: (startDate, endDate, unit) => dispatch(retrieveOpeData(startDate, endDate, unit)),
    reset: () => dispatch(reset()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(dashboardStyle)(Dashboard));
