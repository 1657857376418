import React, {Component} from "react";
import connect from "react-redux/es/connect/connect";
import {FormattedMessage, injectIntl} from "react-intl";
import {ITEM_PER_PAGE} from "config/_pagination";
import {PropagateLoader} from 'react-spinners';

// @mui/material components
import {
    Avatar,
    Checkbox,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Tooltip,
    Switch
} from "@mui/material";

// @mui/icons-material
import { MoreVert } from "@mui/icons-material";

// styles
import membersStyle from "assets/jss/views/member/memberStyle";
import {primaryColor} from 'assets/jss/main'

// core components
import TableHead from "./components/tableHead";
import PaginationContainer from "../../components/pagination/paginationContainer";
import DeleteDialog from "./components/deleteDialog";
import ActionsMenu from "./components/actionsMenu";
import ItemsPerPageSelect from "components/select/itemsPerPageSelect";
import Search from './components/search';

// actions
import {list, reset} from "actions/member/list";
import {update} from "actions/member/update";

// helpers
import {capitalize} from "utils/capitalize";
import abort from "utils/abort";
import {withStyles} from "@mui/styles";
import {encodePercentageCharacter} from '../../utils/encodePercentageCharacter'

class AllMembersList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selected: [],
            page: 1,
            itemsPerPage: this.props.itemsPerPage !== null ? this.props.itemsPerPage : ITEM_PER_PAGE,
            actionMenuPosition: null,
            showDeleteDialog: false,
            selectedMember: null,
        };
    };

    UNSAFE_componentWillMount() {
        this.props.history.push(`/members/${encodeURIComponent(`user[exists]=true&invitationToken[exists]=false&invitationEmail[exists]=false&itemsPerPage=${this.state.itemsPerPage}&pagination=true&page=1`)}`);
        this.props.reset()
    }

    componentDidMount() {
        this.props.list(`user[exists]=true&invitationToken[exists]=false&invitationEmail[exists]=false&itemsPerPage=${this.state.itemsPerPage}&pagination=true&page=1`)
    }

    componentWillUnmount() {
        abort.abortRequests();
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        if ((this.props.updated !== nextProps.updated) || (this.props.deleted !== nextProps.deleted) ) {
            this.props.list(new URLSearchParams(decodeURIComponent(encodePercentageCharacter(nextProps.match.params.params)).toString()))
        }

        if (this.props.match.params.params !== nextProps.match.params.params) {
            this.props.list(new URLSearchParams(decodeURIComponent(encodePercentageCharacter(nextProps.match.params.params)).toString()))
        }

        if (nextProps.itemsPerPage !== null && this.state.itemsPerPage !== nextProps.itemsPerPage) {
            this.state.itemsPerPage = nextProps.itemsPerPage;
        }
    };

    handleCheckMember = (e, item) => {
        e.stopPropagation();

        const {selected} = this.state;

        if (this.isCurrentUser(item['@id'])) return false;

        const isItemAlreadyAdded = selected.some(itemAdded => itemAdded['@id'] === item['@id']);

        this.setState({
            selected: !isItemAlreadyAdded ? [...selected, item] : selected.filter((d) => !(d['@id'] === item['@id']))
        });
    };

    setSelectedRows(newDataArray) {
        this.setState({
            selected: newDataArray,
        });
    }

    handleSelectAllClick = (event) => {
        const {retrieved} = this.props;

        if (event.target.checked) {
            let newDataArray = JSON.parse(JSON.stringify(retrieved["hydra:member"]));

            newDataArray = newDataArray.filter((member) => !this.isCurrentUser(member['@id']));

            this.setSelectedRows(newDataArray)
        } else {
            this.setState({selected: []});
        }
    };

    isSelected = item => this.state.selected.some(selectedItem => selectedItem['@id'] === item['@id']);

    isCurrentUser = (userId) => {
        const id = userId.replace('/members/', '');

        return id === this.props.member.id;
    };

    updateMemberState = (value) => {
        const {selected} = this.state;

        selected.forEach((item, index) => {
            const isCurrentUser = this.isCurrentUser(item['@id']);

            if (!isCurrentUser && index !== selected.length) {
                this.props.update(item, {state: value})
            }

            if (!isCurrentUser && index + 1 === selected.length) {
                this.props.update(item, {state: value})
                    .then(() => {
                        this.props.list()
                            .then(() => {
                                let newDataArray = selected.map((member) => {
                                    member.state = member.state === "disabled" ? "enabled" : "disabled";

                                    return member
                                });

                                this.setSelectedRows(newDataArray)
                            })
                    })
            }

            if (isCurrentUser && index + 1 === selected.length) {
                this.props.list()
                    .then(() => {
                        let newDataArray = selected.map((member) => {
                            member.state = member.state === "disabled" ? "enabled" : "disabled";

                            return member
                        });

                        this.setSelectedRows(newDataArray)
                    })
            }
        })
    };

    renderRoles(roles) {
        if (roles.length > 0){
            if (roles.length >= 2) {
                return (
                    <Tooltip
                        title={roles.map((role, index) => `${capitalize(role['name'])}${index + 1 === roles.length ? "" : ", "}`)}
                    >
                        <span>{`${capitalize(roles[0]['name'])} ...`}</span>
                    </Tooltip>
                )
            } else if (roles.length < 2) return capitalize(roles[0]['name'])
        }
    }

    update(member) {
        let state = member.state === 'enabled' ? 'disabled' : 'enabled';

        this.props.update(member, {state})
    }

    renderRetailerName(retailers) {
        return retailers.map((retailer, key) => {
            return (
                <div key={key}>
                    {capitalize(retailer.name)}
                    {key + 1 !== retailers.length && ', '}
                </div>
            )
        })
    }

    showActionMenu(member, event) {
        this.setState({
            selectedMember: member,
            actionMenuPosition: event.currentTarget
        })
    }

    closeActionMenu() {
        this.setState({ actionMenuPosition: null })
    }

    showDeleteDialog() {
        this.closeActionMenu()
        this.setState({ showDeleteDialog: true })
    }

    closeDeleteDialog() {
        this.setState({ showDeleteDialog: false })
    }

    getNumberCurrentUser() {
        const {retrieved} = this.props;

        return retrieved['hydra:member']
            .filter(member => this.isCurrentUser(member['@id']))
            .length;
    }

    render() {
        const {classes, retrieved, intl, listLoading, updateLoading} = this.props;
        const {selected} = this.state;

        let disabledStates = selected.filter(function (member) {
            return member.state === "disabled";
        });

        const tableHead = [
            {
                id: "image",
                label: ""
            },
            {
                id: "member",
                label: intl.formatMessage({id: "member.allMember.list.table.header.email"})
            },
            {
                id: "name",
                label: intl.formatMessage({id: "member.allMember.list.table.header.name"})
            },
            {
                id: "magasin",
                label: intl.formatMessage({id: "member.allMember.list.table.header.magasin"})
            },
            {
                id: "role",
                label: intl.formatMessage({id: "member.allMember.list.table.header.role"})
            }
        ];

        const showGlobalUpdateStateButton = () => {
            let selectedData = selected;
            const disabled = selectedData.filter((item) => item.state === "disabled").length;
            const enabled = selectedData.filter((item) => item.state === "enabled").length;

            if (!disabled && !enabled) return false;

            return !(enabled && disabled)
        };

        return (
            <div>
                <div className={classes.subTableContainer}>
                    <Search params={this.props.match.params.params} history={this.props.history}/>
                    <ItemsPerPageSelect history={this.props.history} match={this.props.match} />
                </div>
                <div className={classes.tableWrapper}>
                    {!(retrieved && retrieved["hydra:member"]) &&
                        <div className={classes.propagateLoader}>
                            <PropagateLoader
                                sizeUnit={"px"}
                                size={16}
                                color={primaryColor}
                                loading={listLoading}
                            />
                        </div>
                    }
                    <Table padding="none" className={classes.table}>
                        <TableHead
                            checked={
                                retrieved && retrieved['hydra:totalItems'] > 1 && (
                                    retrieved['hydra:member'].length - this.getNumberCurrentUser() === this.state.selected.length
                                )
                            }
                            onSelectAllClick={this.handleSelectAllClick}
                            tableHead={tableHead}
                            selected={selected}
                            showGlobalUpdateStateButton={showGlobalUpdateStateButton()}
                            updateMemberState={this.updateMemberState}
                            disabledStates={disabledStates}
                            loading={updateLoading}
                        />
                        <TableBody className={classes.tableBody}>
                            {(retrieved && retrieved["hydra:member"].length > 0) &&
                            retrieved["hydra:member"].map(member => {
                                const isSelected = this.isSelected(member);
                                const isCurrentUser = this.isCurrentUser(member['@id']);

                                if (!member["user"]) return null;

                                return (
                                    <TableRow
                                        hover
                                        className={`${member.state === 'enabled' ? classes.enabledTableRow : classes.disabledTableRow} ${isSelected ? classes.selectedTableRow : null}`}
                                        key={member['@id']}
                                        tabIndex={-1}
                                    >
                                        <TableCell className={classes.leftTableCell}>
                                            {!isCurrentUser && (
                                                <Checkbox
                                                    checked={!!isSelected}
                                                    onChange={(e) => this.handleCheckMember(e, member)}
                                                    classes={{
                                                        root: classes.checkbox,
                                                        checked: classes.checked,
                                                    }}
                                                />
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            <div className={classes.logoContainer}>
                                                {member["user"] && member["user"]["picture"] ?
                                                    <Avatar
                                                        src={member["user"]["picture"]}
                                                        className={member.state === 'enabled' ? classes.avatarEnabled : classes.avatarDisabled}
                                                    />
                                                    :
                                                    <Avatar className={member.state === 'enabled' ? classes.avatarTextEnabled : classes.avatarTextDisabled}>
                                                        {member["user"]['givenName'] && member["user"]['givenName'].charAt(0)}
                                                        {member["user"]['familyName'] && member["user"]['familyName'].charAt(0)}
                                                    </Avatar>
                                                }
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            {member.user.email}
                                        </TableCell>
                                        <TableCell>
                                            {capitalize(member.user.fullName)}
                                        </TableCell>
                                        <TableCell>
                                            {member.retailers.length > 0 &&
                                                this.renderRetailerName(member.retailers)
                                            }
                                        </TableCell>
                                        <TableCell>
                                           {this.renderRoles(member.roles)}
                                        </TableCell>
                                        <TableCell className={classes.rightTableCell}>
                                            {!isCurrentUser && (
                                                <Tooltip
                                                    title={<FormattedMessage id={`member.tab.all.${member.state === 'enabled' ? 'disabled' : 'enabled'}.button`}/>}
                                                    onClick={(e) => e.stopPropagation()}
                                                >
                                                    <Switch
                                                        checked={member.state === 'enabled'}
                                                        onChange={() => this.update(member)}
                                                        color="primary"
                                                    />
                                                </Tooltip>
                                            )}
                                        </TableCell>
                                        <TableCell className={classes.rightTableCell}>
                                            {!isCurrentUser && (
                                                <IconButton
                                                    className={classes.icon}
                                                    size={"small"}
                                                    onClick={(e) => this.showActionMenu(member, e)}
                                                >
                                                    <MoreVert />
                                                </IconButton>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                            {retrieved && retrieved["hydra:member"].length === 0 &&
                            <TableRow>
                                <TableCell colSpan={9} className={classes.tableCellNoResult}>
                                    <FormattedMessage id={"member.tab.all.table.no.result"}/>
                                </TableCell>
                            </TableRow>
                            }
                        </TableBody>
                    </Table>
                    {this.state.selectedMember !== null &&
                        <>
                            <ActionsMenu
                                selectedMember={this.state.selectedMember}
                                position={this.state.actionMenuPosition}
                                handleClose={() => this.closeActionMenu()}
                                openEditDialog={(selectedMember) => this.props.openMemberFormDialog(selectedMember)}
                                openDeleteDialog={() => this.showDeleteDialog()}
                            />
                            <DeleteDialog
                                selectedMember={this.state.selectedMember}
                                isOpen={this.state.showDeleteDialog}
                                closeDeleteDialog={() => this.closeDeleteDialog()}
                            />
                        </>
                    }
                </div>
                <div className={classes.paginationContainer}>
                    <PaginationContainer
                        itemsPerPage={this.state.itemsPerPage}
                        urlParams={this.props.match.params.params}
                        retrieved={this.props.retrieved}
                    />
                </div>
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        retrieved: state.member.list.retrieved,
        listLoading: state.member.list.loading,
        itemsPerPage: state.itemsPerPage.itemsPerPage,
        member: state.authentication.member,
        updated: state.member.update.updated,
        updateLoading: state.member.update.loading,
        deleted: state.member.del.deleted,
    };
};

const mapDispatchToProps = dispatch => ({
    update: (item, value) => dispatch(update(item, value)),
    list: (params) => dispatch(list(params)),
    reset: () => dispatch(reset())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(membersStyle)(injectIntl(AllMembersList)));
