import {infoColor} from "../../main";

const ItemsPerPageSelectStyle = theme => ({
    customMenuItem: {
        "&:hover": {
            background: infoColor,
            color: "white",
        },
        "&:focus": {
            background: infoColor,
            color: "white",
        }
    },
    customMenuItemSelected: {
        background: `${infoColor} !important`,
        color: "white !important",
    },
    customSelect: {
        borderRadius: "10px",
        height: "38px",
        marginTop: "8px",
        textAlign: "left"
    },
})

export default ItemsPerPageSelectStyle;
