import React from "react";
import {makeStyles} from "@mui/styles";

import Card from "components/card/card";

const useStyles = makeStyles(theme => ({
    cardStats: {
        marginTop: "0px",
        textAlign: "center"
    },
}));

export default function CardStats({...props}) {
    const classes = useStyles();

    const { children } = props;

    return (
        <Card className={classes.cardStats}>
            {children}
        </Card>
    )
}
