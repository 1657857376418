import {ListItem, ListItemIcon, ListItemText} from "@mui/material";
import {GetApp} from "@mui/icons-material";
import {FormattedMessage} from "react-intl";
import React, {Component} from "react";

class DrawerPwa extends Component {
    constructor(props) {
        super(props);
        this.state = {
            active: false
        };
    }
    componentDidMount() {
        this.verifyIfInstallPromptIsReady();
    }

    verifyIfInstallPromptIsReady = () => {
        let isActive = !!window.installWishibamApplicationPrompt;

        if (!isActive) {
            this.checkForPrompt = setTimeout(this.verifyIfInstallPromptIsReady, 1000);
        } else {
            this.setState({active: true});
        }
    }

    componentWillUnmount() {
        if (this.checkForPrompt) {
            clearTimeout(this.checkForPrompt);
        }
    }
    installApp = () => {
        if (window.installWishibamApplicationPrompt) {
            window.installWishibamApplicationPrompt.prompt();
            window.installWishibamApplicationPrompt.userChoice.then((choiceResult) => {
                if (this.checkForPrompt) {
                    clearTimeout(this.checkForPrompt);
                }
                if (choiceResult.outcome === 'accepted') {
                    window.installWishibamApplicationPrompt = null;
                    this.setState({active: false});
                } else {
                    // User did not accept the installation of the app.
                }
            });
        }
    }

    render() {
        if (!this.state.active) {
            return null;
        }
        const classes = this.props.classes;

        return (
            <div>
                <ListItem
                    button
                    className={classes.listItem}
                    onClick={this.installApp}
                    classes={{selected: classes.selected}}
                    key={"pwa"}
                    selected={false}
                >
                    <ListItemIcon>
                        <GetApp color={"secondary"}/>
                    </ListItemIcon>
                    <ListItemText
                        primaryTypographyProps={{color:"secondary"}}
                        primary={<FormattedMessage id={"sidebar.item.pwa"}/>}
                    />
                </ListItem>
            </div>
        )
    }
}

export default DrawerPwa;
