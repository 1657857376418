import {renderS3Url} from "./renderS3Url";

export function getFirstProductImage(pictures) {
    let defaultImage = "https://via.placeholder.com/60.jpg";

    if (pictures.length === 0) {
        return defaultImage;
    }

    const firstPicture = pictures[0];
    let url;
    if (firstPicture.hasOwnProperty('mediaObject')) {
        url = firstPicture.mediaObject.contentUrl;
    } else if (firstPicture.hasOwnProperty('contentUrl')) {
        url = firstPicture.contentUrl;
    } else if (typeof firstPicture === 'string') {
        url = firstPicture;
    } else {
        throw new Error('Impossible to handle this picture of type ' + (typeof firstPicture));
    }

    return url.substring(0, 4) !== "http" ? renderS3Url(this.props.organizations, this.props.member, "product", url) : url
}
