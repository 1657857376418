import React, { useEffect } from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";

// @mui/material components
import { Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText } from "@mui/material";

// core components
import Button from "components/button/button";
import Loader from '../../configuration/components/loader';

// actions
import { deleteSelection } from "actions/sector/deleteSelection";

function GlobalDeleteDialog(props) {
    const { sectors, closeDialog, deleted, loading, isOpen } = props;

    useEffect(() => {
        if (deleted) {
            closeDialog();
        }
    }, [deleted]);

    return (
        <Dialog
            open={isOpen}
            onClose={closeDialog}
            maxWidth={'xs'}
            scroll={'body'}
        >
            <DialogTitle>
                <FormattedMessage id={"sector.global.delete.title"} />
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <FormattedMessage id={"sector.global.delete.text"} />
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button color={"info"} simple onClick={closeDialog} disabled={loading}>
                    <FormattedMessage id={"sector.delete.cancel"} />
                </Button>
                <Button
                    round
                    color={"danger"}
                    type="submit"
                    disabled={loading}
                    onClick={() => props.deleteSelection(sectors)}
                >
                    <FormattedMessage id={"sector.delete.submit"} />
                    {loading && <Loader />}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

const mapStateToProps = state => {
    return {
        loading: state.sector.deleteSelection.loading,
        deleted: state.sector.deleteSelection.deleted,
    }
};

const mapDispatchToProps = dispatch => ({
    deleteSelection: (sectors) => dispatch(deleteSelection(sectors)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GlobalDeleteDialog);
