import React, {Component} from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {Doughnut} from "react-chartjs-2";
import {connect} from "react-redux";
import classNames from 'classnames'

// @mui/material components
import {Chip, Grid} from "@mui/material";
import {withStyles} from "@mui/styles";

// styles
import dashboardStyle from "assets/jss/views/picking/dashboard/dashboardStyle";
import GridItem from '../../../components/grid/gridItem'

const LegendItem = (props) => {
    const { statusName, label, value, classes } = props

    return (
        <div className={classes.legendContainer}>
            <GridItem xs={8}>
                <Chip
                    className={classNames(classes.chip, classes[`${statusName}Chip`])}
                    label={<FormattedMessage id={label} />}
                />
            </GridItem>
            <GridItem xs={4} className={classes.productCountValue}>
                {value}
            </GridItem>
        </div>
    )
}

class OrderStatisticBlock extends Component {
    render() {
        const {classes, ordersCount} = this.props;

        const sumOrdersCount = ordersCount && (
            ordersCount.pending +
            ordersCount.canceled +
            ordersCount.confirmed +
            ordersCount.returned +
            ordersCount['to-ship'] +
            ordersCount.reserved +
            ordersCount.unavailable +
            ordersCount.returning
        ).toLocaleString();
        const data = {
            datasets: [{
                data: ordersCount && [
                    ordersCount.confirmed,
                    ordersCount.pending,
                    ordersCount['to-ship'],
                    ordersCount.reserved,
                    ordersCount.canceled,
                    ordersCount.unavailable,
                    ordersCount.returned,
                    ordersCount.returning
                ],
                labels: [
                    this.props.intl.formatMessage({id: "picking.dashboard.order.confirmed"}),
                    this.props.intl.formatMessage({id: "picking.dashboard.order.pending"}),
                    this.props.intl.formatMessage({id: "picking.dashboard.order.toShip"}),
                    this.props.intl.formatMessage({id: "picking.dashboard.order.reserved"}),
                    this.props.intl.formatMessage({id: "picking.dashboard.order.canceled"}),
                    this.props.intl.formatMessage({id: "picking.dashboard.order.unavailable"}),
                    this.props.intl.formatMessage({id: "picking.dashboard.order.return"}),
                    this.props.intl.formatMessage({id: "picking.dashboard.order.returning"}),
                ],
                backgroundColor: [
                    '#78BC61',
                    '#e6c229',
                    '#00acc1',
                    '#976ef5',
                    '#ED6A5A',
                    '#4a4a49',
                    '#FF823C',
                    '#FF8E5E',
                ],
                hoverBackgroundColor: [
                    '#43832E',
                    '#C1A635',
                    '#058898',
                    '#6F4DBF',
                    '#BD2A1F',
                    '#4a4a49',
                    '#FF823C',
                    '#E27947',
                ],
            }]
        };

        return (
            <Grid item className={classes.statisticBlockContainer}>
                <div className={classes.statisticBlockHeader}>
                    <FormattedMessage id={"picking.dashboard.header.order"}/>
                </div>
                <div className={classes.statisticBlockData}>
                    <Doughnut data={data}
                              options={{
                                  cutoutPercentage: 80,
                                  responsive: true,
                                  maintainAspectRatio: false,
                                  legend: {
                                      display: false
                                  },
                                  tooltips: {
                                      callbacks: {
                                          label: function(tooltipItem, data) {
                                              let dataset = data.datasets[tooltipItem.datasetIndex];
                                              let index = tooltipItem.index;
                                              return dataset.labels[index] + ': ' + dataset.data[index];
                                          },
                                      },
                                  }
                              }}
                    />
                    <div className={classes.ordersCountData}>
                        <span className={classes.ordersCountNumber}>{sumOrdersCount}</span>
                        <FormattedMessage className={classes.ordersCountLabel}
                                          id={"picking.dashboard.header.order"}/>
                    </div>
                </div>
                <div className={classes.statisticFooter}>
                    <div className={classes.footerSubBlock}>
                        <LegendItem
                            classes={classes}
                            label={'picking.dashboard.order.pending'}
                            statusName='pending'
                            value={ordersCount && ordersCount.pending.toLocaleString()}
                        />
                        <LegendItem
                            classes={classes}
                            label={'picking.dashboard.order.unavailable'}
                            statusName='unavailable'
                            value={ordersCount && ordersCount.unavailable.toLocaleString()}
                        />
                        <LegendItem
                            classes={classes}
                            label={'picking.dashboard.order.reserved'}
                            statusName='reserved'
                            value={ordersCount && ordersCount.reserved.toLocaleString()}
                        />
                        <LegendItem
                            classes={classes}
                            label={'picking.dashboard.order.confirmed'}
                            statusName='confirmed'
                            value={ordersCount && ordersCount.confirmed.toLocaleString()}
                        />
                    </div>
                    <div className={classes.footerSubBlock}>
                        <LegendItem
                            classes={classes}
                            label={'picking.dashboard.order.toShip'}
                            statusName='toShip'
                            value={ordersCount && ordersCount['to-ship'].toLocaleString()}
                        />
                        {process.env.REACT_APP_RETURN_ENABLED &&
                            <LegendItem
                                classes={classes}
                                label={'picking.dashboard.order.returning'}
                                statusName='returning'
                                value={ordersCount && (
                                    ordersCount.returning
                                ).toLocaleString()}
                            />
                        }
                        <LegendItem
                            classes={classes}
                            label={'picking.dashboard.order.return'}
                            statusName='returned'
                            value={ordersCount && ordersCount.returned.toLocaleString()}
                        />
                        <LegendItem
                            classes={classes}
                            label={'picking.dashboard.order.canceled'}
                            statusName='canceled'
                            value={ordersCount && ordersCount.canceled.toLocaleString()}
                        />
                    </div>
                </div>
            </Grid>
        );
    }
}

export default connect()(withStyles(dashboardStyle)(injectIntl(OrderStatisticBlock)));
