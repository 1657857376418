import {fetch} from "utils/dataAccess";

export function success(retrieved) {
    return {type: "AUTHORIZATIONS_LIST_SUCCESS", retrieved};
}

export function error(error) {
    return {type: "AUTHORIZATIONS_LIST_ERROR", error};
}

export function loading(loading) {
    return {type: "AUTHORIZATIONS_LIST_LOADING", loading};
}

export function list(params = "pagination=false") {
    return dispatch => {
        dispatch(loading(true));

        return fetch(`/authorizations?${params}`).then(response => {
            response.json().then(retrieved => {
                dispatch(loading(false));
                dispatch(success(retrieved));
            })
        }).catch(e => {
            dispatch(loading(false));
            dispatch(error(e.message));
        });
    };
}

export function reset() {
    return dispatch => {
        dispatch({type: "AUTHORIZATIONS_LIST_RESET"});
    };
}