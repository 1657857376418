export const SUPPORTED_LOCALES = {
    'fr_FR': {'code': 'fr_FR', 'name': 'Français (FR)', 'flag': '🇫🇷', 'translated': true},
    'fr_BE': {'code': 'fr_BE', 'name': 'Français (BE)', 'flag': '🇧🇪', 'translated': true},
    'nl_BE': {'code': 'nl_BE', 'name': 'Nederlands (BE)', 'flag': '🇧🇪', 'translated': false},
    'nl_NL': {'code': 'nl_NL', 'name': 'Nederlands (NL)', 'flag': '🇳🇱', 'translated': false},
    'ru_RU': {'code': 'ru_RU', 'name': 'Russian', 'flag': '🇷🇺', 'translated': false},
    'zh_CN': {'code': 'zh_CN', 'name': 'Chinese', 'flag': '🇨🇳', 'translated': false},
    'en_US': {'code': 'en_US', 'name': 'English (US)', 'flag': '🇺🇸', 'translated': true},
    'en_GB': {'code': 'en_GB', 'name': 'English (GB)', 'flag': '🇬🇧', 'translated': true},
    'it_IT': {'code': 'it_IT', 'name': 'Italiano', 'flag': '🇮🇹', 'translated': true},
    'de_DE': {'code': 'de_DE', 'name': 'German', 'flag': '🇩🇪', 'translated': false},
    'fi_FI': {'code': 'fi_FI', 'name': 'Finnish', 'flag': '🇫🇮', 'translated': false}
};

export const SUPPORTED_LOCALES_CODES = Object.keys(SUPPORTED_LOCALES);

export const isTranslatedCode = (code) => {
    for (const [localeCode, localeConfig] of Object.entries(SUPPORTED_LOCALES)) {
        if (localeConfig.translated === false) {
            continue;
        }

        // browser language can be 'en-US' or just 'en'
        if (code === localeCode || code.replace('-', '_') === localeCode || code.split(/[-_]/)[0] === localeCode.split(/[-_]/)[0]) {
            return true;
        }
    }

    return false;
}
