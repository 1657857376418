import React from "react";
import { FormattedMessage } from "react-intl";

// @mui/material components
import { Popover, Typography } from "@mui/material";
import {makeStyles} from "@mui/styles";

// styles
import { primaryColor } from "../../../assets/jss/main";

const useStyles = makeStyles(theme => ({
    typography: {
        padding: theme.spacing(2),
        cursor: "pointer",
        "&:hover": {
            background: primaryColor,
            color: "white",
        },
    },
}));

export default function GlobalPopover(props) {
    const classes = useStyles();
    const { closePopover, isPopoverOpen, openDeleteDialog, position } = props;

    function deleteDialog() {
        openDeleteDialog();
        closePopover();
    }

    return (
        <Popover
            open={isPopoverOpen}
            onClose={closePopover}
            anchorEl={position}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            <Typography className={classes.typography} onClick={deleteDialog}>
                <FormattedMessage id={"roles.table.row.delete"}/>
            </Typography>
        </Popover>
    );
}
