import {fetch} from "../../utils/dataAccess";

 function error(error) {
    return {type: "MEMBER_DELETE_ERROR", error};
}

 function loading(loading) {
    return {type: "MEMBER_DELETE_LOADING", loading};
}

 function success(success) {
    return {type: "MEMBER_DELETE_SUCCESS", success};
}

export function del(item) {
    return dispatch => {
        dispatch(loading(true));

        return fetch(item["@id"], {method: "DELETE"})
            .then(() => {
                dispatch(loading(false));
                dispatch(success(item));
            })
            .catch(e => {
                dispatch(loading(false));
                dispatch(error(e.message));
            });
    };
}

export function reset() {
    return dispatch => {
        dispatch({type: "MEMBER_DELETE_RESET"});
    };
}
