import React, {Component} from "react";
import {injectIntl} from "react-intl";
import PropTypes from 'prop-types';

import {
    Box,
    Collapse,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@mui/material";
import {KeyboardArrowDown, KeyboardArrowUp} from "@mui/icons-material";

import moment from "moment";
import {withStyles} from "@mui/styles";
import listStyle from "../../../assets/jss/views/accounting/listStyle";
import FormattedNumberWithCurrency from "../../../components/formattedNumberWithCurrencies/formattedNumberWithCurrency";

const dateFormat = 'dddd, D MMM YYYY';

class Row extends Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
        };
    }

    toggleOpen () {
        this.setState({
            open: !this.state.open,
        })
    }

    displayStatus(status) {
        const { intl } = this.props;

        switch (status) {
            case 'paid':
                return intl.formatMessage({id: 'accounting.payout_list.status.paid'});
            case 'in_transit':
                return intl.formatMessage({id: 'accounting.payout_list.status.in_transit'});
        }

        return 'unknown';
    }

    get open() { return this.state.open; }

    render () {
        const { row, intl, classes, currentOrganization } = this.props;

        return (
            <React.Fragment>
                <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                    <TableCell>
                        {this.open ? (<KeyboardArrowUp onClick={() => this.toggleOpen()}/>) : (<KeyboardArrowDown onClick={() => this.toggleOpen()}/>)}
                    </TableCell>
                    <TableCell align="left">{moment(row.arrivalDate).format(dateFormat)}</TableCell>
                    <TableCell align="left">{moment(row.creationDate).format(dateFormat)}</TableCell>
                    <TableCell align="right"><FormattedNumberWithCurrency value={row.amount} currency={currentOrganization?.currency} /></TableCell>
                    <TableCell align="right">{this.displayStatus(row.status)}</TableCell>
                    <TableCell align="right">{row.reference}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                        <Collapse in={this.open} timeout="auto" unmountOnExit>
                            <Box sx={{ margin: 1 }}>
                                <h4>{intl.formatMessage({id: "accounting.payout_row.title"})}</h4>
                                <Table size="small" aria-label="purchases">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>{intl.formatMessage({id: "accounting.payout_row.order_id"})}</TableCell>
                                            <TableCell>{intl.formatMessage({id: "accounting.payout_row.type_transfer"})}</TableCell>
                                            <TableCell align="right">{intl.formatMessage({id: "accounting.payout_row.amount_transfer"})}</TableCell>
                                            <TableCell align="right">{intl.formatMessage({id: "accounting.payout_row.amount_fees"})}</TableCell>
                                            <TableCell align="right">{intl.formatMessage({id: "accounting.payout_row.amount_paid_by_customer"})}</TableCell>
                                            <TableCell align="right">{intl.formatMessage({id: "accounting.payout_row.date_transfer"})}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {row.transfers.map((transfer, index) => (
                                            <TableRow key={index}>
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                >
                                                    {!!transfer.orderId ? (
                                                        <span
                                                            className={transfer.orderUuid ? classes.openOrderDetail : ''}
                                                            onClick={() => transfer.orderUuid && window.open('/picking/orders/show/' + transfer.orderUuid, '_blank')}
                                                        >
                                                            {transfer.orderId}
                                                        </span>
                                                    ) : (
                                                        <span>-</span>
                                                    )}
                                                </TableCell>
                                                <TableCell>{transfer.type}</TableCell>
                                                <TableCell align="right"><FormattedNumberWithCurrency value={transfer.amount} currency={currentOrganization?.currency} /></TableCell>
                                                <TableCell align="right"><FormattedNumberWithCurrency value={transfer.commission} currency={currentOrganization?.currency} /></TableCell>
                                                <TableCell align="right"><FormattedNumberWithCurrency value={transfer.amount_paid_by_customer} currency={currentOrganization?.currency} /></TableCell>
                                                <TableCell align="right">{moment(transfer.date).format(dateFormat)}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </React.Fragment>
        );
    }
}

Row.propTypes = {
    row: PropTypes.shape({
        arrivalDate: PropTypes.string.isRequired,
        creationDate: PropTypes.string.isRequired,
        amount: PropTypes.number.isRequired,
        status: PropTypes.string.isRequired,
        reference: PropTypes.string.isRequired,
        transfers: PropTypes.arrayOf(
            PropTypes.shape({
                orderId: PropTypes.string,
                orderUuid: PropTypes.string,
                amount: PropTypes.number.isRequired,
                commission: PropTypes.number.isRequired,
                amount_paid_by_customer: PropTypes.number.isRequired,
                type: PropTypes.string.isRequired,
                date: PropTypes.string.isRequired,
            }),
        ).isRequired,
    }).isRequired,
};

export default withStyles(listStyle)(injectIntl(Row));
