import {
    container,
    defaultFont,
    infoColor,
    grayColor,
    irisBlueColor,
    terraCottaColor,
    mantisGreenColor,
    yellowColor,
    lightGrayColor,
    primaryColor,
    thunderGrayColor,
    successColorDisabled,
} from "../../../main";

const status = (theme) => {
    return {
        padding: "5px 25px 5px 25px",
        borderRadius: "20px",
        textAlign: "center",
        maxHeight: "32px",
        whiteSpace: "nowrap",
        fontWeight: "400",
        fontSize: "16px",
        [theme.breakpoints.down('md')]: {
            padding: "5px 15px 5px 15px",
        },
    };
};

const showStyle = theme => ({
    containerWithSidebar: {
        [theme.breakpoints.up("md")]: {
            marginLeft: "240px",
        }
    },
    container: {
        ...container,
        ...defaultFont
    },
    card: {
        minHeight: window.innerHeight * 0.84
    },
    notesCard: {
        minHeight: window.innerHeight * 0.24
    },
    tableCellRight: {
        textAlign: "right"
    },
    primaryTableHeaderCellLogo: {
        width: '100%',
        textAlign: 'center',
    },
    actionContainer: {
        display: "flex",
        justifyContent: "flex-end"
    },
    cardHeader: {
        padding: "32px 30px 16px 30px !important",
        textTransform: 'none',
    },
    cardOrderHeader: {
        marginBottom: 12,
    },
    orderRunnerDetails: {
        '&:first-child': {
            maxWidth: '90%',
        },
        '&:last-child': {
            maxWidth: '10%',
        },
    },
    priceDetailsContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        marginBottom: '72px',
    },
    notesCardHeader: {
        padding: "1rem 0.9375rem 0.9375rem 0.9375rem !important",
    },
    noteContainer: {
        display: "flex"
    },
    notePaper: {
        padding: "5px",
        marginLeft: "30px",
        marginBottom: "5px",
        marginTop: "5px",
        width: "100%",
        backgroundColor: "transparent"
    },
    notePaperHeader: {
        display: "flex",
        justifyContent: "space-between",
        fontWeight: "bold"
    },
    addNoteContainer: {
        display: "flex",
        justifyContent: "flex-end"
    },
    title: {
        fontWeight: "400 !important",
        marginTop: 0,
    },
    header: {
        marginTop: '30px'
    },
    dateRangePicker: {
        [theme.breakpoints.down('md')]: {
            marginBottom: "5px",
        },
        [theme.breakpoints.up("md")]: {
            maxWidth: "470px",
        },
        "@media (min-width: 1280px) and (max-width: 1920px)": {
            "& .DateRangePicker_picker": {
                left: "1px",
            }
        }
    },
    search: {
        width: "100%",
        float: 'right',
        "& input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button": {
            WebkitAppearance: "none",
            margin: 0
        },

        "& div": {
            height: "38px"
        },

        "& button": {
            padding: "2px"
        }
    },
    footer: {
        padding: "2rem 1.875rem 1.9375rem 1.875rem"
    },
    pagination: {
        textAlign: "left"
    },
    total: {
        textAlign: "right",
        color: grayColor
    },
    customer: {
        textAlign: "right",
        '& b': {
            display: 'flex',
            justifyContent: 'right',
            alignItems: 'center',
            gap: 8,
        },
    },
    editCustomerShipping: {
        cursor: 'pointer',
    },
    orderStatus: {
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-end",
        alignContent: "flex-end",
        justifyContent: "flex-end",
        textAlign: "right",
    },
    orderActions: {
        display: "flex",
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        gap: '24px',
    },
    assign: {
        padding: '8px 24px',
        borderRadius: '32px',
        backgroundColor: infoColor,
        textTransform: 'none',
        boxShadow: 'unset',
        '&:hover': {
            boxShadow: 'unset',
        },
        '&:active': {
            boxShadow: 'unset',
        },
    },
    affectedTo: {
        marginRight: 12,
        fontSize: 16,
        fontWeight: 400,
        letterSpacing: '0.192px',
    },
    runner:{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: '32px',
    },
    runnerList: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    img: {
        width: "40px",
        height: "40px",
        borderRadius: "32px",
        boxSizing: "border-box",
        padding: 0,
    },
    imgPlaceholder: {
        background: '#F4511E',
        color: "#FFFFFF",
        borderRadius: "32px",
        width: "40px",
        height: "40px",
        fontSize: 20,
        fontWeight: 400,
        letterSpacing: '0.24px',
    },
    validateAction: {
        color: "#FFFFFF",
        fontSize: "40px",
    },
    cancelAction: {
        color: "#FFFFFF",
        fontSize: "40px",
    },
    cardBody: {
        padding: "0 0 0 0 !important",
        overflow: "inherit"
    },
    doubleHead: {
        display: "flex",
        flexDirection: "column",
    },
    tableHeaderXs: {
        fontSize: "14px !important"
    },
    toShip: {
        ...status(theme),
        backgroundColor: "rgba(0, 172, 193, 0.2)",
        color: irisBlueColor,
    },
    canceled: {
        ...status(theme),
        backgroundColor: "rgba(237, 106, 90, 0.2)",
        color: terraCottaColor,
    },
    refunded: {
        ...status(theme),
        backgroundColor: "rgba(230, 81, 0, 0.2)",
        color: "#ff833a",
    },
    returned: {
        ...status(theme),
        backgroundColor: "#FDDDD2",
        color: "#FF823C",
    },
    reserved: {
        ...status(theme),
        backgroundColor: "rgba(105, 41, 230, 0.2)",
        color: '#976ef5',
    },
    pending: {
        ...status(theme),
        backgroundColor: "rgba(230, 194, 41, 0.2)",
        color: yellowColor,
    },
    confirmed: {
        ...status(theme),
        backgroundColor: "rgba(93, 209, 103, 0.4)",
        color: mantisGreenColor
    },
    unavailable: {
        ...status(theme),
        backgroundColor: "rgba(74, 74, 73, 0.2)",
        color: thunderGrayColor,
    },
    returning: {
        ...status(theme),
        backgroundColor: '#FFE6C9',
        color: '#FF8E5E'
    },
    lineItemActivityCancellationReason: {
        display: 'block',
    },
    lineItemActivityStatus: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
        padding: "10px 0",

        [theme.breakpoints.down('md')]: {
            flexDirection: "column",
        },
    },
    lineItemActivityStatusChangesBox: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
    },
    lineItemActivityStatusChanges: {
        transform: 'scale(.8)',
    },
    lineItemActivityStatusArrow: {
        margin: "10xp 0",
    },
    cardFooter: {
        display: "flex",
        alignItems: "center",
        backgroundColor: "transparent",
        padding: "0.9375rem 1.875rem",
        justifyContent: "space-between",
        borderTop: `1px solid ${grayColor}`,
        fontSize: "18px"
    },
    cardFooterAdjustments: {
        borderTop: "1px solid #f5f5f5"
    },
    returningBadge : {
        '& .MuiBadge-badge': {
            right: '25px',
            border: '2px solid white',
            borderRadius: '50%',
            width: '20px',
            height: '20px',
            padding: '0',
            top: '5px',
        }
    },
    fakeMoreList: {
        backgroundColor: grayColor,
        height: theme.spacing(1),
        margin: theme.spacing(2),
        // Selects every two elements among any group of siblings.
        '&:nth-child(2n)': {
            marginRight: theme.spacing(3),
        },
    },
    moreListepaper: {
        position: 'absolute',
        top: 52,
        right: 14,
        width: 141,
        zIndex: 2
    },
    moreButton: {
        width: "0px",
        minWidth: "0px"
    },
    validationButton: {
        width: 40,
        height: 40,
        boxShadow: 'unset',
        '&.Mui-disabled' : {
            backgroundColor: successColorDisabled,
        }
    },
    cancelButton: {
        width: 40,
        height: 40,
        backgroundColor: '#F44336',
        boxShadow: 'unset',
    },
    shipButton: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-end",
        textAlign: "right",
    },
    retailerInfos: {
        display: "flex",
        flexDirection: "column"
    },
    infosLink:{
        textDecoration: "none",
    },
    runnerBloc: {
        display: "flex",
        alignItems: "center",
        gap: '12px 32px',
    },
    justificationContainer: {
        display: "none"
    },
    justificationButton: {
        borderRadius: "32px",
        maxHeight: "40px",
        padding: '8px 24px',
        backgroundColor: infoColor,
        textTransform: 'none',
        boxShadow: 'unset',
        '&:hover': {
            boxShadow: 'unset',
        },
        '&:active': {
            boxShadow: 'unset',
        },
    },
    logo: {
        width: 200
    },
    filterBarContainer: {
        border: "1px solid " + lightGrayColor,
        borderRadius: "5px",
        padding: "5px",
        "& > span": {
            fontWeight: "400",
            fontSize: "16px",
            color: primaryColor
        },
        marginBottom: "10px",
    },
    filterButton: {
        color: infoColor,
        float:"right",
        padding: "0px",
    },
    filterItem: {
        fontWeight: "400",
        fontSize: "16px",
        margin: "10px 10px 10px 10px ",
        flex:" 0 0 7%",
    },
    orderTitleContent: {
        display: "flex",
    },
    orderTitleStatus: {
        marginLeft: "15px",
        height: "28px",
        "& > span": {
            fontSize: "12px",
        }
    },
    "@media (max-width: 670px)": {
        cardOrderHeader: {
            flexDirection: "column",
        },
        runnerBloc: {
            flexDirection: 'column',
        },
        orderDetails: {
            maxWidth: "100% !important",
        },
        orderRunnerDetails: {
            maxWidth: '100% !important',
        },
        customer: {
            maxWidth: "100% !important",
            textAlign: "left",
        },
        orderStatus: {
            justifyContent: "flex-start",
            marginTop: "6px",
        },
    }
});

export default showStyle;
