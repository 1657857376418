
const miniCardDetailsStyle = theme => ({
    container: {
        '& ul': {
            margin: 0,
            padding: 0,
            listStyle: "none",
            fontSize: "12px",
            '& li': {
                lineHeight: "14px",
            }
        }
    },
    containerClickable: {
        cursor: "pointer",
    },
    title: {
        wordBreak: "break-word",
        fontSize: "12px",
        fontWeight: "bold",
        lineHeight: "12px",
    },
    columns: {
        display: "flex",
        justifyContent: "space-between",
        marginTop: "5px",
        fontSize: "12px",
    }
});

export default miniCardDetailsStyle;