import React, {Component} from "react";
import { ThemeProvider } from '@mui/material/styles';
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {FormattedDate, FormattedTime, FormattedMessage, injectIntl} from "react-intl";
import {BeatLoader} from "react-spinners";
import ReactToPrint from "react-to-print";

// @mui/material components
import {
    Button,
    Avatar,
    Paper,
    Fab,
    Tooltip,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    TextField,
    createTheme,
} from "@mui/material"

// @mui/icons-material
import {
  NotificationsActive,
  Add,
  EmojiPeople,
} from "@mui/icons-material";

// core components
import CancellationReasonDialog from "../components/cancellationReasonDialog";
import ModalWaybillSelector from '../components/ModalWaybillSelector';
import ModalCustomerShippingEdit from './components/ModalCustomerShippingEdit';
import GridContainer from "components/grid/gridContainer";
import GridItem from "components/grid/gridItem";
import Card from "components/card/card";
import CardHeader from "components/card/cardHeader";
import CardBody from "components/card/cardBody";
import LineItemActivity from "../components/lineItemActivity";
import PriceDetails from '../components/PriceDetails';
import Table from "./showTable";
import Snackbar from "components/snackbar/snackbar";
import Justification from "./justification"

// styles
import showStyle from "assets/jss/views/picking/order/showStyle";
import {blackMagicColor, dangerColor, primaryColor, successColor} from "assets/jss/main";
import { ReactComponent as BrushColor } from 'assets/img/brush_color.svg'
import {ReactComponent as Check} from 'assets/img/check.svg';
import {ReactComponent as Close} from 'assets/img/close.svg';

// actions
import {list, reset} from "actions/lineItem/list";
import {
    cancellationReason,
    confirm,
    assign,
    update,
    reserve,
    reset as resetUpdateOrder
} from "actions/order/update";
import {list as noteList, reset as noteReset} from "actions/order/note/list";
import {list as lineItemActivityList, reset as lineItemActivityReset} from "actions/order/lineItemActivity/list";
import {create as createNote} from "actions/order/note/create";
import {resetUpdate} from "actions/lineItem/update";
import {list as organizationShippingMethodsList} from "api/shippingMethod/list";

// helpers
import {capitalize} from "utils/capitalize";
import abort from "utils/abort";
import {Authorizations, hasAuthorization} from "../../../utils/authorizations";
import {LineItemTransitions} from "utils/lineItemTransitions";

// context
import {OrderContext} from "contexts/OrderContext";
import { withStyles} from "@mui/styles";
import {getNotShippedStatus, LineItemStatus} from "utils/LineItemStatus";

const theme = createTheme({
    palette: {
        primary: {main: successColor},
        secondary: {main: dangerColor},
        default: {main: primaryColor},
        blackMagic: {main: blackMagicColor},
    }
});

class Show extends Component {
    static propTypes = {
        error: PropTypes.string,
        list: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            open: false,
            createErrorNotification: true,
            modalOpen: false,
            modalShippingOpen: false,
            trackingNumber: null,
            runner: false,
            addNoteOpen: false,
            note: null,
            organizationShippingMethods: [],
            cancellationReasonDialog: false,
            transitionActions: null,
            selectedItem: null,
            lineItemSelectedIds: [],
            waitingTimeBeforeRefreshingList: false,
            selectedShipment: null
        };
    };

    // because we don't want to use redux to trigger a refresh
    refreshOrder(waitingTime = 0) {
        this.setState({
            waitingTimeBeforeRefreshingList: true,
        });
        setTimeout(() => {
            this.props.list(decodeURIComponent(`order.id=${this.props.match.params.id}&pagination=true&itemsPerPage=9999&page=1&order[name]=asc`));
            this.props.noteList(decodeURIComponent(`order.id=${this.props.match.params.id}&order[createdAt]=desc`));
            this.props.lineItemActivityList(decodeURIComponent(`order.id=${this.props.match.params.id}&order[createdAt]=desc`));
            this.props.resetUpdate();
            this.props.resetUpdateOrder();
            this.setState({
                waitingTimeBeforeRefreshingList: false,
            });

            if (this.props.retrieved) {
                this.autoCheckToShipWithoutShipmentLineItems(this.props.retrieved['hydra:member']);
            }
        }, waitingTime);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({createErrorNotification: true});
        if ((this.props.updateSuccessOrder !== nextProps.updateSuccessOrder) || this.props.updateSuccessLineItem !== nextProps.updateSuccessLineItem) {
            this.refreshOrder();
        }

        if (this.props.noteCreated !== nextProps.noteCreated) {
            nextProps.noteList(decodeURIComponent(`order.id=${this.props.match.params.id}&order[createdAt]=desc`));
        }

        if (nextProps.retrieved) {
            this.autoCheckToShipWithoutShipmentLineItems(nextProps.retrieved['hydra:member']);
        }
    }

    componentDidMount() {
        this.props.list(decodeURIComponent(`order.id=${this.props.match.params.id}&pagination=true&itemsPerPage=9999&page=1&order[name]=asc`));
        this.props.noteList(decodeURIComponent(`order.id=${this.props.match.params.id}&order[createdAt]=desc`));
        this.props.lineItemActivityList(decodeURIComponent(`order.id=${this.props.match.params.id}&order[createdAt]=desc`));

        organizationShippingMethodsList().then((res) => {
            this.setState({
                organizationShippingMethods: res
            })
        })
    }

    componentWillUnmount() {
        this.props.reset();
        this.props.noteReset();
        this.props.lineItemActivityReset();
        abort.abortRequests();
    };

    autoCheckToShipWithoutShipmentLineItems = (lineItems) => {
        this.setLineItemSelectedIds(this.getToShipWithoutShipmentLineItems(lineItems).map(li => li['@id']))
    }

    checkIfRunnerExist = (lineItems) => {
        for (let i = 0; i < lineItems.length; i++) {
            if (lineItems[i].runner !== null) {
                return true
            }
        }

        return false;
    };

    isThereOtherRunnerThanUser(lineItems, member) {
        for (let i = 0; i < lineItems.length; i++) {
            if (lineItems[i].runner === null || lineItems[i].runner["@id"] !== `/members/${member.id}`) {
                return true
            }
        }

        return false;
    }

    renderRunnerAvatar = (runner, key) => {
        const {classes} = this.props;

        return (
            runner.user.picture ?
                <img
                    src={runner.user.picture}
                    className={classes.img}
                    alt={""}
                    key={key}
                    style={{position: 'relative', zIndex: 20 - key}}
                /> :
                <Avatar className={classes.imgPlaceholder} key={key} style={{position: 'relative', zIndex: 20 - key}}>
                    {capitalize(runner.user.givenName.charAt(0))}
                </Avatar>
        );
    };

    renderRunner = (lineItems) => {
        const {classes} = this.props;

        let runnerList = [];

        if (hasAuthorization(this.props.authorizations, Authorizations.PICKING_RETAILER) || hasAuthorization(this.props.authorizations, Authorizations.PICKING_READ_ONLY)) {
            return null;
        }

        return (
            <div className={classes.runner}>
                {this.checkIfRunnerExist(lineItems) && (
                    <div className={classes.runnerList}>
                        <span className={classes.affectedTo}>
                            <FormattedMessage id={"picking.order.show.header.affectedTo"}/>
                        </span>
                        {lineItems.map((item, key) => {
                            if (item.runner !== null) {
                                if (!runnerList.includes(item.runner["@id"])) {
                                    runnerList.push(item.runner["@id"]);
                                    return this.renderRunnerAvatar(item.runner, key);
                                }
                            }

                            return null;
                        })}
                    </div>
                )}
                {this.isThereOtherRunnerThanUser(lineItems, this.props.member) && (
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={() => this.handleClickAssign(lineItems[0].order["@id"],this.props.member.id)}
                        classes={{root: classes.assign}}
                    >
                        <FormattedMessage id={"picking.order.show.header.assignItToMe"}/>
                        <div className={classes.submitLoading}>
                            <BeatLoader
                                sizeUnit={"px"}
                                size={4}
                                color={"#FFF"}
                                loading={this.props.updateLoadingOrder}
                            />
                        </div>
                    </Button>
                )}
            </div>
        )
    }

    toggleModalWaybillSelector = () => {
        this.setState({modalOpen: !this.state.modalOpen}, () => {
            // when popup is close
            if (false === this.state.modalOpen) {
                this.setSelectedShipment(null);
                this.autoCheckToShipWithoutShipmentLineItems(this.props.retrieved['hydra:member'])
            }
        });
    }

    renderOrderStatus = (lineItems) => {
        const {classes, retrieved} = this.props;

        const isPickingManager = hasAuthorization(this.props.authorizations, Authorizations.PICKING_MANAGER);
        let isRetailer = hasAuthorization(this.props.authorizations, Authorizations.PICKING_RETAILER);
        let orderStatus = lineItems[0].order.status;

        const statusIsPending = lineItems[0].order.status === "pending";
        const statusIsReserved = lineItems[0].order.status === "reserved";
        const statusIsConfirmed = lineItems[0].order.status === "confirmed";
        const statusIsToShip = lineItems[0].order.status === "to-ship";
        const isClickAndCollect = lineItems[0].shippingMethod === 'click-collect';

        const canConfirm = hasAuthorization(this.props.authorizations, Authorizations.PICKING_RETAILER_CONFIRM);
        const canCancel = hasAuthorization(this.props.authorizations, Authorizations.PICKING_RETAILER_CANCEL);
        const canRefund = hasAuthorization(this.props.authorizations, Authorizations.PICKING_RETAILER_REFUND);
        const canClickAndCollect = hasAuthorization(this.props.authorizations, Authorizations.PICKING_RETAILER_C_C);
        const isReadOnly = hasAuthorization(this.props.authorizations, Authorizations.PICKING_READ_ONLY);

        const isClickAndCollectAndHaveAuthorization = isClickAndCollect && canClickAndCollect;

        const order = lineItems[0].order;

        let isAssigned = true;

        lineItems.map(lineItem => {
            if (lineItem.runner === null) {
                isAssigned = false;
                return isAssigned;
            }

            if (lineItem.runner["@id"] !== `/members/${this.props.member.id}`) {
                isAssigned = false;
                return isAssigned;
            }

            return isAssigned;
        });

            let primaryFunction = null;
            let primaryTooltipMessageId = null;
            let secondaryFunction = null;
            let secondaryTooltipMessageId = null;

            if (statusIsPending) {
                if (isRetailer || isAssigned) {
                    primaryFunction = this.handleClickReserve;
                    primaryTooltipMessageId = "picking.order.show.table.action.reserve";
                }
                if ((!isRetailer && (isAssigned )) || canCancel) {
                    secondaryFunction = () => {
                        this.openCancellationDialogByTransitionAndItem(order, [
                            LineItemTransitions.CANCEL,
                            LineItemTransitions.UNAVAILABLE
                        ])
                    }
                    secondaryTooltipMessageId = "picking.order.show.table.action.cancel";
                }
            } else if (statusIsConfirmed) {
                if ((!isRetailer) || canRefund) {
                    secondaryFunction = () => {
                        this.openCancellationDialogByTransitionAndItem(order, [LineItemTransitions.CANCEL])
                    }
                    secondaryTooltipMessageId = "picking.order.show.table.action.refund";
                }
            } else if (statusIsReserved) {
                if (isAssigned || (isRetailer && canConfirm)) {
                    primaryFunction = this.handleClickConfirm;
                    primaryTooltipMessageId = "picking.order.show.table.action.confirm";
                }
                if ((!isRetailer && (isAssigned )) || canCancel) {
                    secondaryFunction = () => {
                        this.openCancellationDialogByTransitionAndItem(order, [
                            LineItemTransitions.CANCEL,
                            LineItemTransitions.UNAVAILABLE
                        ])
                    }
                    secondaryTooltipMessageId = "picking.order.show.table.action.cancel";
                }
            } else if (statusIsToShip) {
                if ((!isRetailer && (isAssigned )) || canCancel) {
                    secondaryFunction = () => {
                        this.openCancellationDialogByTransitionAndItem(order, [LineItemTransitions.CANCEL])
                    }
                    secondaryTooltipMessageId = "picking.order.show.table.action.cancel";
                }
            }

            if (isReadOnly) {
                primaryFunction = null;
                primaryTooltipMessageId = null;
                secondaryFunction = null;
                secondaryTooltipMessageId = null;
            }

            let orderStatusClass;
            let orderShippingMethod;

            if ('pending' !== orderStatus && 'reserved' !== orderStatus) {
                lineItems.map(lineItem => {
                    if ('to-ship' === lineItem.status) {
                        orderStatus = 'to-ship'
                    }
                    orderShippingMethod = lineItem.shippingMethod;
                });
            }

            switch (orderStatus) {
                case 'to-ship':
                    orderStatusClass = classes.toShip;

                    break;
                case 'confirmed':
                    orderStatusClass = classes.confirmed;

                    break;
                case 'canceled':
                    orderStatusClass = classes.canceled;

                    break;

                case 'reserved':
                    orderStatusClass = classes.reserved;

                    break;

                case 'returning':
                    orderStatusClass = classes.returning;

                    break;
                default:
                    orderStatusClass = classes[orderStatus];
            }

            return (
                <div className={classes.actionContainer}>
                    <div className={classes.orderStatus}>
                        <ThemeProvider theme={theme}>
                            {this.props.updateLoadingOrder || this.props.updateLoadingLineItem || this.state.waitingTimeBeforeRefreshingList ?
                                <BeatLoader
                                    sizeUnit={"px"}
                                    size={4}
                                    color={primaryColor}
                                    loading={true}
                                /> :
                                <span className={classes.orderActions}>
                                    { statusIsToShip && (
                                        <div className={classes.orderStatus}>
                                            {'to-ship' !== orderStatus ?
                                                <span className={orderStatusClass}>
                                <FormattedMessage id={"picking.order.show.table.status." + orderStatus}/>
                            </span> :
                                                <div>
                                                    {(isPickingManager || isAssigned || (isRetailer && (hasAuthorization(this.props.authorizations, Authorizations.PICKING_RETAILER_DRIVE) || isClickAndCollectAndHaveAuthorization))) &&
                                                        <div>
                                                            <Fab
                                                                color="primary"
                                                                size={'small'}
                                                                className={classes.validationButton}
                                                                onClick={this.toggleModalWaybillSelector}
                                                                disabled={!this.state.lineItemSelectedIds.length}
                                                            >
                                                                <Tooltip
                                                                    title={
                                                                        'click-collect' === orderShippingMethod ? (
                                                                            <FormattedMessage id={"picking.order.show.action.delivered.to.customer"}/>
                                                                        ) : 'locker' === orderShippingMethod ? (
                                                                            <FormattedMessage id={"picking.order.show.action.delivered.to.locker"}/>
                                                                        ) : (
                                                                            <FormattedMessage id={"picking.order.show.action.ship"}/>
                                                                        )
                                                                    }>
                                                                    <Check />
                                                                </Tooltip>
                                                            </Fab>
                                                            <ModalWaybillSelector
                                                                open={this.state.modalOpen}
                                                                onClose={this.toggleModalWaybillSelector}
                                                                refreshOrder={(waitingTime) => this.refreshOrder(waitingTime)}
                                                                lineItems={retrieved && retrieved['hydra:member']}
                                                                lineItemSelectedIds={this.state.lineItemSelectedIds}
                                                                selectedShipment={this.state.selectedShipment}
                                                            />
                                                        </div>
                                                    }
                                                </div>
                                            }
                                        </div>
                                    ) }
                                    {primaryFunction !== null &&
                                    <Fab
                                        color="primary"
                                        size={'small'}
                                        className={classes.validationButton}
                                        onClick={() => primaryFunction(lineItems[0].order["@id"])}
                                    >
                                        <Tooltip title={<FormattedMessage id={primaryTooltipMessageId}/>}>
                                            <Check />
                                        </Tooltip>
                                    </Fab>
                                    }
                                    {secondaryFunction !== null &&
                                        <Fab
                                            color="secondary"
                                            size={'small'}
                                            className={classes.cancelButton}
                                            onClick={() => secondaryFunction(lineItems[0].order["@id"])}
                                        >
                                            <Tooltip title={<FormattedMessage id={secondaryTooltipMessageId}/>}>
                                                <Close />
                                            </Tooltip>
                                        </Fab>
                                    }
                                </span>
                            }
                        </ThemeProvider>
                   </div>
                </div>
            );
    };

    handleMoreClick = () => {
        this.setState(state => ({
            open: !state.open,
        }));
    };

    handleClickConfirm = (orderId) => {
        if (window.confirm(this.props.intl.formatMessage({id: "picking.order.show.table.window.confirmAll"})))
            this.props.confirm(orderId);
    };

    handleClickReserve = (orderId) => {
        if (window.confirm(this.props.intl.formatMessage({id: "picking.order.show.table.window.reserveAll"})))
            this.props.reserve(orderId);
    };

    handleClickAssign = (orderId, runner) => {
        this.props.assign(orderId, {"runner": runner})
    };

    confirmCancellationAction(reason, unavailableDays = null) {
        const {selectedItem} = this.state;

        this.props.cancellationReason(selectedItem, reason, unavailableDays);

        this.closeCancellationReasonDialog();
    }

    openCancellationDialogByTransitionAndItem(order, transitionActions) {
        this.setState({
            selectedItem: order,
            transitionActions: transitionActions,
            cancellationReasonDialog: true
        })
    }

    closeCancellationReasonDialog() {
        this.setState({
            cancellationReasonDialog: false,
            selectedItem: null,
            transitionActions: null
        })
    }

    setLineItemSelectedIds = (lineItemIds) => {
        this.setState({
            lineItemSelectedIds: lineItemIds,
        })
    }

    setSelectedShipment = (shipment) => {
        this.setState({
            selectedShipment: shipment
        })
    }

    handleChangeCheckboxGlobal() {
        const {retrieved} = this.props
        const {lineItemSelectedIds} = this.state
        let newSelected = []

        if (!retrieved) {
            return;
        }

        if (lineItemSelectedIds.length < this.getToShipLineItems(retrieved['hydra:member']).length) {
            const lineItems = this.getToShipLineItems(retrieved['hydra:member']);
            newSelected = lineItems?.map(li => li['@id'])
        }

        this.setLineItemSelectedIds(newSelected);
    }

    handleChangeCheckboxItem(itemId) {
        const {lineItemSelectedIds} = this.state
        const newSelected = [...lineItemSelectedIds]
        const currentIndex = lineItemSelectedIds.indexOf(itemId)

        if (currentIndex === -1) {
            newSelected.push(itemId)
        } else {
            newSelected.splice(currentIndex, 1);
        }

        this.setLineItemSelectedIds(newSelected);
    }

    getToShipLineItems(lineItems) {
        if (!lineItems) {
            return [];
        }

        return lineItems.filter(lineItem => lineItem.status.includes(LineItemStatus.TO_SHIP));

    }

    getToShipWithoutShipmentLineItems(lineItems) {
        if (!lineItems) {
            return [];
        }

        return lineItems
            .filter(lineItem => lineItem.status.includes(LineItemStatus.TO_SHIP) && lineItem.shipments.length === 0);
    }

    getNotShippedLineItems(lineItems) {
        if (!lineItems) {
            return [];
        }

        return lineItems.filter(lineItem => getNotShippedStatus().includes(lineItem.status) && !lineItem.shipments.length);
    }


    /* Obscure code, isn't it ? */
    sortShipmentsByCreatedAtDate(shipments) {
        // transform object to array
        let sortable = [];
        for (let shipment in shipments) {
            sortable.push([shipment, shipments[shipment]]);
        }

        // sort by date
        sortable.sort(function(a, b) {
            return new Date(a[1].shipmentData.createdAt) - new Date(b[1].shipmentData.createdAt);
        });

        // transform array to object
        let objSorted = {}
        sortable.forEach(function(item){
            objSorted[item[0]] = item[1]
        })


        return objSorted;
    }

    getShipmentsByLineItems(lineItems) {
        let shipments = {};

        if (!lineItems) {
            return {}
        }

        // this code is to avoid another api call
        // and a lot of @Groups for apiPlatform
        // we have all the data we want here
        // we just group lineItems in their shipments
        for (let lineItem of lineItems) {
            for (let shipment of lineItem.shipments) {
                let key = shipment['@id'];

                if (key in shipments) {
                    shipments[key].lineItems.push(lineItem)
                } else {
                    shipments[key] = {
                        shipmentData: shipment,
                        lineItems: [lineItem]
                    }
                }
            }
        }

        return this.sortShipmentsByCreatedAtDate(shipments)
    }

    render() {
        const {classes, retrieved} = this.props;

        const hasErrors = this.props.error || this.props.updateErrorOrder;
        const order = retrieved && retrieved["hydra:member"][0].order;
        const orderStatusBeforeCompleted = [
            LineItemStatus.PENDING,
            LineItemStatus.RESERVED,
            LineItemStatus.TO_SHIP,
        ].includes(order?.status) ?? false;

        const lineItems = retrieved && retrieved["hydra:member"];

        const shipments = this.getShipmentsByLineItems(lineItems)

        const handleClickOpenAddNote = () => {
            this.setState(state => ({addNoteOpen: true}));
        };

        const handleCloseAddNote = () => {
            this.setState(state => ({addNoteOpen: false}));
        };

        const handleNoteChange = (event) => {
            this.setState({note: event.currentTarget.value});
        }

        const handleAddNote = () => {
            this.setState(state => ({addNoteOpen: false}));
            this.props.createNote({content: this.state.note, order: `/orders/${order.id}`, member: `/members/${this.props.member.id}`})
        };

        return (
            <div className={classes.containerWithSidebar}>
                {hasErrors && (
                    <Snackbar
                        open={this.state.createErrorNotification}
                        close
                        closeNotification={() => this.setState({createErrorNotification: false})}
                        place={"bl"}
                        color={"danger"}
                        icon={function () {
                            return <NotificationsActive/>
                        }}
                        message={this.props.error}
                    />
                )}
                <div className={classes.container}>
                    <Card>
                        <CardHeader color="info" className={classes.cardHeader}>
                            <GridContainer justifyContent={"space-between"} className={classes.cardOrderHeader}>
                                <GridItem xs={6} className={classes.orderDetails}>
                                    {order &&
                                        <h3 className={classes.title}>
                                            <div className={classes.orderTitleContent}>
                                                <FormattedMessage id={"picking.order.show.title"}/>&nbsp;
                                                <b>#{order.externalId}</b>
                                                <div className={classes.orderTitleStatus}>
                                                    <span className={order.status === "to-ship" ? classes.toShip : classes[order.status]}>
                                                        <FormattedMessage id={"picking.order.show.table.status." + order.status}/>
                                                    </span>
                                                </div>
                                            </div>
                                            <FormattedDate value={new Date(order.createdAt)} year={"numeric"} month={"numeric"} day={"numeric"}/>
                                            &nbsp;
                                            <FormattedTime value={new Date(order.createdAt)} hour={"numeric"} minute={"numeric"}/>
                                        </h3>
                                    }
                                </GridItem>
                                <GridItem xs={6} className={classes.customer}>
                                    <div>
                                        <b>
                                            {retrieved &&
                                            capitalize(retrieved["hydra:member"][0].order.customerShippingFirstname || retrieved["hydra:member"][0].order.customerBillingFirstname)
                                            + " " +
                                            capitalize(retrieved["hydra:member"][0].order.customerShippingLastname || retrieved["hydra:member"][0].order.customerBillingLastname)
                                            }
                                            {orderStatusBeforeCompleted && <BrushColor
                                                className={classes.editCustomerShipping}
                                                onClick={() => {
                                                    this.setState({
                                                        modalShippingOpen: !this.state.modalShippingOpen,
                                                    });
                                                }}
                                            />}
                                        </b>
                                        {order &&
                                            <div>
                                                {null !== order.customerShippingCompany && order.customerShippingCompany + ", "}
                                                {order.customerShippingAddress + ", "}
                                                {null !== order.customerShippingAddressMore && order.customerShippingAddressMore + ", "}
                                                {order.customerShippingPostalCode + " "}
                                                {order.customerShippingCity}
                                                <br />
                                                {order.customerShippingCountry}
                                                <br/>
                                                {order.customerShippingPhone}
                                                <br/>
                                                {order.customerBillingEmail}
                                            </div>
                                        }
                                    </div>
                                </GridItem>
                            </GridContainer>
                            <GridContainer justifyContent={"space-between"} className={classes.cardOrderHeader}>
                                <GridItem xs={6} className={classes.orderRunnerDetails}>
                                    {(retrieved && !hasAuthorization(this.props.authorizations, Authorizations.PICKING_READ_ONLY)) &&
                                    <div className={classes.runnerBloc}>
                                        {this.renderRunner(retrieved["hydra:member"])}
                                        <ReactToPrint
                                            trigger={() => <Button
                                                color="primary"
                                                variant="contained"
                                                classes={{root: classes.justificationButton}}
                                            >
                                                <FormattedMessage id={"picking.order.show.header.justificationPrintBtn"}/>
                                            </Button>}
                                            content={() => this.componentRef}
                                        />
                                        <div className={classes.justificationContainer}>
                                            <Justification ref={el => (this.componentRef = el)} />
                                        </div>
                                    </div>
                                    }
                                </GridItem>
                                <GridItem xs={6} className={classes.orderRunnerDetails}>
                                    {retrieved && this.renderOrderStatus(retrieved["hydra:member"])}
                                </GridItem>
                            </GridContainer>
                        </CardHeader>
                        <CardBody className={classes.cardBody}>
                            <Table
                                tableData={lineItems && this.getNotShippedLineItems(lineItems)}
                                match={this.props.match}
                                runnerHeader={retrieved && this.checkIfRunnerExist(retrieved["hydra:member"])}
                                organizationShippingMethods={this.state.organizationShippingMethods}
                                lineItemSelectedIds={this.state.lineItemSelectedIds}
                                handleChangeCheckboxGlobal={this.handleChangeCheckboxGlobal.bind(this)}
                                handleChangeCheckboxItem={this.handleChangeCheckboxItem.bind(this)}
                                shipments={shipments}
                                toShipLineItemLength={lineItems && this.getToShipLineItems(lineItems).length}
                                refreshOrder={() => this.refreshOrder()}
                                toggleModalWaybillSelector={this.toggleModalWaybillSelector}
                                setLineItemSelectedIds={(lineItemIds) => this.setLineItemSelectedIds(lineItemIds)}
                                setSelectedShipment={(shipment) => this.setSelectedShipment(shipment)}
                                waitingTimeBeforeRefreshingList={this.state.waitingTimeBeforeRefreshingList}
                            />
                        </CardBody>
                    </Card>
                    <div className={classes.priceDetailsContainer}>
                        <PriceDetails
                            lineItems={retrieved && retrieved['hydra:member']}
                            currentOrganization={this.props.currentOrganization}
                            authorizations={this.props.authorizations}
                        />
                    </div>
                    {!this.props.noteLoading && !this.props.lineItemActivityLoading &&
                        <div>
                            {!hasAuthorization(this.props.authorizations, Authorizations.PICKING_READ_ONLY) &&
                                <div className={classes.addNoteContainer}>
                                    <Button color="primary" onClick={handleClickOpenAddNote} endIcon={<Add/>}>
                                        <FormattedMessage id={'picking.order.show.note.add'}/>
                                    </Button>
                                    <Dialog open={this.state.addNoteOpen} onClose={handleCloseAddNote} aria-labelledby="form-dialog-title">
                                        <DialogTitle id="form-dialog-title">
                                            <FormattedMessage id={"picking.order.show.note.dialog.title"}/>
                                        </DialogTitle>
                                        <DialogContent>
                                            <DialogContentText>
                                                <FormattedMessage id={"picking.order.show.note.dialog.content"}/>
                                            </DialogContentText>
                                            <TextField
                                                variant="standard"
                                                autoFocus
                                                margin="dense"
                                                id="name"
                                                type="text"
                                                fullWidth
                                                onChange={handleNoteChange} />
                                        </DialogContent>
                                        <DialogActions>
                                            <Button onClick={handleCloseAddNote} color="primary">
                                                <FormattedMessage id={"picking.order.show.dialog.action.cancel"}/>
                                            </Button>
                                            <Button onClick={handleAddNote} color="primary">
                                                <FormattedMessage id={"picking.order.show.dialog.action.confirm"}/>
                                            </Button>
                                        </DialogActions>
                                    </Dialog>
                                </div>
                            }


                            {((this.props.noteRetrieved && this.props.noteRetrieved["hydra:member"].length > 0) || (this.props.lineItemActivityRetrieved && this.props.lineItemActivityRetrieved["hydra:member"].length > 0)) &&
                                this.renderNotesAndLineItemActivities()
                            }
                        </div>
                    }
                </div>
                <ModalCustomerShippingEdit
                    open={this.state.modalShippingOpen}
                    onClose={() => this.setState({modalShippingOpen: false})}
                    refreshOrder={(waitingTime) => this.refreshOrder(waitingTime)}
                    order={order}
                />
                <OrderContext.Provider value={{
                    closeCancellationReasonDialog: () => this.closeCancellationReasonDialog(),
                    confirmCancellationAction: (selectedReason, daysCount) => {
                        this.confirmCancellationAction(selectedReason, daysCount)
                    },
                    isCancellationReasonDialogOpen: this.state.cancellationReasonDialog,
                    transitionActions: this.state.transitionActions,
                    isOrderAllowed: true,
                    isItemAllowed: false,
                    status: this.state.selectedItem?.status
                }}>
                    <CancellationReasonDialog />
                </OrderContext.Provider>
            </div>
        );
    }

    renderNotesAndLineItemActivities() {
        const {classes} = this.props;

        let items = [];
        // Hotfix because we have async problem with those 2 fields
        if (this.props.noteRetrieved) {
            items = this.props.noteRetrieved["hydra:member"] ?? [];
        }
        if (this.props.lineItemActivityRetrieved) {
            items = items.concat(this.props.lineItemActivityRetrieved["hydra:member"] ?? []);
        }

        items.sort((itemA, itemB) => {
            let dateA = Date.parse(itemA.createdAt), dateB = Date.parse(itemB.createdAt);
            return (dateA < dateB) - (dateA > dateB);
        });

        return items.map((item, key) => {
            let content;
            let user;
            switch (item['@type']) {
                case 'Note':
                    user = item.member ? item.member.user : null;
                    content = this.renderNote(item);
                    break;
                case 'LineItemActivity':
                    user = item.user;
                    content = <LineItemActivity classes={classes} lineItemActivity={item} />
                    break;
                default:
                    console.warning('Try to render an invalid object of type ' + item['@type'] + '. Supported types : Note, LineItemActivity.');
                    return <></>;
            }

            return (
                <div className={classes.noteContainer} key={key}>
                    {user ?
                        <div>
                            {user.picture ?
                                <img
                                    src={user.picture}
                                    className={classes.img}
                                    alt={""}
                                />
                                :
                                <Avatar className={classes.imgPlaceholder}>
                                    {capitalize(user.familyName.charAt(0) + user.givenName.charAt(0))}
                                </Avatar>
                            }
                        </div>
                        :
                        <Avatar className={classes.imgPlaceholder}>
                            <EmojiPeople/>
                        </Avatar>
                    }
                    <Paper className={classes.notePaper} elevation={0} variant="outlined">
                        <div className={classes.notePaperHeader}>
                            <span>{user ? user.givenName + ' ' + user.familyName : this.props.intl.formatMessage({id: "picking.order.show.note.customer"})}</span>
                            <span><FormattedDate value={new Date(item.createdAt)}/> - <FormattedTime value={new Date(item.createdAt)}/></span>
                        </div>
                        {content}
                    </Paper>
                </div>
            );
        });
    }

    renderNote(note) {
        let content = this.translateAffectedCommandNote(note.content)
        content = this.translateAffectedArticleNote(content)

        return (
            <p>{content}</p>
        )
    }

    translateAffectedCommandNote(content) {
        const regex = /Je me suis affecté la commande: #(.*)/g
        const matches = regex.exec(content)
        if (matches) {
            content = <FormattedMessage
                id={'picking.order.show.note.affected_command'}
                values={{orderId: matches[1]}}
            />
        }

        return content
    }

    translateAffectedArticleNote(content) {
        const regex = /Je me suis affecté l'article: (.*)/g
        const matches = regex.exec(content)
        if (matches) {
            content = <FormattedMessage
                id={'picking.order.show.note.affected_article'}
                values={{lineItemName: matches[1]}}
            />
        }

        return content
    }
}

const mapStateToProps = state => {
    return {
        retrieved: state.lineItem.list.retrieved,
        loading: state.order.list.loading,
        error: state.order.list.error,
        noteRetrieved: state.note.list.retrieved,
        noteLoading: state.note.list.loading,
        noteError: state.note.list.error,
        noteCreated: state.note.create.created,
        lineItemActivityRetrieved: state.lineItemActivity.list.retrieved,
        lineItemActivityLoading: state.lineItemActivity.list.loading,
        updateLoadingOrder: state.order.update.loading,
        updateSuccessOrder: state.order.update.success,
        updateErrorOrder: state.order.update.error,
        updateLoadingLineItem: state.lineItem.update.loading,
        updateSuccessLineItem: state.lineItem.update.success,
        organizations: state.authentication.organizations,
        member: state.authentication.member,
        authorizations: state.authentication.authorizations,
        currentOrganization: state.currentOrganization.retrieved ?? null,
    };

};

const mapDispatchToProps = dispatch => ({
    list: params => dispatch(list(params)),
    noteList: params => dispatch(noteList(params)),
    lineItemActivityList: params => dispatch(lineItemActivityList(params)),
    createNote: values => dispatch(createNote(values)),
    update: (order, values) => dispatch(update(order, values)),
    reset: () => dispatch(reset()),
    noteReset: () => dispatch(noteReset()),
    lineItemActivityReset: () => dispatch(lineItemActivityReset()),
    confirm: (orderId) => dispatch(confirm(orderId)),
    reserve: (orderId) => dispatch(reserve(orderId)),
    assign: (orderId, data) => dispatch(assign(orderId, data)),
    resetUpdate: () => dispatch(resetUpdate()),
    resetUpdateOrder: () => dispatch(resetUpdateOrder()),
    cancellationReason: (order, reason, unavailableDays) => dispatch(cancellationReason(order, reason, unavailableDays))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(showStyle)(injectIntl(Show)));
