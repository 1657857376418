export function paramsToObject(params) {
    params = new URLSearchParams(decodeURIComponent(params));

    let entries = params.entries();

    let result = {};

    for (let entry of entries) {
        let [key, value] = entry;

        if (value === "true") {
            result[key] = true;
        } else if (value === "false") {
            result[key] = false;
        } else {
            result[key] = value;
        }
    }

    return result;
}