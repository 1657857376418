import React from "react"
import {withStyles} from "@mui/styles"
import merchandisingStyle from "../assets/jss/views/merchandising/merchandisingStyle"
import GridContainer from "./grid/gridContainer"
import GridItem from "./grid/gridItem"
import CardBody from "./card/cardBody"
import CardFooter from "./card/cardFooter"
import CardHeader from "./card/cardHeader";
import Card from "./card/card";


function ApplicationContainer ({classes, children, header, footer}) {
    const renderHeader = () => {
        return <CardHeader>{!!header ? header : ''}</CardHeader>
    }
    const renderFooter = () => {
        return <CardFooter>{!!footer ? footer : ''}</CardFooter>
    }

    return <div className={classes.containerWithSidebar}>
        <div className={classes.container}>
            <GridContainer>
                <GridItem xs={12}>
                    <Card>
                        {renderHeader()}
                        <CardBody>
                            {children}
                        </CardBody>
                        {renderFooter()}
                    </Card>
                </GridItem>
            </GridContainer>
        </div>
    </div>
}

export default withStyles(merchandisingStyle)(ApplicationContainer)
