import {fetch} from "../../utils/dataAccess";

export function massUpdateError(massUpdateError) {
    return {type: "PRODUCT_MASS_UPDATE_UPDATE_ERROR", massUpdateError};
}

export function massUpdateLoading(massUpdateLoading) {
    return {type: "PRODUCT_MASS_UPDATE_UPDATE_LOADING", massUpdateLoading};
}

export function massUpdateSuccess(massUpdateSuccess) {
    return {type: "PRODUCT_MASS_UPDATE_UPDATE_SUCCESS", massUpdateSuccess};
}

export function massUpdate(params) {

    return dispatch => {
        dispatch(massUpdateLoading(true));
        return fetch('/product/massupdate/', {
            method: "PUT",
            headers: new Headers({"Content-Type": "application/ld+json"}),
            body: JSON.stringify(params)
        }).then(response =>
            response.json()
        ).then((retrieved) => {
            dispatch(massUpdateSuccess(retrieved));
            dispatch(massUpdateLoading(false));
        })
        .catch(e => {
            dispatch(massUpdateLoading(false));
            dispatch(massUpdateError(e.message));
        });

    };
}

export function reset(eventSource) {
    return dispatch => {
        if (eventSource) eventSource.close();

        dispatch(massUpdateError(false));
        dispatch(massUpdateSuccess(null));
        dispatch(massUpdateLoading(null));
    };
}