import auth0 from "auth0-js";
import * as Cookies from "js-cookie";
import {AUTH0_DOMAIN, AUTH0_CLIENT_ID, AUTH0_AUDIENCE, AUTH0_REDIRECT_URI, AUTH0_LOGOUT_RETURN} from "../../config/_auth0";

class Auth {
    constructor() {
        this.auth0 = new auth0.WebAuth({
            domain: AUTH0_DOMAIN,
            clientID: AUTH0_CLIENT_ID,
            audience: AUTH0_AUDIENCE,
            redirectUri: AUTH0_REDIRECT_URI,
            responseType: "token"
        });

        this.signIn = this.signIn.bind(this);
        this.signUp = this.signUp.bind(this);
        this.forceSignIn = this.forceSignIn.bind(this);
        this.signOut = this.signOut.bind(this);
    }

    signIn() {
        this.clearCookies();
        this.clearLocalstorage();
        this.auth0.authorize();
    }

    signUp() {
        this.clearCookies();
        this.auth0.authorize({screen_hint: 'signup'});
    }

    forceSignIn() {
        this.clearCookies();
        this.auth0.authorize({prompt: "login"});
    }

    async signOut() {
        await this.auth0.logout({returnTo: AUTH0_LOGOUT_RETURN});
        this.clearCookies();
        this.clearLocalstorage();
    }

    clearCookies() {
        var cookies = document.cookie.split(";");

        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i];
            var eqPos = cookie.indexOf("=");
            var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            if (' _ga' !== name && ' _gid' !== name) {
                Cookies.remove(name.trim(), {domain: process.env.REACT_APP_HOST});
            }
        }
    }

    clearLocalstorage() {
        localStorage.clear();
    }
}

const auth0Client = new Auth();

export default auth0Client;
