import {fetch} from "../../utils/dataAccess";

export function categoryList(params = "") {
    return dispatch => {
        dispatch({type: "ORGANIZATION_CATEGORY_LIST_LOADING", loading: true});

        fetch('/categories?pagination=false&draft=false'+params).then(response => {
            response.json().then(retrieved => {
                dispatch({type: "ORGANIZATION_CATEGORY_LIST_LOADING", loading: false});
                dispatch({type: "ORGANIZATION_CATEGORY_LIST_SUCCESS", retrieved: retrieved["hydra:member"]});
            })
        }).catch(error => {
            // canceled
        });
    };
}
