import {
    container,
    defaultFont,
    lightGrayColor,
    primaryColor,
} from "../../main";

import {
    quoteAccepted,
    quoteRefused,
    quoteWaitingCustomer,
    quoteWaitingReview,
} from "./colors";

const tab = {
    padding: "12px 16px",
    borderRadius: "8px",
    margin: "5px 5% 5px 0",
    minHeight: "40px",
};

const listStyle = theme => ({
    containerWithSidebar: {
        [theme.breakpoints.up("md")]: {
            marginLeft: "240px",
        }
    },
    container: {
        ...container,
        ...defaultFont
    },
    header: {
        width: "100%",
        height: "15%",
        marginTop: "20px",
        maxHeight: "200px",
        display: "flex",
    },
    datepicker: {
        flex: "1",
        maxWidth: "430px",
    },
    search: {
        flex: "1",
        textAlign: "right",
        maxHeight: "30px",
        width: "50%",
    },
    tabsRoot: {
        borderBottom: `2px solid ${lightGrayColor}`,
        borderTop: `1px solid ${lightGrayColor}`,
    },
    tabsIndicator: {
        backgroundColor: primaryColor,
    },
    tabSelected: {
        backgroundColor: primaryColor,
        color: "white",
    },
    tabAwaiting: {
        backgroundColor: quoteWaitingReview,
        color: '#222',
        '&:hover': {color: '#545454', backgroundColor: '#F7F7F7',},
        '&$tabSelected': {color: '#222', backgroundColor: '#D5D5D5',},
        '&:focus': {color: '#222',},
    },
    tabInternalAction: {
        backgroundColor: quoteWaitingReview,
        color: '#222',
        '&:hover': {color: '#545454', backgroundColor: '#F7F7F7',},
        '&$tabSelected': {color: '#222', backgroundColor: '#D5D5D5',},
        '&:focus': {color: '#222',},
    },
    tabCustomerAction: {
        color: 'white',
        backgroundColor: quoteWaitingCustomer,
        '&:hover': {backgroundColor: '#fea869',},
        '&$tabSelected': {color: 'white', backgroundColor: '#e08543',},
        '&:focus': {color: 'white',},
    },
    tabValidated: {
        color: 'white',
        backgroundColor: quoteAccepted,
        '&:hover': {backgroundColor: '#44c59e',},
        '&$tabSelected': {color: 'white', backgroundColor: '#1c9c76',},
        '&:focus': {color: 'white',},
    },
    tabCancelled: {
        color: 'white',
        backgroundColor: quoteRefused,
        '&:hover': {color: 'white', backgroundColor: '#ff5757',},
        '&$tabSelected': {color: 'white', backgroundColor: '#d62e2e'},
        '&:focus': {color: 'white',},
    },
    awaiting: {
        ...tab,
    },
    internalAction: {
        ...tab,
    },
    customerAction: {
        ...tab,
    },
    adminAction: {
        ...tab,
    },
    validated: {
        ...tab,
    },
    cancelled: {
        ...tab,
        margin: "5px 0",
    },

    // Grid style
    cardBody: {
        paddingTop: 0,
    },
    cardHeader: {
        paddingBottom: 0,
    },
    cardHeaderTop: {
        display: "flex",
        justifyContent: "space-between",
    },
    table: {
        marginBottom: "0",
        borderSpacing: "0",
        borderCollapse: "collapse",
    },
    tableHeadCell: {
        ...defaultFont,
        color: primaryColor,
        fontSize: "1em",
        fontWeight: "400!important"
    },
    tableRow: {
        cursor: "pointer",
    },
    tableCell: {
        ...defaultFont,
        lineHeight: "1.42857143",
        verticalAlign: "middle"
    },
    tableCellNoResult: {
        ...defaultFont,
        textAlign: "center",
        fontWeight: "bold",
        verticalAlign: "middle"
    },
    highlight: {
        fontWeight: "bold",
        color: primaryColor,
    },
    pagination: {
        display: "flex",
        justifyContent: "center",
        marginTop: "20px",
    }
});

export default listStyle;
