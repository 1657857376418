import React from 'react'
import {FormattedMessage} from "react-intl";

import {ArrowBackIos} from "@mui/icons-material";

import Button from "components/button/button";

export default function CancelButton({...props}) {
    return (
        <Button
            color={"info"}
            simple
            {...props}
        >
            <ArrowBackIos />
            <FormattedMessage id={"configuration.form.cancel"} />
        </Button>
    )
}
