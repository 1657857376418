import React from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { FormattedDate, FormattedTime } from "react-intl";

// utils
import {capitalize} from "utils/capitalize";

function CustomDate({...props}) {
    const { date, showRelativeDate } = props;

    const millisecondsAgo = Math.abs(Date.now() - new Date(date));
    const minutesAgo = Math.floor(millisecondsAgo / 60000);
    const hoursAgo = Math.round(minutesAgo / 60);
    const daysAgo = Math.round(hoursAgo / 24);

    function renderDay(date) {
        return (
            <div>
                <FormattedDate
                    value={new Date(date)}
                    year={"numeric"}
                    month={"numeric"}
                    day={"numeric"}
                />
            </div>
        )
    }

    function renderTime(date){
        return (
            <div>
                <FormattedTime
                    value={new Date(date)}
                    hour={"numeric"}
                    minute={"numeric"}
                />
            </div>
        )
    }

    function renderDate(date) {
        return (
            <div>
                {renderDay(date)}
                {renderTime(date)}
            </div>
        )
    }

    function renderRelativeDate(date) {
        return capitalize(moment(date).fromNow());
    }

    return (
      showRelativeDate === true && daysAgo < 1 ? (
          renderRelativeDate(date)
      ) : (
          renderDate(date)
      )
    )
}

CustomDate.propTypes = {
    date: PropTypes.string,
    showRelativeDate: PropTypes.bool
};

export default CustomDate;