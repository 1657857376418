import {
    warningCardHeader,
    successCardHeader,
    dangerCardHeader,
    infoCardHeader,
    primaryCardHeader,
} from "../../main";

const cardHeaderStyle = {
    cardHeader: {
        borderRadius: "3px",
        padding: "0.9375rem 1.875rem",
        border: "0",
        marginBottom: "0",
        fontSize: "16px",
        textTransform: "capitalize"
    },
    cardHeaderPlain: {
        marginLeft: "0px",
        marginRight: "0px"
    },
    warningCardHeader,
    successCardHeader,
    dangerCardHeader,
    infoCardHeader,
    primaryCardHeader
};

export default cardHeaderStyle;
