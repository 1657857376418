import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";

// @mui/material components
import {withStyles} from "@mui/styles";

// styles
import cardHeaderStyle from "../../assets/jss/components/card/cardHeaderStyle";

function CardHeader({...props}) {
    const {classes, className, children, color, plain, ...rest} = props;
    const cardHeaderClasses = classNames({
        [classes.cardHeader]: true,
        [classes[color + "CardHeader"]]: color,
        [classes.cardHeaderPlain]: plain,
        [className]: className !== undefined
    });
    return (
        <div className={cardHeaderClasses} {...rest}>
            {children}
        </div>
    );
}

CardHeader.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
    color: PropTypes.oneOf(["warning", "success", "danger", "info", "primary"]),
    plain: PropTypes.bool
};

export default withStyles(cardHeaderStyle)(CardHeader);
