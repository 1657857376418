import React, {useContext} from "react";

// @mui/material components
import {
    DialogContent,
    DialogTitle,
    Dialog
} from "@mui/material";

// core components
import Form from './Form';

// style
import {FormattedMessage} from "react-intl";

// context
import {CancellationReasonListContext} from "contexts/CancellationReasonListContext";

export default function CancellationReasonDialog() {

    const {
        isCancellationReasonDialogOpen,
        closeCancellationReasonDialog,
        selectedCancellationReason,
        setSelectedCancellationReason
    } = useContext(CancellationReasonListContext)

    const titleTranslationId = selectedCancellationReason ?
        'cancellation_reason.form.update.title'
        : 'cancellation_reason.form.create.title'
    ;

    return (
        <Dialog
            open={isCancellationReasonDialogOpen}
            onClose={() => {
                closeCancellationReasonDialog()
                setSelectedCancellationReason(null)
            }}
            maxWidth={'sm'}
            scroll={'body'}
        >
            <DialogTitle>
                <FormattedMessage id={titleTranslationId} />
            </DialogTitle>
            <DialogContent>
                <Form />
            </DialogContent>
        </Dialog>
    )
}
