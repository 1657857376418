import {
    // Add your imported colors below
    grayColor,
    lightBlueColor,
} from "../../main";

const bubbleStyle = theme => ({
    container: {
        display: "inline-flex",
        justifyContent: "center",
        alignItems: "center",
    },
    bubble: {
        display: "inline-flex",
        alignItems: "center",
        borderRadius: "20px",
        padding: "3px 10px",
        fontSize: "12px",
        margin: "2px"
    },
    bubbleLabel: {
        marginRight: "5px",
    },
    bubbleWhite: {
        border: "1px solid",
        borderColor: grayColor,
        height: "20px",
        lineHeight: "12px",

    },
    bubbleDanger: {
        color: "#FFF",
        border: "0px",
        backgroundColor: "#f55a4e"
    },
    bubbleSuccess: {
        color: "#FFF",
        border: "0px",
        backgroundColor: "#5cb860"
    },
    bubbleBlue: {
        backgroundColor: lightBlueColor,
        color: "#fff",
    },
    bubbleListSpacing: {
        marginRight: "5px",
    },
});

export default bubbleStyle; 
