import {fetch} from "utils/dataAccess";

export function loading(loading) {
    return { type: "DICTIONARIES_LOADING", loading };
}

export function success(retrieved) {
    return {type: "DICTIONARIES_SUCCESS", retrieved};
}

export function error(error) {
    return {type: "DICTIONARIES_ERROR", error};
}

export function list(retailerId, types) {
    return dispatch => {
        dispatch(loading(true));
        let url = `/retailers/${retailerId}/dictionaries`;
        if (!!types && Array.isArray(types)) {
            url += '?types=' + types.join(',');
        }

        return fetch(url, {
                method: 'GET',
                headers: new Headers({'Content-Type': 'application/ld+json'}),
            })
            .then(response => {
                response.json().then(dictionaries => {
                    dispatch(loading(false));
                    dispatch(success(dictionaries));
                })
            })
            .catch((e) => {
                dispatch(loading(false));
                dispatch(error(e));
            })
        ;
    };
}
