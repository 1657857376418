import React, {useState, useContext} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import {FormattedMessage} from "react-intl";
import {fetch} from "utils/dataAccess";
import {useSnackbar} from "notistack";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import AutocompleteItemList from 'domain/components/merchandising/batchActions/AutocompleteItemList';
import EditIcon from '@mui/icons-material/Edit';
import {ProductDataContext} from "contexts/productDataContext";
import {getFilterLabel} from "domain/helpers/filterTranslations";

export default function AddCornerActionModal(props) {
    const {open, setOpen, setSelectAction, selectedProducts, selectAll} = props;
    const { enqueueSnackbar } = useSnackbar();
    const { cornersLoaded, cornersFilters } = useContext(ProductDataContext);
    const [selectedCorner, setSelectedCorner] = useState([]);

    const handleAction = () => {
        handleClose();

        let headers = new Headers();
        headers.set('Accept', 'application/json');

        let body = props.buildBody();
        if (selectAll) {
            body = {
                ...body,
                batchAction: 'addCorner',
                batchActionValue: selectedCorner.id,
            };
        } else {
            body = {
                batchAction: 'addCorner',
                batchActionValue: selectedCorner.id,
                ids: selectedProducts
            };
        }

        fetch("/merchandising/batch", {method: "POST", body: JSON.stringify(body), headers})
            .then(response => {
                if (response.ok) {
                    enqueueSnackbar(
                        <FormattedMessage
                            id={"merchandising.batch_action.card.success"}
                        />,
                        {
                            variant: 'success'
                        }
                    );
                } else {
                    enqueueSnackbar(
                        <FormattedMessage
                            id={"merchandising.batch_action.card.error"}
                        />,
                        {
                            variant: 'error'
                        }
                    );
                }
            });
    };

    const handleClose = () => {
        setOpen(false);
        setSelectAction('');
    };

    function buildOptions() {
        const options = cornersFilters.tabContent.items.map(option => ({
            id: option.id,
            label: getFilterLabel(option)
        }));
        return options;
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle
                id="alert-dialog-title"
                sx={{
                    marginX: 'auto'
                }}
            >
                <FormattedMessage id={"merchandising.batch_action.addCorner.modal.title"}/>
            </DialogTitle>
            <Divider />
            <DialogContent>
                {cornersLoaded &&
                    <AutocompleteItemList
                        options={buildOptions()}
                        sx={{ marginTop: '20px', width: '500px'}}
                        setSelectedValue={setSelectedCorner}
                        inputLabel={"merchandising.batch_action.addCorner.modal.label"}
                    />
                }
            </DialogContent>
            <DialogActions sx={{ padding: '15px'}}>
                <Button
                    onClick={handleClose}
                    startIcon={<ArrowBackIosIcon />}
                >
                    <FormattedMessage id={"merchandising.batch_action.modal.cancel"}/>
                </Button>
                <Button
                    onClick={handleAction}
                    variant="contained"
                    autoFocus
                    startIcon={<EditIcon/>}
                    sx={{
                        borderRadius: 28
                    }}
                >
                    <FormattedMessage id={"merchandising.batch_action.addCorner.modal.save"}/>
                </Button>
            </DialogActions>
        </Dialog>
    );
}
