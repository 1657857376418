import React from 'react';
import PropTypes from 'prop-types';

import DefaultContainer from "components/containers/DefaultContainer";
import TopRightLoader from "components/loaders/TopRightLoader";
import CardHeader from "components/card/cardHeader";
import GridContainer from "components/grid/gridContainer";
import GridItem from "components/grid/gridItem";
import CardTitle from "components/card/CardTitle";
import CardBody from "components/card/cardBody";

import Card from "./Card";

Page.propTypes = {
    titleId: PropTypes.string.isRequired,
    subtitleId: PropTypes.string,
    loading: PropTypes.bool,
    fullPage: PropTypes.bool
};

function Page(props) {
    return (
        <DefaultContainer>
            <GridContainer>
                <GridItem xs={12}>
                    <Card fullPage={props.fullPage} noShadow>
                        <TopRightLoader loading={!!props.loading}/>
                        <CardHeader color="info">
                            <GridContainer>
                                <GridItem xs={9} sm={10}>
                                    <CardTitle
                                        titleId={props.titleId}
                                        subtitleId={props.subtitleId}
                                    />
                                </GridItem>
                            </GridContainer>
                        </CardHeader>
                        <CardBody style={{ overflow: "unset" }}>
                            {props.children}
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        </DefaultContainer>
    );
}

export default Page;
