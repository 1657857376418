import React, {Component} from "react";

// @mui/material components
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from "@mui/material";
import Button from "components/button/button";

// styles
import customModalStyle from "assets/jss/views/stockeasy/product/customModalStyle";
import {withStyles} from "@mui/styles";

class CustomModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            open: props.open ?? false,
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.open !== prevProps.open) {
            this.setState({open: this.props.open});
        }
    }

    validateButton = () => {
        if (this.props.validateButtonAction && typeof this.props.validateButtonAction === 'function') {
            this.props.validateButtonAction();
        } else {
            this.handleClose();
        }
    }

    cancelButton = () => {
        if (this.props.cancelButtonAction && typeof this.props.cancelButtonAction === 'function') {
            this.props.cancelButtonAction();
        } else {
            this.handleClose();
        }
    }

    handleClose = () => {
        this.setState({open: false});
    };

    render() {
        const {open} = this.state;
        const {classes, title, contentText, cancelButtonText, validateButtonText, noticeText, icon} = this.props;

        return (
            <Dialog
                className={classes.customModal}
                open={open}
                aria-labelledby="custom-modal-dialog"
                maxWidth={'xs'}
            >
                <DialogTitle id="custom-modal-dialog" className={classes.title}>
                    {title}
                </DialogTitle>
                <DialogContent>
                    {icon &&
                        <div className={classes.icon}>
                            {icon}
                        </div>
                    }
                    {contentText &&
                        <DialogContentText className={classes.contentText}>
                            {contentText}
                        </DialogContentText>
                    }
                </DialogContent>
                {(validateButtonText || cancelButtonText) &&
                    <DialogActions className={classes.actions}>
                    {validateButtonText &&
                        <Button
                            round
                            color={"success"}
                            onClick={this.validateButton}
                        >
                            {validateButtonText}
                        </Button>
                    }
                    {cancelButtonText &&
                        <Button
                            simple
                            color="info"
                            onClick={this.cancelButton}
                            className={classes.cancelButton}
                        >
                            {cancelButtonText}
                        </Button>
                    }
                    </DialogActions>
                }
                {noticeText &&
                    <DialogContent className={classes.notice}>
                        {noticeText}
                    </DialogContent>
                }
            </Dialog>
        );
    }
}

export default (withStyles(customModalStyle)(CustomModal));
