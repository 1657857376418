const notificationStyle = theme => ({
    notificationBody: {
        "@media (max-width: 768px)": {
            padding: "0 1.500rem"
        },
    },
    checkIconContainer: {
        padding: "0px 30px 0px 0px",
    },
    bold: {
        fontWeight: "bold",
        fontSize: 'small'
    },
    secondaryText: {
        color: "grey",
        fontSize: 'small'
    },
    checkCircleIcon: {
        fontSize: 70,
        "@media (max-width: 768px)": {
            fontSize: 50
        },
    }
});

export default notificationStyle;
