import React from "react";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";

import FormattedNumberWithCurrency
    from "components/formattedNumberWithCurrencies/formattedNumberWithCurrency";

import CardStatsTitle from "views/marketplace/dashboard/components/cards/cardStatsTitle";
import CardStatsValue from "views/marketplace/dashboard/components/cards/cardStatsValue";
import CardStatsBody from "views/marketplace/dashboard/components/cards/cardStatsBody";
import CardStats from "views/marketplace/dashboard/components/cards/cardStats";
import Loader from "views/marketplace/dashboard/components/loader";

export default function AverageQuote(props) {
    const {
        currency,
        loading,
        quotesCount,
        quotesTurnover,
    } = props

    const averageCart = ((quotesTurnover / 100) / quotesCount);

    return (
        <CardStats>
            <Loader loading={loading} />
            <CardStatsTitle>
                <FormattedMessage id={"dashboard.quotes.card.title.averageCart"}/>
            </CardStatsTitle>
            <CardStatsBody>
                <CardStatsValue>
                    <FormattedNumberWithCurrency
                        value={averageCart ?? 0}
                        currency={currency}
                    />
                </CardStatsValue>
            </CardStatsBody>
        </CardStats>
    )
}

AverageQuote.propTypes = {
    loading: PropTypes.bool,
    quotesCount: PropTypes.number.isRequired,
    quotesTurnover: PropTypes.number.isRequired
};
