import React from 'react'
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";

import Button from "components/button/button";

import ListLoader from "views/configuration/components/loader";

export default function SubmitButton({loading}) {
    return (
        <Button
            round
            color={"success"}
            type="submit"
        >
            <FormattedMessage id={"feed.form.submit"} />
            {loading && <ListLoader />}
        </Button>
    )
}

SubmitButton.defaultProps = {
    loading: false
}

SubmitButton.propTypes = {
    loading: PropTypes.bool
}
