import React from 'react'
import {makeStyles} from '@mui/styles'

const useStyles = makeStyles(theme => ({
    scrollableSelector: {
        maxHeight: '350px',
        overflow: 'auto',
    },
}))

export default function ScrollableSelector(props) {
    const classes = useStyles()

    return (
        <div className={classes.scrollableSelector}>
            {props.children}
        </div>
    )
}
