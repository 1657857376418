import React, {Component} from "react";
import {connect} from "react-redux";
import {FormattedMessage, injectIntl} from "react-intl";
import {Link} from "react-router-dom";

// @mui/material components
import {Button} from "@mui/material"

// core components
import GridContainer from "../components/grid/gridContainer";
import GridItem from "../components/grid/gridItem";
import Card from "../components/card/card";
import CardHeader from "../components/card/cardHeader";
import CardBody from "../components/card/cardBody";
import Loader from "react-spinners/BeatLoader";

// styles
import logoStripeGrey from "../assets/img/logoStripeGrey.png";
import logoWishibamGrey from "../assets/img/logoWishibamGrey.png";
import squareLogo from "../assets/img/squareLogo.png";
import stripeStyle from "../assets/jss/views/stripeStyle";

// actions
import {confirm} from "actions/retailer/stripe/confirm";
import {withStyles} from "@mui/styles";

class RetailerConfirmStripeInvitation extends Component {
    static propTypes = {
    };

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.confirm({'retailer_id': this.props.match.params.id});
    }

    render() {
        const {classes} = this.props;

        return (
            <GridContainer direction="row" justifyContent="center" alignItems="center" className={classes.containerCard}>
                <GridItem xs={12} className={classes.card}>
                    <Card className={classes.card}>
                        <CardHeader className={classes.cardHeader}>
                            <GridContainer>
                                <GridItem xs={12}>
                                    <img className={classes.logo} src={squareLogo} alt={""}/>
                                    <h3><FormattedMessage id={"retailer.stripe.action.validation"}/></h3>
                                </GridItem>
                            </GridContainer>
                        </CardHeader>
                        <CardBody className={classes.cardBody}>
                            <GridContainer>
                                <GridItem xs={12}>
                                    {this.props.stripeLoading ?
                                        <Button className={classes.button}>
                                            <Loader color={'#FFFFFF'} size={8}/>
                                        </Button>
                                        :
                                        <div>
                                            {(this.props.stripeConfirmed && this.props.stripeConfirmed.success) ?
                                                <div>
                                                    <FormattedMessage id={"retailer.stripe.action.confirm.success"}/><br/>
                                                    <FormattedMessage id={"retailer.stripe.action.confirm.success.detail"}/><br/><br/>
                                                    <Link to={'/logout'} className={classes.link}>
                                                        <Button className={classes.button}>
                                                            <FormattedMessage id={"retailer.stripe.confirm.action.dashboard"}/>
                                                        </Button>
                                                    </Link>
                                                </div>
                                                :
                                                <div>
                                                    <div className={classes.error}><FormattedMessage id={"retailer.stripe.action.confirm.error"}/></div><br/>
                                                    <FormattedMessage id={"retailer.stripe.action.confirm.error.detail"}/><br/><br/>
                                                    <Link to={'/'} className={classes.link}>
                                                        <Button className={classes.button}>
                                                            <FormattedMessage id={"retailer.stripe.confirm.action.back"}/>
                                                        </Button>
                                                    </Link>
                                                </div>
                                            }
                                        </div>
                                    }
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                    </Card>
                    <Link to={'/logout'} className={classes.logoutLink}>
                        <FormattedMessage id={'retailer.stripe.action.logout'}/>
                    </Link><br/>
                    <img className={classes.logoFooter} src={logoStripeGrey} alt={""}/><br/>
                    <img className={classes.logoFooter} src={logoWishibamGrey} alt={""}/>
                </GridItem>
            </GridContainer>

        );
    }
}

const mapStateToProps = state => {

    const stripeConfirmed = state.retailer.confirm.confirmed;
    const stripeLoading = state.retailer.confirm.loading;

    return {
        stripeConfirmed,
        stripeLoading
    };
};

const mapDispatchToProps = dispatch => ({
    confirm: params => dispatch(confirm(params))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(stripeStyle)(injectIntl(RetailerConfirmStripeInvitation)));
