import React from "react";

import Dashboard from "./dashboard";

import {DashboardDateRangePickerContextProvider} from "contexts/dasboardDateRangePickerContext";

export default function Index(props) {
    return (
        <DashboardDateRangePickerContextProvider>
            <Dashboard retailer={props.retailer} />
        </DashboardDateRangePickerContextProvider>
    )
}
