import React, {useContext} from "react";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";

// @mui/material components
import {
    Box,
    MenuItem,
    FormControl,
    Select
} from "@mui/material";

// @mui/icons-material components
import {ExpandMore} from "@mui/icons-material";

// context
import {CancellationReasonContext} from "contexts/CancellationReasonContext";

// style
import {blueColor} from "assets/jss/main";
import {getTranslation} from "../../../../../domain/helpers/translations";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles(theme => ({
    select: {
        borderRadius: '10px',
        padding: '15px',
        background: blueColor,
        color: 'white',
        fontSize: '1rem',
        marginBottom: '10px',
        '& .MuiSelect-icon': {
            color: 'white',
            top: '14px',
            position: 'absolute',
            marginRight: '0.5em',
        }
    },
    span: {
        textAlign: 'center',
    },
    form :{
        '& .MuiInput-underline::after': {
            borderBottom: 'none',
        },
        '& .MuiInput-underline::before': {
            borderBottom: 'none',
        },
        '& .MuiInput-underline:hover:not(.Mui-disabled)::before': {
            borderBottom: 'none',
        },
    },
    placeholder: {
        display: 'none',
    },
}));

export default function CancellationReasonSelect(props) {
    const classes = useStyles();

    const { cancellationReasons } = props;

    const {
        selectedReason,
        setReason
    } = useContext(CancellationReasonContext);

    const value = selectedReason ? selectedReason : ""

    return (
        <Box sx={{ minWidth: 120 }}>
            <FormControl variant="standard" fullWidth className={classes.form}>
                {cancellationReasons.length === 0 ? (
                    <span className={classes.span}>
                        <FormattedMessage id={"picking.order.show.cancellationReason.dialog.zero_action"} />
                    </span>
                ) : (
                    <Select
                        variant="standard"
                        value={value}
                        displayEmpty={true}
                        onChange={(event) => setReason(event.target.value)}
                        disabled={!cancellationReasons.length}
                        className={classes.select}
                        IconComponent={ExpandMore}
                    >
                        <MenuItem value="" disabled className={classes.placeholder}>
                            <FormattedMessage id={"picking.order.show.cancellationReason.dialog.placeholder"} />
                        </MenuItem>
                        {cancellationReasons.map((reason, key) => (
                            <MenuItem value={reason} key={key}>
                                {getTranslation(reason).name}
                            </MenuItem>
                        ))}
                    </Select>
                )}
            </FormControl>
        </Box>
    );
}

CancellationReasonSelect.defaultProps = {
    cancellationReasons: []
};

CancellationReasonSelect.propTypes = {
    isDialogOpen: PropTypes.array
};
