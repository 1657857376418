import React from "react";
import PropTypes from "prop-types";

import {FormattedMessage} from "react-intl";

import FormattedNumberWithCurrency
    from "components/formattedNumberWithCurrencies/formattedNumberWithCurrency";

import Loader from "views/marketplace/dashboard/components/loader";
import CardStatsTitle from "views/marketplace/dashboard/components/cards/cardStatsTitle";
import CardStatsValue from "views/marketplace/dashboard/components/cards/cardStatsValue";
import CardStatsBody from "views/marketplace/dashboard/components/cards/cardStatsBody";
import CardStats from "views/marketplace/dashboard/components/cards/cardStats";

export default function Turnover(props) {
    const { currency, loading, quotesTurnover } = props

    return (
        <CardStats>
            <Loader loading={loading} />
            <CardStatsTitle>
                <FormattedMessage id={"dashboard.quotes.card.title.turnover"} />
            </CardStatsTitle>
            <CardStatsBody>
                <CardStatsValue>
                    <FormattedNumberWithCurrency
                        value={quotesTurnover ? quotesTurnover / 100 : 0}
                        currency={currency}
                    />
                </CardStatsValue>
            </CardStatsBody>
        </CardStats>
    )
}

Turnover.propTypes = {
    currency: PropTypes.string,
    loading: PropTypes.bool,
    quotesTurnover: PropTypes.number.isRequired,
};
