import React from "react";
import {Route} from "react-router-dom";
import NoRequireAuthentication from "../hoc/authentication/noRequireAuthentication"
import AuthenticationAcceptInvitationPage from "../views/authentication/invitation/acceptInvitation";
import AuthenticationPageTemplate from "../views/authentication/pageTemplate";

let routes = [];
routes.push([
  <Route path="/authentication/accept-invitation/:token" component={NoRequireAuthentication(AuthenticationAcceptInvitationPage, AuthenticationPageTemplate)} exact={true} key="logout"/>,
]);

export default routes;
