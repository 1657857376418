import React from 'react';
import GridContainer from "components/grid/gridContainer";

function FormContainer(props) {
    return (
        <GridContainer sx={{ paddingBottom: `${window.innerHeight * 0.1}px` }}>
            {props.children}
        </GridContainer>
    );
}

export default FormContainer;
