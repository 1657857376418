import React from 'react';
import {FormattedMessage} from 'react-intl';
import {styled} from '@mui/material/styles';
import Button from '@mui/material/Button';
import {ReactComponent as Message} from 'assets/img/message.svg';
import {borderGrayColor, fifteenthGrey} from 'assets/jss/main';

const StyledButton = styled(Button)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
    borderRadius: '8px',
    textTransform: 'none',
    padding: '12px 16px',
    background: 'linear-gradient(0deg, #F2F2F2 0%, #F2F2F2 100%), #F2F2F2',
    color: fifteenthGrey,
    textAlign: 'center',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '14px',
    letterSpacing: '0.5px',
    '&:hover': {
        background: borderGrayColor,
    },
})

export default function AddInternalNoteButton({...props}) {
    return (
        <StyledButton
            {...props}
        >
            <Message />
            <FormattedMessage id={'picking.quote.detail.addInternalNoteModal.button'} />
        </StyledButton>
    );
}
