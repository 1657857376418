import {
    warningColor,
    primaryColor,
    infoColor,
    grayColor,
    defaultFont,
    lightGrayColor,
    yellowColor,
    irisBlueColor,
    terraCottaColor,
    mantisGreenColor,
    thunderGrayColor,
    elevenGrey,
} from "../../../main";

const status = {
    padding: "5px 25px 5px 25px",
    borderRadius: "20px",
    textAlign: "center",
    maxHeight: "32px",
    whiteSpace: "nowrap",
    fontWeight: "400",
    fontSize: "16px"
};

const tableStyle = theme => ({
    primaryTableHeader: {
        color: primaryColor
    },
    hidden: {
        display: "none"
    },
    dialogClose: {
        marginRight: "30px",
        marginBottom: "30px"
    },
    table: {
        marginBottom: "0",
        borderSpacing: "0",
        borderCollapse: "collapse",
    },
    tableHead: {
        background: elevenGrey,
        color: '#FFFFFF',
        fontSize: '14px',
        fontWeight: 700,
    },
    tableHeadCellXl: {
        ...defaultFont,
        color: '#000',
        fontWeight: '700!important',
        fontSize: "14px",
    },
    tableHeadCell: {
        ...defaultFont,
        lineHeight: "1.42857143",
        verticalAlign: "middle",
        padding: "23px 30px 23px 20px !important",
        color: '#000',
    },
    tableCell: {
        ...defaultFont,
        lineHeight: "1.42857143",
        verticalAlign: "middle",
        borderBottom: "none",
        borderTop: "1px solid rgba(224, 224, 224, 1)",
        "&:first-child": {
            borderTop: "none",
        },
    },
    checkbox: {
        padding: 0,
        textAlign: 'right',
    },
    adjustment: {
        borderTop: "1px solid #f5f5f5",
        borderBottom: "none",
        fontWeight: 'bold',
    },
    adjustmentTotal: {
        borderTop: "1px solid #f5f5f5",
        borderBottom: "none",
        fontSize: "16px",
    },
    tableCellCenter: {
        ...defaultFont,
        lineHeight: "1.42857143",
        verticalAlign: "middle",
        textAlign: "center",
    },
    tableCellNoResult: {
        ...defaultFont,
        textAlign: "center",
        fontWeight: "bold",
        verticalAlign: "middle"
    },
    tableCellBold: {
        fontSize: "16px",
        fontWeight: "bold",
        whiteSpace: "nowrap",
    },
    tableImageCell: {
        padding: "10px 0px !important",
        position: 'relative',
        '& span': {
            position: 'absolute',
            right: '-13px',
            top: '14px',
            backgroundColor: 'white',
            zIndex: 1,
        },
    },
    tableCellColumn: {
        display: "flex",
        flexDirection: "column",
    },
    tableCellRunner:{
        ...defaultFont,
        lineHeight: "1.42857143",
        verticalAlign: "middle",
        minWidth: "85px"
    },
    tableCellStatus: {
        textAlign: "center",
    },
    tableResponsive: {
        width: "100%",
        overflowX: "auto",
    },
    beatLoader: {
        position: "absolute",
        right: "30px",
        margin: "0 auto",
        top: "15px",
        display: "flex",
        justifyContent: "center"
    },
    propagateLoader: {
        position: "absolute",
        left: "0",
        right: "0",
        margin: "0 auto",
        top: "58%",
        display: "flex",
        justifyContent: "center"
    },
    pickup: {
        color: "#dd9eeb",
        border: "1px solid #dd9eeb",
        borderRadius: "50%",
        padding: "5px",
        fontSize: "15px",
        fontWeight: "700",
        width: "40px",
        height: "40px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    productImage: {
        verticalAlign: "middle",
        width: "80px",
        height: "80px",
        border: `1px solid ${lightGrayColor}`,
    },
    name: {
        minWidth: "200px",
    },
    crossedPrice: {
        textDecoration: "line-through",
        whiteSpace: "nowrap",
    },
    quantityInput: {
        fontWeight: "400",
        fontSize: "16px",
        width: "70px",
    },
    img: {
        width: "50px",
        height: "50px",
        borderRadius: "50%",
        boxSizing: "border-box",
        padding: "0px",
        boxShadow: " 5px 5px 10px -7px rgba(0,0,0,0.96)",
    },
    imgPlaceholder: {
        background: "linear-gradient(60deg, #377dff, #0052cb)",
        color: "#FFFFFF",
        padding: "12px",
        borderRadius: "50%",
        width: "50px",
        height: "50px",
        fontWeight: "bold"
    },
    pending: {
        ...status,
        backgroundColor: "rgba(230, 194, 41, 0.2)",
        color: yellowColor,
    },
    toShip: {
        ...status,
        backgroundColor: "rgba(0, 172, 193, 0.2)",
        color: irisBlueColor,
    },
    reserved: {
        ...status,
        backgroundColor: "rgba(105, 41, 230, 0.2)",
        color: '#976ef5',
    },
    canceled: {
        ...status,
        backgroundColor: "rgba(237, 106, 90, 0.2)",
        color: terraCottaColor,
    },
    confirmed: {
        ...status,
        backgroundColor: "rgba(120, 188, 97, 0.2)",
        color: mantisGreenColor
    },
    unavailable: {
        ...status,
        backgroundColor: "rgba(74, 74, 73, 0.2)",
        color: thunderGrayColor,
    },
    refunded: {
        ...status,
        backgroundColor: "rgba(230, 81, 0, 0.2)",
        color: "#ff833a",
    },
    returned: {
        ...status,
        backgroundColor: "#FDDDD2",
        color: "#FF823C",
    },
    returning: {
        ...status,
        backgroundColor: '#FFE6C9',
        color: '#FF8E5E',
    },
    listReturning: {
        ...status,
        backgroundColor: '#FFE6C9',
        color: '#FF8E5E',
        maxWidth: "fit-content",
        margin: "auto",
    },
    listReserved: {
        ...status,
        backgroundColor: "rgba(105, 41, 230, 0.2)",
        color: '#976ef5',
        maxWidth: "fit-content",
        margin: "auto",
    },
    listPending: {
        ...status,
        backgroundColor: "rgba(230, 194, 41, 0.2)",
        color: yellowColor,
        maxWidth: "fit-content",
        margin: "auto",
    },
    listToShip: {
        ...status,
        backgroundColor: "rgba(0, 172, 193, 0.2)",
        color: irisBlueColor,
        maxWidth: "fit-content",
        margin: "auto",
    },
    returningDate: {
        textAlign: "center",
        fontSize: ".9em",
        marginTop: "10px",
    },
    statusGroup: {
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
    },
    returningBadge: {
        '& .MuiBadge-badge': {
            border: "3px solid white",
            borderRadius: "50%",
            width: '25px',
            height: '25px',
            padding: '0',
        }
    },
    cancellationReason: {
        display: 'block',
        color: '#4B4B4D',
        lineHeight: '2em',
    },
    actionsCell: {
        display: "flex",
        justifyContent: "space-around",
        width: "136px",
        float: "right"
    },
    validationItemQuantity: {
      margin: '27px 0 0 20px',
    },
    validateAction: {
        color: "#FFFFFF",
        fontSize: "40px",
    },
    cancelAction: {
        color: "#FFFFFF",
        fontSize: "40px",
    },
    toolTipNa: {
        color: warningColor,
        cursor: "pointer",
        fontSize: "20px",
        marginLeft: "7px"
    },
    iconButton: {
        width: "15px",
        borderRadius: "5px",
    },
    typography: {
        padding: theme.spacing(2),
        cursor: "pointer",
        "&:hover": {
            background: primaryColor,
            color: "white",
        },
    },
    fakeMoreList: {
        backgroundColor: grayColor,
        height: theme.spacing(1),
        margin: theme.spacing(2),
        // Selects every two elements among any group of siblings.
        "&:nth-child(2n)": {
            marginRight: theme.spacing(3),
        },
    },
    moreListepaper: {
        position: "absolute",
        top: 262,
        width: 141,
        right: 0
    },
    unavailableButton: {
        width: '100%',
        backgroundColor: '#FFFFFF',
        textTransform: 'none !important'
    },
    moreButton: {
        width: "0px",
        minWidth: "0px"
    },
    assign: {
        borderRadius: "20px",
        backgroundColor: infoColor,
        textTransform: "none",
        width: "116px",
        marginRight: "25px",
        "& > span:first-child": {
            display: "initial"
        }
    },
    externalIdColor: {
        color: primaryColor,
        cursor: 'pointer'
    },
    submitLoading: {
        marginLeft: "5px",
        color: "#FFFFFF"
    },
    retailerLogo: {
        maxWidth: 80,
    }
});


export default tableStyle;
