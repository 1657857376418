import React, {Component} from "react";
import {Link} from 'react-router-dom'
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import {connect} from "react-redux";

// @mui/material components
import {withStyles} from "@mui/styles";
import {
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell
} from "@mui/material";

// styles
import rankingStyle from "assets/jss/views/dashboard/rankingStyle";

// helpers
import {capitalize} from "utils/capitalize";
import FormattedNumberWithCurrency from "components/formattedNumberWithCurrencies/formattedNumberWithCurrency";

class RankingTable extends Component {
    render() {
        const {classes, tableHead, tableData, analytics} = this.props;

        return (
            <div className={classes.tableResponsive}>
                <Table className={classes.table}>
                    {tableHead !== undefined ? (
                        <TableHead>
                            <TableRow>
                                {tableHead.map((prop, key) => {
                                    return (
                                        <TableCell
                                            className={classes.tableCell + " " + classes.tableHeadCell}
                                            key={key}
                                        >
                                            {prop}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        </TableHead>
                    ) : null}
                    {tableData &&
                        <TableBody>
                            {Object.entries(tableData).length > 0 ?
                                Object.keys(tableData).map((key) => {
                                    return (
                                        <TableRow key={key}>
                                            <TableCell className={classes.tableCell}>
                                                <div>
                                                    <Link
                                                        to={`/retailers/show/${encodeURIComponent(tableData[key]['retailerId'])}`}
                                                        target='_blank'
                                                    >
                                                        {capitalize(tableData[key]['retailerName'])}
                                                    </Link>
                                                </div>
                                            </TableCell>
                                            <TableCell className={classes.tableCell}>
                                                <div><FormattedNumberWithCurrency value={tableData[key]['turnover']} currency={this.props.currentOrganization?.currency} /></div>
                                            </TableCell>
                                            <TableCell className={classes.tableCell}>
                                                <div><FormattedNumberWithCurrency value={tableData[key]['onlineTurnover']} currency={this.props.currentOrganization?.currency} /></div>
                                            </TableCell>
                                            <TableCell className={classes.tableCell}>
                                                <div><FormattedNumberWithCurrency value={(tableData[key]['turnover'] / tableData[key]['retailerSurface'])} currency={this.props.currentOrganization?.currency} /></div>
                                            </TableCell>
                                            <TableCell className={classes.tableCell}>
                                                <div>{tableData[key]['unavailabilityPerCommand']} %</div>
                                            </TableCell>
                                            <TableCell className={classes.tableCell}>
                                                <div>{tableData[key]['returnPerCommand']} %</div>
                                            </TableCell>
                                        </TableRow>
                                    );
                                }) :
                                <TableRow>
                                    <TableCell colSpan={analytics && analytics !== false? 7 : 6} className={classes.tableCellNoResult}><FormattedMessage
                                        id={"dashboard.retailersRanking.table.no_result"}/></TableCell>
                                </TableRow>
                            }
                        </TableBody>
                        }
                </Table>
            </div>
        );
    }
}

RankingTable.propTypes = {
    classes: PropTypes.object.isRequired,
    tableHead: PropTypes.arrayOf(PropTypes.object),
    tableData: PropTypes.array
};

const mapStateToProps = state => ({
    currentOrganization: state.currentOrganization.retrieved ?? null,
});

export default connect(
    mapStateToProps,
    null
)(withStyles(rankingStyle)(RankingTable));
