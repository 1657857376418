import React from "react";
import { injectIntl } from "react-intl";

// core components
import FormikInput from "components/input/formikInput";
import GridItem from "components/grid/gridItem";
import FormTranslator from "components/translator/formTranslator";
import {makeStyles} from "@mui/styles";
import {dangerColor} from "assets/jss/main";

const useStyles = makeStyles(theme  => ({
    fieldError: {
        color: dangerColor
    },
}));

function TranslationForm(props) {
    const { intl } = props;

    const classes = useStyles();

    const renderTranslationForm = (index, code) => (
        <div>
            <GridItem xs={12} sm={12}>
                <FormikInput
                    labelText={intl.formatMessage({id: "cancellation_reason.form.label.name"})}
                    fieldError={classes.fieldError}
                    inputProps={
                        {
                            name: `translations.${code}.name`,
                            type:"text",
                            placeholder: intl.formatMessage({id: "cancellation_reason.form.label.name"}),
                        }
                    }
                />
            </GridItem>
        </div>
    );

    return <FormTranslator formGenerator={renderTranslationForm} />
}

export default injectIntl(TranslationForm);
