import React, {useEffect, useRef} from "react";
import { injectIntl } from "react-intl";
import PropTypes from "prop-types";
import {useFormikContext} from "formik";

// core components
import FormikInput from "components/input/formikInput";

// utils
import {getTransitionsThatCanApplyCancellationReasonByStatus} from "utils/lineItemTransitions";

function TransitionField(props) {
    const { intl } = props;

    const formikContext = useFormikContext();

    function usePreviousValue(value) {
        const ref = useRef();

        useEffect(() => {
            ref.current = value;
        });

        return ref.current;
    }

    const previousValue = usePreviousValue(formikContext.values.status);

    const transitionOptions = (status) => (
        getTransitionsThatCanApplyCancellationReasonByStatus(status).map((transition, key) => (
            <option value={transition} key={key}>
                {intl.formatMessage({id: "cancellation_reason.table.row.transition." + transition})}
            </option>
        ))
    );

    useEffect(() => {
        if (previousValue !== undefined) {
            formikContext.setFieldValue(
                'transition',
                getTransitionsThatCanApplyCancellationReasonByStatus(formikContext.values.status)[0]
            )
        }
    }, [formikContext.values.status])

    return (
        <FormikInput
            labelTranslationId={'cancellation_reason.form.label.transition'}
            inputProps={
                {
                    name: 'transition',
                    as: 'select',
                    children: transitionOptions(formikContext.values.status),
                    onChange: (e) => {
                        formikContext.handleChange(e)
                        formikContext.setFieldValue('transition', e.target.value)
                    }
                }
            }
        />
    );
}

TransitionField.propTypes = {
    status: PropTypes.string
};

export default injectIntl(TransitionField);
