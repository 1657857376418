import React, {Component} from "react";

// components
import {Chip, Tooltip, } from "@mui/material";
import {withStyles} from "@mui/styles";

// styles
import VariationChipStyle from "assets/jss/components/chip/VariationChipStyle";

class VariationChip extends Component {
    constructor(props) {
        super(props);

        this.state = {
            variant: this.getVariant(this.props.selected)
        }
    }

    getVariant = (selected) => {
        let result;
        selected ? result = "default" :result = "outlined";
        return result;
    }

    render() {
        const {label, classes, selected} = this.props;

        return (
            <Tooltip title={this.props.tooltip}>
                <Chip
                    label={label}
                    onClick={this.props.onClick}
                    variant={this.getVariant(selected)}
                    color="primary"
                    className={classes.root}
                />
            </Tooltip>
        )
    }
}

export default withStyles(VariationChipStyle)(VariationChip);
