import React from "react";
import {withStyles} from "@mui/styles";

import List from "./components/list";
import listStyle from "assets/jss/views/configuration/defaultListStyle";

function Corners (props) {
    const { classes } = props;

    return (
        <div className={classes.containerWithSidebar}>
            <div className={classes.container}>
                <List/>
            </div>
        </div>
    );
}

export default withStyles(listStyle)(Corners);
