import React from 'react';

import CardContent from "@mui/material/CardContent";

function NoteCardContent(props) {
    return (
        <CardContent sx={{
            display: 'flex',
            flexDirection: 'column',
            padding: 0
        }}>
            {props.children}
        </CardContent>
    );
}

export default NoteCardContent;
