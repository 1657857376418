import {fetch, normalize} from "utils/dataAccess";
import normalizeTranslations from "../translations";

export function error(error) {
    return { type: "CATEGORY_UPDATE_ERROR", error };
}

export function loading(loading) {
    return { type: "CATEGORY_UPDATE_LOADING", loading };
}

export function success(updated) {
    return { type: "CATEGORY_UPDATE_SUCCESS", updated };
}

export const update = (item, values) => dispatch => {
    dispatch(loading(true));
    delete values.eshopId;
    values = normalizeTranslations(values);

    // Normalization messes up types
    let normalizedValues = normalize(values);
    
    if (normalizedValues.tagPriority) {
        normalizedValues.tagPriority = parseInt(normalizedValues.tagPriority);
    }

    return fetch(item["@id"], {
            method: 'PUT',
            headers: new Headers({'Content-Type': 'application/ld+json'}),
            body: JSON.stringify(normalizedValues)
        })
        .then(response => {
            response.json().then(retrieved => {
                dispatch(loading(false));
                dispatch(success(retrieved));
            })
        })
        .catch((e) => {
            dispatch(loading(false));
            dispatch(error(e));
        });
};
