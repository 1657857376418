import React, {useContext} from 'react';
import PropTypes from 'prop-types';

import IconButton from "components/button/IconButton";
import GalleryAddIcon from "components/icons/GalleryAddIcon";
import TrashIcon from "components/icons/TrashIcon";

import Image from "./components/Image";
import ActionsContainer from "./components/ActionsContainer";
import PreviewContainer from "./components/PreviewContainer";
import Loading from "../Loading";

import {ImageDropFieldContext} from "../../context/ImageDropFieldContext";

Index.propTypes = {
    imgSrc: PropTypes.string.isRequired
};

function Index({imgSrc}) {
    const {inputProps, importLoading, onDelete, openFileSelector, disabled} = useContext(ImageDropFieldContext);

    return (
        <PreviewContainer>
            <Image src={imgSrc} />
            {!importLoading && (
                <ActionsContainer>
                    <input {...inputProps} />
                    <IconButton disabled={disabled} onClick={openFileSelector}>
                        <GalleryAddIcon />
                    </IconButton>
                    <IconButton disabled={disabled} onClick={(e) => {
                        e.stopPropagation();
                        onDelete();
                    }}>
                        <TrashIcon />
                    </IconButton>
                </ActionsContainer>
            )}
            <Loading loading={importLoading} />
        </PreviewContainer>
    );
}

export default Index;
