import {combineReducers} from "redux"

export function loading(state = false, action) {
    switch (action.type) {
        case "UPLOAD_LOADING":
            return action.loading;

        default:
            return state;
    }
}

export function error(state = false, action) {
    switch (action.type) {
        case "UPLOAD_ERROR":
            return action.error;

        default:
            return state;
    }
}

export function success(state = null, action) {
    switch (action.type) {
        case "UPLOAD_SUCCESS":
            return action.success;

        default:
            return state;
    }
}

export function removeSuccess(state = null, action) {
    switch (action.type) {
        case "REMOVE_UPLOAD_SUCCESS":
            return action.removeSuccess;

        default:
            return state;
    }
}

export default combineReducers({loading, error, success, removeSuccess});
