import React from "react";

import {makeStyles} from "@mui/styles";

import CardFooter from "components/card/cardFooter";

const useStyles = makeStyles(theme => ({
    cardStatsLegend: {
        justifyContent: "center",
        alignItems: "center",

        "& div": {
            margin: "0px 5px"
        }
    },
}));

export default function CardStatsGraphLegend({...props}) {
    const classes = useStyles();

    return (
        <CardFooter className={classes.cardStatsLegend}>
            {props.children}
        </CardFooter>
    )
}
