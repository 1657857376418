import React from 'react';
import IconContainer from "./IconContainer";

import {ReactComponent as InternalNoteSvgIcon} from "assets/img/internalNote.svg";

export default function InternalNoteIcon () {
    return (
        <IconContainer>
            <InternalNoteSvgIcon width={16} height={16}/>
        </IconContainer>
    )
}
