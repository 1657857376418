import {combineReducers} from "redux";

export function exportLoading(state = false, action) {
    switch (action.type) {
        case "RETAILER_EXPORT_LOADING":
            return action.exportLoading;

        default:
            return state;
    }
}

export function importError(state = null, action) {
    switch (action.type) {
        case "RETAILER_IMPORT_ERROR":
            return action.importError;

        default:
            return state;
    }
}

export function importLoading(state = false, action) {
    switch (action.type) {
        case "RETAILER_IMPORT_LOADING":
            return action.importLoading;

        default:
            return state;
    }
}

export function imported(state = null, action) {
    switch (action.type) {
        case "RETAILER_IMPORT_SUCCESS":
            return action.imported;

        default:
            return state;
    }
}

export default combineReducers({
    exportLoading,
    importError,
    importLoading,
    imported,
});
