import React, {useContext, useEffect, useRef} from "react";
import { injectIntl } from "react-intl";
import {useFormikContext} from "formik";
import PropTypes from "prop-types";

// core components
import FormikInput from "components/input/formikInput";

// utils
import {CancellationReasonListContext} from "contexts/CancellationReasonListContext";
import {DefaultCancellationReasonEmailTemplates} from "utils/DefaultCancellationReasonEmailTemplates";

function EmailTemplateField(props) {
    const { intl } = props;

    const { cancellationReasons } = useContext(CancellationReasonListContext)

    const formikContext = useFormikContext();

    function usePreviousValue(value) {
        const ref = useRef();

        useEffect(() => {
            ref.current = value;
        });

        return ref.current;
    }

    const previousValue = usePreviousValue(formikContext.values.transition);

    useEffect(() => {
        if (previousValue !== undefined) {
            const templates = getEmailTemplateByTransition(formikContext.values.transition);

            formikContext.setFieldValue(
                'emailTemplate',
                templates.length > 0 ? templates[0] : ''
            )
        }
    }, [formikContext.values.transition])

    const getEmailTemplateByTransition = (transition) => {
        let templates = getDefaultTemplateByTransition(transition);

        cancellationReasons
            .filter((reason) => reason.emailTemplate !== '' && reason.transition === transition)
            .forEach((reason) => {
                if (!templates.includes(reason.emailTemplate)) {
                    templates.push(reason.emailTemplate);
                }
            })
        ;

        return templates;
    };

    function getDefaultTemplateByTransition(transition) {
        for (let key in DefaultCancellationReasonEmailTemplates) {
            if (key === transition) {
                return DefaultCancellationReasonEmailTemplates[key]
            }
        }

        return []
    }

    function formatOptions(options) {
        const formattedOptions = [];

        options.forEach((name) => {
            formattedOptions.push({
                'value': name, 'label': name
            });
        })

        return formattedOptions
    }

    return (
        <FormikInput
            labelTranslationId={'cancellation_reason.form.label.emailTemplate'}
            inputProps={
                {
                    name: 'emailTemplate',
                    children: formatOptions(getEmailTemplateByTransition(formikContext.values.transition)),
                    type: 'creatableSelect',
                    placeholder: intl.formatMessage({id: 'cancellation_reason.form.field.emailTemplate.placeholder'}),
                }
            }
        />
    );
}

EmailTemplateField.propTypes = {
    transition: PropTypes.string
};

EmailTemplateField.defaultProps = {
    transition: ''
}

export default injectIntl(EmailTemplateField);
