import {
    container,
    defaultFont,
    dangerColor,
    successColor
} from "../main";

const icon = {
    verticalAlign: "bottom",
    marginRight: "5px"
};
const signingStyle = theme => ({
    container: {
        ...container,
        ...defaultFont,
        marginTop: "30px",
        textAlign:" center",
        maxWidth: "550px !important",
    },
    card:{
        padding: "30px 0px",
        marginTop: 0,
    },
    cardError:{
        padding: "10px 0px",
        borderLeft: `6px solid ${dangerColor}`,
    },
    success: {
        padding: "10px",
    },
    error:{
        textAlign: "left",
        marginLeft: "10px",
        fontWeight: "bold",
    },
    successIcon:{
        ...icon,
        color: successColor,

    },
    errorIcon: {
        ...icon,
        color: dangerColor,
    },
    successOrganizationIcon: {
        position: "absolute",
        margin: "7px 0px 0px 5px",
        color: successColor,
    },
    title: {
        fontSize: "1.50em",
        fontWeight: "bold",
        padding:"10px"
    },
    subtitle:{
        ...defaultFont,
    },
    domain:{
        padding: "0px !important",
        textAlign: "right",
    },
    textfield:{
        "& .MuiInputBase-input": {
            textAlign: "right",
            padding: "0.75em"
        },
        verticalAlign: "inherit",
        textAlign:"right",
    },
    host: {
        fontWeight: "bold",
        fontSize: "1.25em",
        textAlign:"left",
        padding: "0px !important"
    },
});

export default signingStyle;
