import React, { useEffect, useState } from "react";
import connect from "react-redux/es/connect/connect";
import {Redirect} from "react-router-dom";
import { FormattedMessage } from "react-intl";

// @mui/material components
import { Tooltip, Fab } from "@mui/material";
import {withStyles} from "@mui/styles";

// @mui/icons-material
import { AddRounded } from "@mui/icons-material";

// core components
import Card from 'components/card/card';
import CardBody from 'components/card/cardBody';
import CardHeader from 'components/card/cardHeader';
import GridContainer from 'components/grid/gridContainer';
import GridItem from 'components/grid/gridItem';

// View components
import ListLoader from '../../configuration/components/listLoader';
import Notification from "./notification";
import Search from '../../configuration/components/search';
import TableHead from './tableHead';
import TableBody  from "./tableBody";
import CornerDialog from "./cornerDialog";

// styles
import listStyle from "assets/jss/views/configuration/defaultListStyle";

// actions
import { cornerList } from "api/corner/list";

// utils
import {Authorizations, hasAuthorization} from "../../../utils/authorizations";

function List (props) {
    const {
        classes,
        authorizations,
    } = props;

    const [showNotification, setShowNotification] = useState(false);
    const [search, setSearch] = useState('');
    const [corners, setCorners] = useState(null);
    const [filteredCorners, setFilteredCorners] = useState(null);
    const [loading, setLoading] = useState(false);
    const [createDialog, showCreateDialog] = useState(false);


    useEffect(() => {
        loadCorners();
    }, []);


    const loadCorners = () => {
        setLoading( true);
        setFilteredCorners(null);
        cornerList('')
            .then(cornersRetrieved => setCorners(cornersRetrieved))
            .finally(() => setLoading(false))
        ;
    }

    if (false === hasAuthorization(authorizations, Authorizations.MARKETPLACE_CATEGORY_MANAGEMENT)) {
        return <Redirect to={'/'} />;
    }

    return (
        <div>
            <Notification isOpen={showNotification} closeNotification={() => setShowNotification(false)} />
            <GridContainer>
                <GridItem xs={12}>
                    <Card className={classes.card}>
                        <CardHeader color="info">
                            <GridContainer>
                                <GridItem xs={9} sm={10}>
                                    <h3>
                                        <FormattedMessage id={"corner.title"} />
                                    </h3>
                                </GridItem>
                                <GridItem xs={3} sm={2} className={classes.mainActionsContainer}>
                                    <Fab onClick={() => showCreateDialog(true)} color={"primary"}>
                                        <Tooltip title={<FormattedMessage id={"corner.add"} />}>
                                            <AddRounded fontSize={"large"} />
                                        </Tooltip>
                                    </Fab>
                                </GridItem>
                            </GridContainer>
                        </CardHeader>
                        <CardBody>
                            <div className={classes.subTableContainer}>
                                <Search
                                    search={search} setSearch={(userSearch) => {
                                        setSearch(userSearch);

                                        userSearch = userSearch.toLowerCase();
                                        if ('' === userSearch) {
                                            setFilteredCorners(corners);
                                            return;
                                        }
                                        setFilteredCorners(corners.filter(corner => {
                                            for (const [,translation] of Object.entries(corner.translations)) {
                                                if (translation.name.toLowerCase().includes(userSearch)) {
                                                    return true;
                                                }
                                                if (translation.description && translation.description.toLowerCase().includes(userSearch)) {
                                                    return true;
                                                }
                                            }
                                            return false;
                                        }))
                                    }}
                                    attributeName={'corner'}
                                />
                            </div>
                            <div className={classes.tableWrapper}>
                                {(!corners || loading) && <ListLoader loading={loading} />}
                                <div>
                                    <TableHead/>
                                    <TableBody
                                        retrieved={filteredCorners || corners}
                                        needReload={() => loadCorners()}
                                    />
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
            <CornerDialog
                closeDialog={() => showCreateDialog(false)}
                isOpen={createDialog}
                needReload={() => loadCorners()}
            />
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        authorizations: state.authentication.authorizations,
    };
};

export default connect(mapStateToProps)(withStyles(listStyle)(List));
