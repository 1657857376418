import React from "react";
import {ListItem, ListItemIcon, ListItemText} from "@mui/material";
import {ImportExport} from "@mui/icons-material";
import {FormattedMessage} from "react-intl";
import {Authorizations, hasAuthorization} from "../../../utils/authorizations";
import {Link} from "react-router-dom";

export default function DrawerMerchandising(props) {
    const isSelected = window.location.pathname === (props.path ?? '/merchandising/products');
    const classes = props.classes;
    const authorizations = props.authorizations;

    if (hasAuthorization(authorizations, Authorizations.MARKETPLACE_MERCHANDISING)) {
        return (
            <ListItem
                button
                className={classes.listItem}
                classes={{selected: classes.selected}}
                component={Link}
                to={`/merchandising/products`}
                key={"merchandisingProducts"}
            >
                <ListItemIcon>
                    <ImportExport color={isSelected ? "primary" : "secondary"}/>
                </ListItemIcon>
                <ListItemText
                    primaryTypographyProps={{color: isSelected ? "primary" : "secondary"}}
                    primary={<FormattedMessage id={"sidebar.item.merchandising"}/>}
                />
            </ListItem>
        )
    }

    return null;
}
