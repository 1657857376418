import React, {useContext, useEffect} from "react";
import connect from "react-redux/es/connect/connect";
import {FormattedMessage} from "react-intl";

import {makeStyles} from "@mui/styles";

import CardStatsBody from "../../dashboard/components/cards/cardStatsBody";
import CardStats from "../../dashboard/components/cards/cardStats";
import Loader from "../../dashboard/components/loader";
import RankingTable from "./rankingTable";

import {retrieveProductsRankingData} from "actions/dashboard";

import {primaryColor, thunderGrayColor} from "assets/jss/main";

import {DashboardDateRangePickerContext} from "../../../../contexts/dasboardDateRangePickerContext";

const useStyles = makeStyles(theme => ({
    tableHead: {
        display: "flex",
        cursor: "pointer",
        color: thunderGrayColor,
        "& span": {
            paddingRight: "5px"
        }
    },
    tableHeadSelected: {
        display: "flex",
        color: primaryColor,
        "& span": {
            paddingRight: "5px"
        }
    },
}));

function ProductsRanking(props) {
    const classes = useStyles();

    const {
        dashboardProductsRankingData,
        dashboardProductsRankingDataLoading,
        retailers,
        maxResult
    } = props


    const { startDate, endDate } = useContext(DashboardDateRangePickerContext)

    useEffect(() => {
        if (startDate !== null && endDate !== null) {
            props.retrieveProductsRankingData(
                startDate.format("Y-M-D"),
                endDate.format("Y-M-D"),
                retailers,
                maxResult
            );
        }
    }, [props.retrieveProductsRankingData, startDate, endDate]);

    const showTotalViews = dashboardProductsRankingData?.lineItems?.[0]?.["totalViews"] != null

    const tableHead = [
        <div className={classes.tableHead}>
            <FormattedMessage id={"dashboard.productRank.table.header.picture"} />
        </div>,
        <div className={classes.tableHead}>
            <FormattedMessage id={"dashboard.productRank.table.header.name"} />
        </div>,
        <div className={classes.tableHead}>
            <FormattedMessage id={"dashboard.productRank.table.header.stock"} />
        </div>,
        <div className={classes.tableHead}>
            <FormattedMessage id={"dashboard.productRank.table.header.sales"} />
        </div>
    ];

    if (showTotalViews) {
        tableHead.push(
            <div className={classes.tableHead}>
                <FormattedMessage id={"dashboard.productRank.table.header.totalViews"} />
            </div>
        )
    }

    return (
        <CardStats>
            <Loader loading={dashboardProductsRankingDataLoading} />
            <CardStatsBody>
                <RankingTable
                    tableHead={tableHead}
                    tableData={dashboardProductsRankingData?.lineItems}
                    showTotalViews={showTotalViews}
                />
            </CardStatsBody>
        </CardStats>
    )
}

const mapStateToProps = (state) => {
    return {
        dashboardProductsRankingDataLoading: state.dashboard.retrieveProductsRankingDataLoading,
        dashboardProductsRankingData: state.dashboard.retrievedProductsRankingData,
    };
};

const mapDispatchToProps = dispatch => ({
    retrieveProductsRankingData: (startDate, endDate, retailers, maxResult) => dispatch(retrieveProductsRankingData(startDate, endDate, retailers, maxResult)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProductsRanking);
