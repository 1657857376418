import React from "react";
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";
import classNames from "classnames";

import GridContainer from "components/grid/gridContainer";
import GridItem from "components/grid/gridItem";
import SelectTitle from "components/radioGroup/SelectTitle";

import CategoryFilterField from "./fields/CategoryFilterField";
import ColorFilterField from "./fields/ColorFilterField";
import RecommendedProductField from "./fields/RecommendedProductField";
import RetailerFilterField from "./fields/RetailerFilterField";

import SortFilterField from "./fields/SortFilterField";
import CategoryOverloadField from "./fields/CategoryOverloadField";

import {makeStyles} from "@mui/styles";
import {grayColor} from "assets/jss/main";

import {Type} from "utils/recommendation";
import {RecommendationFormContext} from "../context/RecommendationFormContext";
import {useFormikContext} from "formik";

const useStyles = makeStyles({
    formTitle: {
        marginTop: 'unset',
        marginBottom: '48px',
        fontSize: '1.25rem',
        color: '#333'
    },
    filtersContainer: {
        paddingLeft: '6px',
        paddingRight: '6px',
    },
    filterContainer: {
        paddingLeft: '6px',
        paddingRight: '6px',
        '&:first-of-type': {
            paddingLeft: '10px',
        },
        '&:last-of-type': {
            paddingRight: '10px',
        }
    },
    filtersDescription: {
        fontSize: '0.75rem',
        fontWeight: 600,
        color: grayColor,
        marginTop: '32px',
        marginBottom: '32px'
    },
    recommendedProductSort: {
        marginTop: '32px',
    }
})

function RecommendationForm(props) {

    const { enableCategorySelector, formName, type } = props;

    let { filtersDescriptionTranslationKey, sortDescriptionTranslationKey, titleTranslationKey } = props;

    const classes = useStyles();

    if (type === Type.CART) {
        titleTranslationKey = 'recommendations.form.cart_page.title'
        filtersDescriptionTranslationKey = 'recommendations.form.cart_page.filter.description'
        sortDescriptionTranslationKey = 'recommendations.form.cart_page.sort.recommended_product.subtitle'
    }

    const formikContext = useFormikContext();

    function getValueByFormFieldName(formFieldName) {
        let value = formikContext.values;

        formFieldName.split(".").forEach((key) => {
            value = value[key];
        });

        return value;
    }

    return (
        <RecommendationFormContext.Provider value={{
            getValueByFormFieldName,
        }}>
            <div className={classes.form}>
                <h2 className={classes.formTitle}>
                    <FormattedMessage id={titleTranslationKey} />
                </h2>
                {enableCategorySelector && (
                    <CategoryOverloadField formFieldName={`${formName}.${type}.overrodeCategory`} />
                )}
                <RecommendedProductField formFieldName={`${formName}.${type}.recommendedProduct`} />
                <p className={classes.filtersDescription}>
                    <FormattedMessage id={filtersDescriptionTranslationKey} />
                </p>
                <GridContainer className={classes.filtersContainer}>
                    <GridItem xs={4} className={classes.filterContainer}>
                        <RetailerFilterField
                            formName={formName}
                            formFieldName={`${formName}.${type}.retailerFilter`}
                        />
                    </GridItem>
                    <GridItem xs={4} className={classes.filterContainer}>
                        <CategoryFilterField
                            formName={formName}
                            formFieldName={`${formName}.${type}.categoryFilter`}
                            type={type}
                        />
                    </GridItem>
                    <GridItem xs={4} className={classes.filterContainer}>
                        <ColorFilterField
                            formName={formName}
                            formFieldName={`${formName}.${type}.colorFilter`}
                        />
                    </GridItem>
                </GridContainer>
                <GridContainer className={classes.filtersContainer}>
                    <GridItem
                        xs={12}
                        className={classNames({
                            [classes.recommendedProductSort]: true,
                            [classes.filterContainer]: true,
                        })}
                    >
                        <SelectTitle
                            titleTranslationKey={'recommendations.form.sort.recommended_product.title'}
                            subtitleTranslationKey={sortDescriptionTranslationKey}
                        />
                        <SortFilterField
                            formName={formName}
                            formFieldName={`${formName}.${type}.sort`}
                        />
                    </GridItem>
                </GridContainer>
            </div>
        </RecommendationFormContext.Provider>
    )
}

RecommendationForm.propTypes = {
    enableCategorySelector: PropTypes.bool,
    type: PropTypes.oneOf(Object.values(Type)),
    formName: PropTypes.string,
    titleTranslationKey: PropTypes.string,
    filtersDescriptionTranslationKey: PropTypes.string,
    sortDescriptionTranslationKey: PropTypes.string,
};

RecommendationForm.defaultProps = {
    enableCategorySelector: false,
    type: 'product',
    formName: 'recommendation',
    titleTranslationKey: 'recommendations.form.product_page.title',
    filtersDescriptionTranslationKey: 'recommendations.form.product_page.filter.description',
    sortDescriptionTranslationKey: 'recommendations.form.product_page.sort.recommended_product.subtitle'
}

export default RecommendationForm;
