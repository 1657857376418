import React from 'react'

import Button from "@mui/material/Button";

import {lighterBlack} from "assets/jss/main";

export default function HeaderActionButton({...props}) {
    return (
        <Button
            variant="contained"
            disableElevation
            sx={{
                color: lighterBlack,
                fontSize: '14px',
                fontWeight: 600,
                textTransform: 'none',
                borderRadius: '32px',
                background: 'linear-gradient(0deg, #E5EEFF 0%, #E5EEFF 100%), #6F88FC',
            }}
            {...props}
        >
            {props.children}
        </Button>
    )
}
