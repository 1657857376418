
import {container, defaultFont, lightGrayColor} from "../../main";

const updateStyle = theme => ({
    containerWithSidebar: {
        [theme.breakpoints.up("md")]: {
            marginLeft: "240px",
        }
    },
    container: {
        ...container,
        ...defaultFont
    },
    card: {
        minHeight: window.innerHeight * 0.94
    },
    formContainer: {
        paddingBottom: window.innerHeight * 0.15,
        flexGrow: 1
    },
    fieldContainer: {
        padding: "5px",
        "& :hover": {
            backgroundColor: lightGrayColor
        }
    },
});

export default updateStyle;
