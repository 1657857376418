import React from 'react';
import {Link} from "react-router-dom";
import {FormattedMessage} from "react-intl";
import PropTypes from 'prop-types';

import {fourteenthGrey, greyishBlue400} from 'assets/jss/main';

import LocationIcon from "components/icons/LocationIcon";
import SaveButton from "components/button/SaveButton";

Infos.propTypes = {
    name: PropTypes.string.isRequired,
    email: PropTypes.string,
    description: PropTypes.string,
    city: PropTypes.string,
    id: PropTypes.string,
};

function Infos({name, email, description, city, id}) {
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            padding: '50px 20px 20px 20px'
        }}>
            <div>
                <p style={{ marginTop: 'unset' }}>
                    <b style={{ fontSize: '16px' }}>{name}</b>
                    <span style={{
                        color: greyishBlue400,
                        fontSize: '12px',
                        display: 'block',
                        lineHeight: '20px'
                    }}>
                    {email}
                </span>
                </p>
                <p style={{
                    color: fourteenthGrey,
                    fontWeight: '400',
                    marginTop: '16px',
                    marginBottom: '32px',
                    display: '-webkit-box',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: 2,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    lineHeight: '16px',
                    height: '32px',
                }}>
                    {description?.replace(/(<([^>]+)>)/gi, '')}
                </p>
            </div>
            <div>
                <p style={{display: 'flex', alignItems: 'center'}}>
                    {city && (
                        <>
                            <LocationIcon />
                            <span style={{
                                marginLeft: '4px',
                                color: greyishBlue400,
                                fontWeight: 400
                            }}>
                            {city}
                        </span>
                        </>
                    )}
                </p>
                <Link style={{ textDecoration: 'none' }} to={`retailer_profiles/${id}`}>
                    <SaveButton fullWidth>
                        <FormattedMessage id='retailer.profile.list.button' />
                    </SaveButton>
                </Link>
            </div>
        </div>
    );
}

export default Infos;
