import {primaryColor} from "../../main";

const showStyle = theme => ({
    dialogTitleContainer: {
        display: "flex",
        flex: 1,
        flexDirection: "row",
        justifyContent: "space-between",
        borderBottom: '1px solid #ddd'
    },
    dialogBorder: {
        borderBottom: '1px solid #ddd'
    },
    actionsContainer: {
        display: "flex",
        flexDirection: "row",
    },
    buttonsContainer: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "flex-start",
        backgroundColor: "transparent"
    },
    labelsDataContainer: {
        marginBottom: "5px"
    },
    labelsData: {
        color: primaryColor,
        fontSize: "14px",
        marginRight: "5px",
        fontWeight: "bold",
    },
    authorizationContainer: {
        display: "flex",
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: "flex-start",
    },
    dialogContent:{
        padding: "8px 24px 24px 24px"
    }
});

export default showStyle;
