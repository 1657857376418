import React, {Component} from "react";

import SimpleImagePerPageSelect from "../../../../components/select/simpleItemsPerPageSelect";
import {ITEMS_PER_PAGE_QUERY} from "../../enums/urlParametersIdentifier";

class QuoteGridPagination extends Component {
    constructor(props) {
        super(props);

        let params = new URLSearchParams(this.props.history.location.search);
        this.state = {
            value: params.get(ITEMS_PER_PAGE_QUERY) ?? 10,
        };
    }

    render() {
        const { history, } = this.props;
        return <SimpleImagePerPageSelect
            value={this.state.value}
            onChange={numberItems => {
                let params = new URLSearchParams(history.location.search);
                params.set(ITEMS_PER_PAGE_QUERY, numberItems);
                const newUrl = `${history.location.pathname}?${params.toString()}`;
                history.push(newUrl); // This will trigger component "container->setStateFromSearchParams" function
            }}
        />;
    }
}

export default QuoteGridPagination;
