import {
    container,
    defaultFont,
    blueColor,
    lightGrayColor,
    primaryColor,
    yellowColor,
    terraCottaColor,
    mantisGreenColor,
    irisBlueColor,
    infoColor,
    stormGrayColor,
} from "../../../main";

const tabSpan = {
    paddingLeft: "30px",
    paddingRight: "30px",
    borderRadius: "20px",
    marginTop: "5px",
    marginBottom: "7px",
    minHeight: "20px"
}

const listStyle = theme => ({
    containerWithSidebar: {
        [theme.breakpoints.up("md")]: {
            marginLeft: "240px",
        }
    },
    container: {
        ...container,
        ...defaultFont
    },
    header: {
        marginTop: '30px'
    },
    card: {
        minHeight: window.innerHeight * 0.84
    },
    cardBody: {
        padding: " 0.9375rem 0 !important",
        overflow: "inherit"
    },
    cardHeader: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    tabs: {
        textAlign: "center",
    },
    tabsRoot: {
        borderBottom: `1px solid ${lightGrayColor}`,
        borderTop: `1px solid ${lightGrayColor}`,
        padding: "0 1.875rem !important",
    },
    tabsFlexContainer: {
        justifyContent: "space-between"
    },
    tabsIndicator: {
        backgroundColor: blueColor,
    },
    tabPending: {'&:hover': {color: "#C1A635",opacity: 1,},
        '&.Mui-selected': {color: "#C1A635",},
        '&:focus': {color: "#C1A635",},
        marginRight: "10px",
    },
    tabReserved: {'&:hover': {color: "#6F4DBF",opacity: 1,},
        '&.Mui-selected': {color: "#6F4DBF",},
        '&:focus': {color: "#6F4DBF",},
        marginRight: "10px",
    },
    tabtoShip: {
        '&:hover': {color: '#058898',opacity: 1,},
        '&.Mui-selected': {color: '#058898',},
        '&:focus': {color: '#058898',},
        marginRight: "10px",
    },
    tabCanceled: {
        '&:hover': {color: '#BD2A1F',opacity: 1,},
        '&.Mui-selected': {color: '#BD2A1F',},
        '&:focus': {color: '#BD2A1F',},
        marginRight: "10px",
    },
    tabConfirmed: {
        '&:hover': {color: '#43832E',opacity: 1,},
        '&.Mui-selected': {color: '#43832E',},
        '&:focus': {color: '#43832E',},
        marginRight: "10px",
    },
    tabReturning: {
        '&:hover': {color: "#E27947",opacity: 1,},
        '&.Mui-selected': {color: "#E27947",},
        '&:focus': {color: "#E27947",},
        marginRight: "10px",
    },
    pending: {
        ...tabSpan,
        backgroundColor: "rgba(230, 194, 41, 0.2)",
        color: yellowColor,
    },
    reserved: {
        ...tabSpan,
        backgroundColor: "rgba(105, 41, 230, 0.2)",
        color: '#976ef5',
    },
    toShip: {
        ...tabSpan,
        backgroundColor: "rgba(0, 172, 193, 0.2)",
        color: irisBlueColor,
    },
    canceled: {
        ...tabSpan,
        backgroundColor: "rgba(237, 106, 90, 0.2)",
        color: terraCottaColor,
    },
    refunded: {
        ...tabSpan,
        backgroundColor: "rgba(230, 81, 0, 0.2)",
        color: "#ff833a",
    },
    returned: {
        ...tabSpan,
        backgroundColor: "#FDDDD2",
        color: "#FF823C",
    },
    confirmed: {
        ...tabSpan,
        backgroundColor: "rgba(120, 188, 97, 0.2)",
        color: mantisGreenColor
    },
    returning: {
        ...tabSpan,
        backgroundColor: "#FFE6C9",
        color: "#FF8E5E",
    },
    tabSelected: {},
    search: {
        width: "100%",
        float:'right',
        "& input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button" : {
            WebkitAppearance: "none",
            margin: 0
        },

        "& div": {
            height: "38px"
        },

        "& button": {
            padding: "2px"
        }
    },
    footer: {
        padding: "0 1.875rem 1.9375rem 1.875rem"
    },
    total: {
        textAlign: "right",
        color: stormGrayColor,
        fontWeight: "bold"
    },
    pagination: {
        textAlign: "center"
    },
    dateRangePicker: {
        [theme.breakpoints.down('md')]: {
            marginBottom: "5px",
        },
        [theme.breakpoints.up("md")]: {
            maxWidth: "470px",
        },
        "@media (min-width: 1280px) and (max-width: 1920px)": {
            "& .DateRangePicker_picker": {
                left: "1px",
            }
        }
    },
    line: {
        border: `1px solid ${lightGrayColor}`
    },
    filterBarContainer: {
        border: "1px solid " + lightGrayColor,
        borderRadius: "5px",
        padding: "5px",
        "& > span": {
            fontWeight: "400",
            fontSize: "16px",
            color: primaryColor
        },
        marginBottom: "10px",
        marginLeft: "10px"
    },
    filterButton: {
        color: infoColor,
        float:"right",
        padding: "0px",
    },
    filterItem: {
        fontWeight: "400",
        fontSize: "16px",
        margin: "10px 10px 10px 10px ",
        flex:" 0 0 7%",
        textTransform:"none",
    },
    filterSelectedItem: {
        textTransform: "none"
    }
});

export default listStyle;
