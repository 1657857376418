import {createContext} from "react";

export const RecommendationContext = createContext({
    categories: [],
    corners: [],
    recommendations: [],
    apiLoading: false,
    openRecommendationOverloadModal: () => {},
    closeRecommendationOverloadModal: () => {},
    apiCategoriesLoading: false,
    apiCornersLoading: false,
    refreshRecommendationList: () => {},
    selectedRecommendation: null,
    setSelectedRecommendation: () => {}
})
