import React from 'react';
import Container from "./Container";
import ContainerWithSideBar from "./ContainerWithSideBar";

export default function DefaultContainer(props) {
    return (
        <ContainerWithSideBar>
            <Container>
                {props.children}
            </Container>
        </ContainerWithSideBar>
    );
}
