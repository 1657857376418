import React, {Component} from "react";
import PropTypes from "prop-types";

// @mui/material components
import {Checkbox, TableCell, TableHead, TableRow, } from "@mui/material";
import {withStyles} from "@mui/styles";

// styles
import tableStyle from "assets/jss/views/roles/tableHeadStyle";

class EnhancedTableHead extends Component {
    render() {
        const {classes, onSelectAllClick, checked} = this.props;

        return (
            <TableHead className={classes.tableHeadCell}>
                <TableRow>
                    <TableCell>
                        <Checkbox
                            classes={{
                                root: classes.checkbox,
                                checked: classes.checked
                            }}
                            checked={checked}
                            onChange={onSelectAllClick}
                        />
                    </TableCell>
                    {this.props.tableHead.map(row => {
                        return (
                            <TableCell
                                key={row.id}
                                className={classes.primaryTableHeader}
                            >
                                {row.label}
                            </TableCell>
                        );
                    })}
                </TableRow>
            </TableHead>
        );
    }
}

EnhancedTableHead.propTypes = {
    onSelectAllClick: PropTypes.func.isRequired,
};

export default (withStyles(tableStyle)(EnhancedTableHead));
