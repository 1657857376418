import {fetch} from "../../../utils/dataAccess";

export const listByProductSku = function (productSku, queryParams) {
    if (!productSku) {
        throw new Error('No product sku given');
    }

    return new Promise((resolve, reject) => {
        let url = `/variations?productsku=${productSku}`;
        if (queryParams instanceof URLSearchParams) {
            queryParams.set('productsku', productSku);
            url = `/variations?${queryParams.toString()}`;
        }

        fetch(url, {
            method: 'GET'
        })
            .then(response => {
                response.json().then(response => {
                    resolve(response);
                });
            })
            .catch(error => reject(error));
    });
}
