import React from "react";
import {Route} from "react-router-dom";
import {List, Create, Update, Show} from "../../views/marketplace/retailer/index";
import RequireAuthentication from "../../hoc/authentication/requireAuthentication";
import RetailerLoader from "../../hoc/retailer/retailerLoader";
import RetailerUnloader from "../../hoc/retailer/retailerUnloader";
import PhysicalEntryHandler from "../../hoc/notification/physicalEntryHandler";
import {Authorizations, hasAuthorization} from "../../utils/authorizations";
import * as Cookies from "js-cookie";
import {getMemberRetailers} from "../../actions/authentificationMember";

let routes = [];
const applications = Cookies.get("_a") ? JSON.parse(Cookies.get("_a").toString()) : null;
const retailers = getMemberRetailers();
const authorizations = Cookies.get("_b") ? JSON.parse(Cookies.get("_b").toString()) : [];

if (applications && applications["marketplace"]) {
    if (null === retailers && hasAuthorization(authorizations, Authorizations.MARKETPLACE_RETAILER_MANAGEMENT)) {
        routes.push([
            <Route
                path="/retailers/create"
                component={RequireAuthentication(RetailerUnloader(Create))}
                exact
                key="create"
            />,
            <Route
                path="/retailers/edit/:id"
                component={RequireAuthentication(RetailerUnloader(Update))}
                exact
                key="update"
            />,
        ])
    }

    if (null === retailers || hasAuthorization(authorizations, Authorizations.MARKETPLACE_RETAILER_MANAGEMENT) || hasAuthorization(authorizations, Authorizations.MARKETPLACE_RETAILER_VIEW)) {
        routes.push([
            <Route
                path="/retailers/show/:id"
                component={RequireAuthentication(RetailerLoader(PhysicalEntryHandler(Show)))}
                exact
                key="show"
            />,
        ])
    }

    if ((null !== retailers && retailers?.length > 1) || hasAuthorization(authorizations, Authorizations.MARKETPLACE_RETAILER_MANAGEMENT)) {
        routes.push([
            <Route
                path="/retailers/:params"
                component={RequireAuthentication(RetailerUnloader(List))}
                exact
                strict
                key="list"
            />,
        ])
    }
}

export default routes;
