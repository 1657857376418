import React from "react";
import {FormattedMessage} from "react-intl";

// @mui/material components
import {withStyles} from "@mui/styles";

// core components
import GridContainer from "components/grid/gridContainer";
import GridItem from "components/grid/gridItem";
import Card from "components/card/card";
import Button from "components/button/button";

// styles
import errorPageStyle from "assets/jss/views/errorPageStyle";
import { ReactComponent as Logo } from "assets/img/picto-error-404.svg";
import {PowerSettingsNewOutlined} from "@mui/icons-material";

class ErrorPage extends React.Component {
    handleClickRedirection = () => {
        this.props.history.push('/');
    }

    handleClickLogout = () => {
        this.props.history.push('/logout');
    }

    render() {
        const {classes} = this.props;

        return (
            <div className={classes.container}>
                <GridContainer>
                    <GridItem xs={12}>
                        <Card className={classes.card}>
                            <GridContainer className={classes.cardContainer}>
                                <GridItem className={classes.pictoContainer}  xs={6}>
                                    <Logo className={classes.picto}/>
                                </GridItem>
                                <GridItem className={classes.textContainer} xs={6}>
                                    <div className={classes.textContent}>
                                        <div className={classes.oopsLabel}>
                                            <FormattedMessage id={"error.page.oops"}/>
                                        </div>
                                        <div className={classes.title}>
                                            <FormattedMessage id={"error.page.title"}/>
                                        </div>

                                        <hr className={classes.underlinedTitle}></hr>

                                        <div className={classes.description}>
                                            <FormattedMessage id={"error.page.description"}/>
                                        </div>
                                        <div className={classes.textLink}>
                                            <FormattedMessage id={"error.page.textLink"}/><a className={classes.linkRedirection} href="mailto:assistance@supportwishibam.zendesk.com"> assistance@supportwishibam.zendesk.com </a>
                                        </div>
                                        <div className={classes.buttonRedirection}>
                                            <Button round color={"info"} type="submit" onClick={this.handleClickRedirection}>
                                                <FormattedMessage id={"error.page.buttonRedirection"}/>
                                            </Button>
                                            <Button round color={"danger"} type="submit" onClick={this.handleClickLogout}>
                                                <PowerSettingsNewOutlined/><FormattedMessage id={"topbar.action.logout"}/>
                                            </Button>
                                        </div>
                                    </div>
                                </GridItem>
                            </GridContainer>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        );
    }
}

export default withStyles(errorPageStyle)(ErrorPage);
