import React, { useEffect, useState } from "react";
import { connect }  from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";

// @mui/material components
import {
    Collapse,
    Dialog,
    DialogTitle,
    DialogContent,
    Divider,
    Input,
    List,
    ListItem,
    ListItemText,
    Radio
} from "@mui/material";

// @mui/icons-material
import { KeyboardArrowDown, KeyboardArrowRight } from "@mui/icons-material";

// core components
import GridItem from "components/grid/gridItem";
import Hierarchy from '../../configuration/components/hierarchy';
import ActionsContainer from "components/form/ActionsContainer";
import FormContainer from "views/configuration/components/FormContainer";
import CancelButton from "views/configuration/components/CancelButton";
import MoveButton from "views/configuration/components/MoveButton";

// action
import { update } from "actions/category/update";

// styles
import {grayColor} from "../../../assets/jss/main";

// Helpers
import {getTranslation} from "../../../domain/helpers/translations";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles(theme => ({
    dialogSearch:{
        width: "100%",
        padding: "10px 20px",
    },
    dialogContent: {
        padding: "0 !important",
    },
    list: {
        padding: "10px 5px",
        height: 400,
        overflowY: "scroll"
    },
    tableCellNoResult: {
        fontSize: "16px",
        textAlign: "center",
        fontWeight: "bold",
        verticalAlign: "middle",
        marginTop: "20px"
    },
    categoryListItem: {
        padding: 0,
        cursor: "pointer"
    },
    childrenCategories: {
        marginLeft: 30,
    },
    setTopParent: {
       backgroundColor: "#FFFFFF",
        color: grayColor,
        width: "1px",
        height: "1px",
        border: `1px solid ${grayColor}`,
        marginLeft: "11px",
        verticalAlign: "sub",
        padding: "9px",
        "&:hover": {
            backgroundColor:"rgba(0, 82, 203, 0.08)",
        }
    },
    hierarchy: {
        padding: "10px"
    }
}));

function MoveDialog(props) {
    const { allCategories, categorySelected, closeDialog, isOpen, updated, intl, update, updateLoading, submitting } = props;
    const classes = useStyles();

    const [search, setSearch] = useState('');
    const [openParents, setOpenParents] = useState([]);
    const [newParent, setNewParent] = useState(null);

    useEffect(() => {
        closeDialog();
    }, [updated]);

    useEffect(() => {
        if (categorySelected.parent !== null) {
            setNewParent(categorySelected.parent);
        } else {
            setNewParent(categorySelected)
        }
    }, [categorySelected]);

    function isNewParentIsAChildOfCategory(newParent, categorySelected) {
        let parent = newParent;

        while(parent !== null) {
            if (parent.id === categorySelected.id) {
                return true
            }

            parent = parent.parent;
        }

        return false
    }

    function submit(e) {
        e.preventDefault();

        if (newParent && newParent["@id"] === categorySelected["@id"]) {
            closeDialog();
        } else if (isNewParentIsAChildOfCategory(newParent, categorySelected)) {
            update(newParent, {parent: categorySelected.parent}).then(() => {
                update(categorySelected, {parent: newParent ? newParent["@id"] : null});
            });
        } else {
            if (categorySelected) {
                update(categorySelected, {parent: newParent ? newParent["@id"] : null});
            }
        }
    }

    function checkParentOpen(categoryId) {
        for (let i = 0; i < openParents.length; i++) {
            if (openParents[i] === categoryId) {
                return true;
            }
        }

        return false;
    }

    function handleShowChildren(categoryId) {
        const isParentAlreadyOpen = checkParentOpen(categoryId);

        if (!isParentAlreadyOpen) {
            setOpenParents((openParents) => [...openParents, categoryId]);
        } else {
            let newOpened = openParents.filter((parent) => !(parent === categoryId));

            setOpenParents(newOpened);
        }
    }

    function renderSetTopParent() {
        return (
            <ListItem className={classes.categoryListItem}>
                <Radio
                    color="primary"
                    disabled={categorySelected.parent === null}
                    onChange={() => setNewParent(null)}
                    classes={{checked: classes.checked}}
                    checked={newParent === null}
                />
                <ListItemText
                    className={classes.setTopParentText}
                    primary={intl.formatMessage({id: "category.form.move.parent"})}
                />
            </ListItem>
        )
    }

    function renderCategoryChildren(categories) {
        if (categories.length > 0) {
            return categories.map(category => {
                const hasChildren = allCategories.filter(item => item.parent && item.parent.id === category.id).length > 0;
                const isParentOpen = checkParentOpen(category.id);

                return (
                    <div key={category.id}>
                        <ListItem className={classes.categoryListItem} button={hasChildren}>
                            <Radio
                                color="primary"
                                disabled={categorySelected.id === category.id}
                                onChange={() => setNewParent(category)}
                                classes={{checked: classes.checked}}
                                checked={!!(newParent && newParent.id === category.id)}
                            />
                            <ListItemText
                                primary={getTranslation(category).name}
                                onClick={() => handleShowChildren(category.id)}
                            />
                            {hasChildren && (isParentOpen ? <KeyboardArrowDown/> : <KeyboardArrowRight/>)}
                        </ListItem>
                        <Collapse
                            className={classes.childrenCategories}
                            in={hasChildren && isParentOpen}
                            timeout="auto"
                            unmountOnExit
                        >
                            {renderCategoryChildren(allCategories.filter(cat => cat.parent !== null && cat.parent.id === category.id))}
                        </Collapse>
                    </div>
                );
            })
        } else {
            return (
                <div className={classes.tableCellNoResult}>
                    <FormattedMessage id={"retailer.list.table.no_result"}/>
                </div>
            )
        }
    }

    return (
        <Dialog
            open={isOpen}
            fullWidth={true}
            scroll={'paper'}
            onClose={closeDialog}
        >
            <DialogTitle>
                <FormattedMessage id={"category.form.move.title"} />
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <FormContainer>
                    <GridItem xs={12} sm={12}>
                        <Divider />
                        <Input
                            placeholder={intl.formatMessage({id: "category.form.move.search"})}
                            onChange={(e) => setSearch(e.target.value.toLowerCase())}
                            className={classes.dialogSearch}
                            autoFocus
                            disableUnderline
                        />
                        <Divider />
                    </GridItem>
                    <GridItem xs={12} sm={12}>
                        <List disablePadding className={classes.list}>
                            {renderSetTopParent()}
                            {search.length > 0 ?
                                renderCategoryChildren(allCategories.filter(category => getTranslation(category).name.toLowerCase().includes(search)))
                                :
                                renderCategoryChildren(allCategories.filter(category => category.parent === null))
                            }
                        </List>
                    </GridItem>
                    {categorySelected && (
                        <GridItem xs={12} sm={12}>
                            <Divider />
                            <div className={classes.hierarchy}>
                                <Hierarchy
                                    moveAttribute={true}
                                    newParent={newParent}
                                    attributeSelected={categorySelected}
                                    attributeName={'category'}
                                />
                            </div>
                            <Divider />
                        </GridItem>
                    )}
                </FormContainer>
                <ActionsContainer>
                    <CancelButton onClick={closeDialog} />
                    <MoveButton
                        onClick={(e) => submit(e)}
                        disabled={submitting}
                        loading={updateLoading}
                    />
                </ActionsContainer>
                <Divider />
            </DialogContent>
        </Dialog>
    )
}

const mapStateToProps = state => {
    return {
        updated: state.category.update.updated,
        updateLoading: state.category.update.loading,
    }
};

const mapDispatchToProps = dispatch => ({
    update: (item, values) => dispatch(update(item, values)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(injectIntl(MoveDialog));
