import {useEffect, useState} from "react";
import {fetch} from "utils/dataAccess";
import SimpleFilterPanel from "views/marketplace/merchandising/filter/simpleFilterPanel";

const Filter = (tabTitle, tabContent) => { return { tabTitle: tabTitle, tabContent: tabContent } }

export default function useSizes() {

    const [sizesFilters, setSizesFilters] = useState(null);
    const [sizesLoaded, setSizesLoaded] = useState(false);

    useEffect(() => {
        fetch("/sizes?pagination=false&translatedOrder[name]=asc&draft=false").then(
            response => {
                response.json().then(retrieved => {

                    // Add missing API property
                    retrieved['hydra:member'].forEach((item) => {
                        item['@type'] = 'Size';
                    })

                    setSizesFilters(
                        new Filter(
                            "sizes",
                            {
                                component: SimpleFilterPanel,
                                items: retrieved['hydra:member'],
                            }
                        )
                    );

                    setSizesLoaded(true);
                });
            }
        );
    }, []);

    return {
        sizesLoaded,
        sizesFilters
    };
}
