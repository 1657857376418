import React, { useContext } from "react";
import Button from '@mui/material/Button';
import {FormattedMessage} from "react-intl";
import {FilterDispatchContext} from "contexts/filterContext";

export default function ResetFiltersButton() {
    const dispatch = useContext(FilterDispatchContext);

    function handleClick() {
        dispatch({
            type: 'filters_reset',
        });
    };

    return (
        <Button variant="contained" onClick={handleClick} >
            <FormattedMessage id={"merchandising.show.label.filters.reset"} />
        </Button>
    );
}
