import React, {useContext} from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {useFormikContext} from 'formik';
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import Grid from '@mui/material/Grid';

import FormTranslator from 'components/translator/formTranslator';
import Label from 'components/input/new/Label';
import ImageDropField from 'components/input/ImageDropField';
import {CKEditorConfig} from 'components/input/inputRenderer/wysiwygInputRenderer';

import FormBloc from './components/FormBloc';

import {RetailerProfilesEditContext} from '../../context/RetailerProfilesEditContext';

import {handleFileUpload} from 'api/upload';

import {FileUploadType} from 'utils/fileUploadType';

function VisualForm({intl}) {
    const {picturesToDelete, setPicturesToDelete, isAllowedToChangeLogo} = useContext(RetailerProfilesEditContext);
    const {values, setFieldValue, isSubmitting} = useFormikContext();

    return (
        <FormBloc titleId="retailer.profile.edit.form.title.visual">
            <FormTranslator formGenerator={(index, code) => (
                <Grid container rowSpacing={3}>
                    <Grid item xs={12}>
                        <Grid container spacing={2} style={{gap: "16px", paddingLeft: "16px"}}>
                            <Grid item xs={12} md={8} style={{maxWidth: "100%", flex: 1, minWidth: "400px", paddingLeft: "0"}}>
                                <Label htmlFor={`translations.${code}.picture`}>
                                    <FormattedMessage id="retailer.profile.edit.form.field.picture" />
                                </Label>
                                <ImageDropField
                                    recommendedFormat="960 X 400 px"
                                    previewUrl={values.translations?.[code]?.picture?.contentUrl}
                                    disabled={isSubmitting}
                                    onDelete={() => {
                                        picturesToDelete.push(values.translations?.[code].picture)
                                        setPicturesToDelete(picturesToDelete);
                                        setFieldValue(`translations.${code}.picture`, null)
                                    }}
                                    onDrop={(acceptedFiles, removeLoader) => {
                                        handleFileUpload(
                                            acceptedFiles,
                                            FileUploadType.RETAILER_COVER,
                                            `translation.${code}.picture`
                                        ).then(response => {
                                            removeLoader();
                                            setFieldValue(`translations.${code}.picture`, response);
                                        })
                                    }}
                                    style={{height: "252px"}}
                                />
                            </Grid>
                            {isAllowedToChangeLogo &&
                                <Grid item xs={12} md={4} style={{width: "252px", maxWidth: "252px", minWidth: "252px", paddingLeft: "0"}}>
                                    <Label htmlFor={`translation.${code}..logo`}>
                                        <FormattedMessage id="retailer.profile.edit.form.field.logo" />
                                    </Label>
                                    <ImageDropField
                                        recommendedFormat="620 X 400 px"
                                        previewUrl={values.translations?.[code]?.logo?.contentUrl}
                                        disabled={isSubmitting}
                                        onDelete={() => {
                                            picturesToDelete.push(values.translations?.[code].logo)
                                            setPicturesToDelete(picturesToDelete);
                                            setFieldValue(`translations.${code}.logo`, null)
                                        }}
                                        onDrop={(acceptedFiles, removeLoader) => {
                                            handleFileUpload(
                                                acceptedFiles,
                                                FileUploadType.RETAILER_LOGO,
                                                `translation.${code}.logo`
                                            ).then(response => {
                                                removeLoader();
                                                setFieldValue(`translations.${code}.logo`, response);
                                            })
                                        }}
                                    />
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className="retailerProfileWysiwyg">
                        <Label htmlFor={`translations.${code}.description`}>
                            <FormattedMessage id="retailer.profile.edit.form.field.description" />
                        </Label>
                        <CKEditor
                            name={`translations.${code}.description`}
                            config={Object.assign(CKEditorConfig, {link: {addTargetToExternalLinks: true}})}
                            editor={ClassicEditor}
                            data={values.translations?.[code]?.description ?? ''}
                            placeholder={intl.formatMessage({id: "retailer.profile.edit.form.field.description.placeholder"})}
                            onChange={( event, editor ) => {
                                setFieldValue(`translations.${code}.description`, editor.getData());
                            }}
                            type={"wysiwyg"}
                        />
                    </Grid>
                </Grid>
            )} />
        </FormBloc>
    );
}

export default injectIntl(VisualForm);
