import { Component } from 'react';

// core components
import auth0Client from './auth';

class Signup extends Component {
    componentDidMount() {
       auth0Client.signUp();
    }

    render() {
        return null;
    }
}

export default Signup;
