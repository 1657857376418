import React, {useEffect, useState} from "react";
import {Draggable} from "react-beautiful-dnd";
import connect from "react-redux/es/connect/connect";

// @mui/material components
import {Checkbox, IconButton} from "@mui/material";

// @mui/icons-material
import { MoreVert, KeyboardArrowDown, KeyboardArrowRight, AddRounded,  SubdirectoryArrowRightRounded } from "@mui/icons-material";

// core components
import Children from './children';
import CustomDate from 'components/date/date';

// styles
import categoryStyle from "assets/jss/views/configuration/defaultAttributeRowStyle";

// helpers
import {sortOn} from "utils/sortOn";
import {Authorizations, hasAuthorization} from "../../../utils/authorizations";
import {getTranslation} from "../../../domain/helpers/translations";
import {withStyles} from "@mui/styles";

function Category (props) {
    const {
        allCategories,
        checkedCategories,
        classes,
        data,
        draggableId,
        index,
        openCreatedDialog,
        openPopover,
        search,
        selectCategory,
        setCategoryTablePosition,
        setCheckedCategories,
    } = props;

    const [openChildren, setOpenChildren] = useState( false);

    const isTopCategory = data.parent === null;

    const children = allCategories.filter(item => item.parent && item.parent.id === data.id);
    const hasChildren = children.length > 0;

    const isChecked = checkedCategories.some(item => item['@id'] === data['@id']);

    useEffect(() => {
        if (search.length > 0) {
            setOpenChildren(true);
        }
    }, [search]);

    function openAddSubCategoryDialog(e) {
        e.stopPropagation();
        selectCategory(data);
        openCreatedDialog();
    }

    function showPopover(e) {
        e.stopPropagation();
        selectCategory(data);
        setCategoryTablePosition(e.currentTarget);
        openPopover();
    }

    function checkCategory(item) {
        const isItemAlreadyChecked = checkedCategories.some(itemAdded => itemAdded['@id'] === item['@id']);

        if (!isItemAlreadyChecked) {
            setCheckedCategories([...checkedCategories, item]);
        } else {
            let newSelected = checkedCategories.filter((d) => !(d['@id'] === item['@id']));

            setCheckedCategories(newSelected);
        }
    }

    function isCategoryMatchWithSearch(category) {
        return !!getTranslation(category).name.toLowerCase().includes(search);
    }

    function showChildren() {
        if (hasChildren) {
            setOpenChildren(!openChildren);
        }
    }

    function elementClasses(category) {
        if (openChildren) {
            if (search.length > 0 && isCategoryMatchWithSearch(category)) {
                return classes.elementMatch;
            } else {
                return classes.elementOpen;
            }
        } else {
            return classes.element;
        }
    }

    function subdirectoryIconClasses() {
        if (openChildren) {
            return classes.subdirectoryIconOpen;
        } else {
            return classes.subdirectoryIconClose;
        }
    }
    function nameClasses() {
        if (isTopCategory) {
            if (openChildren) {
                return classes.topAttributeNameSelected;
            } else {
                return classes.topAttributeName;
            }
        } else if (openChildren) {
            return classes.attributeNameSelected;
        } else {
            return null
        }
    }

    function renderDescription(description) {
        if (description) {
            if (description.length > 100) {
                return description.substring(0, 100 - 3) + "...";
            } else {
                return description;
            }
        } else {
            return "-";
        }
    }

    return <>
        <Draggable index={index} draggableId={draggableId}>
            {provided => (
                <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                    {data.parent && <SubdirectoryArrowRightRounded className={subdirectoryIconClasses()} />}
                    <li className={elementClasses(data)} onClick={() => showChildren()}>
                        <div className={classes.name}>
                            <Checkbox
                                checked={isChecked}
                                classes={{ root: classes.checkbox, checked: classes.checked }}
                                onClick={(e) => e.stopPropagation()}
                                onChange={() => checkCategory(data)}
                            />
                            <span className={nameClasses()}>
                                {getTranslation(data).name}
                            </span>
                            {hasAuthorization(props.authorizations, Authorizations.MARKETPLACE_CATEGORY_MANAGEMENT) && (
                                <IconButton
                                    component="span"
                                    onClick={(e) => openAddSubCategoryDialog(e)}
                                    aria-label="add sub categories"
                                    className={classes.addSubAttributeButton}
                                    size="large">
                                    <AddRounded className={classes.addSubAttributeIcon} />
                                </IconButton>
                            )}
                        </div>
                        <div className={classes.descriptionContainer}>
                            {renderDescription(getTranslation(data).description)}
                        </div>
                        <div className={classes.actionsButtonContainer}>
                            <CustomDate showRelativeDate={true} date={data.updatedAt} />
                            <IconButton
                                className={hasChildren ? classes.iconButton : classes.iconButtonMargin}
                                onClick={(e) => showPopover(e)}
                                size={"small"}
                            >
                                <MoreVert />
                            </IconButton>
                            {hasChildren && (
                                <IconButton className={classes.iconButton} size={"small"}>
                                    {openChildren ? <KeyboardArrowDown color={"primary"} /> : <KeyboardArrowRight />}
                                </IconButton>
                            )}
                        </div>
                    </li>
                </div>
            )}
        </Draggable>
        {(hasChildren && openChildren) && (
            <li>
                <Children
                    children={children.sort(sortOn("position", true))}
                    allCategories={allCategories}
                    openCreatedDialog={openCreatedDialog}
                    selectCategory={selectCategory}
                    setCategoryTablePosition={setCategoryTablePosition}
                    openPopover={openPopover}
                    search={search}
                    checkedCategories={checkedCategories}
                    setCheckedCategories={setCheckedCategories}
                />
            </li>
        )}
    </>;
}

const mapStateToProps = (state) => {
    return {
        authorizations: state.authentication.authorizations,
    };
};

export default connect(
    mapStateToProps,
    null
)(withStyles(categoryStyle)(Category));
