import React from 'react'
import {makeStyles} from '@mui/styles'
import classNames from 'classnames'
import {FormattedMessage} from 'react-intl'
import PropTypes from 'prop-types'

import {getTranslation} from '../../../../../../../domain/helpers/translations'
import {grayColor, infoColor} from '../../../../../../../assets/jss/main'
import { ReactComponent as ArrowDown } from 'assets/img/arrow-down.svg'

const useStyles = makeStyles(theme => ({
    breadcrumb: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        listStyle: 'none',
        margin: '0',
        padding: '0.5rem 0 0 0',
    },
    item: {
        color: grayColor,
        fontSize: '0.75rem',
        fontWeight: '400',
        margin: '0',
        padding: '0',
    },
    separator: {
        display: 'flex',
        paddingLeft: '8px',
        paddingRight: '8px',
        '& svg': {
            width: '16px',
            height: '16px',
            transform: 'rotate(-90deg)',
        },
        '& svg path': {
            stroke: grayColor,
        },
    },
    active: {
        color: infoColor,
    },
}))

export default function Breadcrumb(props) {
    const {node} = props
    const classes = useStyles()
    const separator = <ArrowDown />

    function getBreadcrumbs(node) {
        const items = []
        items.push(node)

        let parent = node.parent
        while (parent) {
            items.push(parent)
            parent = parent.parent
        }

        return items.reverse().map((item, i) => (
            <React.Fragment key={item.id}>
                <li className={classNames(classes.item, classes.separator)}>{separator}</li>
                <li
                    className={classNames(
                        classes.item,
                        { [classes.active]: i === (items.length - 1) },
                    )}
                >
                    {getTranslation(item).name}
                </li>
            </React.Fragment>))
    }

    return (
        <nav aria-label="breadcrumb">
            <ol className={classes.breadcrumb}>
                {!node ? (
                    <>
                        <li className={classes.item}>
                            <FormattedMessage id={"recommendations.form.recommended_product.products.name"} />
                        </li>
                        <li className={classNames(classes.item, classes.separator)}>{separator}</li>
                        <li className={classNames(classes.item, classes.active)}>
                            <FormattedMessage id={"recommendations.form.recommended_product.all_products"} />
                        </li>
                    </>
                ) : (
                    <>
                        <li className={classes.item}>
                            <FormattedMessage id={"recommendations.form.recommended_product.breadcrumb." + node['@type']} />
                        </li>
                        {getBreadcrumbs(node)}
                    </>
                )}
            </ol>
        </nav>
    )
}

Breadcrumb.propTypes = {
    node: PropTypes.object,
}
