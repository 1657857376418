import React, {Component} from "react";
import Dashboard from "./dashboard";
import PropTypes from "prop-types";
import {connect} from "react-redux";

// actions
import {reset as resetBrand} from "actions/brand/list";
import {reset as resetCategory} from "actions/category/list";
import {reset as resetColor} from "actions/color/list";
import {reset as resetGender} from "actions/gender/list";
import {reset as resetSize} from "actions/size/list";

class Show extends Component {
    static propTypes = {
        resetBrand: PropTypes.func.isRequired,
        resetCategory: PropTypes.func.isRequired,
        resetColor: PropTypes.func.isRequired,
        resetGender: PropTypes.func.isRequired,
        resetSize: PropTypes.func.isRequired,
    };

    componentDidMount() {
        this.props.resetBrand()
        this.props.resetCategory()
        this.props.resetColor()
        this.props.resetGender()
        this.props.resetSize()
    }

    render() {
        return (
            <Dashboard retailer={decodeURIComponent(this.props.match.params.id).replace('/retailers/', '')}/>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    resetBrand: () => dispatch(resetBrand()),
    resetCategory: () => dispatch(resetCategory()),
    resetColor: () => dispatch(resetColor()),
    resetGender: () => dispatch(resetGender()),
    resetSize: () => dispatch(resetSize()),
});

export default connect(
    null,
    mapDispatchToProps
)(Show);
