import React, {Component} from "react";
import connect from "react-redux/es/connect/connect";
import { injectIntl, FormattedMessage } from "react-intl";

// @mui/material components
import {withStyles} from "@mui/styles";
import CardBody from "components/card/cardBody";
import Card from "components/card/card";
import congratulationsImage from "assets/img/congratulations.png";

// local components
import ButtonsWrapper from "../button/buttonsWrapper";

// styles
import CreateProductFormStyle from "assets/jss/views/stockeasy/product/create/form/createProductFormStyle";

// utils
import {getStockEasyLink} from "../../../../../../domain/helpers/StockEasyHelper";

class CongratsStep extends Component {
    render() {
        const {classes, intl} = this.props;
        const createProductLink = `/stockeasy/retailers/${this.props.retailerId}/product/create`;

        return (
            <Card className={classes.stepContainer}>
                <CardBody className={classes.center}>
                    <header className={classes.congratsHeader}>
                        <h3><FormattedMessage id={"stockeasy.congrats.title"}/></h3>
                        <h3><FormattedMessage id={"stockeasy.congrats.subtitle"}/></h3>
                    </header>

                    <div className={classes.congratsContainer}>
                        <img className={classes.congratsImage} src={congratulationsImage} alt={"congratulations"}/>
                    </div>

                    <p className={classes.congratsInfo}><FormattedMessage id={"stockeasy.congrats.info"}/></p>

                    <ButtonsWrapper
                        firstButtonLink={createProductLink}
                        secondButtonLink={getStockEasyLink(this.props.retailerId)}
                        submit={() => this.handleConfirmStep()}
                        firstButtonContent={intl.formatMessage({id: "stockeasy.congrats.button.first"})}
                        secondButtonContent={intl.formatMessage({id: "stockeasy.congrats.button.second"})}
                    />

                </CardBody>
            </Card>
        );
    }
}

export default connect(
    null,
    null
)(withStyles(CreateProductFormStyle)(injectIntl(CongratsStep)));
