import React, {Component} from "react";
import PropTypes from "prop-types";
import {FormattedMessage, injectIntl} from "react-intl";
import {connect} from "react-redux";
import {BeatLoader, PropagateLoader} from 'react-spinners';

// @mui/material components
import {withStyles} from "@mui/styles";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
  TableSortLabel,
  Avatar
} from "@mui/material";

// @mui/icons-material
import { MonetizationOn } from "@mui/icons-material";

// styles
import tableStyle from "assets/jss/views/picking/order/tableStyle";
import {primaryColor} from "assets/jss/main";

// components
import FormattedNumberWithCurrency from "../../../components/formattedNumberWithCurrencies/formattedNumberWithCurrency";
import CustomDate from "components/date/date";

// helpers
import {capitalize} from "utils/capitalize";
import {Authorizations, hasAuthorization} from "../../../utils/authorizations";
import ShippingIcon from "../components/shippingIcon";


class OrderTable extends Component {
    static propTypes = {
        loading: PropTypes.bool,
        deleteLoading: PropTypes.bool.isRequired,
        deleted: PropTypes.object
    };

    checkIfRunnerExist = (lineItems) => {
        for (let i = 0; i < lineItems.length; i++) {
            if (lineItems[i].runner !== null) {
                return true
            }
        }
        return false;
    };

    renderRunnerAvatar = (runner, key) => {
        const {classes} = this.props;

        let RunnerFullName = runner && runner.user.fullName;
        let runnerArray = runner && RunnerFullName.split(" ");

        return (
            runner.user.picture ?
                <img
                    src={runner.user.picture}
                    className={classes.image}
                    alt={""}
                    key={key}
                    style={{position: 'relative', zIndex: 20 - key, backgroundColor: primaryColor}}
                /> :
                <Avatar component={"span"} className={classes.imgPlaceholder} key={key}
                        style={{position: 'relative', zIndex: 20 - key, backgroundColor: primaryColor}}>
                    {capitalize(runnerArray[0].charAt(0))+capitalize(runnerArray[1].charAt(0))}
                </Avatar>
        );
    };

    renderRunner = (lineItems) => {
        const {classes} = this.props;

        let runnerList = [];

        if (hasAuthorization(this.props.authorizations, Authorizations.PICKING_RETAILER)) {
            return (
                <div></div>
            )
        }

        if (this.checkIfRunnerExist(lineItems)) {
            return (
                <div className={classes.runnerList}>
                    {lineItems.map((item, key) => {
                        if (item?.runner?.user?.fullName) {
                            if (!runnerList.includes(item.runner["@id"])) {
                                runnerList.push(item.runner["@id"]);
                                return this.renderRunnerAvatar(item.runner, key);
                            }
                        }

                        return null;
                    })}
                </div>
            );
        } else {
            return (<div className={classes.emptyRunner}>---</div>)
        }
    }

    renderShippingIcons(orderShippingMethods) {
        let shippingIcons = [];

        // orders can have multiple shippingMethods
        // see -> App\Entity\Order::getShippingMethods
        for (const orderShippingMethod in orderShippingMethods) {
            shippingIcons.push(
                <div key={orderShippingMethod}>
                    <ShippingIcon
                        shippingMethodName={orderShippingMethod}
                        organizationShippingMethods={this.props.organizationShippingMethods}
                    />
                </div>
            );
        }

        return shippingIcons
    }

    render() {
        const {classes, tableHead, tableData, tableHeaderColor, intl} = this.props;

        return (
            <div className={classes.tableResponsive}>
                {!tableData ?
                    <div className={classes.propagateLoader}>
                        <PropagateLoader
                            sizeUnit={"px"}
                            size={16}
                            color={primaryColor}
                            loading={this.props.loading}
                        />
                    </div> :
                    <div className={classes.beatLoader}>
                        <BeatLoader
                            sizeUnit={"px"}
                            size={12}
                            color={primaryColor}
                            loading={this.props.loading}
                        />
                    </div>
                }

                <Table className={classes.table}>
                    {tableHead !== undefined ? (
                        <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
                            <TableRow>
                                {tableHead.map((prop, key) => {
                                    return (
                                        <TableCell
                                            className={classes.tableCell + " " + classes.tableHeadCell}
                                            key={key}
                                        >
                                            {prop && prop.props.id === "picking.order.list.table.header.order" ?
                                                <Tooltip title="Sort" enterDelay={100}>
                                                    <TableSortLabel
                                                        active={true}
                                                        onClick={prop.props.onClick}
                                                        direction={prop.props.sortDirection}
                                                    >
                                                        {prop}
                                                    </TableSortLabel>
                                                </Tooltip>
                                                : prop
                                            }
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        </TableHead>
                    ) : null}
                    {(tableData && tableData.length) ?
                        <TableBody>
                            {tableData.map((item, key) => {
                                return (
                                    <TableRow
                                        hover
                                        key={key}
                                        className={classes.tableRow}
                                        onClick={() => this.props.history.push(`/picking/orders/show/${item.id}`)}>
                                        <TableCell className={classes.tableCell}>
                                            <div className={classes.primaryColor}>
                                                #{item["externalId"]}
                                            </div>
                                            <CustomDate date={item["createdAt"]} />
                                        </TableCell>
                                        <TableCell className={classes.tableCell}>
                                            <div>
                                                <b>
                                                    {
                                                        capitalize(item["customerShippingFirstname"] || item["customerBillingFirstname"])
                                                        + " " +
                                                        capitalize(item["customerShippingLastname"] || item["customerBillingLastname"])
                                                    }
                                                </b>
                                            </div>
                                        </TableCell>
                                        <TableCell className={classes.tableCell}>
                                            <div className={classes.shippingMethods}>
                                                {this.renderShippingIcons(item.shippingMethods)}
                                            </div>
                                        </TableCell>
                                        <TableCell className={classes.tableCell}>
                                          <b><FormattedNumberWithCurrency value={item["retailerTotal"]} currency={this.props.currentOrganization?.currency} /></b>
                                          {item["retailerTotal"] !== item["total"] && (
                                            <div className={classes.lineIcon}>
                                              <i><FormattedNumberWithCurrency value={item["total"]} currency={this.props.currentOrganization?.currency} /></i>
                                              <Tooltip
                                                title={intl.formatMessage({id: "picking.order.list.table.header.totalPayed"})}
                                                className={classes.processingLoader}
                                              >
                                                <MonetizationOn className={classes.lineIconIcon}/>
                                              </Tooltip>
                                            </div>
                                          )}
                                        </TableCell>
                                        <TableCell className={classes.tableCell}>
                                            <div>{item["lineItemsQuantity"].toLocaleString()}</div>
                                        </TableCell>
                                        <TableCell className={classes.tableCell}>
                                            {this.renderRunner(item.lineItems)}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody> :
                        <TableBody>
                            {(tableData) &&
                            <TableRow>
                                <TableCell colSpan={7} className={classes.tableCellNoResult}>
                                    <FormattedMessage id={"retailer.list.table.no_result"}/>
                                </TableCell>
                            </TableRow>
                            }
                        </TableBody>
                    }
                </Table>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    loading: state.order.list.loading,
    authorizations: state.authentication.authorizations,
    currentOrganization: state.currentOrganization.retrieved ?? null,
});

OrderTable.defaultProps = {
    tableHeaderColor: "gray",
    organizationShippingMethods: [],
};

OrderTable.propTypes = {
    classes: PropTypes.object.isRequired,
    tableHeaderColor: PropTypes.oneOf([
        "warning",
        "primary",
        "danger",
        "success",
        "info",
        "rose",
        "gray"
    ]),
    tableHead: PropTypes.arrayOf(PropTypes.object),
    organizationShippingMethods: PropTypes.arrayOf(PropTypes.object)
};

export default connect(
    mapStateToProps,
    null
)(withStyles(tableStyle)(injectIntl(OrderTable)));
