import React from 'react';
import PropTypes from 'prop-types';
import {styled} from '@mui/material/styles';

export default function PriceDetailLine({...props}) {
    const StyledSpan = styled('span')({
        position: 'relative',
        paddingBottom: '12px',
        color: '#333',
        fontSize: props.bold ? '16px' : '14px',
        fontStyle: 'normal',
        fontWeight: props.bold ? 700 : 400,
        lineHeight: 'normal',
        '& > *': {
            float: 'right',
            letterSpacing: 'initial',
        },
    });

    return (
        <StyledSpan>
            {props.children}
        </StyledSpan>
    );
}

PriceDetailLine.propTypes = {
    bold: PropTypes.bool,
};
