import {
    primaryColor,
    defaultFont
} from "../../main";

const tableHeadStyle = () => ({
    primaryTableHeader: {
        color: primaryColor,
        fontSize: "16px",
        fontWeight: "400"
    },
    tableHeadCell: {
        ...defaultFont,
    },
    checkbox: {
        color: "grey",
        "&$checked": {
            color: primaryColor,
            "&:hover": {
                backgroundColor: "rgba(0, 53, 245, 0.08)"
            },
        },
        "&:hover": {
            backgroundColor: "rgba(0, 53, 245, 0.08)"
        },
    },
    checked: {
        color: primaryColor,
    }
});

export default tableHeadStyle;
