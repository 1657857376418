import {fetch} from "../../../utils/dataAccess";

export function error(error) {
    return {type: "ORDER_NOTE_CREATE_ERROR", error};
}

export function loading(loading) {
    return {type: "ORDER_NOTE_CREATE_LOADING", loading};
}

export function success(created) {
    return {type: "ORDER_NOTE_CREATE_SUCCESS", created};
}

export function create(values) {
    return dispatch => {
        dispatch(loading(true));

        return fetch("/notes", {method: "POST", body: JSON.stringify(values)})
            .then(response => {
                dispatch(loading(false));

                return response.json();
            })
            .then(retrieved => dispatch(success(retrieved)))
            .catch(e => {
                dispatch(loading(false));
                dispatch(error(e));
            });
    };
}

export function reset() {
    return dispatch => {
        dispatch({type: "ORDER_NOTE_CREATE_RESET"});
        dispatch(loading(false));
        dispatch(error(null));
    };
}
