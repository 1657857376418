import React, {useEffect, useState} from "react";
import {FormattedMessage, injectIntl} from "react-intl";

import GridContainer from "components/grid/gridContainer";
import GridItem from "components/grid/gridItem";
import Card from "components/card/card";
import CardHeader from "components/card/cardHeader";
import CardBody from "components/card/cardBody";

import DefaultRecommendationForms from "./components/DefaultRecommendationForms";
import SaveButton from "./components/SaveButton";
import OverrodeRecommendations from "./components/OverrodeRecommendations";

import {makeStyles} from "@mui/styles";
import {container, defaultFont, grayColor} from "assets/jss/main";

import { ReactComponent as AddSquare } from "assets/img/add-square.svg";
import ModalRecommendationOverloadForm from "./components/ModalRecommendationOverloadForm";

import categoryList from '../../api/category/list'
import {cornerList} from '../../api/corner/list'

import {RecommendationContext} from "./context/RecommendationContext";

import {list as recommendationList} from "api/recommendation/list";

const useStyles = makeStyles(theme => ({
    containerWithSidebar: {
        [theme.breakpoints.up("md")]: {
            marginLeft: "240px",
        }
    },
    container: {
        ...container,
        ...defaultFont
    },
    card: {
        height: window.innerHeight * 0.94
    },
    title: {
        fontSize: '1.25rem',
        color: '#333',
        marginBottom: 'unset'
    },
    subtitle: {
        color: grayColor,
        fontWeight: 600
    },
    overloadActionContainer: {
        display: 'flex',
        flexDirection: 'row-reverse',
        marginTop: '42px',
        marginBottom: '42px',
    },
    overloadAction: {
        display: 'flex',
        justifyContent: 'space-between',
        gap: '5px',
        fontSize: '.875rem',
        fontWeight: '600',
        padding: '12px',
    }
}));

function Recommendations(props) {
    const classes = useStyles();
    const [isOverloadModalOpen, setIsOverloadModalOpen] = React.useState(false);

    const [categories, setCategories] = useState([]);
    const [corners, setCorners] = useState([]);
    const [recommendations, setRecommendations] = useState([]);
    const [isListNeedToBeRefresh, setNeedToRefreshList] = useState(false)
    const [apiLoading, setApiLoading] = useState(false);
    const [apiCategoriesLoading, setApiCategoriesLoading] = useState(false);
    const [apiCornersLoading, setApiCornersLoading] = useState(false);
    const [selectedRecommendation, setSelectedRecommendation] = useState(null);

    useEffect(() => {
        setApiCategoriesLoading(true)
        setApiCornersLoading(true)
        cornerList('enabled=true&exists[eshopId]=true').then((res) => {
            setCorners(res)
            setApiCornersLoading(false)
        })
        categoryList('pagination=false&draft=false&exists[eshopId]=true').then((res) => {
            setCategories(res)
            setApiCategoriesLoading(false)
        })
        getRecommendationList();
    }, [])

    useEffect(() => {
       if (isListNeedToBeRefresh) {
           getRecommendationList()
       }

        setNeedToRefreshList(false);
    }, [isListNeedToBeRefresh]);

    function getRecommendationList() {
        setApiLoading(true);

        recommendationList('order[updatedAt]=desc').then((res) => {
            setRecommendations(res)
            setApiLoading(false);
        })
    }

    return (
        <RecommendationContext.Provider value={{
            corners,
            categories,
            recommendations,
            apiLoading,
            openRecommendationOverloadModal: () =>  setIsOverloadModalOpen(true),
            closeRecommendationOverloadModal: () => {
                setIsOverloadModalOpen(false);
                setSelectedRecommendation(null)
            },
            apiCategoriesLoading,
            apiCornersLoading,
            refreshRecommendationList: () => setNeedToRefreshList(true),
            selectedRecommendation,
            setSelectedRecommendation
        }}>
            <div className={classes.containerWithSidebar}>
                <div className={classes.container}>
                    <GridContainer>
                        <GridItem xs={12}>
                            <Card className={classes.card}>
                                <CardHeader color="info">
                                    <GridContainer>
                                        <GridItem xs={9} sm={10}>
                                            <h2 className={classes.title}>
                                                <FormattedMessage id={'recommendations.form.title'} />
                                            </h2>
                                        </GridItem>
                                    </GridContainer>
                                </CardHeader>
                                <CardBody>
                                    <span className={classes.subtitle}>
                                         <FormattedMessage id={'recommendations.form.subtitle'} />
                                    </span>
                                    <DefaultRecommendationForms />
                                    <GridContainer>
                                        <GridItem xs={12} className={classes.overloadActionContainer}>
                                            <SaveButton
                                                className={classes.overloadAction}
                                                onClick={() => setIsOverloadModalOpen(true)}
                                                disabled={apiLoading || apiCategoriesLoading || apiCornersLoading}
                                            >
                                                <AddSquare />
                                                <FormattedMessage id={'recommendations.action.add_overload'} />
                                            </SaveButton>
                                        </GridItem>
                                    </GridContainer>
                                    <OverrodeRecommendations />
                                </CardBody>
                                <ModalRecommendationOverloadForm open={isOverloadModalOpen} />
                            </Card>
                        </GridItem>
                    </GridContainer>
                </div>
            </div>
        </RecommendationContext.Provider>
    )
}

export default injectIntl(Recommendations)
