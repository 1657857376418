import React, {useContext} from "react";
import {Button, TableCell, } from "@mui/material";
import {withStyles} from "@mui/styles";
import {Check, Close, DeleteOutlined, EditOutlined} from "@mui/icons-material";

import {BeatLoader} from "react-spinners";

import detailStyle from "../../../../../assets/jss/views/quote/detailStyle";
import {primaryColor} from "../../../../../assets/jss/main";
import {QuoteContext} from "../../../../../contexts/quoteContext";

const QuoteDetailGridContentLineAction = ({ classes, editorMode = false, line, isLoading, submitForm, resetForm, setEditorMode, isQuoteEditable}) => {
    const { openDeleteLineModal } = useContext(QuoteContext);
    const { lineBeingEdited, setLineBeingEdited } = useContext(QuoteContext);
    // disallow multiple line editing
    if (null !== lineBeingEdited && lineBeingEdited.id !== line.id) {
        return <TableCell align={"right"}></TableCell>;
    }

    if (isLoading) {
        return (
            <TableCell align={"right"}>
                <BeatLoader
                    sizeUnit={"px"}
                    size={12}
                    color={primaryColor}
                    loading={true}
                />
            </TableCell>
        );
    }

    return (
        <TableCell align={"right"} className={classes.lineItemLineActions}>
            {isQuoteEditable && (
                <>
                    {!editorMode && (
                        <>
                            <Button className={classes.secondary + ' ' + classes.smallButton} onClick={() => {
                                resetForm()
                                setEditorMode(true);
                                setLineBeingEdited(line);
                            }}>
                                <EditOutlined/>
                            </Button>
                        </>
                    )}
                    {editorMode && (
                        <>
                            {!isLoading && (
                                <>
                                    <Button className={classes.primary + ' ' + classes.smallButton} onClick={() => {
                                        submitForm()
                                    }}>
                                        <Check/>
                                    </Button>
                                    <Button className={classes.primary + ' ' + classes.smallButton} onClick={() => {
                                        setLineBeingEdited(null);
                                        resetForm();
                                        setEditorMode(false);
                                    }}>
                                        <Close/>
                                    </Button>
                                </>
                            )}
                        </>
                    )}
                    <Button className={classes.smallButton + ' ' + (editorMode ? classes.primary : classes.secondary)} onClick={() => {
                        openDeleteLineModal(line);
                        setEditorMode(false);
                        setLineBeingEdited(null);
                    }}>
                        <DeleteOutlined/>
                    </Button>
                </>
            )}
        </TableCell>
    );
};

export default withStyles(detailStyle)(QuoteDetailGridContentLineAction);
