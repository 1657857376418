import React from 'react';
import {styled} from '@mui/material/styles';
import {UserAgentUtils} from 'utils/useragent';

const StyledDiv = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '24px',
    flex: '1 0 0',
    width: '319px',
});

export default function GridDetails({...props}) {
    if (UserAgentUtils.isMobile()) {
        return props.children;
    }

    return (
        <StyledDiv>
            {props.children}
        </StyledDiv>
    );
}
