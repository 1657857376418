import {FormattedMessage} from "react-intl";
import React, {Component} from "react";
import {Link} from "react-router-dom";

import {ListItem, ListItemIcon, ListItemText} from "@mui/material";
import {EuroSymbol} from "@mui/icons-material";

class DrawerAccounting extends Component {
    render() {
        const classes = this.props.classes;
        const isSelected = window.location.pathname === (this.props.path ?? '/accounting');

        return (
            <div>
                <ListItem
                    button
                    className={classes.listItem}
                    classes={{selected: classes.selected}}
                    key={"accounting"}
                    selected={isSelected}
                    component={Link}
                    to={this.props.path ?? '/accounting'}
                >
                    <ListItemIcon>
                        <EuroSymbol color={isSelected ? "primary" : "secondary"}/>
                    </ListItemIcon>
                    <ListItemText
                        primaryTypographyProps={{color: isSelected ? "primary" : "secondary"}}
                        primary={<FormattedMessage id={"sidebar.item.accounting"}/>}
                    />
                </ListItem>
            </div>
        )
    }
}

export default DrawerAccounting;
