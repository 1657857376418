import React, {useContext, useEffect, useState} from 'react'
import {makeStyles} from '@mui/styles'
import {FormattedMessage, injectIntl} from 'react-intl'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import {RecommendationContext} from '../../../../../context/RecommendationContext'
import {RecommendationFormContext} from '../../../../../context/RecommendationFormContext'
import {getTranslation} from '../../../../../../../domain/helpers/translations'
import {sortOn} from '../../../../../../../utils/sortOn'
import {borderGrayColor, greyColorHover, infoColor} from '../../../../../../../assets/jss/main'
import FormControlLabel from '../../../../../../../components/radioGroup/FormControlLabel'
import SelectorContainer from './SelectorContainer'
import SelectorTitle from './SelectorTitle'
import ScrollableSelector from './ScrollableSelector'
import Loader from '../../Loader'
import { ReactComponent as ArrowLeft } from 'assets/img/arrow-left.svg'
import { ReactComponent as ArrowDown } from 'assets/img/arrow-down.svg'

const useStyles = makeStyles(theme => ({
    nextPage: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '1.5rem 1rem',
        cursor: 'pointer',
        fontSize: '0.875rem',
        fontWeight: '400',
        border: `1px solid ${borderGrayColor}`,
        borderBottom: '1px solid white',
        background: 'white',
        '&:last-child': {
            borderBottomColor: borderGrayColor,
            borderBottomLeftRadius: '8px',
            borderBottomRightRadius: '8px',
        },
        '&:hover': {
            backgroundColor: greyColorHover,
            border: `1px solid ${infoColor}`,
        },
        '& svg': {
            width: '16px',
            height: '16px',
            transform: 'rotate(-90deg)',
        },
    },
    label: {
        '&:first-of-type': {
            borderTopLeftRadius: '0px',
            borderTopRightRadius: '0px',
        },
    },
    info: {
        textAlign: 'center',
        backgroundColor: 'white',
        padding: '16px',
        border: `1px solid ${borderGrayColor}`,
        borderBottomLeftRadius: '8px',
        borderBottomRightRadius: '8px',
    },
    loader: {
        paddingBottom: '8px',
    },
}))

function CategorySelector(props) {
    const {onClickBack, onSelectedCategory, formFieldName, intl} = props
    const {categories, apiCategoriesLoading} = useContext(RecommendationContext)
    const {getValueByFormFieldName} = useContext(RecommendationFormContext)
    const [pageCategories, setPageCategories] = useState([])
    const [pageCategoryName, setPageCategoryName] = useState(intl.formatMessage({id: "recommendations.form.recommended_product.categories.name"}))
    const [pageFirstCategories, setPageFirstCategories] = useState([])
    const [pageCategoriesByCategoryName, setPageCategoriesByCategoryName] = useState([])
    const [pageCategoryNamesByCategoryName, setPageCategoryNamesByCategoryName] = useState([])
    const selectedCategory = getValueByFormFieldName(formFieldName)
    const classes = useStyles()

    useEffect(() => {
        const topParents = getTopParents(categories)
        setPageCategories(topParents)
        setPageFirstCategories(topParents)
    }, [categories])

    function getTopParents(nodes) {
        const parents = []
        const alreadyParents = []

        nodes.forEach(node => {
            let parent = node.parent
            while (parent) {
                if (parent.parent === null && alreadyParents.indexOf(parent.id) < 0) {
                    parents.push(parent)
                    alreadyParents.push(parent.id)
                }
                parent = parent.parent
            }
        })

        return parents.sort(sortOn('position'))
    }

    function getChildrenAndSelf(nodeToCheck, nodes) {
        const children = []

        nodes.forEach(node => {
            if (node.parent && node.parent.id === nodeToCheck.id) {
                children.push(node)
            }
        })

        children.sort(sortOn('position'))
        children.unshift(nodeToCheck)

        return children
    }

    function isLastNodeAndCurrentPage(nodeToCheck, nodes) {
        if (getTranslation(nodeToCheck).name === pageCategoryName) {
            return true
        }

        for (const i in nodes) {
            const node = nodes[i]

            if (node.parent && node.parent.id === nodeToCheck.id) {
                return false
            }
        }

        return true
    }

    function handlePreviousPage() {
        if (categories.length === 0 || isFirstPage()) {
            onClickBack()
        } else {
            setPageCategoryName(pageCategoryNamesByCategoryName[pageCategoryName])
            setPageCategories(pageCategoriesByCategoryName[pageCategoryName])
        }
    }

    function isFirstPage() {
        return pageCategories[0].id === pageFirstCategories[0].id
    }

    function handleNextPage(category) {
        pageCategoryNamesByCategoryName[getTranslation(category).name] = pageCategoryName
        setPageCategoryNamesByCategoryName(pageCategoryNamesByCategoryName)

        pageCategoriesByCategoryName[getTranslation(category).name] = pageCategories
        setPageCategoriesByCategoryName(pageCategoriesByCategoryName)

        setPageCategoryName(getTranslation(category).name)
        setPageCategories(getChildrenAndSelf(category, categories))
    }

    return (
        <SelectorContainer>
            <SelectorTitle>
                <ArrowLeft onClick={() => handlePreviousPage()} />
                <span>{pageCategoryName}</span>
            </SelectorTitle>
            <ScrollableSelector>
                {apiCategoriesLoading && (
                    <div className={classNames(classes.info, classes.loader)}>
                        <Loader />
                    </div>
                )}
                {!apiCategoriesLoading && categories.length === 0 && (
                    <div className={classes.info}>
                        <FormattedMessage id={"recommendations.form.recommended_product.categories.empty"} />
                    </div>
                )}
                {pageCategories.map((category, index) => isLastNodeAndCurrentPage(category, categories) ? (
                    <FormControlLabel
                        key={index}
                        className={classes.label}
                        label={getTranslation(category).name}
                        checked={selectedCategory && selectedCategory['@type'] === 'Category' && selectedCategory.id === category.id}
                        onClick={() => {
                            onSelectedCategory(category)
                        }}
                    />
                ) : (
                    <div
                        onClick={() => handleNextPage(category)}
                        className={classes.nextPage}
                        key={index}
                    >
                        {getTranslation(category).name}
                        <ArrowDown />
                    </div>
                ))}
            </ScrollableSelector>
        </SelectorContainer>
    )
}

CategorySelector.propTypes = {
    onClickBack: PropTypes.func.isRequired,
    onSelectedCategory: PropTypes.func.isRequired,
    formFieldName: PropTypes.string.isRequired,
}

export default injectIntl(CategorySelector)
