import {
    fetch,
    normalize
} from "../../utils/dataAccess";

import {success as createSuccess} from "./create";
import {success as deleteSuccess} from "./delete";
import {success as createMapperSuccess} from "./mapper/create";
import {success as deleteMapperSuccess} from "./mapper/delete";
import {success as importingSuccess} from "./importProduct";

export function error(error) {
    return {type: "FEED_LIST_ERROR", error};
}

export function loading(loading) {
    return {type: "FEED_LIST_LOADING", loading};
}

export function success(retrieved) {
    return {type: "FEED_LIST_SUCCESS", retrieved};
}

export function list(retailer) {
    return dispatch => {
        dispatch(createSuccess(null));
        dispatch(createMapperSuccess(null));
        dispatch(loading(true));
        dispatch(error(""));

        fetch("/feeds?retailer.id=" + retailer + "&pagination=false")
            .then(response =>
                response.json()
            )
            .then((retrieved) => {
                const feeds = normalize(retrieved)["hydra:member"];

                dispatch(loading(false));
                dispatch(success(feeds));
                dispatch(deleteMapperSuccess(null));
            })
            .catch(e => {
                dispatch(loading(false));
                dispatch(error(e.message));
            });
    };
}

export function reset() {
    return dispatch => {
        dispatch({type: "FEED_LIST_RESET"});
        dispatch(deleteSuccess(null));
        dispatch(deleteMapperSuccess(null));
        dispatch(createSuccess(null));
        dispatch(createMapperSuccess(null));
        dispatch(importingSuccess(null));
    };
}