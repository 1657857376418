import {combineReducers} from "redux";

export function error(state = null, action) {
    switch (action.type) {
        case "DICTIONARIES_ERROR":
            return action.error;

        default:
            return state;
    }
}

export function loading(state = false, action) {
    switch (action.type) {
        case "DICTIONARIES_LOADING":
            return action.loading;

        default:
            return state;
    }
}

export function retrieved(state = false, action) {
    switch (action.type) {
        case "DICTIONARIES_SUCCESS":
            return action.retrieved;

        default:
            return state;
    }
}

export function getDictionaryByType(dictionaries, type) {
    if (!Array.isArray(dictionaries)) {
        return null;
    }

    const dic = dictionaries.find(dictionary => !!dictionary.type && type === dictionary.type);

    if (!dic) {
        return [];
    }

    return dic.items;
}

export default combineReducers({error, loading, retrieved});
