import React, { useState } from "react";

// @mui/material components
import {makeStyles} from "@mui/styles";

// core components
import Children from "./children";
import CreateDialog from './createDialog';
import DeleteDialog from './deleteDialog';
import EditDialog from './editDialog';
import MoveDialog from "./moveDialog";
import NoResult from '../../configuration/components/noResult';
import { default as Popover } from '../../configuration/components/popover';

// action
import { categoryExist } from "actions/category/list";

// helpers
import {sortOn} from "utils/sortOn";
import {getTranslation} from "../../../domain/helpers/translations";

// styles
const useStyles = makeStyles(theme => ({
    removeUlPadding: {
        padding: 0,
        margin: 0,
        listStyleType: "none"
    },
}));

export default function EnhancedTableBody (props) {
    const { checkedCategories, retrieved, search, setCheckedCategories } = props;

    const classes = useStyles();

    const [createSubDialog, showCreateSubDialog] = useState(false);
    const [deleteDialog, showDeleteDialog] = useState(false);
    const [editDialog, showEditDialog] = useState(false);
    const [moveDialog, showMoveDialog] = useState(false);

    const [categorySelected, setCategorySelected] = useState(null);
    const [categoryTablePosition, setCategoryTablePosition] = useState(null);

    const [openPopover, setOpenPopover] = useState(false);

    function getTopParentsBySearch(retrieved) {
        let parents = [];
        let categoryRetrieved = retrieved;

        if (search !== '') {
            categoryRetrieved = retrieved.filter(category => getTranslation(category).name.toLowerCase().includes(search));
        }

        // if search is not null: extract all top parent where one of child match with search
        categoryRetrieved.forEach(category => {
            while (category && category.parent !== null) {
                category = category.parent;

                if (category && category.parent === null && !categoryExist(category, parents)) {
                    parents.push(category);
                }
            }

            if (category && category.parent === null && !categoryExist(category, parents)) {
                parents.push(category);
            }
        });

        return parents.sort(sortOn("position"));
    }

    return (
        <div className={classes.removeUlPadding}>
            {(retrieved && retrieved.length > 0) && (
                <Children
                    children={getTopParentsBySearch(retrieved)}
                    allCategories={retrieved}
                    openCreatedDialog={() => showCreateSubDialog(true)}
                    selectCategory={setCategorySelected}
                    setCategoryTablePosition={setCategoryTablePosition}
                    openPopover={() => setOpenPopover(true)}
                    search={search}
                    checkedCategories={checkedCategories}
                    setCheckedCategories={setCheckedCategories}
                    isTopParent={true}
                />
            )}
            {retrieved && retrieved.length === 0 && (
                <NoResult />
            )}
            {categorySelected && (
                <div>
                    <CreateDialog
                        isOpen={createSubDialog}
                        closeDialog={() => showCreateSubDialog(false)}
                        category={categorySelected}
                        isSubCategory={true}
                    />
                    <DeleteDialog
                        isOpen={deleteDialog}
                        closeDialog={() => showDeleteDialog(false)}
                        category={categorySelected}
                    />
                    <EditDialog
                        isOpen={editDialog}
                        closeDialog={() => showEditDialog(false)}
                        category={categorySelected}
                        isSubCategory={categorySelected.parent !== null}
                    />
                    <MoveDialog
                        isOpen={moveDialog}
                        closeDialog={() => showMoveDialog(false)}
                        categorySelected={categorySelected}
                        allCategories={retrieved}
                    />
                </div>
            )}
            <Popover
                isPopoverOpen={openPopover}
                closePopover={() => setOpenPopover(false)}
                position={categoryTablePosition}
                openDeleteDialog={() => showDeleteDialog(true)}
                openEditDialog={() => showEditDialog(true)}
                openMoveDialog={() => showMoveDialog(true)}
                attributeName={'category'}
            />
        </div>
    );
}
