import {fetch} from "../../../utils/dataAccess";

export default async function updateLineItem(lineItemId, unitPrice, tvaId, discount, units) {
    let body;
    if (units.length) {
        body = {
            unitPrice: unitPrice,
            taxRate: '/tax_rates/' + tvaId,
            discount: discount.toString(),
            units: units
        };
    } else {
        body = {
            unitPrice: unitPrice,
            discount: discount.toString(),
            taxRate: '/tax_rates/' + tvaId,
        };
    }

    const response = await fetch(`/quote_line_items/${lineItemId}`, {method: "PUT", body: JSON.stringify(body)});
    return await response.json();
}
