import React, {useContext} from 'react'
import PropTypes from "prop-types";

import RunnerAvatar from "./RunnerAvatar";

import {OrderContext} from "contexts/OrderContext";

export default function RunnerAvatarContainer({lineItem}) {
    const {
        handleShowAssignButton,
        setShowAssignButtonForLineItem
    } = useContext(OrderContext);

    return (
        <div
            onMouseEnter={() => handleShowAssignButton(lineItem)}
            onMouseLeave={() => setShowAssignButtonForLineItem(null)}
        >
            <RunnerAvatar lineItem={lineItem} />
        </div>
    )
}

RunnerAvatarContainer.defaultProps = {
    lineItem: {}
}

RunnerAvatarContainer.propTypes = {
    lineItem: PropTypes.object
}
