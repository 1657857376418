import React from "react";

// @mui/material components
import {withStyles} from "@mui/styles";
import { AppBar, Toolbar} from "@mui/material";

// styles
import UnauthenticatedBarStyle from "../../assets/jss/components/topbar/unauthenticatedBarStyle";
import logo from "../../assets/img/logo.png";

class UnauthenticatedBar extends React.Component {
    render() {
        const {classes} = this.props;
        return (
            <div>
                <AppBar className={classes.appBar} position="fixed" color="default">
                    <Toolbar>
                        <div className={classes.logo_container}><img className={classes.logo} src={logo} alt={"#"}/></div>
                    </Toolbar>
                </AppBar>
                <div className={classes.toolbar}/>
            </div>
        );
    }
}

export default withStyles(UnauthenticatedBarStyle)(UnauthenticatedBar);
