import {fetch, normalize} from "../../utils/dataAccess";

import {updateSuccess} from "./update";

export function error(error) {
    return {type: "PHYSICAL_ENTRY_LIST_ERROR", error};
}

export function loading(loading) {
    return {type: "PHYSICAL_ENTRY_LIST_LOADING", loading};
}

export function success(retrieved) {
    return {type: "PHYSICAL_ENTRY_LIST_SUCCESS", retrieved};
}

export function list(params = "") {
    const url = "/physical_entries?" + params;

    return dispatch => {
        dispatch(success(null));
        dispatch(updateSuccess(null));
        dispatch(loading(true));
        dispatch(error(""));

        fetch(url)
            .then(response =>
                response.json()
            )
            .then((retrieved) => {
                retrieved = normalize(retrieved);

                dispatch(loading(false));
                dispatch(success(retrieved));
            })
            .catch(e => {
                dispatch(loading(false));
                dispatch(error(e.message));
            });
    };
}

export function reset() {
    return dispatch => {
        dispatch({type: "PHYSICAL_ENTRY_RESET"});
    };
}