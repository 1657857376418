import React from "react";
import {makeStyles} from "@mui/styles";

import {
    lightGrayColor,
    thunderGrayColor,
} from "assets/jss/main";

const useStyles = makeStyles(theme => ({
    cardStatsTitle: {
        marginTop: "15px",
        color: thunderGrayColor,
        borderBottom: "1px solid " + lightGrayColor,
        paddingBottom: "15px",
        fontSize: "16px",
        lineHeight: "1.4em"
    },
}));

export default function CardStatsTitle({...props}) {
    const classes = useStyles();

    const { children } = props;

    return (
        <div className={classes.cardStatsTitle}>
            {children}
        </div>
    )
}
