import {
    container,
    defaultFont,
    thunderGrayColor,
} from "../../main";

const dashboardStyle = theme => ({
    containerWithSidebar: {
        [theme.breakpoints.up("md")]: {
            marginLeft: "240px"
        }
    },
    container: {
        ...container,
        ...defaultFont,
        marginTop: "30px"
    },
    dashboardHeader: {
        marginBottom: "30px",
        display: "flex",
        justifyContent: "space-between"
    },
    "@media (max-width: 768px)": {
        dashboardHeader: {
            display: "block"
        }
    },
    cardContainer: {
        display: "flex"
    },
    rankingTableTitle: {
        color: thunderGrayColor,
        fontSize: "18px",
        paddingBottom: "10px"
    },
});

export default dashboardStyle;
