import {fetch, normalize} from "utils/dataAccess";

export function error(error) {
    return {type: "ROLE_UPDATE_ERROR", error};
}

export function loading(loading) {
    return {type: "ROLE_UPDATE_LOADING", loading};
}

export function success(updated) {
    return {type: "ROLE_UPDATE_SUCCESS", updated};
}

export const update = (item, values) => dispatch => {
    dispatch(loading(true));

    return fetch(item["@id"], {
        method: 'PUT',
        headers: new Headers({'Content-Type': 'application/ld+json'}),
        body: JSON.stringify(normalize(values))
    })
        .then(response => {
            response.json().then(retrieved => {
                dispatch(loading(false));
                dispatch(success(retrieved));
            })
        })
        .catch((e) => {
            dispatch(loading(false));
            dispatch(error(e));
        });
};