import React from "react";
import PropTypes from 'prop-types';
import {FormattedMessage} from "react-intl";
import * as moment from "moment/moment";

import {makeStyles} from "@mui/styles";

import {primaryColor, thunderGrayColor} from "assets/jss/main";

import {capitalize} from "utils/capitalize";

const useStyles = makeStyles(theme => ({
    welcomeTitle: {
        fontSize: "28px",
        color: primaryColor
    },
    welcomeSubTitle: {
        paddingTop: "5px",
        color: thunderGrayColor,
        fontSize: "14px"
    },
}));

export default function GreetMessage(props) {
    const classes = useStyles();

    const { name } = props

    const date = new Date();
    const hours = date.getHours();

    const givenName = capitalize(name);

    let dayMoment = 'morning'

    if (name) {
        if (hours < 6) {
            dayMoment = 'night';
        } else if (hours > 12 && hours <= 17) {
            dayMoment = 'afternoon';
        } else if (hours > 17 && hours < 24) {
            dayMoment = 'evening';
        }
    }

    return (
        <div className={classes.welcomeTitle}>
            <FormattedMessage
                id={`dashboard.title.greeting.${dayMoment}`}
                values={{name: givenName}}
            />
            <div className={classes.welcomeSubTitle}>
                <FormattedMessage
                    id={"dashboard.subtitle"}
                    values={{date: moment(new Date()).format("D MMMM")}}
                />
            </div>
        </div>
    )
}

GreetMessage.defaultProps = {
    name: ""
};

GreetMessage.propTypes = {
    name: PropTypes.string.isRequired
};
