import React, {Component} from "react";
import connect from "react-redux/lib/connect/connect";
import {FormattedMessage, injectIntl} from "react-intl";
import { v4 as uuidv4 } from 'uuid';

// @mui/material components
import {Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@mui/material";

// core components
import BarcodeScanner from "../../../../../../../components/barcodeScanner";
import Button from "../../../../../../../components/button/button";
import CustomCreatableSelect from "../../../../../../../components/select/creatableSelect";
import GridContainer from "../../../../../../../components/grid/gridContainer";
import GridItem from "../../../../../../../components/grid/gridItem";

// style
import CreateProductFormStyle from "../../../../../../../assets/jss/views/stockeasy/product/create/form/createProductFormStyle";
import { ReactComponent as BarcodeScannerIcon } from 'assets/img/barcode_scanner.svg';

import {formatOptions} from "../../form/selectHelper";
import {withStyles} from "@mui/styles";

// utils
import {UserAgentUtils} from "../../../../../../../utils/useragent";
import {getTranslation} from "../../../../../../../domain/helpers/translations";

// actions
import { colorList } from "actions/color/list";
import { sizeList } from "actions/size/list";

import localStorageSE from "../../localStorageManager/localStorageSE";
import {redirectToStockEasyApp} from "../../../../../../../utils/redirectToStockEasyApp";

class CreateVariationModalContent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            barcode: props.selectedVariation ? props.selectedVariation.barcode : props.barcode ?? '',
            color: props.selectedVariation ? props.selectedVariation.color : '',
            size: props.selectedVariation ? props.selectedVariation.size : '',
            sku: props.selectedVariation ? props.selectedVariation.sku : uuidv4(),
            weight: props.selectedVariation ? props.selectedVariation.weight : null,
            barcodeErrorMessage: null,
            barcodeScanActive: false,
        }
    }

    componentDidMount() {
        this.props.colorList('order[name]=asc&pagination=false&draft=false');
        this.props.sizeList('order[position]=asc&pagination=false&draft=false');
    }

    onBarcodeChange(barcode) {
        this.setState({
            barcode: barcode,
        });
    }

    /* ---------------- COLORS --------------- */
    onColorChange(color) {
        this.setState({
            color: color.value,
        });
    }

    getColors() {
        return formatOptions(this.props.colors.map(color => getTranslation(color).name));
    }

    /* ---------------- SIZES --------------- */
    getSizes() {
        return formatOptions(this.props.sizes.map(size => getTranslation(size).value));
    }

    onSizeChange(size) {
        this.setState({
            size: size.value,
        });
    }

    onWeightChange(event) {
        this.setState({
            weight: event.target.value,
        });
    }

    isBarcodeValid(barcode) {
        const {intl, selectedVariation} = this.props;
        const productData = localStorageSE.getProductData();

        if (barcode === null) {
            this.setState({
                barcodeErrorMessage: intl.formatMessage({id: "stockeasy.color.validation.barcode.exists"})
            });

            return false;
        }

        // no change on selected variation
        if (selectedVariation && selectedVariation.barcode === barcode) {
            return true;
        }

        // if it's not a barcode but a sku
        if (productData.variations[0] && productData.variations[0].sku !== null && productData.variations[0].barcode === null) {
            return true;
        }

        const regex = new RegExp('^[0-9]{13}$');

        if (!regex.test(barcode)){
            this.setState({
                barcodeErrorMessage: intl.formatMessage({id: "stockeasy.color.validation.barcode.length"})
            });

            return false;
        }

        const duplicate = productData.variations.some((variation) => variation.sku === barcode);

        if (duplicate) {
            this.setState({
                barcodeErrorMessage: intl.formatMessage({id: "stockeasy.color.validation.barcode.duplicate"})
            });

            return false;
        }

        return true;
    }

    isSizeValid(color, size) {
        const {intl} = this.props;

        if (size === '') {
            this.setState({
                barcodeErrorMessage: intl.formatMessage({id: "stockeasy.color.sizes.error.empty"})
            });

            return false;
        }

        return true;
    }

    isWeightValid(weight) {
        const {intl} = this.props;

        if (null === weight || weight === '' || weight <= 0) {
            this.setState({
                barcodeErrorMessage: intl.formatMessage({id: "stockeasy.weight.error.empty"})
            });

            return false;
        }

        return true;
    }

    isVariationExist(color, size) {
        const {productData, intl, selectedVariation} = this.props;

        // if no change on selected variation
        if (selectedVariation && (color+size === selectedVariation.color+selectedVariation.size)) {
            return false;
        }

        const variationExist = productData.variations.some((variation) => variation.barcode !== variation.color+variation.size === color+size);

        if (variationExist) {
            this.setState({
                barcodeErrorMessage: intl.formatMessage({id: "stockeasy.color.validation.color_size.uniqueness.error"})
            });

            return true;
        }

        return false;
    }

    persistVariation() {
        const {barcode, color, size, sku, weight} = this.state;
        const productData = localStorageSE.getProductData();

        const isBarcodeValid = this.isBarcodeValid(barcode);
        const isWeightValid = this.isWeightValid(weight);
        const isSizeValid = this.isSizeValid(color, size);
        const isVariationExist = this.isVariationExist(color, size);

        if ((isBarcodeValid || (sku !== null && productData.isProductWithoutBarCode)) && isSizeValid && isWeightValid && isVariationExist === false) {
            this.props.submit(barcode, color, size, sku, weight)
        }
    }

    handleBarcodeScanned(barcode) {
        this.setState({
            barcodeScanActive: false,
            barcode: barcode,
        });
    }

    renderBarcodeScanner() {
        const {barcodeScanActive} = this.state;
        const productData = localStorageSE.getProductData();
        const isVariationWithoutBarcode = productData.isProductWithoutBarCode;
        if (!UserAgentUtils.isMobileOrTablet() || isVariationWithoutBarcode || !barcodeScanActive) {
            return;
        }

        return (
            <Dialog
                open={barcodeScanActive}
                aria-labelledby="responsive-dialog-title"
                onClose={() => this.setState({barcodeScanActive: false})}
            >
                <DialogContent>
                    <BarcodeScanner
                        hideButtons
                        onBarcodeFound={(barcode) => this.handleBarcodeScanned(barcode)}
                        onExit={() => this.setState({barcodeScanActive: false})}
                        allowLoopScanning={true}
                    />
                </DialogContent>
            </Dialog>
        );
    }

    render() {
        const {classes, intl, selectedVariation} = this.props;
        const productData = localStorageSE.getProductData();
        const isVariationWithoutBarcode = productData.isProductWithoutBarCode;
        const isSelectedVariationWithBarcode = selectedVariation && selectedVariation.barcode;

        return (
            <div>
                <DialogTitle id="form-dialog-title">
                    <FormattedMessage id={"stockeasy.variation_create.dialog.title"} />
                </DialogTitle>

                {this.renderBarcodeScanner()}
                <DialogContent className={classes.dialogContentBarcode}>
                    {isVariationWithoutBarcode ?
                        <TextField
                            variant="standard"
                            id="sku"
                            label={intl.formatMessage({id: "stockeasy.sku.label"})}
                            autoFocus
                            margin="dense"
                            type="text"
                            fullWidth
                            disabled={true}
                            defaultValue={selectedVariation && selectedVariation.sku ? selectedVariation.sku : this.state.sku}
                            required />
                        :
                        (<div>
                            {(UserAgentUtils.isMobileOrTablet() && !isSelectedVariationWithBarcode ) && (
                                <Button
                                    round
                                    className={classes.scanButton}
                                    onClick={() => redirectToStockEasyApp(true) }
                                >
                                    <BarcodeScannerIcon className={classes.scanIcon}/>
                                </Button>
                            )}
                            <TextField
                                variant="standard"
                                id="barcode"
                                label={intl.formatMessage({id: "stockeasy.barcode.label"})}
                                autoFocus
                                margin="dense"
                                type="text"
                                fullWidth
                                disabled={selectedVariation && selectedVariation.default}
                                defaultValue={this.state.barcode || (selectedVariation && selectedVariation.barcode)}
                                onChange={(e) => this.onBarcodeChange(e.target.value)}
                                required />
                        </div>
                        )
                    }
                    <CustomCreatableSelect
                        id="colors"
                        label={intl.formatMessage({id: "stockeasy.colors.color.label"})}
                        onChange={(color) => this.onColorChange(color)}
                        options={this.props.colors && this.getColors()}
                        value={this.state.color ? formatOptions([this.state.color]) : null}
                        maxMenuHeight={250}
                        formatCreateLabel={(name) => intl.formatMessage({id: "stockeasy.color.color.add"})+' "'+name+'"'}
                        className={classes.textField}
                        noOptionsMessage={intl.formatMessage({id: "stockeasy.color.color.add_color"})}
                        placeholder={intl.formatMessage({id: "stockeasy.color.color.select_color"})}
                    />
                    <CustomCreatableSelect
                        id="sizes"
                        label={intl.formatMessage({id: "stockeasy.color.sizes.label"})}
                        required
                        onChange={(size) => this.onSizeChange(size)}
                        options={this.props.sizes && this.getSizes()}
                        value={this.state.size ? formatOptions([this.state.size]) : null}
                        maxMenuHeight={250}
                        formatCreateLabel={(name) => intl.formatMessage({id: "stockeasy.color.sizes.add"})+' "'+name+'"'}
                        className={classes.textField}
                        noOptionsMessage={intl.formatMessage({id: "stockeasy.color.size.add_size"})}
                        placeholder={intl.formatMessage({id: "stockeasy.color.size.select_size"})}
                    />
                    <TextField
                      id="weight"
                      label={intl.formatMessage({id: "stockeasy.variation_create.weight"})}
                      placeholder={intl.formatMessage({id: "stockeasy.variation_create.weight.placeholder"})}
                      variant="outlined"
                      type="number"
                      fullWidth
                      value={this.state.weight}
                      className={classes.modalTextField}
                      onChange={(event) => { this.onWeightChange(event) }}
                      InputLabelProps={{shrink: true}}
                      required
                    />
                </DialogContent>
                <DialogActions className={classes.dialogVariationActions}>
                    <GridContainer className={classes.buttonsWrapper}>
                        <GridItem className={classes.buttonWrapper}>
                            <Button
                                round
                                color={"success"}
                                onClick={() => this.persistVariation()}
                                className={classes.buttonAction}
                            >
                                {intl.formatMessage({id:"stockeasy.barcode.dialog.button"})}
                            </Button>
                        </GridItem>
                        {(selectedVariation && selectedVariation.default === false) && (
                            <GridItem className={classes.buttonWrapper}>
                                <Button
                                    round
                                    color={"danger"}
                                    onClick={() => this.props.delete()}
                                    className={classes.buttonAction}
                                >
                                    {intl.formatMessage({id:"stockeasy.color.sizes.button.delete"})}
                                </Button>
                            </GridItem>
                        )}
                    </GridContainer>
                    {this.state.barcodeErrorMessage !== null && (
                        <span className={classes.error}>
                            {this.state.barcodeErrorMessage}
                        </span>
                    )}
                </DialogActions>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        colors: state.color.list.retrieved,
        sizes: state.size.list.retrieved,
    };
};

const mapDispatchToProps = dispatch => ({
    colorList: params => dispatch(colorList(params)),
    sizeList: params => dispatch(sizeList(params)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withStyles(CreateProductFormStyle)(injectIntl(CreateVariationModalContent)));
