import React, {useContext, useState} from 'react'
import {FormattedMessage} from 'react-intl'
import PropTypes from 'prop-types'
import {ClickAwayListener} from '@mui/material'

import FakeSelectInput from "components/radioGroup/FakeSelectInput"

import Selectors from './components/Selectors'
import Breadcrumb from './components/Breadcrumb'

import {getTranslation} from 'domain/helpers/translations'

import {RecommendationFormContext} from '../../../../context/RecommendationFormContext'

export default function Index(props) {
    const {formFieldName} = props
    const {getValueByFormFieldName} = useContext(RecommendationFormContext)
    const selectedNode = getValueByFormFieldName(formFieldName)
    const [opened, setOpen] = useState(false)

    return (
        <ClickAwayListener onClickAway={() => setOpen(false)}>
            <div>
                <FakeSelectInput
                    onClick={() => setOpen(!opened)}
                    open={opened}
                    text={selectedNode ? getTranslation(selectedNode).name : <FormattedMessage id={"recommendations.form.recommended_product.all_products"} />}
                />
                {opened && (
                    <Selectors
                        onSelectedNode={() => {
                            setOpen(false)
                        }}
                        formFieldName={formFieldName}
                    />
                )}

                <Breadcrumb
                    node={selectedNode}
                />
            </div>
        </ClickAwayListener>
    )
}

Index.propTypes = {
    formFieldName: PropTypes.string.isRequired,
}
