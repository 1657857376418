import React from 'react';
import {FormattedMessage} from "react-intl";

import {fifteenthGrey} from "assets/jss/main";

export default function Title() {
    return (
        <>
            <h2 style={{
                fontSize: '24px',
                color: fifteenthGrey,
                marginBottom: "unset"
            }}>
                <FormattedMessage id={'picking.printers.title'} />
            </h2>
            <span style={{fontSize: "14px", textTransform: 'initial'}}>
                <FormattedMessage id={'picking.printers.subtitle'} />
            </span>
        </>
    );
}
