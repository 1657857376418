import {primaryColor, boxShadow} from "assets/jss/main";

const attributesStyle = theme => ({
    containerWithSidebar: {
        [theme.breakpoints.up("md")]: {
            marginLeft: "240px",
        }
    },
    formContainer: {
        marginTop: 20,
        minHeight: window.innerHeight * 0.84,

        "& > div:first-child": {
            background: "transparent",
            height: "150px"
        }
    },
    compareArrows: {
        color: primaryColor,
    },
    actionsContainer: {
        textAlign: "right",
    },
    propagateLoader: {
        left: "0",
        right: "0",
        margin: "0 auto",
        top: "15%",
        display: "flex",
        textAlign: "center",
        justifyContent: "center",
    },
    selectedStepper: {
        "& span": {
            color: `${primaryColor}!important`
        },
        "& span svg:nth-child(1)": {
            fill: `${primaryColor}!important`,
            fontSize: "50px",
            "& > text": {
                display: "none"
            },
        },

    },
    stepper: {
        "& > span:first-child > span:first-child ": {
            borderRadius: "50%",
            ...boxShadow
        },
        "& span svg:nth-child(1)": {
            fill: "white",
            fontSize: "50px",

            "& > text": {
                display: "none"
            },
        },
    },
    step: {
        "& > div:first-child": {
            top: "25px",
            left: "calc(-50% + 27px)"
        },
    },
    labelStyle: {
        height: "calc(100vh - 280px)",
        padding: "0px 15px"
    }
});

export default attributesStyle;
