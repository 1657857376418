import React from "react";
import {makeStyles} from "@mui/styles";

import { primaryColor } from "assets/jss/main";

const useStyles = makeStyles(theme => ({
    cardStatsValue: {
        fontSize: "26px",
        color: primaryColor
    },
}));

export default function CardStatsValue({...props}) {
    const classes = useStyles();

    const { children } = props;

    return (
        <div className={classes.cardStatsValue}>
            {children}
        </div>
    )
}
