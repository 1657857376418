import {combineReducers} from "redux";

export function selected(state = [], action) {
    switch (action.type) {
        case "SET_PRODUCT_SELECTION":
            return action.selected;
        case "RESET_PRODUCT_SELECTION":
            return [];
        default:
            return state;
    }
}

export function isSelectAll(state = false, action) {
    switch (action.type) {
        case "SET_IS_SELECT_ALL_PRODUCTS":
            return action.isSelectAll;
        default:
            return state;
    }
}

export default combineReducers({selected, isSelectAll});
