// ##############################
// // // Variables - Styles that are used on more than one component
// #############################

const drawerWidth = 260;

const transition = {
    transition: "all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
};

const containerFluid = {
    paddingRight: "15px",
    paddingLeft: "15px",
    marginRight: "auto",
    marginLeft: "auto",
    width: "100%"
};
const container = {
    ...containerFluid,
    "@media (min-width: 576px)": {
        maxWidth: "780px"
    },
    "@media (min-width: 768px)": {
        maxWidth: "980px"
    },
    "@media (min-width: 992px)": {
        maxWidth: "1260px"
    },
    "@media (min-width: 1200px)": {
        maxWidth: "1680px"
    },
    "@media (min-width: 1600px)": {
        maxWidth: "1980px"
    }
};

const boxShadow = {
    boxShadow:
        "0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
};

const card = {
    display: "inline-block",
    position: "relative",
    width: "100%",
    margin: "25px 0",
    boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.14)",
    borderRadius: "3px",
    color: "rgba(0, 0, 0, 0.87)",
    background: "#fff"
};

const defaultFont = {
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    fontWeight: "300",
    lineHeight: "1.5em"
};

const successColor = "#5DD167";   // rgb(93, 209, 103) -> Green
const successColorDisabled = 'rgba(93, 209, 103, 0.20)';
const mantisGreenColor = "#78BC61";   // rgb(120, 188, 97) -> Mantis Green
const greenColor = "#21BA8C"
const green500 = '#2DB98D';
const crystalGreenColor = "#ecf9ed";   // rgb(236, 249, 255) -> Crystal Green
const primaryColor = "#0052cb";   // rgb(0,82,203) -> Dark Blue
const blueColor = "#3C81FB";
const blue25 = '#F7FAFF';
const blue50 = '#EBF2FE';
const infoColor = "#377dff";      // rgb(55, 125, 255) -> Blue
const infoColorHover = "#80ACFE"
const infoColorDisabled = '#C2D8FF'
const irisBlueColor = "#00acc1";      // rgb(0, 172, 193) -> Iris Blue
const portageBlueColor = "#788DE2"; // rgb(120, 141, 226) -> Portage Blue
const secondaryColor = "#7cacff"; // rgb(124, 172, 255) -> Light Blue
const lightBlueColor = "#ADD6F1";
const aliceBlueColor = "#F2FAFF"; // rgb(242, 250, 255) -> Alice Blue
const red50 = '#FFEBEB';
const red500 = '#FC3A3F';
const dangerColor = '#FF3737'; // must be replace by red500
const dangerColorHover = '#FF8282';
const dangerColorDisabled = '#FFCFCF';
const orangeColor = '#F4511E';
const orangeReturn = '#FE974C';
const crystalRedColor = "#ffecec";    // rgb(255, 236, 255) -> Crystal Red
const terraCottaColor = "#ED6A5A";    // rgb(237, 106, 90) -> Terra Cotta
const warningColor = "#ff9800" ;   // rgb(255, 152, 0) -> Orange
const yellowColor = "#e6c229" ;   // rgb(230, 194, 41) -> Yellow
const roseColor = "#e91e63";      // rgb(233, 30, 99) -> Pink
const purpleColor = "#762EB9";      // rgb(118, 46, 185) -> Purple
const blackMagicColor = "#252422";      // rgb(37, 36, 34) -> Black magic
const lighterBlack = "#355C7D";
const stormGrayColor = "#2d333a";  // rgb(45, 51, 58) -> Storm Grey
const thunderGrayColor = "#4a4a49";  // rgb(74, 74, 73) -> Thunder Grey
const radioCircleGrey = "#474747";
const darkGrayColor = "#495057";  // rgb(73, 80, 87) -> Dark Grey
const elevenGrey = '#6C727E';
const twelveGrey = '#F5F6F8';
const thirteenthGrey = '#EAEBEF';
const fourteenthGrey = '#838383'; // must be replace by greyishBlue500
const fifteenthGrey = '#333'; // must be replace by black500
const sixteenGrey = '#6D6D6D';
const sevenTeenGrey = '#FAFAFA';
const grey200 = '#F7F7F7';
const grey500 = '#EDEDED';
const greyishBlue50 = '#F4F4F6';
const greyishBlue200 = '#CCCFD6'
const greyishBlue300 = '#B6BAC4';
const greyishBlue400 = '#9CA3AE';
const greyishBlue500 = '#838B9B';
const grayColor = "#999999";      // rgb(153, 153, 153) -> Grey
const mediumGrayColor = "#c2c8d0";      // rgb(194, 200, 208) -> Medium Grey
const borderGrayColor = "#E6E6E6"
const greyColorHover = "#fbfcff";
const whiteColorHover = "#F4F4F4";
const whiteColorDisabled = "#FBFBFB";
const lightGrayColor = "#eee";    // rgb(238, 238, 238) -> Light Grey
const sidebarColor ='#FFFFFF';
const backgroundAppColor = '#f5f5f5';
const veryLightBlue = '#F6F9FE';
const black20 = '#EBEBEB';
const black100 = '#D6D6D6';
const black500 = '#333333';

const primaryBoxShadow = {
    boxShadow:
        "0 12px 20px -10px rgba(0, 82, 203, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(0, 82, 203, 0.2)"
};
const infoBoxShadow = {
    boxShadow:
        "0 12px 20px -10px rgba(55, 125, 255, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(55, 125, 255, 0.2)"
};
const successBoxShadow = {
    boxShadow:
        "0 12px 20px -10px rgba(76, 175, 80, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(76, 175, 80, 0.2)"
};
const warningBoxShadow = {
    boxShadow:
        "0 12px 20px -10px rgba(255, 152, 0, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(255, 152, 0, 0.2)"
};
const dangerBoxShadow = {
    boxShadow:
        "0 12px 20px -10px rgba(244, 67, 54, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(244, 67, 54, 0.2)"
};
const roseBoxShadow = {
    boxShadow:
        "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(233, 30, 99, 0.4)"
};

const warningCardHeader = {
    color: "#fff",
    background: "linear-gradient(60deg, #ffa726, #fb8c00)",
    ...warningBoxShadow
};
const successCardHeader = {
    color: "#fff",
    background: "linear-gradient(60deg, #66bb6a, #43a047)",
    ...successBoxShadow
};
const dangerCardHeader = {
    color: "#fff",
    background: "linear-gradient(60deg, #ef5350, #e53935)",
    ...dangerBoxShadow
};
const infoCardHeader = {
    color: "#4a4a49",
};
const primaryCardHeader = {
    color: "#fff",
    background: "linear-gradient(60deg, #ab47bc, #8e24aa)",
    ...primaryBoxShadow
};
const roseCardHeader = {
    color: "#fff",
    background: "linear-gradient(60deg, #ec407a, #d81b60)",
    ...roseBoxShadow
};
const cardActions = {
    margin: "0 20px 10px",
    paddingTop: "10px",
    borderTop: "1px solid #eeeeee",
    height: "auto",
    ...defaultFont
};

const cardHeader = {
    margin: "-30px 15px 0",
    borderRadius: "3px",
    padding: "15px"
};

const defaultBoxShadow = {
    border: "0",
    borderRadius: "3px",
    boxShadow:
        "0 10px 20px -12px rgba(0, 0, 0, 0.42), 0 3px 20px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
    padding: "10px 0",
    transition: "all 150ms ease 0s"
};

const title = {
    color: "#3C4858",
    margin: "1.75rem 0 0.875rem",
    textDecoration: "none",
    fontWeight: "700",
    fontFamily: `"Roboto Slab", "Times New Roman", serif`
};

const cardTitle = {
    ...title,
    marginTop: ".625rem"
};

const cardLink = {
    "& + $cardLink": {
        marginLeft: "1.25rem"
    }
};

const cardSubtitle = {
    marginBottom: "0",
    marginTop: "-.375rem"
};

export {
    //variables
    drawerWidth,
    transition,
    container,
    containerFluid,
    boxShadow,
    card,
    defaultFont,
    primaryColor,
    secondaryColor,
    warningColor,
    dangerColor,
    dangerColorHover,
    dangerColorDisabled,
    orangeColor,
    crystalRedColor,
    terraCottaColor,
    successColor,
    successColorDisabled,
    mantisGreenColor,
    greenColor,
    green500,
    crystalGreenColor,
    infoColor,
    purpleColor,
    blueColor,
    blue25,
    blue50,
    roseColor,
    lightBlueColor,
    aliceBlueColor,
    irisBlueColor,
    portageBlueColor,
    mediumGrayColor,
    grayColor,
    stormGrayColor,
    darkGrayColor,
    thunderGrayColor,
    lightGrayColor,
    primaryBoxShadow,
    infoBoxShadow,
    successBoxShadow,
    warningBoxShadow,
    dangerBoxShadow,
    roseBoxShadow,
    blackMagicColor,
    warningCardHeader,
    successCardHeader,
    dangerCardHeader,
    infoCardHeader,
    primaryCardHeader,
    roseCardHeader,
    cardActions,
    cardHeader,
    defaultBoxShadow,
    title,
    cardTitle,
    cardLink,
    cardSubtitle,
    sidebarColor,
    yellowColor,
    backgroundAppColor,
    borderGrayColor,
    whiteColorHover,
    infoColorHover,
    infoColorDisabled,
    greyColorHover,
    radioCircleGrey,
    whiteColorDisabled,
    veryLightBlue,
    elevenGrey,
    twelveGrey,
    lighterBlack,
    orangeReturn,
    thirteenthGrey,
    fourteenthGrey,
    fifteenthGrey,
    sixteenGrey,
    sevenTeenGrey,
    grey500,
    grey200,
    greyishBlue50,
    greyishBlue200,
    greyishBlue300,
    greyishBlue400,
    greyishBlue500,
    red50,
    red500,
    black20,
    black100,
    black500
};
