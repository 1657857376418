import {
    defaultFont,
    primaryColor,
    primaryBoxShadow,
    infoColor,
    infoBoxShadow,
    successColor,
    successBoxShadow,
    warningColor,
    warningBoxShadow,
    dangerColor,
    dangerBoxShadow,
    roseColor,
    roseBoxShadow
} from "../../main";

const dropdownStyle = theme => ({
    popperClose: {
        pointerEvents: "none"
    },
    dropdown: {
        borderRadius: "3px",
        border: "0",
        boxShadow: "0 2px 5px 0 rgba(0, 0, 0, 0.26)",
        top: "100%",
        zIndex: "1000",
        minWidth: "160px",
        padding: "5px 0",
        margin: "2px 0 0",
        fontSize: "14px",
        textAlign: "left",
        listStyle: "none",
        backgroundColor: "#fff",
        backgroundClip: "padding-box"
    },
    menuList: {
        padding: "0",
        minWidth: "180px"
    },
    pooperResponsive: {
        zIndex: "1200",
        [theme.breakpoints.down('md')]: {
            zIndex: "1640",
            position: "static",
            float: "none",
            width: "auto",
            marginTop: "0",
            backgroundColor: "transparent",
            border: "0",
            boxShadow: "none",
            color: "black"
        }
    },
    dropdownItem: {
        ...defaultFont,
        fontSize: "13px",
        padding: "0px",
        margin: "0 5px",
        borderRadius: "2px",
        position: "relative",
        transition: "all 150ms linear",
        display: "flex",
        clear: "both",
        fontWeight: "400",
        height: "fit-content",
        color: "#333",
        whiteSpace: "nowrap",
        "& a": {
            color: primaryColor,
            padding: "15px 20px",
            width: "100%",
            "&:hover": {
                color: "#FFFFFF"
            }
        }
    },
    blackHover: {
        "&:hover": {
            boxShadow:
                "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(33, 33, 33, 0.4)",
            backgroundColor: "#212121",
            color: "#fff"
        }
    },
    primaryHover: {
        "&:hover": {
            backgroundColor: primaryColor,
            color: "#FFFFFF",
            ...primaryBoxShadow
        }
    },
    infoHover: {
        "&:hover": {
            backgroundColor: infoColor,
            color: "#FFFFFF",
            ...infoBoxShadow
        }
    },
    successHover: {
        "&:hover": {
            backgroundColor: successColor,
            color: "#FFFFFF",
            ...successBoxShadow
        }
    },
    warningHover: {
        "&:hover": {
            backgroundColor: warningColor,
            color: "#FFFFFF",
            ...warningBoxShadow
        }
    },
    dangerHover: {
        "&:hover": {
            backgroundColor: dangerColor,
            color: "#FFFFFF",
            ...dangerBoxShadow
        }
    },
    roseHover: {
        "&:hover": {
            backgroundColor: roseColor,
            color: "#FFFFFF",
            ...roseBoxShadow
        }
    },
    dropdownItemRTL: {
        textAlign: "right"
    },
    dropdownDividerItem: {
        margin: "5px 0",
        backgroundColor: "rgba(0, 0, 0, 0.12)",
        height: "1px",
        overflow: "hidden"
    },
    buttonIcon: {
        "&:hover": {
            backgroundColor: "transparent"
        }
    },
    buttonIconNoPadding: {
        padding: "0px",
    },
    whiteButtonIcon: {
        color: "#FFFFFF"
    },
    caret: {
        transition: "all 150ms ease-in",
        display: "inline-block",
        width: "0",
        height: "0",
        marginLeft: "6px",
        verticalAlign: "middle",
        borderTop: "6px solid",
        borderRight: "6px solid transparent",
        borderLeft: "6px solid transparent"
    },
    caretActive: {
        transform: "rotate(180deg)"
    },
    caretRTL: {
        marginRight: "4px"
    },
    dropdownHeader: {
        display: "block",
        padding: "0.1875rem 1.25rem",
        fontSize: "0.75rem",
        lineHeight: "1.428571",
        color: "#777",
        whiteSpace: "pre-wrap",
        fontWeight: "inherit",
        marginTop: "10px",
        height: "28px",
        "&:hover,&:focus": {
            backgroundColor: "transparent",
            cursor: "auto"
        }
    },
    noLiPadding: {
        padding: "0"
    }
});

export default dropdownStyle;
