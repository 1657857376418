import React, {PureComponent} from "react";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import {BeatLoader} from "react-spinners";

// @mui/material components
import {Checkbox, Switch, TableCell, TableHead, TableRow, Tooltip, } from "@mui/material";
import {withStyles} from "@mui/styles";

// styles
import tableStyle from "assets/jss/views/member/tableHeadStyle";
import {primaryColor} from 'assets/jss/main'

class EnhancedTableHead extends PureComponent {
    render() {
        const {classes, onSelectAllClick, checked, showGlobalUpdateStateButton, updateMemberState, disabledStates, loading} = this.props;

        const isDisabled = disabledStates && disabledStates.length > 0;

        return (
            <TableHead className={classes.tableHeadCell}>
                <TableRow>
                    <TableCell>
                        <Checkbox
                            classes={{
                                root: classes.checkbox,
                                checked: classes.checked
                            }}
                            checked={!!checked}
                            onChange={onSelectAllClick}
                        />
                    </TableCell>
                    {this.props.tableHead.map(row => {
                        return (
                            <TableCell key={row.id} className={classes.primaryTableHeader}>
                                {row.label}
                            </TableCell>
                        );
                    }, this)}
                    <TableCell>
                        {loading ?
                            <BeatLoader
                                sizeUnit={"px"}
                                size={12}
                                color={primaryColor}
                                loading={loading}
                            />
                        : showGlobalUpdateStateButton &&
                        <Tooltip
                            title={<FormattedMessage id={`member.tab.all.${isDisabled ? "enabled" : "disabled"}.button`}/>}
                        >
                            <Switch
                                checked={!isDisabled}
                                onChange={() => updateMemberState(isDisabled ? 'enabled' : 'disabled')}
                                color="primary"
                            />
                        </Tooltip>
                        }
                    </TableCell>
                </TableRow>
            </TableHead>
        );
    }
}

EnhancedTableHead.propTypes = {
    onSelectAllClick: PropTypes.func.isRequired,
};

export default (withStyles(tableStyle)(EnhancedTableHead));
