import { primaryColor, grayColor, lightGrayColor } from "../../main";

const row = {
    height: "65px",
    borderRadius: "5px",
    background: "white",
    overflow: "none",
    cursor: "pointer",
    display:'flex',
    flexDirection: "row",
    justifyContent: "start",
    alignItems: "center",
    marginTop: "10px",
    listStyleType: "none",
};

const subdirectoryIcon = {
    float: "left",
    marginTop: "18px"
};

const attributeStyle = theme => ({
    element: {
        ...row,
        "&:hover": {
            //boxShadow: "0px 0px 13px 1px #c6cace",
            //background: "linear-gradient(90deg, rgba(43,33,223,1) 0.7%, rgba(255,255,255,1) 0.7%)",
            overflow: "hidden",
        },
    },
    elementOpen:{
        ...row,
        background: "linear-gradient(90deg, rgba(43,33,223,1) 0.7%, rgba(255,255,255,1) 0.7%)",
        overflow: "hidden",
    },
    elementMatch: {
        ...row,
        border: `2px solid ${primaryColor} !important`
    },
    elementHidden: {
        ...row,
        display: `none`
    },
    checkbox: {
        color: "grey",
        "&$checked": {
            color: primaryColor,
            "&:hover": {
                backgroundColor: "rgba(0, 53, 245, 0.08)"
            },
        },
        "&:hover": {
            backgroundColor: "rgba(0, 53, 245, 0.08)"
        },
    },
    checked: {
        color: primaryColor,
    },
    topAttributeName: {
        textTransform: "uppercase",
        fontWeight: "bold",
    },
    topAttributeNameSelected: {
        textTransform: "uppercase",
        fontWeight: "bold",
        color: primaryColor,
    },
    attributeNameSelected: {
        fontWeight: "bold",
        color: primaryColor,
    },
    addSubAttributeButton: {
        backgroundColor: "#FFFFFF",
        color: grayColor,
        width: "1px",
        height: "1px",
        border: `1px solid ${grayColor}`,
        marginLeft: "10px",
        verticalAlign: "sub",
        padding: "7px",
        "&:hover": {
            backgroundColor: primaryColor,
            color: "#FFFFFF",
            border: `1px solid ${primaryColor}`,
        }
    },
    addSubAttributeIcon: {
        fontSize: "18px"
    },
    iconButton: {
        "&:hover": {
            backgroundColor: "rgba(0, 53, 245, 0.08)"
        }
    },
    iconButtonMargin :{
        marginRight: "30px"
    },
    name: {
        width: "20%",
        flexGrow: 1,
        marginRight: "10px",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start"
    },
    descriptionContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "40%",
        flexGrow: 2,
        marginRight: "50px",
        maxHeight: "65px"
    },
    description: {
        width: "50%",
        textAlign: "start"
    },
    actionsButtonContainer: {
        paddingRight: "10px",
        display: "flex",
        alignItems: "center",
        flexGrow: 0,
    },
    subdirectoryIconOpen : {
        ...subdirectoryIcon,
        color: primaryColor
    },
    subdirectoryIconClose: {
        ...subdirectoryIcon,
        color: lightGrayColor
    }
});

export default attributeStyle;
