import React from "react";
import PropTypes from "prop-types";

import Icon from '@mui/material/Icon';

import {
    DirectionsBike,
    DirectionsCar,
    DirectionsWalk,
    Inbox,
    LocalShipping,
    MarkunreadMailbox
} from "@mui/icons-material";

import {makeStyles} from "@mui/styles";
import {infoColor} from "assets/jss/main";
import { ReactComponent as HeavyDeliveryImage } from "assets/img/heavy-delivery.svg";

const defaultShippingIconStyle = {
    color: infoColor,
    fontSize: "24px",
    border: `1px solid ${infoColor}`,
    borderRadius: "50%",
    padding: "7px",
    width: '40px',
    height: '40px',
    marginRight: "10px",
    background: '#FFFFFF'
};

const useStyles = makeStyles(theme => ({
    largeShippingIcon: {
       ...defaultShippingIconStyle,
    },
    smallShippingIcon: {
        ...defaultShippingIconStyle,
        padding: "5px",
        width: '32px',
        height: '32px',
        fontSize: '20px'
    },
}));

export default function ShippingIcon(props) {
    const classes = useStyles();

    const { organizationShippingMethods, shippingMethodName, size } = props

    const shippingIconClassName = size === 'small' ? classes.smallShippingIcon : classes.largeShippingIcon;
    
    function formatShippingMethodName(name) {
        /* we need to reformat name because App\Entity\Order::getShippingMethods
        return shipping method name, different of App\Entity\ShippingMethod */
        name = name.toLowerCase();
        switch (name) {
            case 'clickandcollect':
                return 'click-collect';
            case 'shipping':
                return 'delivery';
            case 'relaypoint':
                return 'relay-point';
            case 'heavydelivery':
                return 'heavy-delivery';
            default:
                return name;
        }
    }

    function getDefaultShippingIcon(name) {
        switch (name) {
            case 'delivery':
            case 'postnl':
            case 'bpost':
                return <LocalShipping className={shippingIconClassName} />;
            case 'click-collect':
                return <DirectionsWalk className={shippingIconClassName} />;
            case 'drive':
                return <DirectionsCar className={shippingIconClassName} />;
            case 'bike':
                return <DirectionsBike className={shippingIconClassName} />;
            case 'relay-point':
            case 'bring':
                return <MarkunreadMailbox className={shippingIconClassName} />;
            case 'locker':
                return <Inbox className={shippingIconClassName} />;
            case 'heavy-delivery':
                return <HeavyDeliveryImage className={shippingIconClassName} />
            default:
                return <></>
        }
    }

    function getShippingIcon(orderShippingMethod) {
        const matchedShippingMethod = organizationShippingMethods.filter((shippingMethod) => {
            return shippingMethod['shippingMethodShopName'].toLowerCase() === orderShippingMethod.toLowerCase()
        })

        if (
            matchedShippingMethod.length > 0
            && matchedShippingMethod[0].icon
            && matchedShippingMethod[0].icon !== null
            && matchedShippingMethod[0].icon !== ''
        ) {
            return (
                <Icon className={shippingIconClassName}>
                    {matchedShippingMethod[0].icon}
                </Icon>
            )
        }

        return getDefaultShippingIcon(orderShippingMethod);
    }

    return getShippingIcon(formatShippingMethodName(shippingMethodName));
}

ShippingIcon.defaultProps = {
    organizationShippingMethods: [],
    shippingMethodName: '',
    size: 'large'
};

ShippingIcon.propTypes = {
    organizationShippingMethods: PropTypes.arrayOf(PropTypes.object),
    shippingMethodName: PropTypes.string.isRequired,
    size: PropTypes.oneOf(['small', 'large'])
};
