import React, {useContext, useState} from 'react'
import PropTypes from "prop-types";
import {BeatLoader} from "react-spinners";
import {FormattedMessage} from "react-intl";

import HeaderActionButton from "./HeaderActionButton";

import ship from "api/shipment/ship";

import {primaryColor} from "assets/jss/main";

import {OrderContext} from "contexts/OrderContext";
import {ShippingMethods} from "../../../../../../utils/ShippingMethods";

export default function GivenToCustomerButton({shipment}) {

    const [loading, setLoading] = useState(false);

    const { refreshOrder } = useContext(OrderContext);

    if (loading) {
        return (
            <BeatLoader
                sizeUnit={"px"}
                size={12}
                color={primaryColor}
                loading={true}
            />
        )
    }

    return (
        <HeaderActionButton
            onClick={() => {
                setLoading(true)
                ship(shipment).then(() => {
                    refreshOrder()
                }).catch(() => {
                    setLoading(false)
                })
            }}
        >
            { shipment.shippingMethod.shippingMethodShopName === ShippingMethods.LOCKER ?
                <FormattedMessage id={`picking.order.shipments.header.action.givenToCustomer.locker`} />
                :
                <FormattedMessage id={`picking.order.shipments.header.action.givenToCustomer`} />
            }
        </HeaderActionButton>
    )
}

GivenToCustomerButton.defaultProps = {
    shipment: {}
}

GivenToCustomerButton.propTypes = {
    shipment: PropTypes.object.isRequired
}
