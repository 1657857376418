import React from "react";
import PropTypes from "prop-types";
import {FormattedMessage, injectIntl} from "react-intl";
import {Line} from "react-chartjs-2";

import CardBody from "components/card/cardBody";

import CardStats from "../cards/cardStats";
import CardStatsGraphLegend from "../cards/CardStatsGraphLegend";
import CardStatsGraphTitle from "../cards/cardStatsGraphTitle";
import LegendDotBue from "./legendDotBue";
import LegendDotPurple from "./legendDotPurple";
import Loader from "../loader";

import {blueLine, defaultOptions, purpleLine} from "config/graph/defaultLineGraphConfig";

function SalesByVolumeGraph(props) {
    const {
        intl,
        isRetailer,
        loading,
        onlineCountGraph,
        physicalCountGraph,
        unit,
        unitParser,
        unitTooltip,
    } = props

    let newOnlineCountGraph = [];
    let newPhysicalCountGraph = [];

    Object.keys(onlineCountGraph).map((key) => {
        return newOnlineCountGraph.push({
            x: key,
            y: onlineCountGraph[key]
        });
    });

    Object.keys(physicalCountGraph).map((key) => {
        return newPhysicalCountGraph.push({
            x: key,
            y: physicalCountGraph[key]
        });
    });

    let cloneBlue = JSON.parse(JSON.stringify(blueLine));

    const data = {
        datasets : [cloneBlue]
    };

    data.datasets[0].label = intl.formatMessage({id: "dashboard.graph.label.online"});
    data.datasets[0].data = newOnlineCountGraph;

    if (!isRetailer) {
        let clonePurple = JSON.parse(JSON.stringify(purpleLine));

        data.datasets.push(clonePurple);
        data.datasets[1].label = intl.formatMessage({id: "dashboard.graph.label.physical"});
        data.datasets[1].data = newPhysicalCountGraph;
    }

    const cloneDefaultOptions = JSON.parse(JSON.stringify(defaultOptions));

    cloneDefaultOptions.scales.xAxes[0].time.unit = unit;
    cloneDefaultOptions.scales.xAxes[0].time.parser = unitParser;
    cloneDefaultOptions.scales.xAxes[0].time.tooltipFormat = unitTooltip;
    cloneDefaultOptions.scales.yAxes[0].scaleLabel.labelString =  intl.formatMessage({id: "dashboard.graph.label.ordersCount"});

    return (
        <CardStats>
            <Loader loading={loading} />
            <CardStatsGraphTitle>
                <FormattedMessage id={"dashboard.graph.order.title"} />
            </CardStatsGraphTitle>
            <CardBody>
                <Line
                    data={data}
                    options={cloneDefaultOptions}
                    width={400}
                    height={150}
                />
            </CardBody>
            <CardStatsGraphLegend>
                <LegendDotBue title={"dashboard.graph.legend.online"} />
                {!isRetailer && (
                    <LegendDotPurple title={"dashboard.graph.legend.physical"} />
                )}
            </CardStatsGraphLegend>
        </CardStats>
    )
}

export default injectIntl(SalesByVolumeGraph);

SalesByVolumeGraph.defaultProps = {
    onlineCountGraph: {},
    physicalCountGraph: {}
}

SalesByVolumeGraph.propTypes = {
    isRetailer: PropTypes.bool,
    loading: PropTypes.bool,
    onlineCountGraph: PropTypes.object.isRequired,
    physicalCountGraph: PropTypes.object.isRequired,
    unit: PropTypes.oneOf(['day', 'month', 'year']).isRequired,
    unitParser: PropTypes.oneOf(['YYYY', 'YYYYMM', 'YYYYMMDD']).isRequired,
    unitTooltip: PropTypes.oneOf(['YYYY','MMMM YYYY','D MMMM YYYY']).isRequired
};
