import {container, defaultFont} from "../../main";


const listStyle = theme => ({
    containerWithSidebar: {
        [theme.breakpoints.up("md")]: {
            marginLeft: "240px",
        }
    },
    container: {
        ...container,
        ...defaultFont
    },
    mainActionsContainer: {
        margin: "8px 0px",
        textAlign: "right"
    },
    card: {
        height: window.innerHeight * 0.94
    },
    snackbarMessage: {
        display: "flex",
        alignItems: "center",

        "& span": {
            paddingLeft: "10px"
        }
    }
});


export default listStyle;
