import React, {Component} from 'react';
import Select from 'react-select';
import {ITEM_PER_PAGE} from "config/_pagination";

// nodejs library to set properties for components
import PropTypes from "prop-types";
import {FormattedMessage, injectIntl} from "react-intl";

import {Field, reduxForm} from "redux-form";
import connect from "react-redux/es/connect/connect";

import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import fr from 'date-fns/locale/fr';

// @mui/material components
import {
    Divider,
    Dialog,
    DialogContent,
} from "@mui/material";
import { LocalizationProvider } from '@mui/x-date-pickers'

import { DatePicker } from '@mui/x-date-pickers'

import formStyle from "assets/jss/views/product/formStyle";
import bubbleStyle from "assets/jss/views/product/bubbleStyle";
import ImageSlider from "./imageSlider";
import Bubble from "./bubble";
import {ArrowBackIos, InfoOutlined, LockRounded, LockOpenRounded} from "@mui/icons-material";
import Input from "components/input/input";
import Button from "components/button/button";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import {update as updateProduct} from "actions/product/update";
import {BeatLoader} from "react-spinners";
import GridContainer from "components/grid/gridContainer";
import GridItem from "components/grid/gridItem";
import Tooltip from "@mui/material/Tooltip";
import moment from "moment";
import {massUpdate} from "actions/product/massUpdate";
import {Authorizations, hasAuthorization} from "../../../../../utils/authorizations";
import {PRICE} from "../../../../../domain/enum/regexs";
import FormTranslator from "../../../../../components/translator/formTranslator";
import {getTranslation} from "../../../../../domain/helpers/translations";
import {ReactComponent as BarcodeScannerIcon} from "../../../../../assets/img/barcode_scanner.svg";
import {UserAgentUtils} from "../../../../../utils/useragent";
import BarcodeScanner from "../../../../../components/barcodeScanner";
import { isFloat } from "utils/typeValidation";
import {withStyles} from "@mui/styles";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {CKEditorConfigSimple, CKEditorConfigSimpleWithTitles} from "../../../../../components/input/inputRenderer/wysiwygInputRenderer";
import * as Cookies from "js-cookie";
import LabeledSwitch from "components/switch/LabeledSwitch";
import LabeledSwitchContainer from "./LabeledSwitchContainer";

class Form extends Component {
    static propTypes = {
        handleSubmit: PropTypes.func.isRequired,
        updateProductLoading: PropTypes.bool.isRequired,
        updateVariationLoading: PropTypes.bool.isRequired,
        uploadLoading: PropTypes.bool.isRequired,
        error: PropTypes.string,
        updateProduct: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);

        this.state = {
            currentVariation: {},
            selectedOptionBrand: false,
            selectedOptionCorners: false,
            selectedOptionGender: false,
            selectedOptionColor: false,
            selectedOptionSize: false,
            selectedOptionCategory: false,
            selectedOptionTaxRate: false,
            switchState: null,
            switchProductState: null,
            itemsPerPage: this.props.itemsPerPage !== null ? this.props.itemsPerPage : ITEM_PER_PAGE
        }

        this.renderField = this.renderField.bind(this);
    }

    handleChangeCategory = (selectedOption) => {
        if (null === selectedOption) {
            selectedOption = [];
        }
        if (selectedOption) {
            let currentVariation = this.state.currentVariation;
            currentVariation.categories = selectedOption.map(function (value) {
                return value["value"];
            });
            this.setState({
                selectedOptionCategory: selectedOption,
                currentVariation: currentVariation
            });
        }
    };

    handleChangeCorner = (selectedOptions) => {
        if (null === selectedOptions) {
            selectedOptions = [];
        }

        let currentVariation = this.state.currentVariation;
        currentVariation.corners = selectedOptions.map(value => value["value"]);
        this.setState({
            selectedOptionCorners: selectedOptions,
            currentVariation: currentVariation,
        });
    };

    handleChangeBrand = (selectedOption) => {
        let currentVariation = this.state.currentVariation;
        currentVariation.brand = selectedOption.value;
        this.setState({
            currentVariation: currentVariation,
            selectedOptionBrand: selectedOption
        });
    };

    handleChangeGender = (selectedOption) => {
        let currentVariation = this.state.currentVariation;
        currentVariation.gender = selectedOption.value;
        this.setState({
            selectedOptionGender: selectedOption,
            currentVariation: currentVariation
        });
    };

    handleChangeColor = (selectedOption) => {
        let currentVariation = this.state.currentVariation;
        currentVariation.color = selectedOption.value;
        this.setState({
            selectedOptionColor: selectedOption,
            currentVariation: currentVariation
        });
    };

    handleChangeSize = (selectedOption) => {
        let currentVariation = this.state.currentVariation;
        currentVariation.size = selectedOption.value;
        this.setState({
            selectedOptionSize: selectedOption,
            currentVariation: currentVariation
        });
    };

    handleChangeTaxRate = (selectedOption) => {
        let currentVariation = this.state.currentVariation;
        currentVariation.taxRate = selectedOption.value;
        this.setState({
            selectedOptionTaxRate: selectedOption,
            currentVariation: currentVariation
        });
    };

    handleSwitchState = (event) => {
        let checked = event.target.checked;
        let currentVariation = this.state.currentVariation;
        currentVariation.status = checked;
        this.setState({
            switchState: checked,
            currentVariation: currentVariation
        }, () => {
            this.props.handleSubmit();
        });
    };

    handleSwitchCatalogOnlyFeatureProperty(event, property) {
        const checked = event.target.checked;
        const currentVariation = this.state.currentVariation;

        currentVariation[property] = checked;

        this.setState({
            currentVariation: currentVariation
        });
    }

    handleSwitchProductState = (event) => {
        let checked = event.target.checked;
        let currentVariation = this.state.currentVariation;

        const params = {
            'retailer': currentVariation.retailer['@id'],
            'productList': ['/products/' + currentVariation.id],
            'key': ['status', checked]
        };

        currentVariation.status = checked;

        this.setState({
            switchState: checked,
            currentVariation: currentVariation
        });
        this.props.massUpdate(params).then(() => {
            window.location.reload(false);
        });
    };

    handleSortPictures = (file, fromPosition, toPosition) => {
        let pictures = this.state.currentVariation.pictures;
        let element = pictures[fromPosition];

        pictures.splice(fromPosition, 1);
        pictures.splice(toPosition, 0, element);
    }

    isFullyLocked = () => {
        let allLock = document.querySelectorAll('input[name^=custom_lock_checkbox]');
        let allLocked = document.querySelectorAll('input[name^=custom_lock_checkbox]:checked');
        return (allLocked.length === allLock.length);
    };

    populateLockCheckbox = (event, name) => {
        name = name.split('.');
        name = name[name.length - 1];
        this.props.lockAttributes.push(name);
        let checked;
        if (this.state.currentVariation["@type"] === "Variation") {
            checked = (this.state.currentVariation.variationLock) ? this.state.currentVariation.variationLock[name] : false;
        } else {
            checked = (this.state.currentVariation.productLock) ? this.state.currentVariation.productLock[name] : false;
        }
        return checked;
    };

    handleLockCheckboxAll = (event) => {
        let checked = event.target.checked;
        let tmp_lock = {};
        this.props.lockAttributes.forEach((value) => {
            tmp_lock[value] = checked;
        });

        if (this.state.currentVariation["@type"] === "Variation") {
            let tmp_variation = this.state.currentVariation;
            if (!this.state.currentVariation.variationLock) {
                tmp_variation.variationLock = tmp_lock;
                this.setState({currentVariation: tmp_variation});
                this.props.createVariationLock(tmp_variation["@id"], {});
            } else {
                tmp_lock["@id"] = tmp_variation.variationLock["@id"];
                tmp_variation.variationLock = tmp_lock;
                this.setState({currentVariation: tmp_variation},
                    () => {
                        this.props.updateVariationLock(tmp_variation.variationLock, tmp_variation.variationLock);
                    });
            }
        } else if (this.state.currentVariation["@type"] === "Product") {
            let tmp_product = this.state.currentVariation;
            if (!this.state.currentVariation.productLock) {
                tmp_product.productLock = tmp_lock;
                this.setState({currentVariation: tmp_product});
                this.props.createProductLock(tmp_product["@id"], {});
            } else {
                tmp_lock["@id"] = tmp_product.productLock["@id"];
                tmp_product.productLock = tmp_lock;
                this.setState({currentVariation: tmp_product},
                    () => {
                        this.props.updateProductLock(tmp_product.productLock, tmp_product.productLock);
                    }
                );
            }
        }
    };

    handleLockCheckbox = (event, name) => {
        name = name.split('.');
        name = name[name.length - 1];
        let checked = event.target.checked;
        if (this.state.currentVariation["@type"] === "Variation") {
            let tmp_variation = this.state.currentVariation;
            if (!this.state.currentVariation.variationLock) {
                tmp_variation.variationLock = {};
                tmp_variation.variationLock[name] = checked;
                this.setState({currentVariation: tmp_variation});
                this.props.createVariationLock(tmp_variation["@id"], tmp_variation.variationLock);
            } else {
                tmp_variation.variationLock[name] = checked;
                this.setState({currentVariation: tmp_variation});
                this.props.updateVariationLock(tmp_variation.variationLock, tmp_variation.variationLock);
            }
        } else if (this.state.currentVariation["@type"] === "Product") {
            let tmp_product = this.state.currentVariation;
            if (!this.state.currentVariation.productLock) {
                tmp_product.productLock = {};
                tmp_product.productLock[name] = checked;
                this.setState({currentVariation: tmp_product});
                this.props.createProductLock(tmp_product["@id"], tmp_product.productLock);
            } else {
                tmp_product.productLock[name] = checked;
                this.setState({currentVariation: tmp_product});
                this.props.updateProductLock(tmp_product.productLock, tmp_product.productLock);
            }
        }
    };

    handleBarcodeScanned(barcode) {
        this.props.values.sku = barcode;
        this.setState({
            barcodeScanActive: false,
        });
    }

    renderBarcodeScanner(index, code) {
        const {barcodeScanActive} = this.state;

        return (
            <Dialog
                open={barcodeScanActive}
                aria-labelledby="responsive-dialog-title"
                onClose={() => this.setState({barcodeScanActive: false})}
            >
                <DialogContent>
                    <BarcodeScanner
                        hideButtons
                        onBarcodeFound={(barcode) => this.handleBarcodeScanned(barcode)}
                        onExit={() => this.setState({barcodeScanActive: false})}
                        allowLoopScanning={true}
                    />
                </DialogContent>
            </Dialog>
        );
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.submitting) {
            return;
        }

        let values;

        if (typeof nextProps.values === "object") {
            values = nextProps.values;
        } else {
            values = nextProps.initialValues;
        }

        if (values) {
            this.setState({currentVariation: values});
        }

        if (nextProps.initialValues.id !== this.props.initialValues.id) {
            this.setState({
                selectedOptionBrand: false,
                selectedOptionGender: false,
                selectedOptionSize: false,
                selectedOptionColor: false,
                selectedOptionCategory: false,
                selectedOptionTaxRate: false,
                switchState: null
            });
        }
    }

    renderField = data => {
        const {classes, createProductLockLoading, createVariationLockLoading, authorizations} = this.props;

        const member = Cookies.get("_c") ? JSON.parse(Cookies.get("_c").toString()) : null;
        let HTMLPurifierConfig = CKEditorConfigSimple;
        if (member && member['organizationIsTitleTagsAllowedInProductDescription']) {
            HTMLPurifierConfig = CKEditorConfigSimpleWithTitles;
        }

        const canLockFields = hasAuthorization(authorizations, Authorizations.MARKETPLACE_PRODUCT_MANAGEMENT);
        data.input.className = "";

        const isInvalid = data.meta.touched && !!data.meta.error;
        if (isInvalid) {
            data.input.className += " is-invalid";
            data.input["aria-invalid"] = true;
        }

        if (this.props.error && data.meta.touched && !data.meta.error) {
            data.input.className += " is-valid";
        }

        return (
            <div className={classes.fieldContainer}>
                <label
                    htmlFor={`product_${data.input.name}`}
                    className={classes.fieldLabel}
                >
                    {data.label}
                </label>
                <div className={classes.formContainerLock}>
                    {(data.type !== "wysiwyg" && data.type !== "select" && data.type !== "checkbox" && data.type !== "textarea" && data.type !== "bubbleList" && data.type !== "date") &&
                    <Input
                        {...data.input}
                        type={data.type}
                        step={data.step}
                        required={data.required}
                        fullWidth={true}
                        margin={'dense'}
                        id={`product_${data.input.name}`}
                        inputRootCustomClasses={classes.input}
                        inputProps={{
                            placeholder: data.placeholder,
                            type: data.type,
                            multiline: data.multiline,
                            disabled: data.disabled,
                            required: data.required,
                            ...data.input,
                            className: data.className,
                            autoComplete: data.autoComplete
                        }}
                        formControlProps={{
                            className: [classes.formControl]
                        }}
                    />
                    }
                    {(data.type === 'date') &&
                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fr}>
                        <DatePicker
                            disablePast
                            onChange={data.input.onChange}
                            value={this.state.currentVariation[data.input.name] && new Date(this.state.currentVariation[data.input.name])}
                        />
                    </LocalizationProvider>
                    }
                    {data.type === "select" &&
                    <Select className={classes.formSelect}
                            classes={data.classes}
                            classNamePrefix={"custom"}
                            name={data.name}
                            options={data.options}
                            value={data.selected}
                            isMulti={data.isMulti}
                            inputProps={{
                                ...data.input
                            }}
                            onChange={data.onChangeValue}
                            placeholder={data.placeholder}
                            noOptionsMessage={data.noOptionsMessage}
                    />
                    }
                    {(data.type && data.type === "bubbleList" && data.input.value) &&
                    <div className={classes.bubbleContainer}>
                        {(Array.isArray(data.input.value)) &&
                        data.input.value.map((item, key) => (
                            <Bubble key={`bubble_${key}`} type="blue">{item.name}</Bubble>
                        ))
                        }
                    </div>
                    }
                    {(data.type && data.type === "wysiwyg") &&
                        <div style={{width:'100%'}} >
                            <CKEditor
                                config={HTMLPurifierConfig}
                                editor={ClassicEditor}
                                data={data.input.value}
                                onChange={(event, editor) => {
                                    this.props.values.translations[data.code].description = editor.getData();
                                }}
                            />
                        </div>
                    }
                    <div className={classes.formSpaceLock}>
                        {data.lock && canLockFields &&
                        <div>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name={`custom_lock_checkbox_${data.input.name}`}
                                        icon={<LockOpenRounded color={"disabled"}/>}
                                        checkedIcon={<LockRounded color={"action"}/>}
                                        value="true"
                                        checked={!!this.populateLockCheckbox("", data.input.name)}
                                        onChange={(e) => this.handleLockCheckbox(e, data.input.name)}
                                        disabled={createProductLockLoading || createVariationLockLoading}
                                        color="primary"
                                    />
                                }
                            />
                        </div>
                        }
                    </div>
                </div>

                {isInvalid && <div className={classes.inputError}><FormattedMessage id={data.meta.error}/></div>}
            </div>
        );
    };

    renderTranslationForm = (index, code) => {
        const {
            classes,
            currentType,
            currentProduct,
            categoryList,
            selectedCategories,
            brandList,
            selectedBrand,
            cornerList,
            selectedCorners,
            genderList,
            selectedGender,
            taxRateList,
            selectedTaxRate,
            colorList,
            selectedColor,
            sizeList,
            selectedSize,
            retailer,
        } = this.props;

        const checkPriceIsValid = (e) => {
            const newPrice = e.target.value;
            if (!PRICE.test(newPrice)) {
                e.preventDefault(); // This prevent the data to be update in the redux form
            }
        }

        const setLabelsTranslationInCurrentLocale = (items, field) => {
            if (!items) {
                return [];
            }

            return items
                .map(item => {
                        if (item.value !== undefined) {
                            item = Object.assign({}, item);
                            // 'code' below mean the locale currently selected
                            item.label = getTranslation(item)[field];
                            return item;
                        }
                    }
                );
        }

        const getTranslatedLabelForOption = (options, field) => {
            if (!options) {
                return;
            }

            const isArray = Array.isArray(options);
            if (!isArray) {
                options = [options];
            }

            options = options.map(option => {
                option = Object.assign({}, option);
                option.label = getTranslation(option)[field];
                return option;
            })

            return isArray ? options : options[0];
        };

        // Translate externals entities for the active locale tab
        const categoryListTranslated = setLabelsTranslationInCurrentLocale(categoryList, 'name');
        const selectedCategoriesTranslated = setLabelsTranslationInCurrentLocale(selectedCategories, 'name');

        const cornerListTranslated = setLabelsTranslationInCurrentLocale(cornerList, 'name');
        const selectedCornersTranslated = setLabelsTranslationInCurrentLocale(selectedCorners, 'name');

        const genderListTranslated = setLabelsTranslationInCurrentLocale(genderList, 'value');
        const selectedGenderTranslated = setLabelsTranslationInCurrentLocale(selectedGender, 'value');

        const colorListTranslated = setLabelsTranslationInCurrentLocale(colorList, 'name');
        const selectedColorTranslated = setLabelsTranslationInCurrentLocale(selectedColor, 'name');

        const sizeListTranslated = setLabelsTranslationInCurrentLocale(sizeList, 'name');
        const selectedSizeTranslated = setLabelsTranslationInCurrentLocale(selectedSize, 'name');

        const updateAt = currentType === "variation" ? this.state.currentVariation.updatedAt : currentProduct.updatedAt;
        const status = currentType === "variation" ? this.state.currentVariation.status : currentProduct.status;
        const productStatus = currentProduct.status;

        let filteredTaxRateList = [];
        if (retailer !== null && taxRateList) {
            const isRetailerZeroTaxRateEnabled = retailer.taxRateZero;
            taxRateList.forEach((taxRate) => {
                if (
                    (taxRate.label === '0%' && isRetailerZeroTaxRateEnabled)
                    || (taxRate.label !== '0%' && !isRetailerZeroTaxRateEnabled)
                ) {
                    filteredTaxRateList.push(taxRate);
                }
            });
        }

        const additionalMappingFields = null !== retailer ? retailer.additional_mapping_fields : null;
        const canManageCatalogSettings = (hasAuthorization(this.props.authorizations, Authorizations.STOCK_EASY_ACCESS) && hasAuthorization(this.props.authorizations, Authorizations.STOCK_EASY_CATALOG_PRODUCT_SETTINGS)) || !hasAuthorization(this.props.authorizations, Authorizations.STOCK_EASY_ACCESS);

        return (
            <div>
                {this.renderBarcodeScanner(index, code)}
                {currentType !== "variation" ?
                    <div>
                        <GridContainer className={classes.formContainer}>
                            <GridItem xs={12} sm={6}>
                                <Field
                                    component={this.renderField}
                                    name={`translations.${code}.name`}
                                    label={<FormattedMessage id={"product.show.attribute.name"}/>}
                                    type={"text"}
                                    required
                                    lock={true}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={6}>
                                <Field className={classes.formSelect}
                                       component={this.renderField}
                                       type={"select"}
                                       classes={classes}
                                       name={"gender"}
                                       label={<FormattedMessage id={"product.show.attribute.gender"}/>}
                                       options={genderListTranslated}
                                       selected={getTranslatedLabelForOption(this.state.selectedOptionGender, 'value') || selectedGenderTranslated}
                                       onChangeValue={this.handleChangeGender}
                                       placeholder={<FormattedMessage id={"product.show.attribute.gender"}/>}
                                       noOptionsMessage={() => <FormattedMessage
                                           id={"product.form.message.noOptions"}/>}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12}>
                                <Field className={classes.formSelect}
                                       classes={classes}
                                       name={"categories"}
                                       type={"select"}
                                       options={categoryListTranslated}
                                       component={this.renderField}
                                       selected={getTranslatedLabelForOption(this.state.selectedOptionCategory, 'name') || selectedCategoriesTranslated}
                                       onChangeValue={this.handleChangeCategory}
                                       placeholder={<FormattedMessage id={"product.show.attribute.categories"}/>}
                                       noOptionsMessage={() => <FormattedMessage
                                           id={"product.form.message.noOptions"}/>}
                                       isMulti
                                       label={<FormattedMessage id={"product.show.attribute.categories"}/>}
                                       lock={true}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12}>
                                <Field
                                    component={this.renderField}
                                    name={`translations.${code}.description`}
                                    label={<FormattedMessage id={"product.show.attribute.description"}/>}
                                    type={"wysiwyg"}
                                    lock={true}
                                    code={code}
                                />
                            </GridItem>
                            {retailer && (
                                <GridItem xs={12} sm={6}>
                                    <GridContainer className={classes.formContainer}>
                                        <GridItem xs={12} sm={12}>
                                            <Field className={classes.formSelect}
                                                   component={this.renderField}
                                                   classes={classes}
                                                   type={"select"}
                                                   name={"taxRate"}
                                                   options={filteredTaxRateList}
                                                   selected={this.state.selectedOptionTaxRate || selectedTaxRate}
                                                   onChangeValue={this.handleChangeTaxRate}
                                                   placeholder={<FormattedMessage
                                                       id={"product.show.attribute.taxRate"}/>}
                                                   noOptionsMessage={() => <FormattedMessage
                                                       id={"product.form.message.noOptions"}/>}
                                                   label={<FormattedMessage id={"product.show.attribute.taxRate"}/>}
                                            />
                                        </GridItem>
                                    </GridContainer>
                                </GridItem>
                            )}
                        </GridContainer>
                        <Divider/>
                        <GridContainer className={classes.formContainer}>
                            <GridItem xs={12} sm={6}>
                                <Field
                                    component={this.renderField}
                                    name={"sku"}
                                    label={<FormattedMessage id={"product.show.attribute.sku"}/>}
                                    type={"text"}
                                    required
                                />
                            </GridItem>
                            <GridItem xs={12} sm={6}>
                                <Field
                                    component={this.renderField}
                                    name={"externalId"}
                                    label={<FormattedMessage id={"product.show.attribute.externalId"}/>}
                                    type={"text"}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={6}>
                                <Field className={classes.formSelect}
                                       classes={classes}
                                       name={"brand"}
                                       options={brandList}
                                       component={this.renderField}
                                       type={"select"}
                                       selected={this.state.selectedOptionBrand || selectedBrand}
                                       onChangeValue={this.handleChangeBrand}
                                       placeholder={<FormattedMessage id={"product.show.attribute.brand"}/>}
                                       noOptionsMessage={() => <FormattedMessage
                                           id={"product.form.message.noOptions"}/>}
                                       label={<FormattedMessage id={"product.show.attribute.brand"}/>}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={6}>
                                <Field className={classes.formSelect}
                                       classes={classes}
                                       name={"corners"}
                                       options={cornerListTranslated}
                                       component={this.renderField}
                                       type={"select"}
                                       isMulti={true}
                                       selected={getTranslatedLabelForOption(this.state.selectedOptionCorners, 'name') || selectedCornersTranslated}
                                       onChangeValue={this.handleChangeCorner}
                                       placeholder={<FormattedMessage id={"product.show.attribute.corners"}/>}
                                       noOptionsMessage={() => <FormattedMessage
                                           id={"product.form.message.noOptions"}/>}
                                       label={<FormattedMessage id={"product.show.attribute.corners"}/>}
                                />
                            </GridItem>
                        </GridContainer>
                        { additionalMappingFields &&
                            <>
                            <Divider/>
                                <GridContainer className={classes.formContainer}>
                                    { Object.keys(additionalMappingFields).map(key => {
                                        // only support float (price) and text
                                        let type = additionalMappingFields[key]['type'] === 'float' ? 'price' : 'text';
                                        let onChange = type === 'price' ? function(event){ event.target.value !== '' && !isFloat(event.target.value) && event.preventDefault()} : null;

                                        return (
                                            <GridItem xs={12} sm={6} key={key}>
                                                <Field
                                                    key={key}
                                                    component={this.renderField}
                                                    name={`translations.${code}.additionalAttributes[${key}]`}
                                                    label={<FormattedMessage id={"product.show.attribute."+key}/>}
                                                    type={type}
                                                    onChange={onChange}
                                                />
                                            </GridItem>
                                        )
                                    })}
                                </GridContainer>
                            </>
                        }
                        <Divider/>
                        { canManageCatalogSettings &&
                            <GridContainer>
                                <GridItem xs={12} sm={6}>
                                    <LabeledSwitchContainer>
                                        <LabeledSwitch
                                            labelMessageId={"product.show.attribute.catalogOnly"}
                                            disabled={this.props.updateVariationLoading}
                                            checked={this.state.currentVariation.catalogOnly !== null ? this.state.currentVariation.catalogOnly : currentProduct.catalogOnly}
                                            onChange={(e) => this.handleSwitchCatalogOnlyFeatureProperty(e, "catalogOnly")}
                                        />
                                    </LabeledSwitchContainer>
                                    <LabeledSwitchContainer>
                                        <LabeledSwitch
                                            labelMessageId={"product.show.attribute.hiddenPrice"}
                                            disabled={this.props.updateVariationLoading}
                                            checked={this.state.currentVariation.hiddenPrice !== null ? this.state.currentVariation.hiddenPrice : currentProduct.hiddenPrice}
                                            onChange={(e) => this.handleSwitchCatalogOnlyFeatureProperty(e, "hiddenPrice")}
                                        />
                                    </LabeledSwitchContainer>
                                </GridItem>
                                <GridItem xs={12} sm={6}>
                                    <LabeledSwitchContainer>
                                        <LabeledSwitch
                                            labelMessageId={"product.show.attribute.privateProduct"}
                                            disabled={this.props.updateVariationLoading}
                                            checked={this.state.currentVariation.privateProduct !== null ? this.state.currentVariation.privateProduct : currentProduct.privateProduct}
                                            onChange={(e) => this.handleSwitchCatalogOnlyFeatureProperty(e, "privateProduct")}
                                        />
                                    </LabeledSwitchContainer>
                                    <LabeledSwitchContainer>
                                        <LabeledSwitch
                                            labelMessageId={"product.show.attribute.privatePrice"}
                                            disabled={this.props.updateVariationLoading}
                                            checked={this.state.currentVariation.privatePrice !== null ? this.state.currentVariation.privatePrice : currentProduct.privatePrice}
                                            onChange={(e) => this.handleSwitchCatalogOnlyFeatureProperty(e, "privatePrice")}
                                        />
                                    </LabeledSwitchContainer>
                                </GridItem>
                            </GridContainer>
                        }
                    </div> :
                    <div>
                        <GridContainer className={classes.formContainer}>
                            <GridItem xs={12} sm={6}>
                                <Field
                                    component={this.renderField}
                                    name={`translations.${code}.name`}
                                    label={<FormattedMessage id={"product.show.attribute.name"}/>}
                                    type={"text"}
                                    required
                                    lock={true}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={6}>
                                <Field
                                    component={this.renderField}
                                    name={"stock"}
                                    label={<FormattedMessage id={"product.show.attribute.stock"}/>}
                                    type={"number"}
                                    lock={true}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={6}>
                                <Field className={classes.formSelect}
                                       classes={classes}
                                       label={<FormattedMessage id={"product.show.attribute.color"}/>}
                                       type={"select"}
                                       name={"color"}
                                       options={colorListTranslated}
                                       component={this.renderField}
                                       selected={getTranslatedLabelForOption(this.state.selectedOptionColor, 'name') || selectedColorTranslated}
                                       onChangeValue={this.handleChangeColor}
                                       placeholder={<FormattedMessage id={"product.show.attribute.color"}/>}
                                       noOptionsMessage={() => <FormattedMessage
                                           id={"product.form.message.noOptions"}/>}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={6}>
                                <Field className={classes.formSelect}
                                       classes={classes}
                                       name={"size"}
                                       options={sizeListTranslated}
                                       component={this.renderField}
                                       selected={getTranslatedLabelForOption(this.state.selectedOptionSize, 'name') || selectedSizeTranslated}
                                       onChangeValue={this.handleChangeSize}
                                       placeholder={<FormattedMessage id={"product.show.attribute.size"}/>}
                                       noOptionsMessage={() => <FormattedMessage
                                           id={"product.form.message.noOptions"}/>}
                                       label={<FormattedMessage id={"product.show.attribute.size"}/>}
                                       type={"select"}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={6}>
                                <Field
                                    component={this.renderField}
                                    name={"price"}
                                    onChange={checkPriceIsValid}
                                    label={<FormattedMessage id={"product.show.attribute.priceIncludedTax"}/>}
                                    type={"price"}
                                    lock={true}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={6}>
                                <Field
                                    component={this.renderField}
                                    name={"weight"}
                                    label={<FormattedMessage id={"product.show.attribute.weight"}/>}
                                    placeholder={this.props.intl.formatMessage({id: "product.show.attribute.weight.placeholder"})}
                                    type={"price"}
                                    onChange={event => event.target.value !== '' && !isFloat(event.target.value) && event.preventDefault() }
                                    lock={true}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={6}>
                                <Field
                                    component={this.renderField}
                                    name={"outletPrice"}
                                    onChange={checkPriceIsValid}
                                    label={<FormattedMessage id={"product.show.attribute.outletPrice"}/>}
                                    type={"price"}
                                    lock={true}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={6}>
                                <Field
                                    component={this.renderField}
                                    name={"discountedPrice"}
                                    onChange={checkPriceIsValid}
                                    label={<FormattedMessage id={"product.show.attribute.discountedPrice"}/>}
                                    type={"price"}
                                    lock={true}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={6}>
                                <Field
                                    component={this.renderField}
                                    name={"ecoTax"}
                                    label={<FormattedMessage id={"product.show.attribute.ecoTax"}/>}
                                    type={"price"}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={6}>
                                <Field
                                    component={this.renderField}
                                    name="unavailableBefore"
                                    label={<FormattedMessage id={"product.show.attribute.unavailableBefore"}/>}
                                    type="date"
                                />
                            </GridItem>
                        </GridContainer>
                        <Divider/>
                        <GridContainer className={classes.formContainer}>
                            <GridItem xs={12} sm={6}>
                                <div className={classes.positionRelative}>
                                    {UserAgentUtils.isMobile() && (
                                        <Button
                                            round
                                            className={classes.scanButton}
                                            onClick={() => this.setState({barcodeScanActive: true})}
                                        >
                                            <BarcodeScannerIcon className={classes.scanIcon}/>
                                        </Button>
                                    )}
                                    <Field
                                        component={this.renderField}
                                        name={"sku"}
                                        label={<FormattedMessage id={"product.show.attribute.variation.sku"}/>}
                                        type={"text"}
                                        required
                                        disabled={hasAuthorization(this.props.authorizations, Authorizations.PICKING_RETAILER)}
                                    />
                                </div>
                            </GridItem>
                            <GridItem xs={12} sm={6}>
                                <Field
                                    component={this.renderField}
                                    name={"externalId"}
                                    label={<FormattedMessage id={"product.show.attribute.externalId"}/>}
                                    type={"text"}
                                />
                            </GridItem>
                        </GridContainer>
                        <Divider/>
                        <GridContainer className={classes.formContainer}>
                            <GridItem xs={12} sm={12}>
                                <div className={classes.fieldContainer}>
                                    <label className={classes.fieldLabel}>
                                        <FormattedMessage id={"product.show.attribute.pictures"}/>
                                    </label>
                                </div>
                                <div className={classes.sectionFooter}>
                                    <ImageSlider
                                        upload={this.props.upload}
                                        removeFile={this.props.removeFile}
                                        sortPictures={this.handleSortPictures}
                                        uploadLoading={this.props.uploadLoading || this.props.mediaObjectLoading}
                                        currentVariation={this.state.currentVariation}
                                        images={this.state.currentVariation.pictures}
                                    />
                                </div>
                            </GridItem>
                        </GridContainer>
                    </div>
                }<Divider/>

                <div className={classes.actionsContainer}>
                    <GridContainer className={classes.formContainer}>
                        <GridItem xs={12} sm={6}>
                            {currentType === "variation" ?
                                <FormGroup row className={classes.formGroupActionFooter}>
                                    <FormControlLabel
                                        control={
                                            <LabeledSwitch
                                                labelMessageId={"product.show.attribute.status"}
                                                disabled={this.props.updateVariationLoading}
                                                checked={(this.state.switchState !== null) ? this.state.switchState : status}
                                                onChange={this.handleSwitchState}
                                            />
                                        }
                                        label={(this.props.updateVariationLoading ?
                                            <BeatLoader sizeUnit={"px"} size={4} color={"#CCC"}
                                                        className={classes.submitLoadingSwitch}/> : "")}
                                    />
                                </FormGroup> :
                                <div className={classes.formGroupLastUpdatedAt}>
                                    {updateAt &&
                                    <div>
                                        <FormattedMessage
                                            id={"product.show.attribute.lastUpdatedAt"}/> {moment(new Date(updateAt)).format("D MMMM YYYY HH:mm")}
                                    </div>
                                    }
                                    <LabeledSwitch
                                        labelMessageId={"product.show.attribute.status"}
                                        disabled={this.props.updateVariationLoading}
                                        checked={(this.state.switchProductState !== null) ? this.state.switchProductState : productStatus}
                                        onChange={this.handleSwitchProductState}
                                    />
                                </div>
                            }
                        </GridItem>
                        <GridItem xs={12} sm={6}>
                            <Button color={"info"} simple
                                    onClick={() => window.history.back()}><ArrowBackIos/><FormattedMessage
                                id={"product.form.back"}/></Button>
                            <Button round color={"success"} type="submit"
                                    disabled={this.props.updateVariationLoading || this.props.updateProductLoading || this.props.massUpdateLoading}>
                                <FormattedMessage id={"product.form.submit"}/>
                                {(this.props.updateProductLoading || this.props.updateVariationLoading || this.props.massUpdateLoading) &&
                                <BeatLoader sizeUnit={"px"} size={4} color={"#FFF"} className={classes.submitLoading}/>
                                }
                            </Button>
                        </GridItem>
                    </GridContainer>
                </div>
            </div>
        );
    }

    render() {
        const {
            authorizations,
            classes,
        } = this.props;
        const canLockFields = hasAuthorization(authorizations, Authorizations.MARKETPLACE_PRODUCT_MANAGEMENT);

        return (
            <div className={classes.container}>
                {canLockFields &&
                <div className={classes.sectionHeader}>
                    <div className={classes.sectionHeaderLine}>
                        <FormControlLabel className={classes.checkLockAll}
                                          control={
                                              <Checkbox
                                                  icon={<LockOpenRounded color={"disabled"}/>}
                                                  checkedIcon={<LockRounded color={"action"}/>}
                                                  checked={this.isFullyLocked()}
                                                  onChange={(e) => this.handleLockCheckboxAll(e)}
                                                  color="primary"
                                              />
                                          }
                        />
                        <Tooltip
                            placement="top-end"
                            title={
                                <React.Fragment>
                                    <FormattedMessage id={"product.show.lock.help"}/>
                                    <span className={classes.arrow}/>
                                </React.Fragment>
                            }
                            classes={{
                                tooltip: classes.bootstrapTooltip,
                                tooltipPlacementLeft: classes.bootstrapPlacementLeft,
                                tooltipPlacementRight: classes.bootstrapPlacementRight,
                                tooltipPlacementTop: classes.bootstrapPlacementTop,
                                tooltipPlacementBottom: classes.bootstrapPlacementBottom,
                            }}
                        >
                            <InfoOutlined size="large"/>
                        </Tooltip>
                    </div>
                </div>}
                <Divider/>
                <form className={classes.sectionBody} onSubmit={this.props.handleSubmit}>
                    <FormTranslator
                        formGenerator={this.renderTranslationForm}
                        changeLocale={(selectedLocale) => this.setState({selectedLocale: selectedLocale})}
                    />
                </form>
            </div>
        );
    }
}

Form.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
};

const mapStateToProps = (state) => {

    const updateProductLoading = state.product.update.updateLoading;
    const updateVariationLoading = state.variation.update.updateLoading;
    const createProductLockLoading = state.productLock.createLock.createLoading;
    const createVariationLockLoading = state.variationLock.createLock.createLoading;
    const updateProduct = state.variation.update.update;
    const {values} = state.form.product;
    const uploadLoading = state.upload.loading;
    const lockAttributes = [];
    const {itemsPerPage} = state.itemsPerPage;

    return {
        updateProductLoading,
        updateVariationLoading,
        createProductLockLoading,
        createVariationLockLoading,
        updateProduct,
        uploadLoading,
        values,
        lockAttributes,
        itemsPerPage,
        mediaObjectLoading: state.variation.variationMediaObject.loading,
        massUpdateLoading: state.product.massUpdate.massUpdateLoading,
        massUpdateSuccess: state.product.massUpdate.massUpdateSuccess,
        massUpdateError: state.product.massUpdate.massUpdateError,
        authorizations: state.authentication.authorizations,
        retailer: state.retailer.show.retrieved,
    };
};

const mapDispatchToProps = dispatch => ({
    updateProduct: (item, values) => dispatch(updateProduct(item, values)),
    massUpdate: (params, values) => dispatch(massUpdate(params, values)),
});

export default reduxForm({
    form: "product",
    enableReinitialize: true,
    keepDirtyOnReinitialize: false
})(connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(formStyle, bubbleStyle)((injectIntl(Form)))));
