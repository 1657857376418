import React, { useEffect, useState, useContext } from "react";
import Chip from '@mui/material/Chip';
import {FilterContext, FilterDispatchContext} from "contexts/filterContext";

export default function FilterChip(props) {
    const [clicked, setClicked] = useState(false);
    const dispatch = useContext(FilterDispatchContext);
    const { filters } = useContext(FilterContext);

    const handleClick = (item) => {
        dispatch({
            type: 'filter',
            item: item
        });
    };

    useEffect(() => {
        let f = filters.find(f => f.id === props.item.id);
        f === undefined ? setClicked(false) : setClicked(true);
    }, [filters]);

    return (
        <Chip
            label={props.label}
            variant="outlined"
            color={ clicked ? "primary" : "default" }
            onClick={() => handleClick(props.item)}
            sx={{...props.sx, width: '100%', margin: 'reset', fontWeight: '400', textTransform: 'none' }}
        />
    );
}
