import React, {Component} from "react";
import connect from "react-redux/es/connect/connect";
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";

// @mui/material components
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
} from "@mui/material";

// actions
import {del} from "actions/member/delete";

class DeleteDialog extends Component {
    static propTypes = {
        isOpen: PropTypes.bool.isRequired,
        closeDeleteDialog: PropTypes.func.isRequired,
        selectedMember: PropTypes.object
    };

    render() {
        return (
            <Dialog open={this.props.isOpen} onClose={() => this.props.closeDeleteDialog()}>
                <DialogTitle id="alert-dialog-title">
                    <FormattedMessage
                        id={"member.dialog.deleteMember"}
                        values={{fullName: this.props.selectedMember.user.fullName}}
                    />
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <FormattedMessage id={"member.dialog.deleteMember.text"}/>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.props.closeDeleteDialog()} color="primary">
                        <FormattedMessage id={"member.dialog.deleteMember.cancel"}/>
                    </Button>
                    <Button
                        onClick={() => {
                            this.props.del(this.props.selectedMember);
                            this.props.closeDeleteDialog()
                        }}
                        color="primary"
                        autoFocus
                    >
                        <FormattedMessage id={"member.dialog.deleteMember.delete"}/>
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    del: (item) => dispatch(del(item))
});

export default connect(
    null,
    mapDispatchToProps
)(DeleteDialog);
