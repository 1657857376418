import React from "react";
import connect from "react-redux/es/connect/connect";
import {injectIntl} from "react-intl";
import {BeatLoader} from 'react-spinners';
import {Field, reduxForm} from "redux-form";
import {SingleDatePicker} from 'react-dates';

// @mui/material components
import {withStyles} from "@mui/styles";

// @mui/icons-material
import {Send} from "@mui/icons-material";

// core components
import Button from "../button/button";

// styles
import stickyNotificationStyle from "../../assets/jss/components/notification/stickyNotificationStyle";
import * as moment from "moment";

class StickyNotificationForm extends React.Component {
    state = {
        date: moment(new Date()),
        focused: false
    };

    render() {
        const {classes} = this.props;

        return (
            <form onSubmit={this.props.handleSubmit} className={classes.cardBodyForm}>
                <SingleDatePicker
                    id={"datePicker"}
                    date={this.state.date}
                    onDateChange={(date) => this.setState({date}, function () {
                        this.props.handleDateChange(date)
                    })}
                    focused={this.state.focused}
                    onFocusChange={({focused}) => this.setState({focused})}
                    appendToBody={true}
                    isOutsideRange={(date) => date > moment()}
                    displayFormat={"D MMMM YYYY"}
                    readOnly={true}
                    required={true}
                    hideKeyboardShortcutsPanel={true}
                    withFullScreenPortal={window.innerWidth < 768}
                    numberOfMonths={1}
                    orientation={window.innerWidth < 768 ? "vertical" : "horizontal"}
                    anchorDirection={"right"}
                />
                <Field
                    component={"input"}
                    name={"ordersCount"}
                    type={"number"}
                    parse={value => value ? parseInt(value, 10) : null}
                    placeholder={this.props.intl.formatMessage({id: "stickyNotification.placeholder.ordersCount"})}
                    min={0}
                    step={1}
                    required
                />
                <Field
                    component={"input"}
                    name={"turnover"}
                    type={"number"}
                    parse={value => value ? parseFloat(value) : null}
                    placeholder={this.props.intl.formatMessage({id: "stickyNotification.placeholder.turnover"})}
                    min={0}
                    required
                />
                <Button type="submit" transparent className={classes.formSubmit}>
                    {(!this.props.listLoading && !this.props.updateLoading && !this.props.createLoading) &&
                    <Send/>
                    }
                    <BeatLoader
                        sizeUnit={"px"}
                        size={5}
                        color={"#FFF"}
                        loading={(this.props.listLoading || this.props.updateLoading || this.props.createLoading)}
                    />
                </Button>
            </form>
        );
    }
}

const mapStateToProps = state => {
    return {
        listLoading: state.physicalEntry.list.loading,
        updateLoading: state.physicalEntry.update.updateLoading,
        createLoading: state.physicalEntry.create.loading
    }
};

export default reduxForm({
    form: "physicalEntry",
    enableReinitialize: true,
}, mapStateToProps)(connect(
    mapStateToProps,
    null
)(withStyles(stickyNotificationStyle)(injectIntl(StickyNotificationForm))));
