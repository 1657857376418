import {primaryColor, lightBlueColor} from "../../main";

const formStyle = theme => ({
    container: {
        borderRadius: "5px",
        backgroundColor: "#fff",
        fontSize: "12px",
        "& .custom__control":{
            borderWidth: "2px",
            borderColor: "#EEE",
            minHeight: "43px",
        },
        "& .custom__control.custom__control--is-focused, .custom__control.custom__control--is-opened":{
            borderWidth: "2px",
            borderColor: primaryColor + "!important",
            boxShadow: "none"
        },
        "& .custom__control:hover":{
            borderWidth: "2px",
            borderColor: "#EEE"
        },
        "& .custom__multi-value":{
            border: "0px solid ",
            backgroundColor: lightBlueColor,
            borderRadius: "3px",
            "& .custom__multi-value__remove":{
                color: "#FFF!important",
            },
            "& .custom__multi-value__remove:hover":{
                backgroundColor: "#8ab8d6!important",
                cursor: "pointer"
            },
            "& .custom__multi-value__label":{
                color: "#FFF!important",
                fontWeight: "bold",
                fontSize: "12px"
            },
        }
    },
    sectionHeader: {
        padding: "10px",
    },
    formContainerLock: {
        alignItems: "center",
        display: "flex",
        justifyContent: "space-between"
    },
    formSpaceLock:{
        marginLeft: "10px",
        width: "25px",
        marginTop: "-5px"
    },
    formSelect: {
        width: "100%"
    },
    sectionHeaderLine: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center"
    },
    checkLockAll: {
       marginRight: "0"
    },
    sectionHeaderTitle: {
        fontWeight: "bold"
    },
    sectionBody: {
        padding: "10px",
        "& hr": {
            margin: "10px 0 10px 0"
        }
    },
    hidden: {
        display:"none"
    },
    sectionFooter: {
        padding: "10px",
        "& .slick-next:before, & .slick-prev:before":{
            color: primaryColor
        },
        "& .slick-next":{
            right: "0"
        },
        "& .slick-prev":{
            left: "0",
            zIndex: "1"
        },
        "& .slick-slide": {
            padding: "0",
            position: "relative",
            width: "150px",
            height: "150px",
            margin: "3px 10px 3px 10px",
            display: "flex",
            alignItems: "center",
            overflow: "hidden",
            border: "2px solid #e0e0e0",
            justifyContent: "center",
            "& > div:first-child": {
                height: "150px"
            }
        },
        "& .slick-slide:first-child": {
            border: "0",
            margin: "3px 3px 3px 10px",
        },
        "& .slick-list": {
            margin: "0 5px"
        },
        "& .slick-track": {
            display: "flex",
            alignItems: "center"
        }
    },
    actionsContainer: {
        position: "relative",
        right: "0px",
        bottom: "0px",
        marginTop: "8px",
        textAlign: "right"
    },
    formGroupActionFooter: {
        alignItems: "center"
    },
    formGroupActionFooterStatus: {
        fontSize: "14px",
        marginRight: "5px"
    },
    formGroupLastUpdatedAt: {
        textAlign: "left",
        marginTop: "15px"
    },
    fieldLabel: {
        color: primaryColor,
        fontWeight: "bold"
    },
    formControl: {
        paddingTop: "0px",
        margin: "0 0 8px 0"
    },
    bubbleContainer:{
        margin: "0 0 17px 0"
    },
    submitLoading: {
        marginLeft: "10px",
        color: "#FFFFFF"
    },
    root: {
        flexGrow: 1,
        height: 250,
    },
    input: {
        display: 'flex',
        padding: 0,
    },
    inputLabel: {
        fontWeight: "bold",
        color: "red"
    },
    valueContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flex: 1,
        alignItems: 'center',
        overflow: 'hidden',
    },
    chip: {
        margin: `${theme.spacing(.5)} ${theme.spacing(.25)}`,
        backgroundColor: "red",
        color: "#FFF"
    },
    chipFocused: {

    },
    noOptionsMessage: {
        padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    },
    singleValue: {
        fontSize: 16,
    },
    placeholder: {
        position: 'absolute',
        left: 2,
        fontSize: 11,
    },
    paper: {
        position: 'absolute',
        zIndex: 1,
        marginTop: theme.spacing(1),
        left: 0,
        right: 0,
    },
    divider: {
        height: theme.spacing(2)
    },
    "& .custom__multi-value":{
        border: "3px solid green"
    },
    arrow: {
        position: 'absolute',
        fontSize: 6,
        width: '3em',
        height: '1em',
        bottom: 0,
        right: 0,
        marginBottom: '-0.95em',
        '&::before': {
            content: '""',
            margin: 'auto',
            display: 'block',
            width: 0,
            height: 0,
            borderStyle: 'solid',
            borderWidth: '1em 1em 0 1em',
            borderColor: `#000 transparent transparent transparent`,
        },
    },

    bootstrapTooltip: {
        backgroundColor: theme.palette.common.black,
    },
    bootstrapPlacementLeft: {
        margin: '0 8px',
    },
    bootstrapPlacementRight: {
        margin: '0 8px',
    },
    bootstrapPlacementTop: {
        margin: '8px 0',
    },
    bootstrapPlacementBottom: {
        margin: '8px 0',
    },

    htmlTooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
        '& b': {
            fontWeight: theme.typography.fontWeightMedium,
        },
    },
    positionRelative: {
        position: 'relative',
    },
    scanButton: {
        height: '37px',
        width: '45px',
        cursor: 'pointer',
        paddingTop: '18px',
        paddingLeft: '0px',
        backgroundColor: 'transparent !important',
        marginBottom: '10px',
        position: 'absolute',
        right: '34px',
        top: 'calc(50% - 17px)',
        zIndex: '1',
        boxShadow: 'none !important',
        borderRadius: '0',
        borderLeft: '2px #eee solid',
    },
    scanIcon: {
        width: '100% !important',
        height: '35px !important',
        position: 'relative',
        right: '-8px',
    },
});

export default formStyle;
