import React from 'react';

import makeStyles from "@mui/styles/makeStyles";

import Table from "@mui/material/Table";

const useStyle = makeStyles({
    table: {
        marginBottom: "0",
        borderSpacing: "0",
        borderCollapse: "collapse",
        borderRadius: '12px',
        overflow: 'hidden',
    }
});

export default function StyledTable({children}) {
    const classes = useStyle();

    return (
        <Table className={classes.table}>
            {children}
        </Table>
    )
}
