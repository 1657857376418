import React from "react";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";

import CardBody from "components/card/cardBody";
import CardStats from "views/marketplace/dashboard/components/cards/cardStats";
import CardStatsTitle from "views/marketplace/dashboard/components/cards/cardStatsTitle";
import Loader from "views/marketplace/dashboard/components/loader";
import SortedTable from "../../sortedTable";

export default function CategoryProductTable(props) {
    const { data, loading } = props;

    return (
        <CardStats>
            <Loader loading={loading} />
            <CardStatsTitle>
                <FormattedMessage id={"dashboard.ope_category_product.title"} />
            </CardStatsTitle>
            <CardBody>
                <SortedTable
                    data={data}
                    orderBy={'count'}
                    headers={[
                        {
                            key: 'name',
                            label: 'dashboard.ope_category_product.table_header.name',
                        },
                        {
                            key: 'count',
                            label: 'dashboard.ope_category_product.table_header.product_count',
                        },
                        {
                            key: 'sold',
                            label: 'dashboard.ope_category_product.table_header.product_sold',
                        },
                    ]}
                />
            </CardBody>
        </CardStats>
    )
}

CategoryProductTable.propTypes = {
    data: PropTypes.array,
    loading: PropTypes.bool.isRequired,
};

