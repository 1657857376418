import React from 'react';
import {Link} from "react-router-dom";
import {FormattedMessage} from "react-intl";

import PrintIcon from '@mui/icons-material/Print';

import {ListItemButton, ListItemIcon, ListItemText} from "@mui/material";

export default function DrawerPrinters({classes}) {
    const isSelected = window.location.pathname.indexOf('/picking/printers') === 0;

    return (
        <div>
            <ListItemButton
                button
                className={classes.listItem}
                classes={{selected: classes.selected}}
                key={"printers"}
                selected={isSelected}
                component={Link}
                to={'/picking/printers'}
            >
                <ListItemIcon>
                    <PrintIcon color={isSelected ? "primary" : "secondary"} />
                </ListItemIcon>
                <ListItemText
                    primaryTypographyProps={{color: isSelected ? "primary" : "secondary"}}
                    primary={<FormattedMessage id={"sidebar.item.printer"}/>}
                />
            </ListItemButton>
        </div>
    );
}
