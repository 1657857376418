import { primaryColor } from "../../main";

const row = {
    height: "65px",
    borderRadius: "5px",
    background: "white",
    overflow: "none",
    cursor: "pointer",
    display:'flex',
    flexDirection: "row",
    justifyContent: "start",
    alignItems: "center",
    marginTop: "10px",
    listStyleType: "none",
};

const sectorStyle = theme => ({
    element: {
        ...row,
        "&:hover": {
            overflow: "hidden",
        },
    },
    elementMatch: {
        ...row,
        border: `2px solid ${primaryColor} !important`
    },
    elementHidden: {
        ...row,
        display: `none`
    },
    checkbox: {
        color: "grey",
        "&$checked": {
            color: primaryColor,
            "&:hover": {
                backgroundColor: "rgba(0, 53, 245, 0.08)"
            },
        },
        "&:hover": {
            backgroundColor: "rgba(0, 53, 245, 0.08)"
        },
    },
    checked: {
        color: primaryColor,
    },
    sectorNameSelected: {
        fontWeight: "bold",
        color: primaryColor,
    },
    iconButton: {
        "&:hover": {
            backgroundColor: "rgba(0, 53, 245, 0.08)"
        }
    },
    iconButtonMargin :{
        marginRight: "30px"
    },
    name: {
        width: "20%",
        flexGrow: 1,
        marginRight: "10px",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start"
    },
    actionsButtonContainer: {
        paddingRight: "10px",
        display: "flex",
        alignItems: "center",
        flexGrow: 0,
    },
});

export default sectorStyle;
