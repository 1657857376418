import React, {useContext} from "react";
import {DateRangePicker} from "react-dates";

import { DashboardDateRangePickerContext } from "../contexts/dasboardDateRangePickerContext";

export default function DashboardDateRangePicker() {
    const {
        startDate,
        endDate,
        handleDateChange,
        focusedInput,
        setStartDate,
        setEndDate,
        setFocusedInput,
    } = useContext(DashboardDateRangePickerContext)

    return (
        <DateRangePicker
            startDateId="startDate"
            endDateId="endDate"
            startDatePlaceholderText={"-"}
            endDatePlaceholderText={"-"}
            startDate={startDate}
            endDate={endDate}
            onDatesChange={({startDate, endDate}) => {
                setStartDate(startDate);
                setEndDate(endDate);
            }}
            focusedInput={focusedInput}
            onFocusChange={(focusedInput) => {
               setFocusedInput(focusedInput)
            }}
            onClose={({ startDate, endDate }) => handleDateChange(startDate, endDate)}
            displayFormat={"D MMMM YYYY"}
            block={true}
            readOnly={true}
            isOutsideRange={() => false}
            showDefaultInputIcon={true}
            showClearDates={true}
            reopenPickerOnClearDates={true}
            required={true}
            hideKeyboardShortcutsPanel={true}
            withFullScreenPortal={window.innerWidth < 768}
            numberOfMonths={2}
            orientation={window.innerWidth < 768 ? "vertical" : "horizontal"}
            anchorDirection={"right"}
            minimumNights={0}
        />
    )
}
