import {ListItem, ListItemIcon, ListItemText} from "@mui/material";
import {LiveHelpOutlined} from "@mui/icons-material";
import {FormattedMessage} from "react-intl";
import React, {Component} from "react";
import {Authorizations, hasAuthorization} from "../../../utils/authorizations";

class DrawerHelp extends Component {
    render() {
        const { classes, authorizations } = this.props;

        return (
            <div>
                <ListItem
                    button
                    className={classes.listItem}
                    classes={{selected: classes.selected}}
                    key={"zendesk"}
                    selected={false}
                    onClick={() => window.open(
                        hasAuthorization(authorizations, Authorizations.OTHER_HELPDESK_CLIENT) ?
                            'https://wishibam8442.zendesk.com/'
                        :
                            'https://supportwishibam.zendesk.com/hc/fr'
                        )}
                >
                    <ListItemIcon>
                        <LiveHelpOutlined color={"secondary"}/>
                    </ListItemIcon>
                    <ListItemText
                        primaryTypographyProps={{color:"secondary"}}
                        primary={<FormattedMessage id={"sidebar.item.SAV"}/>}
                    />
                </ListItem>
            </div>
        )
    }
}

export default DrawerHelp;
